import { useState, useEffect, useRef } from 'react';
import { getContainersByName, getContainers } from '../../../../../API/ContainerApi';
import { _defineStatus, useClickOutside, _defineContainerCat, _defineContainerType, _defineContainerSubType } from '../../../../../Utils';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import * as Constant from '../../../../../Constants';
import SearchComponent from '../../../../../Components/searchComponent';
export default function Container() {
    const navigate = useNavigate();
    const [filterSelected, setFilterSelected] = useState('asc');

    const [AllContainerList, setAllContainerList] = useState([]);
    const [productsNames, setProductNames] = useState([]);
    const [showOptions, setShowOptions] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const dropdownRef = useRef(null);

    const handleClickOutside = () => {
        setShowOptions(false);
    };
    useClickOutside(dropdownRef, handleClickOutside);

    const fetchAllContainer = async () => {
        try {
            const result = await getContainers();
            setAllContainerList(result);
        } catch (error) {
            console.error('Erreur lors de la récupération des Containers', error);
        }
    };

    useEffect(() => {
        const fetchContainerNames = async () => {
            try {
                if (searchTerm.length > 2) {
                    const result = await getContainersByName(searchTerm);
                    if (result.length) {
                        setAllContainerList(result);
                        setProductNames(result);
                        setErrorMessage('');
                    } else {
                        setAllContainerList([]);
                        setProductNames([]);
                        setErrorMessage('Aucun résultat');
                    }
                } else if (searchTerm.length === 0) {
                    fetchAllContainer();
                    setErrorMessage('');
                }
            } catch (error) {
                console.error('Erreur lors de la récupération des Containers', error);
            }
        };

        fetchContainerNames();
    }, [searchTerm]);

    const handleInputChange = (e) => {
        setSearchTerm(e.target.value.toLowerCase());
        setShowOptions(true);
    };

    const handleSelectOption = (option) => {
        setSearchTerm(option.name);
        setShowOptions(false);
        navigate('/servicesupplier/operation/container/sheet', { state: { id: option.id } });
    };

    const sortDataAsc = () => {
        const sortedData = [...AllContainerList].sort((a, b) => a.name.localeCompare(b.name));
        setAllContainerList(sortedData);
        setFilterSelected('asc');
    };

    const sortDataDesc = () => {
        const sortedData = [...AllContainerList].sort((a, b) => b.name.localeCompare(a.name));
        setAllContainerList(sortedData);
        setFilterSelected('desc');
    };

    return (
        <main>
            <section className="big-section">
                <h2>Les contenants</h2>
                <div className="top">
                    <SearchComponent
                        dropdownRef={dropdownRef}
                        showOptions={showOptions}
                        searchTerm={searchTerm}
                        handleInputChange={handleInputChange}
                        productsNames={productsNames}
                        handleSelectOption={handleSelectOption}
                        placeholder="Rechercher un contenant"
                        searchKey="name"
                    />
                    <div className="top-button-sort">
                        <div className="filter">
                            <p className="sort">Trier par</p>
                            <div>
                                <p
                                    className={filterSelected === 'asc' ? 'selected' : 'classic'}
                                    onClick={sortDataAsc}>
                                    A &gt; Z
                                </p>
                                <p
                                    className={filterSelected === 'desc' ? 'selected' : 'classic'}
                                    onClick={sortDataDesc}>
                                    Z &gt; A
                                </p>
                            </div>
                        </div>
                        <Link
                            to={'/servicesupplier/operation/container/add'}
                            state={{ supplier_id: 0 }}
                            className="button-actif">
                            AJOUTER UN CONTENANT
                        </Link>
                    </div>
                </div>
                {errorMessage && (
                    <div style={{ width: '100%', padding: '0.5rem', textAlign: 'center', color: Constant.$rouge, marginBottom: '1.25rem' }}>
                        <p>{errorMessage}</p>
                    </div>
                )}
                <div className="tab-item">
                    <div
                        className="pre-filled-fields-1-several-item"
                        style={{ fontWeight: '600' }}>
                        <p>Nom</p>
                        <p>sku</p>
                        <p>Catégorie</p>
                        <p>Propriétaire</p>
                        <p>Type</p>
                        <p>Sous type</p>
                        <p>Statut</p>
                    </div>
                    {AllContainerList.length ? (
                        AllContainerList.map((item, index) => {
                            return (
                                <Link
                                    key={index}
                                    className="pre-filled-fields-2-several-item"
                                    to={'/servicesupplier/operation/container/sheet'}
                                    state={{ id: item.id, sku: item.sku }}>
                                    <p>{item.name}</p>
                                    <p>{item.sku}</p>
                                    <p>{_defineContainerCat(item.cat)}</p>
                                    <p>{item.owner_brand ? item.entity.name : 'Standard'}</p>
                                    <p>{_defineContainerType(item.type)}</p>
                                    <p>{_defineContainerSubType(item.subtype)}</p>
                                    <p
                                        style={{
                                            backgroundColor: _defineStatus(item.status).color,
                                            color: Constant.$blanc,
                                            borderTopRightRadius: '0.6rem',
                                            borderBottomRightRadius: '0.6rem',
                                        }}>
                                        {_defineStatus(item.status).name}
                                    </p>
                                </Link>
                            );
                        })
                    ) : (
                        <div className="no-data-graph ">Aucun contenant enregistré.</div>
                    )}
                </div>
            </section>
        </main>
    );
}
