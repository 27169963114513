import { getGearAPIApiEndpoint } from './endpoints';
import * as Sentry from '@sentry/react';
import { defaultHeaders } from './headers';

const GEAR_API_BASE_URL = getGearAPIApiEndpoint();

const BOCOLOCO_ADD_SUPPLIER = '/supplier/add';
const BOCOLOCO_UPDATE_SUPPLIER = '/supplier/update';
const BOCOLOCO_GET_SUPPLIERS = '/supplier/get';
const BOCOLOCO_GET_SUPPLIERSBYID = '/supplier/getbyid';
const BOCOLOCO_GET_SUPPLIERSBYNAME = '/supplier/getbyname';
const BOCOLOCO_DELETE_SUPPLIER = '/supplier/delete';
const BOCOLOCO_GET_PRODUCTBYSUPPLIERID = '/product/getbysupplierid';

export function getSuppliers() {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_SUPPLIERS;
    const url = encodeURI(url_base);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function addSupplier(formData) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_ADD_SUPPLIER;
    const url = encodeURI(url_base);
    return fetch(url, {
        method: 'PUT',
        body: JSON.stringify(formData),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            console.log('ERROR ADD SUPPLIER');
            console.log(error);
            throw error;
        });
}
export function updateSupplier(id, updatedData) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_UPDATE_SUPPLIER;
    const url_args = '?id=' + id;
    const url = encodeURI(url_base + url_args);
    return fetch(url, {
        method: 'PUT',
        body: JSON.stringify(updatedData),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            console.log('ERROR UPDATE SUPPLIER');
            console.log(error);
            throw error;
        });
}

export function getSuppliersById(id) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_SUPPLIERSBYID;
    var url_args = '?id=' + id;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function getSuppliersByName(name) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_SUPPLIERSBYNAME;
    var url_args = '?name=' + name;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function getProductBySupplierId(supplier_id) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_PRODUCTBYSUPPLIERID;
    var url_args = '?supplier_id=' + supplier_id;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function deleteSupplier(id) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_DELETE_SUPPLIER;
    var url_args = '?id=' + id;
    const url = encodeURI(url_base + url_args);

    return fetch(url, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            throw error;
        });
}
