import { useState, useEffect } from 'react';
import { getAllFinancialStoreCashOutByDates, fetchCsvText } from '../../../API/financialCashOutReporting';
import { downloadReportingStoreCashOutCsv, downloadReportingStoreCashOutPdf } from '../../../API/financialCashOutReporting';
import { getStoreowners, getStoreownerById } from '../../../API/StoreownerApi';
import { useNavigate, Link } from 'react-router-dom';
import { startOfMonth, endOfMonth, format, endOfDay } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Eye, DotsThree, FileCsv, FilePdf } from '@phosphor-icons/react';

export default function CdcStore() {
    const navigate = useNavigate();
    const now = new Date();
    const [startDate, setStartDate] = useState(startOfMonth(new Date()));
    const [endDate, setEndDate] = useState(endOfMonth(new Date()));
    const currentMonth = format(new Date(), 'MM');
    const [monthSelected, setMonthSelected] = useState(currentMonth);
    const [yearSelected, setYearSelected] = useState(now.getFullYear());
    const [reportingTab, setReportingTab] = useState([]);
    const [storeOwnerList, setStoreOwnerList] = useState([]);
    const [storeOwnerSelected, setStoreOwnerSelected] = useState('');
    const [showOptions, setShowOptions] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [showGlobalOptions, setShowGlobalOptions] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // TOUS LES FINANCIAL CASHIN
                const allFinancialStoreCashOutRaw = await getAllFinancialStoreCashOutByDates(startDate.toISOString(), endOfDay(endDate.toISOString()));

                for (let i = 0; i < allFinancialStoreCashOutRaw.length; i++) {
                    const entity = await getStoreownerById(allFinancialStoreCashOutRaw[i].entityId);
                    allFinancialStoreCashOutRaw[i].entity = entity;
                }

                let allFinancialStoreCashOutFiltered = [];
                if (storeOwnerSelected) {
                    allFinancialStoreCashOutFiltered = allFinancialStoreCashOutRaw.filter(
                        (reporting) => reporting.entity[0].id === parseInt(storeOwnerSelected),
                    );
                } else {
                    allFinancialStoreCashOutFiltered = allFinancialStoreCashOutRaw;
                }

                const groupedData = groupByMonthAndEntityId(allFinancialStoreCashOutFiltered);
                const sortedDataDesc = groupedData.sort((a, b) => {
                    if (a.year === b.year) {
                        return b.month - a.month;
                    }
                    return b.year - a.year;
                });

                setReportingTab(sortedDataDesc);

                // TOUS LES STORE OWNER
                const allStoreOwner = await getStoreowners();
                setStoreOwnerList(allStoreOwner);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        if (startDate & endDate) fetchData();
    }, [startDate, endDate, navigate, storeOwnerSelected]);

    const groupByMonthAndEntityId = (data) => {
        const groupedData = {};

        data.forEach((item) => {
            const month = format(item.endDate, 'MM');
            const year = format(item.endDate, 'yyyy');
            const entityId = item.entityId;
            const key = `${year}-${month}-${entityId}`;

            if (!groupedData[key]) {
                groupedData[key] = {
                    month,
                    year,
                    entity: item.entity[0],
                    items: [],
                };
            }
            groupedData[key].items.push(item);
        });

        return Object.values(groupedData);
    };
    const resetGlobalFilter = () => {
        setMonthSelected(currentMonth);
        setYearSelected(now.getFullYear());
        setStartDate(startOfMonth(new Date()));
        setEndDate(endOfMonth(new Date()));
        setStoreOwnerSelected('');
    };

    const getAllMonthsInYear = () => {
        const months = [{ month: "Depuis le début de l'année", value: 'all' }];
        for (let month = 0; month < 12; month++) {
            const date = new Date();
            date.setMonth(month);
            months.push({ month: format(date, 'MMMM', { locale: fr }), value: format(date, 'MM') });
        }
        return months;
    };
    const months = getAllMonthsInYear();

    const getYears = () => {
        const years = [];
        for (let year = 2020; year <= now.getFullYear(); year++) {
            years.push(year);
        }
        return years;
    };
    const years = getYears();

    const handleMonthSelected = (month) => {
        if (month === 'all') {
            setStartDate(new Date(new Date().getFullYear(), 0, 1));
            setEndDate(now);
        } else {
            setStartDate(startOfMonth(new Date(now.getFullYear(), month - 1, 1)));
            setEndDate(endOfMonth(new Date(now.getFullYear(), month - 1, 1)));
        }
        setMonthSelected(month);
    };
    const handleYearSelected = (year) => {
        setYearSelected(year);
        setStartDate(startOfMonth(new Date(year, monthSelected - 1, 1)));
        setEndDate(endOfMonth(new Date(year, monthSelected - 1, 1)));
    };

    const getCsv = async (fileName) => {
        try {
            const response = await downloadReportingStoreCashOutCsv(fileName);
            if (response) {
                console.log('response', response);
            }
        } catch (error) {
            console.log('error', error);
        }
    };

    const getPdf = async (fileName) => {
        try {
            const response = await downloadReportingStoreCashOutPdf(fileName);
            if (response) {
                console.log('response', response);
            }
        } catch (error) {
            console.log('error', error);
        }
    };

    function mergeCsvData(csvDataArray) {
        let mergedData = [];
        let headers = null;

        csvDataArray.forEach((csvData, index) => {
            const rows = csvData.split('\n').map((row) => row.split(','));

            if (index === 0) {
                headers = rows[0]; // On prend les en-têtes du premier fichier CSV
                mergedData.push(headers);
            }

            for (let i = 1; i < rows.length; i++) {
                if (rows[i].length === headers.length) {
                    // Vérifier que la ligne a le bon nombre de colonnes
                    mergedData.push(rows[i]);
                }
            }
        });

        return mergedData;
    }

    function convertArrayToCsv(array) {
        return array.map((row) => row.join(',')).join('\n');
    }

    function downloadMergedCsv(keys) {
        const csvPromises = keys.map((item) => fetchCsvText(item.items[0].csvFile));

        Promise.all(csvPromises)
            .then((csvDataArray) => {
                const mergedData = mergeCsvData(csvDataArray);
                const mergedCsv = convertArrayToCsv(mergedData);
                const blob = new Blob([mergedCsv], { type: 'text/csv' });
                const downloadUrl = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = downloadUrl;
                a.download = `stores_${format(monthSelected, 'MM')}_${yearSelected}.csv.csv`;
                document.body.appendChild(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(downloadUrl);
            })
            .catch((error) => {
                console.error('Error downloading CSV files:', error);
            });
    }

    return (
        <main>
            <div className="data-container">
                <div className="header-title">
                    <h1>REPORTING MENSUELS MAGASINS</h1>
                </div>

                <div className="header-container">
                    <div className="content-header">
                        <div className="header-container-content">
                            <select
                                onChange={(e) => handleMonthSelected(e.target.value)}
                                value={monthSelected}>
                                {months.map((option, index) => (
                                    <option
                                        key={index}
                                        value={option.value}>
                                        {option.month}
                                    </option>
                                ))}
                            </select>
                            <select
                                style={{ width: '5rem' }}
                                onChange={(e) => handleYearSelected(e.target.value)}
                                value={yearSelected}>
                                {years.map((option, index) => (
                                    <option
                                        key={index}
                                        value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>
                            <select
                                style={{ width: '15rem' }}
                                onChange={(e) => setStoreOwnerSelected(e.target.value)}
                                value={storeOwnerSelected}>
                                <option value={''}>Toutes les entités juridiques</option>
                                {storeOwnerList.map((option, index) => (
                                    <option
                                        key={index}
                                        value={option.id}>
                                        {option.name}
                                    </option>
                                ))}
                            </select>

                            <div
                                onClick={resetGlobalFilter}
                                className="reset">
                                <p style={{ fontWeight: 'bold' }}>X</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="header-title"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: '2rem',
                        marginBottom: '1rem',
                        gap: '2rem',
                        position: 'relative',
                    }}>
                    <h1 style={{ marginTop: 0, marginBottom: 0 }}>
                        {monthSelected === 'all' ? "Depuis le début de l'année" : format(monthSelected, 'MMMM', { locale: fr })} {yearSelected}
                    </h1>
                    {reportingTab.length && monthSelected !== 'all' ? (
                        <div>
                            <p
                                style={{ flex: 0.5, paddingLeft: '0.93rem', cursor: 'pointer', textAlign: 'center' }}
                                onClick={() => {
                                    setShowGlobalOptions(!showGlobalOptions);
                                }}>
                                <DotsThree size={25} />
                            </p>
                            {showGlobalOptions && (
                                <div className="option-container tooltip ">
                                    <p style={{ cursor: 'pointer' }}>
                                        <Link
                                            to={'/cdc/financial/stores/details'}
                                            state={{
                                                startDate: startDate,
                                                endDate: endDate,
                                                date: reportingTab[0].items[0].createdAt,
                                            }}>
                                            <Eye size={25} />
                                        </Link>
                                    </p>
                                    <p
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            downloadMergedCsv(reportingTab);
                                        }}>
                                        <FileCsv size={25} />
                                    </p>
                                </div>
                            )}
                        </div>
                    ) : null}
                </div>
                <div style={{ marginBottom: '2rem', display: 'flex', width: '100%', gap: '3%', flexDirection: 'column' }}>
                    <div className="tab-item">
                        <div
                            className="pre-filled-fields-1-several-item"
                            style={{ fontWeight: '600', width: '100%', fontSize: 15 }}>
                            <p style={{ flex: 1 }}>Société juridique</p>
                            <p style={{ flex: 1 }}>Siret</p>
                            <p style={{ flex: 1 }}>Marque</p>
                            <p style={{ flex: 1 }}>Période</p>
                            <p style={{ flex: 1 }}>Date reporting</p>
                            <p style={{ flex: 1, borderRight: 0 }}>Montant consigne (€)</p>
                            <p style={{ flex: 0.5 }}></p>
                        </div>
                    </div>

                    {reportingTab.length ? (
                        reportingTab.map((elem, index) => (
                            <section
                                key={index}
                                className="section-graph"
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    height: '4rem',
                                    alignItems: 'center',
                                    padding: 0,
                                    position: 'relative',
                                }}>
                                <p style={{ flex: 1, paddingLeft: '0.93rem' }}>{elem.entity.company_name}</p>
                                <p style={{ flex: 1, paddingLeft: '0.93rem' }}>{elem.entity.siret}</p>
                                <p style={{ flex: 1, paddingLeft: '0.93rem' }}>{elem.entity.name}</p>
                                <p style={{ flex: 1, paddingLeft: '0.93rem' }}>
                                    {format(elem.month, 'MMMM', { locale: fr })} {elem.year}
                                </p>
                                <p style={{ flex: 1, paddingLeft: '0.93rem' }}>{format(elem.items[0].createdAt, 'dd/MM/yyyy')}</p>
                                <p style={{ flex: 1, paddingLeft: '0.93rem' }}>{elem.items.reduce((acc, item) => acc + item.amount / 100, 0)} €</p>
                                <p
                                    style={{ flex: 0.5, paddingLeft: '0.93rem', cursor: 'pointer', textAlign: 'center' }}
                                    onClick={() => {
                                        setSelectedIndex(index);
                                        setShowOptions(index === selectedIndex ? !showOptions : false);
                                    }}>
                                    <DotsThree size={25} />
                                </p>
                                {showOptions && index === selectedIndex && (
                                    <div className="option-container tooltip ">
                                        <p style={{ cursor: 'pointer' }}>
                                            <Link
                                                to={'/cdc/financial/stores/details'}
                                                state={{ startDate: startDate, endDate: endDate, date: elem.items[0].createdAt, entity: elem.entity }}>
                                                <Eye size={25} />
                                            </Link>
                                        </p>
                                        <p
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                getCsv(elem.items[0].csvFile);
                                            }}>
                                            <FileCsv size={25} />
                                        </p>
                                        <p
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                getPdf(elem.items[0].pdfFile);
                                            }}>
                                            <FilePdf size={25} />
                                        </p>
                                    </div>
                                )}
                            </section>
                        ))
                    ) : (
                        <div className="no-data-graph ">Aucune donnée.</div>
                    )}
                </div>
            </div>
        </main>
    );
}
