import Cookies from 'js-cookie';
import * as Constants from '../../Constants';

export const CdcMenuOperation = () => {
    return [];
};

export const getSousMenuCdcOperation = (onglet) => {
    switch (onglet) {
        default:
            return [];
    }
};

export const CdcMenuData = () => {
    return [];
};

export const getSousMenuCdcData = (onglet) => {
    switch (onglet) {
        default:
            return [];
    }
};

// FACTURATION
const CheckAccessFinancialGestionFinanciere = (access_financial) => {
    for (let key in access_financial.gestion_financiere) {
        if (access_financial.gestion_financiere[key] === 1) {
            return true;
        }
    }
    return false;
};

export const CdcMenuFinancial = () => {
    let menu_financial = [];
    const access = Cookies.get('access').length ? JSON.parse(Cookies.get('access')) : null;
    if (access) {
        let access_financial = access.cdc.financial;
        if (CheckAccessFinancialGestionFinanciere(access_financial)) {
            menu_financial.push('financial_management');
        }

        return menu_financial;
    }
    return [];
};

export const getSubAccessFinancialGestionFinanciere = () => {
    let sousMenu = [{ title: 'Gestion financière' }];
    const access = Cookies.get('access').length ? JSON.parse(Cookies.get('access')) : null;
    if (access) {
        let access_financial = access.cdc.financial;
        if (access_financial.gestion_financiere.dashboard === 1) {
            sousMenu.push({ name: 'Dashboard', link: '/cdc/financial/dashboard' });
        }
        if (access_financial.gestion_financiere.marques === 1) {
            sousMenu.push({ name: 'Marques', link: '/cdc/financial/industrial' });
        }
        if (access_financial.gestion_financiere.magasins === 1) {
            sousMenu.push({ name: 'Magasins', link: '/cdc/financial/stores' });
        }
        if (access_financial.gestion_financiere.collecteurs === 1) {
            sousMenu.push({ name: 'Collecteurs', link: '/cdc/financial/collector' });
        }
    }
    return sousMenu;
};

export const getSousMenuCdcFinancial = (onglet) => {
    switch (onglet) {
        case 'financial_management':
            return getSubAccessFinancialGestionFinanciere();
        default:
            return [];
    }
};

export const cdcMenuAccount = () => {
    return ['my_account'];
};

export const getSousMenuCdcAccount = (onglet) => {
    const role = Cookies.get('role');
    const isAdmin = role === Constants.ENTITY_ROLE_ADMIN;
    switch (onglet) {
        case 'my_account':
            if (isAdmin) {
                return [
                    { title: 'Mon compte' },
                    { name: 'Informations personnelles', link: '/cdc/myprofil/personal_info' },
                    { name: 'Société', link: '/cdc/myprofil/company_info' },
                    { name: 'Accès', link: '/cdc/myprofil/access' },
                    { name: 'Gestion des comptes', link: '/cdc/myprofil/rolemanagement' },
                ];
            } else {
                return [
                    { title: 'Mon compte' },
                    { name: 'Informations personnelles', link: '/cdc/myprofil/personal_info' },
                    { name: 'Société', link: '/cdc/myprofil/company_info' },
                    { name: 'Accès', link: '/cdc/myprofil/access' },
                ];
            }

        default:
            return [];
    }
};
