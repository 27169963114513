import { useEffect, useState } from 'react';
import { getAllReturnInCollector } from '../../../../API/CollectorReturnApi';
import { getAllReturnInCollectorCheck } from '../../../../API/CheckApi/CheckCollectorReturnApi';
import { getDetailsLastSorterEventStatus } from '../../../../API/SorterDeclarationApi';
import { getDetailsLastWasherEventStatus } from '../../../../API/WasherDeclarationApi';
import * as Constants from '../../../../Constants';
import BarChart from '../../../../Components/BarChart';
import CircleChart from '../../../../Components/CircleChart';
import { ComposableMap, Geographies, Geography, Marker } from 'react-simple-maps';
import franceData from '../../../../Assets/data/france.json';
import { getSorters } from '../../../../API/SorterApi';
import { getCollectors } from '../../../../API/CollectorApi';
import { getDetailsLastWasherEventStatusCheck } from '../../../../API/CheckApi/CheckWasherDeclarationApi';
import { getDetailsLastSorterEventStatusCheck } from '../../../../API/CheckApi/CheckSorterDeclarationApi';
import { getCollectorCheck } from '../../../../API/CheckApi/CheckCollectorApi';
import * as Sentry from '@sentry/react';
import { useNavigate } from 'react-router-dom';
export default function FsCirculationContainerDashboard() {
    const navigate = useNavigate();
    const [allDataInCirculation, setAllDataInCirculation] = useState([]);
    const [sorterDataBySku, setSorterDataBySku] = useState([]);
    const [washerDataBySku, setWasherDataBySku] = useState([]);
    const [collectorDataBySku, setCollectorDataBySku] = useState([]);
    const [allOperatorAddress, setAllOperatorAddress] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const { gearApi } = await import('../../../../gearApi.js');

            const dataInCollector = await getAllReturnInCollector();
            const dataInCollectorCheck = getAllReturnInCollectorCheck(dataInCollector);
            if (dataInCollector.length && dataInCollectorCheck !== true) {
                console.error(dataInCollectorCheck);
                Sentry.captureException(dataInCollectorCheck);
                navigate('/error');
                return;
            }

            const dataInSorter = await getDetailsLastSorterEventStatus();
            const dataInsorterCheck = getDetailsLastSorterEventStatusCheck(dataInSorter);
            if (dataInSorter.length && dataInsorterCheck !== true) {
                console.error(dataInsorterCheck);
                Sentry.captureException(dataInsorterCheck);
                navigate('/error');
                return;
            }
            const dataInSorterFiltered = dataInSorter
                ? dataInSorter.filter(
                      (item) =>
                          item.type === Constants.DECLARATION_DETAILS_TYPE_OK &&
                          (!item.suiviID || (item.suivi && item.suivi.sorterEvents[0].status !== Constants.EVENT_RECEIVED)),
                  )
                : [];

            const dataInWasher = await getDetailsLastWasherEventStatus();
            const dataInWasherCheck = getDetailsLastWasherEventStatusCheck(dataInWasher);
            if (dataInWasher.length && dataInWasherCheck !== true) {
                console.error(dataInWasherCheck);
                Sentry.captureException(dataInWasherCheck);
                navigate('/error');
                return;
            }
            const dataInWasherFiltered = dataInWasher
                ? dataInWasher.filter(
                      (item) =>
                          item.type === Constants.DECLARATION_DETAILS_TYPE_OK &&
                          (!item.suiviID || (item.suivi && item.suivi.washerEvents[0].status !== Constants.EVENT_RECEIVED)),
                  )
                : [];

            const dataInCollectorFinal = dataInCollector.length
                ? dataInCollector.reduce(
                      (acc, curr) => {
                          acc.qty += curr.qty;
                          return acc;
                      },
                      { qty: 0, type: 'collecteur' },
                  )
                : { qty: 0, type: 'collecteur' };
            const dataInSorterFinal = dataFinal(dataInSorterFiltered, 'centre de tri');
            const dataInWasherFinal = dataFinal(dataInWasherFiltered, 'centre de lavage');
            const allDataInCirculationRaw = [];
            allDataInCirculationRaw.push(dataInCollectorFinal, dataInSorterFinal, dataInWasherFinal);

            setAllDataInCirculation(allDataInCirculationRaw);

            const sorterDataBySkuRaw = dataParSku(dataInSorterFiltered, 'centre de tri');
            setSorterDataBySku(sorterDataBySkuRaw);
            const washerDataBySkuRaw = dataParSku(dataInWasherFiltered, 'centre de lavage');
            setWasherDataBySku(washerDataBySkuRaw);
            const collectorDataBySkuRaw =
                Object.values(
                    dataInCollector.reduce((acc, curr) => {
                        const sku = curr.entityIndustrialProduct ? curr.entityIndustrialProduct.container.sku : 'N.D';

                        if (acc[sku]) {
                            acc[sku].qty += curr.qty;
                        } else {
                            acc[sku] = { sku: sku, qty: curr.qty, type: 'collecteur' };
                        }
                        return acc;
                    }, {}),
                ) || [];
            setCollectorDataBySku(collectorDataBySkuRaw);

            const washerAddressRawData = await gearApi.washer.getWashers();
            const sorterAddressRawData = await getSorters();
            const collectorAddressRawData = await getCollectors();

            const collectorAddressRawDataCheck = getCollectorCheck(collectorAddressRawData);
            if (collectorAddressRawDataCheck.length && collectorAddressRawDataCheck !== true) {
                console.error(collectorAddressRawDataCheck);
                Sentry.captureException(collectorAddressRawDataCheck);
                navigate('/error');
                return;
            }

            const collectorAddress = AddressTab(collectorAddressRawData, 5);

            const sorterAddress = AddressTab(sorterAddressRawData, 2);
            const washerAddress = AddressTab(washerAddressRawData, 3);

            const allOperatorAddressRaw = [...sorterAddress, ...washerAddress, ...collectorAddress];
            setAllOperatorAddress(allOperatorAddressRaw);
        };
        fetchData();
    }, [navigate]);

    const AddressTab = (data) => {
        return data.map((objet) => {
            const lat = objet.lat ? objet.lat : objet.store_lat;
            const long = objet.long ? objet.long : objet.store_long;
            return { latitude: lat, longitude: long };
        });
    };

    // qty tt sku confondus
    const dataFinal = (data, type) => {
        const result = data.reduce(
            (acc, curr) => {
                acc.qty += curr.palet_nbcontainer;
                return acc;
            },
            { qty: 0, type: type },
        );
        return result;
    };
    // qty par sku
    const dataParSku = (data, type) => {
        const result = Object.values(
            data.reduce((acc, curr) => {
                if (acc[curr.sku]) {
                    acc[curr.sku].palet_nbcontainer += curr.palet_nbcontainer;
                } else {
                    acc[curr.sku] = { sku: curr.sku, palet_nbcontainer: curr.palet_nbcontainer, type: type };
                }
                return acc;
            }, {}),
        );
        return result;
    };
    // CONST GRAPH

    const dataInCirculationQuantities = allDataInCirculation.map((item) => item.qty);
    const dataInCirculationLabels = allDataInCirculation.map((item) => item.type);
    const sorterGraphQuantities = sorterDataBySku.map((item) => item.palet_nbcontainer);
    const washerGraphQuantities = washerDataBySku.map((item) => item.palet_nbcontainer);
    const collectorGraphQuantities = collectorDataBySku.map((item) => item.qty);
    const collectorGraphLabels = collectorDataBySku.map((item) => item.sku);
    const sorterGraphLabels = sorterDataBySku.map((item) => item.sku);
    const washerGraphLabels = washerDataBySku.map((item) => item.sku);
    return (
        <main>
            <div style={{ width: '100%', height: '50%', display: 'flex' }}>
                <div
                    className="graph-container"
                    style={{ width: '60%' }}>
                    <div className="top">Répartition volume tous sku confondus</div>
                    {allDataInCirculation.length &&
                    allDataInCirculation[0].qty !== 0 &&
                    allDataInCirculation[1].qty !== 0 &&
                    allDataInCirculation[2].qty !== 0 ? (
                        <div
                            style={{
                                width: '100%',
                                height: '100%',
                            }}>
                            <BarChart
                                title={dataInCirculationLabels}
                                quantities={dataInCirculationQuantities}
                            />
                        </div>
                    ) : (
                        <div className="no-data-graph">Aucune donnée.</div>
                    )}
                </div>
                <div
                    style={{ width: '40%' }}
                    className="graph-container">
                    <div className="top">Parc tous opérateurs confondus</div>
                    <div style={{ width: '100%', height: '100%' }}>
                        <ComposableMap
                            projection="geoMercator"
                            projectionConfig={{
                                scale: 2200,
                                center: [2.5, 46.5],
                            }}
                            style={{
                                width: '100%',
                                height: '100%',
                            }}>
                            <Geographies geography={franceData}>
                                {({ geographies }) =>
                                    geographies.map((geo) => (
                                        <Geography
                                            key={geo.rsmKey}
                                            geography={geo}
                                            fill="#EAEAEC"
                                            stroke="#D6D6DA"
                                        />
                                    ))
                                }
                            </Geographies>

                            {allOperatorAddress.map((position, index) => (
                                <Marker
                                    key={index}
                                    coordinates={[position.longitude, position.latitude]}>
                                    <circle
                                        cx={0}
                                        cy={0}
                                        r={5}
                                        fill="red"
                                    />
                                </Marker>
                            ))}
                        </ComposableMap>
                    </div>
                </div>
            </div>
            <div style={{ width: '100%', height: '50%', display: 'flex' }}>
                <div
                    className="graph-container"
                    style={{ flex: 1, height: '90%', width: '100px' }}>
                    <div className="top">Total en collecteur</div>
                    <div style={{ height: '90%' }}>
                        {collectorDataBySku.length ? (
                            <CircleChart
                                title={collectorGraphLabels}
                                quantities={collectorGraphQuantities}
                                position={'right'}
                            />
                        ) : (
                            <div className="no-data-graph">Aucune donnée.</div>
                        )}
                    </div>
                </div>
                <div
                    className="graph-container"
                    style={{ flex: 1, height: '90%', width: '100px' }}>
                    <div className="top">Total en centre de tri</div>
                    <div style={{ height: '90%' }}>
                        {sorterDataBySku.length ? (
                            <CircleChart
                                title={sorterGraphLabels}
                                quantities={sorterGraphQuantities}
                                position={'right'}
                            />
                        ) : (
                            <div className="no-data-graph">Aucune donnée.</div>
                        )}
                    </div>
                </div>
                <div
                    className="graph-container"
                    style={{ flex: 1, height: '90%', width: '100px' }}>
                    <div className="top">Total en centre de lavage</div>
                    <div style={{ height: '90%' }}>
                        {washerDataBySku.length ? (
                            <CircleChart
                                title={washerGraphLabels}
                                quantities={washerGraphQuantities}
                                position={'right'}
                            />
                        ) : (
                            <div className="no-data-graph">Aucune donnée.</div>
                        )}
                    </div>
                </div>
            </div>
        </main>
    );
}
