import React from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';

const BarChart = ({ title, quantities, label }) => {
    const colors = [
        'rgba(255, 99, 132, 0.6)', // Rouge
        'rgba(54, 162, 235, 0.6)', // B
        'rgba(255, 206, 86, 0.6)',
        'rgba(75, 192, 192, 0.6)',
        'rgba(153, 102, 255, 0.6)',
    ];

    const chartData = {
        labels: title,
        datasets: [
            {
                label: label ? label : 'Quantités',
                backgroundColor: colors.slice(0, quantities.length),
                borderColor: colors.slice(0, quantities.length),
                borderWidth: 1,
                hoverBackgroundColor: colors.slice(0, quantities.length).map((color) => color.replace('0.6', '0.8')),
                hoverBorderColor: colors.slice(0, quantities.length).map((color) => color.replace('0.6', '1')),
                data: quantities,
            },
        ],
    };

    return (
        <div style={{ height: '90%' }}>
            <Bar
                data={chartData}
                options={{
                    maintainAspectRatio: false,
                }}
            />
        </div>
    );
};

export default BarChart;
