import { useNavigate } from 'react-router-dom';
import { Jwt } from '../Jwt';
import * as Constants from '../Constants';

export default function ErrorPage() {
    const userContext = new Jwt();

    const currentModule = localStorage.getItem('module');
    const navigate = useNavigate();

    const goToOperations = (type) => {
        switch (type) {
            case Constants.ENTITY_TYPE_FS:
                return navigate(`/servicesupplier/operation/home`);
            case Constants.ENTITY_TYPE_INDUSTRIAL:
                return navigate(`/industrial/operation/home`);
            case Constants.ENTITY_TYPE_SORTER:
                return navigate('/sorter/operation/reception');
            case Constants.ENTITY_TYPE_WASHER:
                return navigate('/washer/operation/reception');
            case Constants.ENTITY_TYPE_OPERATOR:
                return navigate('/collecte/operation/list');
            case Constants.ENTITY_TYPE_RVMOWNER:
                return navigate('/rvmowner/operation/home');
            default:
                break;
        }
    };

    const goToData = (type) => {
        switch (type) {
            case Constants.ENTITY_TYPE_FS:
                return navigate(`/servicesupplier/data/home`);
            case Constants.ENTITY_TYPE_INDUSTRIAL:
                return navigate(`/industrial/data/home`);
            case Constants.ENTITY_TYPE_SORTER:
                return navigate('/');
            case Constants.ENTITY_TYPE_WASHER:
                return navigate('/');
            case Constants.ENTITY_TYPE_OPERATOR:
                return navigate('/');
            case Constants.ENTITY_TYPE_RVMOWNER:
                return navigate('/');
            default:
                break;
        }
    };

    const goToFacturation = (type) => {
        switch (type) {
            case Constants.ENTITY_TYPE_FS:
                return navigate(`/`);
            case Constants.ENTITY_TYPE_INDUSTRIAL:
                return navigate(`/`);
            case Constants.ENTITY_TYPE_SORTER:
                return navigate('/');
            case Constants.ENTITY_TYPE_WASHER:
                return navigate('/');
            case Constants.ENTITY_TYPE_OPERATOR:
                return navigate('/');
            default:
                break;
        }
    };

    const handlClick = (moduleId) => {
        if (moduleId === Constants.MODULE_OPERATION) {
            goToOperations(userContext.entityType);
        } else if (moduleId === Constants.MODULE_DATA) {
            goToData(userContext.entityType);
        } else {
            goToFacturation(userContext.entityType);
        }
    };

    return (
        <main style={{ width: '100%', height: '87.3vh', flex: 1, display: 'flex', justifyContent: 'center' }}>
            <div style={{ display: 'flex', flex: 1, paddingTop: '10%', fontSize: '1.25rem', flexDirection: 'column', alignItems: 'center' }}>
                <h2 style={{ paddingBottom: '3rem' }}>Oups, la page demandée n&apos;est pas disponible...</h2>
                <button
                    onClick={() => {
                        handlClick(currentModule);
                    }}
                    className="button-actif">
                    Retourner à la page d&apos;accueil
                </button>
            </div>
        </main>
    );
}
