import { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getOrderById, AssignOrderDetailToSupplier, AssignOrderDetailToWasher } from '../../../../API/OrderApi';
import { getAddressInfoById } from '../../../../API/IndustrialApi';
import {
    getWasherDeclarationDetailsByOrderDetailIdCheck,
    getWasherDeclarationNoAssignedBySkuCheck,
} from '../../../../API/CheckApi/CheckWasherDeclarationApi.js';
import { getSupplierOrderDetailsByOrderIdCheck } from '../../../../API/CheckApi/CheckSupplierOrderDetailsApi.js';
import { getListByskuCheck } from '../../../../API/CheckApi/CheckProductApi.js';
import { getAddressInfoByIdCheck } from '../../../../API/CheckApi/CheckIndustrialApi.js';
import { parseISO, getISOWeek, format } from 'date-fns';
import { getListBysku } from '../../../../API/ProductApi';
import { CaretLeft, CaretUp, CaretDown } from '@phosphor-icons/react';
import * as Constant from '../../../../Constants';
import * as Sentry from '@sentry/react';
import {
    supplierOrderDetailsCreate,
    updateSupplierOrderDetailsStatusReplaced,
    getSupplierOrderDetailsByOrderId,
} from '../../../../API/SupplierOrderDetailsApi';
import {
    getWasherDeclarationNoAssignedBySku,
    updateWasherDeclarationDetails,
    getWasherDeclarationDetailsByOrderDetailId,
} from '../../../../API/WasherDeclarationApi';
// import { getOperators } from '../../../../API/OperatorApi';

export default function Attribution() {
    const [orderInfos, setOrderInfos] = useState([]);
    const [addressInfos, setAddressInfos] = useState([]);
    const [supplierList, setSupplierList] = useState([]);
    const [showDispo, setShowDispo] = useState(true);
    const [dispoWashers, setDispoWashers] = useState([]);
    // const [operatorList, setOperatorList] = useState([]);
    // const [pickUpRequestTime, setPickUpRequestTime] = useState('');
    const [supplierAttribution, setSupplierAttribution] = useState({ name: null, qty: null });
    const [washerAttribution, setWasherAttribution] = useState([]);

    const location = useLocation();
    const navigate = useNavigate();
    const { item, company_name } = location.state || {};

    const dialogRef = useRef(null);

    const [supplierFormData, setSupplierFormData] = useState({
        supplier_product_id: null,
        nb_palette: item.qty,
        sku: item.sku,
        orderDetailId: item.id,
    });

    const [washerFormData, setWasherFormData] = useState([
        {
            orderDetailId: item.id,
            sku: item.sku,
            nb_palette: null,
            washerId: null,
            operatorId: null,
            industrialId: item.order.entity_id,
        },
    ]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getOrderById(item.order_id);

                setOrderInfos(data[0]);
                const addressInfo = await getAddressInfoById(item.entity_industrial_address_id);
                const addressInfoCheck = getAddressInfoByIdCheck(addressInfo);
                if (addressInfo.length && addressInfoCheck !== true) {
                    console.error(addressInfoCheck);
                    Sentry.captureException(addressInfoCheck);
                    navigate('/error');
                    return;
                }

                setAddressInfos(addressInfo[0]);

                const suppliers = !item.dateasupplier && !item.dateawasher ? await getListBysku(item.sku) : [];
                const suppliersCheck = getListByskuCheck(suppliers);
                if (suppliers.length && suppliersCheck !== true) {
                    console.error(suppliersCheck);
                    Sentry.captureException(suppliersCheck);
                    navigate('/error');
                    return;
                }
                setSupplierList(suppliers);

                const washerDeclarationAvailable = item.dateasupplier && !item.dateawasher ? await getWasherDeclarationNoAssignedBySku(item.sku) : [];
                const washerDeclarationAvailableCheck = getWasherDeclarationNoAssignedBySkuCheck(washerDeclarationAvailable);

                if (washerDeclarationAvailable.length && washerDeclarationAvailableCheck !== true) {
                    console.error(washerDeclarationAvailableCheck);
                    Sentry.captureException(washerDeclarationAvailableCheck);
                    navigate('/error');
                    return;
                }
                const dispoWasherRaw = washerDeclarationAvailable.length ? groupDataByWasherId(washerDeclarationAvailable) : [];
                setDispoWashers(dispoWasherRaw);
                // if already attributed, fetch informations
                if (item.dateasupplier) {
                    const supplierAttributedData = await getSupplierOrderDetailsByOrderId(item.id);
                    const supplierAttributedDataCheck = getSupplierOrderDetailsByOrderIdCheck(supplierAttributedData);
                    if (supplierAttributedData.length && supplierAttributedDataCheck !== true) {
                        console.error(supplierAttributedDataCheck);
                        Sentry.captureException(supplierAttributedDataCheck);
                        navigate('/error');
                        return;
                    }

                    if (supplierAttributedData.length) {
                        setSupplierAttribution({ name: supplierAttributedData[0].company_name, qty: supplierAttributedData.length });
                    }
                }
                if (item.dateawasher) {
                    const washerAttributedData = await getWasherDeclarationDetailsByOrderDetailId(item.id);
                    const washerAttributedDataCheck = getWasherDeclarationDetailsByOrderDetailIdCheck(washerAttributedData);
                    if (washerAttributedData.length && washerAttributedDataCheck !== true) {
                        console.error(washerAttributedDataCheck);
                        Sentry.captureException(washerAttributedDataCheck);
                        navigate('/error');
                        return;
                    }
                    const groupedData = washerAttributedData.reduce((acc, currentItem) => {
                        if (acc[currentItem.company_name]) {
                            acc[currentItem.company_name].qty++;
                        } else {
                            acc[currentItem.company_name] = {
                                company_name: currentItem.company_name,
                                qty: 1,
                            };
                        }
                        return acc;
                    }, {});
                    const groupedArray = Object.values(groupedData);
                    setWasherAttribution(groupedArray);
                }
            } catch (error) {
                console.error('Erreur lors de la récupération des infos de la commande', error);
            }
        };

        fetchData();
    }, [item, navigate]);

    function groupDataByWasherId(data) {
        const groupedData = data.reduce((acc, current) => {
            if (!acc[current.washer_id]) {
                acc[current.washer_id] = {
                    washerId: current.washer_id,
                    washerName: current.company_name,
                    totalQty: 0,
                    totalNbPalette: 0,
                };
            }

            acc[current.washer_id].totalQty += current.qty;
            acc[current.washer_id].totalNbPalette += current.palet_nbcontainer;

            return acc;
        }, {});

        return Object.values(groupedData);
    }

    const mettreAJourCommande = (index, field, value) => {
        const newData = [...washerFormData];
        newData[index][field] = value;
        setWasherFormData(newData);
    };

    // const handleChangeOperator = (operator) => {
    //     for (let i = 0; i < washerFormData.length; i++) {
    //         washerFormData[i].operatorId = parseInt(operator);
    //     }
    // };

    const ajouterLigne = () => {
        setWasherFormData([
            ...washerFormData,
            { orderDetailId: item.id, sku: item.sku, nb_palette: null, washerId: null, industrialId: item.order.entity_id, operatorId: null },
        ]);
    };

    const supprimerLigne = (index) => {
        const newData = [...washerFormData];
        newData.splice(index, 1);
        setWasherFormData(newData);
    };

    const getWeekNumber = (date) => {
        const dateISO = parseISO(date);
        const numeroSemaine = getISOWeek(dateISO);
        return numeroSemaine;
    };

    function calculateTotalQty(data) {
        let totalQty = 0;

        data.forEach((item) => {
            totalQty += parseInt(item.nb_palette);
        });
        return totalQty;
    }

    const handleChange = (e) => {
        const { name, value } = e.target;

        setSupplierFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async () => {
        if (!item.dateasupplier) {
            handleSubmitSupplier();
        } else {
            handleSubmitWasher();
        }
    };
    const handleSubmitWasher = async () => {
        for (let i = 0; i < washerFormData.length; i++) {
            if (!washerFormData[i].washerId || !washerFormData[i].nb_palette) {
                alert('Selectionner un centre de lavage et une quantité');
            } else if (
                parseInt(supplierInputValue) < 0 ||
                parseInt(supplierInputValue) > item.qty ||
                calculateTotalQty(washerFormData) + parseInt(supplierInputValue) !== parseInt(finalInputValue)
            ) {
                alert('Selectionner une quantité valide');
            } else {
                // const operators = await getOperators();
                // setOperatorList(operators);

                if (dialogRef.current) {
                    dialogRef.current.showModal();
                }
            }
        }
    };

    const handleSubmitSupplier = async () => {
        if (!supplierFormData.supplier_product_id || !supplierFormData.nb_palette) {
            alert('Selectionner un verrier ');
        } else {
            if (dialogRef.current) {
                dialogRef.current.showModal();
            }
        }
    };

    const handleModalConfirm = async () => {
        if (item.dateasupplier && !item.dateawasher) {
            for (let i = 0; i < washerFormData.length; i++) {
                sendWasherConfirmation(washerFormData[i]);
            }
        } else {
            sendSupplierConfirmation();
        }
        if (dialogRef.current) {
            dialogRef.current.close();
        }
    };
    const handleModalCancel = () => {
        if (dialogRef.current) {
            dialogRef.current.close();
        }
    };

    const sendSupplierConfirmation = async () => {
        const currentDate = new Date();
        const formattedDate = format(currentDate, 'yyyy-MM-dd HH:mm:ss');
        try {
            const orderDetailsResponse = await supplierOrderDetailsCreate(supplierFormData);

            if (orderDetailsResponse.message !== 'ok') {
                alert('Une erreur est survenue');
                return;
            }

            const assignOrderResponse = await AssignOrderDetailToSupplier(supplierFormData, formattedDate);
            if (!assignOrderResponse) {
                alert('Une erreur est survenue');
                return;
            }

            alert("L'attribution a été effectuée avec succès");
            navigate(-1);
        } catch (error) {
            console.log(error);
        }
    };

    const sendWasherConfirmation = async (itemWasherFormData) => {
        // ATTRIBUTE A DEFAULT OPERATOR AND STATUS 2 FOR THE DEMO
        // if (!itemWasherFormData.operatorId || !pickUpRequestTime) {
        //     alert('veuillez selectionner un transporteur et une date de collecte');
        //     return;
        // }

        try {
            const { gearApi } = await import('../../../../gearApi.js');
            const { endOfDay } = await import('date-fns');
            const hostname = window.location.hostname;

            // https://github.com/Bocolocoo/gear/issues/572
            let operator;
            if (hostname === 'localhost') {
                console.log('localhost');
                operator = 81;
            } else if (hostname === 'gear.demo.bocotech.fr') {
                console.log('demo');
                operator = 81;
            } else if (hostname === 'gear.stag.bocotech.fr') {
                console.log('stag');
                operator = 19;
            } else if (hostname === 'gear.bocotech.fr') {
                console.log('prod');
                operator = 2;
            } else {
                console.log('default');
                operator = 81;
            }
            const pickUpData = await gearApi.washer.putSuiviToEventsForWashers({
                washerId: itemWasherFormData.washerId,
                // ATTRIBUTE A DEFAULT OPERATOR AND STATUS 2 FOR THE DEMO
                requestBody: {
                    industrialId: itemWasherFormData.industrialId,
                    // operatorId: itemWasherFormData.operatorId,
                    operatorId: operator,
                    // Assuming that we have the day selected as our last chance, not the preivous day
                    // time: endOfDay(pickUpRequestTime),
                    time: endOfDay(item.dated),
                    status: Constant.EVENT_DONE,
                },
            });

            if (!pickUpData) {
                alert('Une erreur est survenue lors de la demande de collecte');
                return;
            }

            const washerDeclarationUpdate = await updateWasherDeclarationDetails(itemWasherFormData, pickUpData.suiviId);
            if (washerDeclarationUpdate.message !== 'ok') {
                alert('Une erreur est survenue lors de la mise à jour des détails de la déclaration');
                return;
            }

            const supplierOrderUpdate = await updateSupplierOrderDetailsStatusReplaced(itemWasherFormData);
            if (supplierOrderUpdate.message !== 'ok') {
                alert('Une erreur est survenue lors de la mise à jour des détails de supplier order');
                return;
            }

            const dateActuelle = new Date();
            const assignOrderDetail = await AssignOrderDetailToWasher(itemWasherFormData, format(dateActuelle, 'yyyy-MM-dd HH:mm:ss'));
            if (assignOrderDetail) {
                alert('La demande de collecte a été faite avec succès');
                navigate(-1);
            } else {
                alert('Une erreur est survenue lors de la mise à jour des détails de la déclaration');
            }
        } catch (error) {
            console.error('Une erreur est survenue :', error);
        }
    };
    const supplierInputValue = calculateTotalQty(washerFormData)
        ? supplierFormData.nb_palette - calculateTotalQty(washerFormData)
        : supplierAttribution.qty
          ? supplierAttribution.qty
          : !supplierAttribution.qty && washerAttribution.length
            ? 0
            : item.qty;

    const finalInputValue = calculateTotalQty(washerFormData)
        ? calculateTotalQty(washerFormData) + (supplierFormData.nb_palette - calculateTotalQty(washerFormData))
        : item.qty;

    return (
        <main>
            <div className="header-page">
                <div
                    className="back"
                    onClick={() => {
                        navigate(-1);
                    }}>
                    <div className="icon">
                        <CaretLeft
                            size={20}
                            color={Constant.$blanc}
                        />
                    </div>
                    <p>{company_name}</p>
                </div>
            </div>
            {orderInfos.id && item && (
                <section className="small-section">
                    <h2>Attributions</h2>
                    <div className="tab-item">
                        <div className="pre-filled-fields-1">
                            <p>
                                Date de livraison :{' '}
                                <span style={{ fontWeight: 400, color: Constant.$grisfonce }}>
                                    Semaine {getWeekNumber(item.dated)} - {format(item.dated, 'yyyy-MM-dd')}
                                </span>
                            </p>
                        </div>
                        <div className="pre-filled-fields-1">
                            <p>
                                À : <span style={{ fontWeight: 400, color: Constant.$grisfonce }}>{addressInfos.name}</span>{' '}
                            </p>
                        </div>
                        <div className="pre-filled-fields-1">
                            <p>
                                SKU : <span style={{ fontWeight: 400, color: Constant.$grisfonce }}>{item.sku}</span>{' '}
                            </p>
                        </div>
                        <div className="pre-filled-fields-1">
                            <p>
                                Quantité :{' '}
                                <span style={{ fontWeight: 400, color: Constant.$grisfonce }}>
                                    {item.qty} palettes - {item.qtyTotal} contenants
                                </span>
                            </p>
                        </div>
                    </div>
                    {}
                    <div className="attribution">
                        <h6>Attribution Verrier</h6>
                        <div style={{ display: 'flex', gap: '1.3rem', marginBottom: '1rem' }}>
                            {supplierList.length || supplierAttribution.name ? (
                                <select
                                    disabled={supplierAttribution.name || (washerAttribution.length && true)}
                                    style={{ width: '50%' }}
                                    onChange={handleChange}
                                    name="supplier_product_id">
                                    <option>
                                        {supplierAttribution.name
                                            ? supplierAttribution.name
                                            : washerAttribution.length && !supplierAttribution.length
                                              ? 'Aucun'
                                              : 'Choisissez un fournisseur'}
                                    </option>
                                    {supplierList.map((option, index) => (
                                        <option
                                            key={index}
                                            value={option.id}>
                                            {option.supplier_name}
                                        </option>
                                    ))}
                                </select>
                            ) : (
                                <input
                                    type="text"
                                    disabled
                                    value={'Aucun fournisseur disponible.'}
                                />
                            )}

                            <input
                                disabled
                                type="number"
                                name="nb_palette"
                                value={washerAttribution.length && !supplierAttribution ? 0 : supplierInputValue}
                                className={parseInt(supplierInputValue) < 0 || parseInt(supplierInputValue) > item.qty ? 'input-invalide' : ''}
                                max={item.qty}
                                min={0}
                                onChange={handleChange}
                                required
                                placeholder="Indiquer le nombre"
                            />
                        </div>
                    </div>
                    {!item.dateawasher && item.dateasupplier ? (
                        <div className="attribution">
                            <div style={{ display: 'flex', alignItems: 'center', marginTop: '0.56rem' }}>
                                <h6>Disponibilités laveur</h6>
                                {showDispo ? (
                                    <CaretUp
                                        onClick={() => {
                                            setShowDispo(false);
                                        }}
                                        style={{ cursor: 'pointer' }}
                                        size={15}
                                    />
                                ) : (
                                    <CaretDown
                                        onClick={() => {
                                            setShowDispo(true);
                                        }}
                                        style={{ cursor: 'pointer' }}
                                        size={15}
                                    />
                                )}
                            </div>
                            {showDispo ? (
                                <div className="attribution-washer">
                                    {dispoWashers.length ? (
                                        dispoWashers.map((item, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className="input-container">
                                                    <input
                                                        disabled
                                                        type="text"
                                                        value={item.washerName || ''}
                                                    />
                                                    <input
                                                        disabled
                                                        type="text"
                                                        value={item.totalQty || ''}
                                                    />
                                                </div>
                                            );
                                        })
                                    ) : (
                                        <p
                                            style={{
                                                fontSize: '0.75rem',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                paddingBottom: '0.56rem',
                                                paddingLeft: '0.87rem',
                                                fontWeight: 'bolder',
                                                color: Constant.$rouge,
                                            }}>
                                            Aucune
                                        </p>
                                    )}
                                </div>
                            ) : null}
                            {dispoWashers.length ? (
                                <div className="attribution">
                                    <h6 style={{ marginTop: '0.56rem' }}>Attribution laveur</h6>
                                    {washerFormData.map((ligne, index) => (
                                        <div
                                            key={index}
                                            className="line-container"
                                            style={{ gap: '1.3rem' }}>
                                            <select onChange={(e) => mettreAJourCommande(index, 'washerId', e.target.value)}>
                                                <option>Choisissez une option</option>
                                                {dispoWashers.map((option, index) => (
                                                    <option
                                                        key={index}
                                                        value={option.washerId}>
                                                        {option.washerName}
                                                    </option>
                                                ))}
                                            </select>

                                            <input
                                                type="number"
                                                value={ligne.nb_palette || ''}
                                                onChange={(e) => mettreAJourCommande(index, 'nb_palette', e.target.value)}
                                            />
                                            <div className="add-ligne">
                                                {washerFormData.length > 1 && <button onClick={() => supprimerLigne(washerFormData.length - 1)}>-</button>}
                                                <button onClick={ajouterLigne}>+</button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ) : null}
                        </div>
                    ) : null}
                    {item.dateawasher && washerAttribution.length ? (
                        <div className="attribution">
                            <h6 style={{ marginTop: '0.56rem' }}>Attribution laveur</h6>
                            {washerAttribution.map((ligne, index) => (
                                <div
                                    key={index}
                                    className="line-container"
                                    style={{ gap: '1.3rem' }}>
                                    <select disabled>
                                        <option>{ligne.company_name}</option>
                                    </select>

                                    <input
                                        style={{ width: '30%' }}
                                        disabled
                                        type="number"
                                        value={ligne.qty || ''}
                                    />
                                </div>
                            ))}
                        </div>
                    ) : null}
                    {!item.dateasupplier || !item.dateawasher ? (
                        <div style={{ display: 'flex', justifyContent: 'end', gap: '1.3rem', marginTop: '3.9rem', alignItems: 'center' }}>
                            <div
                                className="pre-filled-fields-1"
                                style={{ width: '6.62rem' }}>
                                <p>{finalInputValue}</p>
                            </div>
                            <button
                                className="button-actif"
                                onClick={handleSubmit}>
                                VALIDER
                            </button>
                        </div>
                    ) : null}
                </section>
            )}

            <dialog
                ref={dialogRef}
                className="dialog-collector">
                <div className="title">
                    <p>Confirmer l&apos;attribution</p>
                    <button
                        className="button-negatif"
                        onClick={handleModalCancel}>
                        X
                    </button>
                </div>
                {/* SKIP THIS PART FOR THE DEMO */}
                {/* {item.dateasupplier && !item.dateawasher ? (
                    <>
                        <div className="collector-date">
                            <label>Transporteur :</label>
                            <select
                                onChange={(e) => {
                                    handleChangeOperator(e.target.value);
                                }}>
                                <option value="">Choisir</option>
                                {operatorList.length &&
                                    operatorList.map((operator, index) => (
                                        <option
                                            key={index}
                                            value={operator.id}>
                                            {operator.name}
                                        </option>
                                    ))}
                            </select>
                        </div>
                        <div
                            className="collector-date"
                            style={{ border: '0px' }}>
                            <label>Date limite de prise en charge :</label>
                            <input
                                min={format(new Date(), 'yyyy-MM-dd')}
                                max={format(item.dated, 'yyyy-MM-dd')}
                                type="date"
                                onChange={(e) => {
                                    setPickUpRequestTime(e.target.value);
                                }}
                            />
                        </div>
                    </>
                ) : null} */}
                <div style={{ display: 'flex', marginTop: 0 }}>
                    {!item.dateasupplier && !item.dateawasher ? (
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                            <p>Ceci est une attribution provisoire.</p>
                            <p>Êtes-vous sûr.e de vouloir valider ?</p>
                        </div>
                    ) : (
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                            <p>Ceci est une attribution définitive.</p>
                            <p>Êtes-vous sûr.e de vouloir valider ?</p>
                        </div>
                    )}
                </div>

                <form
                    method="dialog"
                    style={{ justifyContent: 'space-between' }}>
                    <button
                        className="button-negatif"
                        onClick={handleModalCancel}>
                        ANNULER
                    </button>
                    <button
                        className="button-actif"
                        onClick={handleModalConfirm}>
                        VALIDER
                    </button>
                </form>
            </dialog>
        </main>
    );
}
