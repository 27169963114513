// STORE API
export const getStoreByRvmownerIdCheck = (data) => {
    const name = 'getStoreByRvmownerId : ';
    for (let i = 0; i < data.length; i++) {
        if (!data[i].id) {
            return name + 'No id found';
        } else if (data[i].collectors) {
            for (let j = 0; j < data[i].collectors.length; j++) {
                if (!data[i].collectors[j].id) {
                    return name + 'No collector id found';
                } else {
                    return true;
                }
            }
        } else {
            return true;
        }
    }
};

export const getStoreByCollectorIdCheck = (data) => {
    const name = 'getStoreByCollectorId : ';
    for (let i = 0; i < data.length; i++) {
        if (!data[i].id) {
            return name + 'No id found';
        } else if (data[i].collectors.id) {
            return name + 'No collector id found';
        } else {
            return true;
        }
    }
};
