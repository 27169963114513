import React from 'react';
import { Pie } from 'react-chartjs-2';
import 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const PieChart = ({ title, quantities, position }) => {
    const colors = [
        'rgba(255, 99, 132, 0.6)', // Rouge
        'rgba(54, 162, 235, 0.6)', // Bleu
        'rgba(255, 206, 86, 0.6)', // Jaune
        'rgba(75, 192, 192, 0.6)', // Vert
        'rgba(153, 102, 255, 0.6)', // Violet
        'rgba(255, 159, 64, 0.6)', // Orange
        'rgba(0, 255, 0, 0.6)', // Green
        'rgba(0, 0, 255, 0.6)', // Blue
        'rgba(255, 255, 0, 0.6)', // Yellow
        'rgba(255, 0, 255, 0.6)', // Magenta
    ];

    const chartData = {
        labels: title,
        datasets: [
            {
                // label: 'Quantités',
                backgroundColor: colors.slice(0, quantities.length), // Utilisation des couleurs définies
                borderColor: colors.slice(0, quantities.length),
                borderWidth: 1,
                hoverBackgroundColor: colors.slice(0, quantities.length).map((color) => color.replace('0.6', '0.8')), // Augmenter l'opacité au survol
                hoverBorderColor: colors.slice(0, quantities.length).map((color) => color.replace('0.6', '1')), // Augmenter l'opacité de la bordure au survol
                data: quantities,
            },
        ],
    };

    const options = {
        maintainAspectRatio: false,
        plugins: {
            datalabels: {
                color: 'black',
                // anchor: 'end',
                align: 'end',
                formatter: function (value) {
                    const valueNumber = parseFloat(value);

                    return valueNumber;
                },
            },
            legend: {
                display: true,
                position: position ? position : 'top',
            },
        },
    };

    return (
        <div style={{ height: '90%' }}>
            <Pie
                data={chartData}
                options={options}
                plugins={[ChartDataLabels]}
            />
        </div>
    );
};

export default PieChart;

// POUR AVOIR LA LEGENDE ALIGNER SUR LE COTE DU GRAPHIQUE
// import React from 'react';
// import { Pie } from 'react-chartjs-2';
// import 'chart.js/auto';

// const PieChart = ({ title, quantities }) => {
//     const colors = [
//         'rgba(255, 99, 132, 0.6)', // Rouge
//         'rgba(54, 162, 235, 0.6)', // Bleu
//         'rgba(255, 206, 86, 0.6)', // Jaune
//         'rgba(75, 192, 192, 0.6)', // Vert
//         'rgba(153, 102, 255, 0.6)', // Violet
//         'rgba(255, 159, 64, 0.6)', // Orange
//         // Vous pouvez ajouter d'autres couleurs au besoin
//     ];

//     const chartData = {
//         labels: title,
//         datasets: [
//             {
//                 label: 'Quantités',
//                 backgroundColor: colors.slice(0, quantities.length), // Utilisation des couleurs définies
//                 borderColor: colors.slice(0, quantities.length),
//                 borderWidth: 1,
//                 hoverBackgroundColor: colors.slice(0, quantities.length).map((color) => color.replace('0.6', '0.8')), // Augmenter l'opacité au survol
//                 hoverBorderColor: colors.slice(0, quantities.length).map((color) => color.replace('0.6', '1')), // Augmenter l'opacité de la bordure au survol
//                 data: quantities,
//             },
//         ],
//     };

//     const legendItems = chartData.labels.map((label, index) => ({
//         text: `${label}: ${chartData.datasets[0].data[index]}`,
//         color: chartData.datasets[0].backgroundColor[index],
//     }));

//     return (
//         <div style={{ display: 'flex' }}>
//             <div style={{ flex: 1 }}>
//                 <Pie
//                     data={chartData}
//                     options={{
//                         maintainAspectRatio: false,
//                         plugins: {
//                             legend: {
//                                 display: false, // Désactiver la légende automatique
//                             },
//                         },
//                     }}
//                 />
//             </div>
//             <div style={{ marginLeft: '20px' }}>
//                 {legendItems.map((item, index) => (
//                     <div
//                         key={index}
//                         style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
//                         <div style={{ width: '40px', height: '10px', backgroundColor: item.color, marginRight: '5px' }}></div>
//                         <span>{item.text}</span>
//                     </div>
//                 ))}
//             </div>
//         </div>
//     );
// };

// export default PieChart;
