import { useLocation } from 'react-router-dom';
import * as Constant from '../Constants';

export default function Footer() {
    const location = useLocation();
    const isLeftMenuHided =
        location.pathname === '/support' ||
        location.pathname === '/documentation' ||
        location.pathname === '/myprofil' ||
        location.pathname === '/updatepassword';
    const isPreHome = location.pathname === '/prehome';
    const isLogin = location.pathname === '/login';
    const isError = location.pathname === '/error';
    const isSplashScreen = location.pathname === '/';
    const isPassword = location.pathname.includes('password');

    return (
        <footer className={!isPreHome ? 'footer' : 'footer footer-prehome'}>
            <div
                className="left"
                style={{
                    backgroundColor:
                        isLogin || isPreHome || isSplashScreen || isPassword ? 'transparent' : isLeftMenuHided || isError ? Constant.$blancGris : '',
                }}></div>
            <div
                className="right"
                style={{
                    backgroundColor:
                        isLogin || isPreHome || isSplashScreen || isPassword ? 'transparent' : isLeftMenuHided || isError ? Constant.$blancGris : '',
                }}></div>
            <p style={{ color: isLogin || isPreHome || isSplashScreen || isPassword ? Constant.$blanc : '' }}>
                <span style={{ fontSize: '100%' }}>&copy;</span> Bocoloco 2024
            </p>
        </footer>
    );
}
