import React, { useState, useRef } from 'react';
import { useEffect } from 'react';
import { washerDeclarationCreate } from '../../../API/WasherDeclarationApi';
import parser from 'papaparse';
import { UploadSimple } from '@phosphor-icons/react';
import CSVDownloader from '../../../Components/CSVDownloader';
import { Jwt } from '../../../Jwt';
import { getContainerBySku } from '../../../API/ContainerApi';
import * as Constant from '../../../Constants';
export default function WasherDeclaration() {
    const userContext = new Jwt();
    const [importMsg, setImportMsg] = useState('');
    const [formOk, setFormOk] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);
    const [containerList, setContainerList] = useState([]);
    const [fileName, setFileName] = useState(null);
    const csvName = 'declaration_lavage';
    const headers = ['sku', 'nb_palette', 'rebus'];

    const [declarationDetailsData, setDeclarationDetailsData] = useState([
        { sku: '', nb_palette: 0, palet_nbcontainer: 0, rebus: 0, perte: 0 },
    ]);

    const dialogRef = useRef(null);

    useEffect(() => {
        setImportMsg('');

        const fetchContainer = async () => {
            const { gearApi } = await import('../../../gearApi.js');
            const data = await gearApi.washer.getSkusOfWasher({ washerId: userContext.entityId });
            setContainerList(data);
        };
        fetchContainer();
    }, [userContext.entityId]);

    const ajouterLigne = () => {
        setDeclarationDetailsData([
            ...declarationDetailsData,
            { sku: '', nb_palette: 0, palet_nbcontainer: 0, rebus: 0, perte: 0 },
        ]);
    };

    const mettreAJourCommande = (index, field, value) => {
        const newData = [...declarationDetailsData];
        newData[index][field] = value;
        setDeclarationDetailsData(newData);
    };

    const mettreAJourCommande2 = async (index, field1, value) => {
        const getSkuInfos = await getContainerBySku(value);
        const newData = [...declarationDetailsData];
        newData[index][field1] = value;
        newData[index]['palet_nbcontainer'] = getSkuInfos[0].palet_nbcontainer;

        setDeclarationDetailsData(newData);
    };

    const envoyerCommandeAuServeur = async () => {
        const skuSet = new Set();
        let isSkuUnique = true;
        let qtyValid = true;
        declarationDetailsData.forEach((item) => {
            if (item.nb_palette === 0 || item.sku === '') {
                qtyValid = false;
            }
            if (skuSet.has(item.sku)) {
                isSkuUnique = false;
            } else {
                skuSet.add(item.sku);
            }
        });
        if (!qtyValid) {
            setImportMsg('Erreur : Veuillez renseigner un sku et une quantité valide pour chaque ligne.');
            return;
        }

        if (!isSkuUnique) {
            setImportMsg('Erreur : Merci de renseigner une ligne par SKU.');
            return;
        }

        const response = await washerDeclarationCreate(declarationDetailsData, userContext.entityId, userContext.userId);
        if (response) {
            alert('Déclaration enregistrée!');
            window.location.reload();
        } else {
            alert('Une erreur est survenue.');
        }
    };

    const supprimerLigne = (index) => {
        const newData = [...declarationDetailsData];
        newData.splice(index, 1);
        setDeclarationDetailsData(newData);
    };

    const handleModalOpen = () => {
        if (dialogRef.current) {
            setModalOpen(true);

            dialogRef.current.showModal();
        }
    };

    const handleModalClose = () => {
        setImportMsg('');
        setFileName(null);
        if (dialogRef.current) {
            dialogRef.current.close();
        }
    };

    const importHandler = (e) => {
        setImportMsg('Import in progress');
        e.preventDefault();
        var file = e.target.files[0];
        setFileName(file.name);
        parser.parse(e.target.files[0], {
            header: true,
            skipEmptyLines: true,
            complete: callBackHandleImportParse,
        });
    };

    const callBackHandleImportParse = (results) => {
        if (checkDataValidity(results)) {
            ConvertCsvToForm(results);
        }
    };

    const checkDataValidity = (results) => {
        var i = 0;
        if (checkHeaderValidity(results.meta.fields)) {
            for (i = 0; i < results.data.length; i++) {
                checkLineValidity(i, results.data[i]);
            }
        } else {
            console.log('HEADER - Unvalid');
        }

        setImportMsg('DATA Validity check finished !');
        return 1;
    };

    const checkLineValidity = (i, resultLine) => {
        var ret = 1;
        if (resultLine.sku.length > 32) {
            console.log('LINE' + i + ' - column SKU - unvalid');
            ret = 0;
        }

        if (resultLine.nb_palette > 10000) {
            console.log('LINE' + i + ' - column QUANTITY - unvalid');
            ret = 0;
        }

        if (resultLine.date === '') {
            console.log('LINE' + i + ' - column REBUS - unvalid');
            ret = 0;
        }

        return ret;
    };

    const checkHeaderValidity = (fields) => {
        var ret = 1;

        if (fields.length > 2) {
            console.log('HEADER - too many columns');
            ret = 0;
        }

        if (fields[0] !== 'sku') {
            console.log('HEADER - column 1 - unvalid');
            ret = 0;
        }

        if (fields[1] !== 'nb_palette') {
            console.log('HEADER - column 2 - unvalid');
            ret = 0;
        }

        return ret;
    };

    const ConvertCsvToForm = async (results) => {
        let newData = [];
        let skuSet = new Set();
        for (let i = 0; i < results.data.length; i++) {
            const skuInfo = await getContainerBySku(results.data[i].sku);
            if (!containerList.some((option) => option.sku === results.data[i].sku)) {
                setImportMsg('Erreur: Le SKU ' + results.data[i].sku + " n'existe pas.");
                setFormOk(false);
                return;
            }
            if (skuSet.has(results.data[i].sku)) {
                setImportMsg('Erreur : Merci de renseigner une ligne par SKU.');
                setFormOk(false);
                return;
            }
            skuSet.add(results.data[i].sku);
            newData.push({
                sku: results.data[i].sku,
                nb_palette: results.data[i].nb_palette,
                rebus: results.data[i].rebus,
                palet_nbcontainer: skuInfo[0].palet_nbcontainer,
                perte: ((results.data[i].rebus / (skuInfo[0].palet_nbcontainer * results.data[i].nb_palette)) * 100).toFixed(2),
            });
        }

        setDeclarationDetailsData(newData);
        setImportMsg('CSV converti avec succès !');
        setFormOk(true);
    };

    return (
        <main>
            <section className="big-section">
                <div className="top">
                    <h2>Déclarer un lavage</h2>
                    <button
                        onClick={handleModalOpen}
                        className="button-actif">
                        DÉCLARER PAR CSV
                    </button>
                </div>

                <div className="declaration-container">
                    <div className="tab-item">
                        <div
                            className="pre-filled-fields-1-several-item"
                            style={{ fontWeight: '600' }}>
                            <p>SKU</p>
                            <p>Palettes lavées</p>
                            <p>Equivalent contenants</p>
                            <p>Contenants rebus</p>
                            <p>% Perte</p>
                        </div>
                        {declarationDetailsData.map((ligne, index) => (
                            <div
                                key={index}
                                className="line-container">
                                {containerList.length ? (
                                    <select onChange={(e) => mettreAJourCommande2(index, 'sku', e.target.value)}>
                                        <option>Choisissez une option</option>
                                        {containerList.map((option, index) => (
                                            <option
                                                key={index}
                                                value={option.sku}
                                                selected={ligne.sku === option.sku ? 'selected' : ''}>
                                                {option.sku}
                                            </option>
                                        ))}
                                    </select>
                                ) : (
                                    <input
                                        style={{ color: Constant.$rouge }}
                                        type="text"
                                        disabled
                                        value={'Aucun SKU référencé.'}
                                    />
                                )}

                                <input
                                    type="number"
                                    value={ligne.nb_palette || 0}
                                    onChange={(e) => mettreAJourCommande(index, 'nb_palette', e.target.value)}
                                />
                                <input
                                    disabled
                                    style={{ backgroundColor: Constant.$grisclair, color: Constant.$blanc, opacity: '0.9', fontWeight: 'bolder' }}
                                    type="number"
                                    value={ligne.palet_nbcontainer * ligne.nb_palette || 0}
                                    onChange={(e) => mettreAJourCommande(index, 'palet_nbcontainer', e.target.value)}
                                />
                                <input
                                    type="number"
                                    value={ligne.rebus || 0}
                                    onChange={(e) => mettreAJourCommande(index, 'rebus', e.target.value)}
                                />
                                <input
                                    disabled
                                    style={{ backgroundColor: Constant.$grisclair, color: Constant.$blanc, opacity: '0.9', fontWeight: 'bolder' }}
                                    type="text"
                                    value={(ligne.rebus && ((ligne.rebus / (ligne.palet_nbcontainer * ligne.nb_palette)) * 100).toFixed(2)) || 0}
                                    onChange={(e) => mettreAJourCommande(index, 'perte', e.target.value)}
                                />
                            </div>
                        ))}
                    </div>
                    <div className="add-ligne">
                        {declarationDetailsData.length > 1 && <button onClick={() => supprimerLigne(declarationDetailsData.length - 1)}>-</button>}
                        <button onClick={ajouterLigne}>+</button>
                    </div>
                    <div className="save">
                        {!modalOpen && importMsg && <p style={{ color: Constant.$rouge, fontWeight: 'bolder' }}>{importMsg}</p>}
                        <button
                            className="button-actif"
                            onClick={envoyerCommandeAuServeur}>
                            DÉCLARER
                        </button>
                    </div>
                </div>
            </section>
            <dialog
                ref={dialogRef}
                className="dialog-collector">
                <div className="title">
                    <p>Importer un fichier</p>
                    <button
                        className="button-negatif"
                        onClick={handleModalClose}>
                        X
                    </button>
                </div>
                <div className="modal-declaration">
                    {!fileName ? (
                        <>
                            <CSVDownloader
                                fileName={csvName}
                                headers={headers}
                            />
                            <div className="custom-file-upload">
                                <input
                                    id="fileInput"
                                    type={'file'}
                                    accept={'.csv'}
                                    onChange={importHandler}
                                />
                                <label htmlFor="fileInput">
                                    <span>
                                        <UploadSimple size={32} />
                                    </span>
                                    Importer un fichier CSV
                                </label>
                            </div>
                        </>
                    ) : (
                        <div style={{ width: '100%' }}>
                            {importMsg && <p style={{ paddingBottom: '1rem' }}>{importMsg}</p>}
                            <p>Fichier : {fileName}</p>
                            <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '2rem', width: '100%' }}>
                                <button
                                    className="button-negatif"
                                    onClick={() => {
                                        setFileName(null);
                                    }}>
                                    RECOMMENCER
                                </button>
                                {formOk && (
                                    <button
                                        onClick={handleModalClose}
                                        className="button-actif">
                                        VALIDER
                                    </button>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </dialog>
        </main>
    );
}
