import { useState, useEffect } from 'react';
import { getAllReturnInCollector } from '../../../API/CollectorReturnApi';
import { getAllReturnInCollectorCheck } from '../../../API/CheckApi/CheckCollectorReturnApi';
import { getDetailsLastSorterEventStatus } from '../../../API/SorterDeclarationApi';
import { getDetailsLastWasherEventStatus } from '../../../API/WasherDeclarationApi';
import franceData from '../../../Assets/data/france.json';
import { ComposableMap, Geographies, Geography, Marker } from 'react-simple-maps';
import CircleChart from '../../../Components/CircleChart';
import BarChartMulti from '../../../Components/BarChartMulti';
import { getSorters } from '../../../API/SorterApi';
import { getCollectors } from '../../../API/CollectorApi';
import { getDetailsLastWasherEventStatusCheck } from '../../../API/CheckApi/CheckWasherDeclarationApi';
import { getDetailsLastSorterEventStatusCheck } from '../../../API/CheckApi/CheckSorterDeclarationApi';
import { getCollectorCheck } from '../../../API/CheckApi/CheckCollectorApi';
import { getIndustrials } from '../../../API/IndustrialApi';
import * as Constants from '../../../Constants';
import { getAllOrdersDetails } from '../../../API/OrderApi';
import { transformDataEmpliee } from '../../../UtilsData.js';
import * as Sentry from '@sentry/react';
import { useNavigate } from 'react-router-dom';
export default function ServiceSupplierHome() {
    const navigate = useNavigate();
    const [allDataInCirculation, setAllDataInCirculation] = useState([]);
    const [totalIncirculation, setTotalInCirculation] = useState(0);
    const [allOperatorAddress, setAllOperatorAddress] = useState([]);
    const [totalByType, setTotalByType] = useState({ indus: 0, sorter: 0, washer: 0, collector: 0 });
    const [indusVsWasher, setIndusVsWasher] = useState([]);
    const [notifications, setNotifications] = useState([]);
    const [indusVsSorterTotal, setIndusVsSorterTotal] = useState({ indus: 0, washer: 0 });
    useEffect(() => {
        const fetchData = async () => {
            const { gearApi } = await import('../../../gearApi.js');

            // JE REGARDE OU SONT LES CONTENANTS ACTUELLEMENT
            const dataInCollector = await getAllReturnInCollector();
            const dataInCollectorCheck = getAllReturnInCollectorCheck(dataInCollector);
            if (dataInCollector.length && dataInCollectorCheck !== true) {
                console.error(dataInCollectorCheck);
                Sentry.captureException(dataInCollectorCheck);
                navigate('/error');
                return;
            }
            const dataInSorter = await getDetailsLastSorterEventStatus();
            const dataInSorterCheck = getDetailsLastSorterEventStatusCheck(dataInSorter);
            if (dataInSorter.length && dataInSorterCheck !== true) {
                console.error(dataInSorterCheck);
                Sentry.captureException(dataInSorterCheck);
                navigate('/error');
                return;
            }
            const dataInSorterFiltered = dataInSorter.filter(
                (item) =>
                    item.type === Constants.DECLARATION_DETAILS_TYPE_OK &&
                    (!item.suiviID || (item.suivi && item.suivi.sorterEvents[0].status !== Constants.EVENT_RECEIVED)),
            );
            const dataInWasher = await getDetailsLastWasherEventStatus();
            const dataInWasherCheck = getDetailsLastWasherEventStatusCheck(dataInWasher);
            if (dataInWasher.length && dataInWasherCheck !== true) {
                console.error(dataInWasherCheck);
                Sentry.captureException(dataInWasherCheck);
                navigate('/error');
                return;
            }
            const dataInWasherFiltered = dataInWasher.filter(
                (item) =>
                    item.type === Constants.DECLARATION_DETAILS_TYPE_OK &&
                    (!item.suiviID || (item.suivi && item.suivi.washerEvents[0].status !== Constants.EVENT_RECEIVED)),
            );
            // REPARTITION DES CONTENANTS PAR SKU
            const sorterDataBySkuRaw = dataParSku(dataInSorterFiltered, 'centre de tri');
            const washerDataBySkuRaw = dataParSku(dataInWasherFiltered, 'centre de lavage');

            const collectorDataBySkuRaw =
                Object.values(
                    dataInCollector.reduce((acc, curr) => {
                        const sku = curr.entityIndustrialProduct ? curr.entityIndustrialProduct.container.sku : 'nd';
                        if (acc[sku]) {
                            acc[sku].qty += curr.qty;
                        } else {
                            acc[sku] = {
                                sku: sku,
                                qty: curr.qty,
                                type: 'collecteur',
                            };
                        }
                        return acc;
                    }, {}),
                ) || [];

            const allDataInCirculationRaw = [...sorterDataBySkuRaw, ...washerDataBySkuRaw, ...collectorDataBySkuRaw];
            const cumulativeQty = allDataInCirculationRaw.reduce((acc, curr) => {
                const { sku, qty } = curr;
                acc[sku] = (acc[sku] || 0) + qty;
                return acc;
            }, {});
            const cumulativeQtyArray = Object.keys(cumulativeQty).map((sku) => ({
                sku: sku,
                qty: cumulativeQty[sku],
            }));

            setAllDataInCirculation(cumulativeQtyArray);

            // NB DE CONTENANTS EN TOUT EN CIRCULATION
            const totalIncirculationRaw = cumulativeQtyArray.reduce((acc, curr) => {
                acc += curr.qty;
                return acc;
            }, 0);
            setTotalInCirculation(totalIncirculationRaw);

            // POUR LA CARTE
            const industrialAddressRawData = await getIndustrials();
            const sorterAddressRawData = await getSorters();
            const washerAddressRawData = await gearApi.washer.getWashers();
            const collectorAddressRawData = await getCollectors();
            const collectorAddressRawDataCheck = getCollectorCheck(collectorAddressRawData);
            if (collectorAddressRawData.length && collectorAddressRawDataCheck !== true) {
                console.error(collectorAddressRawDataCheck);
                Sentry.captureException(collectorAddressRawDataCheck);
                navigate('/error');
                return;
            }

            const industrialAddress = AddressTab(industrialAddressRawData, 1);
            const sorterAddress = AddressTab(sorterAddressRawData, 2);
            const washerAddress = AddressTab(washerAddressRawData, 3);
            const collectorAddress = AddressTab(collectorAddressRawData, 5);

            const allOperatorAddressRaw = [...industrialAddress, ...sorterAddress, ...washerAddress, ...collectorAddress];
            setAllOperatorAddress(allOperatorAddressRaw);

            setTotalByType({ sorter: sorterAddress.length, washer: washerAddress.length, collector: collectorAddress.length, indus: industrialAddress.length });

            // COMPARAISON DES BESOINS INDUSTRIELS VS DISPONIBILITÉS LAVEURS

            const allOrderDetails = await getAllOrdersDetails();
            const allOrderDetailsFiltered = allOrderDetails.filter((item) => item.status === Constants.ORDERDETAILS_PENDING);
            for (let i = 0; i < allOrderDetailsFiltered.length; i++) {
                allOrderDetailsFiltered[i].total_nb_container = allOrderDetailsFiltered[i].qty * allOrderDetailsFiltered[i].container.palet_nbcontainer;
            }
            const allOrderDetailsFormatted = needsBySku(allOrderDetailsFiltered, 'Industriels');

            const dataInWasherAvailable = dataInWasher.filter(
                (item) => item.type === Constants.DECLARATION_DETAILS_TYPE_OK && !item.suiviID && item.status === Constants.DECLARATION_DETAILS_AVAILABLE,
            );
            const dataInWasherFormatted = dataParSku(dataInWasherAvailable, 'Laveurs');
            const indusVsWasherRaw = allOrderDetailsFormatted.map((item2) => {
                const item1 = dataInWasherFormatted.find((item) => item.sku === item2.sku);
                const washer_dispo = item1 ? item1.qty : 0;
                return {
                    sku: item2.sku,
                    qty: item2.qty,
                    washer_dispo: washer_dispo,
                };
            });

            const indusNeedsComplied = indusVsWasherRaw.reduce((total, currentItem) => {
                return total + currentItem.qty;
            }, 0);

            // Calcul de la somme de washer_dispo
            const washerDispoCompiled = dataInWasherAvailable.reduce((total, currentItem) => {
                return total + currentItem.qty * currentItem.palet_nbcontainer;
            }, 0);

            setIndusVsSorterTotal({ indus: indusNeedsComplied, washer: washerDispoCompiled });
            let combinedTab = [...allOrderDetailsFormatted, ...dataInWasherFormatted];
            const indusVsWasherFormatted = transformDataEmpliee(combinedTab, 'sku', 'type', 'qty', null, false, null);
            setIndusVsWasher(indusVsWasherFormatted);

            // POUR NOTIFICATIONS COLLECTEURS
            const notifications = [];

            for (let i = 0; i < collectorAddressRawData.length; i++) {
                if (collectorAddressRawData[i].filling_rate >= 80) {
                    notifications.push({
                        type: 1,
                        objet: `Le collecteur ${collectorAddressRawData[i].store_company_name}-${collectorAddressRawData[i].id} est à ${collectorAddressRawData[i].filling_rate}%`,
                    });
                }
            }

            setNotifications(notifications);
        };
        fetchData();
    }, [navigate]);

    const dataParSku = (data, type) => {
        const result = Object.values(
            data.reduce((acc, curr) => {
                if (acc[curr.sku]) {
                    acc[curr.sku].qty += curr.palet_nbcontainer;
                } else {
                    acc[curr.sku] = { sku: curr.sku, qty: curr.palet_nbcontainer, type: type };
                }
                return acc;
            }, {}),
        );
        return result;
    };

    const needsBySku = (data, type) => {
        const result = Object.values(
            data.reduce((acc, curr) => {
                if (acc[curr.sku]) {
                    acc[curr.sku].qty += curr.total_nb_container;
                } else {
                    acc[curr.sku] = { sku: curr.sku, qty: curr.total_nb_container, type: type };
                }
                return acc;
            }, {}),
        );
        return result;
    };

    const AddressTab = (data) => {
        return data.map((objet) => {
            const lat = objet.lat ? objet.lat : objet.store_lat;
            const long = objet.long ? objet.long : objet.store_long;
            return { latitude: lat, longitude: long };
        });
    };

    const dataInCirculationQuantities = allDataInCirculation.map((item) => item.qty);
    const dataInCirculationLabels = allDataInCirculation.map((item) => item.sku);

    const removeNotification = (index) => {
        const newNotifications = [...notifications];
        newNotifications.splice(index, 1);
        setNotifications(newNotifications);
    };

    return (
        <main>
            <div style={{ width: '100%', height: '50%', display: 'flex' }}>
                <div
                    className="graph-container"
                    style={{ width: '50%' }}>
                    <div className="top">Actualités</div>
                    {notifications.map((notification, index) => (
                        <div
                            key={index}
                            className="notification">
                            <p>{notification.objet}</p>
                            <button onClick={() => removeNotification(index)}>X</button>
                        </div>
                    ))}
                </div>
                <div
                    style={{ width: '50%' }}
                    className="graph-container">
                    <div className="top">Parc tous opérateurs confondus</div>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ width: '60%' }}>
                            <ComposableMap
                                projection="geoMercator"
                                projectionConfig={{
                                    scale: 2200,
                                    center: [2.5, 46.5],
                                }}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                }}>
                                <Geographies geography={franceData}>
                                    {({ geographies }) =>
                                        geographies.map((geo) => (
                                            <Geography
                                                key={geo.rsmKey}
                                                geography={geo}
                                                fill="#EAEAEC"
                                                stroke="#D6D6DA"
                                            />
                                        ))
                                    }
                                </Geographies>

                                {allOperatorAddress.map((position, index) => (
                                    <Marker
                                        key={index}
                                        coordinates={[position.longitude, position.latitude]}>
                                        <circle
                                            cx={0}
                                            cy={0}
                                            r={5}
                                            fill="red"
                                        />
                                    </Marker>
                                ))}
                            </ComposableMap>
                        </div>
                        <div
                            style={{
                                width: '40%',
                                height: '90%',
                                color: Constants.$noir,
                                marginBottom: '1rem',
                                fontSize: '20px',
                            }}>
                            <p style={{ fontWeight: 'bold', fontSize: '30px' }}>
                                {totalByType.indus} <span style={{ fontWeight: '400', fontSize: '20px' }}>marques</span>
                            </p>
                            <p style={{ fontWeight: 'bold', fontSize: '30px' }}>
                                {totalByType.collector} <span style={{ fontWeight: '400', fontSize: '20px' }}>collecteurs</span>
                            </p>
                            <p style={{ fontWeight: 'bold', fontSize: '30px' }}>
                                {totalByType.sorter} <span style={{ fontWeight: '400', fontSize: '20px' }}>centres de tri</span>
                            </p>
                            <p style={{ fontWeight: 'bold', fontSize: '30px', marginBottom: '0px' }}>
                                {totalByType.washer} <span style={{ fontWeight: '400', fontSize: '20px' }}>centres de lavage</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ width: '100%', height: '50%', display: 'flex', marginBottom: '1rem' }}>
                <div
                    className="graph-container"
                    style={{ width: '40%', height: '90%' }}>
                    <div className="top">Total en circulation</div>

                    <div style={{ height: '90%', justifyContent: 'space-between', alignItems: 'center', gap: '1rem' }}>
                        <p
                            style={{
                                fontWeight: 'bold',
                                fontSize: '20px',
                                marginBottom: '0',
                                display: 'flex',
                                alignItems: 'center',
                                height: '20%',
                                gap: '0.5rem',
                            }}>
                            {totalIncirculation} <br />
                            <span style={{ fontWeight: '400', fontSize: '15px' }}>contenants en circulation</span>
                        </p>
                        <div style={{ height: '70%' }}>
                            {allDataInCirculation.length ? (
                                <CircleChart
                                    title={dataInCirculationLabels}
                                    quantities={dataInCirculationQuantities}
                                    position={'right'}
                                />
                            ) : (
                                <div className="no-data-graph">Aucune donnée.</div>
                            )}
                        </div>
                    </div>
                </div>
                <div
                    className="graph-container"
                    style={{ height: '90%', width: '60%' }}>
                    <div className="top">Besoins marques / disponibilités centres de lavage</div>
                    <div style={{ justifyContent: 'space-between', height: '90%' }}>
                        <div
                            style={{
                                display: 'flex',
                                padding: '1%',
                                textAlign: 'center',
                                alignItems: 'center',
                                gap: '1rem',
                                height: '15%',
                            }}>
                            <p
                                style={{
                                    fontWeight: 'bold',
                                    fontSize: '20px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '0.5rem',
                                }}>
                                {indusVsSorterTotal.indus}
                                <span style={{ fontWeight: '400', fontSize: '15px' }}>besoins exprimés</span>
                            </p>
                            <p style={{ fontWeight: 'bold', fontSize: '20px', display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                                {indusVsSorterTotal.washer}
                                <span style={{ fontWeight: '400', fontSize: '15px' }}>contenants disponibles</span>
                            </p>
                        </div>
                        <div style={{ height: '85%', width: '100%' }}>
                            {indusVsWasher.labels ? <BarChartMulti formattedData={indusVsWasher} /> : <div className="no-data-graph">Aucune donnée.</div>}
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}
