import { useState, useEffect } from 'react';
import { getSorters } from '../../../../../../API/SorterApi';
import { addSorterWasherLink, getAllSorterWasherLinks } from '../../../../../../API/SorterWasherLinkApi';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Constant from '../../../../../../Constants';

export default function AddSorterWasherLink() {
    const location = useLocation();
    const navigate = useNavigate();
    const { sorter_id } = location.state || {};
    const [sortersList, setSortersList] = useState([]);
    const [washersList, setWashersList] = useState([]);
    const [skuList, setSkuList] = useState([]);

    const [formData, setFormData] = useState({
        entity_id: '',
        washer_id: '',
        selectedSkus: [],
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        const _getAllSorters = async () => {
            const data = await getSorters();
            setSortersList(data);

            let sorters_list = document.getElementById('optionsSelect');
            if (sorter_id > 0) {
                sorters_list.value = sorter_id;
                sorters_list.dispatchEvent(new Event('change'));
                setFormData((formData) => ({ ...formData, entity_id: sorter_id }));

                sorters_list.disabled = true;
            }
        };

        _getAllSorters();
    }, [sorter_id]);

    useEffect(() => {
        const getAllWashers = async () => {
            const { gearApi } = await import('../../../../../../gearApi.js');
            const data = await gearApi.washer.getWashers();
            setWashersList(data);
        };
        getAllWashers();
    }, []);

    const handleWasherChange = async (e) => {
        const { gearApi } = await import('../../../../../../gearApi.js');
        const data = await gearApi.washer.getSkusOfWasher({ washerId: e.target.value });
        const allLinks = await getAllSorterWasherLinks();

        const newData = data.map((washerSku) => ({
            ...washerSku,
            existed: allLinks.some((link) => link.entity_sorter_id === sorter_id && link.sku === washerSku.sku),
        }));
        setSkuList(newData);
    };

    const handleSkuChange = (sku) =>
        setFormData((prevData) => ({
            ...prevData,
            selectedSkus: prevData.selectedSkus.includes(sku)
                ? prevData.selectedSkus.filter((selectedSku) => selectedSku !== sku)
                : [...prevData.selectedSkus, sku],
        }));

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!formData.entity_id || !formData.washer_id || formData.selectedSkus.length === 0) {
            alert('Veuillez sélectionner un centre de tri, un centre de lavage et au moins un SKU.');
            return;
        }
        addSorterWasherLink(formData)
            .then((data) => {
                alert('Le centre de lavage et les sku ont été associés avec succès');
                navigate(-1);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <main>
            <section className="small-section">
                <h2>Associer un centre de lavage</h2>

                <div className="form-container">
                    <section style={{ width: '100%' }}>
                        <div>
                            <label>Centre de tri :</label>
                            <select
                                name="entity_id"
                                id="optionsSelect"
                                onChange={handleChange}>
                                <option>Choisissez une option</option>
                                {sortersList.map((option, index) => (
                                    <option
                                        key={index}
                                        value={option.id}>
                                        {option.company_name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label>Centre de lavage :</label>
                            <select
                                name="washer_id"
                                id="washerSelect"
                                onChange={(e) => {
                                    handleChange(e);
                                    handleWasherChange(e);
                                }}>
                                <option>Choisissez une option</option>
                                {washersList.map((option, index) => (
                                    <option
                                        key={index}
                                        value={option.id}>
                                        {option.company_name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </section>
                    <p></p>
                    <section className="washer-sorter-container">
                        {formData.washer_id ? (
                            <div className="washer-sorter-content">
                                {skuList.length ? (
                                    skuList.map((item, index) => (
                                        <div
                                            key={index}
                                            className="checkbox-container">
                                            <p>{item.sku}</p>
                                            <div className="toggle-checkbox">
                                                <input
                                                    type="checkbox"
                                                    id={`toggle-${item.id}`}
                                                    className="checkbox"
                                                    onChange={() => handleSkuChange(item.sku)}
                                                    checked={formData.selectedSkus.includes(item.sku)}
                                                    disabled={item.existed}
                                                    title={item.existed ? 'SKU déjà attribué' : null}
                                                />
                                                <label
                                                    htmlFor={`toggle-${item.id}`}
                                                    className="label"></label>
                                            </div>
                                            <p style={{ fontSize: '12px' }}>{item.existed && 'SKU déjà attribué à un autre centre de lavage'}</p>
                                        </div>
                                    ))
                                ) : (
                                    <p style={{ color: Constant.$rouge, fontWeight: 'bolder' }}>Aucun SKU n&apos;est associé à ce centre de lavage.</p>
                                )}
                            </div>
                        ) : null}
                    </section>
                </div>

                <div className="button-confirm">
                    <button
                        className="button-actif"
                        onClick={handleSubmit}>
                        Associer le centre de lavage
                    </button>
                </div>
            </section>
        </main>
    );
}
