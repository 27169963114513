export const AccessServiceprovider = {
    serviceprovider: {
        operation: {
            dashboard: {
                all: 0,
            },
            besoins_marques: {
                dashboard: 0,
                besoins_en_cours: 0,
                besoins_par_marque: 0,
            },
            contenants_en_circulation: {
                dashboard: 0,
                collecteurs: 0,
                centre_de_tri: 0,
                centre_de_lavage: 0,
            },
            base_de_donnees: {
                contenants: 0,
                verriers: 0,
                marques: 0,
                produits: 0,
                operateur_de_collecte: 0,
                centre_de_tri: 0,
                centre_de_lavage: 0,
                gestionnaire_de_rvm: 0,
                gestionnaire_de_magasin: 0,
                centre_de_collecte: 0,
                distributor: 0,
            },
        },
        data: {},
        financial: {},
    },
};
