import React, { useState, useRef } from 'react';
import { useEffect } from 'react';
import { format } from 'date-fns';
import { _defineCollecteStatus, _defineBoxesStoreState } from '../../../Utils';
import { getOperatorById } from '../../../API/OperatorApi';
import * as Constant from '../../../Constants';
import { Jwt } from '../../../Jwt';
import { useNavigate } from 'react-router-dom';
import { getStoreEventDetailsBySorterId } from '../../../API/StoreApi';
import * as Constants from '../../../Constants';
import { storePickUpReceived } from '../../../API/StoreApi';

export default function SorterReception() {
    const navigate = useNavigate();
    const userContext = new Jwt();
    const [allEvents, setAllEvents] = useState([]);
    const [showArchives, setShowArchives] = useState(false);
    const [archivesTab, setArchivesTab] = useState([]);
    const [receptionData, setReceptionData] = useState([]);

    const dialogRef = useRef(null);

    useEffect(() => {
        const fetchAllEvents = async () => {
            const data = await getStoreEventDetailsBySorterId(userContext.entityId);

            const groupByStore = data.reduce((acc, obj) => {
                const key = `${obj.storeEvent.storeId}-${obj.storeEvent.suiviId}`;

                if (!acc[key]) {
                    acc[key] = [];
                }
                acc[key].push(obj);
                return acc;
            }, {});

            const storeEventsRowData = Object.keys(groupByStore).map((key) => ({
                time: groupByStore[key][0].storeEvent.time,
                status: groupByStore[key][0].storeEvent.status,
                collecte_type: Constant.COLLECTETYPE_STORE,
                item: groupByStore[key],
                suiviId: groupByStore[key][0].suiviId,
                departure: groupByStore[key][0].storeEvent.store.depot,
            }));

            for (let i = 0; i < storeEventsRowData.length; i++) {
                const operatorInfo = await getOperatorById(storeEventsRowData[i].item[0].storeEvent.operatorId);
                storeEventsRowData[i].operatorInfo = operatorInfo[0];
            }

            const tab = [...storeEventsRowData];

            const archiveTabData = tab.filter((item) => item.status === Constant.EVENT_RECEIVED).sort((a, b) => new Date(b.time) - new Date(a.time));
            setArchivesTab(archiveTabData);
            const finalData = filterData(tab);

            setAllEvents(finalData);
        };

        fetchAllEvents();
    }, [userContext.entityId, navigate]);

    const filterData = (data) => {
        const filteredData = [];
        const groupedData = {};
        data.forEach((item) => {
            if (!groupedData[item.suiviId]) {
                groupedData[item.suiviId] = [];
            }
            groupedData[item.suiviId].push(item);
        });

        Object.keys(groupedData).forEach((suiviId) => {
            const hasStatus3 = groupedData[suiviId].some((item) => item.status === Constant.EVENT_RECEIVED);
            if (!hasStatus3) {
                filteredData.push(...groupedData[suiviId]);
            }
        });

        return filteredData;
    };

    const handleModalOpen = (item) => {
        setReceptionData(item);
        if (dialogRef.current) {
            dialogRef.current.showModal();
        }
    };

    const handleModalClose = () => {
        setReceptionData();

        if (dialogRef.current) {
            dialogRef.current.close();
        }
    };

    const sendPickUpAccept = async () => {
        const dateAujourdhui = new Date();
        const pickUpReceivedTime = format(dateAujourdhui, 'yyyy-MM-dd HH:mm:ss');
        try {
            const data = await storePickUpReceived(receptionData, pickUpReceivedTime);
            if (data.message === 'ok') {
                alert('Enregistrement réussi.');
                handleModalClose();
                window.location.reload();
            } else {
                alert('Une erreur est survenue');
            }
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <main>
            <section className="big-section">
                <div className="top top-history">
                    <h2>Réceptions </h2>
                    <div className="sort">
                        <p
                            onClick={() => {
                                setShowArchives(false);
                            }}
                            className={!showArchives ? 'selected' : 'classic'}>
                            En cours
                        </p>
                        <p
                            onClick={() => {
                                setShowArchives(true);
                            }}
                            className={showArchives ? 'selected' : 'classic'}>
                            Archivées
                        </p>
                    </div>
                </div>
                {!showArchives ? (
                    <div className="tab-item">
                        <div
                            className="pre-filled-fields-1-several-item"
                            style={{ fontWeight: '600', width: '100%' }}>
                            <p>En provenance de</p>
                            <p>Opérateur</p>
                            <p>Date de livraison</p>
                            <p>Statut</p>
                        </div>
                        {allEvents.length ? (
                            allEvents.map((item, index) => {
                                return (
                                    <div
                                        onClick={() => {
                                            handleModalOpen(item);
                                        }}
                                        key={index}
                                        style={{ display: 'flex', flexWrap: 'wrap', width: '100%', justifyContent: 'space-between' }}>
                                        <div
                                            className="pre-filled-fields-2-several-item"
                                            key={item.id}
                                            style={{ width: '100%' }}>
                                            <p>{item.departure.name}</p>
                                            <p>{item.operatorInfo.name}</p>
                                            <p>{format(item.time, 'yyyy-MM-dd HH:mm:ss')}</p>
                                            <p
                                                style={{
                                                    backgroundColor: _defineCollecteStatus(item.status).color,
                                                    color: Constants.$blanc,
                                                    borderTopRightRadius: '0.62rem',
                                                    borderBottomRightRadius: '0.62rem',
                                                }}>
                                                {_defineCollecteStatus(item.status).name}
                                            </p>
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <div className="no-data-graph ">Aucune réception prévue.</div>
                        )}
                    </div>
                ) : (
                    <div className="tab-item">
                        <div
                            className="pre-filled-fields-1-several-item"
                            style={{ fontWeight: '600', width: '100%' }}>
                            <p>En provenance de</p>
                            <p>Opérateur</p>
                            <p>Date de livraison</p>
                            <p>Statut</p>
                        </div>
                        {archivesTab.length ? (
                            archivesTab.map((item, index) => {
                                return (
                                    <div
                                        style={{ display: 'flex', flexWrap: 'wrap', width: '100%', justifyContent: 'space-between' }}
                                        key={index}>
                                        <div
                                            style={{ width: '100%' }}
                                            className="pre-filled-fields-2-several-item">
                                            <p>{item.departure.name}</p>
                                            <p>{item.operatorInfo.name}</p>
                                            <p>{format(item.time, 'yyyy-MM-dd HH:mm:ss')}</p>
                                            <p>{_defineCollecteStatus(item.status).name}</p>
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <div className="no-data-graph ">Aucune réception archivée.</div>
                        )}
                    </div>
                )}
            </section>
            {receptionData ? (
                <dialog
                    ref={dialogRef}
                    className="dialog-collector">
                    <div className="title">
                        <p>Confirmer la réception</p>
                        <button
                            className="button-negatif"
                            onClick={handleModalClose}>
                            X
                        </button>
                    </div>
                    <div className="collector-infos">
                        <p style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}> Vous confirmez avoir reçu de :</p>
                        <span>{receptionData.operatorInfo && receptionData.operatorInfo.name}</span>
                        <p style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}>En provenance de :</p>
                        <span>{receptionData.departure && receptionData.departure.name}</span>
                    </div>
                    {receptionData &&
                        receptionData.item &&
                        receptionData.item.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        padding: '0.5rem 0',
                                    }}>
                                    <p>
                                        {item.container.name} - {_defineBoxesStoreState(item.empty)}
                                    </p>
                                    <p>x{item.qty}</p>
                                </div>
                            );
                        })}

                    <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '2rem' }}>
                        <button
                            className="button-negatif"
                            onClick={handleModalClose}>
                            ANNULER
                        </button>
                        <button
                            onClick={sendPickUpAccept}
                            className="button-actif">
                            VALIDER
                        </button>
                    </div>
                </dialog>
            ) : null}
        </main>
    );
}
