import { useEffect, useState, useRef, useCallback } from 'react';
import { getWasherDeclarationDetailsLastStatusByEntityId } from '../../../../API/WasherDeclarationApi';
import { getWasherDeclarationDetailsLastStatusByEntityIdCheck } from '../../../../API/CheckApi/CheckWasherDeclarationApi.js';
import { getContainers } from '../../../../API/ContainerApi';
import * as Contants from '../../../../Constants';
import { ArrowDown } from '@phosphor-icons/react';
import { handleArrowClickDown } from '../../../../Utils.js';
import { format } from 'date-fns';
import * as Sentry from '@sentry/react';
import { useNavigate } from 'react-router-dom';

export default function FsWasherDashboard() {
    const navigate = useNavigate();
    const [washerDeclarationTabs, setWasherDeclarationTabs] = useState([]);
    const [summaryBySku, setSummaryBySku] = useState([]);
    const dialogRef = useRef(null);

    const getstockedDeclarationDetails = useCallback(
        async (allWashers) => {
            const declarationDetailsPromises = allWashers.map(async (washer) => {
                const washerDeclarationDetails = await getWasherDeclarationDetailsLastStatusByEntityId(washer.id);
                const washerDeclarationDetailsCheck = getWasherDeclarationDetailsLastStatusByEntityIdCheck(washerDeclarationDetails);
                if (washerDeclarationDetails.length && washerDeclarationDetailsCheck !== true) {
                    console.error(washerDeclarationDetailsCheck);
                    Sentry.captureException(washerDeclarationDetailsCheck);
                    navigate('/error');
                    return;
                }

                return { washer_id: washer.id, washer_name: washer.company_name, washerDeclarationDetails };
            });

            const allDeclarationDetails = await Promise.all(declarationDetailsPromises);
            const stockedDeclarationDetails = allDeclarationDetails.map((item) => ({
                ...item,
                washerDeclarationDetails: item.washerDeclarationDetails.filter((detail) => detail.status === Contants.DECLARATION_DETAILS_AVAILABLE),
            }));

            return stockedDeclarationDetails;
        },
        [navigate],
    );

    const getDeclarationDetailsInTransit = useCallback(
        async (allWashers) => {
            const declarationDetailsPromises = allWashers.map(async (washer) => {
                const washerDeclarationDetails = await getWasherDeclarationDetailsLastStatusByEntityId(washer.id);
                const washerDeclarationDetailsCheck = getWasherDeclarationDetailsLastStatusByEntityIdCheck(washerDeclarationDetails);

                if (washerDeclarationDetails.length && washerDeclarationDetailsCheck !== true) {
                    console.error(washerDeclarationDetailsCheck);
                    Sentry.captureException(washerDeclarationDetailsCheck);
                    navigate('/error');
                    return;
                }
                return { washer_id: washer.id, washer_name: washer.company_name, washerDeclarationDetails };
            });

            const allDeclarationDetails = await Promise.all(declarationDetailsPromises);
            const declarationDetailsInTransit = allDeclarationDetails.map((item) => ({
                ...item,
                washerDeclarationDetails: item.washerDeclarationDetails.filter(
                    (detail) => detail.suivi && detail.suivi.washerEvents && detail.suivi.washerEvents[0].status !== Contants.EVENT_RECEIVED,
                ),
            }));
            return declarationDetailsInTransit;
        },
        [navigate],
    );

    useEffect(() => {
        const fetchData = async () => {
            const { gearApi } = await import('../../../../gearApi.js');

            const allContainers = await getContainers();
            const allWashers = await gearApi.washer.getWashers();

            const stockedDeclarationDetails = await getstockedDeclarationDetails(allWashers);
            const declarationDetailsInTransit = await getDeclarationDetailsInTransit(allWashers);
            const mergedData = mergeData(stockedDeclarationDetails, declarationDetailsInTransit, allContainers);

            setWasherDeclarationTabs(mergedData);
        };

        fetchData();
    }, [getstockedDeclarationDetails, getDeclarationDetailsInTransit]);

    function mergeData(filteredData, inTransitData, allContainers) {
        const mergeDetails = (details) => {
            details.forEach((subtab) => {
                allContainers.forEach((skuPrincipal) => {
                    const existeDansSousTab = subtab.washerDeclarationDetails.some((obj) => obj.sku === skuPrincipal.sku);
                    if (!existeDansSousTab) {
                        subtab.washerDeclarationDetails.push({ sku: skuPrincipal.sku, qty: 0 });
                    }
                });
            });
        };

        mergeDetails(filteredData);
        mergeDetails(inTransitData);

        return filteredData.map((filteredItem) => {
            const grouped = filteredItem.washerDeclarationDetails.reduce(
                (acc, curr) => {
                    let existingItemIndex = acc.data.findIndex((item) => item.sku === curr.sku);
                    if (existingItemIndex === -1) {
                        acc.data.push({ sku: curr.sku, qty: curr.qty });
                    } else {
                        acc.data[existingItemIndex].qty += curr.qty;
                    }
                    return acc;
                },
                { washer_id: filteredItem.washer_id, washer_name: filteredItem.washer_name, data: [] },
            );

            const correspondingTransitItem = inTransitData.find((transitItem) => transitItem.washer_id === filteredItem.washer_id);

            const groupedTransit = correspondingTransitItem
                ? correspondingTransitItem.washerDeclarationDetails.reduce(
                      (acc, curr) => {
                          let existingItemIndex = acc.data.findIndex((item) => item.sku === curr.sku);
                          if (existingItemIndex === -1) {
                              acc.data.push({ sku: curr.sku, qty: curr.qty });
                          } else {
                              acc.data[existingItemIndex].qty += curr.qty;
                          }
                          return acc;
                      },
                      { washer_id: correspondingTransitItem.washer_id, washer_name: correspondingTransitItem.washer_name, data: [] },
                  )
                : null;

            return {
                washer_id: filteredItem.washer_id,
                washer_name: filteredItem.washer_name,
                washerDeclarationDetails: grouped.data,
                declarationDetailsInTransit: groupedTransit ? groupedTransit.data : [],
            };
        });
    }

    const handleModalOpen = async (elem, item) => {
        const data = await getWasherDeclarationDetailsLastStatusByEntityId(item.washer_id);
        const filteredData = data.filter(
            (detail) => detail.sku === elem.sku && detail.suivi && detail.suivi.washerEvents && detail.suivi.washerEvents[0].status !== Contants.EVENT_RECEIVED,
        );

        const groupedObjets = filteredData.reduce((acc, obj) => {
            const { orderDetailId, ...rest } = obj;
            if (!acc[orderDetailId]) {
                acc[orderDetailId] = [rest];
            } else {
                acc[orderDetailId].push(rest);
            }
            return acc;
        }, {});

        const result = Object.values(groupedObjets).map((subArray) => {
            const totalOccurrences = subArray.reduce((sum, obj) => sum + obj.qty, 0);
            return {
                sku: subArray[0].sku,
                washer_name: item.washer_name,
                total: totalOccurrences,
                indus_name: subArray[0].industrial_name,
                dated: subArray[0].dated,
                deliveryStatus: subArray[0].suivi.washerEvents[0].status,
            };
        });
        setSummaryBySku(result);

        if (dialogRef.current) {
            dialogRef.current.showModal();
        }
    };

    const handleModalClose = () => {
        if (dialogRef.current) {
            dialogRef.current.close();
        }
    };

    return (
        <main>
            {washerDeclarationTabs.length
                ? washerDeclarationTabs.map((item, index) => {
                      return (
                          <section
                              key={index}
                              className="small-section">
                              <h2>{item.washer_name}</h2>
                              <div className="tab-item">
                                  <div
                                      className="pre-filled-fields-1-several-item"
                                      style={{ fontWeight: '600', width: '100%' }}>
                                      <p>SKU</p>
                                      <p>
                                          Volume total lavé stocké <br />
                                          (en palette)
                                      </p>
                                      <p>
                                          Volume total attribué <br />
                                          (en palette)
                                      </p>
                                  </div>
                                  {item.washerDeclarationDetails.length ? (
                                      <div style={{ display: 'flex' }}>
                                          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                              {item.washerDeclarationDetails
                                                  ? item.washerDeclarationDetails
                                                        .sort((a, b) => a.sku.localeCompare(b.sku))
                                                        .map((elem, index) => {
                                                            return (
                                                                <div key={index}>
                                                                    <div
                                                                        className="pre-filled-fields-2-several-item without-hover"
                                                                        style={{ width: '100%', border: '0px' }}>
                                                                        <p>{elem.sku}</p>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                  : null}
                                          </div>
                                          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                              {item.washerDeclarationDetails
                                                  ? item.washerDeclarationDetails
                                                        .sort((a, b) => a.sku.localeCompare(b.sku))
                                                        .map((elem, index) => {
                                                            return (
                                                                <div key={index}>
                                                                    <div
                                                                        className="pre-filled-fields-2-several-item without-hover"
                                                                        style={{
                                                                            width: '100%',
                                                                            border: '0px',
                                                                            color: elem.qty > 0 && Contants.$rouge,
                                                                            fontWeight: elem.qty > 0 && 'bold',
                                                                        }}>
                                                                        <p>{elem.qty}</p>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                  : null}
                                          </div>

                                          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                              {item.declarationDetailsInTransit
                                                  ? item.declarationDetailsInTransit
                                                        .sort((a, b) => a.sku.localeCompare(b.sku))
                                                        .map((elem, index) => {
                                                            return (
                                                                <div
                                                                    onClick={() => {
                                                                        if (elem.qty > 0) {
                                                                            handleModalOpen(elem, item);
                                                                        }
                                                                    }}
                                                                    key={index}
                                                                    style={{
                                                                        width: '100%',
                                                                        border: '0px',
                                                                        color: elem.qty > 0 ? Contants.$rouge : '',
                                                                        fontWeight: elem.qty > 0 ? 'bold' : '',
                                                                        cursor: elem.qty > 0 ? 'pointer' : 'default',
                                                                    }}
                                                                    className="pre-filled-fields-2-several-item without-hover">
                                                                    <p>{elem.qty}</p>
                                                                </div>
                                                            );
                                                        })
                                                  : null}
                                          </div>
                                      </div>
                                  ) : (
                                      <div className="no-data-graph">Aucun contenant référencé.</div>
                                  )}
                              </div>
                          </section>
                      );
                  })
                : null}
            <dialog
                ref={dialogRef}
                className="dialog-collector">
                {summaryBySku.length ? (
                    <>
                        <div className="title">
                            <p>
                                Centre de lavage {summaryBySku && summaryBySku[0].washer_name} <br /> SKU {summaryBySku && summaryBySku[0].sku}
                            </p>

                            <button
                                className="button-negatif"
                                onClick={handleModalClose}>
                                X
                            </button>
                        </div>

                        <div className="dialog-collector-content">
                            <div className="tab-item">
                                <div
                                    className="pre-filled-fields-1-several-item"
                                    style={{ fontWeight: '600', width: '100%' }}>
                                    <p>Marque</p>
                                    <p>Volume (en palette)</p>
                                    <p>Date de livraison</p>
                                </div>
                                {summaryBySku.length > 0
                                    ? summaryBySku.map((elem, index) => {
                                          return (
                                              <div
                                                  key={index}
                                                  className="pre-filled-fields-2-several-item">
                                                  <p>{elem.indus_name}</p>
                                                  <p>{elem.total}</p>
                                                  <p>{format(elem.dated, 'yyyy-MM-dd')}</p>
                                              </div>
                                          );
                                      })
                                    : null}
                            </div>
                        </div>
                    </>
                ) : null}
            </dialog>
            <div
                className="arrow-down"
                onClick={handleArrowClickDown}>
                <ArrowDown
                    size={32}
                    color={Contants.$vertBocoloco}
                />
            </div>
        </main>
    );
}
