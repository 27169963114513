export const reduceDataByKeyAndQty = (data, key) => {
    return data.reduce((acc, item) => {
        const keyValue = key.split('.').reduce((o, k) => (o && o[k] !== undefined ? o[k] : undefined), item);

        if (keyValue !== undefined) {
            if (acc[keyValue]) {
                acc[keyValue] += item.nb_total ? item.nb_total : item.qty;
            } else {
                acc[keyValue] = item.nb_total ? item.nb_total : item.qty;
            }
        }

        return acc;
    }, {});
};

export const countOccurenceByKey = (data, key) => {
    return data.reduce((acc, obj) => {
        const keyValue = key.split('.').reduce((o, k) => (o && o[k] !== undefined ? o[k] : undefined), obj);
        acc[keyValue] = (acc[keyValue] || 0) + 1;
        return acc;
    }, {});
};

export const reduceByMonthAndKey = (initialData, dateKey, groupingKey, quantityKey, outputKey) => {
    const rawData = initialData.reduce((acc, curr) => {
        const dateValue = new Date(curr[dateKey]);
        const month = dateValue.toLocaleString('default', { month: 'long' });
        const groupValue = groupingKey && groupingKey.split('.').reduce((o, i) => o[i], curr);
        const quantity = curr[quantityKey];

        if (!acc[groupValue]) {
            acc[groupValue] = {};
        }

        if (!acc[groupValue][month]) {
            acc[groupValue][month] = 0;
        }

        acc[groupValue][month] += quantity;

        return acc;
    }, {});

    const formattedData = Object.entries(rawData)
        .map(([groupValue, months]) => {
            return Object.entries(months).map(([month, quantity]) => ({
                [outputKey]: groupValue,
                month,
                quantity,
            }));
        })
        .flat();

    return formattedData;
};

export const addMonthToTab = (formattedData, startDate, endDate, groupKey, monthKey, quantityKey) => {
    const firstDate = new Date(startDate);
    const lastDate = new Date(endDate);
    const groupValues = [...new Set(formattedData.map((fd) => fd[groupKey]))];

    const diffTab = [];

    let currentDate = new Date(firstDate);

    while (currentDate <= lastDate) {
        const currentMonth = currentDate.toLocaleString('default', { month: 'long' });
        groupValues.forEach((groupValue) => {
            const item = formattedData.find((fd) => fd[groupKey] === groupValue && fd[monthKey] === currentMonth);

            let quantity = 0;
            if (item) {
                quantity += item[quantityKey];
            }

            diffTab.push({
                [groupKey]: groupValue,
                [monthKey]: currentMonth,
                [quantityKey]: quantity,
            });
        });
        currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
    }
    return diffTab;
};

export const transformDataEmpliee = (data, groupKey, subgroupKey, quantityKey, defineSubgroupLabel, calculatePercentage = false, defineGroupLabel) => {
    const getNestedValue = (obj, key) => key.split('.').reduce((o, i) => o[i], obj);

    const groups = [...new Set(data.map((item) => getNestedValue(item, groupKey)))];
    const subgroups = [...new Set(data.map((item) => getNestedValue(item, subgroupKey)))];

    const datasets = subgroups.map((subgroup) => {
        const quantities = groups.map((group) => {
            const totalTest = data.filter((d) => getNestedValue(d, groupKey) === group);
            const items = data.filter((d) => getNestedValue(d, groupKey) === group && getNestedValue(d, subgroupKey) === subgroup);

            let totalQty = items.reduce((acc, item) => acc + getNestedValue(item, quantityKey), 0);
            if (calculatePercentage) {
                totalQty /= totalTest.length;
            }

            return totalQty;
        });

        return {
            label: defineSubgroupLabel ? defineSubgroupLabel(subgroup) : subgroup,
            data: quantities,
        };
    });

    return {
        labels: groups ? groups.map((group) => (defineGroupLabel ? defineGroupLabel(group) : group)) : [],
        datasets: datasets,
    };
};

export const initializeAccumulatedData = (startDate, endDate) => {
    const firstDate = new Date(startDate);
    const lastDate = new Date(endDate);
    const accumulatedData = {};

    let currentDate = new Date(firstDate);
    while (currentDate <= lastDate) {
        const monthName = currentDate.toLocaleString('default', { month: 'short' });
        const year = currentDate.getFullYear();
        const monthYear = `${monthName} ${year}`;
        accumulatedData[monthYear] = 0;
        currentDate.setMonth(currentDate.getMonth() + 1);
    }

    return { firstDate, lastDate, accumulatedData };
};

export const reduceDataByMonth = (data, startDate, endDate, dateKey, quantityKey) => {
    const { firstDate, lastDate, accumulatedData } = initializeAccumulatedData(startDate, endDate);

    data.forEach((item) => {
        const dateValue = new Date(item[dateKey]);
        if (isNaN(dateValue)) {
            return;
        }

        const year = dateValue.getFullYear();
        const monthName = dateValue.toLocaleString('default', { month: 'short' });
        const monthYear = `${monthName} ${year}`;

        if (dateValue >= firstDate && dateValue <= lastDate) {
            if (!accumulatedData[monthYear]) {
                accumulatedData[monthYear] = 0;
            }
            if (item[quantityKey] === undefined || item[quantityKey] === null) {
                accumulatedData[monthYear]++;
            } else {
                accumulatedData[monthYear] += item[quantityKey];
            }
        }
    });

    return accumulatedData;
};

export const accumulateDataByMonth = (data, startDate, endDate, dateKey, quantityKey) => {
    const { firstDate, lastDate, accumulatedData } = initializeAccumulatedData(startDate, endDate);

    data.forEach((item) => {
        const dateValue = new Date(item[dateKey]);
        if (isNaN(dateValue)) {
            return;
        }

        const year = dateValue.getFullYear();
        const monthName = dateValue.toLocaleString('default', { month: 'short' });
        const monthYear = `${monthName} ${year}`;

        if (dateValue >= firstDate && dateValue <= lastDate) {
            if (!accumulatedData[monthYear]) {
                accumulatedData[monthYear] = 0;
            }
            if (item[quantityKey] === undefined || item[quantityKey] === null) {
                accumulatedData[monthYear]++;
            } else {
                accumulatedData[monthYear] += item[quantityKey];
            }
        }
    });

    // Order months chronologically
    const orderedMonths = Object.keys(accumulatedData).sort((a, b) => {
        const [monthA, yearA] = a.split(' ');
        const [monthB, yearB] = b.split(' ');
        const dateA = new Date(`${monthA} 1, ${yearA}`);
        const dateB = new Date(`${monthB} 1, ${yearB}`);
        return dateA - dateB;
    });

    // Calculate cumulative quantities
    let cumulativeTotal = 0;
    const cumulativeData = {};
    orderedMonths.forEach((monthYear) => {
        cumulativeTotal += accumulatedData[monthYear];
        cumulativeData[monthYear] = cumulativeTotal;
    });

    return cumulativeData;
};

export const mergeAndFilter = (dataA, dataB, keys) => {
    // Concaténer les deux tableaux
    const mergedData = [...dataA, ...dataB];

    // Filtrer les objets pour ne conserver que les clés souhaitées
    return mergedData.map((item) => {
        const filteredItem = {};
        keys.forEach((key) => {
            if (Object.prototype.hasOwnProperty.call(item, key)) {
                filteredItem[key] = item[key];
            }
        });
        return filteredItem;
    });
};

export const addTotals = (data) => {
    // Créer un objet pour stocker les quantités de produits et de retours par mois
    const monthlyData = {};

    data.forEach((item) => {
        const { month, name, quantity } = item;
        if (!monthlyData[month]) {
            monthlyData[month] = { produit: 0, retour: 0 };
        }
        if (name === 'Montant mis sur le marché') {
            monthlyData[month].produit += quantity;
        } else if (name === 'Montant à rembourser') {
            monthlyData[month].retour += quantity;
        }
    });

    // Ajouter les totaux pour chaque mois directement dans le tableau initial
    Object.keys(monthlyData).forEach((month) => {
        const totalQty = monthlyData[month].produit - monthlyData[month].retour;
        data.push({ name: 'Solde', month, quantity: totalQty });
    });

    return data;
};

export const getQtyByDayOfWeek = (data) => {
    const daysOfWeek = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
    const initialAcc = daysOfWeek.reduce((acc, day) => {
        acc[day] = 0;
        return acc;
    }, {});
    return data.reduce((acc, curr) => {
        const date = new Date(curr.returnedAt);
        const dayOfWeek = daysOfWeek[date.getDay()];
        acc[dayOfWeek] += curr.qty;
        return acc;
    }, initialAcc);
};

export const getQtyByHourOfDay = (data) => {
    const initialAcc = Array.from({ length: 13 }, (_, i) => i + 8).reduce((acc, hour) => {
        acc[hour] = 0;
        return acc;
    }, {});

    return data.reduce((acc, curr) => {
        const dateObject = new Date(curr.returnedAt);
        const hour = dateObject.getUTCHours();
        if (hour >= 8 && hour <= 20) {
            acc[hour] += curr.qty;
        }
        return acc;
    }, initialAcc);
};

export const mergeArrays = (array1, array2, key) => {
    const mergedArray = [];
    const map = new Map();

    array1.forEach((item) => {
        const { qty, ...rest } = item;
        const newItem = {
            ...rest,
            qtyGlobal: rest.qtyGlobal !== undefined ? rest.qtyGlobal : 0,
            qtyMyRef: rest.qtyMyRef !== undefined ? rest.qtyMyRef : 0,
        };
        newItem.qtyRatio = (newItem.qtyMyRef / newItem.qtyGlobal) * 100;
        map.set(item[key], newItem);
    });

    array2.forEach((item) => {
        const { qty, ...rest } = item;
        if (map.has(item[key])) {
            const existingItem = map.get(item[key]);
            const mergedItem = {
                ...existingItem,
                ...rest,
                qtyGlobal: rest.qtyGlobal !== undefined ? rest.qtyGlobal : existingItem.qtyGlobal,
                qtyMyRef: rest.qtyMyRef !== undefined ? rest.qtyMyRef : existingItem.qtyMyRef,
            };
            mergedItem.qtyRatio = (mergedItem.qtyMyRef / mergedItem.qtyGlobal) * 100;
            map.set(item[key], mergedItem);
        } else {
            const newItem = {
                ...rest,
                qtyGlobal: rest.qtyGlobal !== undefined ? rest.qtyGlobal : 0,
                qtyMyRef: rest.qtyMyRef !== undefined ? rest.qtyMyRef : 0,
            };
            newItem.qtyRatio = (newItem.qtyMyRef / newItem.qtyGlobal) * 100;
            map.set(item[key], newItem);
        }
    });

    // Convertir le map en tableau
    map.forEach((value) => {
        mergedArray.push(value);
    });

    return mergedArray;
};

export const groupByNestedKey = (data, nestedKey) => {
    return data.reduce((acc, item) => {
        const keys = nestedKey.split('.');
        let value = item;

        for (let key of keys) {
            value = value[key];
        }

        if (!acc[value]) {
            acc[value] = [];
        }
        acc[value].push(item);

        return acc;
    }, {});
};
