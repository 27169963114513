import Cookies from 'js-cookie';
import * as Constants from '../../Constants';

export const industrialMenuOperation = () => {
    let menu_operation = [];
    const access = Cookies.get('access').length ? JSON.parse(Cookies.get('access')) : null;
    if (access) {
        let access_operation = access.industrial.operation;
        if (checkAccessOperationDashboard(access_operation)) {
            menu_operation.push('homeOpe');
        }
        if (CheckAccessOperationBesoinsMarques(access_operation)) {
            menu_operation.push('indus');
        }
        return menu_operation;
    }
    return [];
};

const checkAccessOperationDashboard = (access_operation) => {
    if (access_operation.dashboard.all === 1) {
        return true;
    }
    return false;
};

const CheckAccessOperationBesoinsMarques = (access_operation) => {
    for (let key in access_operation.marques) {
        if (access_operation.marques[key] === 1) {
            return true;
        }
    }
    return false;
};

const checkAccessDataDashboard = (access_data) => {
    if (access_data.dashboard.all === 1) {
        return true;
    }
    return false;
};

const CheckAccessDataRemploi = (access_data) => {
    for (let key in access_data.reemploi) {
        if (access_data.reemploi[key] === 1) {
            return true;
        }
    }
    return false;
};

const CheckAccessDataConsommateur = (access_data) => {
    for (let key in access_data.consommateur) {
        if (access_data.consommateur[key] === 1) {
            return true;
        }
    }
    return false;
};

const CheckAccessDataImpact = (access_data) => {
    for (let key in access_data.impact) {
        if (access_data.impact[key] === 1) {
            return true;
        }
    }
    return false;
};

const getSubAccessOperationDashboard = () => {
    let sousMenu = [{ title: 'Dashboard' }];
    return sousMenu;
};

const getSubAccessOperationBesoinsMarques = () => {
    const access = JSON.parse(Cookies.get('access'));
    let access_operation = access.industrial.operation;
    let sousMenu = [{ title: 'Marques' }];
    if (access_operation.marques.produits === 1) {
        sousMenu.push({ name: 'Produits', link: '/industrial/operation/product' });
    }
    if (access_operation.marques.besoins === 1) {
        sousMenu.push({
            sousPartie: 'Besoins',
            sousMenu: [
                { name: 'Nouvelle commande', link: '/industrial/operation/order/create' },
                { name: 'Suivi des commandes', link: '/industrial/operation/order/history' },
                { name: 'Prévisions annuelles', link: '/industrial/operation/forecast/create' },
            ],
        });
    }
    if (access_operation.marques.mises_sur_le_marché === 1) {
        sousMenu.push({ name: 'Mises sur le marché', link: '/industrial/operation/sales/create' });
    }
    return sousMenu;
};

export const getSousMenuIndustrialOperation = (onglet) => {
    switch (onglet) {
        case 'homeOpe':
            return getSubAccessOperationDashboard();
        case 'indus':
            return getSubAccessOperationBesoinsMarques();

        default:
            return [];
    }
};

export const industrialMenuData = () => {
    let menu_data = [];
    const access = Cookies.get('access').length ? JSON.parse(Cookies.get('access')) : null;
    if (access) {
        let access_data = access.industrial.data;
        if (checkAccessDataDashboard(access_data)) {
            menu_data.push('homeData');
        }
        if (CheckAccessDataRemploi(access_data)) {
            menu_data.push('reuse');
        }
        if (CheckAccessDataConsommateur(access_data)) {
            menu_data.push('consumer');
        }
        if (CheckAccessDataImpact(access_data)) {
            menu_data.push('impact');
        }
        return menu_data;
    }
    return [];
};

const getSubAccessDataDashboard = () => {
    let sousMenu = [{ title: 'Dashboard' }];
    return sousMenu;
};

const getSubAccessDataRemploi = () => {
    let sousMenu = [{ title: 'Réemploi' }];
    const access = Cookies.get('access').length ? JSON.parse(Cookies.get('access')) : null;
    if (access) {
        let access_data = access.industrial.data;

        if (access_data.reemploi.dashboard === 1) {
            sousMenu.push({ name: 'Dashboard', link: '/industrial/data/reuse/dashboard' });
        }
        if (access_data.reemploi.collecteurs === 1) {
            sousMenu.push({ name: 'Collecteurs', link: '/industrial/data/reuse/collectors' });
        }
        if (access_data.reemploi.centre_de_tri === 1) {
            sousMenu.push({ name: 'Centre de tri', link: '/industrial/data/reuse/sorter' });
        }
        if (access_data.reemploi.centre_de_lavage === 1) {
            sousMenu.push({ name: 'Centre de lavage', link: '/industrial/data/reuse/washer' });
        }
        if (access_data.reemploi.contenants === 1) {
            sousMenu.push({ name: 'Contenants', link: '/industrial/data/reuse/container' });
        }
        if (access_data.reemploi.produits === 1) {
            sousMenu.push({ name: 'Produits', link: '/industrial/data/reuse/products' });
        }
        if (access_data.reemploi.retour === 1) {
            sousMenu.push({ name: 'Retour', link: '/industrial/data/reuse/returns' });
        }
    }

    return sousMenu;
};

const getSubAccessDataConsommateur = () => {
    let sousMenu = [{ title: 'Consommateur' }];
    const access = Cookies.get('access').length ? JSON.parse(Cookies.get('access')) : null;
    if (access) {
        let access_data = access.industrial.data;

        if (access_data.consommateur.dashboard === 1) {
            sousMenu.push({ name: 'Dashboard', link: '/industrial/data/consumer/dashboard' });
        }
        if (access_data.consommateur.produits === 1) {
            sousMenu.push({ name: 'Produits', link: '/industrial/data/consumer/product' });
        }
        if (access_data.consommateur.frequence === 1) {
            sousMenu.push({ name: 'Fréquence', link: '/industrial/data/consumer/frequence' });
        }
        if (access_data.consommateur.region === 1) {
            sousMenu.push({ name: 'Régions', link: '/industrial/data/consumer/regions' });
        }
        if (access_data.consommateur.collecteurs === 1) {
            sousMenu.push({ name: 'Collecteurs', link: '/industrial/data/consumer/collectors' });
        }
    }

    return sousMenu;
};

const getSubAccessDataImpact = () => {
    let sousMenu = [{ title: 'Impact environnement' }];
    const access = Cookies.get('access').length ? JSON.parse(Cookies.get('access')) : null;
    if (access) {
        let access_data = access.industrial.data;

        if (access_data.impact.donnees === 1) {
            sousMenu.push({ name: 'Données', link: '/' });
        }
        if (access_data.impact.indicateur === 1) {
            sousMenu.push({ name: 'Indicateurs', link: '/' });
        }
        if (access_data.impact.impacts_compares === 1) {
            sousMenu.push({ name: 'Impacts comparés', link: '/' });
        }
        if (access_data.impact.acv === 1) {
            sousMenu.push({ name: 'ACV', link: '/' });
        }
    }

    return sousMenu;
};

export const getSousMenuIndustrialData = (onglet) => {
    switch (onglet) {
        case 'homeData':
            return getSubAccessDataDashboard();
        case 'reuse':
            return getSubAccessDataRemploi();
        case 'consumer':
            return getSubAccessDataConsommateur();
        case 'impact':
            return getSubAccessDataImpact();
        default:
            return [];
    }
};

export const industrialMenuFacturation = () => {
    return ['financial_management'];
};

const getSubAccessFacturationIndustrial = () => {
    let sousMenu = [{ title: 'Finance' }];
    const access = Cookies.get('access').length ? JSON.parse(Cookies.get('access')) : null;
    if (access) {
        let access_facturation = access.industrial.financial;

        if (access_facturation.gestion_financiere.reportings === 1) {
            sousMenu.push({ name: 'Reportings', link: '/industrial/financial/reportings' });
        }
    }
    return sousMenu;
};

export const getSousMenuIndustrialFacturation = (onglet) => {
    switch (onglet) {
        case 'financial_management':
            return getSubAccessFacturationIndustrial();
        default:
            return [];
    }
};

export const industrialMenuAccount = () => {
    return ['my_account'];
};

export const getSousMenuIndustrialAccount = (onglet) => {
    const role = Cookies.get('role');
    const isAdmin = role === Constants.ENTITY_ROLE_ADMIN;
    switch (onglet) {
        case 'my_account':
            if (isAdmin) {
                return [
                    { title: 'Mon compte' },
                    { name: 'Mes informations personnelles', link: '/industrial/myprofil/personal_info' },
                    { name: 'Ma société', link: '/industrial/myprofil/company_info' },
                    { name: 'Accès', link: '/industrial/myprofil/access' },
                    { name: 'Gestion des comptes', link: '/industrial/myprofil/rolemanagement' },
                ];
            } else {
                return [
                    { title: 'Mon compte' },
                    { name: 'Mes informations personnelles', link: '/industrial/myprofil/personal_info' },
                    { name: 'Société', link: '/industrial/myprofil/company_info' },
                    { name: 'Accès', link: '/industrial/myprofil/access' },
                ];
            }
        default:
            return [];
    }
};
