import { getGearAPIApiEndpoint } from './endpoints';
import * as Sentry from '@sentry/react';
import { defaultHeaders } from './headers';

const GEAR_API_BASE_URL = getGearAPIApiEndpoint();

const BOCOLOCO_GET_CONTAINER = '/container/get';
const BOCOLOCO_GET_CONTAINERSBYNAME = '/container/getbyname';
const BOCOLOCO_ADD_CONTAINER = '/container/add';
const BOCOLOCO_UPDATE_CONTAINER = '/container/update';
const BOCOLOCO_GET_CONTAINERLISTBYSKU = '/container/getlistbysku';
const BOCOLOCO_GET_CONTAINERBYID = '/container/getbyid';
const BOCOLOCO_GET_CONTAINERBYSKU = '/container/getbysku';
const BOCOLOCO_DELETE_CONTAINER = '/container/delete';
const BOCOLOCO_GET_SUPPLIERSFORSKU = '/container/getsuppliers';
const BOCOLOCO_GET_CONTAINERBYDATES = '/container/getbydates';

export function addContainer(formData) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_ADD_CONTAINER;
    const url = encodeURI(url_base);
    return fetch(url, {
        method: 'PUT',
        body: JSON.stringify(formData),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            console.log('ERROR ADD CLIENT');
            console.log(error);
            throw error;
        });
}

export function getContainers() {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_CONTAINER;
    const url = encodeURI(url_base);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function getContainersByDates(startDate, endDate) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_CONTAINERBYDATES;
    var url_args = '?startDate=' + startDate + '&endDate=' + endDate;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function getContainersByName(name) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_CONTAINERSBYNAME;
    var url_args = '?name=' + name;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function updateContainer(id, updatedData) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_UPDATE_CONTAINER;
    const url_args = '?id=' + id;
    const url = encodeURI(url_base + url_args);
    return fetch(url, {
        method: 'PUT',
        body: JSON.stringify(updatedData),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            console.log('ERROR UPDATE CLIENT');
            console.log(error);
            throw error;
        });
}

export function getContainerListBySKU(sku) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_CONTAINERLISTBYSKU;
    var url_args = '?sku=' + sku;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function getContainerById(id) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_CONTAINERBYID;
    var url_args = '?id=' + id;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function getContainerBySku(sku) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_CONTAINERBYSKU;
    var url_args = '?sku=' + sku;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function deleteContainer(id) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_DELETE_CONTAINER;
    var url_args = '?id=' + id;
    const url = encodeURI(url_base + url_args);

    return fetch(url, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            throw error;
        });
}

export function getSuppliersForSku(sku) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_SUPPLIERSFORSKU;
    var url_args = '?sku=' + sku;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}
