import { useState, useEffect } from 'react';
import { getAddressInfoById, updateIndustrialAddress } from '../../../../../../API/IndustrialApi';
import { getAddressInfoByIdCheck } from '../../../../../../API/CheckApi/CheckIndustrialApi';
import { _defineStatus } from '../../../../../../Utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { checkEntityAdressFormErrors, checkIsInputEmpty } from '../../../../../../Utils';
import * as Constant from '../../../../../../Constants';
import * as Sentry from '@sentry/react';
import AddressSearch from '../../../../../../Components/AddressSearch';
export default function UpdateIndustrialAddress() {
    const location = useLocation();
    const navigate = useNavigate();
    const { id } = location.state || {};
    const [AddressData, setAddressData] = useState([]);
    const [errorMessage, setErrorMessage] = useState([]);
    const [errorEmpty, setErrorEmpty] = useState('');
    useEffect(() => {
        const fetchAddressData = async () => {
            try {
                const address = await getAddressInfoById(id);
                const addressCheck = getAddressInfoByIdCheck(address);
                if (address.length && addressCheck !== true) {
                    console.error(addressCheck);
                    Sentry.captureException(addressCheck);
                    navigate('/error');
                    return;
                }

                setAddressData(address[0]);
            } catch (error) {
                console.error("Erreur lors de la récupération de l'adresse", error);
            }
        };
        fetchAddressData();
    }, [id, navigate]);

    const [formErrors, setFormErrors] = useState({
        entity_id: false,
        name: false,
        address_1: false,
        address_2: false,
        city: false,
        zipcode: false,
        contact_name: false,
        contact_phone: false,
        contact_email: false,
        comments: false,
        delivery_window: false,
    });

    const requiredFields = [
        'name',
        'address_1',
        'city',
        'zipcode',
        'contact_name',
        'contact_phone',
        'contact_email',
        'delivery_window',
    ];

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setAddressData({
            ...AddressData,
            [name]: value,
        });
    };

    const handleSaveChanges = async (e) => {
        e.preventDefault();
        setErrorMessage([]);
        setErrorEmpty('');

        const inputEmpty = checkIsInputEmpty(requiredFields, AddressData, setFormErrors, setErrorEmpty);
        if (!inputEmpty) {
            const checkFormat = checkEntityAdressFormErrors(AddressData, formErrors, setFormErrors, setErrorMessage);
            if (checkFormat) {
                return;
            } else {
                const addAdressData = await updateIndustrialAddress(id, AddressData);
                if (addAdressData) {
                    alert("L'adresse a été modifiée avec succès");
                    navigate(-1);
                } else {
                    alert("Une erreur est survenue lors de la modification de l'adresse");
                }
            }
        } else {
            console.log('Il reste des champs obligatoires vides');
        }
    };
    return (
        <main>
            <section className="big-section">
                <h2>Modifier une adresse</h2>

                {AddressData.id ? (
                    <div className="form-container">
                        <section>
                            <div>
                                <label>Nom de la marque :</label>
                                <select
                                    disabled={true}
                                    name="entity_id"
                                    onChange={handleInputChange}>
                                    <option value={AddressData.entity_id}>{AddressData.company_name}</option>
                                </select>
                            </div>
                            <div>
                                <label>Nom de l&apos;adresse :</label>
                                <input
                                    className={formErrors.name ? 'error' : ''}
                                    type="text"
                                    name="name"
                                    value={AddressData.name || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </section>
                        <section>
                            <AddressSearch
                                formData={AddressData}
                                setFormData={setAddressData}
                                formErrors={formErrors}
                                setFormErrors={setFormErrors}
                                handleChange={handleInputChange}
                            />
                        </section>
                        <section>
                            <div>
                                <label>Nom du contact :</label>
                                <input
                                    className={formErrors.contact_name ? 'error' : ''}
                                    type="text"
                                    name="contact_name"
                                    value={AddressData.contact_name || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <label>Téléphone du contact :</label>
                                <input
                                    className={formErrors.contact_phone ? 'error' : ''}
                                    type="number"
                                    name="contact_phone"
                                    value={AddressData.contact_phone || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <label>Email du contact :</label>
                                <input
                                    className={formErrors.contact_email ? 'error' : ''}
                                    type="email"
                                    name="contact_email"
                                    value={AddressData.contact_email || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </section>
                        <section>
                            <div>
                                <label>Créneau de Livraison :</label>
                                <input
                                    value={AddressData.delivery_window || ''}
                                    className={formErrors.delivery_window ? 'error' : ''}
                                    type="text"
                                    name="delivery_window"
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div>
                                <label>Commentaires Livraison :</label>
                                <input
                                    value={AddressData.comments || ''}
                                    className={formErrors.comments ? 'error' : ''}
                                    type="text"
                                    name="comments"
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div>
                                <label>Statut :</label>
                                <select
                                    style={{ backgroundColor: _defineStatus(AddressData.status).color, color: Constant.$blanc }}
                                    name="status"
                                    value={AddressData.status}
                                    onChange={handleInputChange}>
                                    <option value={0}>Actif</option>
                                    <option value={100}>Inactif</option>
                                </select>
                            </div>
                        </section>
                    </div>
                ) : null}
                <p className="error-form">{errorEmpty && errorEmpty}</p>
                {errorMessage.length
                    ? errorMessage.map((item, index) => {
                          return (
                              <div
                                  className="error-form"
                                  key={index}>
                                  {item}
                              </div>
                          );
                      })
                    : null}
                <div className="button-confirm">
                    <button
                        className="button-actif"
                        onClick={handleSaveChanges}>
                        Enregistrer les modifications
                    </button>
                </div>
            </section>
        </main>
    );
}
