import { useEffect, useState } from 'react';
import CircleChart from '../../../../Components/CircleChart';
import BarChart from '../../../../Components/BarChart';
import { getAllReturnInCollector } from '../../../../API/CollectorReturnApi';
import { getAllReturnInCollectorCheck } from '../../../../API/CheckApi/CheckCollectorReturnApi';
import { useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { getCollectors } from '../../../../API/CollectorApi';
import { countOccurenceByKey } from '../../../../UtilsData';
import { _defineStatus } from '../../../../Utils';

export default function FsCollectorDashboard() {
    const navigate = useNavigate();
    const [collectorByStatus, setCollectorByStatus] = useState([]);
    const [totalCollectors, setTotalCollectors] = useState([]);
    const [totalReturnBySku, setTotalReturnBySku] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const allCollectors = await getCollectors();
            setTotalCollectors(allCollectors);

            const collectorByStatusRaw = countOccurenceByKey(allCollectors, 'status');
            const collectorByStatusCounted = Object.keys(collectorByStatusRaw).map((status) => ({
                status: _defineStatus(parseInt(status)).name,
                qty: collectorByStatusRaw[status],
            }));

            setCollectorByStatus(collectorByStatusCounted);

            const collectorReturn = await getAllReturnInCollector();
            const collectorReturnCheck = getAllReturnInCollectorCheck(collectorReturn);
            if (collectorReturn.length && collectorReturnCheck !== true) {
                console.error(collectorReturnCheck);
                Sentry.captureException(collectorReturnCheck);
                navigate('/error');
                return;
            }

            const totalReturnBySkuRaw = dataBySku(collectorReturn);
            setTotalReturnBySku(totalReturnBySkuRaw);
        };
        fetchData();
    }, [navigate]);

    const dataBySku = (data) => {
        if (data.length === 0) {
            return [];
        }

        const tabFinal = data.reduce((acc, obj) => {
            const sku = obj.entityIndustrialProduct ? obj.entityIndustrialProduct.container.sku : 'N.D';
            if (acc[sku]) {
                acc[sku] += obj.qty;
            } else {
                acc[sku] = obj.qty;
            }
            return acc;
        }, {});

        const resultatFinal = Object.keys(tabFinal).map((sku) => ({
            sku: sku,
            qty: tabFinal[sku],
        }));

        return resultatFinal;
    };

    // CONST POUR GRAPH
    const collectorByStatusQuantities = collectorByStatus.map((item) => item.qty);
    const collectorByStatusLabels = collectorByStatus.map((item) => item.status);

    const barGraphQantities = totalReturnBySku.map((item) => item.qty);
    const barGraphLabel = totalReturnBySku.map((item) => item.sku);

    return (
        <main>
            <div style={{ width: '100%', height: '50%' }}>
                <section
                    className="graph-container"
                    style={{ height: '90%' }}>
                    <div
                        className="top"
                        style={{ marginBottom: '1rem' }}>
                        Problèmes collecteurs
                    </div>
                    {/* TODO */}
                </section>
            </div>
            <div style={{ width: '100%', height: '50%', display: 'flex', marginBottom: '1rem' }}>
                <div
                    className="graph-container"
                    style={{ flex: 1, height: '90%', width: '35%' }}>
                    <div className="top">Statut des collecteurs</div>
                    <div style={{ justifyContent: 'space-around', alignItems: 'center', height: '90%' }}>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                paddingBottom: '1rem',
                            }}>
                            <p style={{ marginTop: '1rem', marginBottom: 0 }}>{totalCollectors.length} collecteurs présents sur le territoire</p>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', height: '80%' }}>
                            {collectorByStatus.length ? (
                                <CircleChart
                                    title={collectorByStatusLabels}
                                    quantities={collectorByStatusQuantities}
                                />
                            ) : (
                                <div className="no-data-graph">Aucune donnée.</div>
                            )}
                        </div>
                    </div>
                </div>
                <div
                    className="graph-container"
                    style={{ width: '65%' }}>
                    <div className="top">Retours de contenants</div>
                    <div
                        style={{
                            height: '100%',
                            width: '100%',
                        }}>
                        {totalReturnBySku.length ? (
                            <BarChart
                                title={barGraphLabel}
                                quantities={barGraphQantities}
                            />
                        ) : (
                            <div className="no-data-graph">Aucune donnée.</div>
                        )}
                    </div>
                </div>
            </div>
        </main>
    );
}
