// DEPOT API

export const getDepotsCheck = (data) => {
    const name = 'getDepots : ';
    for (let i = 0; i < data.length; i++) {
        if (!data[i].id) {
            return name + 'No id found';
        } else if (!data[i].entity.id) {
            return name + 'No entity id found';
        } else {
            return true;
        }
    }
};

export const getDepotByIdCheck = (data) => {
    const name = 'getDepotById : ';
    for (let i = 0; i < data.length; i++) {
        if (!data[i].id) {
            return name + 'No id found';
        } else if (!data[i].entity.id) {
            return name + 'No entity id found';
        } else {
            return true;
        }
    }
};
