import Cookies from 'js-cookie';
import * as Constants from '../../Constants';

// OPERATION

export const distributorMenuOperation = () => {
    return [];
};

export const getSousMenuDistributorOperation = (onglet) => {
    switch (onglet) {
        default:
            return [];
    }
};

// DATA
const checkAccessDataDashboard = (access_data) => {
    if (access_data.dashboard.all === 1) {
        return true;
    }
    return false;
};

const CheckAccessDataRemploi = (access_data) => {
    for (let key in access_data.reemploi) {
        if (access_data.reemploi[key] === 1) {
            return true;
        }
    }
    return false;
};

const CheckAccessDataImpact = (access_data) => {
    for (let key in access_data.impact) {
        if (access_data.impact[key] === 1) {
            return true;
        }
    }
    return false;
};

export const distributorMenuData = () => {
    let menu_data = [];
    const access = Cookies.get('access').length ? JSON.parse(Cookies.get('access')) : null;
    if (access) {
        let access_data = access.distributor.data;
        if (checkAccessDataDashboard(access_data)) {
            menu_data.push('homeData');
        }
        if (CheckAccessDataRemploi(access_data)) {
            menu_data.push('reuse');
        }
        if (CheckAccessDataImpact(access_data)) {
            menu_data.push('impact');
        }
        return menu_data;
    }
    return [];
};

const getSubAccessDataDashboard = () => {
    let sousMenu = [{ title: 'Dashboard' }];
    return sousMenu;
};

const getSubAccessDataRemploi = () => {
    let sousMenu = [{ title: 'Réemploi' }];
    const access = Cookies.get('access').length ? JSON.parse(Cookies.get('access')) : null;
    if (access) {
        let access_data = access.distributor.data;

        if (access_data.reemploi.dashboard === 1) {
            sousMenu.push({ name: 'Dashboard', link: '/distributor/data/reuse/dashboard' });
        }
        if (access_data.reemploi.collecteurs === 1) {
            sousMenu.push({ name: 'Collecteurs', link: '/distributor/data/reuse/collectors' });
        }
        if (access_data.reemploi.contenants === 1) {
            sousMenu.push({ name: 'Contenants', link: '/distributor/data/reuse/container' });
        }
        if (access_data.reemploi.retour === 1) {
            sousMenu.push({ name: 'Retour', link: '/distributor/data/reuse/returns' });
        }
    }

    return sousMenu;
};

const getSubAccessDataImpact = () => {
    let sousMenu = [{ title: 'Impact environnement' }];
    const access = Cookies.get('access').length ? JSON.parse(Cookies.get('access')) : null;
    if (access) {
        let access_data = access.distributor.data;

        if (access_data.impact.donnees === 1) {
            sousMenu.push({ name: 'Données', link: '/' });
        }
        if (access_data.impact.indicateur === 1) {
            sousMenu.push({ name: 'Indicateurs', link: '/' });
        }
        if (access_data.impact.impacts_compares === 1) {
            sousMenu.push({ name: 'Impacts comparés', link: '/' });
        }
        if (access_data.impact.acv === 1) {
            sousMenu.push({ name: 'ACV', link: '/' });
        }
    }

    return sousMenu;
};

export const getSousMenuDistributorData = (onglet) => {
    switch (onglet) {
        case 'homeData':
            return getSubAccessDataDashboard();
        case 'reuse':
            return getSubAccessDataRemploi();

        case 'impact':
            return getSubAccessDataImpact();
        default:
            return [];
    }
};

// FACTURATION

export const distributorMenuFacturation = () => {
    return [];
};

export const getSousMenuDistributorFacturation = (onglet) => {
    switch (onglet) {
        default:
            return [];
    }
};

// ACCOUNT
export const distributorMenuAccount = () => {
    return ['my_account'];
};

export const getSousMenuDistributorAccount = (onglet) => {
    const role = Cookies.get('role');
    const isAdmin = role === Constants.ENTITY_ROLE_ADMIN;
    switch (onglet) {
        case 'my_account':
            if (isAdmin) {
                return [
                    { title: 'Mon compte' },
                    { name: 'Mes informations personnelles', link: '/distributor/myprofil/personal_info' },
                    { name: 'Ma société', link: '/distributor/myprofil/company_info' },
                    { name: 'Accès', link: '/distributor/myprofil/access' },
                    { name: 'Gestion des comptes', link: '/distributor/myprofil/rolemanagement' },
                ];
            } else {
                return [
                    { title: 'Mon compte' },
                    { name: 'Mes informations personnelles', link: '/distributor/myprofil/personal_info' },
                    { name: 'Société', link: '/distributor/myprofil/company_info' },
                    { name: 'Accès', link: '/distributor/myprofil/access' },
                ];
            }
        default:
            return [];
    }
};
