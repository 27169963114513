import { useState, useEffect } from 'react';
import { getStoreById } from '../../../../API/StoreApi';
import { getCollectorsByStoreId } from '../../../../API/CollectorApi';
import { _defineStatus, transformDateFull, _defineCollectorType } from '../../../../Utils';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Pencil } from '@phosphor-icons/react';
import * as Constant from '../../../../Constants';

export default function StoreSheet() {
    const [storeData, setStoreData] = useState([]);
    const [storeCollectorsData, setStoreCollectorsData] = useState([]);

    const location = useLocation();
    const { id } = location.state || {};

    useEffect(() => {
        const fetchDepotData = async () => {
            try {
                const storeRawData = await getStoreById(id);
                console.log('storeRawData');
                console.log(storeRawData);
                setStoreData(storeRawData[0]);

                const storeCollectorsRawData = await getCollectorsByStoreId(id);
                setStoreCollectorsData(storeCollectorsRawData);
            } catch (error) {
                console.error('Erreur lors de la récupération des infos du magasin', error);
            }
        };
        fetchDepotData();
    }, [id]);

    return (
        <main>
            {storeData.id && (
                <div className="sheet">
                    <div className="sheet-top-infos">
                        <div className="title">
                            <Link
                                className="update-depot"
                                to={'/servicesupplier/operation/store/update'}
                                state={{ id: storeData.id }}>
                                <div className="icon">
                                    <Pencil size={20} />
                                </div>
                            </Link>
                            <h2>{storeData.name}</h2>
                            <div
                                className="status"
                                style={{ backgroundColor: _defineStatus(storeData.status).color }}>
                                {_defineStatus(storeData.status).name}
                            </div>
                        </div>
                        <section className="item-info-container">
                            <div className="border">
                                <div>
                                    <p>
                                        Société Mère : <span>{storeData.company_name}</span>
                                    </p>
                                    <p>
                                        Nom : <span>{storeData.name}</span>
                                    </p>
                                </div>
                            </div>
                            <div className="border">
                                <div>
                                    <p>
                                        Adresse 1 : <span>{storeData.address_1}</span>
                                    </p>
                                    <p>
                                        Adresse 2 : <span>{storeData.address_2}</span>
                                    </p>
                                    <p>
                                        Ville : <span>{storeData.city}</span>
                                    </p>
                                    <p>
                                        Code postal : <span>{storeData.zipcode}</span>
                                    </p>
                                </div>
                            </div>
                            <div className="border">
                                <div>
                                    <p>
                                        Nom du contact : <span>{storeData.contact_name}</span>
                                    </p>
                                    <p>
                                        Email du contact : <span>{storeData.contact_email}</span>
                                    </p>
                                    <p>
                                        Téléphone du contact : <span>{storeData.contact_phone}</span>
                                    </p>
                                </div>
                            </div>
                        </section>
                        <section
                            className="item-info-container"
                            style={{ marginTop: 10 }}></section>
                    </div>

                    <section className="big-section">
                        <div className="top">
                            <h2 style={{ margin: '0' }}>Les RVM ({storeCollectorsData.length})</h2>
                            <div className="top-button-sort">
                                <Link
                                    to={'/rvmowner/operation/collector/add'}
                                    state={{ depot_id: storeCollectorsData.id, rvmowner_id: 0 }}
                                    className="button-actif">
                                    AJOUTER UNE RVM
                                </Link>
                            </div>
                        </div>

                        <div className="tab-item">
                            <div
                                className="pre-filled-fields-1-several-item"
                                style={{ fontWeight: '600' }}>
                                <p>Id</p>
                                <p>Device Id</p>
                                <p>Type</p>
                                <p>Capacity</p>
                                <p>Date Contact</p>
                                <p>Statut</p>
                            </div>

                            {storeCollectorsData.length
                                ? storeCollectorsData.map((item, index) => {
                                      return (
                                          <Link
                                              to={'/rvmowner/operation/collector/sheet'}
                                              state={{ id: item.id }}
                                              key={index}
                                              className="pre-filled-fields-2-several-item">
                                              <p>{item.id}</p>
                                              <p>{item.device_id}</p>
                                              <p>{_defineCollectorType(item.type)}</p>
                                              <p>{item.capacity}</p>
                                              <p>{transformDateFull(item.datep)}</p>
                                              <p
                                                  style={{
                                                      backgroundColor: _defineStatus(item.status).color,
                                                      color: Constant.$blanc,
                                                      borderRadius: '0.6rem',
                                                  }}>
                                                  {_defineStatus(item.status).name}
                                              </p>
                                          </Link>
                                      );
                                  })
                                : null}
                        </div>
                    </section>
                </div>
            )}
        </main>
    );
}
