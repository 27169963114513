import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const CircleChart = ({ title, quantities, position, legend }) => {
    const colors = [
        'rgba(255, 99, 132, 0.6)', // Rouge
        'rgba(54, 162, 235, 0.6)', // Bleu
        'rgba(255, 206, 86, 0.6)', // Jaune
        'rgba(75, 192, 192, 0.6)', // Vert
        'rgba(153, 102, 255, 0.6)', // Violet
        'rgba(255, 159, 64, 0.6)', // Orange
        'rgba(0, 255, 0, 0.6)', // Green
        'rgba(0, 0, 255, 0.6)', // Blue
        'rgba(255, 255, 0, 0.6)', // Yellow
        'rgba(255, 0, 255, 0.6)', // Magenta
    ];

    const chartData = {
        labels: title,
        datasets: [
            {
                label: '%',
                backgroundColor: colors.slice(0, quantities.length), // Utilisation des couleurs définies
                borderColor: colors.slice(0, quantities.length),
                borderWidth: 1,
                hoverBackgroundColor: colors.slice(0, quantities.length).map((color) => color.replace('0.6', '0.8')), // Augmenter l'opacité au survol
                hoverBorderColor: colors.slice(0, quantities.length).map((color) => color.replace('0.6', '1')), // Augmenter l'opacité de la bordure au survol
                data: quantities,
            },
        ],
    };

    const options = {
        maintainAspectRatio: false,
        plugins: {
            datalabels: {
                color: 'black',
                // anchor: 'end',
                align: 'center',
                formatter: function (value) {
                    const valueNumber = parseFloat(value);

                    return valueNumber;
                },
            },
            legend: {
                display: legend === 'false' ? false : true,
                position: 'right',
            },
        },
    };

    return (
        <div style={{ height: '100%' }}>
            <Doughnut
                data={chartData}
                options={options}
                plugins={[ChartDataLabels]}
            />
        </div>
    );
};

export default CircleChart;
