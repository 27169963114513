// PRODUCT API

export const getListByskuCheck = (data) => {
    const name = 'getListByskuCheck : ';
    for (let i = 0; i < data.length; i++) {
        if (!data[i].id) {
            return name + 'No id found';
        } else if (!data[i].supplier_id) {
            return name + 'No supplier id found';
        } else {
            return true;
        }
    }
};

export const getProductByIdCheck = (data) => {
    const name = 'getProductById : ';
    for (let i = 0; i < data.length; i++) {
        if (!data[i].id) {
            return name + 'No id found';
        } else if (!data[i].supplier.id) {
            return name + 'No supplier id found';
        } else {
            return true;
        }
    }
};
