import { useEffect, useState } from 'react';
import { _defineClientType, _defineStatus, _defineUserRole } from '../../Utils';
import { useLocation } from 'react-router-dom';
import { AccessServiceprovider } from '../../access/AccessServiceprovider';
import { AccessIndustrial } from '../../access/AccessIndustrial';
import { AccessOperator } from '../../access/AccessOperator';
import { AccessSorter } from '../../access/AccessSorter';
import { AccessWasher } from '../../access/AccessWasher';
import { AccessRvmowner } from '../../access/AccessRvmowner';
import { AccessStoreowner } from '../../access/AccessStoreowner';
import { AccessCdc } from '../../access/AccessCdc';
import { AccessDistributor } from '../../access/AccessDistributor';
import { updateUserAccess } from '../../API/AccountApi';
import { transformString } from '../../Utils';

import * as Constants from '../../Constants';

export default function UserSheet() {
    const location = useLocation();
    const { user } = location.state || {};
    const entity = user.entity;
    const [interfaceAccess, setInterfaceAccess] = useState(null);

    const chooseAccessInterface = (entity_type) => {
        let access;
        switch (parseInt(entity_type)) {
            case Constants.ENTITY_TYPE_FS:
                access = AccessServiceprovider;
                break;
            case Constants.ENTITY_TYPE_INDUSTRIAL:
                access = AccessIndustrial;
                break;
            case Constants.ENTITY_TYPE_SORTER:
                access = AccessSorter;
                break;
            case Constants.ENTITY_TYPE_WASHER:
                access = AccessWasher;
                break;
            case Constants.ENTITY_TYPE_OPERATOR:
                access = AccessOperator;
                break;
            case Constants.ENTITY_TYPE_RVMOWNER:
                access = AccessRvmowner;
                break;
            case Constants.ENTITY_TYPE_STOREOWNER:
                access = AccessStoreowner;
                break;
            case Constants.ENTITY_TYPE_CDC:
                access = AccessCdc;
                break;
            case Constants.ENTITY_TYPE_DISTRIBUTOR:
                access = AccessDistributor;
                break;
            default:
                console.log("setAccessInterface: entity type doesn't exist");
        }
        return access;
    };

    let access_tmp = null;
    if (user.access !== '') {
        access_tmp = JSON.parse(user.access);
    } else {
        access_tmp = chooseAccessInterface(user.entity.type);
    }

    const [userNewAccess, setUserNewAccess] = useState(access_tmp);

    const handleAccessChange = async (interfaceName, module, menu, submenu, e) => {
        let val = 0;
        if (!e.target.checked) {
            val = 0;
        } else {
            val = 1;
        }
        let userNewAccess_tmp = { ...userNewAccess };
        userNewAccess_tmp[interfaceName][module][menu][submenu] = val;
        setUserNewAccess(userNewAccess_tmp);
    };

    const handleUpdateUserAccess = () => {
        let access = userNewAccess;
        let userAccessData = {
            id: user.id,
            access: access,
        };
        updateUserAccess(userAccessData)
            .then(() => {
                alert("Accès de l'utilisateur mis à jour");
                window.location.href = '/servicesupplier/myprofil/rolemanagement';
            })
            .catch(() => {
                alert("Erreur lors de la mise à jour de l'accès de l'utilisateur");
            });
    };

    const displayAccess = () => {
        return (
            <div className="access-container">
                {interfaceAccess
                    ? Object.keys(interfaceAccess).map((interfaceName, key) => {
                          return (
                              <div
                                  style={{ display: 'flex', width: '100%', gap: '1rem' }}
                                  key={key}>
                                  {Object.keys(interfaceAccess[interfaceName]).map((module, key) => {
                                      return (
                                          <div
                                              className="small-section"
                                              key={key}>
                                              <h1>{transformString(module)}</h1>
                                              {Object.keys(interfaceAccess[interfaceName][module]).map((menu, key) => {
                                                  return (
                                                      <div
                                                          className="access-menu"
                                                          style={{ display: 'flex', flexDirection: 'column' }}
                                                          key={key}>
                                                          <p className="menu-title">{transformString(menu)}</p>
                                                          {Object.keys(interfaceAccess[interfaceName][module][menu]).map((submenu, key) => {
                                                              return (
                                                                  <div
                                                                      className="access-submenu"
                                                                      style={{ display: 'flex', justifyContent: 'space-around' }}
                                                                      key={key}>
                                                                      <p style={{ flex: 0.2, textAlign: 'right' }}>&#8226;</p>
                                                                      <p style={{ flex: 1, paddingLeft: '0.5rem' }}>{transformString(submenu)}</p>
                                                                      <input
                                                                          type="checkbox"
                                                                          name={submenu}
                                                                          checked={userNewAccess ? userNewAccess[interfaceName][module][menu][submenu] : 0}
                                                                          onChange={(e) => handleAccessChange(interfaceName, module, menu, submenu, e)}
                                                                      />
                                                                  </div>
                                                              );
                                                          })}
                                                      </div>
                                                  );
                                              })}
                                          </div>
                                      );
                                  })}
                              </div>
                          );
                      })
                    : null}
            </div>
        );
    };

    useEffect(() => {
        setInterfaceAccess(chooseAccessInterface(user.entity.type));
    }, [user]);

    return (
        <main>
            {user.id && (
                <div className="sheet">
                    <div className="sheet-top-infos">
                        <div className="title">
                            <h2>
                                {user.first_name} {user.last_name} ({user.email})
                            </h2>
                            <div
                                className="role"
                                style={{ backgroundColor: _defineUserRole(user.role).color }}>
                                {_defineUserRole(user.role).name}
                            </div>
                            <div
                                className="role"
                                style={{ backgroundColor: _defineClientType(user.entity.type).color }}>
                                {_defineClientType(user.entity.type).name}
                            </div>
                            <div
                                className="status"
                                style={{ backgroundColor: _defineStatus(user.status).color }}>
                                {_defineStatus(user.status).name}
                            </div>
                        </div>

                        <section className="item-info-container">
                            <div className="border">
                                <div>
                                    <p>
                                        Société Mère : <span>{entity.company_name}</span>
                                    </p>
                                    <p>
                                        Nom : <span>{entity.name}</span>
                                    </p>
                                    <p>
                                        SIRET : <span>{entity.siret}</span>
                                    </p>
                                    <p>
                                        TVA INTRA. : <span>{entity.tva_intra}</span>
                                    </p>
                                    <p>
                                        Site Web : <span>{entity.website}</span>
                                    </p>
                                </div>
                            </div>
                            <div className="border">
                                <div>
                                    <p>
                                        Adresse 1 : <span>{entity.address_1}</span>
                                    </p>
                                    <p>
                                        Adresse 2 : <span>{entity.address_2}</span>
                                    </p>
                                    <p>
                                        Ville : <span>{entity.city}</span>
                                    </p>
                                    <p>
                                        Code postal : <span>{entity.zipcode}</span>
                                    </p>
                                </div>
                            </div>
                            <div className="border">
                                <div>
                                    <p>
                                        Nom du contact : <span>{entity.contact_name}</span>
                                    </p>
                                    <p>
                                        Email du contact : <span>{entity.contact_email}</span>
                                    </p>
                                    <p>
                                        Téléphone du contact : <span>{entity.contact_phone}</span>
                                    </p>
                                </div>
                            </div>
                        </section>
                    </div>
                    <div className="sheet-top-infos">
                        <div className="title">
                            <h2>Accès</h2>
                            <button
                                onClick={handleUpdateUserAccess}
                                className="button-actif">
                                METTRE A JOUR
                            </button>
                        </div>
                        {displayAccess()}
                    </div>
                </div>
            )}
        </main>
    );
}
