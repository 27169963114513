import React from 'react';
import { DownloadSimple } from '@phosphor-icons/react';

const CSVDownloader = ({ fileName, headers, data }) => {
    const generateEmptyCSV = () => {
        const headersRow = headers.join(',') + '\n';
        let csvContent;
        if (data) {
            const exemplesRow = data.join(',') + '\n';
            csvContent = 'data:text/csv;charset=utf-8,' + encodeURIComponent(headersRow + exemplesRow);
        } else {
            csvContent = 'data:text/csv;charset=utf-8,' + encodeURIComponent(headersRow);
        }

        const link = document.createElement('a');
        link.setAttribute('href', csvContent);
        link.setAttribute('download', fileName + '.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div
            className="template"
            onClick={generateEmptyCSV}>
            <span>
                <DownloadSimple size={32} />
            </span>
            <button>Télécharger le template</button>
        </div>
    );
};

export default CSVDownloader;
