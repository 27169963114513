export const AccessDistributor = {
    distributor: {
        operation: {},
        data: {
            dashboard: {
                all: 0,
            },
            reemploi: {
                dashboard: 0,
                collecteurs: 0,
                contenants: 0,
                retour: 0,
            },
            impact: {
                donnees: 0,
                indicateur: 0,
                impacts_compares: 0,
                acv: 0,
            },
        },
        financial: {},
    },
};
