import { useEffect, useState } from 'react';
import { addCollector } from '../../../../API/CollectorApi';
import { getStoreByRvmownerId } from '../../../../API/StoreApi';
import { getStoreByRvmownerIdCheck } from '../../../../API/CheckApi/CheckStoreApi';
import { useNavigate } from 'react-router-dom';
import { Jwt } from '../../../../Jwt';
import * as Sentry from '@sentry/react';

export default function AddRvm() {
    const [storeList, setStoreList] = useState([]);
    const navigate = useNavigate();
    const userContext = new Jwt();

    const [formData, setFormData] = useState({
        store_id: 0,
        type: 0,
        device_id: '',
        capacity: 0,
        supplier_name: '',
        supplier_model: '',
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        const _getAllStores = async () => {
            let data;
            data = await getStoreByRvmownerId(userContext.entityId);
            console.log(data);

            const dataCheck = getStoreByRvmownerIdCheck(data);
            if (data.length && dataCheck !== true) {
                console.error(dataCheck);
                Sentry.captureException(dataCheck);
                navigate('/error');
                return;
            }
            const sortedData = data.sort((a, b) => a.name.localeCompare(b.name));
            setStoreList(sortedData);
        };
        _getAllStores();
    }, [userContext.entityId, navigate]);

    const handleSubmit = (e) => {
        e.preventDefault();
        addCollector(formData)
            .then((data) => {
                console.log(data);
                alert('Le collecteur a été créé avec succès');
                navigate(-1);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    return (
        <main>
            <section className="small-section">
                <h2>Ajouter un collecteur</h2>

                <div className="form-container">
                    <section style={{ width: '100%' }}>
                        <div>
                            <label>Nom du magasin:</label>
                            <select
                                name="store_id"
                                id="optionsSelect"
                                onChange={handleChange}
                                disabled={false}>
                                <option>Choisissez une option</option>
                                {storeList.map((option, index) => (
                                    <option
                                        key={index}
                                        value={option.id}>
                                        {option.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label>Fabricant Nom:</label>
                            <input
                                type="text"
                                name="supplier_name"
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <label>Fabricant Modèle:</label>
                            <input
                                type="text"
                                name="supplier_model"
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <label>Type:</label>
                            <select
                                name="type"
                                onChange={handleChange}>
                                <option value={''}>Choisir</option>
                                <option value={1}>Semi-automatique</option>
                                <option value={2}>Automatique</option>
                                <option value={3}>TCE</option>
                                <option value={4}>Caisse</option>
                            </select>
                        </div>
                        <div>
                            <label>Capacity:</label>
                            <input
                                type="text"
                                name="capacity"
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <label>Identifiant unique:</label>
                            <input
                                type="text"
                                name="device_id"
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </section>
                </div>
                <div className="button-confirm">
                    <button
                        className="button-actif"
                        onClick={handleSubmit}>
                        Ajouter le collecteur
                    </button>
                </div>
            </section>
        </main>
    );
}
