import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUsersByEntityId } from '../../API/AccountApi';
import { _defineClientType, _defineUserRole, _defineStatus } from '../../Utils';
import * as Constants from '../../Constants';
import Cookies from 'js-cookie';
import { Jwt } from '../../Jwt';

export default function ProfilRoleManagement() {
    const tokenFromCookie = Cookies.get('userToken');
    const jwt = new Jwt(tokenFromCookie);
    const [userContext] = useState(jwt);
    const navigate = useNavigate();

    const [usersList, setUsersList] = useState([]);

    const fetchUsersByEntityId = useCallback(async () => {
        try {
            const result = await getUsersByEntityId();
            setUsersList(result);
        } catch (error) {
            console.error('Erreur lors de la récupération des utilisateurs', error);
        }
    }, []);

    useEffect(() => {
        fetchUsersByEntityId();
    }, [fetchUsersByEntityId]);

    const handleAddUser = () => {
        let entity_type = userContext.entityType;

        switch (entity_type) {
            case Constants.ENTITY_TYPE_FS:
                navigate('/servicesupplier/myprofil/user/add');
                break;
            case Constants.ENTITY_TYPE_INDUSTRIAL:
                navigate('/industrial/myprofil/user/add');
                break;
            case Constants.ENTITY_TYPE_SORTER:
                navigate('/sorter/myprofil/user/add');
                break;
            case Constants.ENTITY_TYPE_WASHER:
                navigate('/washer/myprofil/user/add');
                break;
            case Constants.ENTITY_TYPE_OPERATOR:
                navigate('/collecte/myprofil/user/add');
                break;
            case Constants.ENTITY_TYPE_RVMOWNER:
                navigate('/rvmowner/myprofil/user/add');
                break;
            case Constants.ENTITY_TYPE_STOREOWNER:
                navigate('/storeowner/myprofil/user/add');
                break;
            case Constants.ENTITY_TYPE_CDC:
                navigate('/cdc/myprofil/user/add');
                break;
            case Constants.ENTITY_TYPE_DISTRIBUTOR:
                navigate('/distributor/myprofil/user/add');
                break;
            default:
                alert("Type d'entité non reconnu");
                break;
        }
    };

    const handleGoToUserSheet = (item) => {
        let entity_type = userContext.entityType;

        switch (entity_type) {
            case Constants.ENTITY_TYPE_FS:
                navigate('/servicesupplier/myprofil/user/sheet', { state: { user: item } });
                break;
            case Constants.ENTITY_TYPE_INDUSTRIAL:
                navigate('/industrial/myprofil/user/sheet', { state: { user: item } });
                break;
            case Constants.ENTITY_TYPE_SORTER:
                navigate('/sorter/myprofil/user/sheet', { state: { user: item } });
                break;
            case Constants.ENTITY_TYPE_WASHER:
                navigate('/washer/myprofil/user/sheet', { state: { user: item } });
                break;
            case Constants.ENTITY_TYPE_OPERATOR:
                navigate('/collecte/myprofil/user/sheet', { state: { user: item } });
                break;
            case Constants.ENTITY_TYPE_RVMOWNER:
                navigate('/rvmowner/myprofil/user/sheet', { state: { user: item } });
                break;
            case Constants.ENTITY_TYPE_STOREOWNER:
                navigate('/storeowner/myprofil/user/sheet', { state: { user: item } });
                break;
            case Constants.ENTITY_TYPE_CDC:
                navigate('/cdc/myprofil/user/sheet', { state: { user: item } });
                break;
            case Constants.ENTITY_TYPE_DISTRIBUTOR:
                navigate('/distributor/myprofil/user/sheet', { state: { user: item } });
                break;
            default:
                alert("Type d'entité non reconnu");
                break;
        }
    };

    return (
        <main>
            <section className="big-section ">
                <div className="top">
                    <h2>Les utilisateurs ({usersList.length})</h2>
                    <div className="top-button-sort">
                        <div
                            className="button-actif"
                            onClick={() => {
                                handleAddUser();
                            }}>
                            AJOUTER UN UTILISATEUR
                        </div>
                    </div>
                </div>
                <div className="tab-item">
                    <div
                        className="pre-filled-fields-1-several-item"
                        style={{ fontWeight: '600' }}>
                        <p style={{ flex: 0.3 }}>ID</p>
                        <p style={{ flex: 0.55 }}>Prenom</p>
                        <p style={{ flex: 0.55 }}>Nom</p>
                        <p style={{ flex: 1.2 }}>Email</p>
                        <p style={{ flex: 0.6 }}>Role</p>
                        <p>Société</p>
                        <p>Type</p>
                        <p style={{ flex: 0.4 }}>Statut</p>
                    </div>
                    {usersList.length ? (
                        usersList.map((item, index) => {
                            return (
                                <div
                                    style={{ cursor: 'pointer' }}
                                    key={index}
                                    className="pre-filled-fields-2-several-item"
                                    onClick={() => {
                                        handleGoToUserSheet(item);
                                    }}>
                                    <p style={{ flex: 0.3 }}>{item.id}</p>
                                    <p style={{ flex: 0.55 }}>{item.first_name}</p>
                                    <p style={{ flex: 0.55 }}>{item.last_name}</p>
                                    <p style={{ flex: 1.2 }}>{item.email}</p>
                                    <p
                                        style={{
                                            flex: 0.6,
                                            backgroundColor: _defineUserRole(item.role).color,
                                            color: Constants.$blanc,
                                        }}>
                                        {_defineUserRole(item.role).name}
                                    </p>
                                    <p>{item.entity.name}</p>
                                    <p
                                        style={{
                                            backgroundColor: _defineClientType(item.entity.type).color,
                                            color: Constants.$blanc,
                                        }}>
                                        {_defineClientType(item.entity.type).name}
                                    </p>
                                    <p
                                        style={{
                                            backgroundColor: _defineStatus(item.status).color,
                                            color: Constants.$blanc,
                                            flex: 0.4,
                                        }}>
                                        {_defineStatus(item.status).name}
                                    </p>
                                </div>
                            );
                        })
                    ) : (
                        <div className="no-data-graph ">Aucun utilisateur enregistré.</div>
                    )}
                </div>
            </section>
        </main>
    );
}
