import { useState } from 'react';
import stringEntropy from 'fast-password-entropy';
import { createNewUserPassword } from '../../API/AccountApi';
import Footer from '../../containers/Footer';

export default function PasswordCreate() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const tokenFromUrl = urlParams.get('token');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [newpasswordStatus, setPasswordStatus] = useState(0);
    const [passwordMsg, setPasswordMsg] = useState('');
    const [passwordMsgColor, setPasswordMsgColor] = useState('black');
    const [passwordUpdateStatut, setPasswordUpdateStatus] = useState(0);

    const testNewPassword = (value) => {
        setPasswordUpdateStatus(0);
        setConfirmPassword('');
        if (stringEntropy(value) < 50) {
            setPasswordMsg('Mot de passe trop faible');
            setPasswordMsgColor('red');
            setPasswordStatus(0);
        } else {
            setPasswordMsg('Mot de passe fort');
            setPasswordMsgColor('green');
            setPasswordStatus(1);
        }
        setNewPassword(value);
    };

    const testConfirmPassword = (value) => {
        if (value !== newPassword) {
            setPasswordMsg('Les mots de passe ne correspondent pas');
            setPasswordMsgColor('red');
            setPasswordUpdateStatus(0);
        } else {
            setPasswordMsg('Ok');
            setPasswordMsgColor('green');
            setPasswordUpdateStatus(1);
        }

        setConfirmPassword(value);
    };

    const getBackgroundColor = (status) => {
        if (status === 0) return 'grey';
        if (status === 1) return '#e1f3ef';
    };

    const handleCreateNewPassword = async () => {
        const userData = {
            newPassword: newPassword,
        };
        try {
            await createNewUserPassword(tokenFromUrl, userData)
                .then((response) => {
                    console.log(response);
                    alert('Mot de passe mis à jour');
                    window.location.href = '/';
                })
                .catch((error) => {
                    console.error('Erreur lors de la mise à jour du mot de passe', error);
                    alert('Le lien a expiré, veuillez recommencer la procédure de réinitialisation de mot de passe');
                });
        } catch (error) {
            console.error('Erreur lors de la mise à jour du mot de passe', error);
            alert('Erreur lors de la mise à jour du mot de passe');
        }
    };

    return (
        <main className="login-container">
            <div className="form-container resetpassword">
                <div className="title">
                    <h2>Création de votre mot de passe</h2>
                </div>
                <form className="login-form">
                    <label>Mot de passe</label>
                    <input
                        type="password"
                        name="newPassword"
                        value={newPassword || ''}
                        onChange={(e) => testNewPassword(e.target.value)}
                    />
                    <label>Confirmer le mot de passe</label>
                    <input
                        disabled={newpasswordStatus === 0 ? true : false}
                        style={{ backgroundColor: getBackgroundColor(newpasswordStatus) }}
                        type="password"
                        name="confirmPassword"
                        value={confirmPassword || ''}
                        onChange={(e) => testConfirmPassword(e.target.value)}
                    />
                    <div>
                        <label>Statut Mot de passe</label>
                        <p style={{ fontWeight: 'bold', color: passwordMsgColor, textAlign: 'center' }}>{passwordMsg}</p>
                    </div>
                </form>
                <div className="button">
                    <button
                        disabled={passwordUpdateStatut ? false : true}
                        className={passwordUpdateStatut ? 'button-actif' : 'button-inactif'}
                        onClick={handleCreateNewPassword}>
                        Sauver
                    </button>
                </div>
            </div>
            <Footer />
        </main>
    );
}
