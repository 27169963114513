// FINANCIAL REPORTING CASHIN API

export const getAllFInancialCashInByDatesCheck = (data) => {
    const name = 'getAllFInancialCashInByDatesCheck : ';
    for (let i = 0; i < data.length; i++) {
        if (!data[i].id) {
            return name + 'No id found';
        } else if (!data[i].entityId) {
            return name + 'No entity id found';
        } else {
            return true;
        }
    }
};
