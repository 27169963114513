import React, { useState, useRef } from 'react';
import { useEffect } from 'react';
import {
    getStoreByStoreownerId,
    storePickUpPending,
    getEventDetailsByStoreId,
    storePickUpReceived,
    storePickUpModifiedReduceEmptyBoxes,
    storePickUpModifiedAddEmptyBoxes,
} from '../../../API/StoreApi';
import { getStoreBoxesByStoreId } from '../../../API/StoreBoxesApi';
import { getDepotById } from '../../../API/DepotApi';
import { format } from 'date-fns';
import { Jwt } from '../../../Jwt';
import { SortAscending, SortDescending } from '@phosphor-icons/react';
import { _defineCollecteStatus, _defineBoxesStoreState, _defineStoreEventType, getDefaultOperator } from '../../../Utils/';
import * as Constants from '../../../Constants';
import { PencilSimple } from '@phosphor-icons/react';
import { groupByNestedKey } from '../../../UtilsData';

export default function StoreOwnerDelivery() {
    const userContext = new Jwt();
    const [storeData, setStoreData] = useState([]);
    const [depotData, setDepotData] = useState([]);
    const [allEventsTab, setAllEventsTab] = useState([]);
    const [archivesTab, setArchivesTab] = useState([]);
    const [showCalendar, setShowCalendar] = useState(false);
    const [filter, SetFilter] = useState('asc');
    const [storeBoxes, setStoreBoxes] = useState([]);
    const [storeEventData, setStoreEventData] = useState({ time: null, storeId: null, sorterId: null, operatorId: null });
    const [storeEventDetailsData, setStoreEventDetailsData] = useState([]);
    const [dataToReceive, setDataToReceive] = useState([]);
    const [dataToModify, setDataToModify] = useState({ from: null, storeEventId: null, suiviId: null, time: null });
    const [dataToSee, setDataToSee] = useState([]);
    const [modalStatus, setModalStatus] = useState(null);

    const dateActuelle = new Date();

    const dialogRef = useRef(null);

    useEffect(() => {
        const fetchStoreStoreEvents = async () => {
            const storeRawData = await getStoreByStoreownerId(userContext.entityId);
            setStoreData(storeRawData[0]);

            const depotRawData = await getDepotById(storeRawData[0].depot_id);
            setDepotData(depotRawData[0]);

            const result = await getEventDetailsByStoreId(storeRawData[0].id);

            const groupBySuiviId = result.reduce((acc, obj) => {
                const key = `${obj.storeEvent.suiviId}`;

                if (!acc[key]) {
                    acc[key] = [];
                }
                acc[key].push(obj);
                return acc;
            }, {});
            const storeEventsRowData = Object.keys(groupBySuiviId).map((key) => ({
                type: groupBySuiviId[key][0].storeEvent.type,
                time: groupBySuiviId[key][0].storeEvent.time,
                qty: groupBySuiviId[key].reduce((acc, obj) => acc + obj.qty, 0),
                status: groupBySuiviId[key][0].storeEvent.status,
                item: groupBySuiviId[key],
            }));
            const resultFiltered = storeEventsRowData.filter((item) => item.status !== Constants.EVENT_RECEIVED);
            setAllEventsTab(resultFiltered);

            const archiveRaw = storeEventsRowData.filter((item) => item.status === Constants.EVENT_RECEIVED);
            setArchivesTab(archiveRaw);
        };

        fetchStoreStoreEvents();
    }, [
        userContext.entityId,
    ]);

    const sortData = (data, setData) => {
        const sortedData = [...data].sort((a, b) => (filter === 'asc' ? a.date.localeCompare(b.date) : b.date.localeCompare(a.date)));
        setData(sortedData);
    };

    const modalCreatePickUp = async () => {
        setModalStatus('request');
        const storeBoxesData = await getStoreBoxesByStoreId(storeData.id);
        const boxesFilled = storeBoxesData.filter((item) => item.empty === false);
        const groupedData = groupByNestedKey(boxesFilled, 'container.name');
        const result = Object.keys(groupedData).map((name) => ({
            name,
            items: groupedData[name],
        }));
        const operator = await getDefaultOperator();
        setStoreEventData({ ...storeEventData, storeId: storeData.id, sorterId: depotData.entity_sorter_id, operatorId: operator });
        setStoreBoxes(result);
    };

    const modalModifyPickUp = async (item) => {
        if (item.status >= Constants.EVENT_ACCEPTED) {
            setModalStatus('');
            alert('Vous ne pouvez plus modifier cette collecte.');
        } else {
            setDataToModify({
                from: Constants.STOREEVENTDETAILS_FROM_STORE_MODIFIED,
                storeEventId: item.item[0].storeEvent.id,
                suiviId: item.item[0].suiviId,
            });
            if (item.type === Constants.STOREEVENT_TYPE_COLLECTE) {
                setModalStatus('modify-reduce');
                const storeBoxesData = await getStoreBoxesByStoreId(storeData.id);
                const boxesFilled = storeBoxesData.filter((item) => item.empty === true);
                const groupedData = groupByNestedKey(boxesFilled, 'container.name');

                const result = Object.keys(groupedData).map((name) => ({
                    name,
                    items: groupedData[name],
                }));

                setStoreBoxes(result);
            } else {
                setModalStatus('modify-add');
                const storeBoxesData = await getStoreBoxesByStoreId(storeData.id);
                const groupedData = groupByNestedKey(storeBoxesData, 'container.name');
                const result = Object.keys(groupedData).map((name) => ({
                    name,
                    items: groupedData[name],
                }));
                setStoreBoxes(result);
            }
        }
    };

    const modalSeePickUp = (item) => {
        const countBySuiviIDAndEmpty = item.item.reduce((acc, obj) => {
            const key = `${obj.storeEvent.suiviId}-${obj.empty}-${obj.container.name}`;

            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(obj);
            return acc;
        }, {});

        const storeEventsRowData = Object.keys(countBySuiviIDAndEmpty).map((key) => ({
            type: item.type,
            name: countBySuiviIDAndEmpty[key][0].container.name,
            suiviId: countBySuiviIDAndEmpty[key][0].storeEvent.suiviId,
            empty: countBySuiviIDAndEmpty[key][0].empty,
            item: countBySuiviIDAndEmpty[key],
            qty: countBySuiviIDAndEmpty[key].reduce((acc, obj) => acc + obj.qty, 0),
        }));

        setModalStatus('see');
        setDataToSee(storeEventsRowData);
    };

    const modalReceiveDelivery = async (item) => {
        setModalStatus('receive');
        setDataToReceive(item);
    };

    const handleValidate = async () => {
        let request;
        let data;
        switch (modalStatus) {
            case 'request':
                data = {
                    storeEventDetailsData,
                    storeEventData,
                };
                request = await storePickUpPending(data);
                break;
            case 'modify-reduce':
                data = {
                    storeEventDetailsData,
                    dataToModify,
                };
                request = await storePickUpModifiedReduceEmptyBoxes(data);
                break;
            case 'modify-add':
                data = {
                    storeEventDetailsData,
                    dataToModify,
                };
                request = await storePickUpModifiedAddEmptyBoxes(data);
                break;
            case 'receive':
                request = await storePickUpReceived(dataToReceive, dateActuelle);
                break;
            default:
                break;
        }
        if (request && request.message === 'ok') {
            alert('Opération effectuée');
            handleModalClose();
            window.location.reload();
        } else {
            alert('Une erreur est survenue');
        }
    };

    const openRightModal = (item, modalType) => {
        switch (modalType) {
            case 'request':
                modalCreatePickUp();
                break;
            case 'modify-reduce':
                modalModifyPickUp(item);
                break;
            case 'see':
                modalSeePickUp(item);
                break;
            case 'receive':
                modalReceiveDelivery(item);
                break;
            default:
                break;
        }
        if (dialogRef.current) {
            dialogRef.current.showModal();
        }
    };

    const handleModalClose = () => {
        setDataToModify({ from: null, storeEventId: null, suiviId: null, time: null });
        setDataToSee([]);
        setDataToReceive([]);
        setStoreEventDetailsData([]);
        setStoreEventData({ time: null, storeId: null, sorterId: null, operatorId: null });
        setModalStatus(null);
        setStoreBoxes([]);

        if (dialogRef.current) {
            dialogRef.current.close();
        }
    };

    useEffect(() => {
        const initialFormData = storeBoxes.map((item) => ({
            containerName: item.name,
            containerId: item.items[0].containerId,
            qty: 0,
        }));
        setStoreEventDetailsData(initialFormData);
    }, [storeBoxes]);

    const handleInputChange = (index, value) => {
        const newFormData = [...storeEventDetailsData];
        newFormData[index] = { ...newFormData[index], qty: value };

        setStoreEventDetailsData(newFormData);
    };

    return (
        <main>
            <section className="big-section">
                <div className="top top-history">
                    <h2>Liste des collectes</h2>
                    <div style={{ display: 'flex', gap: '1rem' }}>
                        <div
                            className="sort"
                            style={{ height: '2.75rem' }}>
                            <p
                                onClick={() => {
                                    setShowCalendar(false);
                                }}
                                className={!showCalendar ? 'selected' : 'classic'}>
                                En cours
                            </p>
                            <p
                                onClick={() => {
                                    setShowCalendar(true);
                                }}
                                className={showCalendar ? 'selected' : 'classic'}>
                                Archivées
                            </p>
                        </div>

                        <button
                            onClick={() => {
                                openRightModal(null, 'request');
                            }}
                            className="button-actif">
                            DEMANDER UNE COLLECTE
                        </button>
                    </div>
                </div>
                {!showCalendar ? (
                    <div className="tab-item">
                        <div
                            className="pre-filled-fields-1-several-item"
                            style={{ fontWeight: '600', width: '100%' }}>
                            <p>Type</p>
                            <p style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>Date estimée</span>
                                <span
                                    style={{ paddingRight: '0.93rem', cursor: 'pointer' }}
                                    onClick={() => {
                                        SetFilter(filter === 'asc' ? 'desc' : 'asc');
                                        sortData(allEventsTab, setAllEventsTab);
                                    }}>
                                    {filter === 'asc' ? <SortAscending size={15} /> : <SortDescending size={15} />}
                                </span>
                            </p>
                            <p>Qté caisses</p>
                            <p style={{ borderRight: '0px' }}>Statut</p>
                            <p style={{ flex: 0.2 }}></p>
                        </div>
                        {allEventsTab.length
                            ? allEventsTab.map((item, index) => {
                                  return (
                                      <div
                                          style={{ display: 'flex', flexWrap: 'wrap', width: '100%', justifyContent: 'space-between' }}
                                          key={index}>
                                          <div
                                              style={{ width: '100%' }}
                                              className="pre-filled-fields-2-several-item">
                                              <p>{_defineStoreEventType(item.type)}</p>
                                              <p>{format(item.time, 'yyyy-MM-dd')}</p>
                                              <p>{item.qty}</p>
                                              <p
                                                  onClick={() => {
                                                      item.status === Constants.EVENT_DONE && item.type === Constants.STOREEVENT_TYPE_DELIVERY
                                                          ? openRightModal(item, 'receive')
                                                          : openRightModal(item, 'see');
                                                  }}
                                                  style={{
                                                      backgroundColor: _defineCollecteStatus(item.status).color,
                                                      color: Constants.$blanc,
                                                      cursor: 'pointer',
                                                  }}>
                                                  {_defineCollecteStatus(item.status).name}
                                              </p>
                                              <p
                                                  onClick={() => {
                                                      openRightModal(item, 'modify-reduce');
                                                  }}
                                                  style={{ textAlign: 'center', padding: 0, paddingLeft: '0.93rem', cursor: 'pointer', flex: 0.2 }}>
                                                  <PencilSimple size={20} />
                                              </p>
                                          </div>
                                      </div>
                                  );
                              })
                            : null}
                    </div>
                ) : (
                    <div className="tab-item">
                        <div
                            className="pre-filled-fields-1-several-item"
                            style={{ fontWeight: '600', width: '100%' }}>
                            <p>Type</p>
                            <p style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>Date</span>
                                <span
                                    style={{ paddingRight: '0.93rem', cursor: 'pointer' }}
                                    onClick={() => {
                                        SetFilter(filter === 'asc' ? 'desc' : 'asc');
                                        sortData(allEventsTab, setAllEventsTab);
                                    }}>
                                    {filter === 'asc' ? <SortAscending size={15} /> : <SortDescending size={15} />}
                                </span>
                            </p>
                            <p>Qté caisses</p>
                            <p style={{ borderRight: '0px' }}>Statut</p>
                        </div>
                        {archivesTab.length
                            ? archivesTab.map((item, index) => {
                                  return (
                                      <div
                                          style={{ display: 'flex', flexWrap: 'wrap', width: '100%', justifyContent: 'space-between' }}
                                          key={index}>
                                          <div
                                              style={{ width: '100%' }}
                                              className="pre-filled-fields-2-several-item">
                                              <p>{_defineStoreEventType(item.type)}</p>
                                              <p>{format(item.time, 'yyyy-MM-dd')}</p>
                                              <p>{item.qty}</p>
                                              <p
                                                  onClick={() => {
                                                      item.status === Constants.EVENT_DONE && item.type === Constants.STOREEVENT_TYPE_DELIVERY
                                                          ? openRightModal(item, 'receive')
                                                          : openRightModal(item, 'see');
                                                  }}
                                                  style={{
                                                      backgroundColor: _defineCollecteStatus(item.status).color,
                                                      color: Constants.$blanc,
                                                      cursor: 'pointer',
                                                  }}>
                                                  {_defineCollecteStatus(item.status).name}
                                              </p>
                                          </div>
                                      </div>
                                  );
                              })
                            : null}
                    </div>
                )}
            </section>
            {modalStatus === 'request' ? (
                <dialog
                    ref={dialogRef}
                    className="dialog-collector">
                    <div className="title">
                        <p>Demande de collecte</p>
                        <button
                            className="button-negatif"
                            onClick={handleModalClose}>
                            X
                        </button>
                    </div>

                    {storeEventDetailsData.length ? (
                        <div>
                            {storeEventDetailsData.map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        className="collector-infos">
                                        <div className="depart-arrival-container">
                                            <div
                                                className="departure"
                                                style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                                <h4>Contenant</h4>
                                                <p>{item.containerName}</p>
                                            </div>
                                            <div>
                                                <h4>Quantité</h4>
                                                <select
                                                    value={item.qty}
                                                    onChange={(e) => {
                                                        handleInputChange(index, e.target.value);
                                                    }}>
                                                    <option value="">--</option>
                                                    {Array.from({ length: storeBoxes[index].items.length }, (_, i) => i + 1).map((num) => (
                                                        <option
                                                            key={num}
                                                            value={num}>
                                                            {num}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                            <div
                                className="depart-arrival-container"
                                style={{ display: 'flex', marginTop: '1rem' }}>
                                <div
                                    className="departure"
                                    style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '50%' }}>
                                    <h4>Date collecte :</h4>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <input
                                        style={{ width: '67%' }}
                                        min={format(dateActuelle, 'yyyy-MM-dd')}
                                        type="date"
                                        onChange={(e) => {
                                            setStoreEventData({ ...storeEventData, time: e.target.value });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="collector-validate">
                                <button
                                    className="button-actif"
                                    onClick={handleValidate}>
                                    VALIDER
                                </button>
                            </div>
                        </div>
                    ) : (
                        <div
                            className="departure"
                            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                            <h4>Aucune caisse pleine en magasin.</h4>
                        </div>
                    )}
                </dialog>
            ) : modalStatus === 'see' ? (
                <dialog
                    ref={dialogRef}
                    className="dialog-collector">
                    <div className="title">
                        <p>Informations {_defineStoreEventType(dataToSee[0].type)}</p>
                        <button
                            className="button-negatif"
                            onClick={handleModalClose}>
                            X
                        </button>
                    </div>

                    <div className="collector-infos">
                        {dataToSee &&
                            dataToSee.map((elem, index) => {
                                return (
                                    <div
                                        key={index}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            padding: '0.5rem 0',
                                        }}>
                                        <p>
                                            {elem.name} - {_defineBoxesStoreState(elem.empty)}
                                        </p>
                                        <p>x{elem.qty}</p>
                                    </div>
                                );
                            })}
                    </div>
                </dialog>
            ) : modalStatus === 'modify-reduce' ? (
                <dialog
                    ref={dialogRef}
                    className="dialog-collector">
                    <div className="title">
                        <p>Diminution de caisses vides</p>
                        <button
                            className="button-negatif"
                            onClick={handleModalClose}>
                            X
                        </button>
                    </div>

                    <div>
                        {storeEventDetailsData.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className="collector-infos">
                                    <div className="depart-arrival-container">
                                        <div
                                            className="departure"
                                            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                            <h4>Contenant</h4>
                                            <p>{item.containerName}</p>
                                        </div>
                                        <div>
                                            <h4>Quantité</h4>
                                            <select
                                                value={item.qty}
                                                onChange={(e) => {
                                                    handleInputChange(index, e.target.value);
                                                }}>
                                                <option value="">--</option>
                                                {Array.from({ length: storeBoxes[index].items.length }, (_, i) => i + 1).map((num) => (
                                                    <option
                                                        key={num}
                                                        value={num}>
                                                        {num}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>

                    <div className="collector-validate">
                        <button
                            className="button-actif"
                            onClick={handleValidate}>
                            VALIDER
                        </button>
                    </div>
                </dialog>
            ) : modalStatus === 'receive' ? (
                <dialog
                    ref={dialogRef}
                    className="dialog-collector">
                    <div className="title">
                        <p>Informations livraison</p>
                        <button
                            className="button-negatif"
                            onClick={handleModalClose}>
                            X
                        </button>
                    </div>
                    <div className="collector-infos">
                        <div
                            className="depart-arrival-container"
                            style={{ marginBottom: '1rem' }}>
                            Vous confirmez avoir reçu :
                        </div>
                        {dataToReceive.item &&
                            dataToReceive.item
                                .reduce((acc, elem) => {
                                    const existingElem = acc.find(
                                        (e) => e.suivi === elem.suivi && e.container.name === elem.container.name && e.empty === elem.empty,
                                    );

                                    if (existingElem) {
                                        existingElem.qty += elem.qty;
                                    } else {
                                        acc.push({ ...elem });
                                    }

                                    return acc;
                                }, [])
                                .map((elem, index) => {
                                    return (
                                        <div
                                            key={index}
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                padding: '0.5rem 0',
                                            }}>
                                            <p>
                                                {elem.container.name} - {_defineBoxesStoreState(elem.empty)}
                                            </p>
                                            <p>x{elem.qty}</p>
                                        </div>
                                    );
                                })}
                    </div>
                    <div className="collector-validate">
                        <button
                            className="button-actif"
                            onClick={handleValidate}>
                            VALIDER
                        </button>
                    </div>
                </dialog>
            ) : modalStatus === 'modify-add' ? (
                <dialog
                    ref={dialogRef}
                    className="dialog-collector">
                    <div className="title">
                        <p>Besoin de caisses vides</p>
                        <button
                            className="button-negatif"
                            onClick={handleModalClose}>
                            X
                        </button>
                    </div>

                    <div>
                        {storeEventDetailsData.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className="collector-infos">
                                    <div className="depart-arrival-container">
                                        <div
                                            className="departure"
                                            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                            <h4>Contenant</h4>
                                            <p>{item.containerName}</p>
                                        </div>
                                        <div>
                                            <h4>Quantité</h4>
                                            <select
                                                value={item.qty}
                                                onChange={(e) => {
                                                    handleInputChange(index, e.target.value);
                                                }}>
                                                <option value="">--</option>
                                                {Array.from({ length: 5 }, (_, i) => i + 1).map((num) => (
                                                    <option
                                                        key={num}
                                                        value={num}>
                                                        {num}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>

                    <div className="collector-validate">
                        <button
                            className="button-actif"
                            onClick={handleValidate}>
                            VALIDER
                        </button>
                    </div>
                </dialog>
            ) : (
                <dialog ref={dialogRef}></dialog>
            )}
        </main>
    );
}
