import { getGearAPIApiEndpoint } from './endpoints';
import * as Sentry from '@sentry/react';
import { defaultHeaders } from './headers';

const GEAR_API_BASE_URL = getGearAPIApiEndpoint();

const BOCOLOCO_CREATE_ORDER = '/order/new';
const BOCOLOCO_GET_ORDERBYID = '/order/getbyid';
const BOCOLOCO_GET_ORDERSDETAILS_BYENTITYID = '/order/getdetailsbyentityid';
const BOCOLOCO_ORDERSDETAILS_ASSIGN_TOSUPPLIER = '/order/details/assigntosupplier';
const BOCOLOCO_GET_ORDERSDETAILS_NOTCOMPLETED = '/order/details/notcompleted';
const BOCOLOCO_ORDERSDETAILS_ASSIGN_TOWASHER = '/order/details/assigntowasher';
const BOCOLOCO_SET_ORDERDETAIL_RECEIVED = '/order/details/received';
const BOCOLOCO_GET_ORDERSDETAILS_ALL = '/order/details/all';
const BOCOLOCO_GET_ORDERSDETAILS_INTRANSIT = '/order/details/intransit';
const BOCOLOCO_GET_ORDERSDETAILS_BYDATES = '/order/details/bydates';

export function IndusOrder(declarationDetailsData, entity_id, user_id) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_CREATE_ORDER;
    var url_args = '?entity_id=' + entity_id + '&user_id=' + user_id;
    const url = encodeURI(url_base + url_args);
    return fetch(url, {
        method: 'POST',
        body: JSON.stringify(declarationDetailsData),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            console.log('ERROR ORDER INDUS');
            console.log(error);
            throw error;
        });
}

export function getOrderById(id) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_ORDERBYID;
    var url_args = '?id=' + id;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function getOrderDetailsByEntityId(entity_id) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_ORDERSDETAILS_BYENTITYID;
    var url_args = '?entity_id=' + entity_id;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function AssignOrderDetailToSupplier(formData, dateasupplier) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_ORDERSDETAILS_ASSIGN_TOSUPPLIER;
    const url_args = '?dateasupplier=' + dateasupplier;
    const url = encodeURI(url_base + url_args);
    return fetch(url, {
        method: 'PUT',
        body: JSON.stringify(formData),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            console.log('ERROR ASSIGN ORDER DETAIL');
            console.log(error);
            throw error;
        });
}

export function getOrdersDetailsNotCompleted() {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_ORDERSDETAILS_NOTCOMPLETED;
    const url = encodeURI(url_base);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function AssignOrderDetailToWasher(formData, dateawasher) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_ORDERSDETAILS_ASSIGN_TOWASHER;
    const url_args = '?dateawasher=' + dateawasher;
    const url = encodeURI(url_base + url_args);
    return fetch(url, {
        method: 'PUT',
        body: JSON.stringify(formData),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            console.log('ERROR ASSIGN ORDER DETAIL TO WASHER');
            console.log(error);
            throw error;
        });
}

export function setOrderDetailsReceived(formData) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_SET_ORDERDETAIL_RECEIVED;
    const url = encodeURI(url_base);
    return fetch(url, {
        method: 'PUT',
        body: JSON.stringify(formData),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            console.log('ERROR ASSIGN ORDER DETAIL TO WASHER');
            console.log(error);
            throw error;
        });
}

export function getAllOrdersDetails() {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_ORDERSDETAILS_ALL;
    const url = encodeURI(url_base);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function getAllOrdersDetailsByDates(startDate, endDate) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_ORDERSDETAILS_BYDATES;
    var url_args = '?startDate=' + startDate + '&endDate=' + endDate;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function getOrderDetailsInTransit() {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_ORDERSDETAILS_INTRANSIT;
    const url = encodeURI(url_base);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}
