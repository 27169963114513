import { useState, useEffect } from 'react';
import { getContainerById, updateContainer } from '../../../../../API/ContainerApi';
import { useLocation, useNavigate } from 'react-router-dom';
import { getIndustrials } from '../../../../../API/IndustrialApi';

import {
    checkContainerFormErrors,
    checkIsInputEmpty,
    _defineStatus,
    _defineContainerUsage,
    containerUsageTab,
    _defineContainerCat,
    containerCatTab,
    containerOwnerStatusTab,
    _defineContainerOwnerStatus,
    containerTypesTab,
    _defineContainerType,
    _defineContainerSubType,
    containerMaterialTab,
    _defineContainerMaterial,
    containerPaletTypeTab,
    _defineContainerPaletType,
    containerSubTypesTabBowl,
    containerSubTypesTabBottle,
    containerSubTypesTabContainer,
} from '../../../../../Utils';
import * as Constant from '../../../../../Constants';
export default function Updatecontainer() {
    const location = useLocation();
    const { id } = location.state || {};
    const [errorMessage, setErrorMessage] = useState([]);
    const [errorEmpty, setErrorEmpty] = useState('');
    const [containerData, setContainerData] = useState([]);
    const [containerSubTypeTab, setContainerSubTypeTab] = useState([]);
    const [containerOwnerBrandTab, setContainerOwnerBrandTab] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchContainerData = async () => {
            try {
                const containerData = await getContainerById(id);
                setContainerData(containerData[0]);

                const industrials = await getIndustrials();
                setContainerOwnerBrandTab(industrials);
            } catch (error) {
                console.error('Erreur lors de la récupération des infos du contenant', error);
            }
        };
        fetchContainerData();
    }, [id]);

    const [formErrors, setFormErrors] = useState({
        name: false,
        sku: false,
        unitValue: false,
        weight: false,
        palet_nbcontainer: false,
        dim_width: false,
        dim_length: false,
        dim_height_without_cover: false,
        dim_height_with_cover: false,
        dim_base_diameter: false,
        dim_opening_diameter: false,
        url: false,
    });
    const requiredFields = [
        'name',
        'sku',
        'cat',
        'owner',
        'type',
        'material',
        'unit',
        'unitValue',
        'weight',
        'dim_height_without_cover',
        'dim_height_with_cover',
        'palet_type',
        'palet_nbcontainer',
        'usage',
        'price',
    ];

    const handleInputChange = (e) => {
        setContainerData({ ...containerData, [e.target.name]: e.target.value });
        if (e.target.name === 'type') {
            const type = parseInt(e.target.value);
            if (type === Constant.CONTAINER_TYPE_BOWL) {
                setContainerSubTypeTab(containerSubTypesTabBowl);
            } else if (type === Constant.CONTAINER_TYPE_BOTTLE) {
                setContainerSubTypeTab(containerSubTypesTabBottle);
            } else if (type === Constant.CONTAINER_TYPE_CONTAINER) {
                setContainerSubTypeTab(containerSubTypesTabContainer);
            } else {
                setContainerSubTypeTab([]);
            }
        }
    };
    const handleSaveChanges = async (e) => {
        e.preventDefault();
        setErrorMessage([]);
        setErrorEmpty('');
        const inputEmpty = checkIsInputEmpty(requiredFields, containerData, setFormErrors, setErrorEmpty);

        console.log(inputEmpty);
        console.log(containerData);
        if (!inputEmpty) {
            const checkFormat = checkContainerFormErrors(containerData, formErrors, setFormErrors, setErrorMessage);
            console.log(checkFormat);
            if (checkFormat) {
                return;
            } else {
                const updateContainerData = await updateContainer(id, containerData);
                if (updateContainerData.message === 'sku already exist') {
                    alert('Ce SKU existe déjà');
                } else {
                    alert('Modifications enregistrées avec succès');
                    navigate(-1);
                }
            }
        } else {
            console.log('Il reste des champs obligatoires vides');
        }
    };

    return (
        <main>
            <section className="big-section">
                <h2>Modifier un contenant standard</h2>
                {containerData.id ? (
                    <div className="form-container">
                        <section>
                            <div>
                                <label>Type de contenant:</label>
                                <select
                                    className={formErrors.usage ? 'error' : ''}
                                    name="usage"
                                    value={containerData.usage || ''}
                                    onChange={handleInputChange}>
                                    <option value={''}>--</option>
                                    {containerUsageTab.map((type, index) => (
                                        <option
                                            key={index}
                                            value={parseInt(type)}>
                                            {_defineContainerUsage(type)}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label>Nom:</label>
                                <input
                                    className={formErrors.name ? 'error' : ''}
                                    type="text"
                                    name="name"
                                    value={containerData.name || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <label>SKU:</label>
                                <input
                                    className={formErrors.sku ? 'error' : ''}
                                    type="text"
                                    name="sku"
                                    value={containerData.sku || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <label>Gamme:</label>
                                <select
                                    className={formErrors.cat ? 'error' : ''}
                                    name="cat"
                                    value={containerData.cat || ''}
                                    onChange={handleInputChange}>
                                    {containerCatTab.map((type, index) => (
                                        <option
                                            key={index}
                                            value={parseInt(type)}>
                                            {_defineContainerCat(type)}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label>Propriétaire:</label>
                                <select
                                    name="owner"
                                    value={containerData.owner || ''}
                                    onChange={handleInputChange}>
                                    {containerOwnerStatusTab.map((status, index) => (
                                        <option
                                            key={index}
                                            value={parseInt(status)}>
                                            {_defineContainerOwnerStatus(status)}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div>
                                <label>Marque:</label>
                                <select
                                    disabled={
                                        parseInt(containerData.owner) === Constant.CONTAINER_OWNER_STATUS_STANDARD || containerData.owner === null
                                            ? true
                                            : false
                                    }
                                    value={parseInt(containerData.owner) === Constant.CONTAINER_OWNER_STATUS_STANDARD ? '' : containerData.owner_brand}
                                    name="owner_brand"
                                    onChange={handleInputChange}>
                                    <option value={''}>--</option>
                                    {containerOwnerBrandTab.map((owner, index) => (
                                        <option
                                            key={index}
                                            value={owner.id}>
                                            {owner.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label>Format:</label>
                                <select
                                    className={formErrors.type ? 'error' : ''}
                                    name="type"
                                    value={containerData.type || ''}
                                    onChange={handleInputChange}>
                                    <option value="">--</option>
                                    {containerTypesTab.map((type, index) => (
                                        <option
                                            key={index}
                                            value={parseInt(type)}>
                                            {_defineContainerType(type)}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label>Sous format:</label>
                                <select
                                    disabled={containerSubTypeTab.length === 0 ? true : false}
                                    className={formErrors.subtype ? 'error' : ''}
                                    name="subtype"
                                    value={containerData.subtype || ''}
                                    onChange={handleInputChange}>
                                    <option value={''}>--</option>
                                    {containerSubTypeTab.map((type, index) => (
                                        <option
                                            key={index}
                                            value={type}>
                                            {_defineContainerSubType(type)}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label className="small-title">Matériaux:</label>
                                <select
                                    value={containerData.material || ''}
                                    className={formErrors.material ? 'error' : ''}
                                    name="material"
                                    onChange={handleInputChange}>
                                    <option value={''}>--</option>
                                    {containerMaterialTab.map((material, index) => (
                                        <option
                                            key={index}
                                            value={parseInt(material)}>
                                            {_defineContainerMaterial(material)}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </section>
                        <section>
                            <div>
                                <label className="small-title">Hauteur hors couvercle (mm):</label>
                                <input
                                    value={containerData.dim_height_without_cover || ''}
                                    className={formErrors.dim_height_without_cover ? 'error' : ''}
                                    type="number"
                                    name="dim_height_without_cover"
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div>
                                <label className="small-title">Hauteur avec couvercle (mm):</label>
                                <input
                                    value={containerData.dim_height_with_cover || ''}
                                    className={formErrors.dim_height_with_cover ? 'error' : ''}
                                    type="number"
                                    name="dim_height_with_cover"
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div>
                                <label className="small-title">Largeur (mm) :</label>
                                <input
                                    value={containerData.dim_width || ''}
                                    className={formErrors.dim_width ? 'error' : ''}
                                    type="number"
                                    name="dim_width"
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div>
                                <label className="small-title">Longueur (mm) :</label>
                                <input
                                    value={containerData.dim_length || ''}
                                    className={formErrors.dim_length ? 'error' : ''}
                                    type="text"
                                    name="dim_length"
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div>
                                <label className="small-title">Diamètre base (mm) :</label>
                                <input
                                    value={containerData.dim_base_diameter || ''}
                                    className={formErrors.dim_base_diameter ? 'error' : ''}
                                    type="number"
                                    name="dim_base_diameter"
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div>
                                <label className="small-title">Diamètre ouverture (mm):</label>
                                <input
                                    value={containerData.dim_opening_diameter || ''}
                                    className={formErrors.dim_opening_diameter ? 'error' : ''}
                                    type="number"
                                    name="dim_opening_diameter"
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                        </section>
                        <section>
                            <div>
                                <label>Unité:</label>
                                <select
                                    value={containerData.unit || ''}
                                    className={formErrors.unit ? 'error' : ''}
                                    name="unit"
                                    onChange={handleInputChange}>
                                    <option value={1}>mL</option>
                                </select>
                            </div>
                            <div>
                                <label>Valeur unité:</label>
                                <input
                                    className={formErrors.unitValue ? 'error' : ''}
                                    type="text"
                                    name="unitValue"
                                    value={containerData.unitValue || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <label className="small-title">Poids (gr):</label>
                                <input
                                    value={containerData.weight || ''}
                                    className={formErrors.weight ? 'error' : ''}
                                    type="number"
                                    name="weight"
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div>
                                <label>Couleur:</label>
                                <input
                                    className={formErrors.color ? 'error' : ''}
                                    type="text"
                                    name="color"
                                    value={containerData.color || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </section>

                        <section>
                            <div>
                                <label>Type de palette:</label>

                                <select
                                    className={formErrors.palet_type ? 'error' : ''}
                                    name="palet_type"
                                    value={containerData.palet_type || ''}
                                    onChange={handleInputChange}>
                                    <option value={''}>--</option>
                                    {containerPaletTypeTab.map((type, index) => (
                                        <option
                                            key={index}
                                            value={type}>
                                            {_defineContainerPaletType(type)}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label>Nb contenants / palette:</label>
                                <input
                                    className={formErrors.palet_nbcontainer ? 'error' : ''}
                                    type="text"
                                    name="palet_nbcontainer"
                                    value={containerData.palet_nbcontainer || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <label>URL specifications techniques :</label>
                                <input
                                    className={formErrors.url ? 'error' : ''}
                                    type="text"
                                    name="dim_url"
                                    value={containerData.url || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <label>Montant consigne :</label>
                                <input
                                    className={formErrors.price ? 'error' : ''}
                                    type="number"
                                    name="price"
                                    value={containerData.price || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <label>Statut:</label>
                                <select
                                    style={{ backgroundColor: _defineStatus(containerData.status).color, color: Constant.$blanc }}
                                    name="status"
                                    value={containerData.status}
                                    onChange={handleInputChange}>
                                    <option value={0}>Actif</option>
                                    <option value={100}>Inactif</option>
                                </select>
                            </div>
                        </section>
                    </div>
                ) : null}
                <p className="error-form">{errorEmpty && errorEmpty}</p>

                {errorMessage.length
                    ? errorMessage.map((item, index) => {
                          return (
                              <div
                                  className="error-form"
                                  key={index}>
                                  {item}
                              </div>
                          );
                      })
                    : null}
                <div className="button-confirm">
                    <button
                        className="
                        button-actif"
                        onClick={handleSaveChanges}>
                        Enregistrer les modifications
                    </button>
                </div>
            </section>
        </main>
    );
}
