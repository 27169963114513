import Cookies from 'js-cookie';
import * as Constants from '../../Constants';

export const storeownerMenuOperation = () => {
    return ['storeowner'];
};

const getSubAccessOperationStoreowner = () => {
    let sousMenu = [{ title: 'Magasin' }];
    const access = Cookies.get('access').length ? JSON.parse(Cookies.get('access')) : null;
    if (access) {
        let access_operation = access.storeowner.operation;
        if (access_operation.magasins.liste_rvm === 1) {
            sousMenu.push({ name: 'Etat collecteurs', link: '/storeowner/operation/collector/list' });
        }
        if (access_operation.magasins.details_magasin === 1) {
            sousMenu.push({ name: 'Caisses Magasins', link: '/storeowner/operation/store/sheet' });
        }
        if (access_operation.magasins.livraison_et_reprise === 1) {
            sousMenu.push({ name: 'Demande de collecte', link: '/storeowner/operation/delivery/list' });
        }
    }
    return sousMenu;
};

export const getSousMenuStoreownerOperation = (onglet) => {
    switch (onglet) {
        case 'storeowner':
            return getSubAccessOperationStoreowner();
        default:
            return [];
    }
};

export const storeownerMenuData = () => {
    return [];
};

export const getSousMenuStoreownerData = (onglet) => {
    switch (onglet) {
        default:
            return [];
    }
};

export const storeownerMenuFacturation = () => {
    return ['financial_management'];
};
const getSubAccessFacturationStoreowner = () => {
    let sousMenu = [{ title: 'Finance' }];
    const access = Cookies.get('access').length ? JSON.parse(Cookies.get('access')) : null;
    if (access) {
        let access_facturation = access.storeowner.financial;
        if (access_facturation.gestion_financiere.transaction === 1) {
            sousMenu.push({ name: 'Transaction collecteur', link: '/storeowner/financial/transaction' });
        }
        if (access_facturation.gestion_financiere.reportings === 1) {
            sousMenu.push({ name: 'Reportings', link: '/storeowner/financial/reportings' });
        }
    }
    return sousMenu;
};

export const getSousMenuStoreownerFacturation = (onglet) => {
    switch (onglet) {
        case 'financial_management':
            return getSubAccessFacturationStoreowner();
        default:
            return [];
    }
};

export const storeownerMenuAccount = () => {
    return ['my_account'];
};

export const getSousMenuStoreownerAccount = (onglet) => {
    const role = Cookies.get('role');
    const isAdmin = role === Constants.ENTITY_ROLE_ADMIN;
    switch (onglet) {
        case 'my_account':
            if (isAdmin) {
                return [
                    { title: 'Mon compte' },
                    { name: 'Mes informations personnelles', link: '/storeowner/myprofil/personal_info' },
                    { name: 'Ma société', link: '/storeowner/myprofil/company_info' },
                    { name: 'Gestion des comptes', link: '/storeowner/myprofil/rolemanagement' },
                ];
            } else {
                return [
                    { title: 'Mon compte' },
                    { name: 'Mes informations personnelles', link: '/storeowner/myprofil/personal_info' },
                    { name: 'Ma société', link: '/storeowner/myprofil/company_info' },
                ];
            }

        default:
            return [];
    }
};
