import { Routes, Route, Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import * as Constants from '../../Constants';

import CollecteList from '../../containers/operation/collecte_interface/CollecteList';
import CollecteScheduled from '../../containers/operation/collecte_interface/CollecteScheduled';
import CollecteHome from '../../containers/operation/collecte_interface/CollecteHome';
import CollecteHistory from '../../containers/operation/collecte_interface/CollecteHistory';
import Documentation from '../../containers/Documentation';
import Support from '../../containers/account/Support';
import UpdatePassword from '../../containers/account/UpdatePassword';
import PreHome from '../../containers/PreHome';
import ErrorPage from '../../Components/ErrorPage';
import Unknown from '../../containers/account/Unknown';

// Profil
import ProfilPersonalInfo from '../../containers/account/ProfilPersonalInfo';
import ProfilCompanyInfo from '../../containers/account/ProfilCompanyInfo';
import ProfilAccess from '../../containers/account/ProfilAccess';
import ProfilRoleManagement from '../../containers/account/ProfilRoleManagement';

export default function CollecteInterface() {
    const role = Cookies.get('role');
    const isAdmin = role === Constants.ENTITY_ROLE_ADMIN;
    return (
        <Routes>
            <Route
                path="/error"
                element={<ErrorPage />}
            />
            <Route
                path="/prehome"
                element={<PreHome />}
            />
            <Route
                path="/support"
                element={<Support />}
            />
            <Route
                path="/documentation"
                element={<Documentation />}
            />
            <Route
                path="/updatepassword"
                element={<UpdatePassword />}
            />
            <Route
                path="/collecte/operation/home"
                element={<CollecteHome />}
            />
            <Route
                path="/collecte/operation/list"
                element={<CollecteList />}
            />
            <Route
                path="/collecte/operation/scheduled"
                element={<CollecteScheduled />}
            />
            <Route
                path="/collecte/operation/history"
                element={<CollecteHistory />}
            />
            <Route
                path="/collecte/myprofil/personal_info"
                element={<ProfilPersonalInfo />}
            />
            <Route
                path="/collecte/myprofil/company_info"
                element={<ProfilCompanyInfo />}
            />
            <Route
                path="/collecte/myprofil/access"
                element={<ProfilAccess />}
            />
            {isAdmin ? (
                <Route
                    path="/collecte/myprofil/rolemanagement"
                    element={<ProfilRoleManagement />}
                />
            ) : null}
            <Route
                path="/collecte/operation/unknown"
                element={<Unknown />}
            />
            <Route
                path="*"
                element={
                    <Navigate
                        replace
                        to="/collecte/operation/unknown"
                    />
                }
            />
        </Routes>
    );
}
