// SORTER API

export const getSorterEventsByWasherCheck = (data) => {
    const name = 'getSorterEventsByWasher : ';
    for (let i = 0; i < data.length; i++) {
        if (!data[i].id) {
            return name + 'No id found';
        } else if (!data[i].suivi.id) {
            return name + 'No suivi id found';
        } else if (!data[i].suivi.sorterDeclarationDetails) {
            return name + 'No sorter Declaration Details found';
        } else if (data[i].suivi.sorterDeclarationDetails.length) {
            for (let j = 0; j < data[i].suivi.sorterDeclarationDetails.length; j++) {
                if (!data[i].suivi.sorterDeclarationDetails[j].id) {
                    return name + 'No sorter declaration id found';
                } else if (!data[i].suivi.sorterDeclarationDetails[j].container.id) {
                    return name + 'No container id found';
                } else {
                    return true;
                }
            }
        } else {
            return true;
        }
    }
};
