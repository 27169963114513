import { useState, useEffect } from 'react';
import franceData from '../../../Assets/data/france.json';
import { ComposableMap, Geographies, Geography, Marker } from 'react-simple-maps';
import { getSorters } from '../../../API/SorterApi';
import { getCollectors } from '../../../API/CollectorApi';
import { getCollectorCheck } from '../../../API/CheckApi/CheckCollectorApi';
import { getIndustrials } from '../../../API/IndustrialApi';
import * as Constants from '../../../Constants';
import { useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
export default function DistribDataHome() {
    const navigate = useNavigate();
    const [allOperatorAddress, setAllOperatorAddress] = useState([]);
    const [totalByType, setTotalByType] = useState({ sorter: 0, washer: 0, collector: 0, indus: 0 });
    useEffect(() => {
        const fetchData = async () => {
            const { gearApi } = await import('../../../gearApi.js');

            // POUR LA CARTE
            const industrialAddressRawData = await getIndustrials();
            const sorterAddressRawData = await getSorters();
            const washerAddressRawData = await gearApi.washer.getWashers();
            const collectorAddressRawData = await getCollectors();
            const collectorAddressRawDataCheck = getCollectorCheck(collectorAddressRawData);
            if (collectorAddressRawData.length && collectorAddressRawDataCheck !== true) {
                console.error(collectorAddressRawDataCheck);
                Sentry.captureException(collectorAddressRawDataCheck);
                navigate('/error');
                return;
            }

            const industrialAddress = AddressTab(industrialAddressRawData, 1);
            const sorterAddress = AddressTab(sorterAddressRawData, 2);
            const washerAddress = AddressTab(washerAddressRawData, 3);
            const collectorAddress = AddressTab(collectorAddressRawData, 5);

            const allOperatorAddressRaw = [...industrialAddress, ...sorterAddress, ...washerAddress, ...collectorAddress];
            setAllOperatorAddress(allOperatorAddressRaw);

            setTotalByType({ sorter: sorterAddress.length, washer: washerAddress.length, collector: collectorAddress.length, indus: industrialAddress.length });
        };
        fetchData();
    }, [navigate]);

    const AddressTab = (data) => {
        return data.map((objet) => {
            const lat = objet.lat ? objet.lat : objet.store_lat;
            const long = objet.long ? objet.long : objet.store_long;
            return { latitude: lat, longitude: long };
        });
    };
    return (
        <main>
            <div className="data-container">
                <div className="header-container">
                    <div
                        className="header-title"
                        style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <h1>CHIFFRES CLÉS</h1>
                    </div>
                </div>
                <div style={{ display: 'flex', width: '100%' }}>
                    <section
                        className="section-graph"
                        style={{ width: '50%' }}>
                        <div className="title">Parc tous opérateurs confondus</div>

                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ width: '60%' }}>
                                <ComposableMap
                                    projection="geoMercator"
                                    projectionConfig={{
                                        scale: 2200,
                                        center: [2.5, 47.4],
                                    }}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                    }}>
                                    <Geographies geography={franceData}>
                                        {({ geographies }) =>
                                            geographies.map((geo) => (
                                                <Geography
                                                    key={geo.rsmKey}
                                                    geography={geo}
                                                    fill="#EAEAEC"
                                                    stroke="#D6D6DA"
                                                />
                                            ))
                                        }
                                    </Geographies>

                                    {allOperatorAddress.map((position, index) => (
                                        <Marker
                                            key={index}
                                            coordinates={[position.longitude, position.latitude]}>
                                            <circle
                                                cx={0}
                                                cy={0}
                                                r={5}
                                                fill="red"
                                            />
                                        </Marker>
                                    ))}
                                </ComposableMap>
                            </div>
                            <div
                                style={{
                                    width: '40%',
                                    color: Constants.$noir,
                                    fontSize: '20px',
                                }}>
                                <p style={{ fontWeight: 'bold', fontSize: '30px' }}>
                                    {totalByType.indus} <span style={{ fontWeight: '400', fontSize: '20px' }}>marques</span>
                                </p>
                                <p style={{ fontWeight: 'bold', fontSize: '30px' }}>
                                    {totalByType.collector} <span style={{ fontWeight: '400', fontSize: '20px' }}>collecteurs</span>
                                </p>
                                <p style={{ fontWeight: 'bold', fontSize: '30px' }}>
                                    {totalByType.sorter} <span style={{ fontWeight: '400', fontSize: '20px' }}>centres de tri</span>
                                </p>
                                <p style={{ fontWeight: 'bold', fontSize: '30px', marginBottom: '0px' }}>
                                    {totalByType.washer} <span style={{ fontWeight: '400', fontSize: '20px' }}>centres de lavage</span>
                                </p>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </main>
    );
}
