import { useState, useEffect } from 'react';
import { _defineStatus, transformDateFull, _defineCollectorType, _defineCount } from '../../../../../Utils';
import { getStoreByStoreownerId } from '../../../../../API/StoreApi';
import { getCollectorsByStoreOwner } from '../../../../../API/CollectorApi';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Pencil } from '@phosphor-icons/react';
import * as Sentry from '@sentry/react';
import * as Constant from '../../../../../Constants';

export default function StoreownerSheet() {
    const [filterStoreSelected, setFilterStoreSelected] = useState('desc');
    const [filterRvmSelected, setFilterRvmSelected] = useState('desc');
    const [storeownerStoresData, setStoreownerStoresData] = useState([]);
    const [storeownerCollectorData, setStoreownerCollectorData] = useState([]);
    const location = useLocation();
    const { storeowner } = location.state || {};
    console.log(storeowner);

    useEffect(() => {
        const fetchRvmownerData = async () => {
            try {
                const storeownerStore = await getStoreByStoreownerId(storeowner.id);
                setStoreownerStoresData(storeownerStore);

                const storeownerCollector = await getCollectorsByStoreOwner(storeowner.id);

                setStoreownerCollectorData(storeownerCollector);
            } catch (err) {
                Sentry.captureException(err);
                console.error('Erreur lors de la récupération des infos du gestionnaire de RVM', err);
            }
        };
        fetchRvmownerData();
    }, [storeowner.id]);

    const sortDataAsc = (data, setData, type) => {
        if (type === 'store') {
            const sortedData = [...data].sort((a, b) => a.name.localeCompare(b.name));
            setData(sortedData);
            setFilterStoreSelected('asc');
        } else if (type === 'rvm') {
            const sortedData = [...data].sort((a, b) => a.store_name.localeCompare(b.store_name));
            setData(sortedData);
            setFilterRvmSelected('asc');
        }
    };

    const sortDataDesc = (data, setData, type) => {
        if (type === 'store') {
            const sortedData = [...data].sort((a, b) => b.name.localeCompare(a.name));
            setData(sortedData);
            setFilterStoreSelected('desc');
        }
        if (type === 'rvm') {
            const sortedData = [...data].sort((a, b) => b.store_name.localeCompare(a.store_name));
            setData(sortedData);
            setFilterRvmSelected('desc');
        }
    };

    return (
        <main>
            {storeowner && (
                <div className="sheet">
                    <div className="sheet-top-infos">
                        <div className="title">
                            <Link
                                className="update-indus"
                                to={'/servicesupplier/operation/storeowner/update'}
                                state={{ id: storeowner.id }}>
                                <div className="icon">
                                    <Pencil size={20} />
                                </div>
                            </Link>
                            <h2>{storeowner.company_name}</h2>
                            <div
                                className="status"
                                style={{ backgroundColor: _defineStatus(storeowner.status).color }}>
                                {_defineStatus(storeowner.status).name}
                            </div>
                        </div>
                        <section className="item-info-container">
                            <div className="border">
                                <div>
                                    <p>
                                        Société : <span>{storeowner.company_name}</span>
                                    </p>
                                    <p>
                                        Siret : <span>{storeowner.siret}</span>
                                    </p>
                                    <p>
                                        Tva intracommunautaire : <span>{storeowner.tva_intra}</span>
                                    </p>
                                    <p>
                                        Website : <span>{storeowner.website}</span>
                                    </p>
                                </div>
                            </div>
                            <div className="border">
                                <div>
                                    <p>
                                        Adresse 1 : <span>{storeowner.address_1}</span>
                                    </p>
                                    <p>
                                        Adresse 2 : <span>{storeowner.address_2}</span>
                                    </p>
                                    <p>
                                        Ville : <span>{storeowner.city}</span>
                                    </p>
                                    <p>
                                        Code postal : <span>{storeowner.zipcode}</span>
                                    </p>
                                </div>
                            </div>
                            <div className="border">
                                <div>
                                    <p>
                                        Nom du contact : <span>{storeowner.contact_name}</span>
                                    </p>
                                    <p>
                                        Email du contact : <span>{storeowner.contact_email}</span>
                                    </p>
                                    <p>
                                        Téléphone du contact : <span>{storeowner.contact_phone}</span>
                                    </p>
                                </div>
                            </div>
                        </section>
                    </div>
                    <section className="big-section">
                        <div className="top">
                            <h2 style={{ margin: '0' }}>Les magasins ({storeownerStoresData.length})</h2>
                            <div className="top-button-sort">
                                <div className="filter">
                                    <p className="sort">Trier par</p>
                                    <div>
                                        <p
                                            className={filterStoreSelected === 'asc' ? 'selected' : 'classic'}
                                            onClick={() => {
                                                sortDataAsc(storeownerStoresData, setStoreownerStoresData, 'store');
                                            }}>
                                            A &gt; Z
                                        </p>
                                        <p
                                            className={filterStoreSelected === 'desc' ? 'selected' : 'classic'}
                                            onClick={() => {
                                                sortDataDesc(storeownerStoresData, setStoreownerStoresData, 'store');
                                            }}>
                                            Z &gt; A
                                        </p>
                                    </div>
                                </div>
                                <Link
                                    to={'/servicesupplier/operation/store/add'}
                                    state={{ depot_id: 0, depot_name: 0, rvmowner_id: 0 }}
                                    className="button-actif">
                                    AJOUTER UN MAGASIN
                                </Link>
                            </div>
                        </div>

                        <div className="tab-item">
                            <div
                                className="pre-filled-fields-1-several-item"
                                style={{ fontWeight: '600' }}>
                                <p>Nom</p>
                                <p>Adresse 1</p>
                                <p>Adresse 2</p>
                                <p>Ville</p>
                                <p>Code postal</p>
                                <p>Nb RVM</p>
                                <p>Statut</p>
                            </div>

                            {storeownerStoresData.length
                                ? storeownerStoresData.map((item, index) => {
                                      return (
                                          <Link
                                              to={'/servicesupplier/operation/store/sheet'}
                                              state={{ id: item.id }}
                                              key={index}
                                              className="pre-filled-fields-2-several-item">
                                              <p>{item.name}</p>
                                              <p>{item.address_1}</p>
                                              <p>{item.address_2}</p>
                                              <p>{item.city}</p>
                                              <p>{item.zipcode}</p>

                                              <p
                                                  style={{
                                                      backgroundColor: _defineCount(item.count_rvm).backgroundcolor,
                                                      color: _defineCount(item.count_rvm).color,
                                                      fontWeight: _defineCount(item.count_rvm).fontweight,
                                                  }}>
                                                  {item.count_rvm}
                                              </p>
                                              <p
                                                  style={{
                                                      backgroundColor: _defineStatus(item.status).color,
                                                      color: Constant.$blanc,
                                                      borderTopRightRadius: '0.6rem',
                                                      borderBottomRightRadius: '0.6rem',
                                                  }}>
                                                  {_defineStatus(item.status).name}
                                              </p>
                                          </Link>
                                      );
                                  })
                                : null}
                        </div>
                    </section>

                    <section className="big-section">
                        <div className="top">
                            <h2 style={{ margin: '0' }}>Les RVM ({storeownerCollectorData.length})</h2>
                            <div className="top-button-sort">
                                <div className="filter">
                                    <p className="sort">Trier par</p>
                                    <div>
                                        <p
                                            className={filterRvmSelected === 'asc' ? 'selected' : 'classic'}
                                            onClick={() => {
                                                sortDataAsc(storeownerCollectorData, setStoreownerCollectorData, 'rvm');
                                            }}>
                                            A &gt; Z
                                        </p>
                                        <p
                                            className={filterRvmSelected === 'desc' ? 'selected' : 'classic'}
                                            onClick={() => {
                                                sortDataDesc(storeownerCollectorData, setStoreownerCollectorData, 'rvm');
                                            }}>
                                            Z &gt; A
                                        </p>
                                    </div>
                                </div>
                                <Link
                                    to={'/servicesupplier/operation/collector/add'}
                                    state={{ depot_id: 0, rvmowner_id: 0, store: null }}
                                    className="button-actif">
                                    AJOUTER UNE RVM
                                </Link>{' '}
                            </div>
                        </div>

                        <div className="tab-item">
                            <div
                                className="pre-filled-fields-1-several-item"
                                style={{ fontWeight: '600' }}>
                                <p>Id</p>
                                <p>Magasin</p>
                                <p>Type</p>
                                <p>Capacity</p>
                                <p>Date Contact</p>
                                <p>Statut</p>
                            </div>

                            {storeownerCollectorData.length
                                ? storeownerCollectorData.map((item, index) => {
                                      return (
                                          <Link
                                              to={'/servicesupplier/operation/collector/sheet'}
                                              state={{ id: item.id }}
                                              key={index}
                                              className="pre-filled-fields-2-several-item">
                                              <p>{item.id}</p>
                                              <p>{item.store.name}</p>
                                              <p>{_defineCollectorType(item.type)}</p>
                                              <p>{item.capacity}</p>
                                              <p>{transformDateFull(item.datep)}</p>
                                              <p
                                                  style={{
                                                      backgroundColor: _defineStatus(item.status).color,
                                                      color: Constant.$blanc,
                                                      borderTopRightRadius: '0.6rem',
                                                      borderBottomRightRadius: '0.6rem',
                                                  }}>
                                                  {_defineStatus(item.status).name}
                                              </p>
                                          </Link>
                                      );
                                  })
                                : null}
                        </div>
                    </section>
                </div>
            )}
        </main>
    );
}
