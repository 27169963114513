import { useState, useEffect } from 'react';
import { _defineStatus, transformDateFull, _defineCollectorType } from '../../../../../Utils';
import { getStoresDistributorId } from '../../../../../API/StoreApi';
import { getCollectorsByDistributor } from '../../../../../API/CollectorApi';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Pencil } from '@phosphor-icons/react';
import * as Sentry from '@sentry/react';
import * as Constant from '../../../../../Constants';

export default function DistributorSheet() {
    const [filterStoreSelected, setFilterStoreSelected] = useState('desc');
    const [filterRvmSelected, setFilterRvmSelected] = useState('desc');
    const [distributorStoresData, setDistributorStoresData] = useState([]);
    const [distributorCollectorData, setDistributorCollectorData] = useState([]);
    const location = useLocation();
    const { distributor } = location.state || {};

    useEffect(() => {
        const fetchDistributorData = async () => {
            try {
                const distributorStore = await getStoresDistributorId(distributor.id);

                setDistributorStoresData(distributorStore);

                const distributorCollector = await getCollectorsByDistributor(distributor.id);

                setDistributorCollectorData(distributorCollector);
            } catch (err) {
                Sentry.captureException(err);
                console.error('Erreur lors de la récupération des infos du distributeur', err);
            }
        };
        fetchDistributorData();
    }, [distributor.id]);

    const sortDataAsc = (data, setData, type) => {
        if (type === 'store') {
            const sortedData = [...data].sort((a, b) => a.name.localeCompare(b.name));
            setData(sortedData);
            setFilterStoreSelected('asc');
        } else if (type === 'rvm') {
            const sortedData = [...data].sort((a, b) => a.store_name.localeCompare(b.store_name));
            setData(sortedData);
            setFilterRvmSelected('asc');
        }
    };

    const sortDataDesc = (data, setData, type) => {
        if (type === 'store') {
            const sortedData = [...data].sort((a, b) => b.name.localeCompare(a.name));
            setData(sortedData);
            setFilterStoreSelected('desc');
        }
        if (type === 'rvm') {
            const sortedData = [...data].sort((a, b) => b.store_name.localeCompare(a.store_name));
            setData(sortedData);
            setFilterRvmSelected('desc');
        }
    };

    return (
        <main>
            {distributor && (
                <div className="sheet">
                    <div className="sheet-top-infos">
                        <div className="title">
                            <Link
                                className="update-indus"
                                to={'/servicesupplier/operation/distributor/update'}
                                state={{ id: distributor.id }}>
                                <div className="icon">
                                    <Pencil size={20} />
                                </div>
                            </Link>
                            <h2>{distributor.company_name}</h2>
                            <div
                                className="status"
                                style={{ backgroundColor: _defineStatus(distributor.status).color }}>
                                {_defineStatus(distributor.status).name}
                            </div>
                        </div>
                        <section className="item-info-container">
                            <div className="border">
                                <div>
                                    <p>
                                        Société : <span>{distributor.company_name}</span>
                                    </p>
                                    <p>
                                        Siret : <span>{distributor.siret}</span>
                                    </p>
                                    <p>
                                        Tva intracommunautaire : <span>{distributor.tva_intra}</span>
                                    </p>
                                    <p>
                                        Website : <span>{distributor.website}</span>
                                    </p>
                                </div>
                            </div>
                            <div className="border">
                                <div>
                                    <p>
                                        Adresse 1 : <span>{distributor.address_1}</span>
                                    </p>
                                    <p>
                                        Adresse 2 : <span>{distributor.address_2}</span>
                                    </p>
                                    <p>
                                        Ville : <span>{distributor.city}</span>
                                    </p>
                                    <p>
                                        Code postal : <span>{distributor.zipcode}</span>
                                    </p>
                                </div>
                            </div>
                            <div className="border">
                                <div>
                                    <p>
                                        Nom du contact : <span>{distributor.contact_name}</span>
                                    </p>
                                    <p>
                                        Email du contact : <span>{distributor.contact_email}</span>
                                    </p>
                                    <p>
                                        Téléphone du contact : <span>{distributor.contact_phone}</span>
                                    </p>
                                </div>
                            </div>
                        </section>
                    </div>
                    <section className="big-section">
                        <div className="top">
                            <h2 style={{ margin: '0' }}>Les magasins ({distributorStoresData.length})</h2>
                            <div className="top-button-sort">
                                <div className="filter">
                                    <p className="sort">Trier par</p>
                                    <div>
                                        <p
                                            className={filterStoreSelected === 'asc' ? 'selected' : 'classic'}
                                            onClick={() => {
                                                sortDataAsc(distributorStoresData, setDistributorStoresData, 'store');
                                            }}>
                                            A &gt; Z
                                        </p>
                                        <p
                                            className={filterStoreSelected === 'desc' ? 'selected' : 'classic'}
                                            onClick={() => {
                                                sortDataDesc(distributorStoresData, setDistributorStoresData, 'store');
                                            }}>
                                            Z &gt; A
                                        </p>
                                    </div>
                                </div>
                                <Link
                                    to={'/servicesupplier/operation/store/add'}
                                    state={{ depot_id: 0, rvmowner_id: 0, distributor_id: distributor.id }}
                                    className="button-actif">
                                    AJOUTER UN MAGASIN
                                </Link>
                            </div>
                        </div>

                        <div className="tab-item">
                            <div
                                className="pre-filled-fields-1-several-item"
                                style={{ fontWeight: '600' }}>
                                <p>Nom</p>
                                <p>Adresse 1</p>
                                <p>Adresse 2</p>
                                <p>Ville</p>
                                <p>Code postal</p>
                                <p>Nb RVM</p>
                                <p>Statut</p>
                            </div>

                            {distributorStoresData.length
                                ? distributorStoresData.map((item, index) => {
                                      return (
                                          <Link
                                              to={'/servicesupplier/operation/store/sheet'}
                                              state={{ id: item.id }}
                                              key={index}
                                              className="pre-filled-fields-2-several-item">
                                              <p>{item.name}</p>
                                              <p>{item.address_1}</p>
                                              <p>{item.address_2}</p>
                                              <p>{item.city}</p>
                                              <p>{item.zipcode}</p>
                                              <p>{item.collectors.length}</p>
                                              <p
                                                  style={{
                                                      backgroundColor: _defineStatus(item.status).color,
                                                      color: Constant.$blanc,
                                                      borderTopRightRadius: '0.6rem',
                                                      borderBottomRightRadius: '0.6rem',
                                                  }}>
                                                  {_defineStatus(item.status).name}
                                              </p>
                                          </Link>
                                      );
                                  })
                                : null}
                        </div>
                    </section>

                    <section className="big-section">
                        <div className="top">
                            <h2 style={{ margin: '0' }}>Les RVM ({distributorCollectorData.length})</h2>
                            <div className="top-button-sort">
                                <div className="filter">
                                    <p className="sort">Trier par</p>
                                    <div>
                                        <p
                                            className={filterRvmSelected === 'asc' ? 'selected' : 'classic'}
                                            onClick={() => {
                                                sortDataAsc(distributorCollectorData, setDistributorCollectorData, 'rvm');
                                            }}>
                                            A &gt; Z
                                        </p>
                                        <p
                                            className={filterRvmSelected === 'desc' ? 'selected' : 'classic'}
                                            onClick={() => {
                                                sortDataDesc(distributorCollectorData, setDistributorCollectorData, 'rvm');
                                            }}>
                                            Z &gt; A
                                        </p>
                                    </div>
                                </div>
                                <Link
                                    to={'/servicesupplier/operation/collector/add'}
                                    state={{ depot_id: 0, distributorId: distributor.id, store: null }}
                                    className="button-actif">
                                    AJOUTER UNE RVM
                                </Link>{' '}
                            </div>
                        </div>

                        <div className="tab-item">
                            <div
                                className="pre-filled-fields-1-several-item"
                                style={{ fontWeight: '600' }}>
                                <p>Id</p>
                                <p>Magasin</p>
                                <p>Type</p>
                                <p>Capacity</p>
                                <p>Date Contact</p>
                                <p>Statut</p>
                            </div>

                            {distributorCollectorData.length
                                ? distributorCollectorData.map((item, index) => {
                                      return (
                                          <Link
                                              to={'/servicesupplier/operation/collector/sheet'}
                                              state={{ id: item.id }}
                                              key={index}
                                              className="pre-filled-fields-2-several-item">
                                              <p>{item.id}</p>
                                              <p>{item.store.name}</p>
                                              <p>{_defineCollectorType(item.type)}</p>
                                              <p>{item.capacity}</p>
                                              <p>{transformDateFull(item.datep)}</p>
                                              <p
                                                  style={{
                                                      backgroundColor: _defineStatus(item.status).color,
                                                      color: Constant.$blanc,
                                                      borderTopRightRadius: '0.6rem',
                                                      borderBottomRightRadius: '0.6rem',
                                                  }}>
                                                  {_defineStatus(item.status).name}
                                              </p>
                                          </Link>
                                      );
                                  })
                                : null}
                        </div>
                    </section>
                </div>
            )}
        </main>
    );
}
