import { useEffect, useState } from 'react';
import { getAllReturnByStoreAndDates } from '../../../API/CollectorReturnApi';
import { Jwt } from '../../../Jwt';
import { endOfDay } from 'date-fns';
import { _defineCollectorType, _defineRemboursementType } from '../../../Utils';
import { format } from 'date-fns';
import { CSVLink } from 'react-csv';
import * as Constants from '../../../Constants';
import DateFilter from '../../../Components/DateFilter';
export default function StoreOwnerTransactionCollector() {
    const userContext = new Jwt();
    // FILTRES
    const now = new Date();

    const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), 0, 1));
    const [endDate, setEndDate] = useState(now);
    const [allReturns, setAllReturns] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const response = await getAllReturnByStoreAndDates(startDate.toISOString(), endOfDay(endDate.toISOString()), userContext.entityId);

            const groupByCollectorId = response.reduce((acc, curr) => {
                if (!acc[curr.collector_id]) {
                    acc[curr.collector_id] = [];
                }
                acc[curr.collector_id].push(curr);
                return acc;
            }, {});

            const formattedData = Object.keys(groupByCollectorId).map((key) => ({
                name: _defineCollectorType(groupByCollectorId[key][0].collector.type),
                items: groupByCollectorId[key].sort((a, b) => new Date(b.returnedAt) - new Date(a.returnedAt)),
            }));

            const groupItemsByType = (items) => {
                return items.reduce((acc, curr) => {
                    if (!acc[curr.type]) {
                        acc[curr.type] = [];
                    }
                    acc[curr.type].push(curr);
                    return acc;
                }, {});
            };

            for (let i = 0; i < formattedData.length; i++) {
                const groupByType = groupItemsByType(formattedData[i].items);
                formattedData[i].items = Object.keys(groupByType).map((key) => ({
                    type: key,
                    items: groupByType[key],
                }));
            }

            setAllReturns(formattedData);
        };
        fetchData();
    }, [userContext.entityId, startDate, endDate]);

    const formatted = (item) => {
        let mergedItems = [];

        item.items.forEach((obj) => {
            mergedItems = mergedItems.concat(obj.items);
        });

        const formattedData = mergedItems.map((item) => {
            return {
                date: format(new Date(item.returnedAt), 'dd/MM/yyyy HH:mm'),
                collectorId: item.collector.id,
                collectorType: _defineCollectorType(item.collector.type),
                remboursementType: _defineRemboursementType(item.type),
                price: item.entityIndustrialProduct ? (item.entityIndustrialProduct.container.price / 100).toFixed(2) : 'nd',
            };
        });

        return formattedData;
    };

    const customHeader = [
        { label: 'Date', key: 'date' },
        { label: 'ID Collecteur', key: 'collectorId' },
        { label: 'Type Collecteur', key: 'collectorType' },
        { label: 'Type Remboursement', key: 'remboursementType' },
        { label: 'Prix (€)', key: 'price' },
    ];

    return (
        <main>
            <div className="data-container">
                <div className="header-title">
                    <h1>TRANSACTIONS COLLECTEURS</h1>
                    <div className="date-container">
                        <DateFilter
                            startDate={startDate}
                            endDate={endDate}
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                        />
                    </div>
                </div>
                {allReturns.length ? (
                    allReturns.map((item, index) => (
                        <section
                            key={index}
                            className="big-section"
                            style={{ marginLeft: 0, width: '100%' }}>
                            <div
                                key={index}
                                className="tab-item">
                                <div
                                    className="pre-filled-fields-1-item"
                                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <h3 style={{ fontWeight: 'bolder', fontSize: 20 }}>{item.name}</h3>
                                    <div className="extract-content">
                                        <div
                                            className="extract"
                                            style={{
                                                backgroundColor: Constants.$vertBocoloco,
                                                padding: '0.5rem',
                                                fontSize: '0.8rem',
                                                borderRadius: '0.40rem',
                                            }}>
                                            <CSVLink
                                                style={{ textDecoration: 'none', color: Constants.$blanc }}
                                                className="extract-button"
                                                headers={customHeader}
                                                data={formatted(item)}
                                                filename={`collector_data.csv`}
                                                target="_blank"
                                                separator={';'}>
                                                Télécharger details
                                            </CSVLink>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div
                                        className="pre-filled-fields-1-several-item"
                                        style={{ fontWeight: '600', width: '100%' }}>
                                        <p>Type de remboursement</p>
                                        <p>Nombre de transactions</p>
                                        <p>Montant total (€)</p>
                                    </div>

                                    {item.items.map((subItem, subIndex) => (
                                        <div
                                            key={subIndex}
                                            style={{ display: 'flex', flexWrap: 'wrap', width: '100%', justifyContent: 'space-between', marginTop: '0.3rem' }}
                                            className="pre-filled-fields-2-several-item">
                                            <p>{_defineRemboursementType(subItem.type)}</p>
                                            <p>{subItem.items.length}</p>
                                            <p>
                                                {(
                                                    subItem.items.reduce((acc, curr) => {
                                                        return (
                                                            acc +
                                                            (curr.entityIndustrialProduct && curr.entityIndustrialProduct.container
                                                                ? curr.entityIndustrialProduct.container.price
                                                                : 0)
                                                        );
                                                    }, 0) / 100
                                                ).toFixed(2)}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </section>
                    ))
                ) : (
                    <div className="no-data-graph ">Aucune donnée.</div>
                )}
            </div>
        </main>
    );
}
