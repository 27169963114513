import { Routes, Route, Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import * as Constants from '../../Constants';

import SorterDeclaration from '../../containers/operation/sorter_interface/SorterDeclaration';
import SorterDeclarationHistory from '../../containers/operation/sorter_interface/SorterDeclarationHistory';
import SorterReception from '../../containers/operation/sorter_interface/SorterReception';
import Documentation from '../../containers/Documentation';
import Support from '../../containers/account/Support';
import UpdatePassword from '../../containers/account/UpdatePassword';
import PreHome from '../../containers/PreHome';
import ErrorPage from '../../Components/ErrorPage';
import Unknown from '../../containers/account/Unknown';

// Profil
import ProfilPersonalInfo from '../../containers/account/ProfilPersonalInfo';
import ProfilCompanyInfo from '../../containers/account/ProfilCompanyInfo';
import ProfilAccess from '../../containers/account/ProfilAccess';
import ProfilRoleManagement from '../../containers/account/ProfilRoleManagement';

export default function SorterInterface() {
    const role = Cookies.get('role');
    const isAdmin = role === Constants.ENTITY_ROLE_ADMIN;
    return (
        <Routes>
            <Route
                path="/error"
                element={<ErrorPage />}
            />
            <Route
                path="/prehome"
                element={<PreHome />}
            />
            <Route
                path="/support"
                element={<Support />}
            />
            <Route
                path="/documentation"
                element={<Documentation />}
            />
            <Route
                path="/updatepassword"
                element={<UpdatePassword />}
            />
            <Route
                path="/sorter/operation/reception"
                element={<SorterReception />}
            />
            <Route
                path="/sorter/operation/declaration"
                element={<SorterDeclaration />}
            />
            <Route
                path="/sorter/operation/history"
                element={<SorterDeclarationHistory />}
            />
            <Route
                path="/sorter/myprofil/personal_info"
                element={<ProfilPersonalInfo />}
            />
            <Route
                path="/sorter/myprofil/company_info"
                element={<ProfilCompanyInfo />}
            />
            <Route
                path="/sorter/myprofil/access"
                element={<ProfilAccess />}
            />
            {isAdmin ? (
                <Route
                    path="/sorter/myprofil/rolemanagement"
                    element={<ProfilRoleManagement />}
                />
            ) : null}
            <Route
                path="/sorter/operation/unknown"
                element={<Unknown />}
            />
            <Route
                path="*"
                element={
                    <Navigate
                        replace
                        to="/sorter/operation/unknown"
                    />
                }
            />
        </Routes>
    );
}
