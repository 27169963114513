import { useEffect, useState } from 'react';
import { getCollectorsByDates } from '../../../../API/CollectorApi';
import { getCollectorsByDatesCheck } from '../../../../API/CheckApi/CheckCollectorApi';

import PieChart from '../../../../Components/PieChart';
import CircleChart from '../../../../Components/CircleChart';
import { _defineCollectorType, _defineStoreOwner, _defineStoreType, getRegionsName } from '../../../../Utils';
import * as Constant from '../../../../Constants';
import { CSVLink } from 'react-csv';
import { format, endOfDay } from 'date-fns';
import DateFilter from '../../../../Components/DateFilter';
import { countOccurenceByKey } from '../../../../UtilsData';
import * as Sentry from '@sentry/react';
import { useNavigate } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
export default function IndusDataReuseCollecteurs() {
    const navigate = useNavigate();
    const now = new Date();
    const [collectorActive, setCollectorActive] = useState(0);
    const [collectorByRegion, setCollectorByRegion] = useState([]);
    const [collectorByType, setCollectorByType] = useState([]);
    const [rvmByRegion, setRvmByRegion] = useState([]);
    const [tceByRegion, setTceByRegion] = useState([]);
    const [collectorByStoreType, setCollectorByStoreType] = useState([]);
    const [collectorByOwnerStore, setCollectorByOwnerStore] = useState([]);
    const [formattedData, setFormattedData] = useState([]);

    // FILTRES
    const [startDate, setStartDate] = useState(new Date(now.getFullYear(), 0, 1));
    const [endDate, setEndDate] = useState(now);

    useEffect(() => {
        const fetchData = async () => {
            setCollectorActive(0);
            setCollectorByRegion([]);
            setCollectorByType([]);
            setRvmByRegion([]);
            setTceByRegion([]);
            setCollectorByStoreType([]);
            setCollectorByOwnerStore([]);
            setFormattedData([]);

            try {
                const collectorsRaw = await getCollectorsByDates(startDate.toISOString(), endOfDay(endDate.toISOString()));
                const collectorRawCheck = getCollectorsByDatesCheck(collectorsRaw);
                if (collectorsRaw.length && collectorRawCheck !== true) {
                    console.error(collectorRawCheck);
                    Sentry.captureException(collectorRawCheck);
                    navigate('/error');
                    return;
                }
                const collectorActiveRaw = collectorsRaw.filter((collector) => collector.status === 0);

                const countedRegions = countOccurenceByKey(collectorActiveRaw, 'region');
                const regionsArray = Object.keys(countedRegions).map((region) => ({
                    region: getRegionsName(region),
                    qty: ((countedRegions[region] / collectorActiveRaw.length) * 100).toFixed(1),
                }));

                const countedType = countOccurenceByKey(collectorActiveRaw, 'type');
                const collectorByTypeRaw = Object.keys(countedType).map((type) => ({
                    type: _defineCollectorType(type),
                    qty: ((countedType[type] / collectorActiveRaw.length) * 100).toFixed(1),
                }));

                const collectorRvmRaw = collectorActiveRaw.filter((collector) => collector.type === parseInt(Constant.COLLECTOR_TYPE_RVM));
                const countedRvm = countOccurenceByKey(collectorRvmRaw, 'region');
                const collectorRvmCounted = Object.keys(countedRvm).map((region) => ({
                    region: getRegionsName(region),
                    qty: ((countedRvm[region] / collectorRvmRaw.length) * 100).toFixed(1),
                }));

                const collectorTceRaw = collectorActiveRaw.filter((collector) => collector.type === parseInt(Constant.COLLECTOR_TYPE_TCE));
                const countedTce = countOccurenceByKey(collectorTceRaw, 'region');
                const collectorTceCounted = Object.keys(countedTce).map((region) => ({
                    region: getRegionsName(region),
                    qty: ((countedTce[region] / collectorTceRaw.length) * 100).toFixed(1),
                }));

                const collectorByOwnerStoreRaw = countOccurenceByKey(collectorActiveRaw, 'store_owner');
                const collectorByOwnerStoreCounted = Object.keys(collectorByOwnerStoreRaw).map((store_owner) => ({
                    store_owner: _defineStoreOwner(store_owner),
                    qty: ((collectorByOwnerStoreRaw[store_owner] / collectorActiveRaw.length) * 100).toFixed(1),
                }));

                setCollectorByOwnerStore(collectorByOwnerStoreCounted);
                const collectorByStoreTypeRaw = countOccurenceByKey(collectorActiveRaw, 'store_type');
                const collectorByStoreCounted = Object.keys(collectorByStoreTypeRaw).map((store_type) => ({
                    store_type: _defineStoreType(store_type),
                    qty: ((collectorByStoreTypeRaw[store_type] / collectorActiveRaw.length) * 100).toFixed(1),
                }));

                const formattedDataRaw = collectorActiveRaw.map((item) => {
                    const collector = {
                        nom: item.store_name,
                        region: getRegionsName(item.region),
                        store_type: _defineStoreType(item.store_type),
                        collector_type: _defineCollectorType(item.type),
                        datei: format(item.createdAt, 'yyyy-MM-dd'),
                    };
                    return collector;
                });

                setCollectorByStoreType(collectorByStoreCounted);
                setRvmByRegion(collectorRvmCounted);
                setTceByRegion(collectorTceCounted);
                setCollectorByType(collectorByTypeRaw);
                setCollectorByRegion(regionsArray);
                setCollectorActive(collectorActiveRaw.length);
                setFormattedData(formattedDataRaw);
            } catch (error) {
                console.debug(error);
            }
        };
        if (startDate & endDate) fetchData();
    }, [startDate, endDate, navigate]);

    const collectorByRegionQuantities = collectorByRegion.map((item) => item.qty);
    const collectorByRegionLabels = collectorByRegion.map((item) => item.region);

    const collectorByTypeQuantities = collectorByType.map((item) => item.qty);
    const collectorByTypeLabels = collectorByType.map((item) => item.type);

    const rvmByRegionQuantities = rvmByRegion.map((item) => item.qty);
    const rvmByRegionLabels = rvmByRegion.map((item) => item.region);

    const tceByRegionQuantities = tceByRegion.map((item) => item.qty);
    const tceByRegionLabels = tceByRegion.map((item) => item.region);

    const collectorByStoreTypeQuantities = collectorByStoreType.map((item) => item.qty);
    const collectorByStoreTypeLabels = collectorByStoreType.map((item) => item.store_type);

    const collectorByOwnerStoreQuantities = collectorByOwnerStore.map((item) => item.qty);
    const collectorByOwnerStoreLabels = collectorByOwnerStore.map((item) => item.store_owner);

    const customHeader = [
        {
            label: 'Nom du magasin',
            key: 'nom',
        },
        {
            label: 'Région',
            key: 'region',
        },
        {
            label: 'Type de magasin',
            key: 'store_type',
        },
        {
            label: 'Type de collecteur',
            key: 'collector_type',
        },
        {
            label: "Date d'implentation",
            key: 'datei',
        },
    ];

    return (
        <main>
            <div className="data-container">
                <div className="header-container">
                    <div className="header-title">
                        <h1>COLLECTEURS</h1>
                        <div className="date-container">
                            <DateFilter
                                startDate={startDate}
                                endDate={endDate}
                                setStartDate={setStartDate}
                                setEndDate={setEndDate}
                            />
                            <div className="extract-content">
                                <div className="extract">
                                    <CSVLink
                                        style={{ textDecoration: 'none', color: Constant.$blanc }}
                                        className="extract-button"
                                        headers={customHeader}
                                        data={formattedData ? formattedData : []}
                                        filename={`collecteurs_data.csv`}
                                        target="_blank"
                                        separator={';'}>
                                        Extraire la donnée
                                    </CSVLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{ display: 'flex', gap: '1%' }}>
                    <section
                        className="section-number"
                        style={{ width: '33%' }}>
                        <div className="title">Nombre de collecteurs actifs</div>
                        <div className="content">
                            <div className="number">{collectorActive}</div>
                            <div className="text"></div>
                        </div>
                    </section>
                    <section style={{ width: '50%' }}></section>
                </div>
                <div style={{ display: 'flex', gap: '1%' }}>
                    <section
                        className="section-graph"
                        style={{ width: '33%' }}>
                        <div className="title">Répartition des collecteurs par région (%) :</div>
                        <div style={{ height: '13rem' }}>
                            {collectorByRegion.length > 0 ? (
                                <PieChart
                                    title={collectorByRegionLabels}
                                    quantities={collectorByRegionQuantities}
                                    position={'right'}
                                />
                            ) : (
                                <div className="no-data-graph ">Aucune donnée.</div>
                            )}
                        </div>
                    </section>
                    <section
                        className="section-graph"
                        style={{ width: '33%' }}>
                        <div className="title">Répartition des collecteurs par type (%) :</div>
                        <div style={{ height: '13rem' }}>
                            {collectorByType.length > 0 ? (
                                <CircleChart
                                    title={collectorByTypeLabels}
                                    quantities={collectorByTypeQuantities}
                                />
                            ) : (
                                <div className="no-data-graph ">Aucune donnée.</div>
                            )}
                        </div>
                    </section>
                    <section
                        className="section-graph"
                        style={{ width: '33%' }}>
                        <div className="title">Répartition des RVM par région (%) :</div>
                        <div style={{ height: '13rem' }}>
                            {rvmByRegion.length > 0 ? (
                                <PieChart
                                    title={rvmByRegionLabels}
                                    quantities={rvmByRegionQuantities}
                                    position={'right'}
                                />
                            ) : (
                                <div className="no-data-graph ">Aucune donnée.</div>
                            )}
                        </div>
                    </section>
                </div>
                <div style={{ display: 'flex', gap: '1%' }}>
                    <section
                        className="section-graph"
                        style={{ width: '33%' }}>
                        <div className="title">Répartition des TCE par région (%) :</div>
                        <div style={{ height: '13rem' }}>
                            {tceByRegion.length > 0 ? (
                                <PieChart
                                    title={tceByRegionLabels}
                                    quantities={tceByRegionQuantities}
                                    position={'right'}
                                />
                            ) : (
                                <div className="no-data-graph ">Aucune donnée.</div>
                            )}
                        </div>
                    </section>
                    <section
                        className="section-graph"
                        style={{ width: '33%' }}>
                        <div className="title">Répartition des collecteurs par propriétaire (%) :</div>
                        <div style={{ height: '13rem' }}>
                            {collectorByOwnerStore.length > 0 ? (
                                <CircleChart
                                    title={collectorByOwnerStoreLabels}
                                    quantities={collectorByOwnerStoreQuantities}
                                />
                            ) : (
                                <div className="no-data-graph ">Aucune donnée.</div>
                            )}
                        </div>
                    </section>
                    <section
                        className="section-graph"
                        style={{ width: '33%' }}>
                        <div className="title">
                            Répartition des collecteurs par type <br />
                            de magasin (%) :
                        </div>
                        <div style={{ height: '13rem' }}>
                            {collectorByStoreType.length > 0 ? (
                                <PieChart
                                    title={collectorByStoreTypeLabels}
                                    quantities={collectorByStoreTypeQuantities}
                                    position={'right'}
                                />
                            ) : (
                                <div className="no-data-graph ">Aucune donnée.</div>
                            )}
                        </div>
                    </section>
                </div>
            </div>
        </main>
    );
}
