import { useState, useEffect } from 'react';
import { getProductById, updateProduct } from '../../../../../../API/ProductApi';
import { getProductByIdCheck } from '../../../../../../API/CheckApi/CheckProductApi';
import { _defineStatus } from '../../../../../../Utils';
import { getContainers } from '../../../../../../API/ContainerApi';
import { useLocation, useNavigate } from 'react-router-dom';
import { checkSupplierProductFormErrors, checkIsInputEmpty } from '../../../../../../Utils';
import * as Constant from '../../../../../../Constants';
import * as Sentry from '@sentry/react';
export default function Updateproduct() {
    const [errorMessage, setErrorMessage] = useState([]);
    const [errorEmpty, setErrorEmpty] = useState('');
    const [containerList, setContainerList] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();
    const { id } = location.state || {};
    const [productData, setProductData] = useState([]);

    useEffect(() => {
        const fetchProductData = async () => {
            try {
                const productData = await getProductById(id);
                const productDataCheck = getProductByIdCheck(productData);
                if (productData.length && productDataCheck !== true) {
                    console.error(productDataCheck);
                    Sentry.captureException(productDataCheck);
                    navigate('/error');
                    return;
                }
                setProductData(productData[0]);

                const containerData = await getContainers();
                setContainerList(containerData);
            } catch (error) {
                console.error('Erreur lors de la récupération des infos du verrier', error);
            }
        };
        fetchProductData();
    }, [id, navigate]);
    const [formErrors, setFormErrors] = useState({
        supplier_id: '',
        name: '',
        ref: '',
        sku: '',
    });

    const requiredFields = [
        'name',
        'ref',
        'sku',
    ];
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProductData({
            ...productData,
            [name]: value,
        });
    };

    const handleSaveChanges = async (e) => {
        e.preventDefault();
        setErrorMessage([]);
        setErrorEmpty('');

        const inputEmpty = checkIsInputEmpty(requiredFields, productData, setFormErrors, setErrorEmpty);
        if (!inputEmpty) {
            const checkFormat = checkSupplierProductFormErrors(productData, formErrors, setFormErrors, setErrorMessage);
            if (checkFormat) {
                return;
            } else {
                const updateProductData = await updateProduct(id, productData);
                if (updateProductData) {
                    alert('Modifications enregistrées avec succès');
                    navigate(-1);
                } else {
                    alert("Erreur lors de l'enregistrement des modifications");
                }
            }
        } else {
            console.log('Il reste des champs obligatoires vides');
        }
    };
    return (
        <main>
            <section className="small-section">
                <h2>Modifier un contenant</h2>
                {productData.id ? (
                    <div className="form-container">
                        <section style={{ width: '100%' }}>
                            <div>
                                <label>Nom du verrier:</label>
                                <select
                                    disabled={true}
                                    className={formErrors.supplier_id ? 'error' : ''}
                                    name="supplier_id"
                                    onChange={handleInputChange}>
                                    <option value={productData.supplier_id}>{productData.supplier_name}</option>
                                </select>
                            </div>
                            <div>
                                <label>Nom du contenant:</label>
                                <input
                                    className={formErrors.name ? 'error' : ''}
                                    type="text"
                                    name="name"
                                    value={productData.name || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <label>Référence interne:</label>
                                <input
                                    className={formErrors.ref ? 'error' : ''}
                                    type="text"
                                    name="ref"
                                    value={productData.ref || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <label>SKU</label>
                                <select
                                    value={productData.sku}
                                    name="sku"
                                    className={formErrors.sku ? 'error' : ''}
                                    onChange={handleInputChange}>
                                    {containerList.map((option, index) => (
                                        <option
                                            key={index}
                                            value={option.sku}>
                                            {option.sku}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label>Statut:</label>
                                <select
                                    style={{ backgroundColor: _defineStatus(productData.status).color, color: Constant.$blanc }}
                                    name="status"
                                    value={productData.status}
                                    onChange={handleInputChange}>
                                    <option value={0}>Actif</option>
                                    <option value={100}>Inactif</option>
                                </select>
                            </div>
                        </section>
                    </div>
                ) : null}
                <p className="error-form">{errorEmpty && errorEmpty}</p>
                {errorMessage.length
                    ? errorMessage.map((item, index) => {
                          return (
                              <div
                                  className="error-form"
                                  key={index}>
                                  {item}
                              </div>
                          );
                      })
                    : null}
                <div className="button-confirm">
                    <button
                        className="button-actif"
                        onClick={handleSaveChanges}>
                        Enregistrer les modifications
                    </button>
                </div>
            </section>
        </main>
    );
}
