export const AccessRvmowner = {
    rvmowner: {
        operation: {
            rvm: {
                dashboard: 0,
                liste_magasin: 0,
                liste_rvm: 0,
                ajouter_une_rvm: 0,
            },
        },
        data: {},
        financial: {},
    },
};
