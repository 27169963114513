import { useEffect, useState } from 'react';
import { addDepot } from '../../../../../API/DepotApi';
import { useNavigate } from 'react-router-dom';
import { getSorters } from '../../../../../API/SorterApi';
import { checkDepotFormErrors, checkIsInputEmpty } from '../../../../../Utils';
import AddressSearch from '../../../../../Components/AddressSearch';
export default function AddDepot() {
    const [errorMessage, setErrorMessage] = useState([]);
    const [errorEmpty, setErrorEmpty] = useState('');
    const [sorterList, setSorterList] = useState([]);
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        company_name: '',
        name: '',
        address_1: '',
        address_2: '',
        city: '',
        zipcode: '',
        contact_name: '',
        contact_phone: '',
        contact_email: '',
        entity_sorter_id: '',
        lat: '',
        long: '',
    });

    const [formErrors, setFormErrors] = useState({
        company_name: false,
        name: false,
        address_1: false,
        address_2: false,
        city: false,
        zipcode: false,
        contact_name: false,
        contact_phone: false,
        contact_email: false,
        entity_sorter_id: false,
    });

    const requiredFields = [
        'company_name',
        'name',
        'address_1',
        'city',
        'zipcode',
        'contact_name',
        'contact_phone',
        'contact_email',
        'entity_sorter_id',
    ];

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        const fetchSorters = async () => {
            const result = await getSorters();
            setSorterList(result);
        };
        fetchSorters();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage([]);
        setErrorEmpty('');
        const inputEmpty = checkIsInputEmpty(requiredFields, formData, setFormErrors, setErrorEmpty);
        if (!inputEmpty) {
            const checkFormat = checkDepotFormErrors(formData, formErrors, setFormErrors, setErrorMessage);
            if (checkFormat) {
                return;
            } else {
                const addDepotData = await addDepot(formData);
                if (addDepotData) {
                    alert('Le centre de collecte a été ajouté avec succès');
                    navigate(-1);
                } else {
                    alert("une erreur est survenue lors de l'ajout du centre de collecte");
                }
            }
        } else {
            console.log('Il reste des champs obligatoires vides');
        }
    };
    return (
        <main>
            <section className="big-section">
                <h2>Ajouter un centre de collecte</h2>
                <div className="form-container">
                    <section>
                        <div>
                            <label>Société Mère:</label>
                            <input
                                className={formErrors.company_name ? 'error' : ''}
                                type="text"
                                name="company_name"
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <label>Nom du centre de collecte:</label>
                            <input
                                className={formErrors.name ? 'error' : ''}
                                type="text"
                                name="name"
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <label>Centre de tri:</label>
                            {sorterList.length ? (
                                <select
                                    className={formErrors.entity_sorter_id ? 'error' : ''}
                                    name="entity_sorter_id"
                                    onChange={handleChange}>
                                    <option>Choisissez une option</option>
                                    {sorterList.map((option, index) => (
                                        <option
                                            key={index}
                                            value={option.id}>
                                            {option.company_name}
                                        </option>
                                    ))}
                                </select>
                            ) : (
                                <input
                                    type="text"
                                    disabled
                                    value={'Aucun centre de tri référencé'}
                                />
                            )}
                        </div>
                    </section>
                    <section>
                        <AddressSearch
                            formData={formData}
                            setFormData={setFormData}
                            formErrors={formErrors}
                            setFormErrors={setFormErrors}
                            handleChange={handleChange}
                        />
                    </section>
                    <section>
                        <div>
                            <label>Nom du contact:</label>
                            <input
                                className={formErrors.contact_name ? 'error' : ''}
                                type="text"
                                name="contact_name"
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <label>Téléphone du contact:</label>
                            <input
                                className={formErrors.contact_phone ? 'error' : ''}
                                type="number"
                                name="contact_phone"
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <label>Email du contact:</label>
                            <input
                                className={formErrors.contact_email ? 'error' : ''}
                                type="email"
                                name="contact_email"
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </section>
                </div>
                <p className="error-form">{errorEmpty && errorEmpty}</p>
                {errorMessage.length
                    ? errorMessage.map((item, index) => {
                          return (
                              <div
                                  className="error-form"
                                  key={index}>
                                  {item}
                              </div>
                          );
                      })
                    : null}
                <div className="button-confirm">
                    <button
                        className="button-actif"
                        onClick={handleSubmit}>
                        Ajouter le centre de collecte
                    </button>
                </div>
            </section>
        </main>
    );
}
