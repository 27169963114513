import { useEffect, useState } from 'react';
import { getAllSalesDetailsByDates } from '../../../API/SalesApi';
import * as Constant from '../../../Constants';
import { endOfDay } from 'date-fns';
import { getCollectorsByDates } from '../../../API/CollectorApi';
import { getReturnByDates } from '../../../API/CollectorReturnApi';
import { getContainersByDates } from '../../../API/ContainerApi';
import { reduceDataByKeyAndQty, countOccurenceByKey, accumulateDataByMonth } from '../../../UtilsData';
import { _defineContainerType } from '../../../Utils';
import PieChart from '../../../Components/PieChart';
import LineChart from '../../../Components/LineChart';
import { getAllSalesDetailsByDatesCheck } from '../../../API/CheckApi/CheckSalesApi';
import { getReturnByDatesCheck } from '../../../API/CheckApi/CheckCollectorReturnApi';
import { getCollectorsByDatesCheck } from '../../../API/CheckApi/CheckCollectorApi';
import * as Sentry from '@sentry/react';
import { useNavigate } from 'react-router-dom';
export default function IndusDataHome() {
    const navigate = useNavigate();
    const [standardContainersByType, setStandardContainersByType] = useState([]);
    const [totalContainerInCirculation, setTotalContainerInCirculation] = useState(0);
    const [allContainerInCirculation, setAllContainerInCirculation] = useState([]);
    const [containersByReturn, setContainersByReturn] = useState([]);
    const [containerReturnedBySku, setContainerReturnedBySku] = useState([]);
    const [collectorActive, setCollectorActive] = useState(0);
    const [totalReturned, setTotalReturned] = useState(0);
    const [averageContainerByReturn, setAverageContainerByReturn] = useState(0);
    useEffect(() => {
        const fetchData = async () => {
            const now = new Date();
            const startDate = new Date(now.getFullYear(), 0, 1);
            const endDate = now;
            try {
                const allSalesDetailsRaw = await getAllSalesDetailsByDates(startDate.toISOString(), endOfDay(endDate.toISOString()));
                const allSalesDetailsCheck = getAllSalesDetailsByDatesCheck(allSalesDetailsRaw);
                if (allSalesDetailsRaw.length && allSalesDetailsCheck !== true) {
                    console.error(allSalesDetailsCheck);
                    Sentry.captureException(allSalesDetailsCheck);
                    navigate('/error');
                    return;
                }
                const standardCirculationContainer = allSalesDetailsRaw.filter(
                    (item) => item.entityIndustrialProduct.container.owner === Constant.CONTAINER_OWNER_STATUS_STANDARD,
                );

                // TOTAL UNITES EN CIRCULATION
                const totalSales = standardCirculationContainer.reduce((acc, item) => acc + item.qty, 0);

                // UNITES EN CIRCULATION PAR MOIS
                const countedSalesByMonth = accumulateDataByMonth(standardCirculationContainer, startDate, endDate, 'createdAt', 'qty');
                const salesByMonthRaw = Object.keys(countedSalesByMonth).map((month) => ({
                    month,
                    qty: countedSalesByMonth[month],
                }));

                setAllContainerInCirculation(salesByMonthRaw);

                setTotalContainerInCirculation(totalSales);
                // COLLECTEURS
                const collectorsRaw = await getCollectorsByDates(startDate.toISOString(), endOfDay(endDate.toISOString()));
                const collectorRawCheck = getCollectorsByDatesCheck(collectorsRaw);
                if (collectorsRaw.length && collectorRawCheck !== true) {
                    console.error(collectorRawCheck);
                    Sentry.captureException(collectorRawCheck);
                    navigate('/error');
                    return;
                }
                const collectorActiveRaw = collectorsRaw.filter((collector) => collector.status === 0);
                setCollectorActive(collectorActiveRaw.length);

                // UNITES COLLECTEES
                const allCollectorReturnRaw = await getReturnByDates(startDate.toISOString(), endOfDay(endDate.toISOString()));
                const allCollectorReturnCheck = getReturnByDatesCheck(allCollectorReturnRaw);
                if (allCollectorReturnRaw.length && allCollectorReturnCheck !== true) {
                    console.error(allCollectorReturnCheck);
                    Sentry.captureException(allCollectorReturnCheck);
                    navigate('/error');
                    return;
                }
                const totalQty = allCollectorReturnRaw.reduce((acc, item) => acc + item.qty, 0);
                setTotalReturned(totalQty);

                const standardSkuReturned = allCollectorReturnRaw.filter(
                    (item) => item.entityIndustrialProduct && item.entityIndustrialProduct.container.owner === Constant.CONTAINER_OWNER_STATUS_STANDARD,
                );
                const countedBySku = reduceDataByKeyAndQty(standardSkuReturned, 'entityIndustrialProduct.container.sku');
                const containerReturnBySkuRaw = Object.keys(countedBySku).map((sku) => ({
                    sku,
                    qty: ((countedBySku[sku] / standardSkuReturned.length) * 100).toFixed(1),
                }));
                setContainerReturnedBySku(containerReturnBySkuRaw);

                // NB MOYEN DE CONTENANTS PAR RETOUR
                const countedAverageContainerByReturn = reduceDataByKeyAndQty(allCollectorReturnRaw, 'returnedAt');
                const averageContainerByReturnRaw = Object.keys(countedAverageContainerByReturn).map((item) => ({
                    date: item,
                    qty: countedAverageContainerByReturn[item],
                }));
                const averageContainerByReturnFinal = allCollectorReturnRaw.length / averageContainerByReturnRaw.length;
                setAverageContainerByReturn(averageContainerByReturnFinal);

                const countedContainerByReturnRaw = averageContainerByReturnRaw.reduce((acc, obj) => {
                    const qty = obj.qty;
                    const count = (acc[qty] || 0) + 1;

                    return {
                        ...acc,
                        [qty]: count,
                    };
                }, {});
                const containerByReturnRaw = Object.keys(countedContainerByReturnRaw).map((item) => ({
                    nbByReturn: item + ' contenant(s)',
                    qty: ((countedContainerByReturnRaw[item] / averageContainerByReturnRaw.length) * 100).toFixed(1),
                }));
                setContainersByReturn(containerByReturnRaw);

                // CONTENANTS STANDARD PAR TYPE
                const containerRaw = await getContainersByDates(startDate.toISOString(), endOfDay(endDate.toISOString()));
                const containerStandard = containerRaw.filter((container) => container.owner === Constant.CONTAINER_OWNER_STATUS_STANDARD);
                const countedByType = countOccurenceByKey(containerStandard, 'type');
                const containerByTypeRaw = Object.keys(countedByType).map((type) => ({
                    type: _defineContainerType(type),
                    qty: ((countedByType[type] / containerStandard.length) * 100).toFixed(1),
                }));
                setStandardContainersByType(containerByTypeRaw);
            } catch (error) {
                console.debug(error);
            }
        };
        fetchData();
    }, [navigate]);

    const standardContainersByTypeLabels = standardContainersByType.map((item) => item.type);
    const standardContainersByTypeQuantities = standardContainersByType.map((item) => item.qty);

    const orderByMonthsLabels = allContainerInCirculation.map((item) => item.month);
    const orderByMonthQuantities = allContainerInCirculation.map((item) => item.qty);

    const containersReturnedBySkuLabels = containerReturnedBySku.map((item) => item.sku);
    const containersReturnedBySkuQuantities = containerReturnedBySku.map((item) => item.qty);

    const containersByReturnQuantities = containersByReturn.map((item) => item.qty);
    const containersByReturnLabels = containersByReturn.map((item) => item.nbByReturn);

    return (
        <main>
            <div className="data-container">
                <div className="header-container">
                    <div className="header-title">
                        <h1>CHIFFRES CLÉS</h1>
                    </div>
                </div>

                <div style={{ display: 'flex', width: '100%', gap: '1%' }}>
                    <section
                        className="section-graph"
                        style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                        <div
                            className="title"
                            style={{ fontWeight: 'bold', height: '1rem' }}>
                            Données réemploi :
                        </div>
                        <div
                            className="title"
                            style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <p>Nombre totol de contenants standards en circulation : </p>
                            <p style={{ fontSize: 25 }}>{totalContainerInCirculation}</p>
                        </div>
                        <div
                            className="title"
                            style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <p>Nombre de collecteurs actifs : </p>
                            <p style={{ fontSize: 25 }}>{collectorActive}</p>
                        </div>
                        <div
                            className="title"
                            style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <p>Nombre de contenants collectés : </p>
                            <p style={{ fontSize: 25 }}>{totalReturned}</p>
                        </div>
                    </section>
                    <section
                        className="section-graph"
                        style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                        <div
                            className="title"
                            style={{ fontWeight: 'bold', height: '1rem' }}>
                            Données consommateur :
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                            <div
                                className="title"
                                style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <p>Nombre de contenants moyen par acte de retour :</p>
                                <p style={{ fontSize: 25 }}>{averageContainerByReturn.toFixed(1)}</p>
                            </div>
                        </div>
                    </section>
                </div>
                <div style={{ width: '100%', display: 'flex', gap: '1%' }}>
                    <section
                        className="section-graph"
                        style={{ height: '22rem', width: '50%' }}>
                        <div
                            className="title"
                            style={{ fontWeight: 'bold', height: '1rem' }}>
                            SKU :
                        </div>
                        <div
                            className="title"
                            style={{ height: '1rem' }}>
                            <div className="title">Répartition par typologie (%) :</div>
                        </div>
                        <div style={{ height: '80%' }}>
                            {standardContainersByType.length > 0 ? (
                                <PieChart
                                    title={standardContainersByTypeLabels}
                                    quantities={standardContainersByTypeQuantities}
                                    position={'left'}
                                />
                            ) : (
                                <div className="no-data-graph ">Aucune donnée.</div>
                            )}
                        </div>
                    </section>
                    <section
                        className="section-graph"
                        style={{ height: '22rem', width: '50%' }}>
                        <div
                            className="title"
                            style={{ fontWeight: 'bold', height: '1rem' }}>
                            Produit :{' '}
                        </div>
                        <div
                            className="title"
                            style={{ height: '1rem' }}>
                            Évolution du nombre de contenants standards mis sur le marché :
                        </div>
                        <div style={{ height: '80%' }}>
                            {allContainerInCirculation.length > 0 ? (
                                <LineChart
                                    labels={orderByMonthsLabels}
                                    quantities={orderByMonthQuantities}
                                />
                            ) : (
                                <div className="no-data-graph ">Aucune donnée.</div>
                            )}
                        </div>
                    </section>
                </div>
                <div style={{ width: '100%', display: 'flex', gap: '1%' }}>
                    <section
                        className="section-graph"
                        style={{ height: '22rem', width: '50%' }}>
                        <div
                            className="title"
                            style={{ fontWeight: 'bold', height: '1rem' }}>
                            Contenants retournés :{' '}
                        </div>
                        <div
                            className="title"
                            style={{ height: '1rem' }}>
                            <div className="title">Répartition par SKU (%) :</div>
                        </div>
                        <div style={{ height: '80%' }}>
                            {containerReturnedBySku.length > 0 ? (
                                <PieChart
                                    title={containersReturnedBySkuLabels}
                                    quantities={containersReturnedBySkuQuantities}
                                    position={'left'}
                                />
                            ) : (
                                <div className="no-data-graph ">Aucune donnée.</div>
                            )}
                        </div>
                    </section>
                    <section
                        className="section-graph"
                        style={{ height: '22rem', width: '50%' }}>
                        <div
                            className="title"
                            style={{ fontWeight: 'bold', height: '1rem' }}>
                            Nombre de contenants par acte de retour :
                        </div>
                        <div
                            className="title"
                            style={{ height: '1rem' }}>
                            Répartition (%):
                        </div>
                        <div style={{ height: '80%' }}>
                            {containersByReturn.length > 0 ? (
                                <PieChart
                                    title={containersByReturnLabels}
                                    quantities={containersByReturnQuantities}
                                    position={'left'}
                                />
                            ) : (
                                <div className="no-data-graph ">Aucune donnée.</div>
                            )}
                        </div>
                    </section>
                </div>
            </div>
        </main>
    );
}
