import { getGearAPIApiEndpoint } from './endpoints';
import * as Sentry from '@sentry/react';
import { defaultHeaders } from './headers';

const GEAR_API_BASE_URL = getGearAPIApiEndpoint();

const BOCOLOCO_GET_OPERATORS = '/operator/get';
const BOCOLOCO_GET_OPERATORBYID = '/operator/getbyid';
const BOCOLOCO_GET_OPERATORSBYNAME = '/operator/getbyname';
const BOCOLOCO_ADD_OPERATOR = '/operator/add';
const BOCOLOCO_UPDATE_OPERATOR = '/operator/update';
const BOCOLOCO_DELETE_OPERATOR = '/operator/delete';

export function getOperators() {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_OPERATORS;
    const url = encodeURI(url_base);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function addOperator(formData) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_ADD_OPERATOR;
    const url = encodeURI(url_base);
    return fetch(url, {
        method: 'PUT',
        body: JSON.stringify(formData),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            console.log('ERROR ADD OPERATOR');
            console.log(error);
            throw error;
        });
}

export function getOperatorsByName(name) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_OPERATORSBYNAME;
    var url_args = '?name=' + name;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function getOperatorById(id) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_OPERATORBYID;
    var url_args = '?id=' + id;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function updateOperator(id, updatedData) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_UPDATE_OPERATOR;
    const url_args = '?id=' + id;
    const url = encodeURI(url_base + url_args);
    return fetch(url, {
        method: 'PUT',
        body: JSON.stringify(updatedData),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            console.log('ERROR UPDATE OPERATOR');
            console.log(error);
            throw error;
        });
}

export function deleteOperator(id) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_DELETE_OPERATOR;
    var url_args = '?id=' + id;
    const url = encodeURI(url_base + url_args);

    return fetch(url, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            throw error;
        });
}
