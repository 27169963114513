export const AccessIndustrial = {
    industrial: {
        operation: {
            dashboard: {
                all: 0,
            },
            marques: {
                produits: 0,
                besoins: 0,
                mises_sur_le_marché: 0,
            },
        },
        data: {
            dashboard: {
                all: 0,
            },
            reemploi: {
                dashboard: 0,
                collecteurs: 0,
                centre_de_tri: 0,
                centre_de_lavage: 0,
                contenants: 0,
                produits: 0,
                retour: 0,
            },
            consommateur: {
                dashboard: 0,
                produits: 0,
                frequence: 0,
                region: 0,
                collecteurs: 0,
            },
            impact: {
                donnees: 0,
                indicateur: 0,
                impacts_compares: 0,
                acv: 0,
            },
        },
        financial: {
            gestion_financiere: {
                reportings: 0,
            },
        },
    },
};
