import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';

const BarChartMulti = ({ title, quantities_first, quantities_second, label_first, label_second, formattedData }) => {
    // État local pour stocker les données formatées
    const [chartData, setChartData] = useState({
        labels: title,
        datasets: [
            {
                label: label_first,
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                data: quantities_first,
            },
            {
                label: label_second,
                backgroundColor: 'rgba(54, 162, 235, 0.5)',
                data: quantities_second,
            },
        ],
    });

    // Configuration des options du graphique
    const chartOptions = {
        scales: {
            x: {
                stacked: true, // Empiler les barres sur l'axe x
            },
            y: {
                stacked: true, // Empiler les barres sur l'axe y
            },
        },
        maintainAspectRatio: false,
    };

    // Mettre à jour le composant Bar avec les données formatées une fois qu'elles sont disponibles
    useEffect(() => {
        if (formattedData) {
            setChartData(formattedData);
        }
    }, [formattedData]);

    return (
        <div style={{ width: '100%', height: '90%' }}>
            <Bar
                data={chartData}
                options={chartOptions}
            />
        </div>
    );
};

export default BarChartMulti;
