import { getGearAPIApiEndpoint } from './endpoints';
import * as Sentry from '@sentry/react';
import { defaultHeaders } from './headers';

const GEAR_API_BASE_URL = getGearAPIApiEndpoint();

const BOCOLOCO_ADD_SORTERWASHERLINK = '/sorter/washerlink/add';
const BOCOLOCO_GET_WASHERSFROMSORTERBYID = '/sorter/washerlink/getbysorterid';
const BOCOLOCO_GET_SORTERWASHERLINK = '/sorter/washerlink/get';
const BOCOLOCO_UPDATE_SORTERWASHERLINK = '/sorter/washerlink/update';
const BOCOLOCO_GET_WASHERLINK_BYSORTERIDANDSKU = '/sorter/washerlink/getbysorteridandsku';

export function addSorterWasherLink(formData) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_ADD_SORTERWASHERLINK;
    const url = encodeURI(url_base);
    return fetch(url, {
        method: 'PUT',
        body: JSON.stringify(formData),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            console.log('ERROR ADD WASHER');
            console.log(error);
            throw error;
        });
}

export function getWashersFromSorterId(id) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_WASHERSFROMSORTERBYID;
    var url_args = '?id=' + id;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function getAllSorterWasherLinks() {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_SORTERWASHERLINK;
    const url = encodeURI(url_base);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function updateSorterWasherLink(formData) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_UPDATE_SORTERWASHERLINK;
    const url = encodeURI(url_base);
    return fetch(url, {
        method: 'PUT',
        body: JSON.stringify(formData),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            console.log('ERROR UPDATE SORTER WASHER LINK');
            console.log(error);
            throw error;
        });
}

export function getWasherLinkBySorterIdAndSku(entity_sorter_id, sku) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_WASHERLINK_BYSORTERIDANDSKU;
    var url_args = '?entity_sorter_id=' + entity_sorter_id + '&sku=' + sku;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}
