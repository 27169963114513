import { useEffect, useState } from 'react';
import PieChart from '../../../../Components/PieChart.jsx';
import { CSVLink } from 'react-csv';
import { getReturnByDates } from '../../../../API/CollectorReturnApi';
import { getReturnByDatesCheck } from '../../../../API/CheckApi/CheckCollectorReturnApi';
import { endOfDay } from 'date-fns';
import { _defineContainerPrice, _defineContainerType, _defineProductCat } from '../../../../Utils';
import BarChart from '../../../../Components/BarChart.jsx';
import { Jwt } from '../../../../Jwt';
import DateFilter from '../../../../Components/DateFilter';
import * as Constant from '../../../../Constants';
import { reduceDataByKeyAndQty } from '../../../../UtilsData';
import * as Sentry from '@sentry/react';
import { useNavigate } from 'react-router-dom';
export default function IndusDataConsumerProduct() {
    const navigate = useNavigate();
    const now = new Date();
    const userContext = new Jwt();
    const [originalAllCollectorReturns, setOriginalAllCollectorReturns] = useState([]);
    const [originalMyProductCollectorReturn, setOriginalMyProductCollectorReturn] = useState([]);
    const [averageContainerByReturn, setAverageContainerByReturn] = useState(0);
    const [containersByReturn, setContainersByReturn] = useState([]);
    const [containerByPrice, setContainerByPrice] = useState([]);
    const [myContributionReturn, setMyContributionReturn] = useState(0);
    const [formattedTabGlobalData, setFormattedTabGlobalData] = useState([]);
    const [formattedTabMyRefsData, setFormattedTabMyRefsData] = useState([]);

    // FILTRES
    const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), 0, 1));
    const [endDate, setEndDate] = useState(now);
    const [skuSelected, setSkuSelected] = useState('');
    const [eanSelected, setEanSelected] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            setAverageContainerByReturn(0);
            setContainersByReturn([]);
            setContainerByPrice([]);
            setMyContributionReturn(0);
            setFormattedTabGlobalData([]);
            setFormattedTabMyRefsData([]);
            setOriginalAllCollectorReturns([]);
            setOriginalMyProductCollectorReturn([]);

            const allCollectorReturnRaw = await getReturnByDates(startDate.toISOString(), endOfDay(endDate.toISOString()));
            const allCollectorReturnCheck = getReturnByDatesCheck(allCollectorReturnRaw);
            if (allCollectorReturnRaw.length && allCollectorReturnCheck !== true) {
                console.error(allCollectorReturnCheck);
                Sentry.captureException(allCollectorReturnCheck);
                navigate('/error');
                return;
            }
            for (let i = 0; i < allCollectorReturnRaw.length; i++) {
                allCollectorReturnRaw[i].price = allCollectorReturnRaw[i].entityIndustrialProduct
                    ? _defineContainerPrice(allCollectorReturnRaw[i].entityIndustrialProduct.container.type)
                    : 'nd';
            }
            setOriginalAllCollectorReturns(allCollectorReturnRaw);

            let allCollectorReturnFiltered = [];
            if (skuSelected) {
                allCollectorReturnFiltered = allCollectorReturnRaw.filter((container) => container.sku === skuSelected);
            } else {
                allCollectorReturnFiltered = allCollectorReturnRaw;
            }

            // NB MOYEN DE CONTENANTS PAR RETOUR
            const countedAverageContainerByReturn = reduceDataByKeyAndQty(allCollectorReturnFiltered, 'returnedAt');
            const averageContainerByReturnRaw = Object.keys(countedAverageContainerByReturn).map((item) => ({
                date: item,
                qty: countedAverageContainerByReturn[item],
            }));
            const averageContainerByReturnFinal = allCollectorReturnFiltered.length / averageContainerByReturnRaw.length;
            setAverageContainerByReturn(averageContainerByReturnFinal);

            // NB DE CONTENANTS PAR RETOUR PAR
            const countedContainerByReturnRaw = averageContainerByReturnRaw.reduce((acc, obj) => {
                const qty = obj.qty;
                const count = (acc[qty] || 0) + 1;

                return {
                    ...acc,
                    [qty]: count,
                };
            }, {});
            const containerByReturnRaw = Object.keys(countedContainerByReturnRaw).map((item) => ({
                nbByReturn: item + ' contenant(s)',
                qty: ((countedContainerByReturnRaw[item] / averageContainerByReturnRaw.length) * 100).toFixed(1),
            }));
            setContainersByReturn(containerByReturnRaw);

            // NB D’UNITÉS RETOURNÉES PAR PRIX DE CONSIGNE
            const countedContainerByPrice = reduceDataByKeyAndQty(allCollectorReturnFiltered, 'price');
            const containerByPriceRaw = Object.keys(countedContainerByPrice).map((container) => ({
                price: container,
                qty: countedContainerByPrice[container],
            }));
            setContainerByPrice(containerByPriceRaw);

            // NB DE FOIS OU MA MARQUE SE TROUVE DANS UNR RETOUR
            const myCollectorReturnRaw = allCollectorReturnRaw.filter(
                (container) => container.entityIndustrialProduct && container.entityIndustrialProduct.entity_id === userContext.entityId,
            );
            setOriginalMyProductCollectorReturn(myCollectorReturnRaw);
            let myCollectorReturnFiltered = [];
            if (eanSelected) {
                myCollectorReturnFiltered = myCollectorReturnRaw.filter((container) => container.ean === eanSelected);
            } else {
                myCollectorReturnFiltered = myCollectorReturnRaw;
            }
            const myContributionReturnRaw = (myCollectorReturnFiltered.length / allCollectorReturnRaw.length) * 100;
            setMyContributionReturn(myContributionReturnRaw);

            // EXTRACT GLOBAL MARCHE
            const formattedTabGlobalDataRaw = allCollectorReturnFiltered.map((item) => {
                return {
                    year: new Date(item.returnedAt).getFullYear(),
                    month: new Date(item.returnedAt).toLocaleString('default', { month: 'long' }),
                    sku: item.sku,
                    skuType: item.entityIndustrialProduct ? _defineContainerType(item.entityIndustrialProduct.container.type) : 'nd',
                    cat: item.entityIndustrialProduct ? _defineProductCat(item.entityIndustrialProduct.cat) : 'nd',
                    price: item.price,
                };
            });
            setFormattedTabGlobalData(formattedTabGlobalDataRaw);
            // EXTRACT MES REFERENCES
            const formattedDataRaw = myCollectorReturnFiltered.map((item) => {
                return {
                    year: new Date(item.returnedAt).getFullYear(),
                    month: new Date(item.returnedAt).toLocaleString('default', { month: 'long' }),
                    ean: item.ean,
                    name: item.entityIndustrialProduct ? item.entityIndustrialProduct.name : 'nd',
                    cat: item.entityIndustrialProduct ? _defineProductCat(item.entityIndustrialProduct.cat) : 'nd',
                    sku: item.entityIndustrialProduct ? item.entityIndustrialProduct.container.sku : 'nd',
                    price: item.price,
                };
            });
            setFormattedTabMyRefsData(formattedDataRaw);
        };
        if (startDate && endDate) fetchData();
    }, [startDate, endDate, userContext.entityId, skuSelected, eanSelected, navigate]);

    const containersByReturnQuantities = containersByReturn.map((item) => item.qty);
    const containersByReturnLabels = containersByReturn.map((item) => item.nbByReturn);

    const containerByPriceQuantities = containerByPrice.map((item) => item.qty);
    const containerByPriceLabels = containerByPrice.map((item) => item.price);

    const customHeaderGlobal = [
        {
            label: 'Aannée',
            key: 'year',
        },
        {
            label: 'Mois',
            key: 'month',
        },
        {
            label: 'SKU',
            key: 'sku',
        },
        {
            label: 'Type de SKU',
            key: 'skuType',
        },
        {
            label: 'Catégorie',
            key: 'cat',
        },
        {
            label: 'Prix de consigne',
            key: 'price',
        },
    ];

    const customHeaderMyRefs = [
        {
            label: 'Année',
            key: 'year',
        },
        {
            label: 'Mois',
            key: 'month',
        },
        {
            label: 'EAN',
            key: 'ean',
        },
        {
            label: 'Nom',
            key: 'name',
        },
        {
            label: 'Catégorie',
            key: 'cat',
        },
        {
            label: 'SKU',
            key: 'sku',
        },
        {
            label: 'Prix de consigne',
            key: 'price',
        },
    ];

    const resetGlobalFilter = () => {
        setSkuSelected('');
    };

    const resetMyRefsFilter = () => {
        setEanSelected('');
    };

    const filteredsku = originalAllCollectorReturns.filter(
        (item) => item.entityIndustrialProduct && item.entityIndustrialProduct.container.owner === Constant.CONTAINER_OWNER_STATUS_STANDARD,
    );
    const mySkus = [...new Set(filteredsku.map((fd) => fd.sku))];

    const myEans = [...new Set(originalMyProductCollectorReturn.map((fd) => fd.ean))];
    return (
        <main>
            <div className="data-container">
                <div className="header-title">
                    <h1>PRODUITS</h1>
                    <div className="date-container">
                        <DateFilter
                            startDate={startDate}
                            endDate={endDate}
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                        />
                    </div>
                </div>
                <div className="header-container">
                    <div className="header-title">
                        <h1>Global marché</h1>
                    </div>
                    <div className="content-header">
                        <div className="header-container-content">
                            <select
                                onChange={(e) => setSkuSelected(e.target.value)}
                                value={skuSelected}>
                                <option value="">SKU</option>

                                {mySkus.map((option, index) => (
                                    <option
                                        key={index}
                                        value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>

                            <div
                                onClick={resetGlobalFilter}
                                className="reset">
                                <p style={{ fontWeight: 'bold' }}>X</p>
                            </div>
                        </div>
                        <div className="extract-content">
                            <div className="extract">
                                <CSVLink
                                    style={{ textDecoration: 'none', color: Constant.$blanc }}
                                    className="extract-button"
                                    headers={customHeaderGlobal}
                                    data={formattedTabGlobalData ? formattedTabGlobalData : []}
                                    filename={`returns_data.csv`}
                                    target="_blank"
                                    separator={';'}>
                                    Extraire la donnée globale
                                </CSVLink>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ marginBottom: '2rem', display: 'flex', width: '100%', gap: '1%', height: '25rem' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 2 }}>
                        <section className="section-graph">
                            <div className="title">
                                Nombre moyen de contenants par acte de retour :
                                <span style={{ fontSize: 25 }}>{averageContainerByReturn ? averageContainerByReturn.toFixed(1) : 0}</span>
                            </div>
                        </section>
                        <section
                            className="section-graph"
                            style={{ height: '100%', marginBottom: 0 }}>
                            <div
                                className="title"
                                style={{ height: '1rem' }}>
                                Répartition du nombre de contenants par acte de retour (%) :{' '}
                            </div>
                            <div style={{ height: '90%' }}>
                                {containersByReturn.length > 0 ? (
                                    <PieChart
                                        title={containersByReturnLabels}
                                        quantities={containersByReturnQuantities}
                                        position={'left'}
                                    />
                                ) : (
                                    <div className="no-data-graph ">Aucune donnée.</div>
                                )}
                            </div>
                        </section>
                    </div>
                    <div style={{ flex: 3, display: 'flex', flexDirection: 'column' }}>
                        <section
                            className="section-graph"
                            style={{ height: '100%', marginBottom: 0 }}>
                            <div
                                className="title"
                                style={{ height: '1rem' }}>
                                Nombre de contenants retournés par prix de consigne :{' '}
                            </div>
                            <div style={{ height: '100%' }}>
                                {containerByPrice.length > 0 ? (
                                    <BarChart
                                        title={containerByPriceLabels}
                                        quantities={containerByPriceQuantities}
                                    />
                                ) : (
                                    <div className="no-data-graph">Aucune donnée.</div>
                                )}
                            </div>
                        </section>
                    </div>
                </div>
                <div className="header-container">
                    <h1>Mes références</h1>
                    <div className="content-header">
                        <div className="header-container-content">
                            <select
                                onChange={(e) => setEanSelected(e.target.value)}
                                value={eanSelected}>
                                <option value="">EAN</option>

                                {myEans.map((option, index) => (
                                    <option
                                        key={index}
                                        value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>

                            <div
                                onClick={resetMyRefsFilter}
                                className="reset">
                                <p style={{ fontWeight: 'bold' }}>X</p>
                            </div>
                        </div>

                        <div className="extract-content">
                            <div className="extract">
                                <CSVLink
                                    style={{ textDecoration: 'none', color: Constant.$blanc }}
                                    className="extract-button"
                                    headers={customHeaderMyRefs}
                                    data={formattedTabMyRefsData ? formattedTabMyRefsData : []}
                                    filename={`myproduct_data.csv`}
                                    target="_blank"
                                    separator={';'}>
                                    Extraire ma donnée
                                </CSVLink>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ marginBottom: '2rem', display: 'flex', width: '100%', gap: '1%' }}>
                    <section
                        className="section-graph"
                        style={{ width: '100%' }}>
                        <div className="title">
                            Probabilité qu’au moins un de mes produits soit présent dans un retour :{' '}
                            <span style={{ fontSize: 25 }}> {myContributionReturn ? ((myContributionReturn / 100) * 10).toFixed(1) : 0}/10</span>
                        </div>
                    </section>
                </div>
            </div>
        </main>
    );
}
