export const AccessOperator = {
    operator: {
        operation: {
            collecte: {
                a_valider: 0,
                programmes: 0,
                historique: 0,
            },
        },
        data: {},
        financial: {},
    },
};
