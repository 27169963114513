import { useState, useEffect } from 'react';
import { getStoreById } from '../../../../../API/StoreApi';
import { getCollectorsByStoreId } from '../../../../../API/CollectorApi';
import { getDepotById } from '../../../../../API/DepotApi';
import { getDepotByIdCheck } from '../../../../../API/CheckApi/CheckDepotApi';
import { _defineStatus, transformDateFull, _defineCollectorType } from '../../../../../Utils';
import { Link } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import { Pencil } from '@phosphor-icons/react';
import * as Constant from '../../../../../Constants';
import * as Sentry from '@sentry/react';

export default function StoreSheet() {
    const navigate = useNavigate();
    const [filterSelected, setFilterSelected] = useState('desc');
    const [storeData, setStoreData] = useState([]);
    const [depotData, setDepotData] = useState([]);
    const [rvmownerData, setRvmownerData] = useState([]);
    const [storeCollectorsData, setStoreCollectorsData] = useState([]);

    const location = useLocation();
    const { id } = location.state || {};

    useEffect(() => {
        const fetchStoreData = async () => {
            try {
                const { gearApi } = await import('../../../../../gearApi.js');
                const storeRawData = await getStoreById(id);

                setStoreData(storeRawData[0]);

                if (storeRawData.length) {
                    if (storeRawData[0].depot_id) {
                        const depotRawData = await getDepotById(storeRawData[0].depot_id);
                        const depotRawDataCheck = getDepotByIdCheck(depotRawData);
                        if (depotRawData.length && depotRawDataCheck !== true) {
                            console.error(depotRawDataCheck);
                            Sentry.captureException(depotRawDataCheck);
                            navigate('/error');
                            return;
                        }

                        setDepotData(depotRawData[0]);
                    }
                }

                const rvmownerRawData = await gearApi.rvmowner.getRvmownerById({ rvmownerId: storeRawData[0].rvmowner_id });

                setRvmownerData(rvmownerRawData);

                const storeCollectorsRawData = await getCollectorsByStoreId(id);
                setStoreCollectorsData(storeCollectorsRawData);
            } catch (error) {
                console.error('Erreur lors de la récupération des infos du magasin', error);
            }
        };
        fetchStoreData();
    }, [id, navigate]);

    const sortDataAsc = (data, setData) => {
        const sortedData = [...data].sort((a, b) => a.name.localeCompare(b.name));
        setData(sortedData);
        setFilterSelected('asc');
    };

    const sortDataDesc = (data, setData) => {
        const sortedData = [...data].sort((a, b) => b.name.localeCompare(a.name));
        setData(sortedData);
        setFilterSelected('desc');
    };

    return (
        <main>
            {storeData.id && (
                <div className="sheet">
                    <div className="sheet-top-infos">
                        <div className="title">
                            <Link
                                className="update-depot"
                                to={'/servicesupplier/operation/store/update'}
                                state={{ id: storeData.id }}>
                                <div className="icon">
                                    <Pencil size={20} />
                                </div>
                            </Link>
                            <h2>{storeData.name}</h2>
                            <div
                                className="status"
                                style={{ backgroundColor: _defineStatus(storeData.status).color }}>
                                {_defineStatus(storeData.status).name}
                            </div>
                        </div>
                        <section className="item-info-container">
                            <div className="border">
                                <div>
                                    <p>
                                        Société Mère : <span>{storeData.company_name}</span>
                                    </p>
                                    <p>
                                        Nom : <span>{storeData.name}</span>
                                    </p>
                                </div>
                            </div>
                            <div className="border">
                                <div>
                                    <p>
                                        Adresse 1 : <span>{storeData.address_1}</span>
                                    </p>
                                    <p>
                                        Adresse 2 : <span>{storeData.address_2}</span>
                                    </p>
                                    <p>
                                        Ville : <span>{storeData.city}</span>
                                    </p>
                                    <p>
                                        Code postal : <span>{storeData.zipcode}</span>
                                    </p>
                                </div>
                            </div>
                            <div className="border">
                                <div>
                                    <p>
                                        Nom du contact : <span>{storeData.contact_name}</span>
                                    </p>
                                    <p>
                                        Email du contact : <span>{storeData.contact_email}</span>
                                    </p>
                                    <p>
                                        Téléphone du contact : <span>{storeData.contact_phone}</span>
                                    </p>
                                </div>
                            </div>
                            <div className="border">
                                <div>
                                    <p>
                                        Centre de collecte :{' '}
                                        {depotData.id ? (
                                            <span>
                                                {depotData.name} - {depotData.zipcode} {depotData.city}
                                            </span>
                                        ) : (
                                            'Aucun '
                                        )}
                                    </p>
                                    <p>
                                        Gestionnaire de RVM :{' '}
                                        {rvmownerData.id ? (
                                            <span>
                                                {rvmownerData.name} - {rvmownerData.zipcode} - {rvmownerData.city}
                                            </span>
                                        ) : (
                                            'Aucun'
                                        )}
                                    </p>
                                </div>
                            </div>
                        </section>
                        <section
                            className="item-info-container"
                            style={{ marginTop: 10 }}>
                            <div className="border"></div>
                        </section>
                    </div>

                    <section className="big-section">
                        <div className="top">
                            <h2 style={{ margin: '0' }}>Les RVM ({storeCollectorsData.length})</h2>
                            <div className="top-button-sort">
                                <div className="filter">
                                    <p className="sort">Trier par</p>
                                    <div>
                                        <p
                                            className={filterSelected === 'asc' ? 'selected' : 'classic'}
                                            onClick={() => {
                                                sortDataAsc(storeCollectorsData, setStoreCollectorsData);
                                            }}>
                                            A &gt; Z
                                        </p>
                                        <p
                                            className={filterSelected === 'desc' ? 'selected' : 'classic'}
                                            onClick={() => {
                                                sortDataDesc(storeCollectorsData, setStoreCollectorsData);
                                            }}>
                                            Z &gt; A
                                        </p>
                                    </div>
                                </div>
                                <Link
                                    to={'/servicesupplier/operation/collector/add'}
                                    state={{ depot_id: depotData.id, rvmowner_id: storeData.rvmowner_id, storeData: storeData }}
                                    className="button-actif">
                                    AJOUTER UNE RVM
                                </Link>
                            </div>
                        </div>

                        <div className="tab-item">
                            <div
                                className="pre-filled-fields-1-several-item"
                                style={{ fontWeight: '600' }}>
                                <p>Id</p>
                                <p>Device Id</p>
                                <p>Type</p>
                                <p>Capacity</p>
                                <p>Date Contact</p>
                                <p>Statut</p>
                            </div>

                            {storeCollectorsData.length ? (
                                storeCollectorsData.map((item, index) => {
                                    return (
                                        <Link
                                            to={'/servicesupplier/operation/collector/sheet'}
                                            state={{ id: item.id }}
                                            key={index}
                                            className="pre-filled-fields-2-several-item">
                                            <p>{item.id}</p>
                                            <p>{item.device_id}</p>
                                            <p>{_defineCollectorType(item.type)}</p>
                                            <p>{item.capacity}</p>
                                            <p>{transformDateFull(item.datep)}</p>
                                            <p
                                                style={{
                                                    backgroundColor: _defineStatus(item.status).color,
                                                    color: Constant.$blanc,
                                                    borderTopRightRadius: '0.6rem',
                                                    borderBottomRightRadius: '0.6rem',
                                                }}>
                                                {_defineStatus(item.status).name}
                                            </p>
                                        </Link>
                                    );
                                })
                            ) : (
                                <div className="no-data-graph ">Aucun collecteur associé à ce magasin.</div>
                            )}
                        </div>
                    </section>
                </div>
            )}
        </main>
    );
}
