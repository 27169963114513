import { useEffect, useState } from 'react';
import { getOrderDetailsByEntityId, getOrderDetailsInTransit } from '../../../API/OrderApi';
import { getOrderDetailsByEntityIdCheck, getOrderDetailsInTransitCheck } from '../../../API/CheckApi/CheckOrderApi';
import { getIndustrialProductById } from '../../../API/IndustrialApi';
import { Jwt } from '../../../Jwt';
import CircleChart from '../../../Components/CircleChart';
import { getContainerBySku } from '../../../API/ContainerApi';
import { _defineContainerType, _defineProductCat } from '../../../Utils';
import * as Constant from '../../../Constants';
import * as Sentry from '@sentry/react';
import { useNavigate } from 'react-router-dom';
export default function IndustrialHome() {
    const navigate = useNavigate();
    const userContext = new Jwt();

    const [orderDetailsBySku, setOrderDetailsBySku] = useState([]);
    const [totalContainerOrdered, setTotalContainerOrdered] = useState(0);
    const [industrialProducts, setIndustrialProducts] = useState([]);
    const [skuList, setSkuList] = useState([]);
    const [ordersToReceived, setOrdersToReceived] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getOrderDetailsByEntityId(userContext.entityId);
                const dataCheck = getOrderDetailsByEntityIdCheck(data);
                if (data.length && dataCheck !== true) {
                    console.error(dataCheck);
                    Sentry.captureException(dataCheck);
                    navigate('/error');
                    return;
                }

                for (let i = 0; i < data.length; i++) {
                    const total_nb_container = data[i].qty * data[i].container.palet_nbcontainer;
                    data[i].total_nb_container = total_nb_container;
                }
                // TOTAL ORDERS
                const totalContainerOrderedRaw = data.reduce((acc, item) => acc + item.total_nb_container, 0);
                setTotalContainerOrdered(totalContainerOrderedRaw);
                // ORDERS BY SKU
                const cumulativeQty = data.reduce((acc, curr) => {
                    const { sku, total_nb_container } = curr;
                    acc[sku] = (acc[sku] || 0) + total_nb_container;
                    return acc;
                }, {});
                const cumulativeQtyArray = Object.keys(cumulativeQty).map((sku) => ({
                    sku: sku,
                    qty: cumulativeQty[sku],
                }));
                setOrderDetailsBySku(cumulativeQtyArray);

                // PRODUCTS
                const indusProductRaw = await getIndustrialProductById(userContext.entityId);

                // CONTAINER USED
                const uniqueSKUs = indusProductRaw.reduce((acc, current) => {
                    if (!acc.includes(current.sku)) {
                        acc.push(current.sku);
                    }
                    return acc;
                }, []);

                for (let i = 0; i < uniqueSKUs.length; i++) {
                    const containerInfo = await getContainerBySku(uniqueSKUs[i]);
                    uniqueSKUs[i] = containerInfo[0];
                }

                setSkuList(uniqueSKUs);
                setIndustrialProducts(indusProductRaw);
                // ORDERS TO RECEIVE
                const orderInTransitRaw = await getOrderDetailsInTransit();
                const orderInTransitRawCheck = getOrderDetailsInTransitCheck(orderInTransitRaw);
                if (orderInTransitRaw.length && orderInTransitRawCheck !== true) {
                    console.error(orderInTransitRawCheck);
                    Sentry.captureException(orderInTransitRawCheck);
                    navigate('/error');
                    return;
                }
                const filteredData = orderInTransitRaw.filter(
                    (item) =>
                        item.washerDeclarationDetails.length &&
                        (item.washerDeclarationDetails[0].status === Constant.DECLARATION_DETAILS_ATTRIBUTED ||
                            item.washerDeclarationDetails[0].status === Constant.DECLARATION_DETAILS_VALIDATED) &&
                        item.washerDeclarationDetails[0].suivi &&
                        item.washerDeclarationDetails[0].suivi.washerEvents.length &&
                        item.washerDeclarationDetails[0].suivi.washerEvents[0].status === Constant.EVENT_DONE,
                );

                setOrdersToReceived(filteredData);
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, [userContext.entityId, navigate]);

    const orderDetailsBySkuQuantities = orderDetailsBySku.map((item) => item.qty);
    const orderDetailsBySkuLabels = orderDetailsBySku.map((item) => item.sku);

    const removeNotification = (index) => {
        const newNotifications = [...ordersToReceived];
        newNotifications.splice(index, 1);
        setOrdersToReceived(newNotifications);
    };
    return (
        <main style={{ height: '90vh' }}>
            <div style={{ height: '30%', boxSizing: 'border-box', width: '80%', display: 'flex' }}>
                <div
                    className="graph-container"
                    style={{ flex: 1 }}>
                    <div className="top">Actualités</div>
                    {ordersToReceived.length
                        ? ordersToReceived.map((item, index) => {
                              return (
                                  <div
                                      key={index}
                                      className="notification">
                                      <p>
                                          Commande #{item.order_id} de {item.qty} palettes de {item.sku} à récéptionner
                                      </p>
                                      <button onClick={() => removeNotification(index)}>X</button>
                                  </div>
                              );
                          })
                        : null}
                </div>
            </div>
            <div style={{ height: '70%', width: '100%', display: 'flex' }}>
                <div style={{ width: '60%', display: 'flex', flexDirection: 'column' }}>
                    <div
                        style={{ height: '43%' }}
                        className="graph-container">
                        <div className="top">Produits mis en consigne</div>
                        <div style={{ display: 'flex', gap: '2rem', height: '85%' }}>
                            <p
                                style={{
                                    fontWeight: 'bold',
                                    fontSize: '50px',
                                    marginBottom: '0',
                                    display: 'flex',
                                    gap: '1rem',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '30%',
                                }}>
                                {industrialProducts.length ? industrialProducts.length : 0}
                                <span style={{ fontWeight: '400', fontSize: '15px' }}>
                                    produits mis <br />
                                    en consigne
                                </span>
                            </p>
                            <div style={{ width: '70%', height: '100%' }}>
                                <div
                                    className="pre-filled-fields-1-several-item"
                                    style={{ fontWeight: '600' }}>
                                    <p style={{ marginBottom: 0, flex: 1 }}>Nom</p>
                                    <p style={{ marginBottom: 0, flex: 1 }}>EAN</p>
                                    <p style={{ marginBottom: 0, flex: 1 }}>Catégorie</p>
                                </div>
                                <div
                                    style={{ height: '80%', overflow: 'scroll', marginTop: '0.5em' }}
                                    className="hide-scrollbar">
                                    {industrialProducts.length ? (
                                        industrialProducts.map((item, index) => {
                                            return (
                                                <div
                                                    style={{ marginTop: '0.2rem' }}
                                                    key={index}
                                                    className="pre-filled-fields-2-several-item">
                                                    <p style={{ marginBottom: 0, flex: 1 }}>{item.name}</p>
                                                    <p style={{ marginBottom: 0, flex: 1 }}>{item.ean}</p>
                                                    <p style={{ marginBottom: 0, flex: 1 }}>{_defineProductCat(item.cat)}</p>
                                                </div>
                                            );
                                        })
                                    ) : (
                                        <div className="no-data-graph ">Aucun produit enregistré.</div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        style={{ height: '43%' }}
                        className="graph-container">
                        <div className="top">Contenants utilisés</div>
                        <div style={{ display: 'flex', gap: '2rem', height: '85%' }}>
                            <p
                                style={{
                                    fontWeight: 'bold',
                                    fontSize: '50px',
                                    marginBottom: '0',
                                    display: 'flex',
                                    gap: '1rem',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '30%',
                                }}>
                                {skuList.length ? skuList.length : 0}
                                <span style={{ fontWeight: '400', fontSize: '15px' }}>
                                    contenants <br /> utilisés
                                </span>
                            </p>
                            <div style={{ width: '70%', height: '100%' }}>
                                <div
                                    className="pre-filled-fields-1-several-item"
                                    style={{ fontWeight: '600' }}>
                                    <p style={{ marginBottom: 0, flex: 1 }}>Nom</p>
                                    <p style={{ marginBottom: 0, flex: 1 }}>SKU</p>
                                    <p style={{ marginBottom: 0, flex: 1 }}>Type</p>
                                </div>
                                <div
                                    style={{ height: '80%', overflow: 'scroll', marginTop: '0.5em' }}
                                    className="hide-scrollbar">
                                    {skuList.length ? (
                                        skuList.map((item, index) => {
                                            return (
                                                <div
                                                    style={{ marginTop: '0.2rem' }}
                                                    key={index}
                                                    className="pre-filled-fields-2-several-item">
                                                    <p style={{ marginBottom: 0, flex: 1 }}>{item.name}</p>
                                                    <p style={{ marginBottom: 0, flex: 1 }}>{item.sku}</p>
                                                    <p style={{ marginBottom: 0, flex: 1 }}>{_defineContainerType(item.type)}</p>
                                                </div>
                                            );
                                        })
                                    ) : (
                                        <div className="no-data-graph ">Aucun contenant utilisé.</div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ width: '40%', display: 'flex' }}>
                    <div
                        style={{ width: '100%', display: 'flex', flexDirection: 'column' }}
                        className="graph-container">
                        <div className="top">Contenants commandés</div>
                        <p
                            style={{
                                fontWeight: 'bold',
                                fontSize: '50px',
                                marginBottom: '0',
                                display: 'flex',
                                gap: '1rem',
                                alignItems: 'center',
                                flex: 1,
                            }}>
                            {totalContainerOrdered ? totalContainerOrdered : 0}
                            <span style={{ fontWeight: '400', fontSize: '15px' }}>contenants commandés</span>
                        </p>
                        <div style={{ flex: 2 }}>
                            {orderDetailsBySku.length ? (
                                <CircleChart
                                    title={orderDetailsBySkuLabels}
                                    quantities={orderDetailsBySkuQuantities}
                                    position={'left'}
                                />
                            ) : (
                                <div className="no-data-graph ">Aucun contenant commandé.</div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}
