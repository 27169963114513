import Cookies from 'js-cookie';
import * as Constants from '../../Constants';

export const sorterMenuOperation = () => {
    return ['sorter'];
};

const getSubAccessOperationSorter = () => {
    let sousMenu = [{ title: 'Tri' }];
    const access = Cookies.get('access').length ? JSON.parse(Cookies.get('access')) : null;
    if (access) {
        let access_operation = access.sorter.operation;
        if (access_operation.tri.reception === 1) {
            sousMenu.push({ name: 'Réceptions', link: '/sorter/operation/reception' });
        }
        if (access_operation.tri.declaration === 1) {
            sousMenu.push({ name: 'Déclarations', link: '/sorter/operation/declaration' });
        }
        if (access_operation.tri.historique === 1) {
            sousMenu.push({ name: 'Historique', link: 'sorter/operation/history' });
        }
        // if (access_operation.tri.order === 1) {
        //     sousMenu.push({ name: 'Commandes', link: '/sorter/order' });
        // }
    }
    return sousMenu;
};

export const getSousMenuSorterOperation = (onglet) => {
    switch (onglet) {
        case 'sorter':
            return getSubAccessOperationSorter();
        default:
            return [];
    }
};

export const sorterMenuData = () => {
    return [];
};

export const getSousMenuSorterData = (onglet) => {
    switch (onglet) {
        default:
            return [];
    }
};

export const sorterMenuFacturation = () => {
    return [];
};

export const getSousMenuSorterFacturation = (onglet) => {
    switch (onglet) {
        default:
            return [];
    }
};

export const sorterMenuAccount = () => {
    return ['my_account'];
};

export const getSousMenuSorterAccount = (onglet) => {
    const role = Cookies.get('role');
    const isAdmin = role === Constants.ENTITY_ROLE_ADMIN;
    switch (onglet) {
        case 'my_account':
            if (isAdmin) {
                return [
                    { title: 'Mon compte' },
                    { name: 'Mes informations personnelles', link: '/sorter/myprofil/personal_info' },
                    { name: 'Ma société', link: '/sorter/myprofil/company_info' },
                    { name: 'Gestion des comptes', link: '/sorter/myprofil/rolemanagement' },
                ];
            } else {
                return [
                    { title: 'Mon compte' },
                    { name: 'Mes informations personnelles', link: '/sorter/myprofil/personal_info' },
                    { name: 'Ma société', link: '/sorter/myprofil/company_info' },
                ];
            }
        default:
            return [];
    }
};
