import { getAllSalesDetailsByDates } from '../../../API/SalesApi';
import { getAllSalesDetailsByDatesCheck } from '../../../API/CheckApi/CheckSalesApi';
import { getReturnByDates } from '../../../API/CollectorReturnApi';
import { getReturnByDatesCheck } from '../../../API/CheckApi/CheckCollectorReturnApi';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { endOfDay } from 'date-fns';
import DateFilter from '../../../Components/DateFilter';
import BarChart from '../../../Components/BarChart';
import MultiLineChart from '../../../Components/MultiLineChart';
import { mergeAndFilter, reduceByMonthAndKey, addMonthToTab, addTotals } from '../../../UtilsData';
export default function CdcHome() {
    const navigate = useNavigate();
    const now = new Date();
    const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), 0, 1));
    const [endDate, setEndDate] = useState(now);
    const [amountTotalTab, setAmountTotalTab] = useState([]);
    const [amountTotal, setAmountTotal] = useState(0);
    const [amountByMonth, setAmountByMonth] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                // TOUTES LES MISE EN MARCHE
                const allSalesDetailsRaw = await getAllSalesDetailsByDates(startDate.toISOString(), endOfDay(endDate.toISOString()));
                const allSalesDetailsCheck = getAllSalesDetailsByDatesCheck(allSalesDetailsRaw);
                if (allSalesDetailsRaw.length && allSalesDetailsCheck !== true) {
                    console.error(allSalesDetailsCheck);
                    Sentry.captureException(allSalesDetailsCheck);
                    navigate('/error');
                    return;
                }

                for (let i = 0; i < allSalesDetailsRaw.length; i++) {
                    allSalesDetailsRaw[i].totalPrice = (allSalesDetailsRaw[i].qty * allSalesDetailsRaw[i].entityIndustrialProduct.container.price) / 100;
                    allSalesDetailsRaw[i].name = 'Montant mis sur le marché';
                    allSalesDetailsRaw[i].month = allSalesDetailsRaw[i].createdAt;
                }

                // TOUTES LES RETOURS
                const allReturnsRaw = await getReturnByDates(startDate.toISOString(), endOfDay(endDate.toISOString()));
                const allReturnsCheck = getReturnByDatesCheck(allReturnsRaw);
                if (allReturnsRaw.length && allReturnsCheck !== true) {
                    console.error(allReturnsCheck);
                    Sentry.captureException(allReturnsCheck);
                    navigate('/error');
                    return;
                }
                for (let i = 0; i < allReturnsRaw.length; i++) {
                    allReturnsRaw[i].totalPrice = allReturnsRaw[i].entityIndustrialProduct ? allReturnsRaw[i].entityIndustrialProduct.container.price / 100 : 0;
                    allReturnsRaw[i].name = 'Montant à rembourser';
                    allReturnsRaw[i].month = allReturnsRaw[i].returnedAt;
                }
                const totalProductedAmout = allSalesDetailsRaw.reduce((acc, sale) => acc + sale.totalPrice, 0);
                const totalReturnedAmount = allReturnsRaw.reduce((acc, sale) => acc + sale.totalPrice, 0);
                const totalAmount = totalProductedAmout - totalReturnedAmount;

                setAmountTotal(totalAmount);

                setAmountTotalTab([
                    {
                        name: 'Total mis sur le marché',
                        value: totalProductedAmout,
                    },
                    {
                        name: 'Total à rembourser',
                        value: totalReturnedAmount,
                    },
                ]);

                // EVOLUTION SOLDE CONSIGNE PAR MOIS
                const keysToKeep = ['month', 'totalPrice', 'name'];
                const returnedAndProductedMerged = mergeAndFilter(allSalesDetailsRaw, allReturnsRaw, keysToKeep);
                const amountByMonthformattedDataRaw = reduceByMonthAndKey(returnedAndProductedMerged, 'month', 'name', 'totalPrice', 'name');
                const amountByMonthformatted = addTotals(amountByMonthformattedDataRaw);
                const amountByMonthChart = addMonthToTab(
                    amountByMonthformatted,
                    startDate.toISOString(),
                    endOfDay(endDate.toISOString()),
                    'name',
                    'month',
                    'quantity',
                );
                setAmountByMonth(amountByMonthChart);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        if (startDate & endDate) fetchData();
    }, [startDate, endDate, navigate]);

    const amountTotalQuantities = amountTotalTab.map((item) => item.value);
    const amountTotalLabels = amountTotalTab.map((item) => item.name);

    const typeOfAmount = [...new Set(amountByMonth.map((fd) => fd.name))];

    const amountByMonthQuantites = typeOfAmount.map((name) => {
        const data = amountByMonth.filter((fd) => fd.name === name).map((fd) => fd.quantity);
        return {
            label: name,
            data,
        };
    });

    return (
        <main>
            <div className="data-container">
                <h1>Tableau de bord</h1>
                <div className="header-container">
                    <div className="header-title">
                        <div className="date-container">
                            <DateFilter
                                startDate={startDate}
                                endDate={endDate}
                                setStartDate={setStartDate}
                                setEndDate={setEndDate}
                            />
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', gap: '1%', height: '20rem', marginBottom: '2rem' }}>
                    <section
                        className="section-graph"
                        style={{ height: '20rem', width: '50%' }}>
                        <div
                            className="title"
                            style={{ height: '1rem' }}>
                            Solde total
                        </div>
                        <div style={{ height: '90%', fontSize: 40, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{amountTotal}€</div>
                    </section>
                    <section
                        className="section-graph"
                        style={{ height: '20rem', width: '50%' }}>
                        <div
                            className="title"
                            style={{ height: '1rem' }}>
                            Répartition cash in / cash out
                        </div>
                        <div style={{ height: '90%' }}>
                            {amountTotalTab.length > 0 ? (
                                <BarChart
                                    title={amountTotalLabels}
                                    quantities={amountTotalQuantities}
                                    label="Montant"
                                />
                            ) : (
                                <div className="no-data-graph">Aucune donnée.</div>
                            )}
                        </div>
                    </section>
                </div>
                <div style={{ height: '22rem', marginBottom: '2rem' }}>
                    <section
                        className="section-graph"
                        style={{ height: '22rem' }}>
                        <div
                            style={{ height: '1rem' }}
                            className="title">
                            Evolution du solde de consigne par mois
                        </div>
                        <div style={{ display: 'flex', gap: '1%', height: '20rem' }}>
                            <div style={{ width: '100%' }}>
                                {amountByMonth ? (
                                    <MultiLineChart
                                        initialData={amountByMonth}
                                        datasets={amountByMonthQuantites}
                                    />
                                ) : (
                                    <div className="no-data-graph ">Aucune donnée.</div>
                                )}
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </main>
    );
}
