import { useState, useEffect, useRef } from 'react';
import { getStoreowners, getStoreownersByName } from '../../../../../API/StoreownerApi';
import { _defineStatus, useClickOutside } from '../../../../../Utils';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import SearchComponent from '../../../../../Components/searchComponent.jsx';
import * as Constant from '../../../../../Constants';
export default function Storeowner() {
    const navigate = useNavigate();
    const [filterSelected, setFilterSelected] = useState('asc');

    const [AllStoreownersList, setAllStoreownersList] = useState([]);
    const [storeownersNames, setStoreownersNames] = useState([]);
    const [showOptions, setShowOptions] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const dropdownRef = useRef(null);

    const handleClickOutside = () => {
        setShowOptions(false);
    };
    useClickOutside(dropdownRef, handleClickOutside);

    const fetchAllStoreowners = async () => {
        try {
            const result = await getStoreowners();
            setAllStoreownersList(result);
            console.log(result);
        } catch (error) {
            console.error('Erreur lors de la récupération des gestionnaires de RVM');
            console.error(error);
        }
    };

    useEffect(() => {
        const fetchStoreownersNames = async () => {
            try {
                if (searchTerm.length > 2) {
                    const result = await getStoreownersByName(searchTerm);
                    if (result.length) {
                        setAllStoreownersList(result);
                        setStoreownersNames(result);
                        setErrorMessage('');
                    } else {
                        setAllStoreownersList([]);
                        setStoreownersNames([]);
                        setErrorMessage('Aucun résultat');
                    }
                } else if (searchTerm.length === 0) {
                    fetchAllStoreowners();
                    setErrorMessage('');
                }
            } catch (error) {
                console.error('Erreur lors de la récupération des depots', error);
            }
        };

        fetchStoreownersNames();
    }, [searchTerm]);

    const handleInputChange = (e) => {
        setSearchTerm(e.target.value.toLowerCase());
        setShowOptions(true);
    };

    const handleSelectOption = (option) => {
        setSearchTerm(option.name);
        setShowOptions(false);
        console.log(option);
        navigate('/servicesupplier/operation/storeowner/sheet', { state: { id: option.id } });
    };

    const sortDataAsc = () => {
        const sortedData = [...AllStoreownersList].sort((a, b) => a.name.localeCompare(b.name));
        setFilterSelected('asc');
        setAllStoreownersList(sortedData);
    };

    const sortDataDesc = () => {
        const sortedData = [...AllStoreownersList].sort((a, b) => b.name.localeCompare(a.name));
        setFilterSelected('desc');
        setAllStoreownersList(sortedData);
    };

    return (
        <main>
            <section className="big-section">
                <h2>Les gestionnaires de magasins ({AllStoreownersList.length})</h2>
                <div className="top">
                    <SearchComponent
                        dropdownRef={dropdownRef}
                        showOptions={showOptions}
                        searchTerm={searchTerm}
                        handleInputChange={handleInputChange}
                        productsNames={storeownersNames}
                        handleSelectOption={handleSelectOption}
                        placeholder="Rechercher.."
                        searchKey="name"
                    />

                    <div className="top-button-sort">
                        <div className="filter">
                            <p className="sort">Trier par</p>
                            <div>
                                <p
                                    className={filterSelected === 'asc' ? 'selected' : 'classic'}
                                    onClick={sortDataAsc}>
                                    A &gt; Z
                                </p>
                                <p
                                    className={filterSelected === 'desc' ? 'selected' : 'classic'}
                                    onClick={sortDataDesc}>
                                    Z &gt; A
                                </p>
                            </div>
                        </div>
                        <Link
                            to={'/servicesupplier/operation/storeowner/add'}
                            className="button-actif">
                            AJOUTER UN GESTIONNAIRE
                        </Link>
                    </div>
                </div>
                {errorMessage && (
                    <div style={{ width: '100%', padding: '0.5rem', textAlign: 'center', color: '#EB675B', marginBottom: '1.25rem' }}>
                        <p>{errorMessage}</p>
                    </div>
                )}
                <div className="tab-item">
                    <div
                        className="pre-filled-fields-1-several-item"
                        style={{ fontWeight: '600' }}>
                        <p>Nom</p>
                        <p>Ville</p>
                        <p>Code Postal</p>
                        <p>Nb Magasin</p>
                        <p>Statut</p>
                    </div>

                    {AllStoreownersList.length
                        ? AllStoreownersList.map((item, index) => {
                              return (
                                  <Link
                                      to={'/servicesupplier/operation/storeowner/sheet'}
                                      state={{ storeowner: item }}
                                      key={index}
                                      className="pre-filled-fields-2-several-item">
                                      <p>{item.name}</p>
                                      <p>{item.city}</p>
                                      <p>{item.zipcode}</p>
                                      <p>{item.storesOwnerData.length}</p>
                                      <p
                                          style={{
                                              backgroundColor: _defineStatus(item.status).color,
                                              color: Constant.$blanc,
                                              borderRadius: '0.6rem',
                                          }}>
                                          {_defineStatus(item.status).name}
                                      </p>
                                  </Link>
                              );
                          })
                        : null}
                </div>
            </section>
        </main>
    );
}
