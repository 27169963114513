import { useState, useEffect } from 'react';
import { getContainerById, getSuppliersForSku } from '../../../../../API/ContainerApi';
import { _defineStatus, _defineContainerCat, _defineContainerType, _defineContainerSubType, _defineContainerPaletType } from '../../../../../Utils';
import { Link, useLocation } from 'react-router-dom';
import { Pencil } from '@phosphor-icons/react';
import * as Constant from '../../../../../Constants';

export default function ContainerSheet() {
    const [filterSelected, setFilterSelected] = useState('asc');

    const [containerData, setContainerData] = useState([]);
    const [supplierSkuData, setSupplierSkuData] = useState([]);
    const location = useLocation();
    const { id, sku } = location.state || {};

    useEffect(() => {
        const fetchContainerData = async () => {
            try {
                const containerRowData = await getContainerById(id);
                setContainerData(containerRowData[0]);

                const supplierSku = await getSuppliersForSku(sku);
                setSupplierSkuData(supplierSku);
            } catch (error) {
                console.error('Erreur lors de la récupération des infos du contenant', error);
            }
        };
        fetchContainerData();
    }, [id, sku]);

    const sortDataAsc = (data, setData) => {
        const sortedData = [...data].sort((a, b) => a.name.localeCompare(b.name));
        setData(sortedData);
        setFilterSelected('asc');
    };

    const sortDataDesc = (data, setData) => {
        const sortedData = [...data].sort((a, b) => b.name.localeCompare(a.name));
        setData(sortedData);
        setFilterSelected('desc');
    };
    return (
        <main>
            {containerData.id && (
                <div className="sheet">
                    <div className="sheet-top-infos">
                        <div className="title">
                            <Link
                                className="update-indus"
                                to={'/servicesupplier/operation/container/update'}
                                state={{ id: containerData.id }}>
                                <div className="icon">
                                    <Pencil size={20} />
                                </div>
                            </Link>
                            <h2>{containerData.sku}</h2>
                            <div
                                className="status"
                                style={{ backgroundColor: _defineStatus(containerData.status).color }}>
                                {_defineStatus(containerData.status).name}
                            </div>
                        </div>
                        <section className="item-info-container">
                            <div className="border">
                                <div>
                                    <p>
                                        Nom : <span>{containerData.name}</span>
                                    </p>
                                    <p>
                                        SKU : <span>{containerData.sku}</span>
                                    </p>
                                    <p>
                                        Catégorie : <span>{_defineContainerCat(containerData.cat)}</span>
                                    </p>
                                    <p>
                                        Propriétaire : <span>{containerData.owner_brand ? containerData.entity.name : 'standard'}</span>
                                    </p>
                                    <p>
                                        Type : <span>{_defineContainerType(containerData.type)}</span>
                                    </p>
                                    <p>
                                        Sous-type : <span>{_defineContainerSubType(containerData.subtype)}</span>
                                    </p>
                                </div>
                            </div>
                            <div className="border">
                                <div>
                                    <p>
                                        Unité : <span>{containerData.unit}</span>
                                    </p>
                                    <p>
                                        Valeur unité : <span>{containerData.unitValue}</span>
                                    </p>
                                    <p>
                                        Materiaux : <span>{containerData.material}</span>
                                    </p>
                                    <p>
                                        Couleur : <span>{containerData.color}</span>
                                    </p>
                                    <p>
                                        Poids : <span>{containerData.weight}</span>
                                    </p>
                                </div>
                            </div>
                            <div className="border">
                                <div>
                                    <p>
                                        Hauteur : <span>{containerData.dim_height}</span>
                                    </p>
                                    <p>
                                        Largeur : <span>{containerData.dim_width}</span>
                                    </p>
                                    <p>
                                        Longueur : <span>{containerData.dim_length}</span>
                                    </p>
                                    <p>
                                        Diamètre : <span>{containerData.dim_diameter}</span>
                                    </p>
                                </div>
                            </div>
                            <div className="border">
                                <div>
                                    <p>
                                        Type de palette : <span>{_defineContainerPaletType(containerData.palet_type)}</span>
                                    </p>
                                    <p style={{ flexDirection: 'column' }}>
                                        Nb contenants / palette : <span>{containerData.palet_nbcontainer}</span>
                                    </p>
                                    <p>
                                        Url specifications tech : <span style={{ fontWeight: 400 }}>{containerData.dim_url}</span>
                                    </p>
                                </div>
                            </div>
                        </section>
                    </div>

                    <section className="big-section">
                        <div className="top">
                            <h2 style={{ margin: '0' }}>Verriers disponibles :</h2>
                            <div
                                className="top-button-sort"
                                style={{ gap: 0 }}>
                                <div className="filter">
                                    <p className="sort">Trier par</p>
                                    <div>
                                        <p
                                            className={filterSelected === 'asc' ? 'selected' : 'classic'}
                                            onClick={() => {
                                                sortDataAsc(supplierSkuData, setSupplierSkuData);
                                            }}>
                                            A &gt; Z
                                        </p>
                                        <p
                                            className={filterSelected === 'desc' ? 'selected' : 'classic'}
                                            onClick={() => {
                                                sortDataDesc(supplierSkuData, setSupplierSkuData);
                                            }}>
                                            Z &gt; A
                                        </p>
                                    </div>
                                </div>
                                <div style={{ height: '2.75rem' }}></div>
                            </div>
                        </div>

                        <div className="tab-item">
                            <div
                                className="pre-filled-fields-1-several-item"
                                style={{ fontWeight: '600' }}>
                                <p>Verrier</p>
                                <p>Nom produit</p>
                                <p>Ref</p>
                                <p>Statut</p>
                            </div>

                            {supplierSkuData.length ? (
                                supplierSkuData.map((item, index) => {
                                    return (
                                        <Link
                                            to={'/servicesupplier/operation/supplier/sheet'}
                                            state={{ id: item.supplier_id }}
                                            key={index}
                                            className="pre-filled-fields-2-several-item">
                                            <p>{item.company_name}</p>
                                            <p>{item.name}</p>
                                            <p>{item.ref}</p>
                                            <p
                                                style={{
                                                    backgroundColor: _defineStatus(item.status).color,
                                                    color: Constant.$blanc,
                                                    borderTopRightRadius: '0.6rem',
                                                    borderBottomRightRadius: '0.6rem',
                                                }}>
                                                {_defineStatus(item.status).name}
                                            </p>
                                        </Link>
                                    );
                                })
                            ) : (
                                <div className="no-data-graph ">Aucun verrier disponible.</div>
                            )}
                        </div>
                    </section>
                </div>
            )}
        </main>
    );
}
