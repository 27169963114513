import { useState, useEffect, useRef } from 'react';
import { getSorterById, getSorterEventsByOperator, sorterPickUpDone } from '../../../API/SorterApi';
import { getIndustrialById } from '../../../API/IndustrialApi';
import { _defineCollecteType, _defineBoxesStoreState, _defineStoreEventType } from '../../../Utils';
import { format } from 'date-fns';
import { Jwt } from '../../../Jwt';
import * as Constant from '../../../Constants';
import * as Sentry from '@sentry/react';
import { useNavigate } from 'react-router-dom';
import { getStoreEventDetailsByOperatorId, storePickUpDone } from '../../../API/StoreApi';
import * as DateFns from 'date-fns';

export default function CollecteScheduled() {
    const navigate = useNavigate();
    const userContext = new Jwt();
    const [collecteData, setCollecteData] = useState([]);
    const [allEvents, setAllEvents] = useState([]);

    const dialogRef = useRef(null);

    useEffect(() => {
        const fetchAllEvents = async () => {
            const { gearApi } = await import('../../../gearApi.js');

            const fetchStoreEventsRowData = (await getStoreEventDetailsByOperatorId(userContext.entityId)) || [];
            const groupByStore = fetchStoreEventsRowData.reduce((acc, obj) => {
                const key = `${obj.storeEvent.storeId}-${obj.storeEvent.type}-${obj.suiviId}`;

                if (!acc[key]) {
                    acc[key] = [];
                }
                acc[key].push(obj);
                return acc;
            }, {});

            const storeEventsRowData = Object.keys(groupByStore).map((key) => ({
                time: groupByStore[key][0].storeEvent.time,
                status: groupByStore[key][0].storeEvent.status,
                collecte_type: Constant.COLLECTETYPE_STORE,
                item: groupByStore[key],
                suiviId: groupByStore[key][0].suiviId,
                departure: groupByStore[key][0].storeEvent.store.depot,
            }));

            for (let i = 0; i < storeEventsRowData.length; i++) {
                const sorterInfo = await getSorterById(storeEventsRowData[i].item[0].storeEvent.sorterId);
                if (storeEventsRowData[i].item[0].storeEvent.type === Constant.STOREEVENT_TYPE_COLLECTE) {
                    storeEventsRowData[i].arrival = sorterInfo[0];
                } else {
                    storeEventsRowData[i].arrival = storeEventsRowData[i].departure;
                    storeEventsRowData[i].departure = sorterInfo[0];
                }
            }

            const sorterEventsRowData = (await getSorterEventsByOperator(userContext.entityId)) || [];
            for (let i = 0; i < sorterEventsRowData.length; i++) {
                const event = sorterEventsRowData[i];
                const sorterInfo = await getSorterById(event.sorterId);
                const washerInfo = await gearApi.washer.getWasherById({ washerId: event.washerId });
                sorterEventsRowData[i] = {
                    ...event,
                    collecte_type: Constant.COLLECTETYPE_SORTER,
                    departure: sorterInfo[0],
                    arrival: washerInfo,
                };
            }
            const washerEventsRowData = (await gearApi.washer.getEventsOfWashers({ operatorId: userContext.entityId })) || [];
            for (let i = 0; i < washerEventsRowData.length; i++) {
                const event = washerEventsRowData[i];
                const washerInfo = await gearApi.washer.getWasherById({ washerId: event.washerId });
                const industrialInfo = await getIndustrialById(event.industrialId);
                washerEventsRowData[i] = {
                    ...event,
                    collecte_type: Constant.COLLECTETYPE_WASHER,
                    departure: washerInfo,
                    arrival: industrialInfo[0],
                };
            }

            const tab = [...storeEventsRowData, ...sorterEventsRowData, ...washerEventsRowData];
            const finalData = filterData(tab);
            const finalFilteredData = finalData.sort((a, b) => new Date(b.time) - new Date(a.time));

            setAllEvents(finalFilteredData);
        };

        fetchAllEvents();
    }, [userContext.entityId, navigate]);

    const filterData = (data) => {
        const filteredData = [];
        const groupedData = {};
        data.forEach((item) => {
            if (!groupedData[item.suiviId]) {
                groupedData[item.suiviId] = [];
            }
            groupedData[item.suiviId].push(item);
        });

        Object.keys(groupedData).forEach((suiviId) => {
            const itemsWithRightStatus = groupedData[suiviId].some((item) => item.status === Constant.EVENT_ACCEPTED);

            if (itemsWithRightStatus) {
                filteredData.push(...groupedData[suiviId]);
            }
        });

        return filteredData;
    };

    const handleModalOpen = async (elem) => {
        const updatedItem = {
            ...elem,
            item: elem.item.map((i) => ({
                ...i,
                container: {
                    ...i.container,
                    collectors: i.container.collectors.filter((collector) => collector.store_id === elem.arrival.id),
                },
            })),
        };
        setCollecteData(updatedItem);

        if (dialogRef.current) {
            dialogRef.current.showModal();
        }
    };
    const handleModalClose = () => {
        setCollecteData([]);

        if (dialogRef.current) {
            dialogRef.current.close();
        }
    };
    const sendPickUpDone = async () => {
        const dateAujourdhui = new Date();
        const pickUpAcceptTime = format(dateAujourdhui, 'yyyy-MM-dd HH:mm:ss');

        try {
            // First in case we use GearApi already
            if (collecteData.collecte_type === 2) {
                const { gearApi } = await import('../../../gearApi.js');
                await gearApi.washer
                    .putStatusToSuiviofEventsForWashers({
                        status: Constant.EVENT_DONE,
                        suiviId: collecteData.suiviId,
                        washerId: collecteData.washerId,
                        requestBody: {
                            industrialId: collecteData.industrialId,
                            operatorId: collecteData.operatorId,
                            time: dateAujourdhui,
                        },
                    })

                    .catch(function (err) {
                        console.debug(err);
                        Sentry.captureException(err);
                        alert('Une erreur est survenue');
                    });
            } else {
                const pickUpDoneFunction = (type) => {
                    switch (type) {
                        case Constant.COLLECTETYPE_STORE:
                            return storePickUpDone(collecteData, pickUpAcceptTime);
                        case Constant.COLLECTETYPE_SORTER:
                            return sorterPickUpDone(collecteData, pickUpAcceptTime);
                        case Constant.COLLECTETYPE_WASHER:
                            break; // Special treatment for the new GearApi
                        default:
                            return 'Type de collecte non géré';
                    }
                };

                const data = await pickUpDoneFunction(collecteData.collecte_type);

                if (data.message === 'ok') {
                    // const resetCollector = await collectorResetFillingRate(collecteData.collectorId);
                    // if (resetCollector) {
                    alert('La collecte a bien été effectuée');
                    handleModalClose();
                    window.location.reload();
                    // }
                } else {
                    alert('Une erreur est survenue');
                }
            }
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <main>
            <section className="big-section">
                <h2>Collectes programmées</h2>
                <div className="tab-item">
                    <div
                        className="pre-filled-fields-1-several-item"
                        style={{ fontWeight: '600', width: '100%' }}>
                        <p>Type de collecte</p>
                        <p>Départ</p>
                        <p>Arrivée</p>
                        <p>Date prévue de collecte</p>
                    </div>
                    {allEvents.length ? (
                        allEvents.map((item, index) => {
                            return (
                                <div
                                    style={{ display: 'flex', flexWrap: 'wrap', width: '100%', justifyContent: 'space-between', cursor: 'pointer' }}
                                    key={index}>
                                    <div
                                        style={{ display: 'flex', flexWrap: 'wrap', width: '100%', justifyContent: 'space-between', cursor: 'pointer' }}
                                        key={index}>
                                        <div
                                            style={{ width: '100%' }}
                                            onClick={() => {
                                                handleModalOpen(item);
                                            }}
                                            className="pre-filled-fields-2-several-item">
                                            <p>
                                                {item.collecte_type === Constant.COLLECTETYPE_STORE
                                                    ? `${_defineCollecteType(item.collecte_type)} - ${_defineStoreEventType(item.item[0].storeEvent.type)} `
                                                    : _defineCollecteType(item.collecte_type)}
                                            </p>
                                            <p>{item.departure.name}</p>
                                            <p>{item.arrival.name}</p>
                                            <p>{DateFns.format(item.time, 'yyyy-MM-dd')}</p>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <div className="no-data-graph ">Aucune collecte à valider.</div>
                    )}
                </div>
            </section>

            <dialog
                ref={dialogRef}
                className="dialog-collector">
                <div className="title">
                    <p>Confirmation collecte</p>
                    <button
                        className="button-negatif"
                        onClick={handleModalClose}>
                        X
                    </button>
                </div>
                {collecteData && collecteData.departure && collecteData.arrival ? (
                    <>
                        <div className="collector-infos">
                            <div className="depart-arrival-container">
                                <div>
                                    <h4>Départ</h4>
                                    <p>{collecteData.departure.company_name}</p>
                                    <p>{collecteData.departure.address_1}</p>
                                    <p>{collecteData.departure.address_2}</p>
                                    <p>
                                        {collecteData.departure.zipcode},{collecteData && collecteData.departure.city}
                                    </p>
                                </div>

                                <div className="arrival">
                                    <h4>Arrivée</h4>
                                    <p>{collecteData.arrival.company_name}</p>
                                    <p>{collecteData.arrival.address_1}</p>
                                    <p>{collecteData.arrival.address_2}</p>
                                    <p>
                                        {collecteData.arrival.zipcode},{collecteData.arrival.city}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="collector-infos">
                            {collecteData.collecte_type === Constant.COLLECTETYPE_STORE &&
                                collecteData.item
                                    .reduce((acc, elem) => {
                                        const existingElem = acc.find(
                                            (e) => e.suivi === elem.suivi && e.container.name === elem.container.name && e.empty === elem.empty,
                                        );

                                        if (existingElem) {
                                            existingElem.qty += elem.qty;
                                        } else {
                                            acc.push({ ...elem });
                                        }

                                        return acc;
                                    }, [])
                                    .map((elem, index) => {
                                        return (
                                            <div
                                                key={index}
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    padding: '0.5rem 0',
                                                }}>
                                                <p>
                                                    {elem.container.name} - {_defineBoxesStoreState(elem.empty)}
                                                </p>
                                                <p>x{elem.qty}</p>
                                            </div>
                                        );
                                    })}
                        </div>
                        <div className="collector-date">
                            <label>A collecter le :</label>
                            <p>{format(collecteData.time, 'yyyy-MM-dd')}</p>
                        </div>
                    </>
                ) : null}

                <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '2rem' }}>
                    <button
                        className="button-negatif"
                        onClick={handleModalClose}>
                        ANNULER
                    </button>
                    <button
                        onClick={sendPickUpDone}
                        className="button-actif">
                        VALIDER
                    </button>
                </div>
            </dialog>
        </main>
    );
}
