export const AccessStoreowner = {
    storeowner: {
        operation: {
            magasins: {
                details_magasin: 0,
                livraison_et_reprise: 0,
                liste_rvm: 0,
                contact_gest_mag: 0,
            },
        },
        data: {},
        financial: {
            gestion_financiere: {
                transaction: 0,
                reportings: 0,
            },
        },
    },
};
