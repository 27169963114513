import { useState, useRef } from 'react';
import { useClickOutside } from '../Utils';
export default function AddressSearch({ formData, setFormData, formErrors, handleChange }) {
    const [showOptions, setShowOptions] = useState(false);
    const [address, setAddress] = useState('');
    const [suggestions, setSuggestions] = useState([]);

    const dropdownRef = useRef(null);

    const handleClickOutside = () => {
        setShowOptions(false);
    };
    useClickOutside(dropdownRef, handleClickOutside);

    const handleInputChange = async (e) => {
        const query = e.target.value;
        setAddress(query);
        if (query.length > 2) {
            try {
                const response = await fetch(`https://api-adresse.data.gouv.fr/search/?q=${encodeURIComponent(query)}&limit=5`);

                const data = await response.json();

                if (data && data.features) {
                    setShowOptions(true);
                    setSuggestions(data.features);
                } else {
                    setSuggestions(null);
                }
            } catch (error) {
                console.error('Error fetching addresses:', error);
                setShowOptions(false);
                setSuggestions([]);
                setFormData({
                    ...formData,
                    address_1: query,
                });
            }
        } else {
            setSuggestions([]);
        }
    };

    const handleSuggestionClick = (suggestion) => {
        setFormData({
            ...formData,
            address_1: suggestion.properties.name,
            city: suggestion.properties.city,
            zipcode: suggestion.properties.postcode,
            lat: suggestion.geometry.coordinates[1],
            long: suggestion.geometry.coordinates[0],
        });

        setAddress(suggestion.properties.name);

        setSuggestions([]);
    };
    return (
        <>
            <div
                ref={dropdownRef}
                style={{ position: 'relative', margin: '0.5rem' }}
                className="scrolling-search-menu">
                <label> Adresse 1 de la société:</label>
                <input
                    className={formErrors.address_1 ? 'error' : ''}
                    name="address_1"
                    style={{
                        outline: 'none',
                        borderBottomLeftRadius: showOptions && '0px',
                        borderBottomRightRadius: showOptions && '0px',
                        width: '50%',
                        position: 'relative',
                    }}
                    type="text"
                    value={address || formData.address_1}
                    onChange={handleInputChange}
                />

                {showOptions && (
                    <div
                        style={{
                            position: 'absolute',
                            top: '100%',
                            right: 0,
                            width: '55%',
                            margin: 0,
                        }}
                        className="test-option">
                        <div style={{ width: '100%' }}>
                            <ul style={{ listStyle: 'none', margin: 0, padding: 0, width: '100%' }}>
                                {suggestions.length > 0
                                    ? suggestions.map(
                                          (option, index) =>
                                              option.properties.label.toLowerCase().includes(address) && (
                                                  <li
                                                      style={{ width: '90%' }}
                                                      key={index}
                                                      onClick={() => handleSuggestionClick(option)}>
                                                      {option.properties.label}
                                                  </li>
                                              ),
                                      )
                                    : null}
                            </ul>
                        </div>
                    </div>
                )}
            </div>
            <div>
                <label>Adresse 2 de la société:</label>
                <input
                    className={formErrors.address_2 ? 'error' : ''}
                    type="text"
                    name="address_2"
                    onChange={handleChange}
                    required
                />
            </div>
            <div>
                <label>Code postal:</label>
                <input
                    className={formErrors.zipcode ? 'error' : ''}
                    type="text"
                    name="zipcode"
                    onChange={handleChange}
                    required
                    value={formData.zipcode || ''}
                    // disabled={formData.lat && formData.long ? true : false}
                />
            </div>
            <div>
                <label>Ville:</label>
                <input
                    className={formErrors.city ? 'error' : ''}
                    type="text"
                    name="city"
                    onChange={handleChange}
                    required
                    value={formData.city || ''}
                    // disabled={formData.lat && formData.long ? true : false}
                />
            </div>
        </>
    );
}
