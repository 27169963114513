import { useState, useEffect } from 'react';
import { getCollectorById } from '../../../API/CollectorApi';
import { _defineStatus, transformDateFull, _defineCollectorType } from '../../../Utils';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { getReturnByCollectorId } from '../../../API/CollectorReturnApi';
import { Pencil } from '@phosphor-icons/react';

export default function CollectorSheet() {
    const [filterSelected, setFilterSelected] = useState('asc');

    const [collectorData, setCollectorData] = useState([]);
    const [collectorReturnData, setCollectorReturnData] = useState([]);
    const location = useLocation();
    const { id } = location.state || {};

    useEffect(() => {
        const fetchDepotData = async () => {
            try {
                const collectorRawData = await getCollectorById(id);
                setCollectorData(collectorRawData[0]);

                const storeCollectorReturnRawData = await getReturnByCollectorId(id);
                setCollectorReturnData(storeCollectorReturnRawData);
            } catch (error) {
                console.error('Erreur lors de la récupération des infos du collecteur', error);
            }
        };
        fetchDepotData();
    }, [id]);

    console.log(collectorReturnData);

    const sortDataAsc = (data, setData) => {
        const sortedData = [...data].sort((a, b) => a.returnedAt.localeCompare(b.returnedAt));
        setData(sortedData);
        setFilterSelected('asc');
    };

    const sortDataDesc = (data, setData) => {
        const sortedData = [...data].sort((a, b) => b.returnedAt.localeCompare(a.returnedAt));
        setData(sortedData);
        setFilterSelected('desc');
    };
    return (
        <main>
            {collectorData.id && (
                <div className="sheet">
                    <div className="sheet-top-infos">
                        <div className="title">
                            <Link
                                className="update-collector"
                                to={'/rvmowner/operation/collector/update'}
                                state={{ id: collectorData.id }}>
                                <div className="icon">
                                    <Pencil size={20} />
                                </div>
                            </Link>
                            <h2>
                                {collectorData.store_name} - {collectorData.id}
                            </h2>
                            <div
                                className="status"
                                style={{ backgroundColor: _defineStatus(collectorData.status).color }}>
                                {_defineStatus(collectorData.status).name}
                            </div>
                        </div>
                        <section className="item-info-container">
                            <div className="border">
                                <div>
                                    <p>
                                        Enseigne : <span>{collectorData.store_company_name}</span>
                                    </p>
                                    <p>
                                        Nom : <span>{collectorData.store_name}</span>
                                    </p>
                                    <p>
                                        Addresse : <span>{collectorData.store_address_1}</span>
                                    </p>
                                    <p>
                                        Addresse Comp. : <span>{collectorData.store_address_2}</span>
                                    </p>
                                    <p>
                                        Code postal : <span>{collectorData.store_zipcode}</span>
                                    </p>
                                    <p>
                                        Ville : <span>{collectorData.store_city}</span>
                                    </p>
                                </div>
                            </div>
                            <div className="border">
                                <div>
                                    <p>
                                        Fabricant : <span>{collectorData.supplier_name}</span>
                                    </p>
                                    <p>
                                        Modèle : <span>{collectorData.supplier_model}</span>
                                    </p>
                                    <p>
                                        Type : <span>{_defineCollectorType(collectorData.type)}</span>
                                    </p>
                                    <p>
                                        Device Id : <span>{collectorData.device_id}</span>
                                    </p>
                                    <p>
                                        Capacité : <span>X/{collectorData.capacity}</span>
                                    </p>
                                </div>
                            </div>
                            <div className="border">
                                <div>
                                    <p>
                                        Dernière Sync : <span>{transformDateFull(collectorData.datep)}</span>
                                    </p>
                                    <p>
                                        Remplissage : <span>{collectorData.filling_rate}</span>
                                    </p>
                                    <p>
                                        Statut syncronisation : <span>{_defineStatus(collectorData.status).name}</span>
                                    </p>
                                </div>
                            </div>
                        </section>
                    </div>
                    <section className="big-section">
                        <div className="top">
                            <h2 style={{ margin: '0' }}>Les Retours :</h2>
                            <div className="top-button-sort">
                                <div className="filter">
                                    <p className="sort">Trier par</p>
                                    <div>
                                        <p
                                            className={filterSelected === 'asc' ? 'selected' : 'classic'}
                                            onClick={() => {
                                                sortDataAsc(collectorReturnData, setCollectorReturnData);
                                            }}>
                                            A &gt; Z
                                        </p>
                                        <p
                                            className={filterSelected === 'desc' ? 'selected' : 'classic'}
                                            onClick={() => {
                                                sortDataDesc(collectorReturnData, setCollectorReturnData);
                                            }}>
                                            Z &gt; A
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="tab-item">
                            <div
                                className="pre-filled-fields-1-several-item"
                                style={{ fontWeight: '600' }}>
                                <p>Id</p>
                                <p>Date Retour</p>
                                <p>SKU</p>
                                <p>EAN</p>
                                <p>Qty</p>
                            </div>

                            {collectorReturnData.length
                                ? collectorReturnData.map((item, index) => {
                                      return (
                                          <div
                                              to={'/servicesupplier/operation/collector/sheet'}
                                              key={index}
                                              className="pre-filled-fields-2-several-item">
                                              <p>{item.id}</p>
                                              <p>{transformDateFull(item.returnedAt)}</p>
                                              <p>{item.entityIndustrialProduct ? item.entityIndustrialProduct.sku : '-'}</p>
                                              <p>{item.ean}</p>
                                              <p>{item.qty}</p>
                                          </div>
                                      );
                                  })
                                : null}
                        </div>
                    </section>
                </div>
            )}
        </main>
    );
}
