import { useEffect, useState } from 'react';
import { addContainer } from '../../../../../API/ContainerApi';
import { useNavigate } from 'react-router-dom';
import { checkContainerFormErrors, checkIsInputEmpty } from '../../../../../Utils';
import { getIndustrials } from '../../../../../API/IndustrialApi';
import {
    containerTypesTab,
    containerSubTypesTabBowl,
    containerSubTypesTabBottle,
    containerSubTypesTabContainer,
    _defineContainerType,
    _defineContainerSubType,
    containerCatTab,
    _defineContainerCat,
    // containerOwnerBrandTab,
    // _defineContainerOwnerBrand,
    containerMaterialTab,
    _defineContainerMaterial,
    containerPaletTypeTab,
    _defineContainerPaletType,
    containerUnitTab,
    _defineContainerUnit,
    containerOwnerStatusTab,
    _defineContainerOwnerStatus,
    containerUsageTab,
    _defineContainerUsage,
} from '../../../../../Utils';
import * as Contants from '../../../../../Constants';
export default function AddContainer() {
    const navigate = useNavigate();
    const [containerOwnerBrandTab, setContainerOwnerBrandTab] = useState([]);
    const [errorMessage, setErrorMessage] = useState([]);
    const [containerSubTypeTab, setContainerSubTypeTab] = useState([]);
    const [errorEmpty, setErrorEmpty] = useState('');
    const [formData, setFormData] = useState({
        name: '',
        sku: '',
        cat: null,
        owner: null,
        owner_brand: null,
        type: null,
        subtype: null,
        material: null,
        unit: '',
        unitValue: null,
        color: '',
        weight: null,
        palet_type: null,
        palet_nbcontainer: null,
        dim_width: null,
        dim_length: null,
        dim_height_without_cover: null,
        dim_height_with_cover: null,
        dim_base_diameter: null,
        dim_opening_diameter: null,
        url: '',
        usage: '',
        price: null,
    });

    useEffect(() => {
        const fetchData = async () => {
            const industrials = await getIndustrials();
            setContainerOwnerBrandTab(industrials);
        };
        fetchData();
    }, []);

    const [formErrors, setFormErrors] = useState({
        name: false,
        sku: false,
        unitValue: false,
        weight: false,
        palet_nbcontainer: false,
        dim_width: false,
        dim_length: false,
        dim_height_without_cover: false,
        dim_height_with_cover: false,
        dim_base_diameter: false,
        dim_opening_diameter: false,
        url: false,
    });
    const requiredFields = [
        'name',
        'sku',
        'cat',
        'owner',
        'type',
        'material',
        'unit',
        'unitValue',
        'weight',
        'dim_height_without_cover',
        'dim_height_with_cover',
        'palet_type',
        'palet_nbcontainer',
        'usage',
        'price',
    ];

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        if (e.target.name === 'type') {
            const type = parseInt(e.target.value);
            if (type === Contants.CONTAINER_TYPE_BOWL) {
                setContainerSubTypeTab(containerSubTypesTabBowl);
            } else if (type === Contants.CONTAINER_TYPE_BOTTLE) {
                setContainerSubTypeTab(containerSubTypesTabBottle);
            } else if (type === Contants.CONTAINER_TYPE_CONTAINER) {
                setContainerSubTypeTab(containerSubTypesTabContainer);
            } else {
                setContainerSubTypeTab([]);
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage([]);
        setErrorEmpty('');
        const inputEmpty = checkIsInputEmpty(requiredFields, formData, setFormErrors, setErrorEmpty);
        if (!inputEmpty) {
            const checkFormat = checkContainerFormErrors(formData, formErrors, setFormErrors, setErrorMessage);
            if (checkFormat) {
                return;
            } else {
                const addContainerData = await addContainer(formData);
                if (addContainerData.message === 'sku already exist') {
                    alert('Ce SKU existe déjà');
                } else {
                    alert('Contenant ajouté');
                    navigate(-1);
                }
            }
        } else {
            console.log('Il reste des champs obligatoires vides');
        }
    };

    return (
        <main>
            <p>{errorMessage && errorMessage}</p>
            <section className="big-section">
                <h2>Ajouter un contenant</h2>
                <div className="form-container">
                    <section>
                        <div>
                            <label className="small-title">Type de contenant:</label>
                            <select
                                className={formErrors.usage ? 'error' : ''}
                                name="usage"
                                onChange={handleChange}>
                                <option value={''}>--</option>
                                {containerUsageTab.map((type, index) => (
                                    <option
                                        key={index}
                                        value={parseInt(type)}>
                                        {_defineContainerUsage(type)}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label className="small-title">Nom:</label>
                            <input
                                className={formErrors.name ? 'error' : ''}
                                type="text"
                                name="name"
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <label className="small-title">SKU:</label>
                            <input
                                className={formErrors.sku ? 'error' : ''}
                                type="text"
                                name="sku"
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <label className="small-title">Gamme:</label>
                            <select
                                className={formErrors.cat ? 'error' : ''}
                                name="cat"
                                onChange={handleChange}>
                                <option value={''}>--</option>
                                {containerCatTab.map((type, index) => (
                                    <option
                                        key={index}
                                        value={parseInt(type)}>
                                        {_defineContainerCat(type)}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label className="small-title">Propriétaire:</label>
                            <select
                                className={formErrors.owner ? 'error' : ''}
                                name="owner"
                                onChange={handleChange}>
                                <option value={''}>--</option>
                                {containerOwnerStatusTab.map((status, index) => (
                                    <option
                                        key={index}
                                        value={parseInt(status)}>
                                        {_defineContainerOwnerStatus(status)}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label className="small-title">Marque:</label>
                            <select
                                disabled={parseInt(formData.owner) === Contants.CONTAINER_OWNER_STATUS_STANDARD || formData.owner === null ? true : false}
                                name="owner_brand"
                                onChange={handleChange}>
                                <option value={''}>--</option>
                                {containerOwnerBrandTab.map((owner, index) => (
                                    <option
                                        key={index}
                                        value={owner.id}>
                                        {owner.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label className="small-title">Format :</label>
                            <select
                                name="type"
                                className={formErrors.type ? 'error' : ''}
                                onChange={handleChange}>
                                <option value="">--</option>
                                {containerTypesTab.map((type, index) => (
                                    <option
                                        key={index}
                                        value={parseInt(type)}>
                                        {_defineContainerType(type)}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label className="small-title">Sous format :</label>
                            <select
                                disabled={containerSubTypeTab.length === 0 ? true : false}
                                className={formErrors.subtype ? 'error' : ''}
                                name="subtype"
                                onChange={handleChange}>
                                <option value={''}>--</option>
                                {containerSubTypeTab.map((type, index) => (
                                    <option
                                        key={index}
                                        value={type}>
                                        {_defineContainerSubType(type)}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label className="small-title">Matériaux :</label>
                            <select
                                className={formErrors.material ? 'error' : ''}
                                name="material"
                                onChange={handleChange}>
                                <option value={''}>--</option>
                                {containerMaterialTab.map((material, index) => (
                                    <option
                                        key={index}
                                        value={parseInt(material)}>
                                        {_defineContainerMaterial(material)}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </section>
                    <section>
                        <div>
                            <label className="small-title">Hauteur hors couvercle (mm):</label>
                            <input
                                className={formErrors.dim_height_without_cover ? 'error' : ''}
                                type="number"
                                name="dim_height_without_cover"
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <label className="small-title">Hauteur avec couvercle (mm):</label>
                            <input
                                className={formErrors.dim_height_with_cover ? 'error' : ''}
                                type="number"
                                name="dim_height_with_cover"
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <label className="small-title">Largeur (mm) :</label>
                            <input
                                className={formErrors.dim_width ? 'error' : ''}
                                type="number"
                                name="dim_width"
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <label className="small-title">Longueur (mm) :</label>
                            <input
                                className={formErrors.dim_length ? 'error' : ''}
                                type="text"
                                name="dim_length"
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <label className="small-title">Diamètre base (mm) :</label>
                            <input
                                className={formErrors.dim_base_diameter ? 'error' : ''}
                                type="number"
                                name="dim_base_diameter"
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <label className="small-title">Diamètre ouverture (mm):</label>
                            <input
                                className={formErrors.dim_opening_diameter ? 'error' : ''}
                                type="number"
                                name="dim_opening_diameter"
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </section>
                    <section>
                        <div>
                            <label className="small-title">Unité:</label>
                            <select
                                className={formErrors.unit ? 'error' : ''}
                                name="unit"
                                onChange={handleChange}>
                                <option value={''}>--</option>
                                {containerUnitTab.map((unit, index) => (
                                    <option
                                        key={index}
                                        value={unit}>
                                        {_defineContainerUnit(unit)}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label className="small-title">Valeur Unité:</label>
                            <input
                                className={formErrors.unitValue ? 'error' : ''}
                                type="number"
                                name="unitValue"
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <label className="small-title">Poids (gr):</label>
                            <input
                                className={formErrors.weight ? 'error' : ''}
                                type="number"
                                name="weight"
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div>
                            <label className="small-title">Couleur:</label>
                            <input
                                className={formErrors.color ? 'error' : ''}
                                type="text"
                                name="color"
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </section>

                    <section>
                        <div>
                            <label className="small-title">Type de palette:</label>
                            <select
                                className={formErrors.palet_type ? 'error' : ''}
                                name="palet_type"
                                onChange={handleChange}>
                                <option value={''}>--</option>
                                {containerPaletTypeTab.map((type, index) => (
                                    <option
                                        key={index}
                                        value={type}>
                                        {_defineContainerPaletType(type)}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label className="small-title">Nb contenants / palette :</label>
                            <input
                                className={formErrors.palet_nbcontainer ? 'error' : ''}
                                type="number"
                                name="palet_nbcontainer"
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <label className="small-title">URL specifications techniques :</label>
                            <input
                                className={formErrors.url ? 'error' : ''}
                                type="url"
                                name="url"
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <label className="small-title">Montant consigne :</label>
                            <input
                                className={formErrors.price ? 'error' : ''}
                                type="number"
                                name="price"
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </section>
                </div>
                <p className="error-form">{errorEmpty && errorEmpty}</p>

                {errorMessage.length
                    ? errorMessage.map((item, index) => {
                          return (
                              <div
                                  className="error-form"
                                  key={index}>
                                  {item}
                              </div>
                          );
                      })
                    : null}
                <div className="button-confirm">
                    <button
                        className="button-actif"
                        onClick={handleSubmit}>
                        Ajouter le contenant
                    </button>
                </div>
            </section>
        </main>
    );
}
