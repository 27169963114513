import { getGearAPIApiEndpoint } from './endpoints';
import * as Sentry from '@sentry/react';
import { defaultHeaders } from './headers';

const GEAR_API_BASE_URL = getGearAPIApiEndpoint();

const BOCOLOCO_GET_FINANCIAL_STORECASHOUT_ALLBYDATES = '/financial/cashout/store/bydates';
const BOCOLOCO_GET_FINANCIAL_STORECASHOUT_GETCSV = '/financial/cashout/store/getcsv';
const BOCOLOCO_GET_FINANCIAL_STORECASHOUT_GETPDF = '/financial/cashout/store/getpdf';
const BOCOLOCO_GET_FINANCIAL_COLLECTORCASHOUT_ALLBYDATES = '/financial/cashout/collector/bydates';
const BOCOLOCO_GET_FINANCIAL_STORECASHOUT_BYSTOREANDDATES = '/financial/cashout/bystoreowner/bydates';

export function getAllFinancialStoreCashOutByDates(startDate, endDate) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_FINANCIAL_STORECASHOUT_ALLBYDATES;
    var url_args = '?startDate=' + startDate + '&endDate=' + endDate;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function downloadReportingStoreCashOutCsv(key) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_FINANCIAL_STORECASHOUT_GETCSV;
    var url_args = '?key=' + key;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.blob();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .then((blob) => {
            if (blob) {
                const downloadUrl = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = downloadUrl;
                a.download = key;
                document.body.appendChild(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(downloadUrl);
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function downloadReportingStoreCashOutPdf(key) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_FINANCIAL_STORECASHOUT_GETPDF;
    const url_args = '?key=' + key;
    const url = encodeURI(url_base + url_args);

    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.blob();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .then((blob) => {
            if (blob) {
                const downloadUrl = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = downloadUrl;
                a.download = key;
                document.body.appendChild(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(downloadUrl);
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function getAllFinancialCollectorCashOutByDates(startDate, endDate) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_FINANCIAL_COLLECTORCASHOUT_ALLBYDATES;
    var url_args = '?startDate=' + startDate + '&endDate=' + endDate;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function fetchCsvText(key) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_FINANCIAL_STORECASHOUT_GETCSV;
    var url_args = '?key=' + key;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.text();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function getFinancialStoreCashOutByStoreAndDates(startDate, endDate, storeOwnerId) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_FINANCIAL_STORECASHOUT_BYSTOREANDDATES;
    var url_args = '?startDate=' + startDate + '&endDate=' + endDate + '&storeOwnerId=' + storeOwnerId;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}
