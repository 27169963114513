import { useEffect, useState } from 'react';
import { getSortersByDates } from '../../../../API/SorterApi';
import PieChart from '../../../../Components/PieChart';
import { CSVLink } from 'react-csv';
import { endOfDay } from 'date-fns';
import { getRegionsName } from '../../../../Utils';
import DateFilter from '../../../../Components/DateFilter';
import * as Constant from '../../../../Constants';
import { countOccurenceByKey } from '../../../../UtilsData';

export default function IndusDataReuseSorter() {
    const now = new Date();
    const [sorterActive, setsorterActive] = useState(0);
    const [sorterByRegion, setsorterByRegion] = useState([]);
    const [formattedData, setFormattedData] = useState([]);

    // FILTRES
    const [startDate, setStartDate] = useState(new Date(now.getFullYear(), 0, 1));
    const [endDate, setEndDate] = useState(now);

    useEffect(() => {
        const fetchData = async () => {
            setsorterActive(0);
            setsorterByRegion([]);
            setFormattedData([]);
            try {
                const sorterRaw = await getSortersByDates(startDate.toISOString(), endOfDay(endDate.toISOString()));
                const sorterActiveRaw = sorterRaw.filter((sorter) => sorter.status === 0);

                const countedRegions = countOccurenceByKey(sorterActiveRaw, 'region');
                const regionsArray = Object.keys(countedRegions).map((region) => ({
                    region: getRegionsName(region),
                    qty: ((countedRegions[region] / sorterActiveRaw.length) * 100).toFixed(1),
                }));

                const formattedDataRaw = sorterActiveRaw.map((item) => {
                    const sorter = {
                        nom: item.company_name,
                        region: getRegionsName(item.region),
                        zipcode: item.zipcode,
                        city: item.city,
                    };
                    return sorter;
                });

                setsorterByRegion(regionsArray);
                setsorterActive(sorterActiveRaw.length);
                setFormattedData(formattedDataRaw);
            } catch (error) {
                console.debug(error);
            }
        };
        if (startDate & endDate) fetchData();
    }, [startDate, endDate]);

    const sorterByRegionQuantities = sorterByRegion.map((item) => item.qty);
    const sorterByRegionLabels = sorterByRegion.map((item) => item.region);

    const customHeader = [
        {
            label: 'Nom',
            key: 'nom',
        },
        {
            label: 'Code postal',
            key: 'zipcode',
        },
        {
            label: 'Ville',
            key: 'city',
        },
        {
            label: 'Région',
            key: 'region',
        },
    ];

    return (
        <main>
            <div className="data-container">
                <div className="header-container">
                    <div className="header-title">
                        <h1>CENTRE DE TRI</h1>
                        <div className="date-container">
                            <DateFilter
                                startDate={startDate}
                                endDate={endDate}
                                setStartDate={setStartDate}
                                setEndDate={setEndDate}
                            />
                            <div className="extract-content">
                                <div className="extract">
                                    <CSVLink
                                        style={{ textDecoration: 'none', color: Constant.$blanc }}
                                        className="extract-button"
                                        headers={customHeader}
                                        data={formattedData ? formattedData : []}
                                        filename={`collecteurs_data.csv`}
                                        target="_blank"
                                        separator={';'}>
                                        Extraire la donnée
                                    </CSVLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', gap: '1%' }}>
                    <section
                        className="section-number"
                        style={{ width: '33%' }}>
                        <div className="title">Nombre de centres actifs</div>
                        <div className="content">
                            <div className="number">{sorterActive}</div>
                            <div className="text"></div>
                        </div>
                    </section>
                    <section style={{ width: '50%' }}></section>
                </div>

                <div style={{ display: 'flex', gap: '1%' }}>
                    <section
                        className="section-graph"
                        style={{ width: '33%' }}>
                        <div className="title">Répartition des centres de tri par région (%) :</div>
                        <div style={{ height: '13rem' }}>
                            {sorterByRegion.length > 0 ? (
                                <PieChart
                                    title={sorterByRegionLabels}
                                    quantities={sorterByRegionQuantities}
                                />
                            ) : (
                                <div className="no-data-graph">Aucune donnée.</div>
                            )}
                        </div>
                    </section>
                </div>
            </div>
        </main>
    );
}
