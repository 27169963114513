export const getUserInfoCheck = (data) => {
    const name = 'getUserInfo : ';
    for (let i = 0; i < data.length; i++) {
        if (!data[i].entity_id) {
            return name + ' No entity id found';
        } else {
            return true;
        }
    }
};
