import { useState, useEffect, useRef } from 'react';
import { getIndustrialsByName, getIndustrials } from '../../../../API/IndustrialApi';
import { _defineStatus, useClickOutside } from '../../../../Utils';
import { Link } from 'react-router-dom';
import * as Constant from '../../../../Constants';
import SearchComponent from '../../../../Components/searchComponent';
export default function IndustrialNeedList() {
    const [filterSelected, setFilterSelected] = useState('asc');

    const [AllIndustrialList, setAllIndustrialList] = useState([]);
    const [industrialsNames, setIndustrialNames] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [showOptions, setShowOptions] = useState(false);

    const dropdownRef = useRef(null);

    const handleClickOutside = () => {
        setShowOptions(false);
    };

    useClickOutside(dropdownRef, handleClickOutside);

    useEffect(() => {
        const fetchAllIndistrials = async () => {
            try {
                const result = await getIndustrials();
                setAllIndustrialList(result);
            } catch (error) {
                console.error('Erreur lors de la récupération des industrials', error);
            }
        };
        fetchAllIndistrials();
    }, []);

    useEffect(() => {
        const fetchIndustrialNames = async () => {
            try {
                if (searchTerm.length > 2) {
                    const result = await getIndustrialsByName(searchTerm);
                    setAllIndustrialList(result);
                    setIndustrialNames(result);
                }
            } catch (error) {
                console.error('Erreur lors de la récupération des industrials', error);
            }
        };

        fetchIndustrialNames();
    }, [searchTerm]);

    const handleInputChange = (e) => {
        setSearchTerm(e.target.value.toLowerCase());
        setShowOptions(true);
    };

    const handleSelectOption = (option) => {
        setSearchTerm(option.name);
        setShowOptions(false);
        console.log(option);
    };

    // Fonction pour trier les données par ordre alphabétique ascendant
    const sortDataAsc = () => {
        const sortedData = [...AllIndustrialList].sort((a, b) => a.name.localeCompare(b.name));
        setAllIndustrialList(sortedData);
        setFilterSelected('asc');
    };

    // Fonction pour trier les données par ordre alphabétique descendant
    const sortDataDesc = () => {
        const sortedData = [...AllIndustrialList].sort((a, b) => b.name.localeCompare(a.name));
        setAllIndustrialList(sortedData);
        setFilterSelected('desc');
    };

    return (
        <main>
            <section
                className="small-section"
                style={{ width: '60%' }}>
                <h2>Liste marques</h2>
                <div className="top">
                    <SearchComponent
                        dropdownRef={dropdownRef}
                        showOptions={showOptions}
                        searchTerm={searchTerm}
                        handleInputChange={handleInputChange}
                        productsNames={industrialsNames}
                        handleSelectOption={handleSelectOption}
                        placeholder="Rechercher une marque"
                        searchKey="name"
                    />

                    <div className="filter">
                        <p className="sort">Trier par</p>
                        <div>
                            <p
                                className={filterSelected === 'asc' ? 'selected' : 'classic'}
                                onClick={sortDataAsc}>
                                A &gt; Z
                            </p>
                            <p
                                className={filterSelected === 'desc' ? 'selected' : 'classic'}
                                onClick={sortDataDesc}>
                                Z &gt; A
                            </p>
                        </div>
                    </div>
                </div>
                <div className="tab-item">
                    <div
                        className="pre-filled-fields-1-several-item"
                        style={{ fontWeight: '600', width: '100%' }}>
                        <p>Marque</p>
                        <p>Ville</p>
                        <p>Code postal</p>
                        <p>Statut</p>
                    </div>
                    {AllIndustrialList.length ? (
                        AllIndustrialList.map((item, index) => {
                            return (
                                <Link
                                    to={'/servicesupplier/operation/indusneeds/sheet'}
                                    state={{ id: item.id }}
                                    key={index}
                                    className="pre-filled-fields-2-several-item">
                                    <p>{item.name}</p>
                                    <p>{item.city}</p>
                                    <p>{item.zipcode}</p>
                                    <p
                                        style={{
                                            height: '100%',
                                            backgroundColor: _defineStatus(item.status).color,
                                            borderTopRightRadius: '0.6rem',
                                            borderBottomRightRadius: '0.6rem',
                                            color: Constant.$blanc,
                                        }}>
                                        {_defineStatus(item.status).name}
                                    </p>
                                </Link>
                            );
                        })
                    ) : (
                        <div className="no-data-graph">Aucune marque enregistrée.</div>
                    )}
                </div>
            </section>
        </main>
    );
}
