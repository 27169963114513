import Cookies from 'js-cookie';
import * as Constants from '../../Constants';

export const washerMenuOperation = () => {
    return ['washer'];
};

const getSubAccessOperationWasher = () => {
    let sousMenu = [{ title: 'Lavage' }];
    const access = JSON.parse(Cookies.get('access'));
    let access_operation = access.washer.operation;
    if (access_operation.lavage.reception === 1) {
        sousMenu.push({ name: 'Réceptions', link: '/washer/operation/reception' });
    }
    if (access_operation.lavage.declaration === 1) {
        sousMenu.push({
            sousPartie: 'Déclarations',
            sousMenu: [
                { name: 'Déclarer', link: '/washer/operation/declaration' },
                { name: 'Historique', link: '/washer/operation/history' },
            ],
        });
    }

    if (access_operation.lavage.bons_de_commande === 1) {
        sousMenu.push({ name: 'Bons de commande', link: '/washer/operation/order' });
    }
    return sousMenu;
};

export const getSousMenuWasherOperation = (onglet) => {
    switch (onglet) {
        case 'washer':
            return getSubAccessOperationWasher();
        default:
            return [];
    }
};

export const washerMenuData = () => {
    return [];
};

export const getSousMenuWasherData = (onglet) => {
    switch (onglet) {
        default:
            return [];
    }
};

export const washerMenuFacturation = () => {
    return [];
};

export const getSousMenuWasherFacturation = (onglet) => {
    switch (onglet) {
        default:
            return [];
    }
};

export const washerMenuAccount = () => {
    return ['my_account'];
};

export const getSousMenuWasherAccount = (onglet) => {
    const role = Cookies.get('role');
    const isAdmin = role === Constants.ENTITY_ROLE_ADMIN;
    switch (onglet) {
        case 'my_account':
            if (isAdmin) {
                return [
                    { title: 'Mon compte' },
                    { name: 'Mes informations personnelles', link: '/washer/myprofil/personal_info' },
                    { name: 'Ma société', link: '/washer/myprofil/company_info' },
                    { name: 'Gestion des comptes', link: '/washer/myprofil/rolemanagement' },
                ];
            } else {
                return [
                    { title: 'Mon compte' },
                    { name: 'Mes informations personnelles', link: '/washer/myprofil/personal_info' },
                    { name: 'Ma société', link: '/washer/myprofil/company_info' },
                ];
            }
        default:
            return [];
    }
};
