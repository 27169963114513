import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { endOfDay, format } from 'date-fns';
import { getReturnByDates } from '../../../../API/CollectorReturnApi';
import { getReturnByDatesCheck } from '../../../../API/CheckApi/CheckCollectorReturnApi';
import { getAllSalesDetailsByDates } from '../../../../API/SalesApi';
import { getAllSalesDetailsByDatesCheck } from '../../../../API/CheckApi/CheckSalesApi';
import * as Constants from '../../../../Constants';
import { Jwt } from '../../../../Jwt';
import DateFilter from '../../../../Components/DateFilter';
import { _defineCollectorType, _defineStoreType, getRegionsName, _defineContainerType } from '../../../../Utils';
import {
    reduceDataByKeyAndQty,
    reduceByMonthAndKey,
    addMonthToTab,
    countOccurenceByKey,
    getQtyByDayOfWeek,
    getQtyByHourOfDay,
    mergeArrays,
} from '../../../../UtilsData.js';
import MultiLineChart from '../../../../Components/MultiLineChart';
import PieChart from '../../../../Components/PieChart';
import BarChart from '../../../../Components/BarChart';
import LineChart from '../../../../Components/LineChart';
import { CSVLink } from 'react-csv';
import * as Constant from '../../../../Constants';

export default function DistribDataReuseReturns() {
    const navigate = useNavigate();
    const now = new Date();
    const userContext = new Jwt();

    const [originalReturns, setOriginalReturns] = useState([]);
    const [allReturns, setAllReturns] = useState([]);
    const [tauxStandardReturned, setTauxStandardReturned] = useState(0);
    const [storeTypeSelected, setStoreTypeSelected] = useState('');
    const [collectorTypeSelected, setCollectorTypeSelected] = useState('');
    const [regionSelected, setRegionSelected] = useState('');
    const [returnsByRegion, setReturnsByRegion] = useState([]);
    const [returnsByRegionPie, setReturnsByRegionPie] = useState([]);
    const [returnsByStoreType, setReturnsByStoreType] = useState([]);
    const [returnsByStoreTypePie, setReturnsByStoreTypePie] = useState([]);
    const [returnsByCollectorType, setReturnsByCollectorType] = useState([]);
    const [returnsByCollectorTypePie, setReturnsByCollectorTypePie] = useState([]);
    const [returnsBySku, setReturnsBySku] = useState([]);
    const [containersByReturn, setContainersByReturn] = useState([]);
    const [returnsByWeekDay, setReturnsByWeekDay] = useState([]);
    const [returnsByHour, setReturnsByHour] = useState([]);
    const [formattedTabGlobalData, setFormattedTabGlobalData] = useState([]);
    const [storeSelected, setStoreSelected] = useState('');
    const [originalMyReturns, setOriginalMyReturns] = useState([]);
    const [myReturns, setMyReturns] = useState([]);
    const [tauxStandardReturnedInMyCollectors, setTauxStandardReturnedInMyCollectors] = useState(0);
    const [myReturnsByRegion, setMyReturnsByRegion] = useState([]);
    const [myReturnsByRegionPie, setMyReturnsByRegionPie] = useState([]);
    const [myRatioRegion, setMyRatioRegion] = useState([]);
    const [myReturnsByStoreType, setMyReturnsByStoreType] = useState([]);
    const [myReturnsByStoreTypePie, setMyReturnsByStoreTypePie] = useState([]);
    const [myRatioStoreType, setMyRatioStoreType] = useState([]);
    const [myReturnsByCollectorType, setMyReturnsByCollectorType] = useState([]);
    const [myReturnsByCollectorTypePie, setMyReturnsByCollectorTypePie] = useState([]);
    const [myRatioCollectorType, setMyRatioCollectorType] = useState([]);
    const [myReturnsBySku, setMyReturnsBySku] = useState([]);
    const [containersByMyReturn, setContainersByMyReturn] = useState([]);
    const [myReturnsByWeekDay, setMyReturnsByWeekDay] = useState([]);
    const [myReturnsByHour, setMyReturnsByHour] = useState([]);
    const [formattedMyRefsData, setFormattedMyRefsData] = useState([]);

    // FILTRE
    const [startDate, setStartDate] = useState(new Date(now.getFullYear(), 0, 1));
    const [endDate, setEndDate] = useState(now);

    useEffect(() => {
        const fetchData = async () => {
            //  --------- GLOBAL MARKET ---------

            // CONTENANTS EN CIRCULATION
            const allSalesDetails = await getAllSalesDetailsByDates(startDate.toISOString(), endOfDay(endDate.toISOString()));
            const allSalesDetailsCheck = getAllSalesDetailsByDatesCheck(allSalesDetails);
            if (allSalesDetails.length && allSalesDetailsCheck !== true) {
                console.error(allSalesDetailsCheck);
                Sentry.captureException(allSalesDetailsCheck);
                navigate('/error');
                return;
            }

            const standardCirculationContainer = allSalesDetails.filter(
                (item) => item.entityIndustrialProduct.container.owner === Constants.CONTAINER_OWNER_STATUS_STANDARD,
            );
            const totalQtyStandard = standardCirculationContainer.reduce((acc, item) => acc + item.qty, 0);
            // COLLECTORS RETURNS
            const allReturnsRaw = await getReturnByDates(startDate.toISOString(), endOfDay(endDate.toISOString()));
            const allCollectorReturnCheck = getReturnByDatesCheck(allReturnsRaw);
            if (allReturnsRaw.length && allCollectorReturnCheck !== true) {
                console.error(allCollectorReturnCheck);
                Sentry.captureException(allCollectorReturnCheck);
                navigate('/error');
                return;
            }
            for (let i = 0; i < allReturnsRaw.length; i++) {
                if (!allReturnsRaw[i].entityIndustrialProduct) {
                    allReturnsRaw[i].sku = 'nd';
                } else if (allReturnsRaw[i].entityIndustrialProduct.container.owner === Constants.CONTAINER_OWNER_STATUS_BRAND) {
                    allReturnsRaw[i].sku = 'Autres';
                } else {
                    allReturnsRaw[i].sku = allReturnsRaw[i].entityIndustrialProduct.sku;
                }
            }
            setOriginalReturns(allReturnsRaw);

            let allReturnsRawFiltered = allReturnsRaw;
            if (storeTypeSelected) {
                allReturnsRawFiltered = allReturnsRawFiltered.filter((container) => container.collector.store.type === parseInt(storeTypeSelected));
            }
            if (collectorTypeSelected) {
                allReturnsRawFiltered = allReturnsRawFiltered.filter((container) => container.collector.type === parseInt(collectorTypeSelected));
            }
            if (regionSelected) {
                allReturnsRawFiltered = allReturnsRawFiltered.filter((container) => container.collector.store.region === regionSelected);
            }
            setAllReturns(allReturnsRawFiltered);

            const standardReturnedContainer = allReturnsRawFiltered.filter(
                (item) => item.entityIndustrialProduct && item.entityIndustrialProduct.container.owner === Constants.CONTAINER_OWNER_STATUS_STANDARD,
            );
            const tauxStandardReturnedRaw = ((standardReturnedContainer.length / totalQtyStandard) * 100).toFixed(1);
            setTauxStandardReturned(tauxStandardReturnedRaw);

            // EVOLUTION RETURNS BY REGIONS
            const countedReturnsByRegions = reduceByMonthAndKey(allReturnsRawFiltered, 'returnedAt', 'collector.store.region', 'qty', 'region');
            const returnsByRegionsRaw = addMonthToTab(
                countedReturnsByRegions,
                startDate.toISOString(),
                endOfDay(endDate.toISOString()),
                'region',
                'month',
                'quantity',
            );

            setReturnsByRegion(returnsByRegionsRaw);

            const countedReturnsByRegionsPie = countOccurenceByKey(allReturnsRawFiltered, 'collector.store.region');
            const returnsByRegionsPieRaw = Object.keys(countedReturnsByRegionsPie).map((region) => ({
                region: getRegionsName(region),
                qty: ((countedReturnsByRegionsPie[region] / allReturnsRawFiltered.length) * 100).toFixed(1),
                qtyGlobal: countedReturnsByRegionsPie[region],
            }));
            setReturnsByRegionPie(returnsByRegionsPieRaw);

            // EVOLUTION RETURNS BY STORE TYPE
            const countedReturnsByStoreType = reduceByMonthAndKey(allReturnsRawFiltered, 'returnedAt', 'collector.store.type', 'qty', 'storeType');
            const returnsByStoreTypeRaw = addMonthToTab(
                countedReturnsByStoreType,
                startDate.toISOString(),
                endOfDay(endDate.toISOString()),
                'storeType',
                'month',
                'quantity',
            );

            setReturnsByStoreType(returnsByStoreTypeRaw);

            const countedReturnsByStoreTypePie = countOccurenceByKey(allReturnsRawFiltered, 'collector.store.type');
            const returnsByStoreTypePieRaw = Object.keys(countedReturnsByStoreTypePie).map((type) => ({
                storeType: _defineStoreType(type),
                qty: ((countedReturnsByStoreTypePie[type] / allReturnsRawFiltered.length) * 100).toFixed(1),
                qtyGlobal: countedReturnsByStoreTypePie[type],
            }));
            setReturnsByStoreTypePie(returnsByStoreTypePieRaw);

            // EVOLUTION RETURNS BY COLLECTOR TYPE
            const countedReturnsByCollectorType = reduceByMonthAndKey(allReturnsRawFiltered, 'returnedAt', 'collector.type', 'qty', 'collectorType');
            const returnsByCollectorTypeRaw = addMonthToTab(
                countedReturnsByCollectorType,
                startDate.toISOString(),
                endOfDay(endDate.toISOString()),
                'collectorType',
                'month',
                'quantity',
            );
            setReturnsByCollectorType(returnsByCollectorTypeRaw);

            const countedReturnsByCollectorTypePie = countOccurenceByKey(allReturnsRawFiltered, 'collector.type');
            const returnsByCollectorTypePieRaw = Object.keys(countedReturnsByCollectorTypePie).map((type) => ({
                collectorType: _defineCollectorType(type),
                qty: ((countedReturnsByCollectorTypePie[type] / allReturnsRawFiltered.length) * 100).toFixed(1),
                qtyGlobal: countedReturnsByCollectorTypePie[type],
            }));
            setReturnsByCollectorTypePie(returnsByCollectorTypePieRaw);

            // EVOLUTION RETURNS BY SKU

            const countedReturnsBySku = reduceByMonthAndKey(allReturnsRawFiltered, 'returnedAt', 'sku', 'qty', 'sku');
            const returnsBySkuRaw = addMonthToTab(countedReturnsBySku, startDate.toISOString(), endOfDay(endDate.toISOString()), 'sku', 'month', 'quantity');
            setReturnsBySku(returnsBySkuRaw);

            // NB MOYEN DE CONTENANTS PAR RETOUR
            const countedAverageContainerByReturn = reduceDataByKeyAndQty(allReturnsRawFiltered, 'returnedAt');
            const averageContainerByReturnRaw = Object.keys(countedAverageContainerByReturn).map((item) => ({
                date: item,
                qty: countedAverageContainerByReturn[item],
            }));
            // NB DE CONTENANTS PAR RETOUR
            const countedContainerByReturnRaw = averageContainerByReturnRaw.reduce((acc, obj) => {
                const qty = obj.qty;
                const count = (acc[qty] || 0) + 1;

                return {
                    ...acc,
                    [qty]: count,
                };
            }, {});
            const containerByReturnRaw = Object.keys(countedContainerByReturnRaw).map((item) => ({
                nbByReturn: item + ' contenant(s)',
                qty: ((countedContainerByReturnRaw[item] / averageContainerByReturnRaw.length) * 100).toFixed(1),
            }));
            setContainersByReturn(containerByReturnRaw);

            // NB RETOUR BY DAY OF WEEK
            const countedByWeekDays = getQtyByDayOfWeek(allReturnsRawFiltered);
            const returnsByWeekDayRaw = Object.keys(countedByWeekDays).map((item) => ({
                day: item,
                qty: countedByWeekDays[item],
            }));
            setReturnsByWeekDay(returnsByWeekDayRaw);

            // NB RETOUR OF MY REFERENCES BY HOUR OF DAY
            const countedByHourOfDay = getQtyByHourOfDay(allReturnsRawFiltered);
            const returnsByHoursRaw = Object.keys(countedByHourOfDay).map((item) => ({
                hour: item + 'h',
                qty: countedByHourOfDay[item],
            }));
            setReturnsByHour(returnsByHoursRaw);

            // EXTRACT GLOBAL MARCHE
            const formattedTabGlobalDataRaw = allReturnsRawFiltered.map((item) => {
                return {
                    date: format(item.returnedAt, 'dd/MM/yyyy HH:mm:ss'),
                    year: new Date(item.returnedAt).getFullYear(),
                    month: new Date(item.returnedAt).toLocaleString('default', { month: 'long' }),
                    region: getRegionsName(item.collector.store.region),
                    storeType: _defineStoreType(item.collector.store.type),
                    sku: item.sku,
                    skuType: item.entityIndustrialProduct ? _defineContainerType(item.entityIndustrialProduct.container.type) : 'nd',
                    collectorType: _defineCollectorType(item.collector.type),
                };
            });
            setFormattedTabGlobalData(formattedTabGlobalDataRaw);

            // MY REFS
            const myReturnsRaw = allReturnsRaw.filter((container) => container.collector.store.distributor_id === userContext.entityId);
            setOriginalMyReturns(myReturnsRaw);

            let myReturnsFiltered = [];
            if (storeSelected) {
                myReturnsFiltered = myReturnsRaw.filter((container) => container.collector.store.name === storeSelected);
            } else {
                myReturnsFiltered = myReturnsRaw;
            }
            setMyReturns(myReturnsFiltered);
            const standardReturnedContainerInMyCollectors = myReturnsFiltered.filter(
                (item) => item.entityIndustrialProduct && item.entityIndustrialProduct.container.owner === Constants.CONTAINER_OWNER_STATUS_STANDARD,
            );
            const tauxStandardReturnedInMyCollectorsRaw = ((standardReturnedContainerInMyCollectors.length / totalQtyStandard) * 100).toFixed(1);
            setTauxStandardReturnedInMyCollectors(tauxStandardReturnedInMyCollectorsRaw);

            // EVOLUTION RETURNS BY REGIONS
            const countedMyReturnsByRegions = reduceByMonthAndKey(myReturnsFiltered, 'returnedAt', 'collector.store.region', 'qty', 'region');
            const myReturnsByRegionsRaw = addMonthToTab(
                countedMyReturnsByRegions,
                startDate.toISOString(),
                endOfDay(endDate.toISOString()),
                'region',
                'month',
                'quantity',
            );

            setMyReturnsByRegion(myReturnsByRegionsRaw);

            const countedMyReturnsByRegionsPie = countOccurenceByKey(myReturnsFiltered, 'collector.store.region');
            const myReturnsByRegionsPieRaw = Object.keys(countedMyReturnsByRegionsPie).map((region) => ({
                region: getRegionsName(region),
                qty: ((countedMyReturnsByRegionsPie[region] / myReturnsFiltered.length) * 100).toFixed(1),
                qtyMyRef: countedMyReturnsByRegionsPie[region],
            }));
            setMyReturnsByRegionPie(myReturnsByRegionsPieRaw);

            const myRatioRegionRaw = mergeArrays(returnsByRegionsPieRaw, myReturnsByRegionsPieRaw, 'region');

            setMyRatioRegion(myRatioRegionRaw);

            // EVOLUTION RETURNS BY STORE TYPE
            const countedMyReturnsByStoreType = reduceByMonthAndKey(myReturnsFiltered, 'returnedAt', 'collector.store.type', 'qty', 'storeType');
            const myReturnsByStoreTypeRaw = addMonthToTab(
                countedMyReturnsByStoreType,
                startDate.toISOString(),
                endOfDay(endDate.toISOString()),
                'storeType',
                'month',
                'quantity',
            );
            setMyReturnsByStoreType(myReturnsByStoreTypeRaw);

            const countedMyReturnsByStoreTypePie = countOccurenceByKey(myReturnsFiltered, 'collector.store.type');
            const myReturnsByStoreTypePieRaw = Object.keys(countedMyReturnsByStoreTypePie).map((type) => ({
                storeType: _defineStoreType(type),
                qty: ((countedMyReturnsByStoreTypePie[type] / myReturnsFiltered.length) * 100).toFixed(1),
                qtyMyRef: countedMyReturnsByStoreTypePie[type],
            }));
            setMyReturnsByStoreTypePie(myReturnsByStoreTypePieRaw);

            const myRatioStoreTypeRaw = mergeArrays(returnsByStoreTypePieRaw, myReturnsByStoreTypePieRaw, 'storeType');
            setMyRatioStoreType(myRatioStoreTypeRaw);

            // EVOLUTION RETURNS BY COLLECTOR TYPE
            const countedMyReturnsByCollectorType = reduceByMonthAndKey(myReturnsFiltered, 'returnedAt', 'collector.type', 'qty', 'collectorType');
            const myReturnsByCollectorTypeRaw = addMonthToTab(
                countedMyReturnsByCollectorType,
                startDate.toISOString(),
                endOfDay(endDate.toISOString()),
                'collectorType',
                'month',
                'quantity',
            );
            setMyReturnsByCollectorType(myReturnsByCollectorTypeRaw);

            const countedMyReturnsByCollectorTypePie = countOccurenceByKey(myReturnsFiltered, 'collector.type');
            const myReturnsByCollectorTypePieRaw = Object.keys(countedMyReturnsByCollectorTypePie).map((type) => ({
                collectorType: _defineCollectorType(type),
                qty: ((countedMyReturnsByCollectorTypePie[type] / myReturnsFiltered.length) * 100).toFixed(1),
                qtyMyRef: countedMyReturnsByCollectorTypePie[type],
            }));
            setMyReturnsByCollectorTypePie(myReturnsByCollectorTypePieRaw);

            const myRatioCollectorTypeRaw = mergeArrays(returnsByCollectorTypePieRaw, myReturnsByCollectorTypePieRaw, 'collectorType');
            setMyRatioCollectorType(myRatioCollectorTypeRaw);

            // EVOLUTION RETURNS BY SKU

            const countedMyReturnsBySku = reduceByMonthAndKey(myReturnsFiltered, 'returnedAt', 'sku', 'qty', 'sku');
            const myReturnsBySkuRaw = addMonthToTab(
                countedMyReturnsBySku,
                startDate.toISOString(),
                endOfDay(endDate.toISOString()),
                'sku',
                'month',
                'quantity',
            );
            setMyReturnsBySku(myReturnsBySkuRaw);

            // NB MOYEN DE CONTENANTS PAR RETOUR
            const countedAverageContainerByMyReturn = reduceDataByKeyAndQty(myReturnsFiltered, 'returnedAt');
            const averageContainerByMyReturnRaw = Object.keys(countedAverageContainerByMyReturn).map((item) => ({
                date: item,
                qty: countedAverageContainerByMyReturn[item],
            }));
            // NB DE CONTENANTS PAR RETOUR
            const countedContainerByMyReturnRaw = averageContainerByMyReturnRaw.reduce((acc, obj) => {
                const qty = obj.qty;
                const count = (acc[qty] || 0) + 1;

                return {
                    ...acc,
                    [qty]: count,
                };
            }, {});
            const containerByMyReturnRaw = Object.keys(countedContainerByMyReturnRaw).map((item) => ({
                nbByReturn: item + ' contenant(s)',
                qty: ((countedContainerByMyReturnRaw[item] / averageContainerByMyReturnRaw.length) * 100).toFixed(1),
            }));
            setContainersByMyReturn(containerByMyReturnRaw);

            // NB RETOUR BY DAY OF WEEK
            const countedByWeekDaysMyReturns = getQtyByDayOfWeek(myReturnsFiltered);
            const myReturnsByWeekDayRaw = Object.keys(countedByWeekDaysMyReturns).map((item) => ({
                day: item,
                qty: countedByWeekDaysMyReturns[item],
            }));
            setMyReturnsByWeekDay(myReturnsByWeekDayRaw);

            // NB RETOUR OF MY REFERENCES BY HOUR OF DAY
            const countedByHourOfDayMyReturns = getQtyByHourOfDay(myReturnsFiltered);
            const myReturnsByHoursRaw = Object.keys(countedByHourOfDayMyReturns).map((item) => ({
                hour: item + 'h',
                qty: countedByHourOfDayMyReturns[item],
            }));
            setMyReturnsByHour(myReturnsByHoursRaw);

            // EXTRACT MY REFS
            const formattedTabMyRefsRaw = myReturnsFiltered.map((item) => {
                return {
                    date: format(item.returnedAt, 'dd/MM/yyyy HH:mm:ss'),
                    year: new Date(item.returnedAt).getFullYear(),
                    month: new Date(item.returnedAt).toLocaleString('default', { month: 'long' }),
                    region: getRegionsName(item.collector.store.region),
                    storeType: _defineStoreType(item.collector.store.type),
                    sku: item.sku,
                    skuType: item.entityIndustrialProduct ? _defineContainerType(item.entityIndustrialProduct.container.type) : 'nd',
                    collectorType: _defineCollectorType(item.collector.type),
                };
            });
            setFormattedMyRefsData(formattedTabMyRefsRaw);
        };
        if (startDate & endDate) fetchData();
    }, [startDate, endDate, navigate, userContext.entityId, storeTypeSelected, collectorTypeSelected, regionSelected, storeSelected]);

    const resetGlobalFilter = () => {
        setStoreTypeSelected('');
        setCollectorTypeSelected('');
        setRegionSelected('');
    };

    const storeTypes = [...new Set(originalReturns.map((fd) => fd.collector.store.type))];
    const collectorTypes = [...new Set(originalReturns.map((fd) => fd.collector.type))];
    const regions = [...new Set(originalReturns.map((fd) => fd.collector.store.region))];

    const evolutionReturnsByRegion = [...new Set(returnsByRegion.map((fd) => fd.region))];
    const evolutionReturnsByRegionQuantities = evolutionReturnsByRegion.map((region) => {
        const data = returnsByRegion.filter((fd) => fd.region === region).map((fd) => fd.quantity);

        return {
            label: getRegionsName(region),
            data,
        };
    });

    const returnsByRegionsPieLabels = returnsByRegionPie.map((fd) => fd.region);
    const returnsByRegionsPieQuantities = returnsByRegionPie.map((fd) => fd.qty);

    const evolutionReturnsByStoreType = [...new Set(returnsByStoreType.map((fd) => fd.storeType))];
    const evolutionReturnsByStoreTypeQuantities = evolutionReturnsByStoreType.map((storeType) => {
        const data = returnsByStoreType.filter((fd) => fd.storeType === storeType).map((fd) => fd.quantity);

        return {
            label: _defineStoreType(storeType),
            data,
        };
    });

    const returnsByStoreTypePieLabels = returnsByStoreTypePie.map((fd) => fd.storeType);
    const returnsByStoreTypePieQuantities = returnsByStoreTypePie.map((fd) => fd.qty);

    const evolutionReturnsByCollectorType = [...new Set(returnsByCollectorType.map((fd) => fd.collectorType))];
    const evolutionReturnsByCollectorTypeQuantities = evolutionReturnsByCollectorType.map((collectorType) => {
        const data = returnsByCollectorType.filter((fd) => fd.collectorType === collectorType).map((fd) => fd.quantity);

        return {
            label: _defineCollectorType(collectorType),
            data,
        };
    });

    const returnsByCollectorTypePieLabels = returnsByCollectorTypePie.map((fd) => fd.collectorType);
    const returnsByCollectorTypePieQuantities = returnsByCollectorTypePie.map((fd) => fd.qty);

    const evolutionReturnsBySku = [...new Set(returnsBySku.map((fd) => fd.sku))];
    const evolutionReturnsBySkuQuantities = evolutionReturnsBySku.map((sku) => {
        const data = returnsBySku.filter((fd) => fd.sku === sku).map((fd) => fd.quantity);

        return {
            label: sku,
            data,
        };
    });

    const containersByReturnQuantities = containersByReturn.map((item) => item.qty);
    const containersByReturnLabels = containersByReturn.map((item) => item.nbByReturn);

    const returnsByWeekDayQuantities = returnsByWeekDay.map((item) => item.qty);
    const returnsByWeekDayLabels = returnsByWeekDay.map((item) => item.day);

    const returnsByHourQuantities = returnsByHour.map((item) => item.qty);
    const returnsByHourLabels = returnsByHour.map((item) => item.hour);

    const customHeaderGlobal = [
        {
            label: 'Date',
            key: 'date',
        },
        {
            label: 'Année',
            key: 'year',
        },
        {
            label: 'Mois',
            key: 'month',
        },
        {
            label: 'Région',
            key: 'region',
        },
        {
            label: 'Type de magasin',
            key: 'storeType',
        },
        {
            label: 'SKU',
            key: 'sku',
        },
        {
            label: 'Type de SKU',
            key: 'skuType',
        },
        {
            label: 'Type de collecteur',
            key: 'collectorType',
        },
    ];

    const resetMyRefsFilter = () => {
        setStoreSelected('');
    };

    const stores = [...new Set(originalMyReturns.map((fd) => fd.collector.store.name))];
    const evolutionMyReturnsByRegion = [...new Set(myReturnsByRegion.map((fd) => fd.region))];
    const evolutionMyReturnsByRegionQuantities = evolutionMyReturnsByRegion.map((region) => {
        const data = myReturnsByRegion.filter((fd) => fd.region === region).map((fd) => fd.quantity);

        return {
            label: getRegionsName(region),
            data,
        };
    });

    const myReturnsByRegionsPieLabels = myReturnsByRegionPie.map((fd) => fd.region);
    const myReturnsByRegionsPieQuantities = myReturnsByRegionPie.map((fd) => fd.qty);

    const myRatioRegionQantities = myRatioRegion.map((item) => item.qtyRatio);
    const myRatioRegionLabels = myRatioRegion.map((item) => item.region);

    const evolutionMyReturnsByStoreType = [...new Set(myReturnsByStoreType.map((fd) => fd.storeType))];
    const evolutionMyReturnsByStoreTypeQuantities = evolutionMyReturnsByStoreType.map((storeType) => {
        const data = myReturnsByStoreType.filter((fd) => fd.storeType === storeType).map((fd) => fd.quantity);

        return {
            label: _defineStoreType(storeType),
            data,
        };
    });

    const myReturnsByStoreTypePieLabels = myReturnsByStoreTypePie.map((fd) => fd.storeType);
    const myReturnsByStoreTypePieQuantities = myReturnsByStoreTypePie.map((fd) => fd.qty);

    const myRatioStoreTypeQantities = myRatioStoreType.map((item) => item.qtyRatio);
    const myRatioStoreTypeLabels = myRatioStoreType.map((item) => item.storeType);

    const evolutionMyReturnsByCollectorType = [...new Set(myReturnsByCollectorType.map((fd) => fd.collectorType))];
    const evolutionMyReturnsByCollectorTypeQuantities = evolutionMyReturnsByCollectorType.map((collectorType) => {
        const data = myReturnsByCollectorType.filter((fd) => fd.collectorType === collectorType).map((fd) => fd.quantity);

        return {
            label: _defineCollectorType(collectorType),
            data,
        };
    });

    const myReturnsByCollectorTypePieLabels = myReturnsByCollectorTypePie.map((fd) => fd.collectorType);
    const myReturnsByCollectorTypePieQuantities = myReturnsByCollectorTypePie.map((fd) => fd.qty);

    const myRatioCollectorTypeQantities = myRatioCollectorType.map((item) => item.qtyRatio);
    const myRatioCollectorTypeLabels = myRatioCollectorType.map((item) => item.collectorType);

    const evolutionMyReturnsBySku = [...new Set(myReturnsBySku.map((fd) => fd.sku))];
    const evolutionMyReturnsBySkuQuantities = evolutionMyReturnsBySku.map((sku) => {
        const data = myReturnsBySku.filter((fd) => fd.sku === sku).map((fd) => fd.quantity);

        return {
            label: sku,
            data,
        };
    });

    const containersByMyReturnQuantities = containersByMyReturn.map((item) => item.qty);
    const containersByMyReturnLabels = containersByMyReturn.map((item) => item.nbByReturn);

    const myReturnsByWeekDayQuantities = myReturnsByWeekDay.map((item) => item.qty);
    const myReturnsByWeekDayLabels = myReturnsByWeekDay.map((item) => item.day);

    const myReturnsByHourQuantities = myReturnsByHour.map((item) => item.qty);
    const myReturnsByHourLabels = myReturnsByHour.map((item) => item.hour);

    const customHeaderMyRefs = [
        {
            label: 'Date',
            key: 'date',
        },
        {
            label: 'Année',
            key: 'year',
        },
        {
            label: 'Mois',
            key: 'month',
        },
        {
            label: 'Région',
            key: 'region',
        },
        {
            label: 'Type de magasin',
            key: 'storeType',
        },
        {
            label: 'SKU',
            key: 'sku',
        },
        {
            label: 'Type de SKU',
            key: 'skuType',
        },
        {
            label: 'Type de collecteur',
            key: 'collectorType',
        },
    ];
    return (
        <main>
            <div className="data-container">
                <div className="header-title">
                    <h1>RETOURS</h1>
                    <div className="date-container">
                        <DateFilter
                            startDate={startDate}
                            endDate={endDate}
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                        />
                    </div>
                </div>
                <div className="header-container">
                    <div className="header-title">
                        <h1>Global marché</h1>
                    </div>
                    <div className="content-header">
                        <div className="header-container-content">
                            <select
                                onChange={(e) => setStoreTypeSelected(e.target.value)}
                                value={storeTypeSelected}>
                                <option value="">Type d&#39;enseigne</option>

                                {storeTypes.map((option, index) => (
                                    <option
                                        key={index}
                                        value={option}>
                                        {_defineStoreType(option)}
                                    </option>
                                ))}
                            </select>
                            <select
                                onChange={(e) => setCollectorTypeSelected(e.target.value)}
                                value={collectorTypeSelected}>
                                <option value="">Type de collecteur</option>

                                {collectorTypes.map((option, index) => (
                                    <option
                                        key={index}
                                        value={option}>
                                        {_defineCollectorType(option)}
                                    </option>
                                ))}
                            </select>
                            <select
                                onChange={(e) => setRegionSelected(e.target.value)}
                                value={regionSelected}>
                                <option value="">Régions</option>

                                {regions.map((option, index) => (
                                    <option
                                        key={index}
                                        value={option}>
                                        {getRegionsName(option)}
                                    </option>
                                ))}
                            </select>

                            <div
                                onClick={resetGlobalFilter}
                                className="reset">
                                <p style={{ fontWeight: 'bold' }}>X</p>
                            </div>
                        </div>
                        <div className="extract-content">
                            <div className="extract">
                                <CSVLink
                                    style={{ textDecoration: 'none', color: Constant.$blanc }}
                                    className="extract-button"
                                    headers={customHeaderGlobal}
                                    data={formattedTabGlobalData ? formattedTabGlobalData : []}
                                    filename={`returns_data.csv`}
                                    target="_blank"
                                    separator={';'}>
                                    Extraire la donnée globale
                                </CSVLink>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', gap: '1%' }}>
                    <section
                        className="section-number"
                        style={{ width: '50%' }}>
                        <div className="title">Nombre total de contenants retournés</div>
                        <div className="content">
                            <div className="number">{allReturns.length}</div>
                        </div>
                    </section>
                    <section
                        className="section-number"
                        style={{ width: '50%' }}>
                        <div className="title">Taux de retours des contenants standards</div>
                        <div className="content">
                            <div className="number">{tauxStandardReturned > 0 ? tauxStandardReturned : 0}%</div>
                        </div>
                    </section>
                </div>
                <div style={{ marginBottom: '1rem', display: 'flex', width: '100%', gap: '1%', height: '22rem' }}>
                    <section
                        className="section-graph"
                        style={{ height: '100%', width: '40%' }}>
                        <div
                            className="title"
                            style={{ height: '2rem' }}>
                            Répartition des retours par région (%) :
                        </div>
                        <div style={{ height: '90%' }}>
                            {returnsByRegionPie.length ? (
                                <PieChart
                                    title={returnsByRegionsPieLabels}
                                    quantities={returnsByRegionsPieQuantities}
                                    position={'left'}
                                />
                            ) : (
                                <div className="no-data-graph">Aucune donnée.</div>
                            )}
                        </div>
                    </section>
                    <section
                        className="section-graph"
                        style={{ height: '100%', width: '60%' }}>
                        <div
                            className="title"
                            style={{ height: '1rem' }}>
                            Evolution du nombre de contenants retournés par région :
                        </div>
                        <div style={{ height: '90%' }}>
                            {returnsByRegion ? (
                                <MultiLineChart
                                    initialData={returnsByRegion}
                                    datasets={evolutionReturnsByRegionQuantities}
                                />
                            ) : (
                                <div className="no-data-graph ">Aucune donnée.</div>
                            )}
                        </div>
                    </section>
                </div>
                <div style={{ marginBottom: '1rem', display: 'flex', width: '100%', gap: '1%', height: '22rem' }}>
                    <section
                        className="section-graph"
                        style={{ height: '100%', width: '40%' }}>
                        <div
                            className="title"
                            style={{ height: '2rem' }}>
                            Répartition des retours par type d&apos;enseigne (%) :
                        </div>
                        <div style={{ height: '90%' }}>
                            {returnsByStoreTypePie.length ? (
                                <PieChart
                                    title={returnsByStoreTypePieLabels}
                                    quantities={returnsByStoreTypePieQuantities}
                                    position={'left'}
                                />
                            ) : (
                                <div className="no-data-graph">Aucune donnée.</div>
                            )}
                        </div>
                    </section>
                    <section
                        className="section-graph"
                        style={{ height: '100%', width: '60%' }}>
                        <div
                            className="title"
                            style={{ height: '1rem' }}>
                            Evolution du nombre de contenants retournés par type d&apos;enseigne :
                        </div>
                        <div style={{ height: '90%' }}>
                            {returnsByStoreType ? (
                                <MultiLineChart
                                    initialData={returnsByStoreType}
                                    datasets={evolutionReturnsByStoreTypeQuantities}
                                />
                            ) : (
                                <div className="no-data-graph ">Aucune donnée.</div>
                            )}
                        </div>
                    </section>
                </div>
                <div style={{ marginBottom: '1rem', display: 'flex', width: '100%', gap: '1%', height: '22rem' }}>
                    <section
                        className="section-graph"
                        style={{ height: '100%', width: '40%' }}>
                        <div
                            className="title"
                            style={{ height: '2rem' }}>
                            Répartition des retours par type de collecteurs (%) :
                        </div>
                        <div style={{ height: '90%' }}>
                            {returnsByCollectorType.length ? (
                                <PieChart
                                    title={returnsByCollectorTypePieLabels}
                                    quantities={returnsByCollectorTypePieQuantities}
                                    position={'left'}
                                />
                            ) : (
                                <div className="no-data-graph">Aucune donnée.</div>
                            )}
                        </div>
                    </section>
                    <section
                        className="section-graph"
                        style={{ height: '100%', width: '60%' }}>
                        <div
                            className="title"
                            style={{ height: '1rem' }}>
                            Evolution du nombre de contenants retournés par type de collecteurs :{' '}
                        </div>
                        <div style={{ height: '90%' }}>
                            {returnsByCollectorType ? (
                                <MultiLineChart
                                    initialData={returnsByCollectorType}
                                    datasets={evolutionReturnsByCollectorTypeQuantities}
                                />
                            ) : (
                                <div className="no-data-graph ">Aucune donnée.</div>
                            )}
                        </div>
                    </section>
                </div>
                <div style={{ marginBottom: '1rem', display: 'flex', width: '100%', gap: '1%', height: '22rem' }}>
                    <section
                        className="section-graph"
                        style={{ height: '100%', width: '40%' }}>
                        <div
                            className="title"
                            style={{ height: '2rem' }}>
                            Répartition du nombre de contenants par acte de retour (%) :
                        </div>
                        <div style={{ height: '90%' }}>
                            {containersByReturn ? (
                                <PieChart
                                    title={containersByReturnLabels}
                                    quantities={containersByReturnQuantities}
                                    position={'left'}
                                />
                            ) : (
                                <div className="no-data-graph ">Aucune donnée.</div>
                            )}
                        </div>
                    </section>
                    <section
                        className="section-graph"
                        style={{ height: '100%', width: '60%' }}>
                        <div
                            className="title"
                            style={{ height: '1rem' }}>
                            Evolution du nombre de contenants retournés par SKU :
                        </div>
                        <div style={{ height: '90%' }}>
                            {returnsBySku ? (
                                <MultiLineChart
                                    initialData={returnsBySku}
                                    datasets={evolutionReturnsBySkuQuantities}
                                />
                            ) : (
                                <div className="no-data-graph ">Aucune donnée.</div>
                            )}
                        </div>
                    </section>
                </div>
                <div style={{ marginBottom: '2rem', display: 'flex', width: '100%', gap: '1%', height: '22rem' }}>
                    <section
                        className="section-graph"
                        style={{ height: '100%', width: '50%' }}>
                        <div
                            className="title"
                            style={{ height: '2rem' }}>
                            Nombre de retours par jour de la semaine :
                        </div>
                        <div style={{ height: '90%' }}>
                            {returnsByWeekDay.length > 0 ? (
                                <BarChart
                                    title={returnsByWeekDayLabels}
                                    quantities={returnsByWeekDayQuantities}
                                />
                            ) : (
                                <div className="no-data-graph">Aucune donnée.</div>
                            )}
                        </div>
                    </section>
                    <section
                        className="section-graph"
                        style={{ height: '100%', width: '50%' }}>
                        <div
                            className="title"
                            style={{ height: '1rem' }}>
                            Nombre de retours par heure de la journée :
                        </div>
                        <div style={{ height: '90%' }}>
                            {returnsByHour.length > 0 ? (
                                <LineChart
                                    labels={returnsByHourLabels}
                                    quantities={returnsByHourQuantities}
                                />
                            ) : (
                                <div className="no-data-graph ">Aucune donnée.</div>
                            )}
                        </div>
                    </section>
                </div>
                <div className="header-container">
                    <div className="header-title">
                        <h1>Retours dans mon enseigne</h1>
                    </div>
                    <div className="content-header">
                        <div className="header-container-content">
                            <select
                                onChange={(e) => setStoreSelected(e.target.value)}
                                value={storeTypeSelected}>
                                <option value="">Magasin</option>

                                {stores.map((option, index) => (
                                    <option
                                        key={index}
                                        value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>

                            <div
                                onClick={resetMyRefsFilter}
                                className="reset">
                                <p style={{ fontWeight: 'bold' }}>X</p>
                            </div>
                        </div>
                        <div className="extract-content">
                            <div className="extract">
                                <CSVLink
                                    style={{ textDecoration: 'none', color: Constant.$blanc }}
                                    className="extract-button"
                                    headers={customHeaderMyRefs}
                                    data={formattedMyRefsData ? formattedMyRefsData : []}
                                    filename={`returns_data.csv`}
                                    target="_blank"
                                    separator={';'}>
                                    Extraire ma données
                                </CSVLink>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', gap: '1%' }}>
                    <section
                        className="section-number"
                        style={{ width: '33%' }}>
                        <div className="title">Nombre total de contenants retournés</div>
                        <div className="content">
                            <div className="number">{myReturns.length}</div>
                        </div>
                    </section>
                    <section
                        className="section-number"
                        style={{ width: '33%' }}>
                        <div className="title">Taux de retours des contenants standards</div>
                        <div className="content">
                            <div className="number">{tauxStandardReturnedInMyCollectors > 0 ? tauxStandardReturnedInMyCollectors : 0}%</div>
                        </div>
                    </section>
                    <section
                        className="section-number"
                        style={{ width: '33%' }}>
                        <div className="title">Ma contribution marché</div>
                        <div className="content">
                            {/* <div className="number">{tauxStandardReturned > 0 ? tauxStandardReturned : 0}%</div> */}
                            <div className="number">X%</div>
                        </div>
                    </section>
                </div>
                <div style={{ marginBottom: '1rem', display: 'flex', width: '100%', gap: '1%', height: '22rem' }}>
                    <section
                        className="section-graph"
                        style={{ height: '100%', width: '40%' }}>
                        <div
                            className="title"
                            style={{ height: '2rem' }}>
                            Répartition des retours par région (%) :
                        </div>
                        <div style={{ height: '90%' }}>
                            {myReturnsByRegionPie.length ? (
                                <PieChart
                                    title={myReturnsByRegionsPieLabels}
                                    quantities={myReturnsByRegionsPieQuantities}
                                    position={'left'}
                                />
                            ) : (
                                <div className="no-data-graph">Aucune donnée.</div>
                            )}
                        </div>
                    </section>
                    <section
                        className="section-graph"
                        style={{ height: '100%', width: '60%' }}>
                        <div
                            className="title"
                            style={{ height: '1rem' }}>
                            Poids de mon nombre de contenants collectés par région dans le global marché :
                        </div>
                        <div style={{ height: '90%' }}>
                            {myRatioRegion.length > 0 ? (
                                <BarChart
                                    title={myRatioRegionLabels}
                                    quantities={myRatioRegionQantities}
                                    label={'%'}
                                />
                            ) : (
                                <div className="no-data-graph">Aucune donnée.</div>
                            )}
                        </div>
                    </section>
                </div>
                <div style={{ marginBottom: '1rem', display: 'flex', width: '100%', gap: '1%', height: '22rem' }}>
                    <section
                        className="section-graph"
                        style={{ height: '100%', width: '100%' }}>
                        <div
                            className="title"
                            style={{ height: '1rem' }}>
                            Evolution du nombre de contenants retournés par région :
                        </div>
                        <div style={{ height: '90%' }}>
                            {myReturnsByRegion ? (
                                <MultiLineChart
                                    initialData={myReturnsByRegion}
                                    datasets={evolutionMyReturnsByRegionQuantities}
                                />
                            ) : (
                                <div className="no-data-graph ">Aucune donnée.</div>
                            )}
                        </div>
                    </section>
                </div>
                <div style={{ marginBottom: '1rem', display: 'flex', width: '100%', gap: '1%', height: '22rem' }}>
                    <section
                        className="section-graph"
                        style={{ height: '100%', width: '40%' }}>
                        <div
                            className="title"
                            style={{ height: '2rem' }}>
                            Répartition des retours par type d&#39;enseigne (%) :
                        </div>
                        <div style={{ height: '90%' }}>
                            {myReturnsByStoreType.length ? (
                                <PieChart
                                    title={myReturnsByStoreTypePieLabels}
                                    quantities={myReturnsByStoreTypePieQuantities}
                                    position={'left'}
                                />
                            ) : (
                                <div className="no-data-graph">Aucune donnée.</div>
                            )}
                        </div>
                    </section>
                    <section
                        className="section-graph"
                        style={{ height: '100%', width: '60%' }}>
                        <div
                            className="title"
                            style={{ height: '1rem' }}>
                            Poids de mon nombre de contenants collectés par type d&#39;enseigne dans le global marché :
                        </div>
                        <div style={{ height: '90%' }}>
                            {myRatioStoreType.length > 0 ? (
                                <BarChart
                                    title={myRatioStoreTypeLabels}
                                    quantities={myRatioStoreTypeQantities}
                                    label={'%'}
                                />
                            ) : (
                                <div className="no-data-graph">Aucune donnée.</div>
                            )}
                        </div>
                    </section>
                </div>
                <div style={{ marginBottom: '1rem', display: 'flex', width: '100%', gap: '1%', height: '22rem' }}>
                    <section
                        className="section-graph"
                        style={{ height: '100%', width: '100%' }}>
                        <div
                            className="title"
                            style={{ height: '1rem' }}>
                            Evolution du nombre de contenants retournés par type d&#39;enseigne :
                        </div>
                        <div style={{ height: '90%' }}>
                            {myReturnsByStoreType ? (
                                <MultiLineChart
                                    initialData={myReturnsByStoreType}
                                    datasets={evolutionMyReturnsByStoreTypeQuantities}
                                />
                            ) : (
                                <div className="no-data-graph ">Aucune donnée.</div>
                            )}
                        </div>
                    </section>
                </div>
                <div style={{ marginBottom: '1rem', display: 'flex', width: '100%', gap: '1%', height: '22rem' }}>
                    <section
                        className="section-graph"
                        style={{ height: '100%', width: '40%' }}>
                        <div
                            className="title"
                            style={{ height: '2rem' }}>
                            Répartition des retours par type de collecteur (%) :
                        </div>
                        <div style={{ height: '90%' }}>
                            {myReturnsByCollectorType.length ? (
                                <PieChart
                                    title={myReturnsByCollectorTypePieLabels}
                                    quantities={myReturnsByCollectorTypePieQuantities}
                                    position={'left'}
                                />
                            ) : (
                                <div className="no-data-graph">Aucune donnée.</div>
                            )}
                        </div>
                    </section>
                    <section
                        className="section-graph"
                        style={{ height: '100%', width: '60%' }}>
                        <div
                            className="title"
                            style={{ height: '1rem' }}>
                            Poids de mon nombre de contenants collectés par type de collecteur dans le global marché :
                        </div>
                        <div style={{ height: '90%' }}>
                            {myRatioCollectorType.length > 0 ? (
                                <BarChart
                                    title={myRatioCollectorTypeLabels}
                                    quantities={myRatioCollectorTypeQantities}
                                    label={'%'}
                                />
                            ) : (
                                <div className="no-data-graph">Aucune donnée.</div>
                            )}
                        </div>
                    </section>
                </div>
                <div style={{ marginBottom: '1rem', display: 'flex', width: '100%', gap: '1%', height: '22rem' }}>
                    <section
                        className="section-graph"
                        style={{ height: '100%', width: '100%' }}>
                        <div
                            className="title"
                            style={{ height: '1rem' }}>
                            Evolution du nombre de contenants retournés par type de collecteur :
                        </div>
                        <div style={{ height: '90%' }}>
                            {myReturnsByCollectorType ? (
                                <MultiLineChart
                                    initialData={myReturnsByCollectorType}
                                    datasets={evolutionMyReturnsByCollectorTypeQuantities}
                                />
                            ) : (
                                <div className="no-data-graph ">Aucune donnée.</div>
                            )}
                        </div>
                    </section>
                </div>
                <div style={{ marginBottom: '1rem', display: 'flex', width: '100%', gap: '1%', height: '22rem' }}>
                    <section
                        className="section-graph"
                        style={{ height: '100%', width: '40%' }}>
                        <div
                            className="title"
                            style={{ height: '2rem' }}>
                            Répartition du nombre de contenants par acte de retour dans mes collecteurs (%) :
                        </div>
                        <div style={{ height: '90%' }}>
                            {containersByMyReturn ? (
                                <PieChart
                                    title={containersByMyReturnLabels}
                                    quantities={containersByMyReturnQuantities}
                                    position={'left'}
                                />
                            ) : (
                                <div className="no-data-graph ">Aucune donnée.</div>
                            )}
                        </div>
                    </section>
                    <section
                        className="section-graph"
                        style={{ height: '100%', width: '60%' }}>
                        <div
                            className="title"
                            style={{ height: '1rem' }}>
                            Evolution du nombre de contenants retournés dans mes collecteurs par SKU :
                        </div>
                        <div style={{ height: '90%' }}>
                            {myReturnsBySku ? (
                                <MultiLineChart
                                    initialData={myReturnsBySku}
                                    datasets={evolutionMyReturnsBySkuQuantities}
                                />
                            ) : (
                                <div className="no-data-graph ">Aucune donnée.</div>
                            )}
                        </div>
                    </section>
                </div>
                <div style={{ marginBottom: '2rem', display: 'flex', width: '100%', gap: '1%', height: '22rem' }}>
                    <section
                        className="section-graph"
                        style={{ height: '100%', width: '50%' }}>
                        <div
                            className="title"
                            style={{ height: '2rem' }}>
                            Nombre de retours dans mes collecteurs par jour de la semaine :
                        </div>
                        <div style={{ height: '90%' }}>
                            {myReturnsByWeekDay.length > 0 ? (
                                <BarChart
                                    title={myReturnsByWeekDayLabels}
                                    quantities={myReturnsByWeekDayQuantities}
                                />
                            ) : (
                                <div className="no-data-graph">Aucune donnée.</div>
                            )}
                        </div>
                    </section>
                    <section
                        className="section-graph"
                        style={{ height: '100%', width: '50%' }}>
                        <div
                            className="title"
                            style={{ height: '1rem' }}>
                            Nombre de retours dans mes collecteurs par heure de la journée :
                        </div>
                        <div style={{ height: '90%' }}>
                            {myReturnsByHour.length > 0 ? (
                                <LineChart
                                    labels={myReturnsByHourLabels}
                                    quantities={myReturnsByHourQuantities}
                                />
                            ) : (
                                <div className="no-data-graph ">Aucune donnée.</div>
                            )}
                        </div>
                    </section>
                </div>
            </div>
        </main>
    );
}
