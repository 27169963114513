import * as Constants from '../Constants';
import { Jwt } from '../Jwt';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';

export default function PreHome() {
    const navigate = useNavigate();
    const userContext = new Jwt();

    const goToOperations = (type) => {
        switch (type) {
            case Constants.ENTITY_TYPE_FS:
                return navigate(`/servicesupplier/operation/home`);
            case Constants.ENTITY_TYPE_INDUSTRIAL:
                return navigate(`/industrial/operation/home`);
            case Constants.ENTITY_TYPE_SORTER:
                return navigate('/sorter/operation/reception');
            case Constants.ENTITY_TYPE_WASHER:
                return navigate('/washer/operation/reception');
            case Constants.ENTITY_TYPE_OPERATOR:
                return navigate('/collecte/operation/list');
            case Constants.ENTITY_TYPE_RVMOWNER:
                return navigate('/rvmowner/operation/home');
            case Constants.ENTITY_TYPE_STOREOWNER:
                return navigate('/storeowner/operation/collector/list');
            case Constants.ENTITY_TYPE_CDC:
                return navigate('/');
            case Constants.ENTITY_TYPE_DISTRIBUTOR:
                return navigate(`/`);
            default:
                break;
        }
    };

    const goToData = (type) => {
        switch (type) {
            case Constants.ENTITY_TYPE_FS:
                return navigate(`/servicesupplier/data/home`);
            case Constants.ENTITY_TYPE_INDUSTRIAL:
                return navigate(`/industrial/data/home`);
            case Constants.ENTITY_TYPE_SORTER:
                return navigate('/');
            case Constants.ENTITY_TYPE_WASHER:
                return navigate('/');
            case Constants.ENTITY_TYPE_OPERATOR:
                return navigate('/');
            case Constants.ENTITY_TYPE_RVMOWNER:
                return navigate('/');
            case Constants.ENTITY_TYPE_STOREOWNER:
                return navigate('/');
            case Constants.ENTITY_TYPE_CDC:
                return navigate('/');
            case Constants.ENTITY_TYPE_DISTRIBUTOR:
                return navigate(`/distributor/data/home`);
            default:
                break;
        }
    };

    const goToFacturation = (type) => {
        switch (type) {
            case Constants.ENTITY_TYPE_FS:
                return navigate(`/`);
            case Constants.ENTITY_TYPE_INDUSTRIAL:
                return navigate(`/industrial/financial/reportings`);
            case Constants.ENTITY_TYPE_SORTER:
                return navigate('/');
            case Constants.ENTITY_TYPE_WASHER:
                return navigate('/');
            case Constants.ENTITY_TYPE_OPERATOR:
                return navigate('/');
            case Constants.ENTITY_TYPE_RVMOWNER:
                return navigate('/');
            case Constants.ENTITY_TYPE_STOREOWNER:
                return navigate('/storeowner/financial/transaction');
            case Constants.ENTITY_TYPE_CDC:
                return navigate('/cdc/financial/dashboard');
            case Constants.ENTITY_TYPE_DISTRIBUTOR:
                return navigate(`/`);
            default:
                break;
        }
    };

    const handleModuleClick = (moduleId) => {
        localStorage.setItem('module', moduleId);
        if (moduleId === Constants.MODULE_OPERATION) {
            goToOperations(userContext.entityType);
        } else if (moduleId === Constants.MODULE_DATA) {
            goToData(userContext.entityType);
        } else {
            goToFacturation(userContext.entityType);
        }
        window.location.reload();
    };

    return (
        <main className="pre-home-container">
            <div
                className="circle-container"
                onClick={() => handleModuleClick(Constants.MODULE_OPERATION)}>
                <div>Opérations</div>
            </div>
            <div
                className="circle-container"
                onClick={() => handleModuleClick(Constants.MODULE_DATA)}>
                <div>Data</div>
            </div>
            <div
                className="circle-container"
                onClick={() => handleModuleClick(3)}>
                <div>Finance</div>
            </div>
            <Footer />
        </main>
    );
}
