import { addProduct } from '../../../../../../API/ProductApi';
import React, { useEffect, useState } from 'react';
import { getSuppliers } from '../../../../../../API/SupplierApi';
import { useLocation, useNavigate } from 'react-router-dom';
import { getContainers } from '../../../../../../API/ContainerApi';
import { checkSupplierProductFormErrors, checkIsInputEmpty } from '../../../../../../Utils';
export default function AddProduct() {
    const [errorMessage, setErrorMessage] = useState([]);
    const [errorEmpty, setErrorEmpty] = useState('');
    const [supplierList, setSupplierList] = useState([]);
    const [containerList, setContainerList] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();
    const { supplier_id } = location.state || {};

    const [formData, setFormData] = useState({
        supplier_id: '',
        name: '',
        ref: '',
        sku: '',
    });

    const [formErrors, setFormErrors] = useState({
        supplier_id: '',
        name: '',
        ref: '',
        sku: '',
    });

    const requiredFields = [
        'name',
        'ref',
        'sku',
    ];

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        const _getAllSuppliers = async () => {
            const data = await getSuppliers();
            setSupplierList(data);

            let supplier_list = document.getElementById('optionsSelect');
            if (supplier_id > 0) {
                supplier_list.value = supplier_id;
                supplier_list.dispatchEvent(new Event('change'));
                setFormData((formData) => ({ ...formData, supplier_id: supplier_id }));

                supplier_list.disabled = true;
            }

            const containerData = await getContainers();
            setContainerList(containerData);
        };
        _getAllSuppliers();
    }, [supplier_id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage([]);
        setErrorEmpty('');

        const inputEmpty = checkIsInputEmpty(requiredFields, formData, setFormErrors, setErrorEmpty);
        if (!inputEmpty) {
            const checkFormat = checkSupplierProductFormErrors(formData, formErrors, setFormErrors, setErrorMessage);
            if (checkFormat) {
                return;
            } else {
                const addProductData = await addProduct(formData);
                if (addProductData) {
                    alert('Le produit a été créé avec succès');
                    navigate(-1);
                } else {
                    alert("une erreur est survenue lors de l'ajout du produit");
                }
            }
        } else {
            console.log('Il reste des champs obligatoires vides');
        }
    };

    return (
        <main>
            <section className="small-section">
                <h2>Ajouter un contenant</h2>
                <div className="form-container">
                    <section style={{ width: '100%' }}>
                        <div>
                            <label>Nom du fournisseur:</label>
                            <select
                                className={formErrors.supplier_id ? 'error' : ''}
                                name="supplier_id"
                                id="optionsSelect"
                                onChange={handleChange}>
                                <option>Choisissez une option</option>
                                {supplierList.map((option, index) => (
                                    <option
                                        key={index}
                                        value={option.id}>
                                        {option.name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div>
                            <label>Nom du contenant:</label>
                            <input
                                className={formErrors.name ? 'error' : ''}
                                type="text"
                                name="name"
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <label>Référence interne:</label>
                            <input
                                className={formErrors.ref ? 'error' : ''}
                                type="text"
                                name="ref"
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <label>SKU</label>
                            <select
                                name="sku"
                                className={formErrors.sku ? 'error' : ''}
                                onChange={handleChange}>
                                <option>Choisissez une option</option>
                                {containerList.map((option, index) => (
                                    <option
                                        key={index}
                                        value={option.sku}>
                                        {option.sku}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </section>
                </div>
                <p className="error-form">{errorEmpty && errorEmpty}</p>
                {errorMessage.length
                    ? errorMessage.map((item, index) => {
                          return (
                              <div
                                  className="error-form"
                                  key={index}>
                                  {item}
                              </div>
                          );
                      })
                    : null}
                <div className="button-confirm">
                    <button
                        className="button-actif"
                        onClick={handleSubmit}>
                        Ajouter le contenant
                    </button>
                </div>
            </section>
        </main>
    );
}
