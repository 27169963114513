import Cookies from 'js-cookie';
import * as Constants from '../../Constants';

export const serviceSupplierMenuOperation = () => {
    let menu = [];
    const access = Cookies.get('access').length ? JSON.parse(Cookies.get('access')) : null;
    if (access) {
        let access_operation = access.serviceprovider.operation;

        if (checkAccessOperationDashboard(access_operation)) {
            menu.push('homeOpe');
        }
        if (CheckAccessOperationBesoinsMarques(access_operation)) {
            menu.push('indus_need');
        }
        if (CheckAccessOperationContenantsEnCirculation) {
            menu.push('container');
        }
        if (CheckAccessOperationBaseDeDonnees(access_operation)) {
            menu.push('bdd');
        }
    }
    return menu;
};

const checkAccessOperationDashboard = (access_operation) => {
    if (access_operation.dashboard.all === 1) {
        return true;
    } else {
        return false;
    }
};

const CheckAccessOperationBesoinsMarques = (access_operation) => {
    if (access_operation.besoins_marques.dashboard === 1) {
        return true;
    } else {
        return false;
    }
};

const CheckAccessOperationContenantsEnCirculation = (access_operation) => {
    if (access_operation.contenants_en_circulation.dashboard === 1) {
        return true;
    } else {
        return false;
    }
};

const CheckAccessOperationBaseDeDonnees = (access_operation) => {
    for (let key in access_operation.base_de_donnees) {
        if (access_operation.base_de_donnees[key] === 1) {
            return true;
        }
    }
    return false;
};

const getSubAccessOperationDashboard = () => {
    let sousMenu = [{ title: 'Dashboard' }];
    return sousMenu;
};

const getSubAccessOperationBesoinsMarques = () => {
    let sousMenu = [{ title: 'Besoins marques' }];
    const access = Cookies.get('access').length ? JSON.parse(Cookies.get('access')) : null;
    if (access) {
        let access_operation = access.serviceprovider.operation;

        if (access_operation.besoins_marques.dashboard === 1) {
            sousMenu.push({ name: 'Dashboard', link: '/servicesupplier/operation/indusneeds/dashboard' });
        }
        if (access_operation.besoins_marques.besoins_en_cours === 1) {
            sousMenu.push({ name: 'Besoins en cours', link: '/servicesupplier/operation/indusneeds/untreated' });
        }
        if (access_operation.besoins_marques.besoins_par_marque === 1) {
            sousMenu.push({ name: 'Besoins par marque', link: '/servicesupplier/operation/indusneeds/list' });
        }
    }
    return sousMenu;
};

const getSubAccessOperationContenantsEnCirculation = () => {
    let sousMenu = [{ title: 'Contenants en circulation' }];
    const access = Cookies.get('access').length ? JSON.parse(Cookies.get('access')) : null;
    if (access) {
        let access_operation = access.serviceprovider.operation;

        if (access_operation.contenants_en_circulation.dashboard === 1) {
            sousMenu.push({ name: 'Dashboard', link: '/servicesupplier/operation/circulationcontainers/dashboard' });
        }
        if (access_operation.contenants_en_circulation.collecteurs === 1) {
            sousMenu.push({
                sousPartie: 'Collecteurs',
                sousMenu: [
                    { name: 'Dashboard', link: '/servicesupplier/operation/circulationcontainers/collector/dashboard' },
                    { name: 'Collecte', link: '/servicesupplier/operation/collector/pickup' },
                    { name: 'Liste', link: '/servicesupplier/operation/collector/list' },
                ],
            });
        }
        if (access_operation.contenants_en_circulation.centre_de_tri === 1) {
            sousMenu.push({ name: 'Centre de tri', link: '/servicesupplier/operation/circulationcontainers/sorter' });
        }
        if (access_operation.contenants_en_circulation.centre_de_lavage === 1) {
            sousMenu.push({ name: 'Centre de lavage', link: '/servicesupplier/operation/circulationcontainers/washer' });
        }
    }
    return sousMenu;
};

const getSubAccessOperationBaseDeDonnees = () => {
    let sousMenu = [{ title: 'Base de données' }];
    const access = Cookies.get('access').length ? JSON.parse(Cookies.get('access')) : null;
    if (access) {
        let access_operation = access.serviceprovider.operation;

        if (access_operation.base_de_donnees.contenants === 1) {
            sousMenu.push({ name: 'Contenants', link: '/servicesupplier/operation/container/list' });
        }
        if (access_operation.base_de_donnees.verriers === 1) {
            sousMenu.push({ name: 'Verriers', link: '/servicesupplier/operation/supplier/list' });
        }
        if (access_operation.base_de_donnees.marques === 1) {
            sousMenu.push({ name: 'Marques', link: '/servicesupplier/operation/industrial/list' });
        }
        if (access_operation.base_de_donnees.produits === 1) {
            sousMenu.push({ name: 'Produits', link: '/servicesupplier/operation/industrial/product/list' });
        }
        if (access_operation.base_de_donnees.operateur_de_collecte === 1) {
            sousMenu.push({ name: 'Operateur de collecte', link: '/servicesupplier/operation/operator/list' });
        }
        if (access_operation.base_de_donnees.centre_de_tri === 1) {
            sousMenu.push({ name: 'Centre de Tri', link: '/servicesupplier/operation/sorter/list' });
        }
        if (access_operation.base_de_donnees.centre_de_lavage === 1) {
            sousMenu.push({ name: 'Centre de Lavage', link: '/servicesupplier/operation/washer/list' });
        }
        if (access_operation.base_de_donnees.gestionnaire_de_rvm === 1) {
            sousMenu.push({ name: 'Gestionnaire de RVM', link: '/servicesupplier/operation/rvmowner/list' });
        }
        if (access_operation.base_de_donnees.gestionnaire_de_magasin === 1) {
            sousMenu.push({ name: 'Gestionnaire de Magasin', link: '/servicesupplier/operation/storeowner/list' });
        }
        if (access_operation.base_de_donnees.distributor === 1) {
            sousMenu.push({ name: 'Distributeur', link: '/servicesupplier/operation/distributor/list' });
        }
        if (access_operation.base_de_donnees.centre_de_collecte === 1) {
            // sousMenu.push({ name: 'Centre de Collecte', link: '/servicesupplier/operation/depot/list' });
            sousMenu.push({ name: 'Magasins', link: '/servicesupplier/operation/store/list' });
        }
    }
    return sousMenu;
};

export const getSousMenuServiceSupplierOperation = (onglet) => {
    switch (onglet) {
        case 'homeOpe':
            return getSubAccessOperationDashboard();
        case 'indus_need':
            return getSubAccessOperationBesoinsMarques();
        case 'container':
            return getSubAccessOperationContenantsEnCirculation();
        case 'bdd':
            return getSubAccessOperationBaseDeDonnees();
        default:
            return [];
    }
};

export const serviceSupplierMenuData = () => {
    return [];
};

export const getSousMenuServiceSupplierData = (onglet) => {
    switch (onglet) {
        default:
            return [];
    }
};

export const serviceSupplierMenuFacturation = () => {
    return [];
};

export const getSousMenuServiceSupplierFacturation = (onglet) => {
    switch (onglet) {
        default:
            return [];
    }
};

export const serviceSupplierMenuAccount = () => {
    return ['my_account'];
};

export const getSousMenuServiceSupplierAccount = (onglet) => {
    const role = Cookies.get('role');
    const isAdmin = role === Constants.ENTITY_ROLE_ADMIN;
    switch (onglet) {
        case 'my_account':
            if (isAdmin) {
                return [
                    { title: 'Mon compte' },
                    { name: 'Informations personnelles', link: '/servicesupplier/myprofil/personal_info' },
                    { name: 'Société', link: '/servicesupplier/myprofil/company_info' },
                    { name: 'Accès', link: '/servicesupplier/myprofil/access' },
                    { name: 'Gestion des comptes', link: '/servicesupplier/myprofil/rolemanagement' },
                ];
            } else {
                return [
                    { title: 'Mon compte' },
                    { name: 'Informations personnelles', link: '/servicesupplier/myprofil/personal_info' },
                    { name: 'Société', link: '/servicesupplier/myprofil/company_info' },
                    { name: 'Accès', link: '/servicesupplier/myprofil/access' },
                ];
            }

        default:
            return [];
    }
};
