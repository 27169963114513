import { useState } from 'react';
import { _defineStatus } from '../../../../../../Utils';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Constant from '../../../../../../Constants';
export default function UpdateWasherSku() {
    const location = useLocation();
    const navigate = useNavigate();
    const { skuLink, washerName } = location.state || {};
    const [skuData, setSkuData] = useState(skuLink);
    const [allowModif, setAllowModif] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSkuData({
            ...skuData,
            [name]: value,
        });
    };

    const handleSaveChanges = async () => {
        try {
            if (!skuLink) {
                console.error('ID du sku non valide');
                return;
            }
            const { gearApi } = await import('../../../../../../gearApi.js');
            await gearApi.washer.putStatusForSkuOfWasher({
                washerId: skuData.entity_id,
                sku: skuData.sku,
                status: skuData.status,
            });
            console.log('Modifications enregistrées avec succès');
            alert('Modifications enregistrées avec succès');
            navigate(-1);
        } catch (error) {
            alert("Erreur lors de l'enregistrement des modifications");
            console.error("Erreur lors de l'enregistrement des modifications", error);
        }
        setAllowModif(false);
    };
    return (
        <main>
            <section className="small-section">
                <h2>Modifier un SKU</h2>

                {skuData.id ? (
                    <div className="form-container">
                        <section style={{ width: '100%' }}>
                            <div>
                                <label>Nom du centre de lavage:</label>
                                <input
                                    disabled
                                    type="text"
                                    name="company_name"
                                    value={washerName || ''}
                                />
                            </div>
                            <div>
                                <label>SKU :</label>
                                <input
                                    disabled
                                    type="text"
                                    name="sku"
                                    value={skuData.sku || ''}
                                />
                            </div>
                            <div>
                                <label>Statut :</label>
                                <select
                                    style={{ backgroundColor: _defineStatus(skuData.status).color, color: Constant.$blanc }}
                                    disabled={!allowModif ? true : false}
                                    name="status"
                                    value={skuData.status}
                                    onChange={handleInputChange}>
                                    <option value={0}>Actif</option>
                                    <option value={100}>Inactif</option>
                                </select>
                            </div>
                        </section>
                    </div>
                ) : null}
                <div className="button-confirm">
                    {!allowModif ? (
                        <button
                            className="button-actif"
                            onClick={() => {
                                setAllowModif(!allowModif);
                            }}>
                            Modifier les informations
                        </button>
                    ) : (
                        <button
                            className="button-actif"
                            onClick={handleSaveChanges}>
                            Enregistrer les modifications
                        </button>
                    )}
                </div>
            </section>
        </main>
    );
}
