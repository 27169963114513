import { CaretUp, CaretDown } from '@phosphor-icons/react';
import DatePicker from 'react-datepicker';
import { useState } from 'react';
import { format } from 'date-fns';
export default function DateFilter({ startDate, setStartDate, endDate, setEndDate }) {
    const [showDatePicker, setShowDatePicker] = useState(false);
    const now = new Date();

    const resetDate = () => {
        setStartDate(new Date(new Date().getFullYear(), 0, 1));
        setEndDate(now);
    };
    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };
    return (
        <div className="date">
            <div
                className="date-picker-title"
                onClick={() => {
                    setShowDatePicker(!showDatePicker);
                }}>
                <p>
                    {startDate ? format(startDate, 'yyyy-MM-dd') : null} - {endDate ? format(endDate, 'yyyy-MM-dd') : null}
                </p>
                {!showDatePicker ? <CaretDown size={15} /> : <CaretUp size={15} />}
            </div>
            <div className="date-picker">
                {showDatePicker && (
                    <DatePicker
                        selected={startDate}
                        onChange={onChange}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        inline
                    />
                )}
            </div>
            <div
                onClick={resetDate}
                className="reset">
                <p>Réinitialiser</p>
            </div>
        </div>
    );
}
