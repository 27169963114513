import { getGearAPIApiEndpoint } from './endpoints';
import * as Sentry from '@sentry/react';
import { defaultHeaders } from './headers';

const GEAR_API_BASE_URL = getGearAPIApiEndpoint();

const BOCOLOCO_CREATE_SORTERDECLARATION = '/sorterdeclaration/new';
const BOCOLOCO_GET_DECLARATIONS_BYENTITYID = '/sorterdeclaration/getbyentityid';
const BOCOLOCO_GET_DECLARATIONS_DETAILS = '/sorterdeclaration/getdetails';
//const BOCOLOCO_GET_ALL_SORTER_DECLARATIONS_DETAILS = '/sorterdeclaration/getalldetails';
const BOCOLOCO_GET_DECLARATIONS_DETAILS_BY_ENTITYID = '/sorterdeclaration/gedetailsdyentityid';
const BOCOLOCO_UPDATE_SORTER_DECLARATIONS_DETAILS = '/sorterdeclaration/update';
const BOCOLOCO_GET_DECLARATIONS_DETAILS_BY_ENTITYID_LAST_STATUS = '/sorterdeclaration/gedetailsdyentityid/laststatus';
const BOCOLOCO_GET_DETAILS_LAST_SORTEREVENT_STATUS = '/sorterdeclaration/details/laststatus';
const BOCOLOCO_GET_ALL_SORTER_DECLARATIONS_DETAILS_BYDATES = '/sorterdeclaration/getalldetails/bydates';
export function sorterDeclarationCreate(declarationDetailsData, entity_id, user_id) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_CREATE_SORTERDECLARATION;
    var url_args = '?entity_id=' + entity_id + '&user_id=' + user_id;
    const url = encodeURI(url_base + url_args);
    return fetch(url, {
        method: 'PUT',
        body: JSON.stringify(declarationDetailsData),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            console.log('ERROR WASHER DECLARATION CREATE');
            console.log(error);
            throw error;
        });
}

export function getDeclarationsBySorterId(entity_id) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_DECLARATIONS_BYENTITYID;
    var url_args = '?entity_id=' + entity_id;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function getSorterDeclarationDetails(id) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_DECLARATIONS_DETAILS;
    var url_args = '?id=' + id;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

/*
export function getAllSorterDeclarationDetails() {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_ALL_SORTER_DECLARATIONS_DETAILS;
    const url = encodeURI(url_base);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}
        */

export function getAllSorterDeclarationDetailsByDates(startDate, endDate) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_ALL_SORTER_DECLARATIONS_DETAILS_BYDATES;
    var url_args = '?startDate=' + startDate + '&endDate=' + endDate;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function getSorterDeclarationDetailsByEntityId(id) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_DECLARATIONS_DETAILS_BY_ENTITYID;
    var url_args = '?id=' + id;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function updateSorterDeclarationDetails(formData, suiviId) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_UPDATE_SORTER_DECLARATIONS_DETAILS;
    var url_args = '?suiviId=' + suiviId;
    const url = encodeURI(url_base + url_args);
    return fetch(url, {
        method: 'PUT',
        body: JSON.stringify(formData),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            console.log('ERROR UPDATE SORTER DECLARATION DETAILS');
            console.log(error);
            throw error;
        });
}

export function getSorterDeclarationDetailsLastStatusByEntityId(id) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_DECLARATIONS_DETAILS_BY_ENTITYID_LAST_STATUS;
    var url_args = '?id=' + id;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function getDetailsLastSorterEventStatus() {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_DETAILS_LAST_SORTEREVENT_STATUS;
    const url = encodeURI(url_base);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}
