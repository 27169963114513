import { useState, useEffect, useRef, useCallback } from 'react';
import { getStoresByName, getStores } from '../../../../../API/StoreApi';
// import { getDepotsCheck } from '../../../../../API/CheckApi/CheckDepotApi';
import { _defineStatus, useClickOutside } from '../../../../../Utils';
import { Link, useNavigate } from 'react-router-dom';
import * as Constant from '../../../../../Constants';
import SearchComponent from '../../../../../Components/searchComponent';
export default function Store() {
    const navigate = useNavigate();
    const [filterSelected, setFilterSelected] = useState('asc');
    const [storeList, setStoreList] = useState([]);
    const [storeNames, setStoreNames] = useState([]);
    const [showOptions, setShowOptions] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const dropdownRef = useRef(null);

    const handleClickOutside = () => {
        setShowOptions(false);
    };
    useClickOutside(dropdownRef, handleClickOutside);

    const fetchAllStores = useCallback(async () => {
        try {
            const result = await getStores();

            setStoreList(result);
        } catch (error) {
            console.error('Erreur lors de la récupération des depots', error);
        }
    }, []);

    useEffect(() => {
        const fetchStoreNames = async () => {
            try {
                if (searchTerm.length > 2) {
                    const result = await getStoresByName(searchTerm);
                    if (result.length) {
                        setStoreList(result);
                        setStoreNames(result);
                        setErrorMessage('');
                    } else {
                        setStoreList([]);
                        setStoreNames([]);
                        setErrorMessage('Aucun résultat');
                    }
                } else if (searchTerm.length === 0) {
                    fetchAllStores();
                    setErrorMessage('');
                }
            } catch (error) {
                console.error('Erreur lors de la récupération des depots', error);
            }
        };

        fetchStoreNames();
    }, [
        searchTerm,
        fetchAllStores,
    ]);

    const handleInputChange = (e) => {
        setSearchTerm(e.target.value.toLowerCase());
        setShowOptions(true);
    };

    const handleSelectOption = (option) => {
        setSearchTerm(option.name);
        setShowOptions(false);
        console.log(option);
        navigate('/servicesupplier/operation/store/sheet', { state: { id: option.id } });
    };

    const sortDataAsc = () => {
        const sortedData = [...storeList].sort((a, b) => a.name.localeCompare(b.name));
        setStoreList(sortedData);
        setFilterSelected('asc');
    };

    const sortDataDesc = () => {
        const sortedData = [...storeList].sort((a, b) => b.name.localeCompare(a.name));
        setStoreList(sortedData);
        setFilterSelected('desc');
    };

    return (
        <main>
            <section className="big-section ">
                <h2>Les magasins</h2>
                <div className="top">
                    <SearchComponent
                        dropdownRef={dropdownRef}
                        showOptions={showOptions}
                        searchTerm={searchTerm}
                        handleInputChange={handleInputChange}
                        productsNames={storeNames}
                        handleSelectOption={handleSelectOption}
                        placeholder="Rechercher un magasin"
                        searchKey="name"
                    />

                    <div className="top-button-sort">
                        <div className="filter">
                            <p className="sort">Trier par</p>
                            <div>
                                <p
                                    className={filterSelected === 'asc' ? 'selected' : 'classic'}
                                    onClick={sortDataAsc}>
                                    A &gt; Z
                                </p>
                                <p
                                    className={filterSelected === 'desc' ? 'selected' : 'classic'}
                                    onClick={sortDataDesc}>
                                    Z &gt; A
                                </p>
                            </div>
                        </div>
                        <Link
                            to={'/servicesupplier/operation/store/add'}
                            className="button-actif">
                            AJOUTER UN MAGASIN
                        </Link>
                    </div>
                </div>
                {errorMessage && (
                    <div style={{ width: '100%', padding: '0.5rem', textAlign: 'center', color: Constant.$rouge, marginBottom: '1.25rem' }}>
                        <p>{errorMessage}</p>
                    </div>
                )}
                <div className="tab-item">
                    <div
                        className="pre-filled-fields-1-several-item"
                        style={{ fontWeight: '600' }}>
                        <p>Sociéte</p>
                        <p>Nom</p>
                        <p>Ville</p>
                        <p>Code Postal</p>
                        <p>Centre de tri associé</p>
                        <p>Statut</p>
                    </div>
                    {storeList.length ? (
                        storeList.map((item, index) => {
                            return (
                                <Link
                                    key={index}
                                    to={'/servicesupplier/operation/store/sheet'}
                                    state={{ id: item.id }}
                                    className="pre-filled-fields-2-several-item">
                                    <p>{item.company_name}</p>
                                    <p>{item.name}</p>
                                    <p>{item.city}</p>
                                    <p>{item.zipcode}</p>
                                    <p>{item.sorter_name}</p>
                                    <p
                                        style={{
                                            backgroundColor: _defineStatus(item.status).color,
                                            color: Constant.$blanc,
                                            borderTopRightRadius: '0.6rem',
                                            borderBottomRightRadius: '0.6rem',
                                        }}>
                                        {_defineStatus(item.status).name}
                                    </p>
                                </Link>
                            );
                        })
                    ) : (
                        <div className="no-data-graph ">Aucun centre de collecte enregistré.</div>
                    )}
                </div>
            </section>
        </main>
    );
}
