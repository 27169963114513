import { useState, useEffect } from 'react';
import { getSorterById, updateSorter } from '../../../../../API/SorterApi';
import { _defineStatus } from '../../../../../Utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { checkEntityFormErrors, checkIsInputEmpty } from '../../../../../Utils';
import * as Constant from '../../../../../Constants';
import AddressSearch from '../../../../../Components/AddressSearch';

export default function UpdateSorter() {
    const location = useLocation();
    const navigate = useNavigate();
    const { id } = location.state || {};
    const [sorterData, setSorterData] = useState([]);
    const [errorMessage, setErrorMessage] = useState([]);
    const [errorEmpty, setErrorEmpty] = useState('');
    useEffect(() => {
        const fetchSorterData = async () => {
            try {
                const sorterRawData = await getSorterById(id);
                setSorterData(sorterRawData[0]);
            } catch (error) {
                console.error("Erreur lors de la récupération des infos de l'industriel", error);
            }
        };
        fetchSorterData();
    }, [id]);

    const [formErrors, setFormErrors] = useState({
        company_name: false,
        address_1: false,
        address_2: false,
        city: false,
        zipcode: false,
        siret: false,
        tva_intra: false,
        contact_name: false,
        contact_phone: false,
        contact_email: false,
        website: false,
    });

    const requiredFields = [
        'company_name',
        'address_1',
        'city',
        'zipcode',
        'siret',
        'tva_intra',
        'contact_name',
        'contact_phone',
        'contact_email',
    ];

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSorterData({
            ...sorterData,
            [name]: value,
        });
    };

    const handleSaveChanges = async (e) => {
        e.preventDefault();
        setErrorMessage([]);
        setErrorEmpty('');

        const updatedFormData = { ...sorterData, name: sorterData.company_name };

        const inputEmpty = checkIsInputEmpty(requiredFields, sorterData, setFormErrors, setErrorEmpty);
        if (!inputEmpty) {
            const checkFormat = checkEntityFormErrors(sorterData, formErrors, setFormErrors, setErrorMessage);
            if (checkFormat) {
                return;
            } else {
                const updateSorterData = await updateSorter(id, updatedFormData);
                if (updateSorterData) {
                    alert('Le centre de tri été modifié avec succès');
                    navigate(-1);
                } else {
                    alert('une erreur est survenue lors de la modification du centre de tri');
                }
            }
        } else {
            console.log('Il reste des champs obligatoires vides');
        }
    };

    return (
        <main>
            <section className="big-section">
                <h2>Modifier un centre de tri</h2>
                <div className="form-container">
                    <section>
                        <div>
                            <label>Nom de la société:</label>
                            <input
                                value={sorterData.company_name || ''}
                                className={formErrors.company_name ? 'error' : ''}
                                type="text"
                                name="company_name"
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div>
                            <label>Siret:</label>
                            <input
                                value={sorterData.siret || ''}
                                className={formErrors.siret ? 'error' : ''}
                                type="text"
                                name="siret"
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div>
                            <label>TVA intracommunautaire:</label>
                            <input
                                value={sorterData.tva_intra || ''}
                                className={formErrors.tva_intra ? 'error' : ''}
                                type="text"
                                name="tva_intra"
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                    </section>
                    <section>
                        <AddressSearch
                            formData={sorterData}
                            setFormData={setSorterData}
                            formErrors={formErrors}
                            setFormErrors={setFormErrors}
                            handleChange={handleInputChange}
                        />
                    </section>

                    <section>
                        <div>
                            <label>Nom du contact:</label>
                            <input
                                value={sorterData.contact_name || ''}
                                className={formErrors.contact_name ? 'error' : ''}
                                type="text"
                                name="contact_name"
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div>
                            <label>Téléphone du contact:</label>
                            <input
                                value={sorterData.contact_phone || ''}
                                className={formErrors.contact_phone ? 'error' : ''}
                                type="number"
                                name="contact_phone"
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div>
                            <label>Email du contact:</label>
                            <input
                                value={sorterData.contact_email || ''}
                                className={formErrors.contact_email ? 'error' : ''}
                                type="email"
                                name="contact_email"
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div>
                            <label>Site web:</label>
                            <input
                                value={sorterData.website || ''}
                                className={formErrors.website ? 'error' : ''}
                                type="url"
                                name="website"
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div>
                            <label>Statut:</label>
                            <select
                                style={{ backgroundColor: _defineStatus(sorterData.status).color, color: Constant.$blanc }}
                                name="status"
                                value={sorterData.status}
                                onChange={handleInputChange}>
                                <option value={0}>Actif</option>
                                <option value={100}>Inactif</option>
                            </select>
                        </div>
                    </section>
                </div>
                <p className="error-form">{errorEmpty && errorEmpty}</p>
                {errorMessage.length
                    ? errorMessage.map((item, index) => {
                          return (
                              <div
                                  className="error-form"
                                  key={index}>
                                  {item}
                              </div>
                          );
                      })
                    : null}
                <div className="button-confirm">
                    <button
                        className="button-actif"
                        onClick={handleSaveChanges}>
                        Enregistrer les modifications
                    </button>
                </div>
            </section>
        </main>
    );
}
