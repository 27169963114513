import { useState, useEffect } from 'react';
import { getStoreById, updateStore } from '../../../../../API/StoreApi';
import { getDepots } from '../../../../../API/DepotApi';
import { getDepotsCheck } from '../../../../../API/CheckApi/CheckDepotApi';
import { useLocation, useNavigate } from 'react-router-dom';
import { checkStoreFormErrors, checkIsInputEmpty } from '../../../../../Utils';
import { _defineStoreType, storeTypeTab, storeOwnerTab, _defineStoreOwner } from '../../../../../Utils';
import { _defineStatus } from '../../../../../Utils';
import * as Constant from '../../../../../Constants';
import * as Sentry from '@sentry/react';
import AddressSearch from '../../../../../Components/AddressSearch.jsx';
import { getDistributors } from '../../../../../API/DistributorApi';
import { getStoreowners } from '../../../../../API/StoreownerApi';
export default function UpdateStore() {
    const [errorMessage, setErrorMessage] = useState([]);
    const [errorEmpty, setErrorEmpty] = useState('');
    const location = useLocation();
    const { id } = location.state || {};
    const [storeData, setStoreData] = useState([]);
    const [rvmownerData, setRvmownerData] = useState([]);
    const [depotList, setDepotList] = useState([]);
    const [distributorList, setDistributorList] = useState([]);
    const [storeownerList, setStoreownerList] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchDepotData = async () => {
            try {
                const { gearApi } = await import('../../../../../gearApi.js');

                const storeRawData = await getStoreById(id);
                setStoreData(storeRawData[0]);

                const dataDepot = await getDepots();
                const dataDepotCheck = getDepotsCheck(dataDepot);
                if (dataDepot.length && dataDepotCheck !== true) {
                    console.error(dataDepotCheck);
                    Sentry.captureException(dataDepotCheck);
                    navigate('/error');
                    return;
                }

                setDepotList(dataDepot);

                const rvmownerData = await gearApi.rvmowner.getRvmowners();
                setRvmownerData(rvmownerData);

                const distributorData = await getDistributors();
                setDistributorList(distributorData);

                const storeownerData = await getStoreowners();
                setStoreownerList(storeownerData);
            } catch (error) {
                console.error('Erreur lors de la récupération des infos du magasin', error);
            }
        };
        fetchDepotData();
    }, [id, navigate]);

    console.log(storeData);

    const [formErrors, setFormErrors] = useState({
        company_name: false,
        name: false,
        address_1: false,
        address_2: false,
        city: false,
        zipcode: false,
        contact_name: false,
        contact_phone: false,
        contact_email: false,
        depot_id: false,
        rvmowner_id: false,
        distributor_id: false,
        storeowner_id: false,
        type: false,
        owner: false,
    });

    const requiredFields = [
        'company_name',
        'name',
        'address_1',
        'city',
        'zipcode',
        'contact_name',
        'contact_phone',
        'contact_email',
        'rvmowner_id',
        'distributor_id',
        'depot_id',
        'type',
        'storeowner_id',
        'owner',
    ];

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        console.log(name, value);
        setStoreData({
            ...storeData,
            [name]: value,
        });
    };

    const handleSaveChanges = async (e) => {
        e.preventDefault();
        setErrorMessage([]);
        setErrorEmpty('');
        const inputEmpty = checkIsInputEmpty(requiredFields, storeData, setFormErrors, setErrorEmpty);
        if (!inputEmpty) {
            const checkFormat = checkStoreFormErrors(storeData, formErrors, setFormErrors, setErrorMessage);
            if (checkFormat) {
                return;
            } else {
                const updateStoreData = await updateStore(id, storeData);
                if (updateStoreData) {
                    alert('Le magasin a été modifié avec succès');
                    navigate(-1);
                } else {
                    alert('une erreur est survenue lors de la modification du magasin');
                }
            }
        } else {
            console.log('Il reste des champs obligatoires vides');
        }
    };
    return (
        <main>
            <section className="big-section">
                <h2>Modifier un magasin</h2>
                {storeData.id ? (
                    <div className="form-container">
                        <section>
                            <div>
                                <label>Société Mère:</label>
                                <input
                                    className={formErrors.company_name ? 'error' : ''}
                                    type="text"
                                    name="company_name"
                                    value={storeData.company_name || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <label>Nom du magasin:</label>
                                <input
                                    className={formErrors.name ? 'error' : ''}
                                    type="text"
                                    name="name"
                                    value={storeData.name || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <label className="small-title">Type propriétaire :</label>
                                <select
                                    name="owner"
                                    value={storeData.owner || ''}
                                    className={formErrors.owner ? 'error' : ''}
                                    onChange={handleInputChange}>
                                    <option value="">--</option>
                                    {storeOwnerTab.map((type, index) => (
                                        <option
                                            key={index}
                                            value={parseInt(type)}>
                                            {_defineStoreOwner(type)}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label className="small-title">Type :</label>
                                <select
                                    name="type"
                                    className={formErrors.type ? 'error' : ''}
                                    onChange={handleInputChange}
                                    value={storeData.type || ''}>
                                    <option value="">--</option>
                                    {storeTypeTab.map((type, index) => (
                                        <option
                                            key={index}
                                            value={parseInt(type)}>
                                            {_defineStoreType(type)}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label>Centre de Collecte associé:</label>
                                <select
                                    value={storeData.depot_id || ''}
                                    className={formErrors.entity_sorter_id ? 'error' : ''}
                                    name="depot_id"
                                    onChange={handleInputChange}>
                                    {depotList.map((option, index) => (
                                        <option
                                            key={index}
                                            value={option.id}>
                                            {option.company_name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label>Distributeur associé:</label>
                                <select
                                    value={storeData.distributor_id || ''}
                                    className={formErrors.distributor_id ? 'error' : ''}
                                    name="distributor_id"
                                    onChange={handleInputChange}>
                                    {distributorList.map((option, index) => (
                                        <option
                                            key={index}
                                            value={option.id}>
                                            {option.company_name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label>Gestionnaire de RVM associé:</label>
                                <select
                                    value={storeData.rvmowner_id || ''}
                                    className={formErrors.rvmowner_id ? 'error' : ''}
                                    name="rvmowner_id"
                                    onChange={handleInputChange}>
                                    {!storeData.rvmowner_id ? (
                                        <option
                                            key={-1}
                                            value={-1}>
                                            Selectionner
                                        </option>
                                    ) : null}
                                    {rvmownerData.map((option, index) => (
                                        <option
                                            key={index}
                                            value={option.id}>
                                            {option.company_name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label>Gestionnaire de magasin associé:</label>
                                <select
                                    value={storeData.storeowner_id || ''}
                                    className={formErrors.storeowner_id ? 'error' : ''}
                                    name="storeowner_id"
                                    onChange={handleInputChange}>
                                    {storeownerList.map((option, index) => (
                                        <option
                                            key={index}
                                            value={option.id}>
                                            {option.company_name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </section>
                        <section>
                            <AddressSearch
                                formData={storeData}
                                setFormData={setStoreData}
                                formErrors={formErrors}
                                setFormErrors={setFormErrors}
                                handleChange={handleInputChange}
                            />
                        </section>

                        <section>
                            <div>
                                <label>Nom du contact:</label>
                                <input
                                    className={formErrors.contact_name ? 'error' : ''}
                                    type="text"
                                    name="contact_name"
                                    value={storeData.contact_name || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <label>Téléphone du contact:</label>
                                <input
                                    className={formErrors.contact_phone ? 'error' : ''}
                                    type="number"
                                    name="contact_phone"
                                    value={storeData.contact_phone || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <label>Email du contact:</label>
                                <input
                                    className={formErrors.contact_email ? 'error' : ''}
                                    type="email"
                                    name="contact_email"
                                    value={storeData.contact_email || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <label>Statut:</label>
                                <select
                                    style={{ backgroundColor: _defineStatus(storeData.status).color, color: Constant.$blanc }}
                                    name="status"
                                    value={storeData.status}
                                    onChange={handleInputChange}>
                                    <option value={0}>Actif</option>
                                    <option value={100}>Inactif</option>
                                </select>
                            </div>
                        </section>
                    </div>
                ) : null}
                <p className="error-form">{errorEmpty && errorEmpty}</p>
                {errorMessage.length
                    ? errorMessage.map((item, index) => {
                          return (
                              <div
                                  className="error-form"
                                  key={index}>
                                  {item}
                              </div>
                          );
                      })
                    : null}
                <div className="button-confirm">
                    <button
                        className="button-actif"
                        onClick={handleSaveChanges}>
                        Enregistrer les modifications
                    </button>
                </div>
            </section>
        </main>
    );
}
