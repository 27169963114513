import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getInfoUser } from '../../API/AccountApi';
import { getUserInfoCheck } from '../../API/CheckApi/CheckAccountApi';
import * as Sentry from '@sentry/react';
import * as Constants from '../../Constants';
import { transformString } from '../../Utils';

import { AccessServiceprovider } from '../../access/AccessServiceprovider';
import { AccessIndustrial } from '../../access/AccessIndustrial';
import { AccessSorter } from '../../access/AccessSorter';
import { AccessWasher } from '../../access/AccessWasher';
import { AccessOperator } from '../../access/AccessOperator';
import { AccessRvmowner } from '../../access/AccessRvmowner';
import { AccessStoreowner } from '../../access/AccessStoreowner';
import { AccessCdc } from '../../access/AccessCdc';
import { AccessDistributor } from '../../access/AccessDistributor';

export default function ProfilAccess() {
    const [userInfo, setUserInfo] = useState({});
    const navigate = useNavigate();
    const [interfaceAccess, setInterfaceAccess] = useState(null);

    const chooseAccessInterface = (entity_type) => {
        let access;
        switch (parseInt(entity_type)) {
            case Constants.ENTITY_TYPE_FS:
                access = AccessServiceprovider;
                break;
            case Constants.ENTITY_TYPE_INDUSTRIAL:
                access = AccessIndustrial;
                break;
            case Constants.ENTITY_TYPE_SORTER:
                access = AccessSorter;
                break;
            case Constants.ENTITY_TYPE_WASHER:
                access = AccessWasher;
                break;
            case Constants.ENTITY_TYPE_OPERATOR:
                access = AccessOperator;
                break;
            case Constants.ENTITY_TYPE_RVMOWNER:
                access = AccessRvmowner;
                break;
            case Constants.ENTITY_TYPE_STOREOWNER:
                access = AccessStoreowner;
                break;
            case Constants.ENTITY_TYPE_CDC:
                access = AccessCdc;
                break;
            case Constants.ENTITY_TYPE_DISTRIBUTOR:
                access = AccessDistributor;
                break;
            default:
                console.log("setAccessInterface: entity type doesn't exist");
        }
        return access;
    };

    useEffect(() => {
        const fetchUserInfo = async () => {
            const data = await getInfoUser();
            const dataCheck = getUserInfoCheck(data);
            if (data.length && dataCheck !== true) {
                console.error(dataCheck);
                Sentry.captureException(dataCheck);
                navigate('/error');
                return;
            }
            setInterfaceAccess(chooseAccessInterface(data[0].entity.type));
            setUserInfo(data[0]);
            console.log(data[0]);
        };
        fetchUserInfo();
    }, [navigate]);

    const displayAccess = () => {
        let userInfoAccess = null;
        if (userInfo && userInfo.access) {
            userInfoAccess = JSON.parse(userInfo.access);
        }
        return (
            <div className="access-container">
                {interfaceAccess
                    ? Object.keys(interfaceAccess).map((interfaceName, key) => {
                          return (
                              <div
                                  key={key}
                                  style={{ display: 'flex', width: '100%', gap: '1rem' }}>
                                  {Object.keys(interfaceAccess[interfaceName]).map((module, key) => {
                                      return (
                                          <div
                                              className="small-section"
                                              key={key}>
                                              <h1>{transformString(module)}</h1>
                                              {Object.keys(interfaceAccess[interfaceName][module]).map((menu, key) => {
                                                  return (
                                                      <div
                                                          className="access-menu"
                                                          style={{ display: 'flex', flexDirection: 'column' }}
                                                          key={key}>
                                                          <p className="menu-title">{transformString(menu)}</p>
                                                          {Object.keys(interfaceAccess[interfaceName][module][menu]).map((submenu, key) => {
                                                              return (
                                                                  <div
                                                                      className="access-submenu"
                                                                      style={{ display: 'flex', justifyContent: 'space-around' }}
                                                                      key={key}>
                                                                      <p style={{ flex: 0.2, textAlign: 'right' }}>&#8226;</p>
                                                                      <p style={{ flex: 1, paddingLeft: '0.5rem' }}>{transformString(submenu)}</p>
                                                                      <input
                                                                          type="checkbox"
                                                                          name={submenu}
                                                                          checked={userInfoAccess ? userInfoAccess[interfaceName][module][menu][submenu] : 0}
                                                                          disabled={true}
                                                                      />
                                                                  </div>
                                                              );
                                                          })}
                                                      </div>
                                                  );
                                              })}
                                          </div>
                                      );
                                  })}
                              </div>
                          );
                      })
                    : null}
            </div>
        );
    };

    return (
        <main>
            <div
                className="sheet"
                style={{ marginBottom: '1rem' }}>
                <div className="sheet-top-infos">
                    <div
                        className="title "
                        style={{ marginBottom: '0' }}>
                        <h2>Mes accès</h2>
                    </div>
                </div>
                {userInfo ? <div style={{ display: 'flex', width: '90%' }}>{displayAccess()}</div> : null}
            </div>
        </main>
    );
}
