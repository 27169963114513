import React, { useState } from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { Jwt } from '../../Jwt';
import Footer from '../../containers/Footer';
import { flatten } from 'flat';
import Logo from '../../Assets/images/Logo-GEAR-by-Bocoloco.png';

// TODO: Recfactor the login functionality
const Login = ({ setUserContext }) => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorLogin, setErrorLogin] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        const { getGearAPIApiEndpoint } = await import('../../API/endpoints');
        const { GearApi } = await import('../../GearApi/GearApi');
        const gearApi = new GearApi({ BASE: getGearAPIApiEndpoint() });
        await gearApi.auth
            .createTokens({
                requestBody: {
                    email: email,
                    password: password,
                },
            })
            .then((data) => _callbackLoginSUCCESS(data))
            .catch((error) => _callbackLoginERROR(error));
    };

    const _callbackLoginSUCCESS = async (data) => {
        if (data && data.token) {
            console.log(data);
            let role = data.role;
            let access = data.access;
            if (checkRoleAccessValid(role, access)) {
                const token = data.token;
                const role = data.role;
                const access = data.access;

                const jwt = new Jwt(data.token);

                Cookies.set('userToken', token, {
                    expires: jwt.expiresAt,
                    secure: import.meta.env.PROD,
                    sameSite: 'strict',
                });

                Cookies.set('role', role, {
                    expires: jwt.expiresAt,
                    secure: import.meta.env.PROD,
                    sameSite: 'strict',
                });
                Cookies.set('access', access, {
                    expires: jwt.expiresAt,
                    secure: import.meta.env.PROD,
                    sameSite: 'strict',
                });
                setUserContext(jwt);
                navigate('/prehome');
            } else {
                let ErrorMsg = "Vous n'avez pas les droits d'accès nécessaires";
                console.log(ErrorMsg);
                setErrorLogin(ErrorMsg);
            }
        } else {
            console.log(data.error);
            setErrorLogin(data.error);
        }
    };

    const _callbackLoginERROR = (error) => {
        console.log(error.message);
        if (error.message === 'Authentication missing') {
            setErrorLogin('Adresse mail ou mot de passe incorrect');
        } else {
            setErrorLogin(error.message);
        }
    };

    const checkRoleAccessValid = (role, access) => {
        let res = 0;
        //        if (role && role.length > 0) {
        if (role && role.length > 0 && access && access.length > 0) {
            let access_parsed = JSON.parse(access);
            //check
            let interfaceName = Object.keys(access_parsed)[0];
            res = res + checkOperationAccessValid(access_parsed[interfaceName]['operation']);
            res = res + checkDataAccessValid(access_parsed[interfaceName]['data']);
            res = res + checkFacturationAccessValid(access_parsed[interfaceName]['financial']);
            if (res > 0) {
                return true;
            }
        }
        return false;
    };

    const checkOperationAccessValid = (operation) => {
        let res = 0;
        let flat = flatten(operation);
        for (let key in flat) {
            if (flat[key] === 1) {
                res = res + 1;
            }
        }
        return res;
    };

    const checkDataAccessValid = (data) => {
        let res = 0;
        let flat = flatten(data);
        for (let key in flat) {
            if (flat[key] === 1) {
                res = res + 1;
            }
        }
        return res;
    };

    const checkFacturationAccessValid = (facturation) => {
        let res = 0;
        let flat = flatten(facturation);
        for (let key in flat) {
            if (flat[key] === 1) {
                res = res + 1;
            }
        }
        return res;
    };

    const token = Cookies.get('userToken');
    if (!token) {
        return (
            <main className="login-container">
                <div className="form-container">
                    <div className="logo-container">
                        <img
                            src={Logo}
                            alt="Logo"
                        />
                        <p style={{ textAlign: 'center' }}>La plateforme de gestion du Réemploi</p>
                    </div>
                    <form
                        className="login-form"
                        onSubmit={handleSubmit}>
                        <h2>Connexion</h2>
                        <input
                            value={email}
                            type="text"
                            placeholder="Email"
                            onChange={(event) => {
                                setEmail(event.target.value);
                            }}
                        />
                        <input
                            value={password}
                            type="password"
                            placeholder="Mot de passe"
                            onChange={(event) => {
                                setPassword(event.target.value);
                            }}
                        />
                        <button
                            className="button-actif"
                            type="submit">
                            Valider
                        </button>
                        <a
                            href={'/passwordforgotten'}
                            style={{ fontSize: 12, fontStyle: 'italic' }}>
                            Mot de passe oublié ?
                        </a>
                    </form>
                    {errorLogin ? (
                        <div className="login-error">
                            <p>Erreur de connexion : </p>
                            <p>{errorLogin}</p>
                        </div>
                    ) : (
                        <div className="login-error"></div>
                    )}
                </div>
                <Footer />
            </main>
        );
    } else {
        return <></>;
    }
};

export default Login;
