import { useEffect, useState } from 'react';
import PieChart from '../../../Components/PieChart';
import { getStoreByRvmownerId } from '../../../API/StoreApi';
import { getCollectorsByRvmowner } from '../../../API/CollectorApi';
import { getStoreByRvmownerIdCheck } from '../../../API/CheckApi/CheckStoreApi';
import { getReturnByCollectorIdCheck } from '../../../API/CheckApi/CheckCollectorReturnApi';
import { getCollectorsByRvmownerCheck } from '../../../API/CheckApi/CheckCollectorApi';
import { getReturnByCollectorId } from '../../../API/CollectorReturnApi';
import { Jwt } from '../../../Jwt';
import { reduceDataByKeyAndQty } from '../../../UtilsData';
import * as Sentry from '@sentry/react';
import { useNavigate } from 'react-router-dom';

export default function RvmOwnerHome() {
    const navigate = useNavigate();
    const userContext = new Jwt();
    const [totalStore, setTotalStore] = useState(0);
    const [totalCollector, setTotalCollector] = useState(0);
    const [collectorReturnsSum, setCollectorReturnsSum] = useState([]);
    const [returnByCollector, setReturnByCollector] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const stores = await getStoreByRvmownerId(userContext.entityId);
            const storesCheck = getStoreByRvmownerIdCheck(stores);
            if (stores.length && storesCheck !== true) {
                console.error(storesCheck);
                Sentry.captureException(storesCheck);
                navigate('/error');
                return;
            }

            setTotalStore(stores.length);
            const collectors = await getCollectorsByRvmowner(userContext.entityId);
            const collectorsCheck = getCollectorsByRvmownerCheck(collectors);
            if (collectors.length && collectorsCheck !== true) {
                console.error(collectorsCheck);
                Sentry.captureException(collectorsCheck);
                navigate('/error');
                return;
            }
            setTotalCollector(collectors.length);
            let collectorReturnTab = [];
            for (let i = 0; i < collectors.length; i++) {
                const collectorReturn = await getReturnByCollectorId(collectors[i].id);
                const collectorReturnCheck = getReturnByCollectorIdCheck(collectorReturn);
                if (collectorReturn.length && collectorReturnCheck !== true) {
                    console.error(collectorReturnCheck);
                    Sentry.captureException(collectorReturnCheck);
                    navigate('/error');
                    return;
                }
                collectorReturnTab.push(collectorReturn);
            }
            const tableauFusionne = collectorReturnTab.flat();
            let sum = 0;
            for (let i = 0; i < tableauFusionne.length; i++) {
                tableauFusionne[i].name = tableauFusionne[i].collector.store.name + ' - ' + tableauFusionne[i].collector.id;
                sum += tableauFusionne[i].qty;
            }
            setCollectorReturnsSum(sum);

            const countedReturnByCollector = reduceDataByKeyAndQty(tableauFusionne, 'name');
            const returnByCollectorRaw = Object.keys(countedReturnByCollector).map((item) => ({
                collector: item,
                qty: countedReturnByCollector[item],
            }));
            setReturnByCollector(returnByCollectorRaw);
        };
        fetchData();
    }, [userContext.entityId, navigate]);

    const returnByCollectorLabels = returnByCollector.map((item) => item.collector);
    const returnByCollectorQuantities = returnByCollector.map((item) => item.qty);
    return (
        <main>
            <div style={{ width: '90%', margin: '3rem' }}>
                <div style={{ marginBottom: '2rem', display: 'flex', width: '100%', gap: '1%' }}>
                    <div style={{ flex: 3, flexDirection: 'row', display: 'flex' }}>
                        <section
                            className="section-graph"
                            style={{ flex: 1, display: 'flex', flexDirection: 'column', marginRight: 10 }}>
                            <div
                                className="title"
                                style={{ fontWeight: 'bold', height: '1rem' }}>
                                Magasins
                            </div>
                            <div
                                style={{ height: '1rem' }}
                                className="title">
                                Nombre de magasins en gestion :
                            </div>
                            <div
                                style={{ height: '1rem', fontSize: 25 }}
                                className="title">
                                {totalStore}
                            </div>
                        </section>
                        <section
                            className="section-graph"
                            style={{ flex: 1, display: 'flex', flexDirection: 'column', marginRight: 10 }}>
                            <div
                                className="title"
                                style={{ fontWeight: 'bold', height: '1rem' }}>
                                Collecteurs :
                            </div>
                            <div
                                style={{ height: '1rem' }}
                                className="title">
                                Nombre de collecteurs :
                            </div>
                            <div
                                style={{ height: '1rem', fontSize: 25 }}
                                className="title">
                                {totalCollector}
                            </div>
                        </section>
                        <section
                            className="section-graph"
                            style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                            <div
                                className="title"
                                style={{ fontWeight: 'bold', height: '1rem' }}>
                                Collecte :
                            </div>
                            <div
                                style={{ height: '1rem' }}
                                className="title">
                                Nombre de contenants collecté :
                            </div>
                            <div
                                style={{ height: '1rem', fontSize: 25 }}
                                className="title">
                                {collectorReturnsSum}
                            </div>
                        </section>
                    </div>
                </div>
                <div style={{ flex: 2 }}>
                    <section
                        className="section-graph"
                        style={{ height: '100%' }}>
                        <div
                            className="title"
                            style={{ height: '1rem' }}>
                            Répartition des contenants retournés par RVM :
                        </div>
                        <div style={{ height: '90%' }}>
                            {returnByCollector.length > 0 ? (
                                <PieChart
                                    title={returnByCollectorLabels}
                                    quantities={returnByCollectorQuantities}
                                />
                            ) : (
                                <div className="no-data-graph">Aucune donnée.</div>
                            )}
                        </div>
                    </section>
                </div>
            </div>
        </main>
    );
}
