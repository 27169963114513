import React, { useState, useRef } from 'react';
import { useEffect } from 'react';
import { format } from 'date-fns';
import { _defineDeclarationDetailsStatus } from '../../../Utils';
import * as Constant from '../../../Constants';
import { getWasherDeclarationDetailsAttributedByEntityId, updateWasherDeclarationDetailsValidation } from '../../../API/WasherDeclarationApi';
import { getWasherDeclarationDetailsAttributedByEntityIdCheck } from '../../../API/CheckApi/CheckWasherDeclarationApi';
import * as Sentry from '@sentry/react';
import { useNavigate } from 'react-router-dom';
import { Jwt } from '../../../Jwt';
export default function WasherOrder() {
    const navigate = useNavigate();
    const userContext = new Jwt();
    const [showArchives, setShowArchives] = useState(false);
    const [archivesTab, setArchivesTab] = useState([]);
    const [orderList, setOrderList] = useState([]);
    const [modalData, setModalData] = useState([]);

    const dialogRef = useRef(null);

    useEffect(() => {
        const fetchData = async () => {
            const result = await getWasherDeclarationDetailsAttributedByEntityId(userContext.entityId);
            const resultCheck = getWasherDeclarationDetailsAttributedByEntityIdCheck(result);
            if (result.length && resultCheck !== true) {
                console.error(resultCheck);
                Sentry.captureException(resultCheck);
                navigate('/error');
                return;
            }

            const finalTab = groupedTab(result);
            const currentOrders = finalTab
                .filter((item) => item.collecte_status !== Constant.EVENT_RECEIVED)
                .sort((a, b) => new Date(b.time) - new Date(a.time));
            setOrderList(currentOrders);

            const archiveTabData = finalTab.filter((item) => item.status === Constant.EVENT_RECEIVED).sort((a, b) => new Date(b.time) - new Date(a.time));
            setArchivesTab(archiveTabData);
        };
        fetchData();
    }, [userContext.entityId, navigate]);

    const groupedTab = (data) => {
        const groupedData = data.reduce((acc, obj) => {
            if (acc[obj.orderDetailId]) {
                acc[obj.orderDetailId].qty += obj.qty;
            } else {
                acc[obj.orderDetailId] = {
                    washerDeclarationId: obj.washerDeclarationId,
                    orderDetailId: obj.orderDetailId,
                    qty: obj.qty,
                    sku: obj.sku,
                    suiviId: obj.suiviId,
                    company_name: obj.company_name,
                    status: obj.status,
                    dated: obj.dated,
                    collecte_status: obj.suivi.washerEvents[0].status,
                    address_name: obj.orderDetail.entityAddress.name,
                    address_1: obj.orderDetail.entityAddress.address_1,
                    address_zipcode: obj.orderDetail.entityAddress.zipcode,
                    city: obj.orderDetail.entityAddress.city,
                };
            }
            return acc;
        }, {});

        return Object.values(groupedData);
    };

    const handleModalOpen = (item) => {
        setModalData(item);
        if (dialogRef.current) {
            dialogRef.current.showModal();
        }
    };

    const handleModalClose = () => {
        setModalData([]);

        if (dialogRef.current) {
            dialogRef.current.close();
        }
    };

    const sendDeclarationValidation = async () => {
        try {
            const data = await updateWasherDeclarationDetailsValidation(modalData);
            if (data.message === 'ok') {
                alert('Prise en compte validée.');
                handleModalClose();
                window.location.reload();
            } else {
                alert('Une erreur est survenue');
            }
        } catch (err) {
            console.log(err);
        }
    };
    return (
        <main>
            <section className="big-section">
                <div className="top top-history">
                    <h2>Bons de commande</h2>
                    <div className="sort">
                        <p
                            onClick={() => {
                                setShowArchives(false);
                            }}
                            className={!showArchives ? 'selected' : 'classic'}>
                            En cours
                        </p>
                        <p
                            onClick={() => {
                                setShowArchives(true);
                            }}
                            className={showArchives ? 'selected' : 'classic'}>
                            Archivées
                        </p>
                    </div>
                </div>
                {!showArchives ? (
                    <div className="tab-item">
                        <div
                            className="pre-filled-fields-1-several-item"
                            style={{ fontWeight: '600', width: '100%' }}>
                            <p>Commande ID</p>
                            <p>Marque</p>
                            <p>Date de livraison</p>
                            <p>SKU concerné</p>
                            <p>
                                Quantité <span style={{ fontWeight: 400 }}>(en palettes)</span>
                            </p>
                            <p>Statut</p>
                        </div>
                        {orderList.length ? (
                            orderList.map((item, index) => {
                                return (
                                    <div
                                        onClick={() => {
                                            if (
                                                item.status === Constant.DECLARATION_DETAILS_ATTRIBUTED ||
                                                item.status === Constant.DECLARATION_DETAILS_VALIDATED
                                            ) {
                                                handleModalOpen(item);
                                            }
                                        }}
                                        key={index}
                                        style={{ display: 'flex', flexWrap: 'wrap', width: '100%', justifyContent: 'space-between', cursor: 'pointer' }}>
                                        <div
                                            className="pre-filled-fields-2-several-item"
                                            key={item.id}
                                            style={{ width: '100%' }}>
                                            <p>{item.orderDetailId}</p>
                                            <p>{item.company_name}</p>
                                            <p>{format(item.dated, 'yyyy-MM-dd HH:mm:ss')}</p>
                                            <p>{item.sku}</p>
                                            <p>{item.qty}</p>

                                            <p
                                                style={{
                                                    backgroundColor: _defineDeclarationDetailsStatus(item.status).color,
                                                    borderTopRightRadius: '0.6rem',
                                                    borderBottomRightRadius: '0.6rem',
                                                    color: Constant.$blanc,
                                                }}>
                                                {_defineDeclarationDetailsStatus(item.status).name}
                                            </p>
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <div className="no-data-graph ">Aucune commande en cours.</div>
                        )}
                    </div>
                ) : (
                    <div className="tab-item">
                        <div
                            className="pre-filled-fields-1-several-item"
                            style={{ fontWeight: '600', width: '100%' }}>
                            <p>Commande ID</p>
                            <p>Marque</p>
                            <p>Date de livraison</p>
                            <p>SKU</p>
                            <p>Quantité (en palettes)</p>
                        </div>
                        {archivesTab.length ? (
                            archivesTab.map((item, index) => {
                                return (
                                    <div
                                        style={{ display: 'flex', flexWrap: 'wrap', width: '100%', justifyContent: 'space-between' }}
                                        key={index}>
                                        <div
                                            style={{ width: '100%' }}
                                            className="pre-filled-fields-2-several-item">
                                            <p>{item.orderDetailId}</p>
                                            <p>{item.company_name}</p>
                                            <p>{format(item.dated, 'yyyy-MM-dd HH:mm:ss')}</p>
                                            <p>{item.sku}</p>
                                            <p>{item.qty}</p>
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <div className="no-data-graph ">Aucune commande archivée.</div>
                        )}
                    </div>
                )}
            </section>
            <dialog
                ref={dialogRef}
                className="dialog-collector">
                <div className="title">
                    {modalData.status === Constant.DECLARATION_DETAILS_ATTRIBUTED ? <p>Confirmer la prise en compte</p> : <p>Details de la commande</p>}
                    <button
                        className="button-negatif"
                        onClick={handleModalClose}>
                        X
                    </button>
                </div>
                {modalData ? (
                    <div className="collector-infos">
                        {modalData.status === Constant.DECLARATION_DETAILS_ATTRIBUTED ? (
                            <p style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}> Vous confirmez la commande de :</p>
                        ) : (
                            <p style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}> Commande en provenance de :</p>
                        )}
                        <span>{modalData.company_name}</span>
                        <p style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}>Concernant :</p>
                        <span>
                            {modalData.qty} palettes de {modalData.sku}
                        </span>
                        <p style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}>A livrer avant le :</p>
                        <span>{modalData.dated && format(modalData.dated, 'yyyy-MM-dd')}</span>
                        <p style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}>A l&apos;adresse :</p>
                        <span>
                            {modalData.address_name} - {modalData.address_1}, {modalData.address_zipcode} - {modalData.city}
                        </span>
                    </div>
                ) : null}
                {modalData.status === Constant.DECLARATION_DETAILS_ATTRIBUTED && (
                    <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '2rem' }}>
                        <button
                            className="button-negatif"
                            onClick={handleModalClose}>
                            ANNULER
                        </button>
                        <button
                            onClick={sendDeclarationValidation}
                            className="button-actif">
                            VALIDER
                        </button>
                    </div>
                )}
            </dialog>
        </main>
    );
}
