import { useState, useEffect } from 'react';
import { getCollectorById, updateCollector } from '../../../../API/CollectorApi';
import { getCollectorByIdcheck } from '../../../../API/CheckApi/CheckCollectorApi';
import { _defineStatus } from '../../../../Utils';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
export default function UpdateRvm() {
    const location = useLocation();
    const { id } = location.state || {};
    const [collectorData, setCollectorData] = useState([]);
    const [allowModif, setAllowModif] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCollectorData = async () => {
            try {
                const collectorData = await getCollectorById(id);
                const collectorDataCheck = getCollectorByIdcheck(collectorData);
                if (collectorData.length && collectorDataCheck !== true) {
                    console.error(collectorDataCheck);
                    Sentry.captureException(collectorDataCheck);
                    navigate('/error');
                    return;
                }
                console.log(collectorData);
                setCollectorData(collectorData[0]);
            } catch (error) {
                console.error('Erreur lors de la récupération des infos du collecteur', error);
            }
        };
        fetchCollectorData();
    }, [id, navigate]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCollectorData({
            ...collectorData,
            [name]: value,
        });
    };

    const handleSaveChanges = async () => {
        try {
            if (!id) {
                console.error('ID de la marque non valide');
                return;
            }
            await updateCollector(id, collectorData);
            console.log('Modifications enregistrées avec succès');
            alert('Modifications enregistrées avec succès');
            navigate(-1);
        } catch (error) {
            console.error("Erreur lors de l'enregistrement des modifications", error);
        }
        setAllowModif(false);
    };
    return (
        <main>
            <section className="small-section">
                <h2>Modifier un collecteur</h2>
                {collectorData.id ? (
                    <div className="form-container">
                        <section style={{ width: '100%' }}>
                            <div>
                                <label>Nom du magasin:</label>
                                <input
                                    disabled={!allowModif ? true : false}
                                    type="text"
                                    name="store_name"
                                    value={collectorData.store_name || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <label>Type:</label>
                                <input
                                    disabled={!allowModif ? true : false}
                                    type="text"
                                    name="type"
                                    value={collectorData.type || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <label>Capacity:</label>
                                <input
                                    disabled={!allowModif ? true : false}
                                    type="text"
                                    name="capacity"
                                    value={collectorData.capacity || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <label>Device ID:</label>
                                <input
                                    disabled={!allowModif ? true : false}
                                    type="text"
                                    name="device_id"
                                    value={collectorData.device_id || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <label>Statut:</label>
                                <select
                                    style={{ backgroundColor: _defineStatus(collectorData.status).color, color: 'white' }}
                                    disabled={!allowModif ? true : false}
                                    name="status"
                                    value={collectorData.status}
                                    onChange={handleInputChange}>
                                    <option value={0}>Actif</option>
                                    <option value={100}>Inactif</option>
                                </select>
                            </div>
                        </section>
                    </div>
                ) : null}
                <div className="button-confirm">
                    {!allowModif ? (
                        <button
                            className="button-actif"
                            onClick={() => {
                                setAllowModif(!allowModif);
                            }}>
                            Modifier les informations
                        </button>
                    ) : (
                        <button
                            className="button-actif"
                            onClick={handleSaveChanges}>
                            Enregistrer les modifications
                        </button>
                    )}
                </div>
            </section>
        </main>
    );
}
