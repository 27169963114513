import { useState, useEffect } from 'react';
import { getInfoUser } from '../API/AccountApi';
import { Link } from 'react-router-dom';
import { Book, Question, House, UserCircle, SignOut, CaretRight } from '@phosphor-icons/react';
import { _defineClientType } from '../Utils';
import * as Constant from '../Constants';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { getUserInfoCheck } from '../API/CheckApi/CheckAccountApi';
import * as Sentry from '@sentry/react';
export default function Header({ userContext, setUserContext }) {
    const [userInfo, setUserInfo] = useState({});
    const [showMyAccount, setShowMyAccount] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserInfo = async () => {
            const data = await getInfoUser();
            const dataCheck = getUserInfoCheck(data);
            if (data.length && dataCheck !== true) {
                console.error(dataCheck);
                Sentry.captureException(dataCheck);
                navigate('/error');
                return;
            }
            setUserInfo(data[0]);
        };
        fetchUserInfo();
    }, [navigate]);

    const handleDisconnect = () => {
        localStorage.removeItem('module');
        Cookies.remove('userToken');
        setUserContext(null);
        navigate('/login');
    };

    const handleAccountPress = (type) => {
        setShowMyAccount(false);
        switch (type) {
            case Constant.ENTITY_TYPE_FS:
                navigate('/servicesupplier/myprofil/personal_info');
                return;
            case Constant.ENTITY_TYPE_INDUSTRIAL:
                navigate('/industrial/myprofil/personal_info');
                return;
            case Constant.ENTITY_TYPE_SORTER:
                navigate('/sorter/myprofil/personal_info');
                return;
            case Constant.ENTITY_TYPE_WASHER:
                navigate('/washer/myprofil/personal_info');
                return;
            case Constant.ENTITY_TYPE_OPERATOR:
                navigate('/collecte/myprofil/personal_info');
                return;
            case Constant.ENTITY_TYPE_RVMOWNER:
                navigate('/rvmowner/myprofil/personal_info');
                return;
            case Constant.ENTITY_TYPE_STOREOWNER:
                navigate('/storeowner/myprofil/personal_info');
                return;
            case Constant.ENTITY_TYPE_CDC:
                navigate('/cdc/myprofil/personal_info');
                return;
            case Constant.ENTITY_TYPE_DISTRIBUTOR:
                navigate('/distributor/myprofil/personal_info');
                return;
            default:
                alert('Profil non disponible pour votre type de compte');
                return;
        }
    };

    return userContext.valid ? (
        <header className={'header header-classique'}>
            <Link
                to={'/prehome'}
                className="logo-container">
                {/* <img
                    style={{ height: '30%' }}
                    src={!isPreHome ? logoblanc : logo}
                    alt="logo-bocoloco"
                /> */}
            </Link>
            <div>
                <div style={{ backgroundColor: _defineClientType(userContext.entityType).color }}>
                    <p>{_defineClientType(userContext.entityType).name}</p>
                </div>
            </div>
            <div className="icon-container">
                <Link
                    to={'/prehome'}
                    className="icon">
                    <House
                        size="25px"
                        color={Constant.$blanc}
                    />
                    <p style={{ color: Constant.$blanc }}>Accueil</p>
                </Link>
                <Link
                    className="icon"
                    to={'/support'}>
                    <Question
                        size="25px"
                        color={Constant.$blanc}
                    />
                    <p style={{ color: Constant.$blanc }}>Support</p>
                </Link>

                <Link
                    to={'/documentation'}
                    className="icon">
                    <Book
                        size="25px"
                        color={Constant.$blanc}
                    />
                    <p style={{ color: Constant.$blanc }}>API</p>
                </Link>
                <div
                    onClick={() => setShowMyAccount(!showMyAccount)}
                    className="icon account">
                    <UserCircle
                        size="25px"
                        color={Constant.$blanc}
                    />
                    <p>{userInfo && userInfo.entity && userInfo.entity.company_name && userInfo.entity.company_name}</p>
                </div>
            </div>
            {showMyAccount && (
                <div className="account-info tooltip">
                    <div className="userinfo">
                        <div className="user-name">
                            <div
                                onClick={() => handleAccountPress(userContext.entityType)}
                                className="icon"
                                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                                    <p style={{ fontWeight: 'bolder' }}>{userInfo.first_name}</p>

                                    <p>{userInfo.email}</p>
                                </div>
                                <CaretRight size={15} />
                            </div>
                        </div>
                        <div
                            className="button-negatif deco"
                            onClick={() => handleDisconnect()}>
                            <p>Se déconnecter</p>
                            <SignOut
                                size="20px"
                                color={Constant.$blanc}
                            />
                        </div>
                    </div>
                </div>
            )}
        </header>
    ) : null;
}
