import { getGearAPIApiEndpoint } from './endpoints';
import * as Sentry from '@sentry/react';
import { defaultHeaders } from './headers';

const GEAR_API_BASE_URL = getGearAPIApiEndpoint();

// COLLECTOR RETURN
const BOCOLOCO_GET_RETURNBYCOLLECTORID = '/collectorreturn/getbycollectorid';
const BOCOLOCO_GET_RETURN_IN_COLLECTOR = '/collectorreturn/getincollector';
//const BOCOLOCO_GET_ALL_RETURN = '/collectorreturn/getall';
const BOCOLOCO_GET_RETURN_BYDATES = '/collectorreturn/bydates';
const BOCOLOCO_GET_RETURN_BYDATESANDSTOREOWNER = '/collectorreturn/bydatesandstoreowner';
const BOCOLOCO_GET_RETURN_STOREBYDATES = '/collectorreturn/stores/bydates';
const BOCOLOCO_GET_RETURN_COLLECTORBYDATES = '/collectorreturn/collector/bydates';
const BOCOLOCO_GET_RETURN_BYDATESANDCOLLECTORSUPPLIER = '/collectorreturn/bydatesandcollectorsupplier';
const BOCOLOCO_GET_RETURN_ALL_STORES_BYDATES = '/collectorreturn/all/stores/bydates';

export function getReturnByCollectorId(collector_id) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_RETURNBYCOLLECTORID;
    var url_args = '?collector_id=' + collector_id;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function getAllReturnInCollector() {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_RETURN_IN_COLLECTOR;
    const url = encodeURI(url_base);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

/*
export function getAllReturn() {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_ALL_RETURN;
    const url = encodeURI(url_base);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}
        */

export function getReturnByDates(startDate, endDate) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_RETURN_BYDATES;
    var url_args = '?startDate=' + startDate + '&endDate=' + endDate;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function getReturnByDatesAndStoreOwner(startDate, endDate, storeOwnerId) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_RETURN_BYDATESANDSTOREOWNER;
    var url_args = '?startDate=' + startDate + '&endDate=' + endDate + '&storeOwnerId=' + storeOwnerId;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function getReturnStoreByDates(startDate, endDate) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_RETURN_STOREBYDATES;
    var url_args = '?startDate=' + startDate + '&endDate=' + endDate;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function getReturnByDatesAndCollectorSupplier(startDate, endDate, collectorSupplier) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_RETURN_BYDATESANDCOLLECTORSUPPLIER;
    var url_args = '?startDate=' + startDate + '&endDate=' + endDate + '&collectorSupplier=' + collectorSupplier;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function getReturnCollectorByDates(startDate, endDate) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_RETURN_COLLECTORBYDATES;
    var url_args = '?startDate=' + startDate + '&endDate=' + endDate;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function getAllReturnByStoreAndDates(startDate, endDate, storeowner_id) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_RETURN_ALL_STORES_BYDATES;
    var url_args = '?startDate=' + startDate + '&endDate=' + endDate + '&storeowner_id=' + storeowner_id;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}
