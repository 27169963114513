import Cookies from 'js-cookie';
import * as Constants from '../../Constants';

export const rvmownerMenuOperation = () => {
    return ['rvmowner'];
};

const getSubAccessOperationRvmowner = () => {
    let sousMenu = [{ title: 'Rvm' }];
    const access = Cookies.get('access').length ? JSON.parse(Cookies.get('access')) : null;
    if (access) {
        let access_operation = access.rvmowner.operation;
        if (access_operation.rvm.dashboard === 1) {
            sousMenu.push({ name: 'Dashboard', link: '/rvmowner/operation/home' });
        }
        if (access_operation.rvm.liste_magasin === 1) {
            sousMenu.push({ name: 'Liste Magasins', link: '/rvmowner/operation/store/list' });
        }
        if (access_operation.rvm.liste_rvm === 1) {
            sousMenu.push({ name: 'Liste RVMs', link: '/rvmowner/operation/collector/list' });
        }
        if (access_operation.rvm.ajouter_une_rvm === 1) {
            sousMenu.push({ name: 'Ajouter une RVM', link: '/rvmowner/operation/collector/add' });
        }
    }

    return sousMenu;
};

export const getSousMenuRvmownerOperation = (onglet) => {
    switch (onglet) {
        case 'rvmowner':
            return getSubAccessOperationRvmowner();
        default:
            return [];
    }
};

export const rvmownerMenuData = () => {
    return [];
};

export const getSousMenuRvmownerData = (onglet) => {
    switch (onglet) {
        default:
            return [];
    }
};

export const rvmownerMenuFacturation = () => {
    return [];
};

export const getSousMenuRvmownerFacturation = (onglet) => {
    switch (onglet) {
        default:
            return [];
    }
};

export const rvmownerMenuAccount = () => {
    return ['my_account'];
};

export const getSousMenuRvmownerAccount = (onglet) => {
    const role = Cookies.get('role');
    const isAdmin = role === Constants.ENTITY_ROLE_ADMIN;
    switch (onglet) {
        case 'my_account':
            if (isAdmin) {
                return [
                    { title: 'Mon compte' },
                    { name: 'Mes informations personnelles', link: '/rvmowner/myprofil/personal_info' },
                    { name: 'Ma société', link: '/rvmowner/myprofil/company_info' },
                    { name: 'Gestion des comptes', link: '/rvmowner/myprofil/rolemanagement' },
                ];
            } else {
                return [
                    { title: 'Mon compte' },
                    { name: 'Mes informations personnelles', link: '/rvmowner/myprofil/personal_info' },
                    { name: 'Ma société', link: '/rvmowner/myprofil/company_info' },
                ];
            }
        default:
            return [];
    }
};
