import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';

export default function AddWasherSku() {
    const location = useLocation();
    const navigate = useNavigate();
    const { entity_id } = location.state || {};
    const [washerList, setWasherList] = useState([]);
    const [containerList, setContainerList] = useState([]);

    const [formData, setFormData] = useState({
        entity_id: '',
        sku: '',
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        const _getAllWashers = async () => {
            const { gearApi } = await import('../../../../../../gearApi.js');
            const washers = await gearApi.washer.getWashers();

            setWasherList(washers);
        };
        _getAllWashers();
    }, []);

    useEffect(() => {
        const _getFormEntries = async () => {
            // Make sure we actually have a washer list and a proper entity ID
            if (washerList && !isNaN(entity_id)) {
                let washer_list = document.getElementById('optionsSelect');
                washer_list.value = entity_id;
                washer_list.dispatchEvent(new Event('change'));
                setFormData((f) => ({ ...f, entity_id: entity_id }));
                // TODO: Decide if we actually need a list if not simplify the code!
                washer_list.disabled = true;

                const { gearApi } = await import('../../../../../../gearApi.js');
                const freeSkus = await gearApi.washer.getFreeSkusOfWasher({ washerId: entity_id });
                setContainerList(freeSkus);
            }
        };
        _getFormEntries();
    }, [entity_id, washerList]);

    const handleSubmit = async (e) => {
        const { gearApi } = await import('../../../../../../gearApi.js');

        e.preventDefault();
        await gearApi.washer
            .putSkuForWasher({
                washerId: formData.entity_id,
                sku: formData.sku,
            })
            .then(() => {
                alert('Le sku a été créé avec succès');
                navigate(-1);
            })
            .catch((err) => {
                console.error(err);
                Sentry.captureException(err);
            });
    };
    return (
        <main>
            <section className="small-section">
                <h2>Ajouter un SKU à un centre de lavage</h2>
                <div className="form-container">
                    <section style={{ width: '100%' }}>
                        <div>
                            <label>Nom du centre de lavage:</label>
                            <select
                                name="entity_id"
                                id="optionsSelect"
                                onChange={handleChange}>
                                <option>Choisissez une option</option>
                                {washerList.map((option, index) => (
                                    <option
                                        key={index}
                                        value={option.id}>
                                        {option.company_name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label>SKU:</label>
                            <select
                                name="sku"
                                onChange={handleChange}>
                                <option>Choisissez une option</option>
                                {containerList.map((option, index) => (
                                    <option
                                        key={index}
                                        value={option.sku}>
                                        {option.sku}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </section>
                </div>
                <div className="button-confirm">
                    <button
                        className="button-actif"
                        onClick={handleSubmit}>
                        Ajouter le SKU
                    </button>
                </div>
            </section>
        </main>
    );
}
