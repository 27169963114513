import { useState } from 'react';
import { addStore } from '../../../../API/StoreApi';
import { useNavigate, useLocation } from 'react-router-dom';

export default function AddStore() {
    const location = useLocation();

    const { depot_id, depot_name } = location.state || {};

    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        company_name: '',
        name: '',
        address_1: '',
        address_2: '',
        city: '',
        zipcode: '',
        contact_name: '',
        contact_phone: '',
        contact_email: '',
        depot_id: depot_id,
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    console.log(formData);
    const handleSubmit = (e) => {
        e.preventDefault();
        addStore(formData)
            .then((data) => {
                console.log(data);
                alert('Le magasin a été créé avec succès');
                navigate(-1);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    return (
        <main>
            <section className="big-section">
                <h2>Ajouter un magasin</h2>
                <div className="form-container">
                    <section>
                        <div>
                            <label>Centre de Collecte:</label>
                            <input
                                type="text"
                                name="depot_id"
                                value={depot_name}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <label>Société Mère:</label>
                            <input
                                type="text"
                                name="company_name"
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <label>Nom du magasin:</label>
                            <input
                                type="text"
                                name="name"
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </section>
                    <section>
                        <div>
                            <label>Adresse 1:</label>
                            <input
                                type="text"
                                name="address_1"
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <label>Adresse 2:</label>
                            <input
                                type="text"
                                name="address_2"
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <label>Ville:</label>
                            <input
                                type="text"
                                name="city"
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <label>Code postal:</label>
                            <input
                                type="text"
                                name="zipcode"
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </section>
                    <section>
                        <div>
                            <label>Nom du contact:</label>
                            <input
                                type="text"
                                name="contact_name"
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <label>Téléphone du contact:</label>
                            <input
                                type="text"
                                name="contact_phone"
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <label>Email du contact:</label>
                            <input
                                type="text"
                                name="contact_email"
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </section>
                </div>
                <div className="button-confirm">
                    <button
                        className="button-actif"
                        onClick={handleSubmit}>
                        Ajouter le magasin
                    </button>
                </div>
            </section>
        </main>
    );
}
