import { useEffect, useState, useRef, useCallback } from 'react';
import { getSorterDeclarationDetailsLastStatusByEntityId, updateSorterDeclarationDetails } from '../../../../API/SorterDeclarationApi';
import { getWasherLinkBySorterIdAndSku } from '../../../../API/SorterWasherLinkApi';
import { getWasherLinkBySorterIdAndSkuCheck } from '../../../../API/CheckApi/CheckSorterWasherLinkApi';
import { getSorterDeclarationDetailsLastStatusByEntityIdCheck } from '../../../../API/CheckApi/CheckSorterDeclarationApi';
import { getContainers, getContainerBySku } from '../../../../API/ContainerApi';
import { getSorters, sorterPickUprequest } from '../../../../API/SorterApi';
// import { getOperators } from '../../../../API/OperatorApi';
// import { format, addDays } from 'date-fns';
import { _defineContainerPaletType } from '../../../../Utils';
import * as Contants from '../../../../Constants';
import { ArrowDown } from '@phosphor-icons/react';
import { handleArrowClickDown } from '../../../../Utils.js';
import * as Sentry from '@sentry/react';
import { useNavigate } from 'react-router-dom';

export default function FsSorterDashboard() {
    const navigate = useNavigate();
    const [sorterDeclarationTabs, setSorterDeclarationTabs] = useState([]);
    // const [operatorList, setOperatorList] = useState([]);
    const dateActuelle = new Date();
    // const dateMax = addDays(dateActuelle, 5);
    // const dateMaxFormatted = format(dateMax, 'yyyy-MM-dd HH:mm:ss');
    // const [pickUpRequestTime, setPickUpRequestTime] = useState(dateActuelle);
    const [transferData, setTransferData] = useState([]);
    const [qtyMax, setQtyMax] = useState([]);

    const dialogRef = useRef(null);

    const [formData, setFormData] = useState({
        washerId: null,
        sorterId: null,
        operatorId: null,
        qty: 0,
        sku: '',
    });

    const getstockedDeclarationDetails = useCallback(
        async (allSorter) => {
            const declarationDetailsPromises = allSorter.map(async (sorter) => {
                const sorterDeclarationDetails = await getSorterDeclarationDetailsLastStatusByEntityId(sorter.id);
                const sorterDeclarationDetailsCheck = getSorterDeclarationDetailsLastStatusByEntityIdCheck(sorterDeclarationDetails);
                if (sorterDeclarationDetails.length && sorterDeclarationDetailsCheck !== true) {
                    console.error(sorterDeclarationDetailsCheck);
                    Sentry.captureException(sorterDeclarationDetailsCheck);
                    navigate('/error');
                    return;
                }
                return { sorter_id: sorter.id, sorter_name: sorter.company_name, sorterDeclarationDetails };
            });

            const allDeclarationDetails = await Promise.all(declarationDetailsPromises);
            const stockedDeclarationDetails = allDeclarationDetails.map((item) => ({
                ...item,
                sorterDeclarationDetails: item.sorterDeclarationDetails.filter((detail) => detail.status === Contants.DECLARATION_DETAILS_AVAILABLE),
            }));

            return stockedDeclarationDetails;
        },
        [navigate],
    );

    const getDeclarationDetailsInTransit = useCallback(
        async (allSorter) => {
            const declarationDetailsPromises = allSorter.map(async (sorter) => {
                const sorterDeclarationDetails = await getSorterDeclarationDetailsLastStatusByEntityId(sorter.id);
                const sorterDeclarationDetailsCheck = getSorterDeclarationDetailsLastStatusByEntityIdCheck(sorterDeclarationDetails);
                if (sorterDeclarationDetails.length && sorterDeclarationDetailsCheck !== true) {
                    console.error(sorterDeclarationDetailsCheck);
                    Sentry.captureException(sorterDeclarationDetailsCheck);
                    navigate('/error');
                    return;
                }
                return { sorter_id: sorter.id, sorter_name: sorter.company_name, sorterDeclarationDetails };
            });

            const allDeclarationDetails = await Promise.all(declarationDetailsPromises);
            const declarationDetailsInTransit = allDeclarationDetails.map((item) => ({
                ...item,
                sorterDeclarationDetails: item.sorterDeclarationDetails.filter(
                    (detail) => detail.suivi && detail.suivi.sorterEvents && detail.suivi.sorterEvents[0].status !== Contants.EVENT_RECEIVED,
                ),
            }));

            return declarationDetailsInTransit;
        },
        [navigate],
    );
    useEffect(() => {
        const fetchData = async () => {
            const allContainers = await getContainers();
            const allSorter = await getSorters();

            const stockedDeclarationDetails = await getstockedDeclarationDetails(allSorter);
            const declarationDetailsInTransit = await getDeclarationDetailsInTransit(allSorter);
            const mergedData = mergeData(stockedDeclarationDetails, declarationDetailsInTransit, allContainers);

            setSorterDeclarationTabs(mergedData);
        };

        fetchData();
    }, [getstockedDeclarationDetails, getDeclarationDetailsInTransit]);

    function mergeData(filteredData, inTransitData, allContainers) {
        const mergeDetails = (details) => {
            details.forEach((subtab) => {
                allContainers.forEach((skuPrincipal) => {
                    const existeDansSousTab = subtab.sorterDeclarationDetails.some((obj) => obj.sku === skuPrincipal.sku);
                    if (!existeDansSousTab) {
                        subtab.sorterDeclarationDetails.push({ sku: skuPrincipal.sku, qty: 0 });
                    }
                });
            });
        };

        mergeDetails(filteredData);
        mergeDetails(inTransitData);

        return filteredData.map((filteredItem) => {
            const grouped = filteredItem.sorterDeclarationDetails.reduce(
                (acc, curr) => {
                    let existingItemIndex = acc.data.findIndex((item) => item.sku === curr.sku);
                    if (existingItemIndex === -1) {
                        acc.data.push({ sku: curr.sku, qty: curr.qty });
                    } else {
                        acc.data[existingItemIndex].qty += curr.qty;
                    }
                    return acc;
                },
                { sorter_id: filteredItem.sorter_id, sorter_name: filteredItem.sorter_name, data: [] },
            );

            const correspondingTransitItem = inTransitData.find((transitItem) => transitItem.sorter_id === filteredItem.sorter_id);

            const groupedTransit = correspondingTransitItem
                ? correspondingTransitItem.sorterDeclarationDetails.reduce(
                      (acc, curr) => {
                          let existingItemIndex = acc.data.findIndex((item) => item.sku === curr.sku);
                          if (existingItemIndex === -1) {
                              acc.data.push({ sku: curr.sku, qty: curr.qty });
                          } else {
                              acc.data[existingItemIndex].qty += curr.qty;
                          }
                          return acc;
                      },
                      { sorter_id: correspondingTransitItem.sorter_id, sorter_name: correspondingTransitItem.sorter_name, data: [] },
                  )
                : null;

            return {
                sorter_id: filteredItem.sorter_id,
                sorter_name: filteredItem.sorter_name,
                sorterDeclarationDetails: grouped.data,
                declarationDetailsInTransit: groupedTransit ? groupedTransit.data : [],
            };
        });
    }

    // const handlePickUpRequestChange = (event) => {
    //     setPickUpRequestTime(event.target.value);
    // };

    const handleModalOpen = async (sorter_id, item) => {
        const result = await getWasherLinkBySorterIdAndSku(sorter_id, item.sku);
        const resultCheck = getWasherLinkBySorterIdAndSkuCheck(result);
        if (result.length && resultCheck !== true) {
            console.error(resultCheck);
            Sentry.captureException(resultCheck);
            navigate('/error');
            return;
        }
        const hostname = window.location.hostname;
        console.log('hostname', hostname);

        // https://github.com/Bocolocoo/gear/issues/572
        let operator;
        if (hostname === 'localhost') {
            console.log('localhost');
            operator = 81;
        } else if (hostname === 'gear.demo.bocotech.fr') {
            console.log('demo');
            operator = 81;
        } else if (hostname === 'gear.stag.bocotech.fr') {
            console.log('stag');
            operator = 19;
        } else if (hostname === 'gear.bocotech.fr') {
            console.log('prod');
            operator = 2;
        } else {
            console.log('default');
            operator = 81;
        }
        setFormData({ ...formData, washerId: result[0].entity_washer_id, sorterId: sorter_id, sku: item.sku, operatorId: operator });
        // const operators = await getOperators();
        // setOperatorList(operators);
        const skuInfo = await getContainerBySku(item.sku);
        setTransferData(skuInfo[0]);
        let chooseQty = [];
        for (let i = 1; i <= item.qty; i++) {
            chooseQty.push(i);
        }
        setQtyMax(chooseQty);

        if (dialogRef.current) {
            dialogRef.current.showModal();
        }
    };

    const handleModalClose = () => {
        setFormData({ ...formData, washerId: null, sorterId: null, operatorId: null, qty: 0, sku: '' });

        if (dialogRef.current) {
            dialogRef.current.close();
        }
    };

    const sendPickUpRequest = async () => {
        if (!formData.operatorId) {
            alert('Veuillez sélectionner un transporteur');
            return;
        }

        try {
            const pickUpData = await sorterPickUprequest(formData, dateActuelle);
            if (!pickUpData) {
                alert('Une erreur est survenue lors de la demande de prise en charge');
                return;
            }

            const updatedeclarationDetails = await updateSorterDeclarationDetails(formData, pickUpData.suiviId);
            if (updatedeclarationDetails.message === 'ok') {
                alert('La demande de prise en charge a été faite avec succès');
                handleModalClose();
                window.location.reload();
            } else {
                alert('Une erreur est survenue lors de la mise à jour des détails de la déclaration');
            }
        } catch (err) {
            console.error('Une erreur est survenue :', err);
            alert('Une erreur est survenue');
        }
    };

    return (
        <main>
            {sorterDeclarationTabs.length
                ? sorterDeclarationTabs.map((item, index) => {
                      return (
                          <section
                              key={index}
                              className="small-section">
                              <h2>{item.sorter_name}</h2>
                              <div className="tab-item">
                                  <div
                                      className="pre-filled-fields-1-several-item"
                                      style={{ fontWeight: '600', width: '100%' }}>
                                      <p>SKU</p>
                                      <p>
                                          Volume total trié stocké <br />
                                          (en palette)
                                      </p>
                                      <p>
                                          Volume total à transférer <br />
                                          (en palette)
                                      </p>
                                  </div>
                                  {item.sorterDeclarationDetails.length ? (
                                      <div style={{ display: 'flex' }}>
                                          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                              {item.sorterDeclarationDetails
                                                  ? item.sorterDeclarationDetails
                                                        .sort((a, b) => a.sku.localeCompare(b.sku))
                                                        .map((elem, index) => {
                                                            return (
                                                                <div key={index}>
                                                                    <div
                                                                        className="pre-filled-fields-2-several-item"
                                                                        style={{ width: '100%', border: '0px' }}>
                                                                        <p>{elem.sku}</p>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                  : null}
                                          </div>
                                          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                              {item.sorterDeclarationDetails
                                                  ? item.sorterDeclarationDetails
                                                        .sort((a, b) => a.sku.localeCompare(b.sku))
                                                        .map((elem, index) => {
                                                            return (
                                                                <div
                                                                    onClick={() => {
                                                                        elem.qty > 0 && handleModalOpen(item.sorter_id, elem);
                                                                    }}
                                                                    key={index}>
                                                                    <div
                                                                        className="pre-filled-fields-2-several-item"
                                                                        style={{
                                                                            width: '100%',
                                                                            border: '0px',
                                                                            cursor: elem.qty > 0 && 'pointer',
                                                                            color: elem.qty > 0 && Contants.$rouge,
                                                                            fontWeight: elem.qty > 0 && 'bold',
                                                                        }}>
                                                                        <p>{elem.qty}</p>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                  : null}
                                          </div>

                                          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                              {item.declarationDetailsInTransit
                                                  ? item.declarationDetailsInTransit
                                                        .sort((a, b) => a.sku.localeCompare(b.sku))
                                                        .map((elem, index) => {
                                                            return (
                                                                <div key={index}>
                                                                    <div
                                                                        className="pre-filled-fields-2-several-item"
                                                                        style={{ width: '100%', border: '0px' }}>
                                                                        <p>{elem.qty}</p>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                  : null}
                                          </div>
                                      </div>
                                  ) : (
                                      <div className="no-data-graph">Aucun contenant référencé.</div>
                                  )}
                              </div>
                          </section>
                      );
                  })
                : null}
            <dialog
                ref={dialogRef}
                className="dialog-collector">
                <div className="title">
                    <p>Transferer au laveur</p>
                    <button
                        className="button-negatif"
                        onClick={handleModalClose}>
                        X
                    </button>
                </div>
                <div
                    className="collector-infos"
                    style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '1rem' }}>
                    <p>Transférer </p>
                    <select
                        style={{ width: '3rem', paddingLeft: '3px', paddingRight: '3px' }}
                        onChange={(e) => {
                            setFormData({ ...formData, qty: parseInt(e.target.value) });
                        }}>
                        <option value="">-</option>
                        {qtyMax.length &&
                            qtyMax.map((item, index) => (
                                <option
                                    key={index}
                                    value={item}>
                                    {item}
                                </option>
                            ))}
                    </select>
                    <p>palettes de {transferData && _defineContainerPaletType(transferData.palet_type)}</p>
                </div>

                {/* <div className="collector-date">
                    <label>Transporteur :</label>

                    <select
                        onChange={(e) => {
                            setFormData({ ...formData, operatorId: parseInt(e.target.value) });
                        }}>
                        <option value="">Choisir</option>
                        {operatorList.length &&
                            operatorList.map((operator, index) => (
                                <option
                                    key={index}
                                    value={operator.id}>
                                    {operator.name}
                                </option>
                            ))}
                    </select>
                </div> */}
                {/* <div
                    className="collector-date"
                    style={{ border: '0px' }}>
                    <label>A prendre en charge avant le :</label>
                    <input
                        type="datetime-local"
                        defaultValue={dateMaxFormatted}
                        max={dateMaxFormatted}
                        onChange={handlePickUpRequestChange}
                    />
                </div> */}
                <div className="collector-validate">
                    <button
                        className="button-actif"
                        onClick={sendPickUpRequest}>
                        VALIDER LE TRANSFERT
                    </button>
                </div>
            </dialog>
            <div
                className="arrow-down"
                onClick={handleArrowClickDown}>
                <ArrowDown
                    size={32}
                    color={Contants.$vertBocoloco}
                />
            </div>
        </main>
    );
}
