import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getInfoUser } from '../../API/AccountApi';
import { getUserInfoCheck } from '../../API/CheckApi/CheckAccountApi';
import * as Sentry from '@sentry/react';

export default function ProfilCompanyInfo() {
    const [userInfo, setUserInfo] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserInfo = async () => {
            const data = await getInfoUser();
            const dataCheck = getUserInfoCheck(data);
            if (data.length && dataCheck !== true) {
                console.error(dataCheck);
                Sentry.captureException(dataCheck);
                navigate('/error');
                return;
            }
            setUserInfo(data[0]);
        };
        fetchUserInfo();
    }, [navigate]);

    return (
        <main>
            <section
                className="big-section"
                style={{ width: '50%' }}>
                <h2>Ma société</h2>
                {userInfo && userInfo.entity ? (
                    <div
                        className="form-container"
                        style={{ flexDirection: 'column' }}>
                        <section style={{ width: '100%' }}>
                            <div>
                                <label>Nom</label>
                                <input
                                    disabled
                                    type="text"
                                    name="id"
                                    value={userInfo.entity.company_name || ''}
                                />
                            </div>
                            <div>
                                <label>Adresse</label>
                                <input
                                    disabled
                                    type="text"
                                    name="first_name"
                                    value={userInfo.entity.address_1 || ''}
                                />
                            </div>
                            <div>
                                <label>Comp. Addresse</label>
                                <input
                                    disabled
                                    type="text"
                                    name="first_name"
                                    value={userInfo.entity.address_2 || ''}
                                />
                            </div>
                            <div>
                                <label>Code postal</label>
                                <input
                                    disabled
                                    type="text"
                                    name="email"
                                    value={userInfo.entity.zipcode || ''}
                                />
                            </div>
                            <div>
                                <label>Ville</label>
                                <input
                                    disabled
                                    type="text"
                                    name="email"
                                    value={userInfo.entity.city || ''}
                                />
                            </div>
                        </section>
                        <section style={{ width: '100%' }}>
                            <div>
                                <label>SIRET</label>
                                <input
                                    disabled
                                    type="text"
                                    name="email"
                                    value={userInfo.entity.siret || ''}
                                />
                            </div>
                            <div>
                                <label>TVA intra.</label>
                                <input
                                    disabled
                                    type="text"
                                    name="email"
                                    value={userInfo.entity.tva_intra || ''}
                                />
                            </div>
                            <div>
                                <label>Site Web</label>
                                <input
                                    disabled
                                    type="text"
                                    name="email"
                                    value={userInfo.entity.website || ''}
                                />
                            </div>
                        </section>
                        <section style={{ width: '100%' }}>
                            <div>
                                <label>Nom</label>
                                <input
                                    disabled
                                    type="text"
                                    name="id"
                                    value={userInfo.entity.contact_name || ''}
                                />
                            </div>
                            <div>
                                <label>Téléphone</label>
                                <input
                                    disabled
                                    type="text"
                                    name="id"
                                    value={userInfo.entity.contact_phone || ''}
                                />
                            </div>
                            <div>
                                <label>Email</label>
                                <input
                                    disabled
                                    type="text"
                                    name="id"
                                    value={userInfo.entity.contact_email || ''}
                                />
                            </div>
                        </section>
                    </div>
                ) : null}
            </section>
        </main>
    );
}
