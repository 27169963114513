import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';
import Logo from '../Assets/images/Logo-GEAR-by-Bocoloco.png';
// TODO: Recfactor the login functionality
const SplashScreen = () => {
    const navigate = useNavigate();

    const token = Cookies.get('userToken');
    if (!token) {
        setTimeout(() => {
            navigate('/login');
        }, 3000);
        return (
            <main className="splasing-container">
                <div className="empty"></div>
                <div className="logo-container">
                    <img
                        src={Logo}
                        alt="Logo"
                    />
                </div>
                <div className="loader-container">
                    <div className="loader"></div>
                </div>
                <Footer />
            </main>
        );
    } else {
        return <></>;
    }
};

export default SplashScreen;
