import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getIndustrialProductById } from '../../../../API/IndustrialApi';
import { Jwt } from '../../../../Jwt';
import { _defineStatus, _defineProductCat } from '../../../../Utils';
import * as Constant from '../../../../Constants';
export default function IndustrialProducts() {
    const userContext = new Jwt();
    const [filterSelected, setFilterSelected] = useState('asc');
    const [productList, setProductList] = useState([]);

    useEffect(() => {
        const fetchAllProducts = async () => {
            try {
                const indusProduct = await getIndustrialProductById(userContext.entityId);
                setProductList(indusProduct);
            } catch (error) {
                console.error('Erreur lors de la récupération des industrials', error);
            }
        };
        fetchAllProducts();
    }, [userContext.entityId]);

    const sortDataAsc = () => {
        const sortedData = [...productList].sort((a, b) => a.name.localeCompare(b.name));
        setProductList(sortedData);
        setFilterSelected('asc');
    };

    const sortDataDesc = () => {
        const sortedData = [...productList].sort((a, b) => b.name.localeCompare(a.name));
        setProductList(sortedData);
        setFilterSelected('desc');
    };

    return (
        <main>
            <section className="big-section">
                <div className="top">
                    <h2 style={{ margin: '0' }}>Mes produits</h2>
                    <div className="top-button-sort">
                        <div className="filter">
                            <p className="sort">Trier par</p>
                            <div>
                                <p
                                    className={filterSelected === 'asc' ? 'selected' : 'classic'}
                                    onClick={sortDataAsc}>
                                    A &gt; Z
                                </p>
                                <p
                                    className={filterSelected === 'desc' ? 'selected' : 'classic'}
                                    onClick={sortDataDesc}>
                                    Z &gt; A
                                </p>
                            </div>
                        </div>
                        <Link
                            to={'/industrial/operation/product/add'}
                            state={{ entity_id: userContext.entityId }}
                            className="button-actif">
                            AJOUTER UN PRODUIT
                        </Link>
                    </div>
                </div>

                <div className="tab-item">
                    <div
                        className="pre-filled-fields-1-several-item"
                        style={{ fontWeight: '600' }}>
                        <p>Nom</p>
                        <p>Catégorie</p>
                        <p>EAN</p>
                        <p>SKU</p>
                        <p>Statut</p>
                    </div>

                    {productList.length ? (
                        productList.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className="pre-filled-fields-2-several-item">
                                    <p>{item.name}</p>
                                    <p>{_defineProductCat(item.cat)}</p>
                                    <p>{item.ean}</p>
                                    <p>{item.sku}</p>
                                    <p
                                        style={{
                                            backgroundColor: _defineStatus(item.status).color,
                                            color: Constant.$blanc,
                                            borderTopRightRadius: '0.6rem',
                                            borderBottomRightRadius: '0.6rem',
                                        }}>
                                        {_defineStatus(item.status).name}
                                    </p>
                                </div>
                            );
                        })
                    ) : (
                        <div className="no-data-graph ">Aucun produit enregistré.</div>
                    )}
                </div>
            </section>
        </main>
    );
}
