import React, { useState, useRef } from 'react';
import { useEffect } from 'react';
import { getDeclarationsByWasherId, getWasherDeclarationDetails } from '../../../API/WasherDeclarationApi';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { format } from 'date-fns';
import { Jwt } from '../../../Jwt';
import * as Constants from '../../../Constants';
import { SortAscending, SortDescending } from '@phosphor-icons/react';

export default function WasherDeclarationHistory() {
    const userContext = new Jwt();
    const [declarationsList, setDeclarationsList] = useState([]);
    const [showCalendar, setShowCalendar] = useState(false);
    const [declarationData, setDeclarationData] = useState([]);
    const [declarationDate, setDeclarationDate] = useState('');
    const [filter, SetFilter] = useState('asc');

    const dialogRef = useRef(null);

    useEffect(() => {
        const fetchHistory = async () => {
            const result = await getDeclarationsByWasherId(userContext.entityId);
            for (let i = 0; i < result.length; i++) {
                const event = result[i];
                const details = await getWasherDeclarationDetails(event.id);
                result[i] = {
                    ...event,
                    details: details,
                    nb_sku_total: new Set(details.map((item) => item.sku)).size,
                    qty_washed: details.filter((item) => item.type === Constants.DECLARATION_DETAILS_TYPE_OK).reduce((acc, curr) => acc + curr.qty, 0),
                    qty_rebus: details.filter((item) => item.type === Constants.DECLARATION_DETAILS_TYPE_REBUS).reduce((acc, curr) => acc + curr.qty, 0),
                };
            }
            setDeclarationsList(result);
        };
        fetchHistory();
    }, [userContext.entityId]);

    function handleEventClick(info) {
        const eventData = info.event.extendedProps.eventData;
        handleModalOpen(eventData);
    }

    const handleModalOpen = async (item) => {
        const aggregatedData = aggregateQuantities(item.details);
        setDeclarationData(aggregatedData);
        setDeclarationDate(item.createdAt);

        if (dialogRef.current) {
            dialogRef.current.showModal();
        }
    };

    const aggregateQuantities = (data) => {
        const groupedData = data.reduce((acc, curr) => {
            const { sku, qty, type } = curr;
            const key = type === 0 ? 'washed' : 'rebus';

            if (!acc[sku]) {
                acc[sku] = { sku, washed: 0, rebus: 0 };
            }

            acc[sku][key] += qty;

            return acc;
        }, {});

        return Object.values(groupedData);
    };
    const handleModalClose = () => {
        setDeclarationData([]);

        if (dialogRef.current) {
            dialogRef.current.close();
        }
    };

    const sortData = (data, setData) => {
        const sortedData = [...data].sort((a, b) => (filter === 'asc' ? a.createdAt.localeCompare(b.createdAt) : b.createdAt.localeCompare(a.createdAt)));
        setData(sortedData);
    };

    const customButtons = {
        today: {
            text: "Aujourd'hui",
        },
    };
    return (
        <main>
            <section className="big-section">
                <div className="top top-history">
                    <h2>Historique des déclarations</h2>
                    <div className="sort">
                        <p
                            onClick={() => {
                                setShowCalendar(false);
                            }}
                            className={!showCalendar ? 'selected' : 'classic'}>
                            Liste
                        </p>
                        <p
                            onClick={() => {
                                setShowCalendar(true);
                            }}
                            className={showCalendar ? 'selected' : 'classic'}>
                            Calendrier
                        </p>
                    </div>
                </div>
                {!showCalendar ? (
                    <div className="tab-item">
                        <div
                            className="pre-filled-fields-1-several-item"
                            style={{ fontWeight: '600', width: '100%' }}>
                            <p style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>Date de déclaration</span>
                                <span
                                    style={{ paddingRight: '0.93rem', cursor: 'pointer' }}
                                    onClick={() => {
                                        SetFilter(filter === 'asc' ? 'desc' : 'asc');
                                        sortData(declarationsList, setDeclarationsList);
                                    }}>
                                    {filter === 'asc' ? <SortAscending size={15} /> : <SortDescending size={15} />}
                                </span>
                            </p>
                            <p>SKU concernés</p>
                            <p>Palettes lavées</p>
                            <p>Contenants rebus</p>
                        </div>
                        {declarationsList.length ? (
                            declarationsList.map((item, index) => {
                                return (
                                    <div
                                        style={{ display: 'flex', flexWrap: 'wrap', width: '100%', justifyContent: 'space-between' }}
                                        key={index}>
                                        <div
                                            style={{ width: '100%' }}
                                            onClick={() => {
                                                handleModalOpen(item);
                                            }}
                                            className="pre-filled-fields-2-several-item">
                                            <p>{format(item.createdAt, 'yyyy-MM-dd HH:mm:ss')}</p>
                                            <p>{item.nb_sku_total}</p>
                                            <p>{item.qty_washed}</p>
                                            <p>{item.qty_rebus}</p>
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <div className="no-data-graph ">Aucun historique.</div>
                        )}
                    </div>
                ) : (
                    <FullCalendar
                        events={declarationsList.map((item, index) => {
                            return {
                                index: index,
                                title: `Declaration`,
                                date: item.createdAt,
                                eventData: item,
                            };
                        })}
                        initialView="dayGridMonth"
                        plugins={[dayGridPlugin]}
                        eventClick={handleEventClick}
                        locale="fr"
                        customButtons={customButtons}
                    />
                )}
            </section>
            <dialog
                ref={dialogRef}
                className="dialog-collector">
                <div className="title">
                    <p>Déclaration du {declarationDate && format(declarationDate, 'yyyy-MM-dd HH:mm:ss')}</p>
                    <button
                        className="button-negatif"
                        onClick={handleModalClose}>
                        X
                    </button>
                </div>
                {declarationData && (
                    <div className="tab-item">
                        <div
                            className="pre-filled-fields-1-several-item"
                            style={{ fontWeight: '600', width: '100%' }}>
                            <p>SKU</p>
                            <p>Palettes lavées</p>
                            <p>Contenants rebus</p>
                        </div>
                        {declarationData.length
                            ? declarationData.map((item, index) => {
                                  return (
                                      <div
                                          style={{ display: 'flex', flexWrap: 'wrap', width: '100%', justifyContent: 'space-between' }}
                                          key={index}>
                                          <div
                                              style={{ width: '100%' }}
                                              className="pre-filled-fields-2-several-item">
                                              <p>{item.sku}</p>
                                              <p>{item.washed}</p>
                                              <p>{item.rebus}</p>
                                          </div>
                                      </div>
                                  );
                              })
                            : null}
                    </div>
                )}
            </dialog>
        </main>
    );
}
