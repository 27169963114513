import { Routes, Route, Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import * as Constants from '../../Constants';

// General
import Documentation from '../../containers/Documentation';
import Support from '../../containers/account/Support';
import UpdatePassword from '../../containers/account/UpdatePassword';
import PreHome from '../../containers/PreHome';
import ErrorPage from '../../Components/ErrorPage';
import RvmOwnerHome from '../../containers/operation/rvmowner_interface/RvmOwnerHome';
import Unknown from '../../containers/account/Unknown';

// RVM
import Collector from '../../containers/operation/rvmowner_interface/collector/Collector';
import CollectorSheet from '../../containers/operation/rvmowner_interface/collector/CollectorSheet';
import UpdateCollector from '../../containers/operation/rvmowner_interface/collector/UpdateCollector';
import AddCollector from '../../containers/operation/rvmowner_interface/collector/AddCollector';

// Store
import Store from '../../containers/operation/rvmowner_interface/store/Store';
import StoreSheet from '../../containers/operation/rvmowner_interface/store/StoreSheet';
import UpdateStore from '../../containers/operation/rvmowner_interface/store/UpdateStore';
import AddStore from '../../containers/operation/rvmowner_interface/store/AddStore';

// Depot
// import Depot from '../../containers/operation/rvmowner_interface/depot/Depot';
// import DepotSheet from '../../containers/operation/rvmowner_interface/depot/DepotSheet';

// Profil
import ProfilPersonalInfo from '../../containers/account/ProfilPersonalInfo';
import ProfilCompanyInfo from '../../containers/account/ProfilCompanyInfo';
import ProfilAccess from '../../containers/account/ProfilAccess';
import ProfilRoleManagement from '../../containers/account/ProfilRoleManagement';
import CreateUser from '../../containers/account/CreateUser';
import UserSheet from '../../containers/account/UserSheet';

export default function WasherInterface() {
    const role = Cookies.get('role');
    const isAdmin = role === Constants.ENTITY_ROLE_ADMIN;
    return (
        <Routes>
            <Route
                path="/error"
                element={<ErrorPage />}
            />
            <Route
                path="/prehome"
                element={<PreHome />}
            />
            <Route
                path="/support"
                element={<Support />}
            />
            <Route
                path="/documentation"
                element={<Documentation />}
            />
            <Route
                path="/updatepassword"
                element={<UpdatePassword />}
            />
            <Route
                path="/rvmowner/operation/home"
                element={<RvmOwnerHome />}
            />
            <Route
                path="/rvmowner/operation/collector/list"
                element={<Collector />}
            />
            <Route
                path="/rvmowner/operation/collector/sheet"
                element={<CollectorSheet />}
            />
            <Route
                path="/rvmowner/operation/collector/add"
                element={<AddCollector />}
            />
            <Route
                path="/rvmowner/operation/collector/update"
                element={<UpdateCollector />}
            />
            <Route
                path="/rvmowner/operation/store/list"
                element={<Store />}
            />
            <Route
                path="/rvmowner/operation/store/sheet"
                element={<StoreSheet />}
            />
            <Route
                path="/rvmowner/operation/store/add"
                element={<AddStore />}
            />
            <Route
                path="/rvmowner/operation/store/update"
                element={<UpdateStore />}
            />
            {/* <Route
                path="/rvmowner/depot/list"
                element={<Depot />}
            />
            <Route
                path="/rvmowner/depot/sheet"
                element={<DepotSheet />}
            /> */}
            <Route
                path="/rvmowner/myprofil/personal_info"
                element={<ProfilPersonalInfo />}
            />
            <Route
                path="/rvmowner/myprofil/company_info"
                element={<ProfilCompanyInfo />}
            />
            <Route
                path="/rvmowner/myprofil/access"
                element={<ProfilAccess />}
            />
            {isAdmin ? (
                <Route
                    path="/rvmowner/myprofil/rolemanagement"
                    element={<ProfilRoleManagement />}
                />
            ) : null}
            {isAdmin ? (
                <Route
                    path="/rvmowner/myprofil/user/add"
                    element={<CreateUser />}
                />
            ) : null}
            {isAdmin ? (
                <Route
                    path="/rvmowner/myprofil/user/sheet"
                    element={<UserSheet />}
                />
            ) : null}
            <Route
                path="/rvmowner/operation/unknown"
                element={<Unknown />}
            />
            <Route
                path="*"
                element={
                    <Navigate
                        replace
                        to="/rvmowner/operation/unknown"
                    />
                }
            />
        </Routes>
    );
}
