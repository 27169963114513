import { useState, useEffect } from 'react';
import { getSuppliersById, getProductBySupplierId } from '../../../../../../API/SupplierApi';
import { _defineStatus } from '../../../../../../Utils';
import { Link, useLocation } from 'react-router-dom';
import { Pencil } from '@phosphor-icons/react';
import * as Constant from '../../../../../../Constants';

export default function SupplierSheet() {
    const [filterSelected, setFilterSelected] = useState('asc');

    const [supplierData, setSupplierData] = useState([]);
    const [supplierProductData, setSupplierProductData] = useState([]);
    const location = useLocation();
    const { id } = location.state || {};

    useEffect(() => {
        const fetchSupplierData = async () => {
            try {
                const supplierData = await getSuppliersById(id);
                setSupplierData(supplierData[0]);

                const supplierProduct = await getProductBySupplierId(id);
                setSupplierProductData(supplierProduct);
            } catch (error) {
                console.error('Erreur lors de la récupération des infos du verrier', error);
            }
        };
        fetchSupplierData();
    }, [id]);

    const sortDataAsc = (data, setData) => {
        const sortedData = [...data].sort((a, b) => a.name.localeCompare(b.name));
        setData(sortedData);
        setFilterSelected('asc');
    };

    const sortDataDesc = (data, setData) => {
        const sortedData = [...data].sort((a, b) => b.name.localeCompare(a.name));
        setData(sortedData);
        setFilterSelected('desc');
    };
    return (
        <main>
            {supplierData.id && (
                <div className="sheet">
                    <div className="sheet-top-infos">
                        <div className="title">
                            <Link
                                className="update-supplier"
                                to={'/servicesupplier/operation/supplier/update'}
                                state={{ id: supplierData.id }}>
                                <div className="icon">
                                    <Pencil size={20} />
                                </div>
                            </Link>
                            <h2>{supplierData.name}</h2>
                            <div
                                className="status"
                                style={{ backgroundColor: _defineStatus(supplierData.status).color }}>
                                {_defineStatus(supplierData.status).name}
                            </div>
                        </div>
                        <section className="item-info-container">
                            <div className="border">
                                <div>
                                    <p>
                                        Société : <span>{supplierData.name}</span>
                                    </p>
                                    <p>
                                        Siret : <span>{supplierData.siret}</span>{' '}
                                    </p>
                                    <p>
                                        Tva intracommunautaire : <span>{supplierData.tva_intra}</span>{' '}
                                    </p>
                                </div>
                            </div>
                            <div className="border">
                                <div>
                                    <p>
                                        Adresse 1 : <span>{supplierData.address_1}</span>
                                    </p>
                                    <p>
                                        Adresse 2 : <span>{supplierData.address_2}</span>
                                    </p>
                                    <p>
                                        Ville : <span>{supplierData.city}</span>
                                    </p>
                                    <p>
                                        Code postal : <span>{supplierData.zipcode}</span>
                                    </p>
                                </div>
                            </div>
                            <div className="border">
                                <div>
                                    <p>
                                        Nom du contact : <span>{supplierData.contact_name}</span>
                                    </p>
                                    <p>
                                        Email du contact : <span>{supplierData.contact_email}</span>
                                    </p>
                                    <p>
                                        Téléphone du contact : <span>{supplierData.contact_phone}</span>
                                    </p>
                                </div>
                            </div>
                        </section>
                    </div>

                    <section className="big-section">
                        <div className="top">
                            <h2 style={{ margin: '0' }}>Les contenants :</h2>
                            <div className="top-button-sort">
                                <div className="filter">
                                    <p className="sort">Trier par</p>
                                    <div>
                                        <p
                                            className={filterSelected === 'asc' ? 'selected' : 'classic'}
                                            onClick={() => {
                                                sortDataAsc(supplierProductData, setSupplierProductData);
                                            }}>
                                            A &gt; Z
                                        </p>
                                        <p
                                            className={filterSelected === 'desc' ? 'selected' : 'classic'}
                                            onClick={() => {
                                                sortDataDesc(supplierProductData, setSupplierProductData);
                                            }}>
                                            Z &gt; A
                                        </p>
                                    </div>
                                </div>
                                <Link
                                    to={'/servicesupplier/operation/supplier/product/add'}
                                    state={{ supplier_id: supplierData.id, toto: 'toto' }}
                                    className="button-actif">
                                    AJOUTER UN CONTENANT
                                </Link>
                            </div>
                        </div>

                        <div className="tab-item">
                            <div
                                className="pre-filled-fields-1-several-item"
                                style={{ fontWeight: '600' }}>
                                <p>Nom</p>
                                <p>Référence</p>
                                <p>SKU</p>
                                <p>Statut</p>
                            </div>

                            {supplierProductData.length ? (
                                supplierProductData.map((item, index) => {
                                    return (
                                        <Link
                                            to={'/servicesupplier/operation/supplier/product/update'}
                                            state={{ id: item.id }}
                                            key={index}
                                            className="pre-filled-fields-2-several-item">
                                            <p>{item.name}</p>
                                            <p>{item.ref}</p>
                                            <p>{item.sku}</p>
                                            <p
                                                style={{
                                                    backgroundColor: _defineStatus(item.status).color,
                                                    color: Constant.$blanc,
                                                    borderTopRightRadius: '0.6rem',
                                                    borderBottomRightRadius: '0.6rem',
                                                }}>
                                                {_defineStatus(item.status).name}
                                            </p>
                                        </Link>
                                    );
                                })
                            ) : (
                                <div className="no-data-graph ">Aucun contenant enregistré.</div>
                            )}
                        </div>
                    </section>
                </div>
            )}
        </main>
    );
}
