import { getGearAPIApiEndpoint } from './endpoints';
import * as Sentry from '@sentry/react';
import { defaultHeaders } from './headers';

const GEAR_API_BASE_URL = getGearAPIApiEndpoint();

const BOCOLOCO_GET_STORESBOXES_BYSTOREID = '/storeboxes/getbystoreid';

export function getStoreBoxesByStoreId(store_id) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_STORESBOXES_BYSTOREID;
    var url_args = '?store_id=' + store_id;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}
