export const AccessSorter = {
    sorter: {
        operation: {
            tri: {
                reception: 0,
                declaration: 0,
                historique: 0,
            },
        },
        data: {},
        financial: {},
    },
};
