import { getGearAPIApiEndpoint } from './endpoints';
import * as Sentry from '@sentry/react';
import { defaultHeaders } from './headers';

const GEAR_API_BASE_URL = getGearAPIApiEndpoint();

const BOCOLOCO_GET_PRODUCTS = '/product/get';
const BOCOLOCO_ADD_PRODUCT = '/product/add';
const BOCOLOCO_GET_PRODUCTBYID = '/product/getbyid';
const BOCOLOCO_GET_PRODUCTBYNAME = '/product/getbyname';
const BOCOLOCO_DELETE_PRODUCT = '/product/delete';
const BOCOLOCO_UPDATE_PRODUCT = '/product/update';
const BOCOLOCO_GET_PRODUCTLISTBYSKU = '/product/getlistbysku';

export function getProducts() {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_PRODUCTS;
    const url = encodeURI(url_base);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function addProduct(formData) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_ADD_PRODUCT;
    const url = encodeURI(url_base);
    return fetch(url, {
        method: 'PUT',
        body: JSON.stringify(formData),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            console.log('ERROR ADD PRODUCT');
            console.log(error);
            throw error;
        });
}

export function getProductsByName(name) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_PRODUCTBYNAME;
    var url_args = '?name=' + name;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function getListBysku(sku) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_PRODUCTLISTBYSKU;
    var url_args = '?sku=' + sku;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function getProductById(id) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_PRODUCTBYID;
    var url_args = '?id=' + id;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function updateProduct(id, updatedData) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_UPDATE_PRODUCT;
    const url_args = '?id=' + id;
    const url = encodeURI(url_base + url_args);
    return fetch(url, {
        method: 'PUT',
        body: JSON.stringify(updatedData),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            console.log('ERROR UPDATE PRODUCT');
            console.log(error);
            throw error;
        });
}

export function deleteProduct(id) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_DELETE_PRODUCT;
    var url_args = '?id=' + id;
    const url = encodeURI(url_base + url_args);

    return fetch(url, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            throw error;
        });
}
