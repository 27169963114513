import { useState, useEffect } from 'react';
import { getDepotById } from '../../../../../API/DepotApi';
import { getStoreByDepotId } from '../../../../../API/StoreApi';
import { getCollectorsByDepotId } from '../../../../../API/CollectorApi';
import { getDepotByIdCheck } from '../../../../../API/CheckApi/CheckDepotApi';
import { getCollectorsByDepotIdCheck } from '../../../../../API/CheckApi/CheckCollectorApi';
import { _defineStatus, transformDateFull, _defineCollectorType } from '../../../../../Utils';
import { Link, useLocation } from 'react-router-dom';
import { Pencil } from '@phosphor-icons/react';
import * as Constant from '../../../../../Constants';
import { useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
export default function DepotSheet() {
    const navigate = useNavigate();
    const [filterStoreSelected, setFilterStoreSelected] = useState('asc');
    const [filterRvmSelected, setFilterRvmSelected] = useState('desc');

    const [depotData, setDepotData] = useState([]);
    const [depotStoresData, setDepotStoresData] = useState([]);
    const [depotCollectorData, setDepotCollectorData] = useState([]);

    const location = useLocation();
    const { id } = location.state || {};
    useEffect(() => {
        const fetchDepotData = async () => {
            try {
                const depotData = await getDepotById(id);
                const depotDataCheck = getDepotByIdCheck(depotData);
                if (depotData.length && depotDataCheck !== true) {
                    console.error(depotDataCheck);
                    Sentry.captureMessage(depotDataCheck);
                    navigate('/error');
                    return null;
                }
                setDepotData(depotData[0]);
                const depotStore = await getStoreByDepotId(id);
                setDepotStoresData(depotStore);

                const depotCollector = await getCollectorsByDepotId(id);
                const depotCollectorCheck = getCollectorsByDepotIdCheck(depotCollector);
                if (depotCollector.length && depotCollectorCheck !== true) {
                    console.error(depotCollectorCheck);
                    Sentry.captureMessage(depotCollectorCheck);
                    navigate('/error');
                    return null;
                }

                setDepotCollectorData(depotCollector);
            } catch (error) {
                console.error('Erreur lors de la récupération des infos du centre de collecte', error);
            }
        };
        fetchDepotData();
    }, [id, navigate]);

    const sortDataAsc = (data, setData, type) => {
        if (type === 'store') {
            const sortedData = [...data].sort((a, b) => a.name.localeCompare(b.name));
            setData(sortedData);
            setFilterStoreSelected('asc');
        } else if (type === 'rvm') {
            const sortedData = [...data].sort((a, b) => a.store_name.localeCompare(b.store_name));
            setData(sortedData);
            setFilterRvmSelected('asc');
        }
    };

    const sortDataDesc = (data, setData, type) => {
        if (type === 'store') {
            const sortedData = [...data].sort((a, b) => b.name.localeCompare(a.name));
            setData(sortedData);
            setFilterStoreSelected('desc');
        }
        if (type === 'rvm') {
            const sortedData = [...data].sort((a, b) => b.store_name.localeCompare(a.store_name));
            setData(sortedData);
            setFilterRvmSelected('desc');
        }
    };

    return (
        <main>
            {depotData.id && (
                <div className="sheet">
                    <div className="sheet-top-infos">
                        <div className="title">
                            <Link
                                className="update-depot"
                                to={'/servicesupplier/operation/depot/update'}
                                state={{ id: depotData.id }}>
                                <div className="icon">
                                    <Pencil size={20} />
                                </div>
                            </Link>
                            <h2>{depotData.name}</h2>
                            <div
                                className="status"
                                style={{ backgroundColor: _defineStatus(depotData.status).color }}>
                                {_defineStatus(depotData.status).name}
                            </div>
                        </div>
                        <section className="item-info-container">
                            <div className="border">
                                <div>
                                    <p>
                                        Société Mère : <span>{depotData.company_name}</span>
                                    </p>
                                    <p>
                                        Nom : <span>{depotData.name}</span>
                                    </p>
                                    <p>
                                        Centre de tri : <span>{depotData.sorter_name}</span>
                                    </p>
                                </div>
                            </div>
                            <div className="border">
                                <div>
                                    <p>
                                        Adresse 1 : <span>{depotData.address_1}</span>
                                    </p>
                                    <p>
                                        Adresse 2 : <span>{depotData.address_2}</span>
                                    </p>
                                    <p>
                                        Ville : <span>{depotData.city}</span>
                                    </p>
                                    <p>
                                        Code postal : <span>{depotData.zipcode}</span>
                                    </p>
                                </div>
                            </div>
                            <div className="border">
                                <div>
                                    <p>
                                        Nom du contact : <span>{depotData.contact_name}</span>
                                    </p>
                                    <p>
                                        Email du contact : <span>{depotData.contact_email}</span>
                                    </p>
                                    <p>
                                        Téléphone du contact : <span>{depotData.contact_phone}</span>
                                    </p>
                                </div>
                            </div>
                        </section>
                    </div>

                    <section className="big-section">
                        <div className="top">
                            <h2 style={{ margin: '0' }}>Les magasins ({depotStoresData.length})</h2>
                            <div className="top-button-sort">
                                <div className="filter">
                                    <p className="sort">Trier par</p>
                                    <div>
                                        <p
                                            className={filterStoreSelected === 'asc' ? 'selected' : 'classic'}
                                            onClick={() => {
                                                sortDataAsc(depotStoresData, setDepotStoresData, 'store');
                                            }}>
                                            A &gt; Z
                                        </p>
                                        <p
                                            className={filterStoreSelected === 'desc' ? 'selected' : 'classic'}
                                            onClick={() => {
                                                sortDataDesc(depotStoresData, setDepotStoresData, 'store');
                                            }}>
                                            Z &gt; A
                                        </p>
                                    </div>
                                </div>
                                <Link
                                    to={'/servicesupplier/operation/store/add'}
                                    state={{ depot_id: depotData.id, depot_name: depotData.company_name, rvmowner_id: 0 }}
                                    className="button-actif">
                                    AJOUTER UN MAGASIN
                                </Link>
                            </div>
                        </div>

                        <div className="tab-item">
                            <div
                                className="pre-filled-fields-1-several-item"
                                style={{ fontWeight: '600' }}>
                                <p>Nom</p>
                                <p>Adresse 1</p>
                                <p>Adresse 2</p>
                                <p>Ville</p>
                                <p>Code postal</p>
                                <p>Statut</p>
                            </div>

                            {depotStoresData.length ? (
                                depotStoresData.map((item, index) => {
                                    return (
                                        <Link
                                            to={'/servicesupplier/operation/store/sheet'}
                                            state={{ id: item.id }}
                                            key={index}
                                            className="pre-filled-fields-2-several-item">
                                            <p>{item.name}</p>
                                            <p>{item.address_1}</p>
                                            <p>{item.address_2}</p>
                                            <p>{item.city}</p>
                                            <p>{item.zipcode}</p>
                                            <p
                                                style={{
                                                    backgroundColor: _defineStatus(item.status).color,
                                                    color: Constant.$blanc,
                                                    borderTopRightRadius: '0.6rem',
                                                    borderBottomRightRadius: '0.6rem',
                                                }}>
                                                {_defineStatus(item.status).name}
                                            </p>
                                        </Link>
                                    );
                                })
                            ) : (
                                <div className="no-data-graph ">Aucun magasin pour ce centre de collecte.</div>
                            )}
                        </div>
                    </section>

                    <section className="big-section">
                        <div className="top">
                            <h2 style={{ margin: '0' }}>Les RVM ({depotCollectorData.length})</h2>
                            <div className="top-button-sort">
                                <div className="filter">
                                    <p className="sort">Trier par</p>
                                    <div>
                                        <p
                                            className={filterRvmSelected === 'asc' ? 'selected' : 'classic'}
                                            onClick={() => {
                                                sortDataAsc(depotCollectorData, setDepotCollectorData, 'rvm');
                                            }}>
                                            A &gt; Z
                                        </p>
                                        <p
                                            className={filterRvmSelected === 'desc' ? 'selected' : 'classic'}
                                            onClick={() => {
                                                sortDataDesc(depotCollectorData, setDepotCollectorData, 'rvm');
                                            }}>
                                            Z &gt; A
                                        </p>
                                    </div>
                                </div>
                                <Link
                                    to={'/servicesupplier/operation/collector/add'}
                                    state={{ depot_id: depotData.id, rvmowner_id: 0, store: null }}
                                    className="button-actif">
                                    AJOUTER UNE RVM
                                </Link>{' '}
                            </div>
                        </div>

                        <div className="tab-item">
                            <div
                                className="pre-filled-fields-1-several-item"
                                style={{ fontWeight: '600' }}>
                                <p>Id</p>
                                <p>Magasin</p>
                                <p>Type</p>
                                <p>Capacity</p>
                                <p>Date Contact</p>
                                <p>Statut</p>
                            </div>

                            {depotCollectorData.length ? (
                                depotCollectorData.map((item, index) => {
                                    return (
                                        <Link
                                            to={'/servicesupplier/operation/collector/sheet'}
                                            state={{ id: item.id }}
                                            key={index}
                                            className="pre-filled-fields-2-several-item">
                                            <p>{item.id}</p>
                                            <p>{item.store_name}</p>
                                            <p>{_defineCollectorType(item.type)}</p>
                                            <p>{item.capacity}</p>
                                            <p>{transformDateFull(item.datep)}</p>
                                            <p
                                                style={{
                                                    backgroundColor: _defineStatus(item.status).color,
                                                    color: Constant.$blanc,
                                                    borderTopRightRadius: '0.6rem',
                                                    borderBottomRightRadius: '0.6rem',
                                                }}>
                                                {_defineStatus(item.status).name}
                                            </p>
                                        </Link>
                                    );
                                })
                            ) : (
                                <div className="no-data-graph ">Aucun collecteur pour ce centre de collecte.</div>
                            )}
                        </div>
                    </section>
                </div>
            )}
        </main>
    );
}
