import { Routes, Route, Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import * as Constants from '../../Constants';

// General
import Documentation from '../../containers/Documentation';
import Support from '../../containers/account/Support';
import UpdatePassword from '../../containers/account/UpdatePassword';
import PreHome from '../../containers/PreHome';
import Unknown from '../../containers/account/Unknown';

// RVM owner

// Store
import StoreSheet from '../../containers/operation/storeowner_interface/StoreSheet';

// Collector
import Collector from '../../containers/operation/storeowner_interface/Collector';
import CollectorSheet from '../../containers/operation/storeowner_interface/CollectorSheet';

// Delivery
import StoreOwnerDelivery from '../../containers/operation/storeowner_interface/StoreOwnerDelivery';

// Profil
import ProfilPersonalInfo from '../../containers/account/ProfilPersonalInfo';
import ProfilCompanyInfo from '../../containers/account/ProfilCompanyInfo';
import ProfilAccess from '../../containers/account/ProfilAccess';
import ProfilRoleManagement from '../../containers/account/ProfilRoleManagement';

// FINANCE
import StoreOwnerTransactionCollector from '../../containers/finance/storeowner_interface/StoreOwnerTransactionCollector';
import StoreOwnerReportings from '../../containers/finance/storeowner_interface/StoreOwnerReportings';
import StoreOwnerReportingDetails from '../../containers/finance/storeowner_interface/StoreOwnerReportingsDetail';

export default function StoreownerInterface() {
    const role = Cookies.get('role');
    const isAdmin = role === Constants.ENTITY_ROLE_ADMIN;
    return (
        <Routes>
            <Route
                path="/prehome"
                element={<PreHome />}
            />
            <Route
                path="/support"
                element={<Support />}
            />
            <Route
                path="/documentation"
                element={<Documentation />}
            />
            <Route
                path="/updatepassword"
                element={<UpdatePassword />}
            />

            <Route
                path="/storeowner/operation/store/sheet"
                element={<StoreSheet />}
            />
            <Route
                path="/storeowner/operation/delivery/list"
                element={<StoreOwnerDelivery />}
            />

            <Route
                path="/storeowner/operation/collector/list"
                element={<Collector />}
            />
            <Route
                path="/storeowner/operation/collector/sheet"
                element={<CollectorSheet />}
            />
            <Route
                path="/storeowner/financial/transaction"
                element={<StoreOwnerTransactionCollector />}
            />
            <Route
                path="/storeowner/financial/reportings"
                element={<StoreOwnerReportings />}
            />
            <Route
                path="/storeowner/financial/reportings/details"
                element={<StoreOwnerReportingDetails />}
            />
            <Route
                path="/storeowner/myprofil/personal_info"
                element={<ProfilPersonalInfo />}
            />
            <Route
                path="/storeowner/myprofil/company_info"
                element={<ProfilCompanyInfo />}
            />
            <Route
                path="/storeowner/myprofil/access"
                element={<ProfilAccess />}
            />

            {isAdmin ? (
                <Route
                    path="/storeowner/myprofil/rolemanagement"
                    element={<ProfilRoleManagement />}
                />
            ) : null}
            <Route
                path="/storeowner/operation/unknown"
                element={<Unknown />}
            />
            <Route
                path="*"
                element={
                    <Navigate
                        replace
                        to="/storeowner/operation/unknown"
                    />
                }
            />
        </Routes>
    );
}
