import { Routes, Route, Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import * as Constants from '../../Constants';

import ServiceSupplierHome from '../../containers/operation/service_supplier_interface/ServiceSupplierHome';
import Documentation from '../../containers/Documentation';
import Support from '../../containers/account/Support';
import UpdatePassword from '../../containers/account/UpdatePassword';
import CreateUser from '../../containers/account/CreateUser';
import UserSheet from '../../containers/account/UserSheet';
import Unknown from '../../containers/account/Unknown';

// Industrial
import Industrial from '../../containers/operation/service_supplier_interface/bdd/industrial/Industrial';
import IndustrialSheet from '../../containers/operation/service_supplier_interface/bdd/industrial/IndustrialSheet';
import AddIndustrial from '../../containers/operation/service_supplier_interface/bdd/industrial/AddIndustrial';
import UpdateIndustrial from '../../containers/operation/service_supplier_interface/bdd/industrial/UpdateIndustrial';
import AddIndustrialAddress from '../../containers/operation/service_supplier_interface/bdd/industrial/industrial_address/AddIndustrialAddress';
import UpdateIndustrialAddress from '../../containers/operation/service_supplier_interface/bdd/industrial/industrial_address/UpdateIndustriaAddress';
import AddIndustrialProduct from '../../containers/operation/service_supplier_interface/bdd/industrial/industrial_product/AddIndustrialProduct';
import UpdateIndustrialProduct from '../../containers/operation/service_supplier_interface/bdd/industrial/industrial_product/UpdateIndustrialProduct';
import IndustrialProducts from '../../containers/operation/service_supplier_interface/bdd/industrial/industrial_product/IndustrialProducts';
// Container
import Container from '../../containers/operation/service_supplier_interface/bdd/container/Container';
import AddContainer from '../../containers/operation/service_supplier_interface/bdd/container/AddContainer';
import UpdateContainer from '../../containers/operation/service_supplier_interface/bdd/container/UpdateContainer';
import ContainerSheet from '../../containers/operation/service_supplier_interface/bdd/container/ContainerSheet';

// Supplier
import Supplier from '../../containers/operation/service_supplier_interface/bdd/sourcing/supplier/Supplier';
import SupplierSheet from '../../containers/operation/service_supplier_interface/bdd/sourcing/supplier/SupplierSheet';
import AddSupplier from '../../containers/operation/service_supplier_interface/bdd/sourcing/supplier/AddSupplier';
import UpdateSupplier from '../../containers/operation/service_supplier_interface/bdd/sourcing/supplier/UpdateSupplier';

// Product
import UpdateProduct from '../../containers/operation/service_supplier_interface/bdd/sourcing/supplier_product/UpdateProduct';
import AddProduct from '../../containers/operation/service_supplier_interface/bdd/sourcing/supplier_product/AddProduct';

// Depot
import Depot from '../../containers/operation/service_supplier_interface/collecte/depot/Depot';
import DepotSheet from '../../containers/operation/service_supplier_interface/collecte/depot/DepotSheet';
import UpdateDepot from '../../containers/operation/service_supplier_interface/collecte/depot/UpdateDepot';
import AddDepot from '../../containers/operation/service_supplier_interface/collecte/depot/AddDepot';

// Store
import Store from '../../containers/operation/service_supplier_interface/collecte/store/Store';
import StoreSheet from '../../containers/operation/service_supplier_interface/collecte/store/StoreSheet';
import UpdateStore from '../../containers/operation/service_supplier_interface/collecte/store/UpdateStore';
import AddStore from '../../containers/operation/service_supplier_interface/collecte/store/AddStore';

// Collector
import Collector from '../../containers/operation/service_supplier_interface/collecte/collector/Collector';
import CollectorSheet from '../../containers/operation/service_supplier_interface/collecte/collector/CollectorSheet';
import UpdateCollector from '../../containers/operation/service_supplier_interface/collecte/collector/UpdateCollector';
import AddCollector from '../../containers/operation/service_supplier_interface/collecte/collector/AddCollector';
import CollectorPickup from '../../containers/operation/service_supplier_interface/collecte/collector/CollectorPickup';

// Indus needs
import IndustrialNeedList from '../../containers/operation/service_supplier_interface/indus_needs/IndustrialNeedList';
import IndustrialNeedSheet from '../../containers/operation/service_supplier_interface/indus_needs/IndustrialNeedSheet';
import Attribution from '../../containers/operation/service_supplier_interface/indus_needs/Attribution';
import Untreatedneed from '../../containers/operation/service_supplier_interface/indus_needs/UntreatedNeed';
import FsIndusNeedDashboard from '../../containers/operation/service_supplier_interface/indus_needs/FsIndusNeedDashboard';

// RVM owner
import Rvmowner from '../../containers/operation/service_supplier_interface/bdd/rvmowner/Rvmowner';
import AddRvmowner from '../../containers/operation/service_supplier_interface/bdd/rvmowner/AddRvmowner';
import UpdateRvmowner from '../../containers/operation/service_supplier_interface/bdd/rvmowner/UpdateRvmowner';
import RvmownerSheet from '../../containers/operation/service_supplier_interface/bdd/rvmowner/RvmownerSheet';

// Washer
import Washer from '../../containers/operation/service_supplier_interface/bdd/washer/Washer';
import AddWasher from '../../containers/operation/service_supplier_interface/bdd/washer/AddWasher';
import UpdateWasher from '../../containers/operation/service_supplier_interface/bdd/washer/UpdateWasher';
import WasherSheet from '../../containers/operation/service_supplier_interface/bdd/washer/WasherSheet';
import AddWasherSku from '../../containers/operation/service_supplier_interface/bdd/washer/washer_sku/AddWasherSku';
import UpdateWasherSku from '../../containers/operation/service_supplier_interface/bdd/washer/washer_sku/UpdateWasherSku';

// Sorter
import Sorter from '../../containers/operation/service_supplier_interface/bdd/sorter/Sorter';
import AddSorter from '../../containers/operation/service_supplier_interface/bdd/sorter/AddSorter';
import SorterSheet from '../../containers/operation/service_supplier_interface/bdd/sorter/SorterSheet';
import AddSorterWasherLink from '../../containers/operation/service_supplier_interface/bdd/sorter/sorter_washer/AddSorterWasherLink';
import UpdateWasherSorterLink from '../../containers/operation/service_supplier_interface/bdd/sorter/sorter_washer/UpdateSorterWasherLink';
import UpdateSorter from '../../containers/operation/service_supplier_interface/bdd/sorter/UpdateSorter';

// operator
import Operator from '../../containers/operation/service_supplier_interface/bdd/operator_collecte/Operator';
import AddOperator from '../../containers/operation/service_supplier_interface/bdd/operator_collecte/AddOperator';
import UpdateOperator from '../../containers/operation/service_supplier_interface/bdd/operator_collecte/UpdateOperator';
import OperatorSheet from '../../containers/operation/service_supplier_interface/bdd/operator_collecte/OperatorSheet';

// Store owner
import Storeowner from '../../containers/operation/service_supplier_interface/bdd/storeowner/Storeowner';
import AddStoreowner from '../../containers/operation/service_supplier_interface/bdd/storeowner/AddStoreowner';
import UpdateStoreowner from '../../containers/operation/service_supplier_interface/bdd/storeowner/UpdateStoreowner';
import StoreownerSheet from '../../containers/operation/service_supplier_interface/bdd/storeowner/StoreownerSheet';

// distributor
import Distributor from '../../containers/operation/service_supplier_interface/bdd/distributor/Distributor';
import AddDistributor from '../../containers/operation/service_supplier_interface/bdd/distributor/AddDistributor';
import UpdateDistributor from '../../containers/operation/service_supplier_interface/bdd/distributor/UpdateDistributor';
import DistributorSheet from '../../containers/operation/service_supplier_interface/bdd/distributor/DistributorSheet';

// Contenant en circulation
import FsCirculationContainerDashboard from '../../containers/operation/service_supplier_interface/circulation_containers/FsCirculationContainerDashboard';
import FsSorterDashboard from '../../containers/operation/service_supplier_interface/circulation_containers/FsSorterDashboard';
import FsWasherDashboard from '../../containers/operation/service_supplier_interface/circulation_containers/FsWasherDashboard';
import FsCollectorDashboard from '../../containers/operation/service_supplier_interface/collecte/FsCollectorsDashboard';

// Profil
import ProfilPersonalInfo from '../../containers/account/ProfilPersonalInfo';
import ProfilCompanyInfo from '../../containers/account/ProfilCompanyInfo';
import ProfilAccess from '../../containers/account/ProfilAccess';
import ProfilRoleManagement from '../../containers/account/ProfilRoleManagement';

import PreHome from '../../containers/PreHome';
import ErrorPage from '../../Components/ErrorPage';

import ChartApp from '../../containers/ChartApp';
export default function ServiceSupplierInterface() {
    const role = Cookies.get('role');
    const isAdmin = role === Constants.ENTITY_ROLE_ADMIN;
    return (
        <Routes>
            <Route
                path="/error"
                element={<ErrorPage />}
            />
            <Route
                path="/prehome"
                element={<PreHome />}
            />
            <Route
                path="/support"
                element={<Support />}
            />
            <Route
                path="/documentation"
                element={<Documentation />}
            />
            <Route
                path="/updatepassword"
                element={<UpdatePassword />}
            />
            <Route
                path="/servicesupplier/operation/home"
                element={<ServiceSupplierHome />}
            />
            <Route
                path="/servicesupplier/operation/container/list"
                element={<Container />}
            />
            <Route
                path="/servicesupplier/operation/container/add"
                element={<AddContainer />}
            />
            <Route
                path="/servicesupplier/operation/container/update"
                element={<UpdateContainer />}
            />
            <Route
                path="/servicesupplier/operation/container/sheet"
                element={<ContainerSheet />}
            />
            <Route
                path="/servicesupplier/operation/industrial/list"
                element={<Industrial />}
            />
            <Route
                path="/servicesupplier/operation/industrial/sheet"
                element={<IndustrialSheet />}
            />
            <Route
                path="/servicesupplier/operation/industrial/add"
                element={<AddIndustrial />}
            />
            <Route
                path="/servicesupplier/operation/industrial/update"
                element={<UpdateIndustrial />}
            />
            <Route
                path="/servicesupplier/operation/industrial/address/add"
                element={<AddIndustrialAddress />}
            />
            <Route
                path="/servicesupplier/operation/industrial/address/update"
                element={<UpdateIndustrialAddress />}
            />
            <Route
                path="/servicesupplier/operation/industrial/product/add"
                element={<AddIndustrialProduct />}
            />
            <Route
                path="/servicesupplier/operation/industrial/product/update"
                element={<UpdateIndustrialProduct />}
            />
            <Route
                path="/servicesupplier/operation/industrial/product/list"
                element={<IndustrialProducts />}
            />
            <Route
                path="/servicesupplier/operation/supplier/list"
                element={<Supplier />}
            />
            <Route
                path="/servicesupplier/operation/supplier/sheet"
                element={<SupplierSheet />}
            />
            <Route
                path="/servicesupplier/operation/supplier/add"
                element={<AddSupplier />}
            />
            <Route
                path="/servicesupplier/operation/supplier/update"
                element={<UpdateSupplier />}
            />

            <Route
                path="/servicesupplier/operation/supplier/product/add"
                element={<AddProduct />}
            />
            <Route
                path="/servicesupplier/operation/supplier/product/update"
                element={<UpdateProduct />}
            />
            <Route
                path="/servicesupplier/operation/depot/list"
                element={<Depot />}
            />
            <Route
                path="/servicesupplier/operation/depot/sheet"
                element={<DepotSheet />}
            />
            <Route
                path="/servicesupplier/operation/depot/add"
                element={<AddDepot />}
            />
            <Route
                path="/servicesupplier/operation/depot/update"
                element={<UpdateDepot />}
            />
            <Route
                path="/servicesupplier/operation/store/list"
                element={<Store />}
            />
            <Route
                path="/servicesupplier/operation/store/sheet"
                element={<StoreSheet />}
            />
            <Route
                path="/servicesupplier/operation/store/add"
                element={<AddStore />}
            />
            <Route
                path="/servicesupplier/operation/store/update"
                element={<UpdateStore />}
            />
            <Route
                path="/servicesupplier/operation/collector/list"
                element={<Collector />}
            />
            <Route
                path="/servicesupplier/operation/collector/pickup"
                element={<CollectorPickup />}
            />

            <Route
                path="/servicesupplier/operation/collector/sheet"
                element={<CollectorSheet />}
            />
            <Route
                path="/servicesupplier/operation/collector/add"
                element={<AddCollector />}
            />
            <Route
                path="/servicesupplier/operation/collector/update"
                element={<UpdateCollector />}
            />
            <Route
                path="/servicesupplier/operation/rvmowner/list"
                element={<Rvmowner />}
            />
            <Route
                path="/servicesupplier/operation/rvmowner/add"
                element={<AddRvmowner />}
            />
            <Route
                path="/servicesupplier/operation/rvmowner/update"
                element={<UpdateRvmowner />}
            />
            <Route
                path="/servicesupplier/operation/rvmowner/sheet"
                element={<RvmownerSheet />}
            />
            <Route
                path="/servicesupplier/operation/washer/list"
                element={<Washer />}
            />
            <Route
                path="/servicesupplier/operation/washer/add"
                element={<AddWasher />}
            />
            <Route
                path="/servicesupplier/operation/washer/update"
                element={<UpdateWasher />}
            />
            <Route
                path="/servicesupplier/operation/washer/sheet"
                element={<WasherSheet />}
            />
            <Route
                path="/servicesupplier/operation/washer/sku/add"
                element={<AddWasherSku />}
            />
            <Route
                path="/servicesupplier/operation/washer/sku/update"
                element={<UpdateWasherSku />}
            />
            <Route
                path="/servicesupplier/operation/sorter/list"
                element={<Sorter />}
            />
            <Route
                path="/servicesupplier/operation/sorter/add"
                element={<AddSorter />}
            />
            <Route
                path="/servicesupplier/operation/sorter/update"
                element={<UpdateSorter />}
            />
            <Route
                path="/servicesupplier/operation/sorter/sheet"
                element={<SorterSheet />}
            />
            <Route
                path="/servicesupplier/operation/sorter/washer/add"
                element={<AddSorterWasherLink />}
            />
            <Route
                path="/servicesupplier/operation/sorter/washer/update"
                element={<UpdateWasherSorterLink />}
            />
            <Route
                path="/servicesupplier/operation/operator/list"
                element={<Operator />}
            />
            <Route
                path="/servicesupplier/operation/operator/sheet"
                element={<OperatorSheet />}
            />
            <Route
                path="/servicesupplier/operation/operator/add"
                element={<AddOperator />}
            />
            <Route
                path="/servicesupplier/operation/operator/update"
                element={<UpdateOperator />}
            />

            <Route
                path="/servicesupplier/operation/storeowner/list"
                element={<Storeowner />}
            />
            <Route
                path="/servicesupplier/operation/storeowner/add"
                element={<AddStoreowner />}
            />
            <Route
                path="/servicesupplier/operation/storeowner/update"
                element={<UpdateStoreowner />}
            />
            <Route
                path="/servicesupplier/operation/storeowner/sheet"
                element={<StoreownerSheet />}
            />
            <Route
                path="/servicesupplier/operation/distributor/list"
                element={<Distributor />}
            />
            <Route
                path="/servicesupplier/operation/distributor/add"
                element={<AddDistributor />}
            />
            <Route
                path="/servicesupplier/operation/distributor/update"
                element={<UpdateDistributor />}
            />
            <Route
                path="/servicesupplier/operation/distributor/sheet"
                element={<DistributorSheet />}
            />
            <Route
                path="/servicesupplier/operation/indusneeds/dashboard"
                element={<FsIndusNeedDashboard />}
            />
            <Route
                path="/servicesupplier/operation/indusneeds/list"
                element={<IndustrialNeedList />}
            />
            <Route
                path="/servicesupplier/operation/indusneeds/sheet"
                element={<IndustrialNeedSheet />}
            />
            <Route
                path="/servicesupplier/operation/indusneeds/assign"
                element={<Attribution />}
            />
            <Route
                path="/servicesupplier/operation/indusneeds/untreated"
                element={<Untreatedneed />}
            />
            <Route
                path="/servicesupplier/operation/circulationcontainers/dashboard"
                element={<FsCirculationContainerDashboard />}
            />
            <Route
                path="/servicesupplier/operation/circulationcontainers/sorter"
                element={<FsSorterDashboard />}
            />
            <Route
                path="/servicesupplier/operation/circulationcontainers/washer"
                element={<FsWasherDashboard />}
            />
            <Route
                path="/servicesupplier/operation/circulationcontainers/collector/dashboard"
                element={<FsCollectorDashboard />}
            />
            <Route
                path="/servicesupplier/myprofil/personal_info"
                element={<ProfilPersonalInfo />}
            />
            <Route
                path="/servicesupplier/myprofil/company_info"
                element={<ProfilCompanyInfo />}
            />
            <Route
                path="/servicesupplier/myprofil/access"
                element={<ProfilAccess />}
            />
            {isAdmin ? (
                <Route
                    path="/servicesupplier/myprofil/rolemanagement"
                    element={<ProfilRoleManagement />}
                />
            ) : null}
            {isAdmin ? (
                <Route
                    path="/servicesupplier/myprofil/user/add"
                    element={<CreateUser />}
                />
            ) : null}
            {isAdmin ? (
                <Route
                    path="/servicesupplier/myprofil/user/sheet"
                    element={<UserSheet />}
                />
            ) : null}
            <Route
                path="/servicesupplier/chart"
                element={<ChartApp />}
            />
            <Route
                path="/servicesupplier/operation/unknown"
                element={<Unknown />}
            />
            <Route
                path="*"
                element={
                    <Navigate
                        replace
                        to="/servicesupplier/operation/unknown"
                    />
                }
            />
        </Routes>
    );
}
