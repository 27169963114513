import { useState, useEffect } from 'react';
import { addIndustrialAddress, getIndustrials } from '../../../../../../API/IndustrialApi';
import { useLocation, useNavigate } from 'react-router-dom';
import { checkEntityAdressFormErrors, checkIsInputEmpty } from '../../../../../../Utils';
import AddressSearch from '../../../../../../Components/AddressSearch';
export default function AddIndustrialAddress() {
    const location = useLocation();
    const navigate = useNavigate();
    const { entity_id } = location.state || {};
    const [industrialList, setIndustrialList] = useState([]);
    const [errorMessage, setErrorMessage] = useState([]);
    const [errorEmpty, setErrorEmpty] = useState('');
    const [formData, setFormData] = useState({
        entity_id: '',
        name: '',
        address_1: '',
        address_2: '',
        city: '',
        zipcode: '',
        contact_name: '',
        contact_phone: '',
        contact_email: '',
        comments: '',
        delivery_window: '',
        lat: '',
        long: '',
    });

    const [formErrors, setFormErrors] = useState({
        entity_id: false,
        name: false,
        address_1: false,
        address_2: false,
        city: false,
        zipcode: false,
        contact_name: false,
        contact_phone: false,
        contact_email: false,
        comments: false,
        delivery_window: false,
    });

    const requiredFields = [
        'name',
        'address_1',
        'city',
        'zipcode',
        'contact_name',
        'contact_phone',
        'contact_email',
        'delivery_window',
    ];

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        const _getAllIndustrials = async () => {
            const data = await getIndustrials();
            setIndustrialList(data);

            let industrial_list = document.getElementById('optionsSelect');
            if (entity_id > 0) {
                industrial_list.value = entity_id;
                industrial_list.dispatchEvent(new Event('change'));
                setFormData((formData) => ({ ...formData, entity_id: entity_id }));
                industrial_list.disabled = true;
            }
        };
        _getAllIndustrials();
    }, [entity_id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage([]);
        setErrorEmpty('');

        const inputEmpty = checkIsInputEmpty(requiredFields, formData, setFormErrors, setErrorEmpty);
        if (!inputEmpty) {
            const checkFormat = checkEntityAdressFormErrors(formData, formErrors, setFormErrors, setErrorMessage);
            if (checkFormat) {
                return;
            } else {
                const addAdressData = await addIndustrialAddress(formData);
                if (addAdressData) {
                    alert("L'adresse a été créé avec succès");
                    navigate(-1);
                } else {
                    alert("une erreur est survenue lors de l'ajout de l'adresse");
                }
            }
        } else {
            console.log('Il reste des champs obligatoires vides');
        }
    };
    return (
        <main>
            <section className="big-section">
                <h2>Ajouter une adresse</h2>

                <div className="form-container">
                    <section>
                        <div>
                            <label>Nom de la marque:</label>
                            <select
                                name="entity_id"
                                id="optionsSelect"
                                onChange={handleChange}>
                                <option>Choisissez une option</option>
                                {industrialList.map((option, index) => (
                                    <option
                                        key={index}
                                        value={option.id}>
                                        {option.company_name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div>
                            <label>Nom de l&apos;adresse:</label>
                            <input
                                className={formErrors.name ? 'error' : ''}
                                type="text"
                                name="name"
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </section>
                    <section>
                        <AddressSearch
                            formData={formData}
                            setFormData={setFormData}
                            formErrors={formErrors}
                            setFormErrors={setFormErrors}
                            handleChange={handleChange}
                        />
                    </section>
                    <section>
                        <div>
                            <label>Nom du contact :</label>
                            <input
                                className={formErrors.contact_name ? 'error' : ''}
                                type="text"
                                name="contact_name"
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <label>Téléphone du contact :</label>
                            <input
                                className={formErrors.contact_phone ? 'error' : ''}
                                type="text"
                                name="contact_phone"
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <label>Email du contact :</label>
                            <input
                                className={formErrors.contact_email ? 'error' : ''}
                                type="text"
                                name="contact_email"
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </section>
                    <section>
                        <div>
                            <label>Créneau de Livraison :</label>
                            <input
                                className={formErrors.delivery_window ? 'error' : ''}
                                type="text"
                                name="delivery_window"
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <label>Commentaires Livraison :</label>
                            <input
                                className={formErrors.comments ? 'error' : ''}
                                type="text"
                                name="comments"
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </section>
                </div>
                <p className="error-form">{errorEmpty && errorEmpty}</p>
                {errorMessage.length
                    ? errorMessage.map((item, index) => {
                          return (
                              <div
                                  className="error-form"
                                  key={index}>
                                  {item}
                              </div>
                          );
                      })
                    : null}
                <div className="button-confirm">
                    <button
                        className="button-actif"
                        onClick={handleSubmit}>
                        Ajouter l&apos;adresse
                    </button>
                </div>
            </section>
        </main>
    );
}
