import { Routes, Route, Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import * as Constants from '../../Constants';
import CdcHome from '../../containers/finance/cdc_interface/CdcHome';
import CdcIndustrial from '../../containers/finance/cdc_interface/CdcIndustrial';
import CdcIndustrialDetails from '../../containers/finance/cdc_interface/CdcIndustrialDetails';
import CdcStore from '../../containers/finance/cdc_interface/CdcStore';
import CdcStoreDetails from '../../containers/finance/cdc_interface/CdcStoreDetails';
import CdcCollector from '../../containers/finance/cdc_interface/CdcCollector';
import CdcCollectorDetails from '../../containers/finance/cdc_interface/CdcCollectorDetails';
import Documentation from '../../containers/Documentation';
import Support from '../../containers/account/Support';
import UpdatePassword from '../../containers/account/UpdatePassword';
import PreHome from '../../containers/PreHome';
import ErrorPage from '../../Components/ErrorPage';
import ProfilPersonalInfo from '../../containers/account/ProfilPersonalInfo';
import ProfilCompanyInfo from '../../containers/account/ProfilCompanyInfo';
import ProfilAccess from '../../containers/account/ProfilAccess';
import ProfilRoleManagement from '../../containers/account/ProfilRoleManagement';
import CreateUser from '../../containers/account/CreateUser';
import UserSheet from '../../containers/account/UserSheet';
import Unknown from '../../containers/account/Unknown';

export default function CdcInterface() {
    const role = Cookies.get('role');
    const isAdmin = role === Constants.ENTITY_ROLE_ADMIN;
    return (
        <Routes>
            <Route
                path="/error"
                element={<ErrorPage />}
            />
            <Route
                path="/prehome"
                element={<PreHome />}
            />
            <Route
                path="/support"
                element={<Support />}
            />
            <Route
                path="/documentation"
                element={<Documentation />}
            />
            <Route
                path="/updatepassword"
                element={<UpdatePassword />}
            />
            <Route
                path="/cdc/financial/dashboard"
                element={<CdcHome />}
            />

            <Route
                path="/cdc/financial/stores"
                element={<CdcStore />}
            />
            <Route
                path="/cdc/financial/stores/details"
                element={<CdcStoreDetails />}
            />
            <Route
                path="/cdc/financial/industrial"
                element={<CdcIndustrial />}
            />
            <Route
                path="/cdc/financial/industrial/details"
                element={<CdcIndustrialDetails />}
            />
            <Route
                path="/cdc/financial/collector"
                element={<CdcCollector />}
            />
            <Route
                path="/cdc/financial/collector/details"
                element={<CdcCollectorDetails />}
            />
            <Route
                path="/cdc/myprofil/personal_info"
                element={<ProfilPersonalInfo />}
            />
            <Route
                path="/cdc/myprofil/company_info"
                element={<ProfilCompanyInfo />}
            />
            <Route
                path="/cdc/myprofil/access"
                element={<ProfilAccess />}
            />
            {isAdmin ? (
                <Route
                    path="/cdc/myprofil/rolemanagement"
                    element={<ProfilRoleManagement />}
                />
            ) : null}
            {isAdmin ? (
                <Route
                    path="/cdc/myprofil/user/add"
                    element={<CreateUser />}
                />
            ) : null}
            {isAdmin ? (
                <Route
                    path="/cdc/myprofil/user/sheet"
                    element={<UserSheet />}
                />
            ) : null}
            <Route
                path="/cdc/operation/unknown"
                element={<Unknown />}
            />
            <Route
                path="*"
                element={
                    <Navigate
                        replace
                        to="/cdc/operation/unknown"
                    />
                }
            />
        </Routes>
    );
}
