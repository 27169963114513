import { useState, useEffect, useRef, useCallback } from 'react';
import { getCollectorsByRvmowner, getCollectorsNextCollecte } from '../../../../API/CollectorApi';
import { getCollectorsByRvmownerCheck } from '../../../../API/CheckApi/CheckCollectorApi';
import { _defineStatus, _defineCollecteurStatus, _defineCollectorType, useClickOutside } from '../../../../Utils';
import { useNavigate } from 'react-router-dom';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import { format } from 'date-fns';
import { Jwt } from '../../../../Jwt';
import * as Constant from '../../../../Constants';
import SearchComponent from '../../../../Components/searchComponent';
import * as Sentry from '@sentry/react';
export default function Rvm() {
    const navigate = useNavigate();
    const userContext = new Jwt();
    const [filterSelected, setFilterSelected] = useState('asc');

    const [AllCollectorList, setAllCollectorList] = useState([]);
    const [originalCollectorList, setOriginalCollectorList] = useState([]);
    const [advancedSearch, setAdvancedSearch] = useState(false);
    const [typeTab, setTypeTab] = useState([]);
    const [selectedType, setSelectedType] = useState('');
    const [statusTab, setStatusTab] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState('');
    const [collectorNames, setCollectorNames] = useState([]);
    const [showOptions, setShowOptions] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const dropdownRef = useRef(null);

    const handleClickOutside = () => {
        setShowOptions(false);
    };
    useClickOutside(dropdownRef, handleClickOutside);

    useEffect(() => {
        const fetchAllCollectors = async () => {
            try {
                const result = await getCollectorsByRvmowner(userContext.entityId);
                const resultCheck = getCollectorsByRvmownerCheck(result);
                if (result.length && resultCheck !== true) {
                    console.error(resultCheck);
                    Sentry.captureException(resultCheck);
                    navigate('/error');
                    return;
                }

                for (let i = 0; i < result.length; i++) {
                    const nextCollecte = await getCollectorsNextCollecte(result[i].store.id);
                    if (nextCollecte[0].store.storeEvents.length) {
                        result[i].nextCollecte = nextCollecte[0].store.storeEvents[0];
                    } else {
                        result[i].nextCollecte = null;
                    }
                }
                const sortedData = result.sort((a, b) => a.store_name.localeCompare(b.store_name));
                setFilterSelected('asc');
                setAllCollectorList(sortedData);
                setOriginalCollectorList(sortedData);
            } catch (error) {
                console.error('Erreur lors de la récupération des collectors', error);
            }
        };
        fetchAllCollectors();
    }, [userContext.entityId, navigate]);

    useEffect(() => {
        const fetchCollectorNames = async () => {
            try {
                if (searchTerm.length > 2) {
                    const result = originalCollectorList.filter((item) => item.store_name.toLowerCase().includes(searchTerm.toLowerCase()));
                    if (result.length) {
                        setAllCollectorList(result);
                        setCollectorNames(result);
                        setErrorMessage('');
                    }
                }
            } catch (error) {
                console.error('Erreur lors de la récupération des collecteurs', error);
            }
        };

        fetchCollectorNames();
    }, [searchTerm, originalCollectorList]);

    const handleInputChange = (e) => {
        setSearchTerm(e.target.value.toLowerCase());
        setShowOptions(true);
    };

    const handleSelectOption = (option) => {
        setSearchTerm(option.name);
        setShowOptions(false);
        navigate('/rvmowner/operation/collector/sheet', { state: { id: option.id } });
    };

    const sortDataAsc = () => {
        const sortedData = [...AllCollectorList].sort((a, b) => a.store_name.localeCompare(b.store_name));
        setFilterSelected('asc');
        setAllCollectorList(sortedData);
    };

    const sortDataDesc = () => {
        const sortedData = [...AllCollectorList].sort((a, b) => b.store_name.localeCompare(a.store_name));
        setFilterSelected('desc');
        setAllCollectorList(sortedData);
    };

    useEffect(() => {
        const fetchAdvancedData = async () => {
            try {
                const data = await getCollectorsByRvmowner(userContext.entityId);
                const dataCheck = getCollectorsByRvmownerCheck(data);
                if (data.length && dataCheck !== true) {
                    console.error(dataCheck);
                    Sentry.captureException(dataCheck);
                    navigate('/error');
                    return;
                }
                setOriginalCollectorList(data);
                const typeUnique = [...new Set(data.map((objet) => objet.type))];
                setTypeTab(typeUnique);
                const statusUnique = [...new Set(data.map((objet) => objet.status))];
                setStatusTab(statusUnique);
            } catch (error) {
                console.error('Erreur lors de la récupération des produits', error);
            }
        };

        if (advancedSearch) {
            fetchAdvancedData();
        }
    }, [advancedSearch, userContext.entityId, navigate]);

    const handleChange = (e, type) => {
        const selectedValue = e.target.value;

        switch (type) {
            case 'type':
                setSelectedType(selectedValue);
                break;
            case 'zipcode':
                setSelectedStatus(selectedValue);
                break;
            default:
                break;
        }
    };

    const filterData = useCallback(async () => {
        let filteredData = originalCollectorList;

        if (selectedType) {
            let num = parseInt(selectedType);
            filteredData = filteredData.filter((objet) => objet.type === num);
        }

        if (selectedStatus) {
            filteredData = filteredData.filter((objet) => String(objet.status) === selectedStatus);
        }
        setAllCollectorList(filteredData);
    }, [originalCollectorList, selectedType, selectedStatus]);

    useEffect(() => {
        filterData();
    }, [filterData]);

    const handleCollectorClick = (item) => {
        navigate('/rvmowner/operation/collector/sheet', { state: { id: item.id } });
    };

    return (
        <main>
            <section className="big-section">
                <h2>Les RVMs ({AllCollectorList.length})</h2>

                <div
                    className="top"
                    style={{ flexDirection: 'column' }}>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                        <SearchComponent
                            dropdownRef={dropdownRef}
                            showOptions={showOptions}
                            searchTerm={searchTerm}
                            handleInputChange={handleInputChange}
                            productsNames={collectorNames}
                            handleSelectOption={handleSelectOption}
                            placeholder="Rechercher un collecteur"
                            searchKey="store_name"
                        />

                        <div className="top-button-sort">
                            <div className="filter">
                                <p className="sort">Trier par</p>
                                <div>
                                    <p
                                        className={filterSelected === 'asc' ? 'selected' : 'classic'}
                                        onClick={sortDataAsc}>
                                        A &gt; Z
                                    </p>
                                    <p
                                        className={filterSelected === 'desc' ? 'selected' : 'classic'}
                                        onClick={sortDataDesc}>
                                        Z &gt; A
                                    </p>
                                </div>
                            </div>
                            <div
                                onClick={() => {
                                    setAdvancedSearch(!advancedSearch);
                                }}
                                className="button-actif">
                                Recherche avancée {advancedSearch ? <BiChevronUp size={20} /> : <BiChevronDown size={20} />}
                            </div>
                        </div>
                    </div>

                    {advancedSearch && (
                        <div
                            className="advanced-search"
                            style={{ display: 'flex', marginTop: '1.25rem', gap: '1.25rem', justifyContent: 'end' }}>
                            <select
                                id="city"
                                value={selectedType}
                                onChange={(e) => handleChange(e, 'type')}>
                                <option value="">Type</option>
                                {typeTab.length > 0 &&
                                    typeTab.map((type, index) => (
                                        <option
                                            key={index}
                                            value={type}>
                                            {_defineCollectorType(type)}
                                        </option>
                                    ))}
                            </select>
                            <select
                                id="sku"
                                value={selectedStatus}
                                onChange={(e) => handleChange(e, 'zipcode')}>
                                <option value="">Sélectionnez un statut</option>
                                {statusTab.length > 0 &&
                                    statusTab.map((status, index) => (
                                        <option
                                            key={index}
                                            value={status}>
                                            {_defineStatus(status).name}
                                        </option>
                                    ))}
                            </select>
                        </div>
                    )}
                </div>
                {errorMessage && (
                    <div style={{ width: '100%', padding: '0.5rem', textAlign: 'center', color: Constant.$rouge, marginBottom: '1.25rem' }}>
                        <p>{errorMessage}</p>
                    </div>
                )}
                <div className="tab-item">
                    <div
                        className="pre-filled-fields-1-several-item"
                        style={{ fontWeight: '600', width: '100%' }}>
                        <p style={{ flex: 0.5 }}>ID</p>
                        <p>Magasin</p>
                        <p>Ville</p>
                        <p>Fab & Modèle</p>
                        <p>Capacité</p>
                        <p>Statut</p>
                        <p>Tx remplissage</p>
                        <p>Date demande collecte</p>
                        <p>Trieur associé</p>
                        <p>Clé</p>
                    </div>
                    {AllCollectorList.length ? (
                        AllCollectorList.map((item, index) => {
                            return (
                                <div
                                    style={{ display: 'flex', flexWrap: 'wrap', width: '100%', justifyContent: 'space-between', cursor: 'pointer' }}
                                    key={index}>
                                    <div
                                        style={{ width: '100%' }}
                                        onClick={() => {
                                            handleCollectorClick(item);
                                        }}
                                        className="pre-filled-fields-2-several-item">
                                        <p style={{ flex: 0.5 }}>{item.id}</p>
                                        <p>{item.store_name}</p>
                                        <p>{item.store_city}</p>
                                        <p>
                                            {item.supplier_name} - {item.supplier_model}
                                        </p>
                                        <p>{item.capacity}</p>
                                        <p
                                            style={{
                                                backgroundColor: _defineCollecteurStatus(item.status).color,
                                                color: Constant.$blanc,
                                                flex: 1,
                                            }}>
                                            {_defineCollecteurStatus(item.status).name}
                                        </p>

                                        <p>{item.filling_rate}</p>
                                        <p>{item.nextCollecte ? format(item.nextCollecte.time, 'yyyy-MM-dd') : ''}</p>
                                        <p>{item.sorter_name}</p>
                                        <p>{item.token_count}</p>
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <div className="no-data-graph ">Aucun collecteur enregistré.</div>
                    )}
                </div>
            </section>
        </main>
    );
}
