import { getGearAPIApiEndpoint } from './endpoints';
import * as Sentry from '@sentry/react';
import { defaultHeaders } from './headers';

const GEAR_API_BASE_URL = getGearAPIApiEndpoint();

const BOCOLOCO_GET_FINANCIAL_CASHIN_ALLBYDATES = '/financial/cashin/bydates';
const BOCOLOCO_GET_FINANCIAL_CASHIN_GETCSV = '/financial/cashin/getcsv';
const BOCOLOCO_GET_FINANCIAL_CASHIN_GETPDF = '/financial/cashin/getpdf';
const BOCOLOCO_GET_FINANCIAL_CASHIN_BYINDUSANDDATES = '/financial/cashin/byindus/bydates';

export function getAllFInancialCashInByDates(startDate, endDate) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_FINANCIAL_CASHIN_ALLBYDATES;
    var url_args = '?startDate=' + startDate + '&endDate=' + endDate;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function downloadReportingCashInCsv(key) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_FINANCIAL_CASHIN_GETCSV;
    var url_args = '?key=' + key;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.blob();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .then((blob) => {
            if (blob) {
                const downloadUrl = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = downloadUrl;
                a.download = key;
                document.body.appendChild(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(downloadUrl);
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function downloadReportingCashInPdf(key) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_FINANCIAL_CASHIN_GETPDF;
    const url_args = '?key=' + key;
    const url = encodeURI(url_base + url_args);

    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.blob();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .then((blob) => {
            if (blob) {
                const downloadUrl = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = downloadUrl;
                a.download = key;
                document.body.appendChild(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(downloadUrl);
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function getFinancialCashInByIndusAndDates(startDate, endDate, industrialId) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_FINANCIAL_CASHIN_BYINDUSANDDATES;
    var url_args = '?startDate=' + startDate + '&endDate=' + endDate + '&industrialId=' + industrialId;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}
