import { useState, useEffect } from 'react';
import { getStoreByStoreownerId } from '../../../API/StoreApi';
import { _defineStatus } from '../../../Utils';
import { Jwt } from '../../../Jwt';
import { Link } from 'react-router-dom';
import { Pencil } from '@phosphor-icons/react';
import * as Constants from '../../../Constants';

export default function StoreSheet() {
    const [storeData, setStoreData] = useState([]);
    const [storeBoxes, setStoreBoxes] = useState([]);
    const [rvmownerData, setRvmownerData] = useState([]);
    const userContext = new Jwt();

    useEffect(() => {
        const fetchStoreData = async () => {
            try {
                const { gearApi } = await import('../../../gearApi.js');

                const storeRawData = await getStoreByStoreownerId(userContext.entityId);

                setStoreData(storeRawData[0]);

                const groupedData = storeRawData[0].storeBoxes.reduce((acc, item) => {
                    const name = item.container.name;

                    if (!acc[name]) {
                        acc[name] = [];
                    }

                    if (item.status === Constants.STORESBOXES_STATUS_INSTORE) {
                        acc[name].push(item);
                    }

                    return acc;
                }, {});

                const result = Object.keys(groupedData).map((name) => ({
                    name,
                    items: groupedData[name],
                }));

                setStoreBoxes(result);

                const rvmownerRawData = await gearApi.rvmowner.getRvmownerById({ rvmownerId: storeRawData[0].rvmowner_id });
                setRvmownerData(rvmownerRawData);
            } catch (error) {
                console.error('Erreur lors de la récupération des infos du magasin', error);
            }
        };
        fetchStoreData();
    }, [userContext.entityId]);

    return (
        <main>
            {storeData && (
                <div className="sheet">
                    <div className="sheet-top-infos">
                        <div className="title">
                            <Link
                                className="update-depot"
                                to={'/storeowner/operation/store/update'}
                                state={{ id: storeData.id }}>
                                <div className="icon">
                                    <Pencil size={20} />
                                </div>
                            </Link>
                            <h2>{storeData.name}</h2>
                            <div
                                className="status"
                                style={{ backgroundColor: _defineStatus(storeData.status).color }}>
                                {_defineStatus(storeData.status).name}
                            </div>
                        </div>
                        <section className="item-info-container">
                            <div className="border">
                                <div>
                                    <p>
                                        Société Mère : <span>{storeData.company_name}</span>
                                    </p>
                                    <p>
                                        Nom : <span>{storeData.name}</span>
                                    </p>
                                    <p>
                                        Adresse 1 : <span>{storeData.address_1}</span>
                                    </p>
                                    <p>
                                        Adresse 2 : <span>{storeData.address_2}</span>
                                    </p>
                                    <p>
                                        Ville : <span>{storeData.city}</span>
                                    </p>
                                    <p>
                                        Code postal : <span>{storeData.zipcode}</span>
                                    </p>
                                </div>
                            </div>

                            <div className="border">
                                <div>
                                    <p>
                                        Nom du contact : <span>{storeData.contact_name}</span>
                                    </p>
                                    <p>
                                        Email du contact : <span>{storeData.contact_email}</span>
                                    </p>
                                    <p>
                                        Téléphone du contact : <span>{storeData.contact_phone}</span>
                                    </p>
                                </div>
                            </div>
                            <div className="border">
                                <div>
                                    <p>
                                        Gest. RVM : <span>{rvmownerData.name}</span>
                                    </p>
                                    <p>
                                        Adresse 1 : <span>{rvmownerData.address_1}</span>
                                    </p>
                                    <p>
                                        Ville : <span>{rvmownerData.city}</span>
                                    </p>
                                    <p>
                                        Code postal : <span>{rvmownerData.zipcode}</span>
                                    </p>
                                </div>
                            </div>
                            <div className="border">
                                <div>
                                    <p>
                                        Nom du contact : <span>{rvmownerData.contact_name}</span>
                                    </p>
                                    <p>
                                        Email: <span>{rvmownerData.contact_email}</span>
                                    </p>
                                    <p>
                                        Tel : <span>{rvmownerData.contact_phone}</span>
                                    </p>
                                </div>
                            </div>
                        </section>
                    </div>
                    <section className="big-section">
                        <div className="top">
                            <h2 style={{ margin: '0' }}>Etat du stock contenants secondaires </h2>
                        </div>
                        <div style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
                            <p style={{ fontSize: 16, fontWeight: 'bolder' }}>
                                Nombre initial de caisses : <span style={{ fontWeight: 400 }}>{storeData.initial_nb_boxes}</span>{' '}
                            </p>
                        </div>
                        <div className="tab-item">
                            <div
                                className="pre-filled-fields-1-several-item"
                                style={{ fontWeight: '600' }}>
                                <p>Type</p>
                                <p>En magasin</p>
                                {/* <p>Pleines</p> */}
                            </div>

                            {storeBoxes.length ? (
                                storeBoxes.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className="pre-filled-fields-2-several-item">
                                            <p>{item.name}</p>
                                            <p>{item.items.reduce((acc, item) => (item.status === Constants.STORESBOXES_STATUS_INSTORE ? acc + 1 : acc), 0)}</p>
                                            {/* <p>{item.items.reduce((acc, item) => (item.empty === false ? acc + 1 : acc), 0)}</p> */}
                                        </div>
                                    );
                                })
                            ) : (
                                <div className="no-data-graph ">Aucun verrier disponible.</div>
                            )}
                        </div>
                    </section>
                </div>
            )}
        </main>
    );
}
