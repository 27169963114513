import { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { getReturnByDates } from '../../../../API/CollectorReturnApi';
import { getReturnByDatesCheck } from '../../../../API/CheckApi/CheckCollectorReturnApi';
import { Jwt } from '../../../../Jwt';
import { endOfDay } from 'date-fns';
import MultiLineChart from '../../../../Components/MultiLineChart';
import { getRegionsName, _defineCollectorType, _defineProductCat, regionTab } from '../../../../Utils.js';
import DateFilter from '../../../../Components/DateFilter';
import PieChart from '../../../../Components/PieChart.jsx';
import * as Constant from '../../../../Constants';
import { reduceDataByKeyAndQty, reduceByMonthAndKey, addMonthToTab } from '../../../../UtilsData.js';
import * as Sentry from '@sentry/react';
import { useNavigate } from 'react-router-dom';
export default function IndusDataConsumerRegions() {
    const navigate = useNavigate();
    const now = new Date();
    const userContext = new Jwt();
    const [originalReturns, setOriginalReturns] = useState([]);
    const [allReturns, setAllReturns] = useState([]);
    const [returnsByRegion, setReturnsByRegion] = useState([]);
    const [tauxReturnByRegion, setTauxReturnByRegion] = useState([]);
    const [myReturns, setMyReturns] = useState([]);
    const [originalMyReturns, setOriginalMyReturns] = useState([]);
    const [myReturnsByRegion, setMyReturnsByRegion] = useState([]);
    const [tauxMyReturnByRegion, setTauxMyReturnByRegion] = useState([]);
    const [formattedTabGlobalData, setFormattedTabGlobalData] = useState([]);
    const [formattedTabMyRefsData, setFormattedTabMyRefsData] = useState([]);

    // FILTRES
    const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), 0, 1));
    const [endDate, setEndDate] = useState(now);
    const [skuSelected, setSkuSelected] = useState('');
    const [eanSelected, setEanSelected] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            setAllReturns([]);
            setReturnsByRegion([]);
            setTauxReturnByRegion([]);
            setMyReturns([]);
            setMyReturnsByRegion([]);
            setTauxMyReturnByRegion([]);
            setFormattedTabGlobalData([]);
            setFormattedTabMyRefsData([]);
            setOriginalReturns([]);
            setOriginalMyReturns([]);

            // NB ALL RETURNS
            const allReturnsRaw = await getReturnByDates(startDate.toISOString(), endOfDay(endDate.toISOString()));
            const allReturnsCheck = getReturnByDatesCheck(allReturnsRaw);
            if (allReturnsRaw.length && allReturnsCheck !== true) {
                console.error(allReturnsCheck);
                Sentry.captureException(allReturnsCheck);
                navigate('/error');
                return;
            }

            setOriginalReturns(allReturnsRaw);

            let allReturnsRawFiltered = [];
            if (skuSelected) {
                allReturnsRawFiltered = allReturnsRaw.filter((container) => container.sku === skuSelected);
            } else {
                allReturnsRawFiltered = allReturnsRaw;
            }
            setAllReturns(allReturnsRawFiltered);
            // EVOLUTION RETURNS BY REGIONS
            const countedReturnsByRegions = reduceByMonthAndKey(allReturnsRawFiltered, 'returnedAt', 'collector.store.region', 'qty', 'region');

            const returnsByRegionsRaw = addMonthToTab(
                countedReturnsByRegions,
                startDate.toISOString(),
                endOfDay(endDate.toISOString()),
                'region',
                'month',
                'quantity',
            );
            setReturnsByRegion(returnsByRegionsRaw);

            // TAUX DE RETOUR RÉGIONAL
            const countedReturnsByRegionQty = reduceDataByKeyAndQty(allReturnsRawFiltered, 'collector.store.region');
            const returnsByRegionQtyRaw = Object.keys(countedReturnsByRegionQty).map((region) => ({
                region: region,
                taux: ((countedReturnsByRegionQty[region] / allReturnsRawFiltered.length) * 100).toFixed(1),
            }));

            const updatedReturnsData = [...returnsByRegionQtyRaw];

            regionTab.forEach((region) => {
                if (!updatedReturnsData.some((item) => item.region === region)) {
                    updatedReturnsData.push({ region, taux: 0 });
                }
            });
            setTauxReturnByRegion(updatedReturnsData);

            // EXTRACT GLOBAL MARCHE
            const formattedTabGlobalDataRaw = allReturnsRawFiltered.map((item) => {
                return {
                    year: new Date(item.returnedAt).getFullYear(),
                    month: new Date(item.returnedAt).toLocaleString('default', { month: 'long' }),
                    region: getRegionsName(item.collector.store.region),
                    sku: item.entityIndustrialProduct ? item.entityIndustrialProduct.container.sku : 'nd',
                    type: _defineCollectorType(item.collector.type),
                };
            });
            setFormattedTabGlobalData(formattedTabGlobalDataRaw);

            // MES REFERENCES

            const myReturnsRaw = allReturnsRaw.filter(
                (container) => container.entityIndustrialProduct && container.entityIndustrialProduct.entity_id === userContext.entityId,
            );
            setOriginalMyReturns(myReturnsRaw);

            let myReturnsRawFiltered = [];
            if (eanSelected) {
                myReturnsRawFiltered = myReturnsRaw.filter((container) => container.ean === eanSelected);
            } else {
                myReturnsRawFiltered = myReturnsRaw;
            }

            setMyReturns(myReturnsRawFiltered);
            // EVOLUTION MY RETURNS BY REGIONS
            const countedMyReturnsByRegions = reduceByMonthAndKey(myReturnsRawFiltered, 'returnedAt', 'collector.store.region', 'qty', 'region');
            const myReturnsByRegionsRaw = addMonthToTab(
                countedMyReturnsByRegions,
                startDate.toISOString(),
                endOfDay(endDate.toISOString()),
                'region',
                'month',
                'quantity',
            );

            setMyReturnsByRegion(myReturnsByRegionsRaw);

            // TAUX DE RETOUR RÉGIONAL
            const countedMyReturnsByRegionQty = reduceDataByKeyAndQty(myReturnsRawFiltered, 'collector.store.region');
            const myReturnsByRegionQtyRaw = Object.keys(countedMyReturnsByRegionQty).map((region) => ({
                region: region,
                taux: ((countedMyReturnsByRegionQty[region] / myReturnsRawFiltered.length) * 100).toFixed(1),
            }));

            const updatedMyReturnsData = [...myReturnsByRegionQtyRaw];

            regionTab.forEach((region) => {
                if (!updatedMyReturnsData.some((item) => item.region === region)) {
                    updatedMyReturnsData.push({ region, taux: 0 });
                }
            });

            setTauxMyReturnByRegion(updatedMyReturnsData);

            // EXTRACT MES REFERENCES
            const formattedDataRaw = myReturnsRawFiltered.map((item) => {
                return {
                    year: new Date(item.returnedAt).getFullYear(),
                    month: new Date(item.returnedAt).toLocaleString('default', { month: 'long' }),
                    ean: item.ean,
                    name: item.entityIndustrialProduct.name,
                    cat: _defineProductCat(item.entityIndustrialProduct.cat),
                    sku: item.sku,
                    region: getRegionsName(item.collector.store.region),
                    type: _defineCollectorType(item.collector.type),
                };
            });
            setFormattedTabMyRefsData(formattedDataRaw);
        };
        if (startDate && endDate) fetchData();
    }, [startDate, endDate, userContext.entityId, skuSelected, eanSelected, navigate]);

    const evolutionReturnsByRegion = [...new Set(returnsByRegion.map((fd) => fd.region))];
    const evolutionReturnsByRegionQuantities = evolutionReturnsByRegion.map((region) => {
        const data = returnsByRegion.filter((fd) => fd.region === region).map((fd) => fd.quantity);
        return {
            label: getRegionsName(region),
            data,
        };
    });

    const myEvolutionReturnsByRegion = [...new Set(myReturnsByRegion.map((fd) => fd.region))];
    const myEvolutionReturnsByRegionQuantities = myEvolutionReturnsByRegion.map((region) => {
        const data = myReturnsByRegion.filter((fd) => fd.region === region).map((fd) => fd.quantity);

        return {
            label: getRegionsName(region),
            data,
        };
    });

    const resetGlobalFilter = () => {
        setSkuSelected('');
    };

    const resetMyRefsFilter = () => {
        setEanSelected('');
    };

    const customHeaderGlobal = [
        {
            label: 'Année',
            key: 'year',
        },
        {
            label: 'Mois',
            key: 'month',
        },
        {
            label: 'SKU',
            key: 'sku',
        },
        {
            label: 'Région',
            key: 'region',
        },
        {
            label: 'Type de collecteur',
            key: 'type',
        },
    ];

    const customHeaderMyRefs = [
        {
            label: 'Année',
            key: 'year',
        },
        {
            label: 'Mois',
            key: 'month',
        },
        {
            label: 'EAN',
            key: 'ean',
        },
        {
            label: 'Nom',
            key: 'name',
        },
        {
            label: 'Catégorie',
            key: 'cat',
        },
        {
            label: 'SKU',
            key: 'sku',
        },
        {
            label: 'Région',
            key: 'region',
        },
        {
            label: 'Type de collecteur',
            key: 'type',
        },
    ];
    const mySkus = [...new Set(originalReturns.map((fd) => fd.sku))];
    const myEans = [...new Set(originalMyReturns.map((fd) => fd.ean))];

    const returnByRegionLabels = tauxReturnByRegion.map((item) => getRegionsName(item.region));
    const returnByRegionQuantities = tauxReturnByRegion.map((item) => item.taux);

    const tauxMyReturnByRegionLabels = tauxMyReturnByRegion.map((item) => getRegionsName(item.region));
    const tauxMyReturnByRegionQuantities = tauxMyReturnByRegion.map((item) => item.taux);
    return (
        <main>
            <div className="data-container">
                <div className="header-title">
                    <h1>RÉGIONS</h1>
                    <div className="date-container">
                        <DateFilter
                            startDate={startDate}
                            endDate={endDate}
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                        />
                    </div>
                </div>
                <div className="header-container">
                    <div className="header-title">
                        <h1>Global marché</h1>
                    </div>
                    <div className="content-header">
                        <div className="header-container-content">
                            <select
                                onChange={(e) => setSkuSelected(e.target.value)}
                                value={skuSelected}>
                                <option value="">SKU</option>

                                {mySkus.map((option, index) => (
                                    <option
                                        key={index}
                                        value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>

                            <div
                                onClick={resetGlobalFilter}
                                className="reset">
                                <p style={{ fontWeight: 'bold' }}>X</p>
                            </div>
                        </div>

                        <div className="extract-content">
                            <div className="extract">
                                <CSVLink
                                    style={{ textDecoration: 'none', color: Constant.$blanc }}
                                    className="extract-button"
                                    headers={customHeaderGlobal}
                                    data={formattedTabGlobalData ? formattedTabGlobalData : []}
                                    filename={`product_data.csv`}
                                    target="_blank"
                                    separator={';'}>
                                    Extraire la donnée globale
                                </CSVLink>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ marginBottom: '2rem', display: 'flex', width: '100%', gap: '1%', height: '30rem' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 3 }}>
                        <section className="section-graph">
                            <div className="title">
                                Nombre total de contenants retournés : <span style={{ fontSize: 25 }}>{allReturns.length ? allReturns.length : 0}</span>
                            </div>
                        </section>
                        <section
                            className="section-graph"
                            style={{ height: '100%', marginBottom: 0 }}>
                            <div
                                className="title"
                                style={{ height: '1rem' }}>
                                Evolution du nombre de contenants retournés par région :{' '}
                            </div>
                            <div style={{ height: '90%' }}>
                                {returnsByRegion ? (
                                    <MultiLineChart
                                        initialData={returnsByRegion}
                                        datasets={evolutionReturnsByRegionQuantities}
                                    />
                                ) : (
                                    <div className="no-data-graph ">Aucune donnée.</div>
                                )}
                            </div>
                        </section>
                    </div>
                    <div style={{ flex: 2, display: 'flex', flexDirection: 'column' }}>
                        <section
                            className="section-graph"
                            style={{ height: '100%', marginBottom: 0 }}>
                            <div
                                className="title"
                                style={{ height: '2rem' }}>
                                Répartition des retours par région (%) :
                            </div>
                            <div style={{ height: '90%' }}>
                                {tauxReturnByRegion.length ? (
                                    <PieChart
                                        title={returnByRegionLabels}
                                        quantities={returnByRegionQuantities}
                                        position={'left'}
                                    />
                                ) : (
                                    <div className="no-data-graph">Aucune donnée.</div>
                                )}
                            </div>
                        </section>
                    </div>
                </div>

                <div className="header-container">
                    <h1>Mes références</h1>
                    <div className="content-header">
                        <div className="header-container-content">
                            <select
                                onChange={(e) => setEanSelected(e.target.value)}
                                value={eanSelected}>
                                <option value="">EAN</option>

                                {myEans.map((option, index) => (
                                    <option
                                        key={index}
                                        value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>

                            <div
                                onClick={resetMyRefsFilter}
                                className="reset">
                                <p style={{ fontWeight: 'bold' }}>X</p>
                            </div>
                        </div>

                        <div className="extract-content">
                            <div className="extract">
                                <CSVLink
                                    style={{ textDecoration: 'none', color: Constant.$blanc }}
                                    className="extract-button"
                                    headers={customHeaderMyRefs}
                                    data={formattedTabMyRefsData ? formattedTabMyRefsData : []}
                                    filename={`myproduct_data.csv`}
                                    target="_blank"
                                    separator={';'}>
                                    Extraire ma donnée
                                </CSVLink>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ marginBottom: '2rem', display: 'flex', width: '100%', gap: '1%', height: '30rem' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 3 }}>
                        <section className="section-graph">
                            <div className="title">
                                Nombre total de mes contenants retournés : <span style={{ fontSize: 25 }}>{myReturns.length ? myReturns.length : 0}</span>
                            </div>
                        </section>
                        <section
                            className="section-graph"
                            style={{ height: '100%', marginBottom: 0 }}>
                            <div
                                className="title"
                                style={{ height: '1rem' }}>
                                Evolution du nombre de mes contenants retournés par région :{' '}
                            </div>
                            <div style={{ height: '90%' }}>
                                {myReturnsByRegion ? (
                                    <MultiLineChart
                                        initialData={myReturnsByRegion}
                                        datasets={myEvolutionReturnsByRegionQuantities}
                                    />
                                ) : (
                                    <div className="no-data-graph ">Aucune donnée.</div>
                                )}
                            </div>
                        </section>
                    </div>
                    <div style={{ flex: 2, display: 'flex', flexDirection: 'column' }}>
                        <section
                            className="section-graph"
                            style={{ height: '100%', marginBottom: 0 }}>
                            <div
                                className="title"
                                style={{ height: '2rem' }}>
                                Répartitions retours par région (%) :
                            </div>
                            <div style={{ height: '90%' }}>
                                {tauxMyReturnByRegion.length ? (
                                    <PieChart
                                        title={tauxMyReturnByRegionLabels}
                                        quantities={tauxMyReturnByRegionQuantities}
                                        position={'left'}
                                    />
                                ) : (
                                    <div className="no-data-graph">Aucune donnée.</div>
                                )}
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </main>
    );
}
