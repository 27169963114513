import { useState, useEffect } from 'react';
import stringEntropy from 'fast-password-entropy';
import { getInfoUser, updateUserPassword } from '../../API/AccountApi';

export default function UpdatePassword() {
    const [userInfo, setUserInfo] = useState({});
    const [previousPassword, setPreviousPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [newpasswordStatus, setPasswordStatus] = useState(0);
    const [passwordMsg, setPasswordMsg] = useState('');
    const [passwordMsgColor, setPasswordMsgColor] = useState('black');
    const [passwordUpdateStatut, setPasswordUpdateStatus] = useState(0);

    const testNewPassword = (value) => {
        setPasswordUpdateStatus(0);
        setConfirmPassword('');
        console.log('Testing password strength');
        if (value !== previousPassword) {
            if (stringEntropy(value) < 50) {
                console.log('Password too weak');
                setPasswordMsg('Mot de passe trop faible');
                setPasswordMsgColor('red');
                setPasswordStatus(0);
            } else {
                console.log('Password strong enough');
                setPasswordMsg('Mot de passe fort');
                setPasswordMsgColor('green');
                setPasswordStatus(1);
            }
        } else {
            console.log('Password is the same as the previous one');
            setPasswordMsg('mot de passe identique au précédent');
            setPasswordMsgColor('red');
            setPasswordStatus(0);
        }
        setNewPassword(value);
    };

    const testConfirmPassword = (value) => {
        if (value !== newPassword) {
            console.log('Passwords do not match');
            setPasswordMsg('Les mots de passe ne correspondent pas');
            setPasswordMsgColor('red');
            setPasswordUpdateStatus(0);
        } else {
            console.log('Passwords match');
            setPasswordMsg('Ok');
            setPasswordMsgColor('green');
            setPasswordUpdateStatus(1);
        }

        setConfirmPassword(value);
    };

    const getBackgroundColor = (status) => {
        if (status === 0) return 'grey';
        if (status === 1) return '#e1f3ef';
    };

    const handleUpdatePassword = async () => {
        const userData = {
            mail: userInfo.email,
            previousPassword: previousPassword,
            newPassword: newPassword,
        };
        try {
            await updateUserPassword(userData);
            alert('Mot de passe mis à jour');
            window.location.href = '/prehome';
        } catch (error) {
            console.error('Erreur lors de la mise à jour du mot de passe', error);
        }
    };

    useEffect(() => {
        const fetchUserInfo = async () => {
            const data = await getInfoUser();
            console.log(data);
            setUserInfo(data[0]);
        };
        fetchUserInfo();
    }, []);

    return (
        <main className="updatepassword">
            <section
                className="big-section"
                style={{ width: '40%' }}>
                <h2>Mettre à jour votre mot de passe</h2>
                <div className="form-container">
                    <section>
                        <div>
                            <p>Vous etes connecté en tant que :</p>
                        </div>
                        <div>
                            <label>Utilisateur</label>
                            <p>
                                {userInfo.first_name} {userInfo.last_name}
                            </p>
                        </div>
                        <div>
                            <label>Email</label>
                            <p>{userInfo.email}</p>
                        </div>
                        <div style={{ marginTop: 50 }}>
                            <label>Mot de passe précédent</label>
                            <input
                                type="password"
                                name="previousPassword"
                                value={previousPassword || ''}
                                onChange={(e) => setPreviousPassword(e.target.value)}
                            />
                        </div>
                        <div>
                            <label>Nouveau mot de passe</label>
                            <input
                                type="password"
                                name="newPassword"
                                value={newPassword || ''}
                                onChange={(e) => testNewPassword(e.target.value)}
                            />
                        </div>
                        <div>
                            <label>Confirmer nouveau mot de passe</label>
                            <input
                                disabled={newpasswordStatus === 0 ? true : false}
                                style={{ backgroundColor: getBackgroundColor(newpasswordStatus) }}
                                type="password"
                                name="confirmPassword"
                                value={confirmPassword || ''}
                                onChange={(e) => testConfirmPassword(e.target.value)}
                            />
                        </div>
                        <div>
                            <label>Statut Mot de passe</label>
                            <p style={{ fontWeight: 'bold', color: passwordMsgColor }}>{passwordMsg}</p>
                        </div>
                        <div className="button-confirm">
                            <button
                                disabled={passwordUpdateStatut ? false : true}
                                className={passwordUpdateStatut ? 'button-actif' : 'button-inactif'}
                                onClick={handleUpdatePassword}>
                                Sauver
                            </button>
                        </div>
                    </section>
                </div>
            </section>
        </main>
    );
}
