import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Cookies from 'js-cookie';
import Login from './containers/account/Login';
import SplashScreen from './containers/SplashScreen';
import PasswordForgotten from './containers/account/PasswordForgotten';
import PasswordReset from './containers/account/PasswordReset';
import PasswordCreate from './containers/account/PasswordCreate';
// import ProtectedRoute from './Components/Routing/ProtectedRoute';
import Unauthorized from './containers/account/Unauthorized';

// Home
import MenuOperation from './containers/MenuOperation';
import MenuData from './containers/MenuData';
import MenuFacturation from './containers/MenuFacturation';
import MenuAccount from './containers/MenuAccount';
import Header from './containers/Header';
import Footer from './containers/Footer';

import ServiceSupplierInterface from './navigation/ServiceSupplier/ServiceSupplierInterface';
import IndustrialInterface from './navigation/Industrial/IndustrialInterface';
import SorterInterface from './navigation/Sorter/SorterInterface';
import WasherInterface from './navigation/Washer/WasherInterface';
import CollecteInterface from './navigation/Collecte/CollecteInterface';
import RvmownerInterface from './navigation/Rvmowner/RvmownerInterface';
import StoreownerInterface from './navigation/Storeowner/StoreownerInterface';
import CdcInterface from './navigation/Cdc/CdcInterface';
import DistributorInterface from './navigation/Distributor/DistributorInterface';

import { serviceSupplierMenuOperation } from './navigation/ServiceSupplier/ServiceSupplierMenu';
import { industrialMenuOperation } from './navigation/Industrial/IndustrialMenu';
import { sorterMenuOperation } from './navigation/Sorter/SorterMenu';
import { washerMenuOperation } from './navigation/Washer/WasherMenu';
import { CollecteMenuOperation } from './navigation/Collecte/CollecteMenu';
import { rvmownerMenuOperation } from './navigation/Rvmowner/RvmownerMenu';
import { storeownerMenuOperation } from './navigation/Storeowner/StoreownerMenu';
import { CdcMenuOperation } from './navigation/Cdc/CdcMenu';
import { distributorMenuOperation } from './navigation/Distributor/DistributorMenu';

import { serviceSupplierMenuData } from './navigation/ServiceSupplier/ServiceSupplierMenu';
import { industrialMenuData } from './navigation/Industrial/IndustrialMenu';
import { sorterMenuData } from './navigation/Sorter/SorterMenu';
import { washerMenuData } from './navigation/Washer/WasherMenu';
import { CollecteMenuData } from './navigation/Collecte/CollecteMenu';
import { rvmownerMenuData } from './navigation/Rvmowner/RvmownerMenu';
import { storeownerMenuData } from './navigation/Storeowner/StoreownerMenu';
import { CdcMenuData } from './navigation/Cdc/CdcMenu';
import { distributorMenuData } from './navigation/Distributor/DistributorMenu';

import { serviceSupplierMenuFacturation } from './navigation/ServiceSupplier/ServiceSupplierMenu';
import { industrialMenuFacturation } from './navigation/Industrial/IndustrialMenu';
import { sorterMenuFacturation } from './navigation/Sorter/SorterMenu';
import { washerMenuFacturation } from './navigation/Washer/WasherMenu';
import { CollecteMenuFacturation } from './navigation/Collecte/CollecteMenu';
import { rvmownerMenuFacturation } from './navigation/Rvmowner/RvmownerMenu';
import { storeownerMenuFacturation } from './navigation/Storeowner/StoreownerMenu';
import { CdcMenuFinancial } from './navigation/Cdc/CdcMenu';
import { distributorMenuFacturation } from './navigation/Distributor/DistributorMenu';

import { serviceSupplierMenuAccount } from './navigation/ServiceSupplier/ServiceSupplierMenu';
import { industrialMenuAccount } from './navigation/Industrial/IndustrialMenu';
import { sorterMenuAccount } from './navigation/Sorter/SorterMenu';
import { washerMenuAccount } from './navigation/Washer/WasherMenu';
import { CollecteMenuAccount } from './navigation/Collecte/CollecteMenu';
import { rvmownerMenuAccount } from './navigation/Rvmowner/RvmownerMenu';
import { storeownerMenuAccount } from './navigation/Storeowner/StoreownerMenu';
import { cdcMenuAccount } from './navigation/Cdc/CdcMenu';
import { distributorMenuAccount } from './navigation/Distributor/DistributorMenu';

import { Jwt } from './Jwt';
import PreHome from './containers/PreHome';
import * as Constant from './Constants';

const App = () => {
    const tokenFromCookie = Cookies.get('userToken');
    const jwt = new Jwt(tokenFromCookie);
    const [userContext, setUserContext] = useState(jwt);
    const [isActive, setIsActive] = useState(true);
    const [accessTabOperation, setAccessTabOperation] = useState([]);
    const [accessTabData, setAccessTabData] = useState([]);
    const [accessTabFacturation, setAccessTabFacturation] = useState([]);
    const [accessTabAccount, setAccessTabAccount] = useState([]);
    const [storedModule, setStoredModule] = useState(localStorage.getItem('module'));

    const _defineMenuOperation = (type, module) => {
        switch (type) {
            case Constant.ENTITY_TYPE_FS:
                return serviceSupplierMenuOperation(module);
            case Constant.ENTITY_TYPE_INDUSTRIAL:
                return industrialMenuOperation(module);
            case Constant.ENTITY_TYPE_SORTER:
                return sorterMenuOperation(module);
            case Constant.ENTITY_TYPE_WASHER:
                return washerMenuOperation(module);
            case Constant.ENTITY_TYPE_OPERATOR:
                return CollecteMenuOperation(module);
            case Constant.ENTITY_TYPE_RVMOWNER:
                return rvmownerMenuOperation(module);
            case Constant.ENTITY_TYPE_STOREOWNER:
                return storeownerMenuOperation(module);
            case Constant.ENTITY_TYPE_CDC:
                return CdcMenuOperation(module);
            case Constant.ENTITY_TYPE_DISTRIBUTOR:
                return distributorMenuOperation(module);

            default:
                return [];
        }
    };

    const _defineMenuData = (type, module) => {
        switch (type) {
            case Constant.ENTITY_TYPE_FS:
                return serviceSupplierMenuData(module);
            case Constant.ENTITY_TYPE_INDUSTRIAL:
                return industrialMenuData(module);
            case Constant.ENTITY_TYPE_SORTER:
                return sorterMenuData(module);
            case Constant.ENTITY_TYPE_WASHER:
                return washerMenuData(module);
            case Constant.ENTITY_TYPE_OPERATOR:
                return CollecteMenuData(module);
            case Constant.ENTITY_TYPE_RVMOWNER:
                return rvmownerMenuData(module);
            case Constant.ENTITY_TYPE_STOREOWNER:
                return storeownerMenuData(module);
            case Constant.ENTITY_TYPE_CDC:
                return CdcMenuData(module);
            case Constant.ENTITY_TYPE_DISTRIBUTOR:
                return distributorMenuData(module);
            default:
                return [];
        }
    };

    const _defineMenuFacturation = (type, module) => {
        switch (type) {
            case Constant.ENTITY_TYPE_FS:
                return serviceSupplierMenuFacturation(module);
            case Constant.ENTITY_TYPE_INDUSTRIAL:
                return industrialMenuFacturation(module);
            case Constant.ENTITY_TYPE_SORTER:
                return sorterMenuFacturation(module);
            case Constant.ENTITY_TYPE_WASHER:
                return washerMenuFacturation(module);
            case Constant.ENTITY_TYPE_OPERATOR:
                return CollecteMenuFacturation(module);
            case Constant.ENTITY_TYPE_RVMOWNER:
                return rvmownerMenuFacturation(module);
            case Constant.ENTITY_TYPE_STOREOWNER:
                return storeownerMenuFacturation(module);
            case Constant.ENTITY_TYPE_CDC:
                return CdcMenuFinancial(module);
            case Constant.ENTITY_TYPE_DISTRIBUTOR:
                return distributorMenuFacturation(module);
            default:
                return [];
        }
    };

    const _defineMenuAccount = (type, module) => {
        switch (type) {
            case Constant.ENTITY_TYPE_FS:
                return serviceSupplierMenuAccount(module);
            case Constant.ENTITY_TYPE_INDUSTRIAL:
                return industrialMenuAccount(module);
            case Constant.ENTITY_TYPE_SORTER:
                return sorterMenuAccount(module);
            case Constant.ENTITY_TYPE_WASHER:
                return washerMenuAccount(module);
            case Constant.ENTITY_TYPE_OPERATOR:
                return CollecteMenuAccount(module);
            case Constant.ENTITY_TYPE_RVMOWNER:
                return rvmownerMenuAccount(module);
            case Constant.ENTITY_TYPE_STOREOWNER:
                return storeownerMenuAccount(module);
            case Constant.ENTITY_TYPE_CDC:
                return cdcMenuAccount(module);
            case Constant.ENTITY_TYPE_DISTRIBUTOR:
                return distributorMenuAccount(module);
            default:
                return [];
        }
    };

    useEffect(() => {
        if (userContext && userContext.valid && storedModule) {
            const accessTabRawOperation = _defineMenuOperation(userContext.entityType, storedModule);
            const accessTabRawData = _defineMenuData(userContext.entityType, storedModule);
            const accessTabRawFacturation = _defineMenuFacturation(userContext.entityType, storedModule);
            const accessTabRawAccount = _defineMenuAccount(userContext.entityType, storedModule);
            setAccessTabOperation(accessTabRawOperation);
            setAccessTabData(accessTabRawData);
            setAccessTabFacturation(accessTabRawFacturation);
            setAccessTabAccount(accessTabRawAccount);
        }
    }, [userContext, storedModule]);

    useEffect(() => {
        const handleStorageChange = () => {
            setStoredModule(localStorage.getItem('module'));
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    const _defineInterface = (type, role) => {
        switch (type) {
            case Constant.ENTITY_TYPE_FS:
                return <ServiceSupplierInterface role={role} />;
            case Constant.ENTITY_TYPE_INDUSTRIAL:
                return <IndustrialInterface role={role} />;
            case Constant.ENTITY_TYPE_SORTER:
                return <SorterInterface role={role} />;
            case Constant.ENTITY_TYPE_WASHER:
                return <WasherInterface role={role} />;
            case Constant.ENTITY_TYPE_OPERATOR:
                return <CollecteInterface role={role} />;
            case Constant.ENTITY_TYPE_RVMOWNER:
                return <RvmownerInterface role={role} />;
            case Constant.ENTITY_TYPE_STOREOWNER:
                return <StoreownerInterface role={role} />;
            case Constant.ENTITY_TYPE_CDC:
                return <CdcInterface role={role} />;
            case Constant.ENTITY_TYPE_DISTRIBUTOR:
                return <DistributorInterface role={role} />;
            default:
                return <Unauthorized />;
        }
    };

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (!isActive) {
                console.log('Déconnexion automatique après une heure d’inactivité');
                setUserContext(null);
                Cookies.remove('userToken');
                window.location.href = '/';
            }
        }, 3600000);
        // }, 5000);

        const handleUserActivity = () => {
            setIsActive(true);
            clearTimeout(timeoutId);
            const newTimeoutId = setTimeout(() => {
                setIsActive(false);
            }, 3600000);
            // }, 5000);

            return () => clearTimeout(newTimeoutId);
        };

        window.addEventListener('scroll', handleUserActivity);
        window.addEventListener('click', handleUserActivity);
        window.addEventListener('keydown', handleUserActivity);
        window.addEventListener('mousemove', handleUserActivity);

        return () => {
            window.removeEventListener('scroll', handleUserActivity);
            window.removeEventListener('click', handleUserActivity);
            window.removeEventListener('keydown', handleUserActivity);
            window.addEventListener('mousemove', handleUserActivity);

            clearTimeout(timeoutId);
        };
    }, [isActive]);

    if (userContext && userContext.valid && window.location.pathname === '/') {
        window.location.href = '/prehome';
    }

    return (
        <BrowserRouter>
            {userContext && userContext.valid ? (
                <div>
                    <section className="app-container">
                        <Header
                            userContext={userContext}
                            setUserContext={setUserContext}
                        />
                        {!storedModule ? (
                            <PreHome />
                        ) : (
                            <div className="app-content">
                                <MenuOperation
                                    setUserContext={setUserContext}
                                    accessTab={accessTabOperation}
                                    type={userContext.entityType}
                                />
                                <MenuData
                                    setUserContext={setUserContext}
                                    accessTab={accessTabData}
                                    type={userContext.entityType}
                                />
                                <MenuFacturation
                                    setUserContext={setUserContext}
                                    accessTab={accessTabFacturation}
                                    type={userContext.entityType}
                                />
                                <MenuAccount
                                    setUserContext={setUserContext}
                                    accessTab={accessTabAccount}
                                    type={userContext.entityType}
                                />
                                {_defineInterface(userContext.entityType)}
                                <Footer />
                            </div>
                        )}
                    </section>
                </div>
            ) : (
                <div>
                    {window.location.pathname === '/passwordforgotten' ? (
                        <section className="app-container">
                            <Routes>
                                <Route
                                    path="/passwordforgotten"
                                    element={<PasswordForgotten />}
                                />
                            </Routes>
                        </section>
                    ) : (
                        <div>
                            {window.location.pathname === '/passwordreset' ? (
                                <section className="app-container">
                                    <Routes>
                                        <Route
                                            path="/passwordreset"
                                            element={<PasswordReset />}
                                        />
                                    </Routes>
                                </section>
                            ) : (
                                <div>
                                    {window.location.pathname === '/passwordcreate' ? (
                                        <section className="app-container">
                                            <Routes>
                                                <Route
                                                    path="/passwordcreate"
                                                    element={<PasswordCreate />}
                                                />
                                            </Routes>
                                        </section>
                                    ) : (
                                        <section className="app-container">
                                            <Routes>
                                                <Route
                                                    path="/"
                                                    element={<SplashScreen />}
                                                />
                                                <Route
                                                    path="/login"
                                                    element={<Login setUserContext={setUserContext} />}
                                                />
                                            </Routes>
                                        </section>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}
        </BrowserRouter>
    );
};

export default App;
