import * as Sentry from '@sentry/react';

import { getGearAPIApiEndpoint } from './endpoints';
import { defaultHeaders } from './headers';

const GEAR_API_BASE_URL = getGearAPIApiEndpoint();

const BOCOLOCO_AUTH = '/auth';

const BOCOLOCO_GETINFOUSER = '/getuserinfo';
const BOCOLOCO_GETUSERSBYENTITYID = '/getusersbyentityid';
const BOCOLOCO_UPDATEPASSWORD = '/users/updatepassword';
const BOCOLOCO_CREATENEWPASSWORD = '/users/createnewpassword';

const BOCOLOCO_RESETPASSWORD = '/tokens/requestresetpassword';

// NOUVELLES

const BOCOLOCO_UPDATE_USER = '/user/update';
const BOCOLOCO_UPDATE_USERACCESS = '/user/update_access';

export function login(email, pwd) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_AUTH;
    const url = encodeURI(url_base);
    const formData = {
        email: email,
        password: pwd,
    };

    return fetch(url, {
        method: 'POST',
        body: JSON.stringify(formData),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            console.log(error);
            console.log('ERROR LOGIN');
            throw error;
        });
}

export function getInfoUser() {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GETINFOUSER;
    var url = encodeURI(url_base);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function updateUserInfo(userData) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_UPDATE_USER;
    const url = encodeURI(url_base);
    return fetch(url, {
        method: 'PUT',
        body: JSON.stringify(userData),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            console.log('ERROR UPDATE USER');
            console.log(error);
            throw error;
        });
}

export function updateUserAccess(userAccessData) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_UPDATE_USERACCESS;
    const url = encodeURI(url_base);
    return fetch(url, {
        method: 'PUT',
        body: JSON.stringify(userAccessData),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            console.log('ERROR UPDATE USER');
            console.log(error);
            throw error;
        });
}

export function getUsersByEntityId() {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GETUSERSBYENTITYID;
    var url = encodeURI(url_base);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function updateUserPassword(userData) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_UPDATEPASSWORD;
    var url = encodeURI(url_base);
    return fetch(url, {
        method: 'POST',
        body: JSON.stringify(userData),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            console.log('ERROR:', error);
            throw error;
        });
}

export function resetUserPassword(userData) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_RESETPASSWORD;
    var url = encodeURI(url_base);
    return fetch(url, {
        method: 'POST',
        body: JSON.stringify(userData),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            console.log('ERROR:', error);
            throw error;
        });
}

export function createNewUserPassword(token, userData) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_CREATENEWPASSWORD;
    var url = encodeURI(url_base);
    return fetch(url, {
        method: 'POST',
        body: JSON.stringify(userData),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            console.log('ERROR:', error);
            throw error;
        });
}
