import { useState, useEffect, useRef, useCallback } from 'react';
import { getCollectors, getCollectorsLastCollecte, getCollectorsNextCollecte } from '../../../../../API/CollectorApi';
import { getCollectorCheck } from '../../../../../API/CheckApi/CheckCollectorApi';
import { _defineStatus, _defineCollectorType, useClickOutside } from '../../../../../Utils';
import { useNavigate } from 'react-router-dom';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import { format } from 'date-fns';
import * as Constant from '../../../../../Constants';
import SearchComponent from '../../../../../Components/searchComponent';
import * as Sentry from '@sentry/react';

export default function Collector() {
    const navigate = useNavigate();
    const [filterSelected, setFilterSelected] = useState('desc');
    const [allCollectorList, setallCollectorList] = useState([]);
    const [originalCollectorList, setOriginalCollectorList] = useState([]);
    const [advancedSearch, setAdvancedSearch] = useState(false);
    const [typeTab, setTypeTab] = useState([]);
    const [selectedType, setSelectedType] = useState('');
    const [statusTab, setStatusTab] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState('');
    const [collectorNames, setCollectorNames] = useState([]);
    const [showOptions, setShowOptions] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [infosModal, setInfosModal] = useState();

    const dropdownRef = useRef(null);
    const dialogRef = useRef(null);

    const handleClickOutside = () => {
        setShowOptions(false);
    };
    useClickOutside(dropdownRef, handleClickOutside);

    const fetchAllCollectors = useCallback(async () => {
        try {
            const result = await getCollectors();
            const getCollectorResultCheck = getCollectorCheck(result);
            if (result.length && getCollectorResultCheck !== true) {
                console.error(getCollectorResultCheck);
                Sentry.captureException(getCollectorResultCheck);
                navigate('/error');
                return;
            }

            for (let i = 0; i < result.length; i++) {
                const lastCollecte = await getCollectorsLastCollecte(result[i].store.id);
                if (lastCollecte[0].store.storeEvents.length) {
                    result[i].lastCollecte = lastCollecte[0].store.storeEvents[0];
                } else {
                    result[i].lastCollecte = null;
                }
                const nextCollecte = await getCollectorsNextCollecte(result[i].store.id);
                if (nextCollecte[0].store.storeEvents.length && lastCollecte[0].store.storeEvents.length) {
                    if (nextCollecte[0].store.storeEvents[0].suiviId !== lastCollecte[0].store.storeEvents[0].suiviId) {
                        result[i].nextCollecte = nextCollecte[0].store.storeEvents[0];
                    } else {
                        result[i].nextCollecte = null;
                    }
                } else if (nextCollecte[0].store.storeEvents.length && !lastCollecte[0].store.storeEvents.length) {
                    result[i].nextCollecte = nextCollecte[0].store.storeEvents[0];
                } else {
                    result[i].nextCollecte = null;
                }
            }
            setallCollectorList(result);
        } catch (error) {
            console.error('Erreur lors de la récupération des collectors', error);
        }
    }, [navigate]);

    const handleFilter = useCallback(
        async (searchTerm) => {
            const filtered = allCollectorList.filter((item) => item.store_name.toLowerCase().includes(searchTerm.toLowerCase()));
            return filtered;
        },
        [allCollectorList],
    );

    useEffect(() => {
        fetchAllCollectors();
    }, [fetchAllCollectors]);

    useEffect(() => {
        const fetchCollectorNames = async () => {
            try {
                if (searchTerm.length > 2) {
                    const result = handleFilter(searchTerm);
                    if (result.length) {
                        setCollectorNames(result);
                        setErrorMessage('');
                    } else {
                        setCollectorNames([]);
                        setErrorMessage('Aucun résultat');
                    }
                } else if (searchTerm.length === 0) {
                    setCollectorNames(allCollectorList);
                    setErrorMessage('');
                }
            } catch (error) {
                console.error('Erreur lors de la récupération des collecteurs', error);
            }
        };

        fetchCollectorNames();
    }, [searchTerm, handleFilter, allCollectorList]);

    const handleInputChange = (e) => {
        setSearchTerm(e.target.value.toLowerCase());
        setShowOptions(true);
    };

    const handleSelectOption = (option) => {
        setSearchTerm(option.name);
        setShowOptions(false);
        navigate('/servicesupplier/operation/collector/sheet', { state: { id: option.id } });
    };

    const sortDataAsc = () => {
        const sortedData = [...allCollectorList].sort((a, b) => a.store_name.localeCompare(b.store_name));
        setallCollectorList(sortedData);
        setFilterSelected('asc');
    };

    const sortDataDesc = () => {
        const sortedData = [...allCollectorList].sort((a, b) => b.store_name.localeCompare(a.store_name));
        setallCollectorList(sortedData);
        setFilterSelected('desc');
    };

    useEffect(() => {
        const fetchAdvancedData = async () => {
            try {
                const data = await getCollectors();
                setOriginalCollectorList(data);
                const typeUnique = [...new Set(data.map((objet) => objet.type))];
                setTypeTab(typeUnique);
                const statusUnique = [...new Set(data.map((objet) => objet.status))];
                setStatusTab(statusUnique);
            } catch (error) {
                console.error('Erreur lors de la récupération des produits', error);
            }
        };

        if (advancedSearch) {
            fetchAdvancedData();
        }
    }, [advancedSearch]);

    const handleChange = (e, type) => {
        const selectedValue = e.target.value;

        switch (type) {
            case 'type':
                setSelectedType(selectedValue);
                break;
            case 'zipcode':
                setSelectedStatus(selectedValue);
                break;

            default:
                break;
        }
    };
    const filterData = useCallback(async () => {
        let filteredData = originalCollectorList;

        if (selectedType) {
            let num = parseInt(selectedType);
            filteredData = filteredData.filter((objet) => objet.type === num);
        }

        if (selectedStatus) {
            filteredData = filteredData.filter((objet) => String(objet.status) === selectedStatus);
        }
        setallCollectorList(filteredData);
    }, [originalCollectorList, selectedType, selectedStatus]);

    useEffect(() => {
        filterData();
    }, [filterData]);

    const handleModalOpen = (item) => {
        setInfosModal(item);

        if (dialogRef.current) {
            dialogRef.current.showModal();
        }
    };

    const handleModalClose = () => {
        if (dialogRef.current) {
            dialogRef.current.close();
        }
    };

    return (
        <main>
            <section className="big-section">
                <h2>Les collecteurs</h2>
                <div
                    className="top"
                    style={{ flexDirection: 'column' }}>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                        <SearchComponent
                            dropdownRef={dropdownRef}
                            showOptions={showOptions}
                            searchTerm={searchTerm}
                            handleInputChange={handleInputChange}
                            productsNames={collectorNames}
                            handleSelectOption={handleSelectOption}
                            placeholder="Rechercher.."
                            searchKey="store_name"
                        />

                        <div className="top-button-sort">
                            <div className="filter">
                                <p className="sort">Trier par</p>
                                <div>
                                    <p
                                        className={filterSelected === 'asc' ? 'selected' : 'classic'}
                                        onClick={sortDataAsc}>
                                        A &gt; Z
                                    </p>
                                    <p
                                        className={filterSelected === 'desc' ? 'selected' : 'classic'}
                                        onClick={sortDataDesc}>
                                        Z &gt; A
                                    </p>
                                </div>
                            </div>
                            <div
                                onClick={() => {
                                    setAdvancedSearch(!advancedSearch);
                                }}
                                className="button-actif">
                                Recherche avancée {advancedSearch ? <BiChevronUp size={20} /> : <BiChevronDown size={20} />}
                            </div>
                        </div>
                    </div>

                    {advancedSearch && (
                        <div
                            className="advanced-search"
                            style={{ display: 'flex', marginTop: '1.25rem', gap: '1.25rem', justifyContent: 'end' }}>
                            <select
                                id="city"
                                value={selectedType}
                                onChange={(e) => handleChange(e, 'type')}>
                                <option value="">Type</option>
                                {typeTab.length > 0 &&
                                    typeTab.map((type, index) => (
                                        <option
                                            key={index}
                                            value={type}>
                                            {_defineCollectorType(type)}
                                        </option>
                                    ))}
                            </select>
                            <select
                                id="sku"
                                value={selectedStatus}
                                onChange={(e) => handleChange(e, 'zipcode')}>
                                <option value="">Sélectionnez un statut</option>
                                {statusTab.length > 0 &&
                                    statusTab.map((status, index) => (
                                        <option
                                            key={index}
                                            value={status}>
                                            {_defineStatus(status).name}
                                        </option>
                                    ))}
                            </select>
                        </div>
                    )}
                </div>
                {errorMessage && (
                    <div style={{ width: '100%', padding: '0.5rem', textAlign: 'center', color: Constant.$rouge, marginBottom: '1.25rem' }}>
                        <p>{errorMessage}</p>
                    </div>
                )}
                <div className="tab-item">
                    <div
                        className="pre-filled-fields-1-several-item"
                        style={{ fontWeight: '600', width: '100%' }}>
                        <p style={{ flex: 1.5 }}>Nom</p>
                        <p>Type</p>
                        <p>Statut</p>
                        <p>Centre de tri</p>
                        <p>Tx remplissage</p>
                        <p>Date dernière collecte</p>
                        <p>Date prochaine collecte</p>
                    </div>

                    {allCollectorList.length ? (
                        allCollectorList.map((item, index) => {
                            return (
                                <div
                                    style={{ display: 'flex', flexWrap: 'wrap', width: '100%', justifyContent: 'space-between', cursor: 'pointer' }}
                                    key={index}>
                                    <div
                                        style={{ width: '100%' }}
                                        onClick={() => {
                                            handleModalOpen(item);
                                        }}
                                        className="pre-filled-fields-2-several-item">
                                        <p style={{ flex: 1.5 }}>{item.store_name}</p>
                                        <p>{_defineCollectorType(item.type)}</p>
                                        <p
                                            style={{
                                                backgroundColor: _defineStatus(item.status).color,
                                                color: Constant.$blanc,
                                            }}>
                                            {_defineStatus(item.status).name}
                                        </p>
                                        <p>{item.sorter_name}</p>
                                        <p>{item.filling_rate}</p>
                                        <p>{item.lastCollecte ? format(item.lastCollecte.time, 'yyyy-MM-dd') : ''}</p>
                                        <p>{item.nextCollecte ? format(item.nextCollecte.time, 'yyyy-MM-dd') : ''}</p>
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <div className="no-data-graph">Aucun collecteur enregistré.</div>
                    )}
                </div>
            </section>
            <dialog
                ref={dialogRef}
                className="dialog-collector">
                <div className="title">
                    <p>Fiche collecteur</p>
                    <button
                        className="button-negatif"
                        onClick={handleModalClose}>
                        X
                    </button>
                </div>
                <div className="collector-infos">
                    <p>
                        Capacité : <span>{infosModal && infosModal.capacity}</span>
                    </p>
                    <p>
                        Date création :<span>{infosModal && format(infosModal.createdAt, 'yyyy-MM-dd HH:mm:ss')}</span>
                    </p>
                    <p>
                        Dernier contact : <span>{infosModal && format(infosModal.datep, 'yyyy-MM-dd HH:mm:ss')}</span>
                    </p>
                    <p>
                        Centre de collecte : <span>{infosModal && infosModal.depot_name}</span>
                    </p>
                    <p>
                        Device ID : <span>{infosModal && infosModal.device_id}</span>
                    </p>
                    <p>
                        Taux de remplissage : <span>{infosModal && infosModal.filling_rate}</span>
                    </p>
                    <p>
                        Operateur : <span>{infosModal && infosModal.operator_name}</span>
                    </p>
                    <p>
                        Status : <span>{infosModal && _defineStatus(infosModal.status).name}</span>
                    </p>
                    <p>
                        Société mère : <span>{infosModal && infosModal.store_company_name}</span>
                    </p>
                    <p>
                        Magasin : <span>{infosModal && infosModal.store_name}</span>
                    </p>
                    <p>
                        Modèle : <span>{infosModal && infosModal.supplier_model}</span>
                    </p>
                    <p>
                        Fabricant : <span>{infosModal && infosModal.supplier_name}</span>
                    </p>
                    <p>
                        Type : <span>{infosModal && _defineCollectorType(infosModal.type)}</span>
                    </p>
                </div>
            </dialog>
        </main>
    );
}
