import { useState, useEffect } from 'react';
import { getSuppliersById, updateSupplier } from '../../../../../../API/SupplierApi';
import { _defineStatus } from '../../../../../../Utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { checkSupplierFormErrors, checkIsInputEmpty } from '../../../../../../Utils';
import * as Constant from '../../../../../../Constants';
import AddressSearch from '../../../../../../Components/AddressSearch';
export default function UpdateSupplier() {
    const [errorMessage, setErrorMessage] = useState([]);
    const [errorEmpty, setErrorEmpty] = useState('');
    const location = useLocation();
    const navigate = useNavigate();
    const { id } = location.state || {};
    const [supplierData, setSupplierData] = useState([]);

    useEffect(() => {
        const fetchSupplierData = async () => {
            try {
                const supplierData = await getSuppliersById(id);
                setSupplierData(supplierData[0]);
            } catch (error) {
                console.error('Erreur lors de la récupération des infos du verrier', error);
            }
        };
        fetchSupplierData();
    }, [id]);

    const [formErrors, setFormErrors] = useState({
        name: false,
        website: false,
        address_1: false,
        address_2: false,
        zipcode: false,
        city: false,
        siret: false,
        tva_intra: false,
        contact_name: false,
        contact_phone: false,
        contact_email: false,
        comments: false,
    });

    const requiredFields = [
        'name',
        'address_1',
        'city',
        'zipcode',
        'siret',
        'tva_intra',
        'contact_name',
        'contact_phone',
        'contact_email',
    ];
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSupplierData({
            ...supplierData,
            [name]: value,
        });
    };

    const handleSaveChanges = async (e) => {
        e.preventDefault();
        setErrorMessage([]);
        setErrorEmpty('');

        const inputEmpty = checkIsInputEmpty(requiredFields, supplierData, setFormErrors, setErrorEmpty);
        if (!inputEmpty) {
            const checkFormat = checkSupplierFormErrors(supplierData, formErrors, setFormErrors, setErrorMessage);
            if (checkFormat) {
                return;
            } else {
                const updateSupplierData = await updateSupplier(id, supplierData);
                if (updateSupplierData) {
                    alert('Modifications enregistrées avec succès');
                    navigate(-1);
                } else {
                    alert("une erreur est survenue lors de l'ajout du fournisseur");
                }
            }
        } else {
            console.log('Il reste des champs obligatoires vides');
        }
    };
    return (
        <main>
            <section className="big-section">
                <h2>Modifier un verrier</h2>
                {supplierData.id ? (
                    <div className="form-container">
                        <section>
                            <div>
                                <label>Nom du verrier:</label>
                                <input
                                    className={formErrors.name ? 'error' : ''}
                                    type="text"
                                    name="name"
                                    value={supplierData.name || ''}
                                    onChange={handleInputChange}
                                />
                            </div>

                            <div>
                                <label>Siret:</label>
                                <input
                                    className={formErrors.siret ? 'error' : ''}
                                    type="text"
                                    name="siret"
                                    value={supplierData.siret || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <label>TVA intracommunautaire:</label>
                                <input
                                    className={formErrors.tva_intra ? 'error' : ''}
                                    type="text"
                                    name="tva_intra"
                                    value={supplierData.tva_intra || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </section>
                        <section>
                            <AddressSearch
                                formData={supplierData}
                                setFormData={setSupplierData}
                                formErrors={formErrors}
                                setFormErrors={setFormErrors}
                                handleChange={handleInputChange}
                            />
                        </section>

                        <section>
                            <div>
                                <label>Nom du contact:</label>
                                <input
                                    className={formErrors.contact_name ? 'error' : ''}
                                    type="text"
                                    name="contact_name"
                                    value={supplierData.contact_name || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <label>Téléphone du contact:</label>
                                <input
                                    className={formErrors.contact_phone ? 'error' : ''}
                                    type="number"
                                    name="contact_phone"
                                    value={supplierData.contact_phone || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <label>Email du contact:</label>
                                <input
                                    className={formErrors.contact_email ? 'error' : ''}
                                    type="email"
                                    name="contact_email"
                                    value={supplierData.contact_email || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <label>Site web:</label>
                                <input
                                    className={formErrors.website ? 'error' : ''}
                                    value={supplierData.website || ''}
                                    type="url"
                                    name="website"
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                        </section>
                        <section>
                            <div>
                                <label>Commentaires :</label>
                                <input
                                    className={formErrors.comments ? 'error' : ''}
                                    value={supplierData.comments || ''}
                                    type="text"
                                    name="comments"
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div>
                                <label>Statut:</label>
                                <select
                                    style={{ backgroundColor: _defineStatus(supplierData.status).color, color: Constant.$blanc }}
                                    name="status"
                                    value={supplierData.status}
                                    onChange={handleInputChange}>
                                    <option value={0}>Actif</option>
                                    <option value={100}>Inactif</option>
                                </select>
                            </div>
                        </section>
                    </div>
                ) : null}
                <p className="error-form">{errorEmpty && errorEmpty}</p>
                {errorMessage.length
                    ? errorMessage.map((item, index) => {
                          return (
                              <div
                                  className="error-form"
                                  key={index}>
                                  {item}
                              </div>
                          );
                      })
                    : null}
                <div className="button-confirm">
                    <button
                        className="button-actif"
                        onClick={handleSaveChanges}>
                        Enregistrer les modifications
                    </button>
                </div>
            </section>
        </main>
    );
}
