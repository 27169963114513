import { getGearAPIApiEndpoint } from './endpoints';
import * as Sentry from '@sentry/react';
import { defaultHeaders } from './headers';

const GEAR_API_BASE_URL = getGearAPIApiEndpoint();

const BOCOLOCO_GET_STORES = '/store/get';
const BOCOLOCO_ADD_STORE = '/store/add';
const BOCOLOCO_GET_STOREBYID = '/store/getbyid';
const BOCOLOCO_GET_STOREBYDEPOTID = '/store/getbydepotid';
const BOCOLOCO_GET_STOREBYNAME = '/store/getbyname';
const BOCOLOCO_GET_STOREBYRVMOWNER = '/store/getbyrvmowner';
const BOCOLOCO_GET_STOREBYSTOREOWNER = '/store/getbystoreowner';
const BOCOLOCO_UPDATE_STORE = '/store/update';
const BOCOLOCO_DELETE_STORE = '/store/delete';
const BOCOLOCO_GET_STOREBYCOLLECTORID = '/store/getbycollectorid';
const BOCOLOCO_CREATE_PICKUPPENDING = '/store/event/pending';
const BOCOLOCO_CREATE_PICKUPREQUEST = '/store/event/request';
const BOCOLOCO_CREATE_PICKUPACCEPT = '/store/event/accept';
const BOCOLOCO_CREATE_PICKUPDONE = '/store/event/done';
const BOCOLOCO_GET_EVENTDETAILS_BYSTOREID = '/store/eventdetails/getbystoreid';
const BOCOLOCO_GET_EVENTDETAILS_BYOPERATORID = '/store/eventdetails/getbyoperatorid';
const BOCOLOCO_GET_STOREBYDISTRIBUTOR = '/store/getbydistributor';
const BOCOLOCO_GET_EVENTDETAILS_BYSORTERID = '/store/eventdetails/getbysorterid';
const BOCOLOCO_CREATE_PICKUPRECEIVED = '/store/event/received';
const BOCOLOCO_GET_STOREEVENTDETAILS = '/store/eventdetails/get';
const BOCOLOCO_GET_EVENTDETAILS_BYSUIVIID = '/store/eventdetails/getbysuiviid';
const BOCOLOCO_CREATE_PICKUPPENDINGACCEPT = '/store/event/pendingaccept';
const BOCOLOCO_CREATE_PICKUPMODIFIED_REDUCE = '/store/event/modifiedreduce';
const BOCOLOCO_CREATE_PICKUPMODIFIED_ADD = '/store/event/modifiedadd';
export function getStores() {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_STORES;
    const url = encodeURI(url_base);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function addStore(formData) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_ADD_STORE;
    const url = encodeURI(url_base);
    return fetch(url, {
        method: 'PUT',
        body: JSON.stringify(formData),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            console.log('ERROR ADD STORE');
            console.log(error);
            throw error;
        });
}

export function getStoresByName(name) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_STOREBYNAME;
    var url_args = '?name=' + name;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function getStoreByRvmownerId(rvmowner_id) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_STOREBYRVMOWNER;
    var url_args = '?rvmowner_id=' + rvmowner_id;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function getStoreByStoreownerId(storeowner_id) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_STOREBYSTOREOWNER;
    var url_args = '?storeowner_id=' + storeowner_id;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => response.json())
        .catch((error) => {
            throw error;
        });
}

export function getStoresDistributorId(distributor_id) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_STOREBYDISTRIBUTOR;
    var url_args = '?distributor_id=' + distributor_id;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => response.json())
        .catch((error) => {
            throw error;
        });
}

export function getStoreById(id) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_STOREBYID;
    var url_args = '?id=' + id;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function getStoreByDepotId(depot_id) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_STOREBYDEPOTID;
    var url_args = '?depot_id=' + depot_id;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function getStoreByCollectorId(collectorId) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_STOREBYCOLLECTORID;
    var url_args = '?collectorId=' + collectorId;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function updateStore(id, updatedData) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_UPDATE_STORE;
    const url_args = '?id=' + id;
    const url = encodeURI(url_base + url_args);
    return fetch(url, {
        method: 'PUT',
        body: JSON.stringify(updatedData),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            console.log('ERROR UPDATE STORE');
            console.log(error);
            throw error;
        });
}

export function deleteStore(id) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_DELETE_STORE;
    var url_args = '?id=' + id;
    const url = encodeURI(url_base + url_args);

    return fetch(url, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            throw error;
        });
}

export function storePickUpPending(data) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_CREATE_PICKUPPENDING;
    const url = encodeURI(url_base);
    return fetch(url, {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            console.log('ERROR PICK UP PENDING');
            console.log(error);
            throw error;
        });
}

export function storePickUpPendingAccept(data) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_CREATE_PICKUPPENDINGACCEPT;
    const url = encodeURI(url_base);
    return fetch(url, {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            console.log('ERROR PICK UP PENDING');
            console.log(error);
            throw error;
        });
}

export function storePickUpRequest(data) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_CREATE_PICKUPREQUEST;
    const url = encodeURI(url_base);
    return fetch(url, {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            console.log('ERROR REQUEST PICK UP');
            console.log(error);
            throw error;
        });
}

export function storePickUpAccept(collecteData, pickUpAcceptTime) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_CREATE_PICKUPACCEPT;
    var url_args = '?time=' + pickUpAcceptTime;
    const url = encodeURI(url_base + url_args);
    return fetch(url, {
        method: 'PUT',
        body: JSON.stringify(collecteData),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            console.log('ERROR ACCEPT PICK UP');
            console.log(error);
            throw error;
        });
}

export function storePickUpDone(collecteData, pickUpAcceptTime) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_CREATE_PICKUPDONE;
    var url_args = '?time=' + pickUpAcceptTime;
    const url = encodeURI(url_base + url_args);
    return fetch(url, {
        method: 'PUT',
        body: JSON.stringify(collecteData),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            console.log('ERROR ACCEPT PICK UP');
            console.log(error);
            throw error;
        });
}

export function storePickUpReceived(collecteData, pickUpAcceptTime) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_CREATE_PICKUPRECEIVED;
    var url_args = '?time=' + pickUpAcceptTime;
    const url = encodeURI(url_base + url_args);
    return fetch(url, {
        method: 'PUT',
        body: JSON.stringify(collecteData),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            console.log('ERROR ACCEPT PICK UP');
            console.log(error);
            throw error;
        });
}

export function getEventDetailsByStoreId(storeId) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_EVENTDETAILS_BYSTOREID;
    var url_args = '?storeId=' + storeId;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function getStoreEventDetailsByOperatorId(operatorId) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_EVENTDETAILS_BYOPERATORID;
    var url_args = '?operatorId=' + operatorId;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function getStoreEventDetailsBySorterId(sorterId) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_EVENTDETAILS_BYSORTERID;
    var url_args = '?sorterId=' + sorterId;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function getStoreEventDetails() {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_STOREEVENTDETAILS;
    const url = encodeURI(url_base);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function getStoreEventDetailsBySuiviId(suiviId) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_EVENTDETAILS_BYSUIVIID;
    var url_args = '?suiviId=' + suiviId;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function storePickUpModifiedReduceEmptyBoxes(collecteData) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_CREATE_PICKUPMODIFIED_REDUCE;
    const url = encodeURI(url_base);
    return fetch(url, {
        method: 'PUT',
        body: JSON.stringify(collecteData),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            console.log('ERROR ACCEPT PICK UP');
            console.log(error);
            throw error;
        });
}

export function storePickUpModifiedAddEmptyBoxes(collecteData) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_CREATE_PICKUPMODIFIED_ADD;
    const url = encodeURI(url_base);
    return fetch(url, {
        method: 'PUT',
        body: JSON.stringify(collecteData),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            console.log('ERROR ACCEPT PICK UP');
            console.log(error);
            throw error;
        });
}
