import { getGearAPIApiEndpoint } from './endpoints';
import * as Sentry from '@sentry/react';
import { defaultHeaders } from './headers';

const GEAR_API_BASE_URL = getGearAPIApiEndpoint();

const BOCOLOCO_CREATE_ANNUAL_FORECAST = '/forecast/create';
const BOCOLOCO_GET_ANNUAL_FORECAST_BYENTITY = '/forecast/getbyentityid';
//const BOCOLOCO_GET_ANNUAL_FORECAST_ALL = '/forecast/get';
const BOCOLOCO_GET_ANNUAL_FORECAST_ALLDETAILS = '/forecast/getdetails';

export function createAnnualForecast(declarationDetailsData, startDate) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_CREATE_ANNUAL_FORECAST;
    var url_args = '?startDate=' + startDate;
    const url = encodeURI(url_base + url_args);
    return fetch(url, {
        method: 'POST',
        body: JSON.stringify(declarationDetailsData),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            console.log('ERROR CREATE FORECAST ANNUAL');
            console.log(error);
            throw error;
        });
}

export function getForecastByEntity(entity_id) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_ANNUAL_FORECAST_BYENTITY;
    var url_args = '?entity_id=' + entity_id;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })

        .catch((error) => {
            throw error;
        });
}

/*
export function getAllForecast() {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_ANNUAL_FORECAST_ALL;
    const url = encodeURI(url_base);
    return fetch(url, defaultHeaders)
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}
*/

export function getAllForecastDetails() {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_ANNUAL_FORECAST_ALLDETAILS;
    const url = encodeURI(url_base);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}
