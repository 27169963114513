export const AccessWasher = {
    washer: {
        operation: {
            lavage: {
                reception: 0,
                declaration: 0,
                bons_de_commande: 0,
            },
        },
        data: {},
        financial: {},
    },
};
