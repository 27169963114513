import { useState, useEffect } from 'react';
import { addStore } from '../../../../../API/StoreApi';
import { useNavigate, useLocation } from 'react-router-dom';
import { checkStoreFormErrors, checkIsInputEmpty } from '../../../../../Utils';
import { getDepots } from '../../../../../API/DepotApi';
import { getDepotsCheck } from '../../../../../API/CheckApi/CheckDepotApi';
import { _defineStoreType, storeTypeTab, storeOwnerTab, _defineStoreOwner } from '../../../../../Utils';
import * as Sentry from '@sentry/react';
import AddressSearch from '../../../../../Components/AddressSearch.jsx';
import { getDistributors } from '../../../../../API/DistributorApi';
// import { getStoreowners } from '../../../../../API/StoreownerApi';
import { getSorters } from '../../../../../API/SorterApi';
export default function AddStore() {
    const location = useLocation();
    const [depotList, setDepotList] = useState([]);
    const [distributorList, setDistributorList] = useState([]);
    // const [storeownerList, setStoreownerList] = useState([]);
    const [rvmownerList, setRvmownerList] = useState([]);
    const [sorterList, setSorterList] = useState([]);
    const [errorMessage, setErrorMessage] = useState([]);
    const [errorEmpty, setErrorEmpty] = useState('');
    const { depot_id } = location.state || {};
    const { rvmowner_id } = location.state || {};
    const { distributor_id } = location.state || {};

    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        // company_name: '',
        name: '',
        address_1: '',
        address_2: '',
        city: '',
        zipcode: '',
        contact_name: '',
        contact_phone: '',
        contact_email: '',
        depot_id: depot_id,
        rvmowner_id: rvmowner_id,
        distributor_id: distributor_id,
        storeowner_id: '',
        type: '',
        owner: '',
        initial_nb_boxes: null,
        lat: null,
        long: null,
        siret: '',
        tva_intra: '',
    });

    const [formErrors, setFormErrors] = useState({
        // company_name: false,
        name: false,
        address_1: false,
        address_2: false,
        city: false,
        zipcode: false,
        contact_name: false,
        contact_phone: false,
        contact_email: false,
        depot_id: false,
        rvmowner_id: false,
        distributor_id: false,
        storeowner_id: false,
        type: false,
        owner: false,
        siret: false,
        tva_intra: false,
    });

    const requiredFields = [
        // 'company_name',
        'name',
        'address_1',
        'city',
        'zipcode',
        'contact_name',
        'contact_phone',
        'contact_email',
        'rvmowner_id',
        'distributor_id',
        'depot_id',
        'type',
        'owner',
        'initial_nb_boxes',
        'siret',
        'tva_intra',
    ];

    useEffect(() => {
        const _getAllDepot = async () => {
            const data = await getDepots();
            const dataCheck = getDepotsCheck(data);
            if (data.length && dataCheck !== true) {
                console.error(dataCheck);
                Sentry.captureException(dataCheck);
                navigate('/error');
                return;
            }
            setDepotList(data);
            console.log('_getAllDepot');
            console.log(data);
            console.log('depot_id');
            console.log(depot_id);
            let depot_list = document.getElementById('depotSelect');
            if (depot_id > 0) {
                depot_list.value = depot_id;
                depot_list.dispatchEvent(new Event('change'));
                setFormData((formData) => ({ ...formData, depot_id: depot_id }));
                depot_list.disabled = true;
            }
        };
        const _getAllDistributors = async () => {
            const data = await getDistributors();
            setDistributorList(data);
            let distributor_list = document.getElementById('distributorSelected');
            if (distributor_id > 0) {
                distributor_list.value = distributor_id;
                distributor_list.dispatchEvent(new Event('change'));
                setFormData((formData) => ({ ...formData, distributor_id: distributor_id }));
                distributor_list.disabled = true;
            }
        };

        // const _getAllStoreOwners = async () => {
        //     const data = await getStoreowners();
        //     setStoreownerList(data);
        // };

        const _getAllRvmowner = async () => {
            const { gearApi } = await import('../../../../../gearApi.js');
            const data = await gearApi.rvmowner.getRvmowners();
            const dataSorted = data.sort((a, b) => a.name.localeCompare(b.name));
            setRvmownerList(dataSorted);
        };
        const _getAllSorters = async () => {
            const data = await getSorters();
            setSorterList(data);
        };
        // _getAllStoreOwners();
        _getAllDistributors();
        _getAllDepot();
        _getAllRvmowner();
        _getAllSorters();
    }, [
        distributor_id,
        depot_id,
        navigate,
    ]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage([]);
        setErrorEmpty('');
        const inputEmpty = checkIsInputEmpty(requiredFields, formData, setFormErrors, setErrorEmpty);
        if (!inputEmpty) {
            const checkFormat = checkStoreFormErrors(formData, formErrors, setFormErrors, setErrorMessage);
            if (checkFormat) {
                return;
            } else {
                const addStoreData = await addStore(formData);

                if (addStoreData) {
                    alert('Le magasin a été ajouté avec succès');
                    navigate(-1);
                } else {
                    alert("une erreur est survenue lors de l'ajout du magasin");
                }
            }
        } else {
            console.log('Il reste des champs obligatoires vides');
        }
    };

    return (
        <main>
            <section className="big-section">
                <h2>Ajouter un magasin</h2>
                <div
                    className="form-container"
                    style={{ display: 'flex' }}>
                    <section>
                        <div>
                            <label className="small-title">Type propriétaire:</label>
                            <select
                                name="owner"
                                className={formErrors.owner ? 'error' : ''}
                                onChange={handleChange}>
                                <option value="">--</option>
                                {storeOwnerTab.map((type, index) => (
                                    <option
                                        key={index}
                                        value={parseInt(type)}>
                                        {_defineStoreOwner(type)}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label>Distributeur :</label>
                            {distributorList.length ? (
                                <select
                                    name="distributor_id"
                                    id="distributorSelected"
                                    onChange={handleChange}>
                                    <option>Choisissez une option</option>
                                    {distributorList.map((option, index) => (
                                        <option
                                            key={index}
                                            value={option.id}>
                                            {option.company_name}
                                        </option>
                                    ))}
                                </select>
                            ) : (
                                <input
                                    type="text"
                                    disabled
                                    value={'Aucun distributeur référencé'}
                                />
                            )}
                        </div>

                        <div>
                            <label>Nom du magasin:</label>
                            <input
                                className={formErrors.name ? 'error' : ''}
                                type="text"
                                name="name"
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div>
                            <label className="small-title">Type :</label>
                            <select
                                name="type"
                                className={formErrors.type ? 'error' : ''}
                                onChange={handleChange}>
                                <option value="">--</option>
                                {storeTypeTab.map((type, index) => (
                                    <option
                                        key={index}
                                        value={parseInt(type)}>
                                        {_defineStoreType(type)}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div style={{ marginTop: '2rem' }}>
                            <label>Siret:</label>
                            <input
                                className={formErrors.siret ? 'error' : ''}
                                type="text"
                                name="siret"
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <label>TVA intracommunautaire:</label>
                            <input
                                className={formErrors.tva_intra ? 'error' : ''}
                                type="text"
                                name="tva_intra"
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <label>Centre de Collecte associé:</label>
                            <select
                                name="depot_id"
                                id="depotSelect"
                                onChange={handleChange}>
                                <option>Choisissez une option</option>
                                <option value={0}>Aucun</option>

                                {depotList.length ? (
                                    depotList.map((option, index) => (
                                        <option
                                            key={index}
                                            value={option.id}>
                                            {option.company_name} - {option.name}
                                        </option>
                                    ))
                                ) : (
                                    <option>Aucun dépot référencé</option>
                                )}
                            </select>
                        </div>
                        {formData.depot_id === '0' && (
                            <div>
                                <label>Centre de tri associé:</label>
                                <select
                                    name="entity_sorter_id"
                                    id="sorterSelect"
                                    onChange={handleChange}>
                                    <option>Choisissez une option</option>

                                    {sorterList.length ? (
                                        sorterList.map((option, index) => (
                                            <option
                                                key={index}
                                                value={option.id}>
                                                {option.company_name} - {option.name}
                                            </option>
                                        ))
                                    ) : (
                                        <option>Aucun trieur référencé</option>
                                    )}
                                </select>
                            </div>
                        )}
                    </section>
                    <section>
                        <AddressSearch
                            formData={formData}
                            setFormData={setFormData}
                            formErrors={formErrors}
                            setFormErrors={setFormErrors}
                            handleChange={handleChange}
                        />
                        <div style={{ marginTop: '2rem' }}>
                            <label>Nom du contact:</label>
                            <input
                                className={formErrors.contact_name ? 'error' : ''}
                                type="text"
                                name="contact_name"
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <label>Téléphone du contact:</label>
                            <input
                                className={formErrors.contact_phone ? 'error' : ''}
                                type="number"
                                name="contact_phone"
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <label>Email du contact:</label>
                            <input
                                className={formErrors.contact_email ? 'error' : ''}
                                type="email"
                                name="contact_email"
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </section>

                    <section>
                        <div style={{ marginTop: '1.5rem' }}>
                            <label>Gestionnaire de RVM associé:</label>
                            {rvmownerList.length ? (
                                <select
                                    name="rvmowner_id"
                                    id="depotSelect"
                                    onChange={handleChange}>
                                    <option>Choisissez une option</option>
                                    {rvmownerList.map((option, index) => (
                                        <option
                                            key={index}
                                            value={option.id}>
                                            {option.company_name}
                                        </option>
                                    ))}
                                </select>
                            ) : (
                                <input
                                    type="text"
                                    disabled
                                    value={'Aucun gestionnaire de RVM référencé'}
                                />
                            )}
                        </div>
                        {/* <div>
                            <label>Gestionnaire de magasin associé:</label>
                            {storeownerList.length ? (
                                <select
                                    name="storeowner_id"
                                    id="storeownerSelect"
                                    onChange={handleChange}>
                                    <option>Choisissez une option</option>
                                    {storeownerList.map((option, index) => (
                                        <option
                                            key={index}
                                            value={option.id}>
                                            {option.company_name}
                                        </option>
                                    ))}
                                </select>
                            ) : (
                                <input
                                    type="text"
                                    disabled
                                    value={'Aucun gestionnaire de magasin référencé'}
                                />
                            )}
                        </div> */}
                        <div>
                            <label>Nb caisses associées:</label>
                            <input
                                className={formErrors.initial_nb_boxes ? 'error' : ''}
                                type="number"
                                name="initial_nb_boxes"
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </section>
                </div>
                <p className="error-form">{errorEmpty && errorEmpty}</p>
                {errorMessage.length
                    ? errorMessage.map((item, index) => {
                          return (
                              <div
                                  className="error-form"
                                  key={index}>
                                  {item}
                              </div>
                          );
                      })
                    : null}
                <div className="button-confirm">
                    <button
                        className="button-actif"
                        onClick={handleSubmit}>
                        Ajouter le magasin
                    </button>
                </div>
            </section>
        </main>
    );
}
