import { useState, useEffect } from 'react';
import { getCollectorById, updateCollector } from '../../../../../API/CollectorApi';
import { getCollectorByIdcheck } from '../../../../../API/CheckApi/CheckCollectorApi';
import { _defineStatus } from '../../../../../Utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { checkCollectorFormErrors, checkIsInputEmpty, collectorTypeTab, _defineCollectorType } from '../../../../../Utils';
import * as Constant from '../../../../../Constants';
import * as Sentry from '@sentry/react';
export default function UpdateCollector() {
    const location = useLocation();
    const { id } = location.state || {};
    const [collectorData, setCollectorData] = useState([]);
    const [errorMessage, setErrorMessage] = useState([]);
    const [errorEmpty, setErrorEmpty] = useState('');
    const [rvmownerData, setRvmownerData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCollectorData = async () => {
            try {
                const { gearApi } = await import('../../../../../gearApi.js');

                const collectorData = await getCollectorById(id);
                const collectorDataCheck = getCollectorByIdcheck(collectorData);
                if (collectorData.length && collectorDataCheck !== true) {
                    console.error(collectorDataCheck);
                    Sentry.captureException(collectorDataCheck);
                    navigate('/error');
                    return;
                }
                setCollectorData(collectorData[0]);

                const rvmownerData = await gearApi.rvmowner.getRvmowners();
                setRvmownerData(rvmownerData);
            } catch (error) {
                console.error('Erreur lors de la récupération des infos du collecteur', error);
            }
        };
        fetchCollectorData();
    }, [id, navigate]);

    const [formErrors, setFormErrors] = useState({
        store_id: false,
        type: false,
        capacity: false,
        device_id: false,
        supplier_name: false,
        supplier_model: false,
    });

    const requiredFields = [
        'type',
        'capacity',
        'device_id',
        'supplier_name',
        'supplier_model',
    ];

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCollectorData({
            ...collectorData,
            [name]: value,
        });
    };

    const handleSaveChanges = async (e) => {
        e.preventDefault();
        setErrorMessage([]);
        setErrorEmpty('');
        const inputEmpty = checkIsInputEmpty(requiredFields, collectorData, setFormErrors, setErrorEmpty);
        if (!inputEmpty) {
            const checkFormat = checkCollectorFormErrors(collectorData, formErrors, setFormErrors, setErrorMessage);
            if (checkFormat) {
                return;
            } else {
                const updateCollectorData = await updateCollector(id, collectorData);
                if (updateCollectorData) {
                    alert('Le collecteur a été modifié avec succès');
                    navigate(-1);
                } else {
                    alert('une erreur est survenue lors de la modification du collecteur');
                }
            }
        } else {
            console.log('Il reste des champs obligatoires vides');
        }
    };

    return (
        <main>
            <section className="small-section">
                <h2>Modifier un collecteur</h2>
                {collectorData.id ? (
                    <div className="form-container">
                        <section style={{ width: '100%' }}>
                            <div>
                                <label>Nom du magasin:</label>
                                <input
                                    disabled={true}
                                    type="text"
                                    name="store_name"
                                    value={collectorData.store_name || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <label>Gestionnaire de RVM:</label>
                                <select
                                    value={collectorData.rvmowner_id || ''}
                                    className={formErrors.rvmowner_id ? 'error' : ''}
                                    name="rvmowner_id"
                                    onChange={handleInputChange}>
                                    <option>Choisissez une option</option>
                                    {rvmownerData.map((option, index) => (
                                        <option
                                            key={index}
                                            value={option.id}>
                                            {option.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label>Type :</label>
                                <select
                                    defaultValue={collectorData.type}
                                    name="type"
                                    className={formErrors.type ? 'error' : ''}
                                    onChange={handleInputChange}>
                                    <option value="">--</option>
                                    {collectorTypeTab.map((type, index) => (
                                        <option
                                            key={index}
                                            value={parseInt(type)}>
                                            {_defineCollectorType(type)}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label>Capacity:</label>
                                <input
                                    className={formErrors.device_id ? 'error' : ''}
                                    type="number"
                                    name="capacity"
                                    value={collectorData.capacity || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <label>Device ID:</label>
                                <input
                                    className={formErrors.device_id ? 'error' : ''}
                                    type="text"
                                    name="device_id"
                                    value={collectorData.device_id || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <label>Fabricant:</label>
                                <input
                                    value={collectorData.supplier_name || ''}
                                    className={formErrors.supplier_name ? 'error' : ''}
                                    type="text"
                                    name="supplier_name"
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div>
                                <label>Modèle:</label>
                                <input
                                    value={collectorData.supplier_model || ''}
                                    className={formErrors.supplier_model ? 'error' : ''}
                                    type="text"
                                    name="supplier_model"
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div>
                                <label>Statut:</label>
                                <select
                                    style={{ backgroundColor: _defineStatus(collectorData.status).color, color: Constant.$blanc }}
                                    name="status"
                                    value={collectorData.status}
                                    onChange={handleInputChange}>
                                    <option value={0}>Actif</option>
                                    <option value={100}>Inactif</option>
                                </select>
                            </div>
                        </section>
                    </div>
                ) : null}
                <p className="error-form">{errorEmpty && errorEmpty}</p>
                {errorMessage.length
                    ? errorMessage.map((item, index) => {
                          return (
                              <div
                                  className="error-form"
                                  key={index}>
                                  {item}
                              </div>
                          );
                      })
                    : null}
                <div className="button-confirm">
                    <button
                        className="button-actif"
                        onClick={handleSaveChanges}>
                        Enregistrer les modifications
                    </button>
                </div>
            </section>
        </main>
    );
}
