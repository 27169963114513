import { useEffect, useState } from 'react';
import { addCollector } from '../../../../../API/CollectorApi';
import { getStores, getStoreByDepotId, getStoreByRvmownerId, getStoreById } from '../../../../../API/StoreApi';
import { getStoreByRvmownerIdCheck } from '../../../../../API/CheckApi/CheckStoreApi';
import { useLocation, useNavigate } from 'react-router-dom';
import { getContainers } from '../../../../../API/ContainerApi';
import { checkCollectorFormErrors, checkIsInputEmpty, _defineCollectorType, collectorTypeTab } from '../../../../../Utils';
import * as Sentry from '@sentry/react';
import * as Constants from '../../../../../Constants';

export default function AddCollector() {
    const [storeList, setStoreList] = useState([]);
    const [rvmownerList, setRvmownerList] = useState([]);
    const [containerList, setContainerList] = useState([]);
    const [nbBoxesAvailable, setNbBoxesAvailable] = useState(0);
    const location = useLocation();
    const navigate = useNavigate();
    const { depot_id } = location.state || {};
    const { rvmowner_id } = location.state || {};
    const { storeData } = location.state || {};

    const [errorMessage, setErrorMessage] = useState([]);
    const [errorEmpty, setErrorEmpty] = useState('');
    const [formData, setFormData] = useState({
        rvmowner_id: rvmowner_id ? rvmowner_id : null,
        store_id: storeData ? storeData.id : null,
        type: null,
        capacity: 0,
        device_id: '',
        supplier_name: '',
        supplier_model: '',
        container_id: null,
        qty_container: 0,
    });
    const [formErrors, setFormErrors] = useState({
        rvmowner_id: false,
        store_id: false,
        type: false,
        capacity: false,
        device_id: false,
        supplier_name: false,
        supplier_model: false,
    });

    const requiredFields = [
        'rvmowner_id',
        'store_id',
        'type',
        'capacity',
        'device_id',
        'supplier_name',
        'supplier_model',
        'container_id',
        'qty_container',
    ];

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        const _getAllStores = async () => {
            const { gearApi } = await import('../../../../../gearApi.js');

            let dataStore;
            let dataRvmowner;
            if (depot_id > 0) {
                dataStore = await getStoreByDepotId(depot_id);
            } else {
                if (rvmowner_id > 0) {
                    dataStore = await getStoreByRvmownerId(rvmowner_id);
                    const dataStoreCheck = getStoreByRvmownerIdCheck(dataStore);
                    if (dataStore.length && dataStoreCheck !== true) {
                        console.error(dataStoreCheck);
                        Sentry.captureException(dataStoreCheck);
                        navigate('/error');
                        return;
                    }
                    if (storeData) {
                        dataStore = [storeData];
                    } else {
                        if (rvmowner_id > 0) {
                            dataStore = await getStoreByRvmownerId(rvmowner_id);
                        } else {
                            dataStore = await getStores();
                        }
                    }
                }
            }
            dataStore = dataStore.sort((a, b) => a.name.localeCompare(b.name));
            setStoreList(dataStore);

            if (rvmowner_id > 0) {
                dataRvmowner = await gearApi.rvmowner.getRvmownerById({ rvmownerId: rvmowner_id });
            } else {
                dataRvmowner = await gearApi.rvmowner.getRvmowners();
            }
            console.log('dataRvmowner');
            console.log(dataRvmowner);
            setRvmownerList([dataRvmowner]);

            const containers = await getContainers();
            const containerSecondaire = containers.filter((container) => container.usage === Constants.CONTAINER_USAGE_SECONDARY);
            setContainerList(containerSecondaire);

            if (formData.store_id) {
                const store = await getStoreById(formData.store_id);
                const nbBoxesAvailableRaw = store[0].initial_nb_boxes - store[0].storeBoxes.length;
                setNbBoxesAvailable(nbBoxesAvailableRaw);
            }
        };
        _getAllStores();
    }, [depot_id, rvmowner_id, storeData, navigate, formData.store_id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage([]);
        setErrorEmpty('');
        const inputEmpty = checkIsInputEmpty(requiredFields, formData, setFormErrors, setErrorEmpty);
        if (!inputEmpty) {
            const checkFormat = checkCollectorFormErrors(formData, formErrors, setFormErrors, setErrorMessage);
            if (checkFormat) {
                return;
            } else {
                const addCollectorData = await addCollector(formData);
                if (addCollectorData) {
                    alert('Le collecteur a été ajouté avec succès');
                    navigate(-1);
                } else {
                    alert("une erreur est survenue lors de l'ajout du collecteur");
                }
            }
        } else {
            console.log('Il reste des champs obligatoires vides');
        }
    };
    return (
        <main>
            <section className="small-section">
                <h2>Ajouter un collecteur</h2>

                <div className="form-container">
                    <section style={{ width: '100%' }}>
                        <div>
                            <label>Nom du gestionnaire de RVM:</label>

                            {rvmownerList.length > 0 ? (
                                <select
                                    className={formErrors.rvmowner_id ? 'error' : ''}
                                    name="rvmowner_id"
                                    onChange={handleChange}>
                                    <option>Choisissez une option</option>
                                    {rvmownerList.map((option, index) => (
                                        <option
                                            key={index}
                                            value={option.id}
                                            selected={option.id === rvmowner_id ? true : false}>
                                            {option.name}
                                        </option>
                                    ))}
                                </select>
                            ) : (
                                <select
                                    className={'error'}
                                    name="store_id"
                                    onChange={handleChange}>
                                    <option>Aucun gestionnaire de RVM disponible</option>
                                </select>
                            )}
                        </div>
                        <div>
                            <label>Nom du magasin:</label>

                            {storeList.length > 0 ? (
                                <select
                                    className={formErrors.store_id ? 'error' : ''}
                                    name="store_id"
                                    onChange={handleChange}>
                                    <option value={''}>Choisissez une option</option>
                                    {storeList.map((option, index) => (
                                        <option
                                            key={index}
                                            value={option.id}
                                            selected={storeList.length === 1 ? (option.id === storeList[0].id ? true : false) : false}>
                                            {option.name}
                                        </option>
                                    ))}
                                </select>
                            ) : (
                                <select
                                    className={'error'}
                                    name="store_id"
                                    onChange={handleChange}>
                                    <option>Aucun magasin disponible</option>
                                </select>
                            )}
                        </div>

                        <div>
                            <label>Type :</label>
                            <select
                                name="type"
                                className={formErrors.type ? 'error' : ''}
                                onChange={handleChange}>
                                <option value="">--</option>
                                {collectorTypeTab.map((type, index) => (
                                    <option
                                        key={index}
                                        value={parseInt(type)}>
                                        {_defineCollectorType(type)}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label>Capacity:</label>
                            <input
                                className={formErrors.capacity ? 'error' : ''}
                                type="number"
                                name="capacity"
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <label>Device ID:</label>
                            <input
                                className={formErrors.device_id ? 'error' : ''}
                                type="text"
                                name="device_id"
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <label>Fabricant:</label>
                            <input
                                className={formErrors.supplier_name ? 'error' : ''}
                                type="text"
                                name="supplier_name"
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <label>Modèle:</label>
                            <input
                                className={formErrors.supplier_model ? 'error' : ''}
                                type="text"
                                name="supplier_model"
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <label>SKU caisse associée:</label>

                            {containerList.length > 0 ? (
                                <select
                                    className={formErrors.container_id ? 'error' : ''}
                                    name="container_id"
                                    onChange={handleChange}>
                                    <option>Choisissez une option</option>
                                    {containerList.map((option, index) => (
                                        <option
                                            key={index}
                                            value={option.id}>
                                            {option.name}
                                        </option>
                                    ))}
                                </select>
                            ) : (
                                <select
                                    className={'error'}
                                    name="container_id"
                                    onChange={handleChange}>
                                    <option>Aucune caisse référencée</option>
                                </select>
                            )}
                        </div>
                        <div>
                            <label>Nb de caisses associées:</label>
                            {nbBoxesAvailable > 0 ? (
                                <input
                                    className={formErrors.qty_container ? 'error' : ''}
                                    type="number"
                                    name="qty_container"
                                    onChange={handleChange}
                                    required
                                    disabled={formData.store_id ? false : true}
                                    max={nbBoxesAvailable}
                                />
                            ) : (
                                <input
                                    className={formErrors.qty_container ? 'error' : ''}
                                    type="text"
                                    name="qty_container"
                                    onChange={handleChange}
                                    value={'Nb de caisses disponibles insuffisant'}
                                    required
                                    disabled={true}
                                />
                            )}
                        </div>
                    </section>
                </div>
                <p className="error-form">{errorEmpty && errorEmpty}</p>
                {errorMessage.length
                    ? errorMessage.map((item, index) => {
                          return (
                              <div
                                  className="error-form"
                                  key={index}>
                                  {item}
                              </div>
                          );
                      })
                    : null}
                <div className="button-confirm">
                    <button
                        className="button-actif"
                        onClick={handleSubmit}>
                        Ajouter le collecteur
                    </button>
                </div>
            </section>
        </main>
    );
}
