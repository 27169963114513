// COLLECTOR RETURN API
export const getReturnByCollectorIdCheck = (data) => {
    const name = 'getReturnByCollectorId : ';
    for (let i = 0; i < data.length; i++) {
        if (!data[i].collector.id) {
            return name + 'No collector id found';
        } else if (!data[i].collector.store.id) {
            return name + 'No store id found';
        } else if (data[i].entityIndustrialProduct) {
            if (!data[i].entityIndustrialProduct.id) {
                return name + 'No entity industrial product found';
            } else if (!data[i].entityIndustrialProduct.container.id) {
                return name + 'No container id found';
            } else {
                return true;
            }
        } else {
            return true;
        }
    }
};

export const getReturnByDatesCheck = (data) => {
    const name = 'getReturnByDates : ';
    for (let i = 0; i < data.length; i++) {
        if (!data[i].collector.id) {
            return name + 'No collector id found';
        } else if (!data[i].collector.store.id) {
            return name + 'No store id found';
        } else if (data[i].entityIndustrialProduct) {
            if (!data[i].entityIndustrialProduct.id) {
                return name + 'No entity industrial product found';
            } else if (!data[i].entityIndustrialProduct.container.id) {
                return name + 'No container id found';
            } else {
                return true;
            }
        } else {
            return true;
        }
    }
};

export const getAllReturnInCollectorCheck = (data) => {
    const name = 'getAllReturnInCollectorCheck : ';
    for (let i = 0; i < data.length; i++) {
        if (!data[i].id) {
            return name + 'No id found';
        } else if (data[i].entityIndustrialProduct) {
            if (!data[i].entityIndustrialProduct.id) {
                return name + 'No entity industrial product found';
            } else if (!data[i].entityIndustrialProduct.container.id) {
                return name + 'No container id found';
            } else {
                return true;
            }
        } else {
            return true;
        }
    }
};

export const getReturnByDatesAndStoreOwnerCheck = (data) => {
    const name = 'getReturnByDatesAndStoreOwner : ';
    for (let i = 0; i < data.length; i++) {
        if (!data[i].collector.id) {
            return name + 'No collector id found';
        } else if (!data[i].collector.store.id) {
            return name + 'No store id found';
        } else if (data[i].entityIndustrialProduct) {
            if (!data[i].entityIndustrialProduct.id) {
                return name + 'No entity industrial product found';
            } else if (!data[i].entityIndustrialProduct.container.id) {
                return name + 'No container id found';
            } else {
                return true;
            }
        } else {
            return true;
        }
    }
};

export const getReturnStoreByDatesCheck = (data) => {
    const name = 'getReturnStoreByDatesCheck : ';
    for (let i = 0; i < data.length; i++) {
        if (!data[i].collector.id) {
            return name + 'No collector id found';
        } else if (!data[i].collector.store.id) {
            return name + 'No store id found';
        } else if (data[i].entityIndustrialProduct) {
            if (!data[i].entityIndustrialProduct.id) {
                return name + 'No entity industrial product found';
            } else if (!data[i].entityIndustrialProduct.container.id) {
                return name + 'No container id found';
            } else {
                return true;
            }
        } else {
            return true;
        }
    }
};

export const getReturnByDatesAndCollectorSupplierCheck = (data) => {
    const name = 'getReturnByDatesAndCollectorSupplierCheck : ';
    for (let i = 0; i < data.length; i++) {
        if (!data[i].collector.id) {
            return name + 'No collector id found';
        } else if (data[i].entityIndustrialProduct) {
            if (!data[i].entityIndustrialProduct.id) {
                return name + 'No entity industrial product found';
            } else if (!data[i].entityIndustrialProduct.container.id) {
                return name + 'No container id found';
            } else {
                return true;
            }
        } else {
            return true;
        }
    }
};

export const getReturnCollectorByDatesCheck = (data) => {
    const name = 'getReturnCollectorByDatesCheck : ';
    for (let i = 0; i < data.length; i++) {
        if (!data[i].collector.id) {
            return name + 'No collector id found';
        } else if (data[i].entityIndustrialProduct) {
            if (!data[i].entityIndustrialProduct.id) {
                return name + 'No entity industrial product found';
            } else if (!data[i].entityIndustrialProduct.container.id) {
                return name + 'No container id found';
            } else {
                return true;
            }
        } else {
            return true;
        }
    }
};
