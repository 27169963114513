import Cookies from 'js-cookie';
import * as Constants from '../../Constants';

export const CollecteMenuOperation = () => {
    return ['collecte'];
};

const getSubAccessOperationCollecte = () => {
    let sousMenu = [{ title: 'Collecte' }];
    const access = Cookies.get('access').length ? JSON.parse(Cookies.get('access')) : null;
    if (access) {
        let access_operation = access.operator.operation;
        if (access_operation.collecte.a_valider === 1) {
            sousMenu.push({ name: 'A valider', link: '/collecte/operation/list' });
        }
        if (access_operation.collecte.programmes === 1) {
            sousMenu.push({ name: 'Programmées', link: '/collecte/operation/scheduled' });
        }
        if (access_operation.collecte.historique === 1) {
            sousMenu.push({ name: 'Historique', link: '/collecte/operation/history' });
        }
    }
    return sousMenu;
};

export const getSousMenuCollecteOperation = (onglet) => {
    switch (onglet) {
        case 'collecte':
            return getSubAccessOperationCollecte();
        default:
            return [];
    }
};

export const CollecteMenuData = () => {
    return [];
};

export const getSousMenuCollecteData = (onglet) => {
    switch (onglet) {
        default:
            return [];
    }
};

export const CollecteMenuFacturation = () => {
    return [];
};

export const getSousMenuCollecteFacturation = (onglet) => {
    switch (onglet) {
        default:
            return [];
    }
};

export const CollecteMenuAccount = () => {
    return ['my_account'];
};

export const getSousMenuCollecteAccount = (onglet) => {
    const role = Cookies.get('role');
    const isAdmin = role === Constants.ENTITY_ROLE_ADMIN;
    switch (onglet) {
        case 'my_account':
            if (isAdmin) {
                return [
                    { title: 'Mon compte' },
                    { name: 'Mes informations personnelles', link: '/collecte/myprofil/personal_info' },
                    { name: 'Ma société', link: '/collecte/myprofil/company_info' },
                    { name: 'Gestion des comptes', link: '/collecte/myprofil/rolemanagement' },
                ];
            } else {
                return [
                    { title: 'Mon compte' },
                    { name: 'Mes informations personnelles', link: '/collecte/myprofil/personal_info' },
                    { name: 'Ma société', link: '/collecte/myprofil/company_info' },
                ];
            }
        default:
            return [];
    }
};
