import { useState, useEffect } from 'react';
import { getDepotById, updateDepot } from '../../../../../API/DepotApi';
import { getDepotByIdCheck } from '../../../../../API/CheckApi/CheckDepotApi';
import { _defineStatus } from '../../../../../Utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { getSorters } from '../../../../../API/SorterApi';
import { checkDepotFormErrors, checkIsInputEmpty } from '../../../../../Utils';
import * as Constant from '../../../../../Constants';
import * as Sentry from '@sentry/react';
import AddressSearch from '../../../../../Components/AddressSearch';
export default function UpdateDepot() {
    const [errorMessage, setErrorMessage] = useState([]);
    const [errorEmpty, setErrorEmpty] = useState('');
    const [sorterList, setSorterList] = useState([]);
    const location = useLocation();
    const { id } = location.state || {};
    const [depotData, setDepotData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchDepotData = async () => {
            try {
                const depotRawData = await getDepotById(id);
                const depotRowDataCheck = getDepotByIdCheck(depotRawData);
                if (depotRawData.length && depotRowDataCheck !== true) {
                    console.error(depotRowDataCheck);
                    Sentry.captureException(depotRowDataCheck);
                    navigate('/error');
                    return;
                }
                setDepotData(depotRawData[0]);

                const result = await getSorters();
                setSorterList(result);
            } catch (error) {
                console.error("Erreur lors de la récupération des infos de l'industriel", error);
            }
        };
        fetchDepotData();
    }, [id, navigate]);

    const [formErrors, setFormErrors] = useState({
        company_name: false,
        name: false,
        address_1: false,
        address_2: false,
        city: false,
        zipcode: false,
        contact_name: false,
        contact_phone: false,
        contact_email: false,
        entity_sorter_id: false,
    });

    const requiredFields = [
        'company_name',
        'name',
        'address_1',
        'city',
        'zipcode',
        'contact_name',
        'contact_phone',
        'contact_email',
        'entity_sorter_id',
    ];

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setDepotData({
            ...depotData,
            [name]: value,
        });
    };

    const handleSaveChanges = async (e) => {
        e.preventDefault();
        setErrorMessage([]);
        setErrorEmpty('');
        const inputEmpty = checkIsInputEmpty(requiredFields, depotData, setFormErrors, setErrorEmpty);
        if (!inputEmpty) {
            const checkFormat = checkDepotFormErrors(depotData, formErrors, setFormErrors, setErrorMessage);
            if (checkFormat) {
                return;
            } else {
                const addDepotData = await updateDepot(id, depotData);
                if (addDepotData) {
                    alert('Le centre de collecte a été modifié avec succès');
                    navigate(-1);
                } else {
                    alert('une erreur est survenue lors de la modification du centre de collecte');
                }
            }
        } else {
            console.log('Il reste des champs obligatoires vides');
        }
    };
    return (
        <main>
            <section className="big-section">
                <h2>Modifier un centre de collecte</h2>
                {depotData.id ? (
                    <div className="form-container">
                        <section>
                            <div>
                                <label>Société Mère:</label>
                                <input
                                    className={formErrors.company_name ? 'error' : ''}
                                    type="text"
                                    name="company_name"
                                    value={depotData.company_name || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <label>Nom du centre de collecte:</label>
                                <input
                                    className={formErrors.name ? 'error' : ''}
                                    type="text"
                                    name="name"
                                    value={depotData.name || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <label>Centre de tri:</label>
                                <select
                                    value={depotData.entity_sorter_id || ''}
                                    className={formErrors.entity_sorter_id ? 'error' : ''}
                                    name="entity_sorter_id"
                                    onChange={handleInputChange}>
                                    {sorterList.map((option, index) => (
                                        <option
                                            key={index}
                                            value={option.id}>
                                            {option.company_name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </section>
                        <section>
                            <AddressSearch
                                formData={depotData}
                                setFormData={setDepotData}
                                formErrors={formErrors}
                                setFormErrors={setFormErrors}
                                handleChange={handleInputChange}
                            />
                        </section>

                        <section>
                            <div>
                                <label>Nom du contact:</label>
                                <input
                                    className={formErrors.contact_name ? 'error' : ''}
                                    type="text"
                                    name="contact_name"
                                    value={depotData.contact_name || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <label>Téléphone du contact:</label>
                                <input
                                    className={formErrors.contact_phone ? 'error' : ''}
                                    type="number"
                                    name="contact_phone"
                                    value={depotData.contact_phone || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <label>Email du contact:</label>
                                <input
                                    className={formErrors.contact_email ? 'error' : ''}
                                    type="email"
                                    name="contact_email"
                                    value={depotData.contact_email || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <label>Statut:</label>
                                <select
                                    style={{ backgroundColor: _defineStatus(depotData.status).color, color: Constant.$blanc }}
                                    name="status"
                                    value={depotData.status}
                                    onChange={handleInputChange}>
                                    <option value={0}>Actif</option>
                                    <option value={100}>Inactif</option>
                                </select>
                            </div>
                        </section>
                    </div>
                ) : null}
                <p className="error-form">{errorEmpty && errorEmpty}</p>
                {errorMessage.length
                    ? errorMessage.map((item, index) => {
                          return (
                              <div
                                  className="error-form"
                                  key={index}>
                                  {item}
                              </div>
                          );
                      })
                    : null}
                <div className="button-confirm">
                    <button
                        className="button-actif"
                        onClick={handleSaveChanges}>
                        Enregistrer les modifications
                    </button>
                </div>
            </section>
        </main>
    );
}
