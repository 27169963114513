// INDUSTRIAL API

export const getAddressInfoByIdCheck = (data) => {
    const name = 'getAddressInfoById : ';
    for (let i = 0; i < data.length; i++) {
        if (!data[i].id) {
            return name + 'No id found';
        } else if (!data[i].entity.id) {
            return name + 'No entity id found';
        } else {
            return true;
        }
    }
};

export const getAllIndustrialProductsCheck = (data) => {
    const name = 'getAllIndustrialProducts : ';
    for (let i = 0; i < data.length; i++) {
        if (!data[i].id) {
            return name + 'No id found';
        } else if (!data[i].entity.id) {
            return name + 'No entity id found';
        } else {
            return true;
        }
    }
};

export const getIndustrialProductByDatesCheck = (data) => {
    const name = 'getIndustrialProductByDates : ';
    for (let i = 0; i < data.length; i++) {
        if (!data[i].id) {
            return name + 'No id found';
        } else if (!data[i].entity.id) {
            return name + 'No entity id found';
        } else if (!data[i].container.id) {
            return name + 'No container id found';
        } else {
            return true;
        }
    }
};

export const getIndustrialProductByNameCheck = (data) => {
    const name = 'getIndustrialProductByName : ';
    for (let i = 0; i < data.length; i++) {
        if (!data[i].id) {
            return name + 'No id found';
        } else if (!data[i].entity.id) {
            return name + 'No entity id found';
        } else {
            return true;
        }
    }
};

export const getProductInfoByIdCheck = (data) => {
    const name = 'getProductInfoById : ';
    for (let i = 0; i < data.length; i++) {
        if (!data[i].id) {
            return name + 'No id found';
        } else if (!data[i].entity.id) {
            return name + 'No entity id found';
        } else {
            return true;
        }
    }
};
