import React, { useState, useRef } from 'react';
import { useEffect } from 'react';
import { getSorterById } from '../../../API/SorterApi';
import { format } from 'date-fns';
import { getSorterEventsByWasher, sorterPickUpReceived } from '../../../API/SorterApi';
import { _defineCollecteStatus } from '../../../Utils';
import { getOperatorById } from '../../../API/OperatorApi';
import * as Constant from '../../../Constants';
import { Jwt } from '../../../Jwt';
import { SortAscending, SortDescending } from '@phosphor-icons/react';
import { getSorterEventsByWasherCheck } from '../../../API/CheckApi/CheckSorterApi';
import * as Sentry from '@sentry/react';
import { useNavigate } from 'react-router-dom';

export default function WasherReception() {
    const navigate = useNavigate();
    const userContext = new Jwt();
    const [allEvents, setAllEvents] = useState([]);
    const [showArchives, setShowArchives] = useState(false);
    const [archivesTab, setArchivesTab] = useState([]);
    const [receptionData, setReceptionData] = useState([]);
    const [filter, SetFilter] = useState('asc');

    const dialogRef = useRef(null);

    useEffect(() => {
        const fetchAllEvents = async () => {
            const sorterEventsRawData = (await getSorterEventsByWasher(userContext.entityId)) || [];
            const sorterEventsRawDataCheck = getSorterEventsByWasherCheck(sorterEventsRawData);
            if (sorterEventsRawData.length && sorterEventsRawDataCheck !== true) {
                console.error(sorterEventsRawDataCheck);
                Sentry.captureException(sorterEventsRawDataCheck);
                navigate('/error');
                return;
            }

            for (let i = 0; i < sorterEventsRawData.length; i++) {
                const event = sorterEventsRawData[i];
                const sorterInfo = await getSorterById(event.sorterId);
                const operatorInfo = await getOperatorById(event.operatorId);

                sorterEventsRawData[i] = {
                    ...event,
                    sorterInfo: sorterInfo[0],
                    operatorInfo: operatorInfo[0],
                };
            }

            const tab = [...sorterEventsRawData];

            const finalData = filterData(tab);
            const groupedData = finalData.reduce((acc, obj) => {
                const key = obj.suiviId;
                if (!acc[key]) {
                    acc[key] = [];
                }
                acc[key].push(obj);
                return acc;
            }, []);
            const valuesOnly = Object.values(groupedData);

            setAllEvents(valuesOnly);
            const archiveTabData = tab.filter((item) => item.status === Constant.EVENT_RECEIVED).sort((a, b) => new Date(b.time) - new Date(a.time));
            setArchivesTab(archiveTabData);
        };

        fetchAllEvents();
    }, [userContext.entityId, navigate]);

    const filterData = (data) => {
        const filteredData = [];
        const groupedData = {};
        data.forEach((item) => {
            if (!groupedData[item.suiviId]) {
                groupedData[item.suiviId] = [];
            }
            groupedData[item.suiviId].push(item);
        });

        Object.keys(groupedData).forEach((suiviId) => {
            const hasStatus3 = groupedData[suiviId].some((item) => item.status === Constant.EVENT_RECEIVED);
            if (!hasStatus3) {
                filteredData.push(...groupedData[suiviId]);
            }
        });
        return filteredData;
    };

    const handleModalOpen = (item) => {
        let qtyTotal = 0;
        let nbPalette = 0;
        for (let i = 0; i < item.suivi.sorterDeclarationDetails.length; i++) {
            qtyTotal += item.suivi.sorterDeclarationDetails[i].container.palet_nbcontainer;
            nbPalette += item.suivi.sorterDeclarationDetails[i].qty;
        }
        item.qtyTotal = qtyTotal;
        item.nbPalette = nbPalette;
        setReceptionData(item);

        if (dialogRef.current) {
            dialogRef.current.showModal();
        }
    };

    const handleModalClose = () => {
        setReceptionData();

        if (dialogRef.current) {
            dialogRef.current.close();
        }
    };

    const sendPickUpReceived = async () => {
        const dateAujourdhui = new Date();
        const pickUpReceivedTime = format(dateAujourdhui, 'yyyy-MM-dd HH:mm:ss');
        try {
            const data = await sorterPickUpReceived(receptionData, pickUpReceivedTime);
            if (data.message === 'ok') {
                alert('Enregistrement réussi.');
                handleModalClose();
                window.location.reload();
            } else {
                alert('Une erreur est survenue');
            }
        } catch (err) {
            console.log(err);
        }
    };

    const sortData = (data, setData) => {
        const sortedData = [...data].sort((a, b) => {
            return filter === 'asc' ? a[0].time.localeCompare(b[0].time) : b[0].time.localeCompare(a[0].time);
        });
        setData(sortedData);
    };
    return (
        <main>
            <section className="big-section">
                <div className="top top-history">
                    <h2>Réceptions </h2>
                    <div className="sort">
                        <p
                            onClick={() => {
                                setShowArchives(false);
                            }}
                            className={!showArchives ? 'selected' : 'classic'}>
                            En cours
                        </p>
                        <p
                            onClick={() => {
                                setShowArchives(true);
                            }}
                            className={showArchives ? 'selected' : 'classic'}>
                            Archivées
                        </p>
                    </div>
                </div>
                {!showArchives ? (
                    <div className="tab-item">
                        <div
                            className="pre-filled-fields-1-several-item"
                            style={{ fontWeight: '600', width: '100%' }}>
                            <p>En provenance de</p>
                            <p>Opérateur</p>
                            <p style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>Date de livraison</span>
                                <span
                                    style={{ paddingRight: '0.93rem', cursor: 'pointer' }}
                                    onClick={() => {
                                        SetFilter(filter === 'asc' ? 'desc' : 'asc');
                                        sortData(allEvents, setAllEvents);
                                    }}>
                                    {filter === 'asc' ? <SortAscending size={15} /> : <SortDescending size={15} />}
                                </span>
                            </p>{' '}
                            <p>Statut</p>
                        </div>
                        {allEvents.length ? (
                            allEvents.map((sousTableau, index) => {
                                const objetAvecStatutPlusEleve = sousTableau.reduce((prev, current) => {
                                    return prev.status > current.status ? prev : current;
                                });

                                return (
                                    <div
                                        onClick={() => {
                                            handleModalOpen(objetAvecStatutPlusEleve);
                                        }}
                                        key={index}
                                        style={{ display: 'flex', flexWrap: 'wrap', width: '100%', justifyContent: 'space-between', cursor: 'pointer' }}>
                                        <div
                                            className="pre-filled-fields-2-several-item"
                                            key={objetAvecStatutPlusEleve.id}
                                            style={{ width: '100%' }}>
                                            <p>{objetAvecStatutPlusEleve.sorterInfo.company_name}</p>
                                            <p>{objetAvecStatutPlusEleve.operatorInfo.company_name}</p>
                                            <p>{format(objetAvecStatutPlusEleve.time, 'yyyy-MM-dd')}</p>
                                            <p
                                                style={{
                                                    backgroundColor: _defineCollecteStatus(objetAvecStatutPlusEleve.status).color,
                                                    borderTopRightRadius: '0.6rem',
                                                    borderBottomRightRadius: '0.6rem',
                                                    color: Constant.$blanc,
                                                }}>
                                                {_defineCollecteStatus(objetAvecStatutPlusEleve.status).name}
                                            </p>
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <div className="no-data-graph ">Aucune réception prévue.</div>
                        )}
                    </div>
                ) : (
                    <div className="tab-item">
                        <div
                            className="pre-filled-fields-1-several-item"
                            style={{ fontWeight: '600', width: '100%' }}>
                            <p>En provenance de</p>
                            <p>Opérateur</p>
                            <p>Date de livraison</p>
                            <p>Statut</p>
                        </div>
                        {archivesTab.length ? (
                            archivesTab.map((item, index) => {
                                return (
                                    <div
                                        style={{ display: 'flex', flexWrap: 'wrap', width: '100%', justifyContent: 'space-between', cursor: 'pointer' }}
                                        key={index}
                                        onClick={() => {
                                            handleModalOpen(item);
                                        }}>
                                        <div
                                            style={{ width: '100%' }}
                                            className="pre-filled-fields-2-several-item">
                                            <p>{item.sorterInfo.company_name}</p>
                                            <p>{item.operatorInfo.company_name}</p>
                                            <p>{format(item.time, 'yyyy-MM-dd')}</p>
                                            <p>{_defineCollecteStatus(item.status).name}</p>
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <div className="no-data-graph ">Aucun réception archivée.</div>
                        )}
                    </div>
                )}
            </section>
            <dialog
                ref={dialogRef}
                className="dialog-collector">
                <div className="title">
                    <p> {receptionData && receptionData.status === Constant.EVENT_DONE ? 'Confirmer la réception' : 'Details'} </p>
                    <button
                        className="button-negatif"
                        onClick={handleModalClose}>
                        X
                    </button>
                </div>
                {receptionData && receptionData.operatorInfo && receptionData.sorterInfo ? (
                    <div className="collector-infos">
                        <p style={{ marginTop: '1rem', paddingBottom: '0.5rem' }}>En provenance de :</p>
                        <span>{receptionData.sorterInfo.company_name}</span>
                        <p style={{ marginTop: '1rem', paddingBottom: '0.5rem' }}>SKU :</p>
                        <span>{receptionData.sku}</span>
                        <p style={{ marginTop: '1rem', paddingBottom: '0.5rem' }}>Nombre de palettes :</p>
                        <span>
                            {receptionData.nbPalette} ({receptionData.qtyTotal} contenants)
                        </span>
                        <p style={{ marginTop: '1rem', paddingBottom: '0.5rem' }}>Statut :</p>
                        <span>
                            <p>{_defineCollecteStatus(receptionData.status).name}</p>
                        </span>
                    </div>
                ) : null}
                {receptionData && receptionData.status === Constant.EVENT_DONE ? (
                    <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '2rem' }}>
                        <button
                            className="button-negatif"
                            onClick={handleModalClose}>
                            ANNULER
                        </button>
                        <button
                            onClick={sendPickUpReceived}
                            className="button-actif">
                            VALIDER
                        </button>
                    </div>
                ) : null}
            </dialog>
        </main>
    );
}
