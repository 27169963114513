import { useEffect, useState } from 'react';
import { getCollectorsByDates } from '../../../../API/CollectorApi';
import { getCollectorsByDatesCheck } from '../../../../API/CheckApi/CheckCollectorApi';
import { Jwt } from '../../../../Jwt';
import BarChart from '../../../../Components/BarChart';
import PieChart from '../../../../Components/PieChart';
import CircleChart from '../../../../Components/CircleChart';
import { _defineCollectorType, _defineStoreOwner, _defineStoreType, getRegionsName } from '../../../../Utils';
import * as Constant from '../../../../Constants';
import { CSVLink } from 'react-csv';
import { format, endOfDay } from 'date-fns';
import DateFilter from '../../../../Components/DateFilter';
import { countOccurenceByKey } from '../../../../UtilsData';
import * as Sentry from '@sentry/react';
import { useNavigate } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
export default function DsitribDataReuseCollecteurs() {
    const navigate = useNavigate();
    const now = new Date();
    const userContext = new Jwt();
    const [collectorActive, setCollectorActive] = useState(0);
    const [collectorByRegion, setCollectorByRegion] = useState([]);
    const [collectorByType, setCollectorByType] = useState([]);
    const [rvmByRegion, setRvmByRegion] = useState([]);
    const [tceByRegion, setTceByRegion] = useState([]);
    const [collectorByStoreType, setCollectorByStoreType] = useState([]);
    const [collectorByOwnerStore, setCollectorByOwnerStore] = useState([]);
    const [formattedTabGlobalData, setFormattedTabGlobalData] = useState([]);
    const [formattedTabMyRefsData, setFormattedTabMyRefsData] = useState([]);
    const [myCollectorsActive, setMyCollectorsActive] = useState(0);
    const [myCollectorsContribution, setMyCollectorsContribution] = useState(0);
    const [myCollectorByRegion, setMyCollectorByRegion] = useState([]);
    const [myCollectorByType, setMyCollectorByType] = useState([]);
    const [myRvmByRegion, setMyRvmByRegion] = useState([]);
    const [myTceByRegion, setMyTceByRegion] = useState([]);
    const [myCollectorByStoreType, setMyCollectorByStoreType] = useState([]);
    const [myRatio, setMyRatio] = useState([]);
    // FILTRES
    const [startDate, setStartDate] = useState(new Date(now.getFullYear(), 0, 1));
    const [endDate, setEndDate] = useState(now);

    useEffect(() => {
        const fetchData = async () => {
            setCollectorActive(0);
            setCollectorByRegion([]);
            setCollectorByType([]);
            setRvmByRegion([]);
            setTceByRegion([]);
            setCollectorByStoreType([]);
            setCollectorByOwnerStore([]);
            setFormattedTabGlobalData([]);
            setMyCollectorsActive(0);
            setMyCollectorsContribution(0);
            setMyCollectorByRegion([]);
            setMyCollectorByType([]);
            setMyRvmByRegion([]);
            setMyTceByRegion([]);
            setMyCollectorByStoreType([]);
            setMyRatio([]);

            try {
                const collectorsRaw = await getCollectorsByDates(startDate.toISOString(), endOfDay(endDate.toISOString()));

                const collectorRawCheck = getCollectorsByDatesCheck(collectorsRaw);
                if (collectorsRaw.length && collectorRawCheck !== true) {
                    console.error(collectorRawCheck);
                    Sentry.captureException(collectorRawCheck);
                    navigate('/error');
                    return;
                }
                const collectorActiveRaw = collectorsRaw.filter((collector) => collector.status === 0);
                setCollectorActive(collectorActiveRaw.length);

                const countedRegions = countOccurenceByKey(collectorActiveRaw, 'region');
                const regionsArray = Object.keys(countedRegions).map((region) => ({
                    region: getRegionsName(region),
                    qty: ((countedRegions[region] / collectorActiveRaw.length) * 100).toFixed(1),
                }));
                setCollectorByRegion(regionsArray);

                const countedType = countOccurenceByKey(collectorActiveRaw, 'type');
                const collectorByTypeRaw = Object.keys(countedType).map((type) => ({
                    type: _defineCollectorType(type),
                    qty: ((countedType[type] / collectorActiveRaw.length) * 100).toFixed(1),
                }));
                setCollectorByType(collectorByTypeRaw);

                const collectorRvmRaw = collectorActiveRaw.filter((collector) => collector.type === parseInt(Constant.COLLECTOR_TYPE_RVM));
                const countedRvm = countOccurenceByKey(collectorRvmRaw, 'region');
                const collectorRvmCounted = Object.keys(countedRvm).map((region) => ({
                    region: getRegionsName(region),
                    qty: ((countedRvm[region] / collectorRvmRaw.length) * 100).toFixed(1),
                }));
                setRvmByRegion(collectorRvmCounted);

                const collectorTceRaw = collectorActiveRaw.filter((collector) => collector.type === parseInt(Constant.COLLECTOR_TYPE_TCE));
                const countedTce = countOccurenceByKey(collectorTceRaw, 'region');
                const collectorTceCounted = Object.keys(countedTce).map((region) => ({
                    region: getRegionsName(region),
                    qty: ((countedTce[region] / collectorTceRaw.length) * 100).toFixed(1),
                }));
                setTceByRegion(collectorTceCounted);

                const collectorByOwnerStoreRaw = countOccurenceByKey(collectorActiveRaw, 'store_owner');
                const collectorByOwnerStoreCounted = Object.keys(collectorByOwnerStoreRaw).map((store_owner) => ({
                    store_owner: _defineStoreOwner(store_owner),
                    qty: ((collectorByOwnerStoreRaw[store_owner] / collectorActiveRaw.length) * 100).toFixed(1),
                }));
                setCollectorByOwnerStore(collectorByOwnerStoreCounted);

                const collectorByStoreTypeRaw = countOccurenceByKey(collectorActiveRaw, 'store_type');
                const collectorByStoreCounted = Object.keys(collectorByStoreTypeRaw).map((store_type) => ({
                    store_type: _defineStoreType(store_type),
                    qty: ((collectorByStoreTypeRaw[store_type] / collectorActiveRaw.length) * 100).toFixed(1),
                    qtyGlobal: collectorByStoreTypeRaw[store_type],
                }));
                setCollectorByStoreType(collectorByStoreCounted);

                const formattedDataRaw = collectorActiveRaw.map((item) => {
                    const collector = {
                        nom: item.store_name,
                        region: getRegionsName(item.region),
                        store_type: _defineStoreType(item.store_type),
                        collector_type: _defineCollectorType(item.type),
                        datei: format(item.createdAt, 'yyyy-MM-dd'),
                    };
                    return collector;
                });

                setFormattedTabGlobalData(formattedDataRaw);

                // MES REFERENCES
                const myCollectorRaw = collectorActiveRaw.filter((collector) => collector.store.distributor_id === userContext.entityId);
                setMyCollectorsActive(myCollectorRaw.length);
                const myCollectorContributionRaw = (myCollectorRaw.length / collectorActiveRaw.length) * 100;
                setMyCollectorsContribution(myCollectorContributionRaw);

                const myCollectorCountedRegions = countOccurenceByKey(myCollectorRaw, 'region');
                const myRegionsArray = Object.keys(myCollectorCountedRegions).map((region) => ({
                    region: getRegionsName(region),
                    qty: ((myCollectorCountedRegions[region] / myCollectorRaw.length) * 100).toFixed(1),
                }));

                setMyCollectorByRegion(myRegionsArray);

                const myCollectorCountedType = countOccurenceByKey(myCollectorRaw, 'type');
                const myCollectorByTypeRaw = Object.keys(myCollectorCountedType).map((type) => ({
                    type: _defineCollectorType(type),
                    qty: ((myCollectorCountedType[type] / myCollectorRaw.length) * 100).toFixed(1),
                }));
                setMyCollectorByType(myCollectorByTypeRaw);

                const myCollectorRvmRaw = myCollectorRaw.filter((collector) => collector.type === parseInt(Constant.COLLECTOR_TYPE_RVM));
                const countedMyRvm = countOccurenceByKey(myCollectorRvmRaw, 'region');
                const myCollectorRvmCounted = Object.keys(countedMyRvm).map((region) => ({
                    region: getRegionsName(region),
                    qty: ((countedMyRvm[region] / myCollectorRvmRaw.length) * 100).toFixed(1),
                }));
                setMyRvmByRegion(myCollectorRvmCounted);

                const myCollectorTceRaw = myCollectorRaw.filter((collector) => collector.type === parseInt(Constant.COLLECTOR_TYPE_TCE));
                const countedMyTce = countOccurenceByKey(myCollectorTceRaw, 'region');
                const myCollectorTceCounted = Object.keys(countedMyTce).map((region) => ({
                    region: getRegionsName(region),
                    qty: ((countedMyTce[region] / myCollectorTceRaw.length) * 100).toFixed(1),
                }));
                setMyTceByRegion(myCollectorTceCounted);

                const myCollectorByStoreTypeRaw = countOccurenceByKey(myCollectorRaw, 'store_type');
                const myCollectorByStoreCounted = Object.keys(myCollectorByStoreTypeRaw).map((store_type) => ({
                    store_type: _defineStoreType(store_type),

                    qty: ((myCollectorByStoreTypeRaw[store_type] / myCollectorRaw.length) * 100).toFixed(1),
                    qtyMyRef: myCollectorByStoreTypeRaw[store_type],
                }));
                setMyCollectorByStoreType(myCollectorByStoreCounted);

                // POUR LE POIDS DE MES COLLECTEURS
                const myRatioRaw = mergeArrays(collectorByStoreCounted, myCollectorByStoreCounted);
                setMyRatio(myRatioRaw);

                const formattedMyDataRaw = myCollectorRaw.map((item) => {
                    const collector = {
                        nom: item.store_name,
                        region: getRegionsName(item.region),
                        store_type: _defineStoreType(item.store_type),
                        collector_type: _defineCollectorType(item.type),
                        datei: format(item.createdAt, 'yyyy-MM-dd'),
                    };
                    return collector;
                });
                setFormattedTabMyRefsData(formattedMyDataRaw);
            } catch (error) {
                console.debug(error);
            }
        };
        if (startDate & endDate) fetchData();
    }, [startDate, endDate, navigate, userContext.entityId]);
    const mergeArrays = (array1, array2) => {
        const mergedArray = [];
        const map = new Map();

        array1.forEach((item) => {
            const { qty, ...rest } = item;
            const newItem = {
                ...rest,
                qtyGlobal: rest.qtyGlobal !== undefined ? rest.qtyGlobal : 0,
                qtyMyRef: rest.qtyMyRef !== undefined ? rest.qtyMyRef : 0,
            };
            newItem.qtyRatio = (newItem.qtyMyRef / newItem.qtyGlobal) * 100;
            map.set(item.store_type, newItem);
        });

        array2.forEach((item) => {
            const { qty, ...rest } = item;
            if (map.has(item.store_type)) {
                const existingItem = map.get(item.store_type);
                const mergedItem = {
                    ...existingItem,
                    ...rest,
                    qtyGlobal: rest.qtyGlobal !== undefined ? rest.qtyGlobal : existingItem.qtyGlobal,
                    qtyMyRef: rest.qtyMyRef !== undefined ? rest.qtyMyRef : existingItem.qtyMyRef,
                };
                mergedItem.qtyRatio = (mergedItem.qtyMyRef / mergedItem.qtyGlobal) * 100;
                map.set(item.store_type, mergedItem);
            } else {
                const newItem = {
                    ...rest,
                    qtyGlobal: rest.qtyGlobal !== undefined ? rest.qtyGlobal : 0,
                    qtyMyRef: rest.qtyMyRef !== undefined ? rest.qtyMyRef : 0,
                };
                newItem.qtyRatio = (newItem.qtyMyRef / newItem.qtyGlobal) * 100;
                map.set(item.store_type, newItem);
            }
        });

        // Convertir le map en tableau
        map.forEach((value) => {
            mergedArray.push(value);
        });

        return mergedArray;
    };

    const collectorByRegionQuantities = collectorByRegion.map((item) => item.qty);
    const collectorByRegionLabels = collectorByRegion.map((item) => item.region);

    const collectorByTypeQuantities = collectorByType.map((item) => item.qty);
    const collectorByTypeLabels = collectorByType.map((item) => item.type);

    const rvmByRegionQuantities = rvmByRegion.map((item) => item.qty);
    const rvmByRegionLabels = rvmByRegion.map((item) => item.region);

    const tceByRegionQuantities = tceByRegion.map((item) => item.qty);
    const tceByRegionLabels = tceByRegion.map((item) => item.region);

    const collectorByStoreTypeQuantities = collectorByStoreType.map((item) => item.qty);
    const collectorByStoreTypeLabels = collectorByStoreType.map((item) => item.store_type);

    const collectorByOwnerStoreQuantities = collectorByOwnerStore.map((item) => item.qty);
    const collectorByOwnerStoreLabels = collectorByOwnerStore.map((item) => item.store_owner);

    const myCollectorByRegionQuantities = myCollectorByRegion.map((item) => item.qty);
    const myCollectorByRegionLabels = myCollectorByRegion.map((item) => item.region);

    const myCollectorByTypeQuantities = myCollectorByType.map((item) => item.qty);
    const myCollectorByTypeLabels = myCollectorByType.map((item) => item.type);

    const myRvmByRegionQuantities = myRvmByRegion.map((item) => item.qty);
    const myRvmByRegionLabels = myRvmByRegion.map((item) => item.region);

    const myTceByRegionQuantities = myTceByRegion.map((item) => item.qty);
    const myTceByRegionLabels = myTceByRegion.map((item) => item.region);

    const myCollectorByStoreTypeQuantities = myCollectorByStoreType.map((item) => item.qty);
    const myCollectorByStoreTypeLabels = myCollectorByStoreType.map((item) => item.store_type);

    const myRatioQantities = myRatio.map((item) => item.qtyRatio);
    const myRatioLabels = myRatio.map((item) => item.store_type);
    const customHeader = [
        {
            label: 'Nom du magasin',
            key: 'nom',
        },
        {
            label: 'Région',
            key: 'region',
        },
        {
            label: 'Type de magasin',
            key: 'store_type',
        },
        {
            label: 'Type de collecteur',
            key: 'collector_type',
        },
        {
            label: "Date d'implentation",
            key: 'datei',
        },
    ];

    return (
        <main>
            <div className="data-container">
                <div className="header-title">
                    <h1>COLLECTEURS</h1>
                    <div className="date-container">
                        <DateFilter
                            startDate={startDate}
                            endDate={endDate}
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                        />
                    </div>
                </div>
                <div className="header-container">
                    <div
                        className="header-title"
                        style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <h1>Global marché</h1>
                        <div
                            className="extract"
                            style={{ marginTop: '2rem' }}>
                            <CSVLink
                                style={{ textDecoration: 'none', color: Constant.$blanc, marginBottom: '1rem' }}
                                className="extract-button"
                                headers={customHeader}
                                data={formattedTabGlobalData ? formattedTabGlobalData : []}
                                filename={`returns_data.csv`}
                                target="_blank"
                                separator={';'}>
                                Extraire la donnée globale
                            </CSVLink>
                        </div>
                    </div>
                </div>

                <div style={{ display: 'flex', gap: '1%' }}>
                    <section
                        className="section-number"
                        style={{ width: '33%' }}>
                        <div className="title">Nombre de collecteurs actifs</div>
                        <div className="content">
                            <div className="number">{collectorActive}</div>
                            <div className="text"></div>
                        </div>
                    </section>
                    <section style={{ width: '50%' }}></section>
                </div>
                <div style={{ display: 'flex', gap: '1%' }}>
                    <section
                        className="section-graph"
                        style={{ width: '33%' }}>
                        <div className="title">Répartition des collecteurs par région (%) :</div>
                        <div style={{ height: '13rem' }}>
                            {collectorByRegion.length > 0 ? (
                                <PieChart
                                    title={collectorByRegionLabels}
                                    quantities={collectorByRegionQuantities}
                                    position={'right'}
                                />
                            ) : (
                                <div className="no-data-graph ">Aucune donnée.</div>
                            )}
                        </div>
                    </section>
                    <section
                        className="section-graph"
                        style={{ width: '33%' }}>
                        <div className="title">Répartition des collecteurs par type (%) :</div>
                        <div style={{ height: '13rem' }}>
                            {collectorByType.length > 0 ? (
                                <CircleChart
                                    title={collectorByTypeLabels}
                                    quantities={collectorByTypeQuantities}
                                    position={'right'}
                                />
                            ) : (
                                <div className="no-data-graph ">Aucune donnée.</div>
                            )}
                        </div>
                    </section>
                    <section
                        className="section-graph"
                        style={{ width: '33%' }}>
                        <div className="title">Répartition des RVM par région (%) :</div>
                        <div style={{ height: '13rem' }}>
                            {rvmByRegion.length > 0 ? (
                                <PieChart
                                    title={rvmByRegionLabels}
                                    quantities={rvmByRegionQuantities}
                                    position={'right'}
                                />
                            ) : (
                                <div className="no-data-graph ">Aucune donnée.</div>
                            )}
                        </div>
                    </section>
                </div>
                <div style={{ display: 'flex', gap: '1%' }}>
                    <section
                        className="section-graph"
                        style={{ width: '33%' }}>
                        <div className="title">Répartition des TCE par région (%) :</div>
                        <div style={{ height: '13rem' }}>
                            {tceByRegion.length > 0 ? (
                                <PieChart
                                    title={tceByRegionLabels}
                                    quantities={tceByRegionQuantities}
                                    position={'right'}
                                />
                            ) : (
                                <div className="no-data-graph ">Aucune donnée.</div>
                            )}
                        </div>
                    </section>
                    <section
                        className="section-graph"
                        style={{ width: '33%' }}>
                        <div className="title">Répartition des collecteurs par propriétaire (%) :</div>
                        <div style={{ height: '13rem' }}>
                            {collectorByOwnerStore.length > 0 ? (
                                <CircleChart
                                    title={collectorByOwnerStoreLabels}
                                    quantities={collectorByOwnerStoreQuantities}
                                />
                            ) : (
                                <div className="no-data-graph ">Aucune donnée.</div>
                            )}
                        </div>
                    </section>
                    <section
                        className="section-graph"
                        style={{ width: '33%' }}>
                        <div className="title">
                            Répartition des collecteurs par type <br />
                            de magasin (%) :
                        </div>
                        <div style={{ height: '13rem' }}>
                            {collectorByStoreType.length > 0 ? (
                                <PieChart
                                    title={collectorByStoreTypeLabels}
                                    quantities={collectorByStoreTypeQuantities}
                                    position={'right'}
                                />
                            ) : (
                                <div className="no-data-graph ">Aucune donnée.</div>
                            )}
                        </div>
                    </section>
                </div>
                <div className="header-container">
                    <div
                        className="header-title"
                        style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <h1>Collecteurs propres à mon enseigne</h1>
                        <div
                            className="extract"
                            style={{ marginTop: '2rem' }}>
                            <CSVLink
                                style={{ textDecoration: 'none', color: Constant.$blanc, marginBottom: '1rem' }}
                                className="extract-button"
                                headers={customHeader}
                                data={formattedTabMyRefsData ? formattedTabMyRefsData : []}
                                filename={`returns_data.csv`}
                                target="_blank"
                                separator={';'}>
                                Extraire ma donnée
                            </CSVLink>
                        </div>
                    </div>
                </div>

                <div style={{ display: 'flex', gap: '1%' }}>
                    <section
                        className="section-number"
                        style={{ width: '33%' }}>
                        <div className="title">Nombre de collecteurs actifs</div>
                        <div className="content">
                            <div className="number">{myCollectorsActive}</div>
                        </div>
                    </section>
                    <section
                        className="section-number"
                        style={{ width: '33%' }}>
                        <div className="title">Ma contribution marché</div>
                        <div className="content">
                            <div className="number">{myCollectorsContribution.toFixed(1)}%</div>
                        </div>
                    </section>
                    <section style={{ width: '33%' }}></section>
                </div>
                <div style={{ display: 'flex', gap: '1%' }}>
                    <section
                        className="section-graph"
                        style={{ width: '33%' }}>
                        <div className="title">Répartition des collecteurs par région (%) :</div>
                        <div style={{ height: '13rem' }}>
                            {myCollectorByRegion.length > 0 ? (
                                <PieChart
                                    title={myCollectorByRegionLabels}
                                    quantities={myCollectorByRegionQuantities}
                                    position={'right'}
                                />
                            ) : (
                                <div className="no-data-graph ">Aucune donnée.</div>
                            )}
                        </div>
                    </section>
                    <section
                        className="section-graph"
                        style={{ width: '33%' }}>
                        <div className="title">Répartition des collecteurs par type (%) :</div>
                        <div style={{ height: '13rem' }}>
                            {myCollectorByType.length > 0 ? (
                                <CircleChart
                                    title={myCollectorByTypeLabels}
                                    quantities={myCollectorByTypeQuantities}
                                />
                            ) : (
                                <div className="no-data-graph ">Aucune donnée.</div>
                            )}
                        </div>
                    </section>
                    <section
                        className="section-graph"
                        style={{ width: '33%' }}>
                        <div className="title">Répartition des RVM par région (%) :</div>
                        <div style={{ height: '13rem' }}>
                            {myRvmByRegion.length > 0 ? (
                                <PieChart
                                    title={myRvmByRegionLabels}
                                    quantities={myRvmByRegionQuantities}
                                    position={'right'}
                                />
                            ) : (
                                <div className="no-data-graph ">Aucune donnée.</div>
                            )}
                        </div>
                    </section>
                </div>
                <div style={{ display: 'flex', gap: '1%', marginBottom: '1rem' }}>
                    <section
                        className="section-graph"
                        style={{ width: '33%' }}>
                        <div className="title">Répartition des TCE par région (%) :</div>
                        <div style={{ height: '13rem' }}>
                            {myTceByRegion.length > 0 ? (
                                <PieChart
                                    title={myTceByRegionLabels}
                                    quantities={myTceByRegionQuantities}
                                    position={'right'}
                                />
                            ) : (
                                <div className="no-data-graph ">Aucune donnée.</div>
                            )}
                        </div>
                    </section>
                    <section
                        className="section-graph"
                        style={{ width: '33%' }}>
                        <div className="title">
                            Répartition des collecteurs par type <br />
                            de magasin (%) :
                        </div>
                        <div style={{ height: '13rem' }}>
                            {myCollectorByStoreType.length > 0 ? (
                                <PieChart
                                    title={myCollectorByStoreTypeLabels}
                                    quantities={myCollectorByStoreTypeQuantities}
                                    position={'right'}
                                />
                            ) : (
                                <div className="no-data-graph ">Aucune donnée.</div>
                            )}
                        </div>
                    </section>
                    <section
                        className="section-graph"
                        style={{ width: '33%' }}>
                        <div className="title">Poids de mes collecteurs par type de magasin (%) :</div>
                        <div style={{ height: '13rem' }}>
                            {myRatio.length > 0 ? (
                                <BarChart
                                    title={myRatioLabels}
                                    quantities={myRatioQantities}
                                    label={'%'}
                                />
                            ) : (
                                <div className="no-data-graph">Aucune donnée.</div>
                            )}
                        </div>
                    </section>
                </div>
            </div>
        </main>
    );
}
