import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

const LineChart = ({ labels, quantities }) => {
    const chartData = {
        labels: labels,
        datasets: [
            {
                label: 'Quantités',
                data: quantities,
                fill: false,
                backgroundColor: 'rgba(75,192,192,0.2)',
                borderColor: 'rgba(75,192,192,1)',
                tension: 0.1,
            },
        ],
    };
    const minY = Math.floor(Math.min(...quantities));

    const options = {
        scales: {
            x: {
                title: {
                    display: false,
                    text: 'Mois',
                },
            },
            y: {
                title: {
                    display: false,
                    text: 'Quantités',
                },
                min: minY,
                ticks: {
                    precision: 0,
                    callback: function (value) {
                        return parseInt(value).toString();
                    },
                },
            },
        },
        maintainAspectRatio: false,
    };

    return (
        <div style={{ height: '90%' }}>
            <Line
                data={chartData}
                options={options}
            />
        </div>
    );
};

export default LineChart;
