import { useState, useEffect, useRef, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getAllIndustrialProduct, getIndustrialProductByName } from '../../../../../../API/IndustrialApi';
import { getIndustrialProductByNameCheck } from '../../../../../../API/CheckApi/CheckIndustrialApi';
import { getAllIndustrialProductsCheck } from '../../../../../../API/CheckApi/CheckIndustrialApi';
import { useClickOutside, _defineStatus } from '../../../../../../Utils';
import * as Constant from '../../../../../../Constants';
import SearchComponent from '../../../../../../Components/searchComponent';
import * as Sentry from '@sentry/react';

export default function IndustrialProducts() {
    const navigate = useNavigate();
    const [filterSelected, setFilterSelected] = useState('asc');
    const [allProductsList, setAllProductsList] = useState([]);
    const [productNames, setProductNames] = useState([]);
    const [showOptions, setShowOptions] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const dropdownRef = useRef(null);

    const handleClickOutside = () => {
        setShowOptions(false);
    };
    useClickOutside(dropdownRef, handleClickOutside);

    const fetchAllProducts = useCallback(async () => {
        try {
            const result = await getAllIndustrialProduct();
            const resultCheck = getAllIndustrialProductsCheck(result);
            if (result.length && resultCheck !== true) {
                console.error(resultCheck);
                Sentry.captureException(resultCheck);
                navigate('/error');
                return;
            }
            setAllProductsList(result);
        } catch (error) {
            console.error('Erreur lors de la récupération des produits', error);
        }
    }, [navigate]);

    useEffect(() => {
        const fetchProductNames = async () => {
            try {
                if (searchTerm.length > 2) {
                    const result = await getIndustrialProductByName(searchTerm);
                    const resultCheck = getIndustrialProductByNameCheck(result);
                    if (result.length && resultCheck !== true) {
                        console.error(resultCheck);
                        Sentry.captureException(resultCheck);
                        navigate('/error');
                        return;
                    }

                    if (result.length) {
                        setAllProductsList(result);
                        setProductNames(result);
                        setErrorMessage('');
                    } else {
                        setAllProductsList([]);
                        setProductNames([]);
                        setErrorMessage('Aucun résultat');
                    }
                } else if (searchTerm.length === 0) {
                    fetchAllProducts();
                    setErrorMessage('');
                }
            } catch (error) {
                console.error('Erreur lors de la récupération des produits', error);
            }
        };

        fetchProductNames();
    }, [navigate, searchTerm, fetchAllProducts]);

    const handleInputChange = (e) => {
        setSearchTerm(e.target.value.toLowerCase());
        setShowOptions(true);
    };
    const handleSelectOption = (option) => {
        setSearchTerm(option.name);
        setShowOptions(false);
        navigate('/servicesupplier/operation/industrial/product/update', { state: { id: option.id } });
    };

    const sortDataAsc = () => {
        const sortedData = [...allProductsList].sort((a, b) => a.name.localeCompare(b.name));
        setAllProductsList(sortedData);
        setFilterSelected('asc');
    };

    const sortDataDesc = () => {
        const sortedData = [...allProductsList].sort((a, b) => b.name.localeCompare(a.name));
        setAllProductsList(sortedData);
        setFilterSelected('desc');
    };

    return (
        <main>
            <section className="big-section">
                <h2>Les produits</h2>
                <div className="top">
                    <SearchComponent
                        dropdownRef={dropdownRef}
                        showOptions={showOptions}
                        searchTerm={searchTerm}
                        handleInputChange={handleInputChange}
                        productsNames={productNames}
                        handleSelectOption={handleSelectOption}
                        placeholder="Rechercher un produit"
                        searchKey="name"
                    />

                    <div className="top-button-sort">
                        <div className="filter">
                            <p className="sort">Trier par</p>
                            <div>
                                <p
                                    className={filterSelected === 'asc' ? 'selected' : 'classic'}
                                    onClick={sortDataAsc}>
                                    A &gt; Z
                                </p>
                                <p
                                    className={filterSelected === 'desc' ? 'selected' : 'classic'}
                                    onClick={sortDataDesc}>
                                    Z &gt; A
                                </p>
                            </div>
                        </div>
                        <Link
                            to={'/servicesupplier/operation/industrial/product/add'}
                            className="button-actif">
                            AJOUTER UN PRODUIT
                        </Link>
                    </div>
                </div>
                {errorMessage && (
                    <div style={{ width: '100%', padding: '0.5rem', textAlign: 'center', color: Constant.$rouge, marginBottom: '1.25rem' }}>
                        <p>{errorMessage}</p>
                    </div>
                )}

                <div className="tab-item">
                    <div
                        className="pre-filled-fields-1-several-item"
                        style={{ fontWeight: '600' }}>
                        <p>Marque</p>
                        <p>Nom</p>
                        <p>SKU</p>
                        <p>EAN</p>
                        <p>Statut</p>
                    </div>

                    {allProductsList.length ? (
                        allProductsList.map((item, index) => {
                            return (
                                <Link
                                    to={'/servicesupplier/operation/industrial/product/update'}
                                    state={{ id: item.id }}
                                    key={index}
                                    className="pre-filled-fields-2-several-item">
                                    <p>{item.company_name}</p>
                                    <p>{item.name}</p>
                                    <p>{item.sku}</p>
                                    <p>{item.ean}</p>
                                    <p
                                        style={{
                                            backgroundColor: _defineStatus(item.status).color,
                                            color: Constant.$blanc,
                                            borderTopRightRadius: '0.6rem',
                                            borderBottomRightRadius: '0.6rem',
                                        }}>
                                        {_defineStatus(item.status).name}
                                    </p>
                                </Link>
                            );
                        })
                    ) : (
                        <div className="no-data-graph ">Aucun produit enregistré.</div>
                    )}
                </div>
            </section>
        </main>
    );
}
