import { useState, useEffect, useRef } from 'react';
import { getSorterById, getSorterEventsByOperator } from '../../../API/SorterApi';
import { getIndustrialById } from '../../../API/IndustrialApi';
import { _defineCollecteType, _defineStoreEventType, _defineBoxesStoreState } from '../../../Utils.js';
import { format } from 'date-fns';
import * as Constant from '../../../Constants';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { Jwt } from '../../../Jwt';
import { useNavigate } from 'react-router-dom';
import * as DateFns from 'date-fns';

import { getStoreEventDetailsByOperatorId } from '../../../API/StoreApi';
export default function CollecteHistory() {
    const navigate = useNavigate();
    const userContext = new Jwt();
    const dialogRef = useRef(null);

    const [allEvents, setAllEvents] = useState([]);
    const [showCalendar, setShowCalendar] = useState(false);
    const [collecteData, setCollecteData] = useState([]);
    useEffect(() => {
        const fetchAllEvents = async () => {
            const { gearApi } = await import('../../../gearApi.js');
            const fetchStoreEventsRowData = (await getStoreEventDetailsByOperatorId(userContext.entityId)) || [];

            const groupByStore = fetchStoreEventsRowData.reduce((acc, obj) => {
                const key = `${obj.storeEvent.storeId}-${obj.storeEvent.type}-${obj.suiviId}`;

                if (!acc[key]) {
                    acc[key] = [];
                }
                acc[key].push(obj);
                return acc;
            }, {});

            const storeEventsRowData = Object.keys(groupByStore).map((key) => ({
                time: groupByStore[key][0].storeEvent.time,
                status: groupByStore[key][0].storeEvent.status,
                collecte_type: Constant.COLLECTETYPE_STORE,
                item: groupByStore[key],
                suiviId: groupByStore[key][0].suiviId,
                departure: groupByStore[key][0].storeEvent.store.depot,
            }));

            for (let i = 0; i < storeEventsRowData.length; i++) {
                const sorterInfo = await getSorterById(storeEventsRowData[i].item[0].storeEvent.sorterId);
                if (storeEventsRowData[i].item[0].storeEvent.type === Constant.STOREEVENT_TYPE_COLLECTE) {
                    storeEventsRowData[i].arrival = sorterInfo[0];
                } else {
                    storeEventsRowData[i].arrival = storeEventsRowData[i].departure;
                    storeEventsRowData[i].departure = sorterInfo[0];
                }
            }
            const sorterEventsRowData = (await getSorterEventsByOperator(userContext.entityId)) || [];
            const washerEventsRowData = (await gearApi.washer.getEventsOfWashers({ operatorId: userContext.entityId })) || [];

            for (let i = 0; i < sorterEventsRowData.length; i++) {
                const event = sorterEventsRowData[i];
                const sorterInfo = await getSorterById(event.sorterId);
                const washerInfo = await gearApi.washer.getWasherById({ washerId: event.washerId });
                sorterEventsRowData[i] = {
                    ...event,
                    collecte_type: 1,
                    departure: sorterInfo[0],
                    arrival: washerInfo,
                };
            }

            for (let i = 0; i < washerEventsRowData.length; i++) {
                const event = washerEventsRowData[i];
                const washerInfo = await gearApi.washer.getWasherById({ washerId: event.washerId });
                const industrialInfo = await getIndustrialById(event.industrialId);
                washerEventsRowData[i] = {
                    ...event,
                    collecte_type: 2,
                    departure: washerInfo,
                    arrival: industrialInfo[0],
                };
            }
            const tab = [...storeEventsRowData, ...sorterEventsRowData, ...washerEventsRowData];

            const finalData = tab.filter((item) => item.status === Constant.EVENT_DONE).sort((a, b) => new Date(b.time) - new Date(a.time));
            setAllEvents(finalData);
        };

        fetchAllEvents();
    }, [userContext.entityId, navigate]);

    function handleEventClick(info) {
        const eventData = info.event.extendedProps.eventData;
        handleModalOpen(eventData);
    }

    const handleModalOpen = async (item) => {
        setCollecteData(item);

        if (dialogRef.current) {
            dialogRef.current.showModal();
        }
    };
    const handleModalClose = () => {
        setCollecteData([]);

        if (dialogRef.current) {
            dialogRef.current.close();
        }
    };
    const customButtons = {
        today: {
            text: "Aujourd'hui",
        },
    };

    return (
        <main>
            <section className="big-section">
                <div className="top top-history">
                    <h2>Historique</h2>
                    <div className="sort">
                        <p
                            onClick={() => {
                                setShowCalendar(false);
                            }}
                            className={!showCalendar ? 'selected' : 'classic'}>
                            Liste
                        </p>
                        <p
                            onClick={() => {
                                setShowCalendar(true);
                            }}
                            className={showCalendar ? 'selected' : 'vlassic'}>
                            Calendrier
                        </p>
                    </div>
                </div>
                {!showCalendar ? (
                    <div className="tab-item">
                        <div
                            className="pre-filled-fields-1-several-item"
                            style={{ fontWeight: '600', width: '100%' }}>
                            <p>Type de collecte</p>
                            <p>Départ</p>
                            <p>Arrivée</p>
                            <p>Date de collecte</p>
                        </div>
                        {allEvents.length ? (
                            allEvents.map((item, index) => {
                                return (
                                    <div
                                        style={{ display: 'flex', flexWrap: 'wrap', width: '100%', justifyContent: 'space-between' }}
                                        key={index}>
                                        <div
                                            style={{ width: '100%' }}
                                            onClick={() => {
                                                handleModalOpen(item);
                                            }}
                                            className="pre-filled-fields-2-several-item">
                                            <p>
                                                {item.collecte_type === Constant.COLLECTETYPE_STORE
                                                    ? `${_defineCollecteType(item.collecte_type)} - ${_defineStoreEventType(item.item[0].storeEvent.type)} `
                                                    : _defineCollecteType(item.collecte_type)}
                                            </p>
                                            <p>{item.departure.name}</p>
                                            <p>{item.arrival.name}</p>
                                            <p>{DateFns.format(item.time, 'yyyy-MM-dd')}</p>
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <div className="no-data-graph ">Aucun historique de collecte.</div>
                        )}
                    </div>
                ) : (
                    <FullCalendar
                        events={allEvents.map((item, index) => {
                            return {
                                index: index,
                                title: `Collecte`,
                                date: item.time,
                                eventData: item,
                            };
                        })}
                        initialView="dayGridMonth"
                        plugins={[dayGridPlugin]}
                        eventClick={handleEventClick}
                        locale="fr"
                        customButtons={customButtons}
                    />
                )}
            </section>
            <dialog
                ref={dialogRef}
                className="dialog-collector">
                <div className="title">
                    <p>Informations collecte</p>
                    <button
                        className="button-negatif"
                        onClick={handleModalClose}>
                        X
                    </button>
                </div>
                {collecteData && collecteData.departure && collecteData.arrival ? (
                    <>
                        <div className="collector-infos">
                            <div className="depart-arrival-container">
                                <div>
                                    <h4>Départ</h4>
                                    <p>{collecteData.departure.company_name}</p>
                                    <p>{collecteData.departure.address_1}</p>
                                    <p>{collecteData.departure.address_2}</p>
                                    <p>
                                        {collecteData.departure.zipcode},{collecteData && collecteData.departure.city}
                                    </p>
                                </div>

                                <div className="arrival">
                                    <h4>Arrivée</h4>
                                    <p>{collecteData.arrival.company_name}</p>
                                    <p>{collecteData.arrival.address_1}</p>
                                    <p>{collecteData.arrival.address_2}</p>
                                    <p>
                                        {collecteData.arrival.zipcode},{collecteData.arrival.city}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="collector-infos">
                            {collecteData.collecte_type === Constant.COLLECTETYPE_STORE &&
                                collecteData.item.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                padding: '0.5rem 0',
                                            }}>
                                            <p>
                                                {item.container.name} - {_defineBoxesStoreState(item.empty)}
                                            </p>
                                            <p>x{item.qty}</p>
                                        </div>
                                    );
                                })}
                        </div>
                        <div className="collector-date">
                            <label>Collectée le :</label>
                            <p>{format(collecteData.time, 'yyyy-MM-dd HH:mm:ss')}</p>
                        </div>
                    </>
                ) : null}
            </dialog>
        </main>
    );
}
