import { useState, useEffect } from 'react';
import { _defineStatus, transformDateFull, _defineCollectorType, _defineCount } from '../../../../../Utils';
import { getStoreByRvmownerId } from '../../../../../API/StoreApi';
import { getCollectorsByRvmowner } from '../../../../../API/CollectorApi';
import { getStoreByRvmownerIdCheck } from '../../../../../API/CheckApi/CheckStoreApi';
import { getCollectorsByRvmownerCheck } from '../../../../../API/CheckApi/CheckCollectorApi';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Pencil } from '@phosphor-icons/react';
import * as Sentry from '@sentry/react';
import * as Constant from '../../../../../Constants';
import { useNavigate } from 'react-router-dom';

export default function RvmownerSheet() {
    const navigate = useNavigate();
    const [filterStoreSelected, setFilterStoreSelected] = useState('desc');
    const [filterRvmSelected, setFilterRvmSelected] = useState('desc');
    const [rvmownerData, setRvmownerData] = useState([]);
    const [rvmownerStoresData, setRvmownerStoresData] = useState([]);
    const [rvmownerCollectorData, setRvmownerCollectorData] = useState([]);
    const location = useLocation();
    const { id } = location.state || {};

    useEffect(() => {
        const fetchRvmownerData = async () => {
            try {
                const { gearApi } = await import('../../../../../gearApi.js');

                const rvmownerRawData = await gearApi.rvmowner.getRvmownerById({ rvmownerId: id });
                setRvmownerData(rvmownerRawData);

                const rvmownerStore = await getStoreByRvmownerId(id);
                const rvmownerStoreCheck = getStoreByRvmownerIdCheck(rvmownerStore);
                if (rvmownerStore.length && rvmownerStoreCheck !== true) {
                    console.error(rvmownerStoreCheck);
                    Sentry.captureException(rvmownerStoreCheck);
                    navigate('/error');
                    return;
                }
                setRvmownerStoresData(rvmownerStore);

                const rvmownerCollector = await getCollectorsByRvmowner(id);
                const rvmownerCollectorCheck = getCollectorsByRvmownerCheck(rvmownerCollector);
                if (rvmownerCollector.length && rvmownerCollectorCheck !== true) {
                    console.error(rvmownerCollectorCheck);
                    Sentry.captureException(rvmownerCollectorCheck);
                    navigate('/error');
                    return;
                }
                setRvmownerCollectorData(rvmownerCollector);
            } catch (err) {
                Sentry.captureException(err);
                console.error('Erreur lors de la récupération des infos du gestionnaire de RVM', err);
            }
        };
        fetchRvmownerData();
    }, [id, navigate]);

    const sortDataAsc = (data, setData, type) => {
        if (type === 'store') {
            const sortedData = [...data].sort((a, b) => a.name.localeCompare(b.name));
            setData(sortedData);
            setFilterStoreSelected('asc');
        } else if (type === 'rvm') {
            const sortedData = [...data].sort((a, b) => a.store_name.localeCompare(b.store_name));
            setData(sortedData);
            setFilterRvmSelected('asc');
        }
    };

    const sortDataDesc = (data, setData, type) => {
        if (type === 'store') {
            const sortedData = [...data].sort((a, b) => b.name.localeCompare(a.name));
            setData(sortedData);
            setFilterStoreSelected('desc');
        }
        if (type === 'rvm') {
            const sortedData = [...data].sort((a, b) => b.store_name.localeCompare(a.store_name));
            setData(sortedData);
            setFilterRvmSelected('desc');
        }
    };

    return (
        <main>
            {rvmownerData.id && (
                <div className="sheet">
                    <div className="sheet-top-infos">
                        <div className="title">
                            <Link
                                className="update-indus"
                                to={'/servicesupplier/operation/rvmowner/update'}
                                state={{ id: rvmownerData.id }}>
                                <div className="icon">
                                    <Pencil size={20} />
                                </div>
                            </Link>
                            <h2>{rvmownerData.company_name}</h2>
                            <div
                                className="status"
                                style={{ backgroundColor: _defineStatus(rvmownerData.status).color }}>
                                {_defineStatus(rvmownerData.status).name}
                            </div>
                        </div>
                        <section className="item-info-container">
                            <div className="border">
                                <div>
                                    <p>
                                        Société : <span>{rvmownerData.company_name}</span>
                                    </p>
                                    <p>
                                        Siret : <span>{rvmownerData.siret}</span>
                                    </p>
                                    <p>
                                        Tva intracommunautaire : <span>{rvmownerData.tva_intra}</span>
                                    </p>
                                    <p>
                                        Website : <span>{rvmownerData.website}</span>
                                    </p>
                                </div>
                            </div>
                            <div className="border">
                                <div>
                                    <p>
                                        Adresse 1 : <span>{rvmownerData.address_1}</span>
                                    </p>
                                    <p>
                                        Adresse 2 : <span>{rvmownerData.address_2}</span>
                                    </p>
                                    <p>
                                        Ville : <span>{rvmownerData.city}</span>
                                    </p>
                                    <p>
                                        Code postal : <span>{rvmownerData.zipcode}</span>
                                    </p>
                                </div>
                            </div>
                            <div className="border">
                                <div>
                                    <p>
                                        Nom du contact : <span>{rvmownerData.contact_name}</span>
                                    </p>
                                    <p>
                                        Email du contact : <span>{rvmownerData.contact_email}</span>
                                    </p>
                                    <p>
                                        Téléphone du contact : <span>{rvmownerData.contact_phone}</span>
                                    </p>
                                </div>
                            </div>
                        </section>
                    </div>
                    <section className="big-section">
                        <div className="top">
                            <h2 style={{ margin: '0' }}>Les magasins ({rvmownerStoresData.length})</h2>
                            <div className="top-button-sort">
                                <div className="filter">
                                    <p className="sort">Trier par</p>
                                    <div>
                                        <p
                                            className={filterStoreSelected === 'asc' ? 'selected' : 'classic'}
                                            onClick={() => {
                                                sortDataAsc(rvmownerStoresData, setRvmownerStoresData, 'store');
                                            }}>
                                            A &gt; Z
                                        </p>
                                        <p
                                            className={filterStoreSelected === 'desc' ? 'selected' : 'classic'}
                                            onClick={() => {
                                                sortDataDesc(rvmownerStoresData, setRvmownerStoresData, 'store');
                                            }}>
                                            Z &gt; A
                                        </p>
                                    </div>
                                </div>
                                <Link
                                    to={'/servicesupplier/operation/store/add'}
                                    state={{ depot_id: 0, depot_name: 0, rvmowner_id: id }}
                                    className="button-actif">
                                    AJOUTER UN MAGASIN
                                </Link>
                            </div>
                        </div>

                        <div className="tab-item">
                            <div
                                className="pre-filled-fields-1-several-item"
                                style={{ fontWeight: '600' }}>
                                <p>Nom</p>
                                <p>Adresse 1</p>
                                <p>Adresse 2</p>
                                <p>Ville</p>
                                <p>Code postal</p>
                                <p>Statut</p>
                                <p>Nb RVM</p>
                            </div>

                            {rvmownerStoresData.length ? (
                                rvmownerStoresData.map((item, index) => {
                                    return (
                                        <Link
                                            to={'/servicesupplier/operation/store/sheet'}
                                            state={{ id: item.id }}
                                            key={index}
                                            className="pre-filled-fields-2-several-item">
                                            <p>{item.name}</p>
                                            <p>{item.address_1}</p>
                                            <p>{item.address_2}</p>
                                            <p>{item.city}</p>
                                            <p>{item.zipcode}</p>
                                            <p
                                                style={{
                                                    backgroundColor: _defineStatus(item.status).color,
                                                    color: Constant.$blanc,
                                                }}>
                                                {_defineStatus(item.status).name}
                                            </p>
                                            <p
                                                style={{
                                                    backgroundColor: _defineCount(item.count_rvm).backgroundcolor,
                                                    color: _defineCount(item.count_rvm).color,
                                                    fontWeight: _defineCount(item.count_rvm).fontweight,
                                                    borderTopRightRadius: '0.6rem',
                                                    borderBottomRightRadius: '0.6rem',
                                                    textAlign: 'center',
                                                }}>
                                                {item.count_rvm}
                                            </p>
                                        </Link>
                                    );
                                })
                            ) : (
                                <div className="no-data-graph ">Aucun magasin associé à ce gestionnaire..</div>
                            )}
                        </div>
                    </section>

                    <section className="big-section">
                        <div className="top">
                            <h2 style={{ margin: '0' }}>Les RVM ({rvmownerCollectorData.length})</h2>
                            <div className="top-button-sort">
                                <div className="filter">
                                    <p className="sort">Trier par</p>
                                    <div>
                                        <p
                                            className={filterRvmSelected === 'asc' ? 'selected' : 'classic'}
                                            onClick={() => {
                                                sortDataAsc(rvmownerCollectorData, setRvmownerCollectorData, 'rvm');
                                            }}>
                                            A &gt; Z
                                        </p>
                                        <p
                                            className={filterRvmSelected === 'desc' ? 'selected' : 'classic'}
                                            onClick={() => {
                                                sortDataDesc(rvmownerCollectorData, setRvmownerCollectorData, 'rvm');
                                            }}>
                                            Z &gt; A
                                        </p>
                                    </div>
                                </div>
                                <Link
                                    to={'/servicesupplier/operation/collector/add'}
                                    state={{ depot_id: 0, rvmowner_id: rvmownerData.id, store: null }}
                                    className="button-actif">
                                    AJOUTER UNE RVM
                                </Link>{' '}
                            </div>
                        </div>

                        <div className="tab-item">
                            <div
                                className="pre-filled-fields-1-several-item"
                                style={{ fontWeight: '600' }}>
                                <p>Id</p>
                                <p>Magasin</p>
                                <p>Type</p>
                                <p>Capacity</p>
                                <p>Date Contact</p>
                                <p>Statut</p>
                            </div>

                            {rvmownerCollectorData.length ? (
                                rvmownerCollectorData.map((item, index) => {
                                    return (
                                        <Link
                                            to={'/servicesupplier/operation/collector/sheet'}
                                            state={{ id: item.id }}
                                            key={index}
                                            className="pre-filled-fields-2-several-item">
                                            <p>{item.id}</p>
                                            <p>{item.store_name}</p>
                                            <p>{_defineCollectorType(item.type)}</p>
                                            <p>{item.capacity}</p>
                                            <p>{transformDateFull(item.datep)}</p>
                                            <p
                                                style={{
                                                    backgroundColor: _defineStatus(item.status).color,
                                                    color: Constant.$blanc,
                                                    borderTopRightRadius: '0.6rem',
                                                    borderBottomRightRadius: '0.6rem',
                                                }}>
                                                {_defineStatus(item.status).name}
                                            </p>
                                        </Link>
                                    );
                                })
                            ) : (
                                <div className="no-data-graph ">Aucun collecteur associé à ce gestionnaire.</div>
                            )}
                        </div>
                    </section>
                </div>
            )}
        </main>
    );
}
