import React from 'react';
import { Line } from 'react-chartjs-2';

const MultiLineChart = ({ initialData, datasets }) => {
    // Define an array of colors
    const colors = [
        'rgba(255, 99, 132, 0.6)', // Rouge
        'rgba(54, 162, 235, 0.6)', // Bleu
        'rgba(255, 206, 86, 0.6)', // Jaune
        'rgba(75, 192, 192, 0.6)', // Vert
        'rgba(153, 102, 255, 0.6)', // Violet
        'rgba(255, 159, 64, 0.6)', // Orange
        'rgba(0, 255, 0, 0.6)', // Green
        'rgba(0, 0, 255, 0.6)', // Blue
        'rgba(255, 255, 0, 0.6)', // Yellow
        'rgba(255, 0, 255, 0.6)', // Magenta
        // Add more colors if needed
    ];
    const updatedDatasets = datasets.map((dataset, index) => ({
        ...dataset,
        borderColor: colors[index % colors.length],
        backgroundColor: colors[index % colors.length].replace('1)', '0.2)'), // Lighter background color
        borderWidth: 2, // Adjust the border width for better visibility
        fill: false, // Ensure the area under the line is not filled
        tension: 0, // Adjust the tension for smoother curves
        pointRadius: 1, // Retire les points de pointe de la ligne
    }));

    const chartData = {
        labels: initialData.map((fd) => fd.month).filter((value, index, self) => self.indexOf(value) === index),
        datasets: updatedDatasets,
    };

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,

        scales: {
            y: {
                beginAtZero: true,
                min: 0, // Ensure the Y-axis starts at 0
            },
        },
    };
    return (
        <div style={{ height: '90%' }}>
            <Line
                data={chartData}
                options={chartOptions}
            />
        </div>
    );
};

export default MultiLineChart;
