import { useState, useEffect, useRef } from 'react';
import { _defineStatus, useClickOutside, _defineCount } from '../../../../../Utils';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import SearchComponent from '../../../../../Components/searchComponent.jsx';
import * as Constant from '../../../../../Constants';
export default function Rvmowner() {
    const navigate = useNavigate();
    const [filterSelected, setFilterSelected] = useState('asc');

    const [AllRvmownersList, setAllRvmownersList] = useState([]);
    const [rvmownersNames, setRvmownersNames] = useState([]);
    const [showOptions, setShowOptions] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const dropdownRef = useRef(null);

    const handleClickOutside = () => {
        setShowOptions(false);
    };
    useClickOutside(dropdownRef, handleClickOutside);

    const fetchAllRvmowners = async () => {
        try {
            const { gearApi } = await import('../../../../../gearApi.js');
            const result = await gearApi.rvmowner.getRvmowners();

            setAllRvmownersList(result);
        } catch (error) {
            console.error('Erreur lors de la récupération des gestionnaires de RVM');
            console.error(error);
        }
    };

    useEffect(() => {
        const fetchRvmownersNames = async () => {
            try {
                if (searchTerm.length > 2) {
                    const { gearApi } = await import('../../../../../gearApi.js');
                    const result = await gearApi.rvmowner.getRvmowners();

                    if (result.length) {
                        const sortedData = result.sort((a, b) => a.name.localeCompare(b.name));
                        setAllRvmownersList(sortedData);
                        setRvmownersNames(sortedData);
                        setErrorMessage('');
                    } else {
                        setAllRvmownersList([]);
                        setRvmownersNames([]);
                        setErrorMessage('Aucun résultat');
                    }
                } else if (searchTerm.length === 0) {
                    fetchAllRvmowners();
                    setErrorMessage('');
                }
            } catch (error) {
                console.error('Erreur lors de la récupération des depots', error);
            }
        };

        fetchRvmownersNames();
    }, [searchTerm]);

    const handleInputChange = (e) => {
        setSearchTerm(e.target.value.toLowerCase());
        setShowOptions(true);
    };

    const handleSelectOption = (option) => {
        setSearchTerm(option.name);
        setShowOptions(false);
        console.log(option);
        navigate('/servicesupplier/operation/rvmowner/sheet', { state: { id: option.id } });
    };

    const sortDataAsc = () => {
        const sortedData = [...AllRvmownersList].sort((a, b) => a.name.localeCompare(b.name));
        setFilterSelected('asc');
        setAllRvmownersList(sortedData);
    };

    const sortDataDesc = () => {
        const sortedData = [...AllRvmownersList].sort((a, b) => b.name.localeCompare(a.name));
        setFilterSelected('desc');
        setAllRvmownersList(sortedData);
    };
    return (
        <main>
            <section className="big-section">
                <h2>Les gestionnaires de RVM ({AllRvmownersList.length})</h2>
                <div className="top">
                    <SearchComponent
                        dropdownRef={dropdownRef}
                        showOptions={showOptions}
                        searchTerm={searchTerm}
                        handleInputChange={handleInputChange}
                        productsNames={rvmownersNames}
                        handleSelectOption={handleSelectOption}
                        placeholder="Rechercher.."
                        searchKey="name"
                    />

                    <div className="top-button-sort">
                        <div className="filter">
                            <p className="sort">Trier par</p>
                            <div>
                                <p
                                    className={filterSelected === 'asc' ? 'selected' : 'classic'}
                                    onClick={sortDataAsc}>
                                    A &gt; Z
                                </p>
                                <p
                                    className={filterSelected === 'desc' ? 'selected' : 'classic'}
                                    onClick={sortDataDesc}>
                                    Z &gt; A
                                </p>
                            </div>
                        </div>
                        <Link
                            to={'/servicesupplier/operation/rvmowner/add'}
                            className="button-actif">
                            AJOUTER UN GESTIONNAIRE
                        </Link>
                    </div>
                </div>
                {errorMessage && (
                    <div style={{ width: '100%', padding: '0.5rem', textAlign: 'center', color: '#EB675B', marginBottom: '1.25rem' }}>
                        <p>{errorMessage}</p>
                    </div>
                )}
                <div className="tab-item">
                    <div
                        className="pre-filled-fields-1-several-item"
                        style={{ fontWeight: '600' }}>
                        <p>Nom</p>
                        <p>Ville</p>
                        <p>Code Postal</p>
                        <p>Statut</p>
                        <p>Nb Magasin</p>
                    </div>

                    {AllRvmownersList.length ? (
                        AllRvmownersList.map((item, index) => {
                            return (
                                <Link
                                    to={'/servicesupplier/operation/rvmowner/sheet'}
                                    state={{ id: item.id }}
                                    key={index}
                                    className="pre-filled-fields-2-several-item">
                                    <p>{item.name}</p>
                                    <p>{item.city}</p>
                                    <p>{item.zipcode}</p>
                                    <p
                                        style={{
                                            backgroundColor: _defineStatus(item.status).color,
                                            color: Constant.$blanc,
                                        }}>
                                        {_defineStatus(item.status).name}
                                    </p>
                                    <p
                                        style={{
                                            backgroundColor: _defineCount(item.count_store).backgroundcolor,
                                            color: _defineCount(item.count_store).color,
                                            fontWeight: _defineCount(item.count_store).fontweight,
                                            borderRadius: '0.6rem',
                                            textAlign: 'center',
                                        }}>
                                        {item.count_store}
                                    </p>
                                </Link>
                            );
                        })
                    ) : (
                        <div className="no-data-graph ">Aucun gestionnaire de RVM enregistré.</div>
                    )}
                </div>
            </section>
        </main>
    );
}
