import { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { endOfDay } from 'date-fns';
import { useLocation } from 'react-router-dom';
import { getAllSalesDetailsByDatesAndEntity } from '../../../API/SalesApi';
import { getAllSalesDetailsByDatesAndEntityCheck } from '../../../API/CheckApi/CheckSalesApi';
import { useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';

export default function IndustrialReportingDetails() {
    const navigate = useNavigate();
    const location = useLocation();
    const { startDate, endDate, date, entity } = location.state || {};
    const [declaration, setDeclaration] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            const formattedStartDate = format(startDate, 'yyyy-MM-dd');
            const formattedEndDate = format(endDate, 'yyyy-MM-dd');
            try {
                // TOUTES LES MISE EN MARCHE DE L'ENTITE
                const allSalesDetailsRaw = await getAllSalesDetailsByDatesAndEntity(endOfDay(formattedStartDate), endOfDay(formattedEndDate), entity);
                const allSalesDetailsCheck = getAllSalesDetailsByDatesAndEntityCheck(allSalesDetailsRaw);
                if (allSalesDetailsRaw.length && allSalesDetailsCheck !== true) {
                    console.error(allSalesDetailsCheck);
                    Sentry.captureException(allSalesDetailsCheck);
                    navigate('/error');
                    return;
                }
                for (let i = 0; i < allSalesDetailsRaw.length; i++) {
                    allSalesDetailsRaw[i].totalPrice = allSalesDetailsRaw[i].qty * allSalesDetailsRaw[i].entityIndustrialProduct.container.price;
                }

                console.log('allSalesDetailsRaw', allSalesDetailsRaw);

                setDeclaration(allSalesDetailsRaw);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [
        startDate,
        endDate,
        navigate,
        entity,
    ]);

    return (
        <main>
            {declaration.length ? (
                <section
                    className="big-section"
                    style={{ width: '95%' }}>
                    <h2>
                        Reporting {declaration[0].sale.entity.name} : {format(date, 'dd-MM-yyyy')}{' '}
                    </h2>

                    <h2>Total : {declaration.reduce((acc, item) => acc + item.totalPrice / 100, 0)} €</h2>
                    <div className="tab-item">
                        <div
                            className="pre-filled-fields-1-several-item"
                            style={{ fontWeight: '600' }}>
                            <p>Date déclaration</p>
                            <p>EAN</p>
                            <p>Quantités</p>
                            <p>SKU</p>
                            <p>Montant consigne (€)</p>
                            <p>Sous-total (€)</p>
                        </div>

                        {declaration.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className="pre-filled-fields-2-several-item">
                                    <p>{format(item.createdAt, 'yyyy-MM-dd')}</p>
                                    <p>{item.entityIndustrialProduct.ean}</p>
                                    <p>{item.qty}</p>
                                    <p>{item.entityIndustrialProduct.container.sku}</p>
                                    <p>{(item.entityIndustrialProduct.container.price / 100).toFixed(2)}</p>
                                    <p>{((item.entityIndustrialProduct.container.price * item.qty) / 100).toFixed(2)}</p>
                                </div>
                            );
                        })}
                    </div>
                </section>
            ) : (
                <div className="no-data-graph ">Aucune donnée.</div>
            )}
        </main>
    );
}
