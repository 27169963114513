import React, { useState } from 'react';
import { Bar, Pie, Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement);

const ChartApp = () => {
    const [chartTitle, setChartTitle] = useState('Répartition des Retours');
    const [labels, setLabels] = useState([]);
    const [data, setData] = useState([]);
    const [chartType, setChartType] = useState('bar');
    const [inputs, setInputs] = useState([{ label: '', value: '' }]);
    const [chartWidth, setChartWidth] = useState(50); // largeur par défaut en pourcentage
    const [chartHeight, setChartHeight] = useState(30); // hauteur par défaut en rem

    const handleAddInput = () => {
        setInputs([...inputs, { label: '', value: '' }]);
    };

    const handleInputChange = (index, e) => {
        const newInputs = [...inputs];
        newInputs[index][e.target.name] = e.target.value;
        setInputs(newInputs);
    };

    const handleSaveData = () => {
        const newLabels = inputs.map((input) => input.label);
        let newData = inputs.map((input) => parseFloat(input.value));

        const total = newData.reduce((sum, value) => sum + value, 0);
        newData = newData.map((value) => (value / total) * 100);

        setLabels(newLabels);
        setData(newData);
    };

    const handleTitleChange = (e) => {
        setChartTitle(e.target.value);
    };

    const handleChartChange = (e) => {
        setChartType(e.target.value);
    };

    const handleWidthChange = (e) => {
        setChartWidth(e.target.value);
    };

    const handleHeightChange = (e) => {
        setChartHeight(e.target.value);
    };

    const colors = [
        'rgba(255, 99, 132, 0.6)', // Rouge
        'rgba(54, 162, 235, 0.6)', // Bleu
        'rgba(255, 206, 86, 0.6)', // Jaune
        'rgba(75, 192, 192, 0.6)', // Vert
        'rgba(153, 102, 255, 0.6)', // Violet
        'rgba(255, 159, 64, 0.6)', // Orange
        'rgba(0, 255, 0, 0.6)', // Green
        'rgba(0, 0, 255, 0.6)', // Blue
        'rgba(255, 255, 0, 0.6)', // Yellow
        'rgba(255, 0, 255, 0.6)', // Magenta
    ];
    const chartData = {
        labels: labels,
        datasets: [
            {
                label: chartTitle,
                data: data,
                backgroundColor: colors, // Utilisation des couleurs définies
                borderColor: colors,
                hoverBackgroundColor: colors.map((color) => color.replace('0.6', '0.8')), // Decrease opacity to 0.8 on hover
            },
        ],
    };

    const chartOptions = {
        plugins: {
            datalabels: {
                color: '#000',
                formatter: (value, context) => {
                    const total = context.dataset.data.reduce((acc, val) => acc + val, 0);
                    const percentage = ((value / total) * 100).toFixed(1) + '%';
                    return percentage;
                },
                align: 'center',
            },
            legend: {
                position: 'right', // Place la légende à droite pour Pie et Doughnut
            },
        },
    };

    return (
        <main>
            <div
                className="data-container"
                style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <div style={{ width: '20vw' }}></div>
                <div style={{ padding: '20px', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <h1>Crée ton graphique !</h1>
                    <p style={{ marginTop: '1rem', marginBottom: '0.5rem', fontWeight: 'bolder' }}>Type de graphique</p>
                    <div style={{ marginTop: 0, marginBottom: 0 }}>
                        <select
                            onChange={handleChartChange}
                            style={{ marginTop: 0, marginBottom: 0 }}>
                            <option value="bar">Bar</option>
                            <option value="pie">Pie</option>
                            <option value="doughnut">Doughnut</option>
                        </select>
                    </div>
                    <p style={{ marginTop: '1rem', marginBottom: '0.5rem', fontWeight: 'bolder' }}>Titre du graphique</p>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                            style={{ marginBottom: 0, width: '20rem' }}
                            name="title"
                            placeholder="Titre du graphique"
                            value={chartTitle}
                            onChange={handleTitleChange}
                        />
                    </div>

                    <p style={{ marginTop: '1rem', marginBottom: '0.5rem', fontWeight: 'bolder' }}>Données</p>
                    {inputs.map((input, index) => (
                        <div
                            key={index}
                            style={{ marginBottom: '10px' }}>
                            <input
                                name="label"
                                placeholder={`Label ${index + 1}`}
                                value={input.label}
                                onChange={(e) => handleInputChange(index, e)}
                                style={{ marginRight: '10px' }}
                            />
                            <input
                                name="value"
                                type="number"
                                placeholder="Quantité"
                                value={input.value}
                                onChange={(e) => handleInputChange(index, e)}
                            />
                        </div>
                    ))}
                    <button
                        onClick={handleAddInput}
                        style={{ marginBottom: '20px' }}>
                        +
                    </button>
                    <button
                        onClick={handleSaveData}
                        style={{ marginBottom: '20px', marginLeft: '10px' }}>
                        Enregistrer les Données
                    </button>

                    <p style={{ marginTop: '1rem', marginBottom: '0.5rem', fontWeight: 'bolder' }}>Ajuster la taille du graphique</p>
                    <div style={{ marginBottom: '20px' }}>
                        <label>Largeur (%): </label>
                        <input
                            type="range"
                            min="30"
                            max="100"
                            value={chartWidth}
                            onChange={handleWidthChange}
                            style={{ marginLeft: '10px', marginRight: '10px' }}
                        />
                        <span>{chartWidth}%</span>
                    </div>
                    <div style={{ marginBottom: '20px' }}>
                        <label>Hauteur (rem): </label>
                        <input
                            type="range"
                            min="10"
                            max="50"
                            value={chartHeight}
                            onChange={handleHeightChange}
                            style={{ marginLeft: '10px', marginRight: '10px' }}
                        />
                        <span>{chartHeight}rem</span>
                    </div>

                    <section
                        className="section-graph"
                        style={{ height: `${chartHeight}rem`, width: `${chartWidth}%`, marginTop: '5rem', marginBottom: '2rem' }}>
                        <div className="title">{chartTitle}</div>
                        <div style={{ height: `calc(${chartHeight}rem - 2rem)` }}>
                            {chartType === 'bar' && <Bar data={chartData} />}
                            {chartType === 'pie' && (
                                <Pie
                                    data={chartData}
                                    options={chartOptions}
                                    plugins={[ChartDataLabels]}
                                />
                            )}
                            {chartType === 'doughnut' && (
                                <Doughnut
                                    data={chartData}
                                    options={chartOptions}
                                    plugins={[ChartDataLabels]}
                                />
                            )}
                        </div>
                    </section>
                </div>
            </div>
        </main>
    );
};

export default ChartApp;
