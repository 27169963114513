import { Routes, Route, Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import * as Constants from '../../Constants';

import Documentation from '../../containers/Documentation';
import Support from '../../containers/account/Support';
import UpdatePassword from '../../containers/account/UpdatePassword';
import PreHome from '../../containers/PreHome';
import ErrorPage from '../../Components/ErrorPage';

// DATA
import DistribDataHome from '../../containers/data/distributor_interface/DistribDataHome';
import DistribDataReuseDashboard from '../../containers/data/distributor_interface/reemploi/DistribDataReuseDashboard';
import DistribDataReuseCollecteurs from '../../containers/data/distributor_interface/reemploi/DistribDataReuseCollecteurs';
import DistribDataReuseContainer from '../../containers/data/distributor_interface/reemploi/DistribDataReuseContainer';
import DistribDataReuseReturns from '../../containers/data/distributor_interface/reemploi/DistribDataReuseReturns';

// Profil
import ProfilPersonalInfo from '../../containers/account/ProfilPersonalInfo';
import ProfilCompanyInfo from '../../containers/account/ProfilCompanyInfo';
import ProfilAccess from '../../containers/account/ProfilAccess';
import ProfilRoleManagement from '../../containers/account/ProfilRoleManagement';
import CreateUser from '../../containers/account/CreateUser';
import UserSheet from '../../containers/account/UserSheet';
import Unknown from '../../containers/account/Unknown';

export default function DistributorInterface() {
    const role = Cookies.get('role');
    const isAdmin = role === Constants.ENTITY_ROLE_ADMIN;
    return (
        <Routes>
            <Route
                path="/error"
                element={<ErrorPage />}
            />
            <Route
                path="/prehome"
                element={<PreHome />}
            />
            <Route
                path="/support"
                element={<Support />}
            />
            <Route
                path="/documentation"
                element={<Documentation />}
            />
            <Route
                path="/updatepassword"
                element={<UpdatePassword />}
            />

            <Route
                path="/distributor/data/home"
                element={<DistribDataHome />}
            />
            <Route
                path="/distributor/data/reuse/dashboard"
                element={<DistribDataReuseDashboard />}
            />
            <Route
                path="/distributor/data/reuse/collectors"
                element={<DistribDataReuseCollecteurs />}
            />
            <Route
                path="/distributor/data/reuse/container"
                element={<DistribDataReuseContainer />}
            />

            <Route
                path="/distributor/data/reuse/returns"
                element={<DistribDataReuseReturns />}
            />

            <Route
                path="/distributor/myprofil/personal_info"
                element={<ProfilPersonalInfo />}
            />
            <Route
                path="/distributor/myprofil/company_info"
                element={<ProfilCompanyInfo />}
            />
            <Route
                path="/distributor/myprofil/access"
                element={<ProfilAccess />}
            />
            {isAdmin ? (
                <Route
                    path="/distributor/myprofil/rolemanagement"
                    element={<ProfilRoleManagement />}
                />
            ) : null}
            {isAdmin ? (
                <Route
                    path="/distributor/myprofil/user/add"
                    element={<CreateUser />}
                />
            ) : null}
            {isAdmin ? (
                <Route
                    path="/distributor/myprofil/user/sheet"
                    element={<UserSheet />}
                />
            ) : null}
            <Route
                path="/distributor/operation/unknown"
                element={<Unknown />}
            />
            <Route
                path="*"
                element={
                    <Navigate
                        replace
                        to="/distributor/operation/unknown"
                    />
                }
            />
        </Routes>
    );
}
