import { useState, useEffect } from 'react';
import { getIndustrialById, updateIndustrial } from '../../../../../API/IndustrialApi';
import { _defineStatus } from '../../../../../Utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { checkEntityFormErrors, checkIsInputEmpty } from '../../../../../Utils';
import AddressSearch from '../../../../../Components/AddressSearch';

export default function UpdateIndustrial() {
    const [errorMessage, setErrorMessage] = useState([]);
    const [errorEmpty, setErrorEmpty] = useState('');
    const location = useLocation();
    const navigate = useNavigate();
    const { id } = location.state || {};
    const [industrialData, setIndustrialData] = useState([]);

    useEffect(() => {
        const fetchIndustrialData = async () => {
            try {
                const indusData = await getIndustrialById(id);
                console.log(indusData);
                setIndustrialData(indusData[0]);
            } catch (error) {
                console.error("Erreur lors de la récupération des infos de l'industriel", error);
            }
        };
        fetchIndustrialData();
    }, [id]);

    const [formErrors, setFormErrors] = useState({
        company_name: false,
        name: false,
        address_1: false,
        address_2: false,
        city: false,
        zipcode: false,
        siret: false,
        tva_intra: false,
        contact_name: false,
        contact_phone: false,
        contact_email: false,
        website: false,
    });

    const requiredFields = [
        'company_name',
        'name',
        'address_1',
        'city',
        'zipcode',
        'siret',
        'tva_intra',
        'contact_name',
        'contact_phone',
        'contact_email',
    ];

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setIndustrialData({
            ...industrialData,
            [name]: value,
        });
    };

    const handleSaveChanges = async (e) => {
        e.preventDefault();
        setErrorMessage([]);
        setErrorEmpty('');
        const inputEmpty = checkIsInputEmpty(requiredFields, industrialData, setFormErrors, setErrorEmpty);
        if (!inputEmpty) {
            const checkFormat = checkEntityFormErrors(industrialData, formErrors, setFormErrors, setErrorMessage);
            if (checkFormat) {
                return;
            } else {
                const updateIndustrialData = await updateIndustrial(id, industrialData);
                if (updateIndustrialData) {
                    alert('Modifications enregistrées avec succès');
                    navigate(-1);
                } else {
                    alert('une erreur est survenue lors de la modification de la marque');
                }
            }
        } else {
            console.log('Il reste des champs obligatoires vides');
        }
    };
    return (
        <main>
            <section className="big-section">
                <h2>Modifier une marque</h2>

                {industrialData.id ? (
                    <div className="form-container">
                        <section>
                            <div>
                                <label>Nom industriel:</label>
                                <input
                                    className={formErrors.company_name ? 'error' : ''}
                                    type="text"
                                    name="company_name"
                                    value={industrialData.company_name || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <label>Nom marque:</label>
                                <input
                                    className={formErrors.name ? 'error' : ''}
                                    type="text"
                                    name="name"
                                    value={industrialData.name || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <label>Siret:</label>
                                <input
                                    className={formErrors.siret ? 'error' : ''}
                                    type="text"
                                    name="siret"
                                    value={industrialData.siret || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <label>TVA intracommunautaire::</label>
                                <input
                                    className={formErrors.tva_intra ? 'error' : ''}
                                    type="text"
                                    name="tva_intra"
                                    value={industrialData.tva_intra || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </section>
                        <section>
                            <AddressSearch
                                formData={industrialData}
                                setFormData={setIndustrialData}
                                formErrors={formErrors}
                                setFormErrors={setFormErrors}
                                handleChange={handleInputChange}
                            />
                        </section>

                        <section>
                            <div>
                                <label>Nom du contact:</label>
                                <input
                                    className={formErrors.contact_name ? 'error' : ''}
                                    type="email"
                                    name="contact_name"
                                    value={industrialData.contact_name || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <label>Téléphone du contact:</label>
                                <input
                                    className={formErrors.contact_phone ? 'error' : ''}
                                    type="email"
                                    name="contact_phone"
                                    value={industrialData.contact_phone || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <label>Email du contact:</label>
                                <input
                                    className={formErrors.contact_email ? 'error' : ''}
                                    type="email"
                                    name="contact_email"
                                    value={industrialData.contact_email || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <label>Site web:</label>
                                <input
                                    className={formErrors.website ? 'error' : ''}
                                    value={industrialData.website || ''}
                                    type="url"
                                    name="website"
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div>
                                <label>Statut:</label>
                                <select
                                    style={{ backgroundColor: _defineStatus(industrialData.status).color, color: 'white' }}
                                    name="status"
                                    value={industrialData.status}
                                    onChange={handleInputChange}>
                                    <option value={0}>Actif</option>
                                    <option value={100}>Inactif</option>
                                </select>
                            </div>
                        </section>
                    </div>
                ) : null}
                <p className="error-form">{errorEmpty && errorEmpty}</p>
                {errorMessage.length
                    ? errorMessage.map((item, index) => {
                          return (
                              <div
                                  className="error-form"
                                  key={index}>
                                  {item}
                              </div>
                          );
                      })
                    : null}
                <div className="button-confirm">
                    <button
                        className="button-actif"
                        onClick={handleSaveChanges}>
                        Enregistrer les modifications
                    </button>
                </div>
            </section>
        </main>
    );
}
