import { useState, useEffect } from 'react';
import { getStoreById, updateStore } from '../../../../API/StoreApi';
import { _defineStatus } from '../../../../Utils';
import { useLocation, useNavigate } from 'react-router-dom';

export default function UpdateStore() {
    const location = useLocation();
    const { id } = location.state || {};
    const [storeData, setStoreData] = useState([]);
    const [allowModif, setAllowModif] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchDepotData = async () => {
            try {
                const storeRawData = await getStoreById(id);
                console.log(storeRawData);
                setStoreData(storeRawData[0]);
            } catch (error) {
                console.error('Erreur lors de la récupération des infos du magasin', error);
            }
        };
        fetchDepotData();
    }, [id]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setStoreData({
            ...storeData,
            [name]: value,
        });
    };

    const handleSaveChanges = async () => {
        try {
            if (!id) {
                console.error('ID du magasin non valide');
                return;
            }
            await updateStore(id, storeData);
            console.log('Modifications enregistrées avec succès');
            alert('Modifications enregistrées avec succès');
            navigate(-1);
        } catch (error) {
            console.error("Erreur lors de l'enregistrement des modifications", error);
        }
        setAllowModif(false);
    };
    return (
        <main>
            <section className="big-section">
                <h2>Modifier un magasin</h2>
                {storeData.id ? (
                    <div className="form-container">
                        <section>
                            <div>
                                <label>Société Mère:</label>
                                <input
                                    disabled={!allowModif ? true : false}
                                    type="text"
                                    name="company_name"
                                    value={storeData.company_name || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <label>Nom du magasin:</label>
                                <input
                                    disabled={!allowModif ? true : false}
                                    type="text"
                                    name="name"
                                    value={storeData.name || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <label>Centre de collecte:</label>
                                <input
                                    disabled={!allowModif ? true : false}
                                    type="email"
                                    name="contact_email"
                                    value={storeData.depot_id || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </section>
                        <section>
                            <div>
                                <label>Adresse 1:</label>
                                <input
                                    disabled={!allowModif ? true : false}
                                    type="text"
                                    name="address_1"
                                    value={storeData.address_1 || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <label>Adresse 2:</label>
                                <input
                                    disabled={!allowModif ? true : false}
                                    type="text"
                                    name="address_2"
                                    value={storeData.address_2 || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <label>Ville:</label>
                                <input
                                    disabled={!allowModif ? true : false}
                                    type="text"
                                    name="city"
                                    value={storeData.city || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <label>Code postal:</label>
                                <input
                                    disabled={!allowModif ? true : false}
                                    type="text"
                                    name="zipcode"
                                    value={storeData.zipcode || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </section>

                        <section>
                            <div>
                                <label>Nom du contact:</label>
                                <input
                                    disabled={!allowModif ? true : false}
                                    type="email"
                                    name="contact_name"
                                    value={storeData.contact_name || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <label>Téléphone du contact:</label>
                                <input
                                    disabled={!allowModif ? true : false}
                                    type="email"
                                    name="contact_phone"
                                    value={storeData.contact_phone || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <label>Email du contact:</label>
                                <input
                                    disabled={!allowModif ? true : false}
                                    type="email"
                                    name="contact_email"
                                    value={storeData.contact_email || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <label>Statut:</label>
                                <select
                                    style={{ backgroundColor: _defineStatus(storeData.status).color, color: 'white' }}
                                    disabled={!allowModif ? true : false}
                                    name="status"
                                    value={storeData.status}
                                    onChange={handleInputChange}>
                                    <option value={0}>Actif</option>
                                    <option value={100}>Inactif</option>
                                </select>
                            </div>
                        </section>
                    </div>
                ) : null}

                <div className="button-confirm">
                    {!allowModif ? (
                        <button
                            className="button-actif"
                            onClick={() => {
                                setAllowModif(!allowModif);
                            }}>
                            Modifier les informations
                        </button>
                    ) : (
                        <button
                            className="button-actif"
                            onClick={handleSaveChanges}>
                            Enregistrer les modifications
                        </button>
                    )}
                </div>
            </section>
        </main>
    );
}
