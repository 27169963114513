// SALES API

export const getAllSalesDetailsByDatesCheck = (data) => {
    const name = 'getAllSalesDetailsByDates : ';
    for (let i = 0; i < data.length; i++) {
        if (!data[i].id) {
            return name + 'No id found';
        } else if (!data[i].sale.id) {
            return name + 'No sales id found';
        } else if (!data[i].sale.entity.id) {
            return name + 'No entity id found';
        } else if (!data[i].entityIndustrialProduct.id) {
            return name + 'No entity industrial product id found';
        } else if (!data[i].entityIndustrialProduct.container.id) {
            return name + 'No container id found';
        } else {
            return true;
        }
    }
};

// SALES API

export const getAllSalesDetailsByDatesAndEntityCheck = (data) => {
    const name = 'getAllSalesDetailsByDates : ';
    for (let i = 0; i < data.length; i++) {
        if (!data[i].id) {
            return name + 'No id found';
        } else if (!data[i].sale.id) {
            return name + 'No sales id found';
        } else if (!data[i].sale.entity.id) {
            return name + 'No entity id found';
        } else if (!data[i].entityIndustrialProduct.id) {
            return name + 'No entity industrial product id found';
        } else if (!data[i].entityIndustrialProduct.container.id) {
            return name + 'No container id found';
        } else {
            return true;
        }
    }
};
