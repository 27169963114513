import { useState, useEffect } from 'react';
import { getIndustrialById } from '../../../../API/IndustrialApi';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { getOrderDetailsByEntityId } from '../../../../API/OrderApi';
import { CaretLeft, CaretRight } from '@phosphor-icons/react';
import { getForecastByEntity } from '../../../../API/AnnualForecastApi';
import * as Constant from '../../../../Constants';
import { reduceByMonthAndKey, addMonthToTab } from '../../../../UtilsData';
import { endOfDay } from 'date-fns';
import MultiLineChart from '../../../../Components/MultiLineChart';
import { getOrderDetailsByEntityIdCheck } from '../../../../API/CheckApi/CheckOrderApi';
import { getForecastByEntityCheck } from '../../../../API/CheckApi/CheckAnnualForecastApi';
import * as Sentry from '@sentry/react';

export default function IndustrialNeedSheet() {
    const [industrialData, setIndustrialData] = useState([]);
    const [forecast, setForecast] = useState([]);
    const [selectAnnuelForecast, setSelectedAnnualForecast] = useState(false);
    const [formattedGraph, setFormattedGraph] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [data, setData] = useState([]);

    const location = useLocation();
    const navigate = useNavigate();

    const { id } = location.state || {};

    useEffect(() => {
        const fetchData = async () => {
            const statOfyear = new Date(new Date().getFullYear(), 0, 1);
            const endOfyear = new Date(new Date().getFullYear(), 11, 31);
            try {
                const [indusData, orderDetailsRawData] = await Promise.all([
                    getIndustrialById(id),
                    getOrderDetailsByEntityId(id),
                ]);
                const orderDetailsRawDataCheck = getOrderDetailsByEntityIdCheck(orderDetailsRawData);
                if (orderDetailsRawData.length && orderDetailsRawDataCheck !== true) {
                    console.error(orderDetailsRawDataCheck);
                    Sentry.captureException(orderDetailsRawDataCheck);
                    navigate('/error');
                    return;
                }
                setIndustrialData(indusData[0]);
                for (let i = 0; i < orderDetailsRawData.length; i++) {
                    orderDetailsRawData[i].qtyTotal = orderDetailsRawData[i].qty * orderDetailsRawData[i].container.palet_nbcontainer;
                }

                const dataGroupedByYearRaw = orderDetailsRawData.reduce((acc, item) => {
                    const year = new Date(item.dated).getFullYear().toString();
                    if (!acc[year]) {
                        acc[year] = [];
                    }
                    acc[year].push(item);
                    return acc;
                }, {});
                const formattedData = reduceByMonthAndKey(orderDetailsRawData, 'dated', 'sku', 'qtyTotal', 'sku');
                const formattedChart = addMonthToTab(formattedData, statOfyear.toISOString(), endOfDay(endOfyear.toISOString()), 'sku', 'month', 'quantity');
                setFormattedGraph(formattedChart);
                const dataGroupedByYear = Object.keys(dataGroupedByYearRaw).map((year) => ({
                    year,
                    orders: dataGroupedByYearRaw[year],
                }));

                setData(dataGroupedByYear);

                const forecastRaw = await getForecastByEntity(id);
                const forecastRawCheck = getForecastByEntityCheck(forecastRaw);
                if (forecastRaw.length && forecastRawCheck !== true) {
                    console.error(forecastRawCheck);
                    Sentry.captureException(forecastRawCheck);
                    navigate('/error');
                    return;
                }
                setForecast(forecastRaw);
            } catch (error) {
                console.error("Erreur lors de la récupération des infos de l'industriel", error);
            }
        };

        fetchData();
    }, [id, navigate]);

    const getMonthsInYear = (startDate) => {
        const date = new Date(startDate);
        const year = date.getFullYear();

        const monthsInYear = [];
        let currentDate = new Date();
        currentDate.setFullYear(year);
        currentDate.setMonth(0);

        for (let i = 0; i < 12; i++) {
            const monthName = currentDate.toLocaleString('default', { month: 'long' });
            const currentYear = currentDate.getFullYear();
            monthsInYear.push({ month: monthName, year: currentYear });
            currentDate.setMonth(currentDate.getMonth() + 1);
        }

        return monthsInYear;
    };
    const handleNextIndex = () => {
        setCurrentIndex((prevIndex) => prevIndex + 1);
    };

    const handlePrevIndex = () => {
        setCurrentIndex((prevIndex) => prevIndex - 1);
    };

    const getSku = (tab) => {
        const skusTest = Array.from(new Set(tab.map((item) => item.sku)));
        return skusTest;
    };

    const indusneedsGraph = [...new Set(formattedGraph.map((fd) => fd.sku))];
    const indusneedsGraphQuantites = indusneedsGraph.map((sku) => {
        const data = formattedGraph.filter((fd) => fd.sku === sku).map((fd) => fd.quantity);
        return {
            label: sku,
            data,
        };
    });

    return (
        <main>
            <div className="header-page">
                <div
                    className="back"
                    onClick={() => {
                        navigate(-1);
                    }}>
                    <div className="icon">
                        <CaretLeft
                            size={20}
                            color={Constant.$blanc}
                        />
                    </div>
                    <p>Liste marques</p>
                </div>
                <div className="header-page-title">
                    <div>
                        <h2>{industrialData.name}</h2>
                    </div>
                </div>
                <div className="space"></div>
            </div>
            {industrialData.id && (
                <main>
                    <section className="long-graph-section">
                        <div className="long-graph-section-container">
                            <div className="graph-container">
                                {formattedGraph.length ? <p>Evolution besoin contenants</p> : null}
                                <div style={{ height: '90%' }}>
                                    {formattedGraph.length ? (
                                        <MultiLineChart
                                            initialData={formattedGraph}
                                            datasets={indusneedsGraphQuantites}
                                        />
                                    ) : (
                                        <div className="no-data-graph ">Aucune donnée.</div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="consolidated-view">
                        <div
                            className="graph-needs"
                            style={{ marginBottom: '0rem', marginTop: '2rem' }}>
                            <div style={{ flex: 1 }}></div>
                            <div
                                className="filter-dashboard"
                                style={{ flex: 2 }}>
                                <div
                                    className={selectAnnuelForecast ? 'selected' : ''}
                                    onClick={() => {
                                        setCurrentIndex(0);
                                        setSelectedAnnualForecast(true);
                                    }}>
                                    Prévisions annuelles
                                </div>
                                <div
                                    className={!selectAnnuelForecast ? 'selected' : ''}
                                    onClick={() => {
                                        setCurrentIndex(0);
                                        setSelectedAnnualForecast(false);
                                    }}>
                                    Besoins mensuels
                                </div>
                            </div>
                            <div style={{ flex: 1 }}></div>
                        </div>
                        {!selectAnnuelForecast ? (
                            <section
                                className="big-section"
                                style={{ marginTop: '1rem' }}>
                                <div style={{ marginTop: '10px' }}>
                                    {data.length ? (
                                        <div className="section-title-with-legende">
                                            <h2 style={{ textAlign: 'start' }}>Besoins mensuels </h2>
                                            <div className="legende">
                                                <div style={{ backgroundColor: Constant.$grisclair }}></div>
                                                <p>en attente</p>
                                            </div>
                                            <div className="legende">
                                                <div style={{ backgroundColor: 'orange' }}></div>
                                                <p>attribué au verrier</p>
                                            </div>
                                            <div className="legende">
                                                <div style={{ backgroundColor: Constant.$vertBocoloco }}></div>
                                                <p>attribué au laveur</p>
                                            </div>
                                            <div style={{ display: 'flex', gap: '1rem', width: '25%', justifyContent: 'end' }}>
                                                <button
                                                    style={{ opacity: currentIndex === 0 ? 0.5 : 1 }}
                                                    disabled={currentIndex === 0 && true}
                                                    className="index-button"
                                                    onClick={handlePrevIndex}>
                                                    <CaretLeft
                                                        size={15}
                                                        color={Constant.$blanc}
                                                    />
                                                </button>

                                                <button
                                                    style={{ opacity: currentIndex === data.length - 1 ? 0.5 : 1 }}
                                                    disabled={currentIndex === data.length - 1 && true}
                                                    className="index-button"
                                                    onClick={handleNextIndex}>
                                                    <CaretRight
                                                        size={15}
                                                        color={Constant.$blanc}
                                                    />
                                                </button>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                                {data.length ? (
                                    <div className="tab-container">
                                        <table style={{ width: '100%' }}>
                                            <thead style={{ backgroundColor: Constant.$vertTresClair }}>
                                                <tr>
                                                    <th style={{ width: '16%', backgroundColor: Constant.$blanc }}></th>
                                                    {data.length
                                                        ? getMonthsInYear(data[currentIndex].orders[0].dated).map((item, index) => (
                                                              <th
                                                                  style={{
                                                                      color: Constant.$vertBocoloco,
                                                                      fontWeight: 'bolder',
                                                                      fontSize: '0.75rem',
                                                                      width: '7%',
                                                                      padding: '0.5rem',
                                                                      borderTopLeftRadius: index === 0 && '0.62rem',
                                                                      borderTopRightRadius: index === 11 && '0.62rem',
                                                                      borderRight: index !== 11 ? 'solid 1px' : '0px',
                                                                      borderRightColor: index !== 11 && Constant.$grisclair,
                                                                  }}
                                                                  key={index}>
                                                                  {item.month} <br /> {item.year}
                                                              </th>
                                                          ))
                                                        : null}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.length
                                                    ? getSku(data[currentIndex].orders).map((sku, skuIndex) => (
                                                          <tr
                                                              key={skuIndex}
                                                              style={{
                                                                  borderBottom: skuIndex !== data[currentIndex].orders.length - 2 ? 'solid 1px' : '0px',
                                                                  borderBottomColor: skuIndex !== data[currentIndex].orders.length - 2 && Constant.$grisclair,
                                                              }}>
                                                              <td
                                                                  style={{
                                                                      borderRight: 'solid 1px',
                                                                      borderRightColor: Constant.$vertTresClair,
                                                                      color: Constant.$vertBocoloco,
                                                                      fontWeight: 'bolder',
                                                                      fontSize: '0.75rem',
                                                                      padding: '0.5rem',
                                                                      width: '16%',
                                                                      backgroundColor: Constant.$vertTresClair,
                                                                      borderTopLeftRadius: skuIndex === 0 ? '0.62rem' : '0px',
                                                                      borderBottomLeftRadius:
                                                                          skuIndex === data[currentIndex].orders.length - 2 ? '0.62rem' : '0px',
                                                                  }}>
                                                                  {sku}
                                                              </td>
                                                              {getMonthsInYear(data[currentIndex].orders[0].dated).map((elem, monthIndex) => {
                                                                  const order = data[currentIndex].orders.find(
                                                                      (item) => item.sku === sku && new Date(item.dated).getMonth() + 1 === monthIndex + 1,
                                                                  );
                                                                  const quantity = order?.qtyTotal || 0;

                                                                  return (
                                                                      <td
                                                                          style={{
                                                                              color: Constant.$grisfonce,
                                                                              width: '7%',
                                                                              textAlign: 'center',
                                                                              padding: '0.5rem',
                                                                              fontSize: '0.75rem',
                                                                              borderLeft: 'solid 1px',
                                                                              borderLeftColor: Constant.$grisclair,
                                                                              backgroundColor:
                                                                                  order && order.dateasupplier && !order.dateawasher
                                                                                      ? 'orange'
                                                                                      : order && order.dateawasher
                                                                                        ? Constant.$vertBocoloco
                                                                                        : order && order.dated
                                                                                          ? Constant.$grisclair
                                                                                          : '',
                                                                          }}
                                                                          key={monthIndex}>
                                                                          {order && order.qty ? (
                                                                              <Link
                                                                                  to={'/servicesupplier/operation/indusneeds/assign'}
                                                                                  state={{ item: order, company_name: industrialData.company_name }}>
                                                                                  {quantity}
                                                                              </Link>
                                                                          ) : (
                                                                              <p>{quantity}</p>
                                                                          )}
                                                                      </td>
                                                                  );
                                                              })}
                                                          </tr>
                                                      ))
                                                    : null}
                                            </tbody>
                                        </table>
                                    </div>
                                ) : (
                                    <div className="no-data-graph">Aucune donnée.</div>
                                )}
                            </section>
                        ) : (
                            <section
                                style={{ marginTop: '1rem' }}
                                className="big-section">
                                <div style={{ marginTop: '10px' }}>
                                    {forecast.length ? (
                                        <div className="section-title-with-legende">
                                            <h2 style={{ textAlign: 'start' }}>Prévisionnel annuel </h2>
                                            <div style={{ display: 'flex', gap: '1rem', width: '75%', justifyContent: 'end' }}>
                                                <button
                                                    style={{ opacity: currentIndex === 0 ? 0.5 : 1 }}
                                                    disabled={currentIndex === 0 && true}
                                                    className="index-button"
                                                    onClick={handlePrevIndex}>
                                                    <CaretLeft
                                                        size={15}
                                                        color={Constant.$blanc}
                                                    />
                                                </button>

                                                <button
                                                    style={{ opacity: currentIndex === forecast.length - 1 ? 0.5 : 1 }}
                                                    disabled={currentIndex === forecast.length - 1 && true}
                                                    className="index-button"
                                                    onClick={handleNextIndex}>
                                                    <CaretRight
                                                        size={15}
                                                        color={Constant.$blanc}
                                                    />
                                                </button>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                                {forecast.length ? (
                                    <div className="tab-container">
                                        <table style={{ width: '100%' }}>
                                            <thead style={{ backgroundColor: Constant.$vertTresClair }}>
                                                <tr>
                                                    <th style={{ width: '16%', backgroundColor: Constant.$blanc }}></th>
                                                    {forecast.length &&
                                                        getMonthsInYear(forecast[currentIndex].startDate).map((item, index) => (
                                                            <th
                                                                style={{
                                                                    color: Constant.$vertBocoloco,
                                                                    fontWeight: 'bolder',
                                                                    fontSize: '0.75rem',
                                                                    width: '7%',
                                                                    padding: '0.5rem',
                                                                    borderTopLeftRadius: index === 0 && '0.62rem',
                                                                    borderTopRightRadius: index === 11 && '0.62rem',
                                                                    borderRight: index !== 11 ? 'solid 1px' : '0px',
                                                                    borderRightColor: index !== 11 && Constant.$grisclair,
                                                                }}
                                                                key={index}>
                                                                {item.month} <br /> {item.year}
                                                            </th>
                                                        ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {forecast.length &&
                                                    getSku(forecast[currentIndex].annualForecastDetails).map((sku, skuIndex) => (
                                                        <tr
                                                            key={skuIndex}
                                                            style={{
                                                                borderBottom:
                                                                    skuIndex !== forecast[currentIndex].annualForecastDetails.length - 1 ? 'solid 1px' : '0px',
                                                                borderBottomColor:
                                                                    skuIndex !== forecast[currentIndex].annualForecastDetails.length - 1 && Constant.$grisclair,
                                                            }}>
                                                            <td
                                                                style={{
                                                                    borderRight: 'solid 1px',
                                                                    borderRightColor: Constant.$vertTresClair,
                                                                    color: Constant.$vertBocoloco,
                                                                    fontWeight: 'bolder',
                                                                    fontSize: '0.75rem',
                                                                    padding: '0.5rem',
                                                                    width: '16%',
                                                                    backgroundColor: Constant.$vertTresClair,
                                                                    borderTopLeftRadius: skuIndex === 0 ? '0.62rem' : '0px',
                                                                    borderBottomLeftRadius:
                                                                        skuIndex === forecast[currentIndex].annualForecastDetails.length - 1
                                                                            ? '0.62rem'
                                                                            : '0px',
                                                                }}>
                                                                {sku}
                                                            </td>
                                                            {getMonthsInYear(forecast[currentIndex].startDate).map((item, monthIndex) => {
                                                                const quantity =
                                                                    forecast[currentIndex].annualForecastDetails.find((item) => item.sku === sku)[
                                                                        `month${monthIndex + 1}`
                                                                    ] || 0;
                                                                const palet_nbcontainer = forecast[currentIndex].annualForecastDetails.find(
                                                                    (item) => item.sku === sku,
                                                                )?.container.palet_nbcontainer;
                                                                const total = quantity * palet_nbcontainer;
                                                                return (
                                                                    <td
                                                                        style={{
                                                                            color: Constant.$grisfonce,
                                                                            width: '7%',
                                                                            textAlign: 'center',
                                                                            padding: '0.5rem',
                                                                            fontSize: '0.75rem',
                                                                            borderLeft: 'solid 1px',
                                                                            borderLeftColor: Constant.$grisclair,
                                                                        }}
                                                                        key={monthIndex}>
                                                                        {total}
                                                                    </td>
                                                                );
                                                            })}
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </table>
                                    </div>
                                ) : (
                                    <div className="no-data-graph">Aucune donnée.</div>
                                )}
                            </section>
                        )}
                    </section>
                </main>
            )}
        </main>
    );
}
