import { useState, useEffect } from 'react';
import { getSorterById } from '../../../../../API/SorterApi';
import { getWashersFromSorterId } from '../../../../../API/SorterWasherLinkApi';
import { getWashersFromSorterIdCheck } from '../../../../../API/CheckApi/CheckSorterWasherLinkApi';
import { _defineStatus } from '../../../../../Utils';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Pencil } from '@phosphor-icons/react';
import * as Sentry from '@sentry/react';
import { useNavigate } from 'react-router-dom';

export default function SorterSheet() {
    const navigate = useNavigate();
    const [filterSelected, setFilterSelected] = useState('desc');

    const [sorterData, setSorterData] = useState([]);
    const [sorterWasherData, setSorterWasherData] = useState([]);
    const location = useLocation();
    const { id } = location.state || {};

    useEffect(() => {
        const fetchSorterData = async () => {
            try {
                const sorterRawData = await getSorterById(id);
                setSorterData(sorterRawData[0]);

                const sorterWashers = await getWashersFromSorterId(id);
                const sorterWashersCheck = getWashersFromSorterIdCheck(sorterWashers);
                if (sorterWashers.length && sorterWashersCheck !== true) {
                    console.error(sorterWashersCheck);
                    Sentry.captureException(sorterWashersCheck);
                    navigate('/error');
                    return;
                }
                const resultatFinal = regrouperParWasherId(sorterWashers);
                setSorterWasherData(resultatFinal);
            } catch (error) {
                console.error('Erreur lors de la récupération des infos du centre de tri', error);
            }
        };
        fetchSorterData();
    }, [id, navigate]);

    const regrouperParWasherId = (tab) => {
        const resultat = {};

        tab.forEach((objet) => {
            const { entity_washer_id: washerId, name, sku } = objet;

            if (resultat[washerId]) {
                resultat[washerId].sku.push(sku);
            } else {
                resultat[washerId] = { washer_id: washerId, sku: [sku], name: [name] };
            }
        });

        return Object.values(resultat);
    };

    const sortDataAsc = (data, setData) => {
        const sortedData = [...data].sort((a, b) => a.name[0].localeCompare(b.name[0]));
        setData(sortedData);
        setFilterSelected('asc');
    };

    const sortDataDesc = (data, setData) => {
        const sortedData = [...data].sort((a, b) => b.name[0].localeCompare(a.name[0]));
        setData(sortedData);
        setFilterSelected('desc');
    };

    console.log(sorterWasherData);

    return (
        <main>
            {sorterData.id && (
                <div className="sheet">
                    <div className="sheet-top-infos">
                        <div className="title">
                            <Link
                                className="update-indus"
                                to={'/servicesupplier/operation/sorter/update'}
                                state={{ id: sorterData.id }}>
                                <div className="icon">
                                    <Pencil size={20} />
                                </div>
                            </Link>
                            <h2>{sorterData.company_name}</h2>
                            <div
                                className="status"
                                style={{ backgroundColor: _defineStatus(sorterData.status).color }}>
                                {_defineStatus(sorterData.status).name}
                            </div>
                        </div>
                        <section className="item-info-container">
                            <div className="border">
                                <div>
                                    <p>
                                        Société : <span>{sorterData.company_name}</span>
                                    </p>
                                    <p>
                                        Siret : <span>{sorterData.siret}</span>
                                    </p>
                                    <p>
                                        Tva intracommunautaire : <span>{sorterData.tva_intra}</span>
                                    </p>
                                </div>
                            </div>
                            <div className="border">
                                <div>
                                    <p>
                                        Adresse 1 : <span>{sorterData.address_1}</span>
                                    </p>
                                    <p>
                                        Adresse 2 : <span>{sorterData.address_2}</span>
                                    </p>
                                    <p>
                                        Ville : <span>{sorterData.city}</span>
                                    </p>
                                    <p>
                                        Code postal : <span>{sorterData.zipcode}</span>
                                    </p>
                                </div>
                            </div>
                            <div className="border">
                                <div>
                                    <p>
                                        Nom du contact : <span>{sorterData.contact_name}</span>
                                    </p>
                                    <p>
                                        Email du contact : <span>{sorterData.contact_email}</span>
                                    </p>
                                    <p>
                                        Téléphone du contact : <span>{sorterData.contact_phone}</span>
                                    </p>
                                </div>
                            </div>
                        </section>
                    </div>

                    <section className="big-section">
                        <div className="top">
                            <h2 style={{ margin: '0' }}>Les centres de lavage associés :</h2>
                            <div className="top-button-sort">
                                <div className="filter">
                                    <p className="sort">Trier par</p>
                                    <div>
                                        <p
                                            className={filterSelected === 'asc' ? 'selected' : 'classic'}
                                            onClick={() => {
                                                sortDataAsc(sorterWasherData, setSorterWasherData);
                                            }}>
                                            A &gt; Z
                                        </p>
                                        <p
                                            className={filterSelected === 'desc' ? 'selected' : 'classic'}
                                            onClick={() => {
                                                sortDataDesc(sorterWasherData, setSorterWasherData);
                                            }}>
                                            Z &gt; A
                                        </p>
                                    </div>
                                </div>
                                <Link
                                    to={'/servicesupplier/operation/sorter/washer/add'}
                                    state={{ sorter_id: sorterData.id }}
                                    className="button-actif">
                                    ASSOCIER UN CENTRE
                                </Link>
                            </div>
                        </div>

                        <div className="tab-item">
                            <div
                                className="pre-filled-fields-1-several-item"
                                style={{ fontWeight: '600' }}>
                                <p>Centre de lavage</p>
                                <p>SKU</p>
                            </div>

                            {sorterWasherData.length ? (
                                sorterWasherData.map((item, index) => {
                                    return (
                                        <Link
                                            to={'/servicesupplier/operation/sorter/washer/update'}
                                            state={{ washer_id: item.washer_id, sorter_id: id }}
                                            key={index}
                                            className="pre-filled-fields-2-several-item">
                                            <p>{item.name}</p>
                                            <p>{item.sku.join(', ')}</p>
                                        </Link>
                                    );
                                })
                            ) : (
                                <div className="no-data-graph ">Aucun centre lavage associé.</div>
                            )}
                        </div>
                    </section>
                </div>
            )}
        </main>
    );
}
