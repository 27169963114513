import { getGearAPIApiEndpoint } from './endpoints';
import * as Sentry from '@sentry/react';
import { defaultHeaders } from './headers';

const GEAR_API_BASE_URL = getGearAPIApiEndpoint();

const BOCOLOCO_GET_DEPOTS = '/depot/get';
const BOCOLOCO_ADD_DEPOT = '/depot/add';
const BOCOLOCO_GET_DEPOTBYID = '/depot/getbyid';
const BOCOLOCO_GET_DEPOTBYNAME = '/depot/getbyname';
const BOCOLOCO_UPDATE_DEPOT = '/depot/update';
const BOCOLOCO_DELETE_DEPOT = '/depot/delete';

export function getDepots() {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_DEPOTS;
    const url = encodeURI(url_base);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function addDepot(formData) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_ADD_DEPOT;
    const url = encodeURI(url_base);
    return fetch(url, {
        method: 'PUT',
        body: JSON.stringify(formData),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            console.log('ERROR ADD DEPOT');
            console.log(error);
            throw error;
        });
}

export function getDepotsByName(name) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_DEPOTBYNAME;
    var url_args = '?name=' + name;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function getDepotById(id) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_DEPOTBYID;
    var url_args = '?id=' + id;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function updateDepot(id, updatedData) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_UPDATE_DEPOT;
    const url_args = '?id=' + id;
    const url = encodeURI(url_base + url_args);
    return fetch(url, {
        method: 'PUT',
        body: JSON.stringify(updatedData),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            console.log('ERROR UPDATE DEPOT');
            console.log(error);
            throw error;
        });
}

export function deleteDepot(id) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_DELETE_DEPOT;
    var url_args = '?id=' + id;
    const url = encodeURI(url_base + url_args);

    return fetch(url, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            throw error;
        });
}
