import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getOrdersDetailsNotCompleted } from '../../../../API/OrderApi';
import { getOrdersDetailsNotCompletedCheck } from '../../../../API/CheckApi/CheckOrderApi';
import { _defineOrderStatus } from '../../../../Utils';
import { format } from 'date-fns';
import * as Constant from '../../../../Constants';
import { SortAscending, SortDescending } from '@phosphor-icons/react';
import * as Sentry from '@sentry/react';
import { useNavigate } from 'react-router-dom';

export default function UntreatedNeed() {
    const navigate = useNavigate();
    const [allOrderDetailsList, setAllOrderDetailsList] = useState([]);
    const [isAscending, setIsAscending] = useState(true);

    useEffect(() => {
        const fetchAllOrdersDetails = async () => {
            try {
                const result = await getOrdersDetailsNotCompleted();
                const resultCheck = getOrdersDetailsNotCompletedCheck(result);
                if (result.length && resultCheck !== true) {
                    console.error(resultCheck);
                    Sentry.captureException(resultCheck);
                    navigate('/error');
                    return;
                }
                for (let i = 0; i < result.length; i++) {
                    result[i].qtyTotal = result[i].qty * result[i].container.palet_nbcontainer;
                }
                const nouveauTableau = [];

                result.forEach((objet) => {
                    const dateD = new Date(objet.dated);
                    const mois = format(dateD, 'MM yyyy');
                    if (!nouveauTableau[mois]) {
                        nouveauTableau[mois] = [];
                    }

                    nouveauTableau[mois].push(objet);
                });

                setAllOrderDetailsList(nouveauTableau);
            } catch (error) {
                console.error('Erreur lors de la récupération des industrials', error);
            }
        };
        fetchAllOrdersDetails();
    }, [navigate]);

    const sortData = (asc) => {
        const sortedEntries = Object.entries(allOrderDetailsList).sort((a, b) => {
            const dateA = new Date(a[0].split(' ').reverse().join('-'));
            const dateB = new Date(b[0].split(' ').reverse().join('-'));

            return asc ? dateA - dateB : dateB - dateA;
        });

        const sortedData = Object.fromEntries(sortedEntries);
        setAllOrderDetailsList(sortedData);
    };

    const toggleSortOrder = () => {
        setIsAscending(!isAscending);
        sortData(!isAscending);
    };

    const formatDate = (dateString) => {
        const [month, year] = dateString.split(' ');
        const months = [
            'janvier',
            'février',
            'mars',
            'avril',
            'mai',
            'juin',
            'juillet',
            'août',
            'septembre',
            'octobre',
            'novembre',
            'décembre',
        ];
        const monthIndex = parseInt(month, 10) - 1;
        return `${months[monthIndex]} ${year}`;
    };

    return (
        <main>
            <section
                className="small-section"
                style={{ width: '70%' }}>
                <h2>Besoins en cours</h2>
                <div className="tab-item">
                    <div
                        className="pre-filled-fields-1-several-item"
                        style={{ fontWeight: '600', width: '100%' }}>
                        <p style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span>Date de livraison</span>
                            <span
                                style={{ paddingRight: '0.93rem', cursor: 'pointer' }}
                                onClick={toggleSortOrder}>
                                {isAscending ? <SortAscending size={15} /> : <SortDescending size={15} />}
                            </span>
                        </p>
                        <p>Marque</p>
                        <p style={{ flex: 1.5 }}>SKU</p>
                        <p>Nb palettes</p>
                        <p>Statut</p>
                    </div>
                </div>
                {allOrderDetailsList ? (
                    Object.entries(allOrderDetailsList).map(([date, items], index) => {
                        return (
                            <div
                                key={index}
                                className="tab-item">
                                <div className="pre-filled-fields-1">
                                    <p>{formatDate(date)}</p>
                                </div>
                                {items.map((item, itemIndex) => (
                                    <Link
                                        to={'/servicesupplier/operation/indusneeds/assign'}
                                        state={{ item: item, company_name: item.order.entity.name }}
                                        className="pre-filled-fields-2-several-item"
                                        key={itemIndex}>
                                        <p>{format(item.dated, 'yyyy-MM-dd')}</p>
                                        <p>{item.order.entity.name}</p>
                                        <p style={{ flex: 1.5 }}>{item.sku}</p>
                                        <p>{item.qty}</p>
                                        <p
                                            style={{
                                                height: '100%',
                                                backgroundColor: _defineOrderStatus(item.status).color,
                                                borderTopRightRadius: '0.6rem',
                                                borderBottomRightRadius: '0.6rem',
                                                color: Constant.$blanc,
                                            }}>
                                            {_defineOrderStatus(item.status).name}
                                        </p>
                                    </Link>
                                ))}
                            </div>
                        );
                    })
                ) : (
                    <div style={{ textAlign: 'center' }}>Aucun besoin en cours.</div>
                )}
            </section>
        </main>
    );
}
