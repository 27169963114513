import React, { useState } from 'react';
import Footer from '../../containers/Footer';
import { resetUserPassword } from '../../API/AccountApi';

// TODO: Recfactor the login functionality
const PasswordForgotten = () => {
    const [email, setEmail] = useState('');
    const [statusReset, setStatusReset] = useState('');

    const handleSubmit = async (event) => {
        console.log('submit', event);

        event.preventDefault();
        const userData = {
            mail: email,
        };
        resetUserPassword(userData)
            .then((data) => _callbackResetUserPasswordSUCCESS(data))
            .catch((error) => _callbackResetUserPasswordERROR(error));
    };

    const _callbackResetUserPasswordSUCCESS = async (data) => {
        console.log(data);
        setStatusReset('OK');
        //        window.location.href = '/';
    };

    const _callbackResetUserPasswordERROR = (error) => {
        console.log(error.message);
        setStatusReset('OK');
        //        window.location.href = '/';
    };

    return (
        <main className="login-container">
            <div className="form-container resetpassword">
                <div className="title">
                    <h2>Mot de passe oublié</h2>
                </div>
                <form
                    className="login-form"
                    onSubmit={handleSubmit}>
                    <label style={{ marginBottom: 20 }}>Entrez votre adresse email pour recevoir un lien de réinitialisation</label>
                    <input
                        value={email}
                        type="text"
                        placeholder="Email"
                        onChange={(event) => {
                            setEmail(event.target.value);
                        }}
                    />
                </form>
                <div className="button">
                    <button
                        onClick={handleSubmit}
                        className="button-actif"
                        type="submit">
                        VALIDER
                    </button>
                </div>
                <div>{statusReset && <span>Demande de reinitialisation envoyée</span>}</div>
            </div>
            <Footer />
        </main>
    );
};

export default PasswordForgotten;
