export const getForecastByEntityCheck = (data) => {
    const name = 'getForecastByEntity : ';
    for (let i = 0; i < data.length; i++) {
        if (!data[i].entity_id) {
            return name + 'No entity id found';
        } else if (!data[i].annualForecastDetails) {
            return name + 'No details found';
        } else {
            return true;
        }
    }
};

export const getAllForecastDetailsCheck = (data) => {
    const name = 'getAllForecastDetails : ';
    for (let i = 0; i < data.length; i++) {
        if (!data[i].annualForecast) {
            return name + 'No forecast found';
        } else if (!data[i].container) {
            return name + 'No container found';
        } else {
            return true;
        }
    }
};
