// SORTER DECLARATION
export const getAllSorterDeclarationDetailsByDatesCheck = (data) => {
    const name = 'getAllSorterDeclarationDetailsByDatesCheck : ';
    for (let i = 0; i < data.length; i++) {
        if (!data[i].id) {
            return name + 'No id found';
        } else if (!data[i].container.id) {
            return name + 'No container id found';
        } else if (!data[i].sorterDeclaration.id) {
            return name + 'No sorter declaration id found';
        } else {
            return true;
        }
    }
};

export const getSorterDeclarationDetailsLastStatusByEntityIdCheck = (data) => {
    const name = 'getSorterDeclarationDetailsLastStatusByEntityIdCheck : ';
    for (let i = 0; i < data.length; i++) {
        if (!data[i].id) {
            return name + 'No id found';
        } else if (!data[i].sorterDeclaration.id) {
            return name + 'No sorter Declaration id found';
        } else if (!data[i].sorterDeclaration.entity.id) {
            return name + 'No entity id found';
        } else if (data[i].sorterDeclaration.suivi) {
            if (!data[i].sorterDeclaration.suivi.id) {
                return name + 'No suivi id found';
            } else if (!data[i].sorterDeclaration.suivi.sorterEvents[0].status) {
                return name + 'No status found';
            } else {
                return true;
            }
        } else {
            return true;
        }
    }
};

export const getDetailsLastSorterEventStatusCheck = (data) => {
    const name = 'getDetailsLastSorterEventStatus : ';
    for (let i = 0; i < data.length; i++) {
        if (!data[i].id) {
            return name + 'No id found';
        } else if (!data[i].container.id) {
            return name + 'No container id found';
        } else if (data[i].suivi) {
            if (!data[i].suivi.id) {
                return name + 'No suivi id found';
            } else if (!data[i].suivi.sorterEvents[0].status) {
                return name + 'No status found';
            } else {
                return true;
            }
        } else {
            return true;
        }
    }
};
