import { useState, useEffect, useRef } from 'react';
import { getCollectorById } from '../../../../../API/CollectorApi';
import { getStoreByCollectorIdCheck } from '../../../../../API/CheckApi/CheckStoreApi';
import { getReturnByCollectorIdCheck } from '../../../../../API/CheckApi/CheckCollectorReturnApi';
import { getCollectorByIdcheck } from '../../../../../API/CheckApi/CheckCollectorApi';
import { getStoreByCollectorId } from '../../../../../API/StoreApi';
import { generateTokenRVM, getTokenRVM, deleteTokenRVM } from '../../../../../API/TokenApi';
import { _defineStatus, transformDateFull, _defineCollectorType, _defineStatusBool } from '../../../../../Utils';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Pencil, Trash } from '@phosphor-icons/react';
import * as Constant from '../../../../../Constants';
import { getReturnByCollectorId } from '../../../../../API/CollectorReturnApi';
import { useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';

export default function CollectorSheet() {
    const navigate = useNavigate();
    const [filterSelected, setFilterSelected] = useState('asc');
    const [collectorData, setCollectorData] = useState([]);
    const [storeData, setStoreData] = useState([]);
    const [collectorToken, setCollectorToken] = useState(null);
    const [collectorDBTokens, setCollectorDBTokens] = useState(null);
    const [collectorReturnData, setCollectorReturnData] = useState([]);
    const location = useLocation();
    const dialogRef = useRef(null);
    const { id } = location.state || {};

    useEffect(() => {
        const fetchDepotData = async () => {
            try {
                const storeData = await getStoreByCollectorId(id);
                const storeDataCheck = getStoreByCollectorIdCheck(storeData);
                if (storeData.length && storeDataCheck !== true) {
                    console.error(storeDataCheck);
                    Sentry.captureException(storeDataCheck);
                    navigate('/error');
                    return;
                }

                setStoreData(storeData[0]);

                const collectorRawData = await getCollectorById(id);
                const collectorDataCheck = getCollectorByIdcheck(collectorRawData);
                if (collectorRawData.length && collectorDataCheck !== true) {
                    console.error(collectorDataCheck);
                    Sentry.captureException(collectorDataCheck);
                    navigate('/error');
                    return;
                }
                setCollectorData(collectorRawData[0]);

                const collectorTokens = await getTokenRVM(id);
                setCollectorDBTokens(collectorTokens);

                const storeCollectorReturnRawData = await getReturnByCollectorId(id);
                const storeCollectorReturnCheck = getReturnByCollectorIdCheck(storeCollectorReturnRawData);
                if (storeCollectorReturnRawData.length && storeCollectorReturnCheck !== true) {
                    console.error(storeCollectorReturnCheck);
                    Sentry.captureException(storeCollectorReturnCheck);
                    navigate('/error');
                    return;
                }
                setCollectorReturnData(storeCollectorReturnRawData);
            } catch (error) {
                console.error('Erreur lors de la récupération des infos du collecteur', error);
            }
        };
        fetchDepotData();
    }, [id, navigate]);

    const generateTokenOnClick = async () => {
        try {
            // Generating Token
            const collectorGenerateToken = await generateTokenRVM(id);
            setCollectorToken(collectorGenerateToken);

            const collectorToken = await getTokenRVM(id);
            setCollectorDBTokens(collectorToken);

            if (dialogRef.current) {
                dialogRef.current.showModal();
            }
        } catch (error) {
            console.error("Erreur lors de l'enregistrement des modifications", error);
        }
    };

    const deleteTokenOnClick = async (item) => {
        try {
            // Generating Token
            await deleteTokenRVM(id, item.uuid);
            const collectorToken = await getTokenRVM(id);
            setCollectorDBTokens(collectorToken);
            alert('Token supprimé');
        } catch (error) {
            console.error("Erreur lors de l'enregistrement des modifications", error);
        }
    };

    const handleModalClose = () => {
        setCollectorToken();
        if (dialogRef.current) {
            dialogRef.current.close();
        }
    };

    const sortDataAsc = () => {
        const sortedData = [...collectorReturnData].sort((a, b) => a.returnedAt.localeCompare(b.returnedAt));

        setCollectorReturnData(sortedData);
        setFilterSelected('asc');
    };

    const sortDataDesc = () => {
        const sortedData = [...collectorReturnData].sort((a, b) => b.returnedAt.localeCompare(a.returnedAt));
        setCollectorReturnData(sortedData);
        setFilterSelected('desc');
    };

    return (
        <main>
            {collectorData.id && (
                <div className="sheet">
                    <div className="sheet-top-infos">
                        <div className="title">
                            <Link
                                className="update-store"
                                to={'/servicesupplier/operation/store/update'}
                                state={{ id: collectorData.id }}>
                                <div className="icon">
                                    <Pencil size={20} />
                                </div>
                            </Link>
                            <h2>Magasin : {storeData.name}</h2>
                            <div
                                className="status"
                                style={{ backgroundColor: _defineStatus(collectorData.status).color }}>
                                {_defineStatus(collectorData.status).name}
                            </div>
                        </div>
                        <section className="item-info-container">
                            <div className="border">
                                <div>
                                    <p>
                                        Société Mère : <span>{storeData.company_name}</span>
                                    </p>
                                    <p>
                                        Nom : <span>{storeData.name}</span>
                                    </p>
                                </div>
                            </div>
                            <div className="border">
                                <div>
                                    <p>
                                        Adresse 1 : <span>{storeData.address_1}</span>
                                    </p>
                                    <p>
                                        Adresse 2 : <span>{storeData.address_2}</span>
                                    </p>
                                    <p>
                                        Ville : <span>{storeData.city}</span>
                                    </p>
                                    <p>
                                        Code postal : <span>{storeData.zipcode}</span>
                                    </p>
                                </div>
                            </div>
                            <div className="border">
                                <div>
                                    <p>
                                        Nom du contact : <span>{storeData.contact_name}</span>
                                    </p>
                                    <p>
                                        Email du contact : <span>{storeData.contact_email}</span>
                                    </p>
                                    <p>
                                        Téléphone du contact : <span>{storeData.contact_phone}</span>
                                    </p>
                                </div>
                            </div>
                        </section>
                    </div>
                    <div className="sheet-top-infos">
                        <div className="title">
                            <Link
                                className="update-depot"
                                to={'/servicesupplier/operation/collector/update'}
                                state={{ id: collectorData.id }}>
                                <div className="icon">
                                    <Pencil size={20} />
                                </div>
                            </Link>
                            <h2>Collector : {collectorData.id}</h2>
                            <div
                                className="status"
                                style={{ backgroundColor: _defineStatus(collectorData.status).color }}>
                                {_defineStatus(collectorData.status).name}
                            </div>
                        </div>
                        <section className="item-info-container">
                            <div className="border">
                                <div>
                                    <p>
                                        Type : <span>{_defineCollectorType(collectorData.type)}</span>
                                    </p>
                                    <p>
                                        Device Id : <span>{collectorData.device_id}</span>
                                    </p>

                                    <p>
                                        Store Id : <span>{collectorData.store_id}</span>
                                    </p>
                                </div>
                            </div>
                            <div className="border">
                                <div>
                                    <p>
                                        Capacité : <span>X/{collectorData.capacity}</span>
                                    </p>
                                    <p>
                                        Date contact : <span>{transformDateFull(collectorData.datep)}</span>
                                    </p>
                                </div>
                            </div>
                        </section>
                    </div>
                    <section className="big-section">
                        <div className="top">
                            <h2 style={{ margin: '0' }}>Collecteur token :</h2>
                            <div className="top-button-sort">
                                <div className="filter"></div>
                                <button
                                    className="button-actif"
                                    onClick={generateTokenOnClick}>
                                    Générer un token
                                </button>
                            </div>
                        </div>

                        <div className="tab-item">
                            <div
                                className="pre-filled-fields-1-several-item"
                                style={{ fontWeight: '600' }}>
                                <p>date creation</p>
                                <p style={{ flex: 1.5 }}>UUID</p>
                                <p>Date expiration</p>
                                <p>Statut</p>
                                <p>Action</p>
                            </div>
                            {collectorDBTokens && collectorDBTokens.length
                                ? collectorDBTokens.map((item, index) => {
                                      return (
                                          <div
                                              key={index}
                                              className="pre-filled-fields-2-several-item">
                                              <p>{transformDateFull(item.createdAt)}</p>
                                              <p style={{ flex: 1.5 }}>{item.uuid}</p>
                                              <p>{transformDateFull(item.expiresAt)}</p>
                                              <p
                                                  style={{
                                                      backgroundColor: _defineStatusBool(item.invalid).color,
                                                      color: Constant.$blanc,
                                                  }}>
                                                  {_defineStatusBool(item.invalid).name}
                                              </p>
                                              <p style={{ cursor: 'pointer' }}>
                                                  <Trash
                                                      size={20}
                                                      className="update-collector"
                                                      onClick={() => deleteTokenOnClick(item)}
                                                  />
                                              </p>
                                          </div>
                                      );
                                  })
                                : null}
                        </div>
                    </section>
                    <section className="big-section">
                        <div className="top">
                            <h2 style={{ margin: '0' }}>Les retours :</h2>
                            <div className="top-button-sort">
                                <div className="filter">
                                    <p className="sort">Trier par</p>
                                    <div>
                                        <p
                                            className={filterSelected === 'asc' ? 'selected' : 'classic'}
                                            onClick={sortDataAsc}>
                                            A &gt; Z
                                        </p>
                                        <p
                                            className={filterSelected === 'desc' ? 'selected' : 'classic'}
                                            onClick={sortDataDesc}>
                                            Z &gt; A
                                        </p>
                                    </div>
                                </div>
                                <div style={{ height: '2.75rem' }}></div>
                            </div>
                        </div>

                        <div className="tab-item">
                            <div
                                className="pre-filled-fields-1-several-item"
                                style={{ fontWeight: '600' }}>
                                <p>Date</p>
                                <p>SKU</p>
                                <p>EAN</p>
                            </div>

                            {collectorReturnData.length ? (
                                collectorReturnData.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className="pre-filled-fields-2-several-item">
                                            <p>{transformDateFull(item.returnedAt)}</p>
                                            <p>{item.entityIndustrialProduct ? item.entityIndustrialProduct.container.sku : 'nd'}</p>
                                            <p>{item.ean}</p>
                                        </div>
                                    );
                                })
                            ) : (
                                <div className="no-data-graph ">Aucun retour dans ce collecteur.</div>
                            )}
                        </div>
                    </section>
                    <dialog
                        ref={dialogRef}
                        className="dialog-collector">
                        <div className="title">
                            <p>Information Token</p>
                            <button
                                className="button-negatif"
                                onClick={handleModalClose}>
                                X
                            </button>
                        </div>
                        {collectorToken ? (
                            <div style={{ border: 'solid 1px red', wordBreak: 'break-word', display: 'flex', flexDirection: 'column', gap: '0.93rem' }}>
                                <p style={{ fontWeight: '400' }}>
                                    Ce token ne vous sera devoile que une seule fois. Veuillez le copier et le conserver en lieu sur. <br /> En cas de probleme
                                    avec un token vous pourrez supprimer le précédent et en créer un nouveau :
                                </p>
                                <p style={{ fontWeight: '400', fontSize: 13 }}>{collectorToken.token}</p>
                            </div>
                        ) : null}
                    </dialog>
                </div>
            )}
        </main>
    );
}
