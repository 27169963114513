import { getGearAPIApiEndpoint } from './endpoints';
import * as Sentry from '@sentry/react';
import { defaultHeaders } from './headers';

const GEAR_API_BASE_URL = getGearAPIApiEndpoint();

const BOCOLOCO_CREATE_SUPPLIERORDERDETAILS = '/supplierorderdetails/new';
const BOCOLOCO_UPDATE_SUPPLIER_ORDERDETAILS_REPLACED = '/supplierorderdetails/replaced';
const BOCOLOCO_GET_SUPPLIERORDERDETAILS_BY_ORDERID = '/supplierorderdetails/getbyorderdetailsid';

export function supplierOrderDetailsCreate(supplierOrderData) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_CREATE_SUPPLIERORDERDETAILS;
    const url = encodeURI(url_base);
    return fetch(url, {
        method: 'PUT',
        body: JSON.stringify(supplierOrderData),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            console.log('ERROR SUPPLIER ORDER DETAILS CREATE');
            console.log(error);
            throw error;
        });
}

export function updateSupplierOrderDetailsStatusReplaced(formData) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_UPDATE_SUPPLIER_ORDERDETAILS_REPLACED;
    const url = encodeURI(url_base);
    return fetch(url, {
        method: 'PUT',
        body: JSON.stringify(formData),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            console.log('ERROR UPDATE WASHER DECLARATION DETAILS');
            console.log(error);
            throw error;
        });
}

export function getSupplierOrderDetailsByOrderId(id) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_SUPPLIERORDERDETAILS_BY_ORDERID;
    var url_args = '?id=' + id;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}
