import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getInfoUser } from '../../API/AccountApi';
import { getUserInfoCheck } from '../../API/CheckApi/CheckAccountApi';
import * as Sentry from '@sentry/react';

export default function ProfilPersonalInfo() {
    const [userInfo, setUserInfo] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserInfo = async () => {
            const data = await getInfoUser();
            const dataCheck = getUserInfoCheck(data);
            if (data.length && dataCheck !== true) {
                console.error(dataCheck);
                Sentry.captureException(dataCheck);
                navigate('/error');
                return;
            }
            setUserInfo(data[0]);
        };
        fetchUserInfo();
    }, [navigate]);

    return (
        <main>
            <section
                className="big-section"
                style={{ width: '40%' }}>
                <h2>Mon compte</h2>
                {userInfo ? (
                    <div
                        className="form-container"
                        style={{ flexDirection: 'column' }}>
                        <section style={{ width: '100%' }}>
                            <div>
                                <label>ID</label>
                                <input
                                    disabled
                                    type="text"
                                    name="id"
                                    value={userInfo.id || ''}
                                />
                            </div>
                            <div>
                                <label>Nom</label>
                                <input
                                    disabled
                                    type="text"
                                    name="last_name"
                                    value={userInfo.last_name || ''}
                                />
                            </div>
                            <div>
                                <label>Prénom</label>
                                <input
                                    disabled
                                    type="text"
                                    name="first_name"
                                    value={userInfo.first_name || ''}
                                />
                            </div>
                            <div>
                                <label>Email</label>
                                <input
                                    disabled
                                    type="text"
                                    name="email"
                                    value={userInfo.email || ''}
                                />
                            </div>
                            <div>
                                <label>Mot de passe</label>
                                <Link to={'/updatepassword'}>Changer de mot de passe</Link>
                            </div>
                        </section>
                    </div>
                ) : null}
            </section>
        </main>
    );
}
