import { MagnifyingGlass } from '@phosphor-icons/react';
import * as Constant from '../Constants';
export default function SearchComponent({
    dropdownRef,
    showOptions,
    searchTerm,
    handleInputChange,
    productsNames,
    handleSelectOption,
    placeholder,
    searchKey,
}) {
    return (
        <div
            ref={dropdownRef}
            style={{ position: 'relative' }}
            className="scrolling-search-menu">
            <div style={{ display: 'flex', position: 'relative' }}>
                <input
                    style={{ outline: 'none', borderBottomLeftRadius: showOptions && '0px', borderBottomRightRadius: showOptions && '0px' }}
                    type="text"
                    value={searchTerm}
                    onChange={handleInputChange}
                    placeholder={placeholder}
                />
                <div
                    style={{
                        position: 'absolute',
                        right: 0,
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        width: '10%',
                        paddingRight: '0.5rem',
                    }}>
                    <MagnifyingGlass
                        size={20}
                        color={Constant.$vertBocoloco}
                        weight="bold"
                    />
                </div>
            </div>

            {showOptions && (
                <div
                    style={{ position: 'absolute', top: '100%', left: 0, zIndex: 1 }}
                    className="test-option">
                    <div>
                        <ul style={{ listStyle: 'none', margin: 0, padding: 0 }}>
                            {productsNames.length > 0
                                ? productsNames.map(
                                      (option, index) =>
                                          (typeof option[searchKey] === 'number' ? option[searchKey].toString() : option[searchKey])
                                              .toLowerCase()
                                              .includes(searchTerm) && (
                                              <li
                                                  key={index}
                                                  onClick={() => handleSelectOption(option)}>
                                                  {option[searchKey]}
                                              </li>
                                          ),
                                  )
                                : null}
                        </ul>
                    </div>
                </div>
            )}
        </div>
    );
}
