// Get the endpoint for GEAR API to use:
// Default: Replace gear with gear-api in the instance URL of GEAR
// If the URL host is localhost we will use localhost and the port 3001
import Joi from 'joi';

export function getGearAPIApiEndpoint() {
    let url;

    const gear_hostname = window.location.hostname;

    let gearapi_host;
    if (gear_hostname === 'localhost') {
        gearapi_host = window.location.hostname + ':3001';
    } else {
        gearapi_host = window.location.host.replace('gear', 'gear-api');
    }

    console.debug('GEAR API: Use ' + gearapi_host);
    url = window.location.protocol + '//' + gearapi_host;

    // Validate our URL is valid
    const schema = Joi.string()
        .uri({
            scheme: [
                'http',
                'https',
            ],
        })
        .required();
    const result = schema.validate(url);

    if (result.error) {
        console.error(result.error);
        // In case our API is wrong we want to break for security reasons
        return '';
    } else {
        if (result.warn) {
            console.warn(result.warn);
        }
        return result.value;
    }
}
