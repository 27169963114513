import React from 'react';
import {
    createRoutesFromChildren, // multiline hack
    matchRoutes,
    useLocation,
    useNavigationType,
} from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';

import App from './App';
import './Assets/styles/global.scss';

// Enable Sentry only in Production as we have access to these informations when we running locally
// Also prevents spamming the Sentry bug tracker
function validateSentryDsnUrl() {
    if (import.meta.env.PROD) {
        return 'https://71e7513026dc48bf530faee00c5b7f43@o384779.ingest.sentry.io/4506615475732480';
    } else {
        return '';
    }
}

Sentry.init({
    dsn: validateSentryDsnUrl(),
    integrations: [
        new Sentry.BrowserTracing({
            // See docs for support of different versions of variation of react router
            // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                React.useEffect, // multiline hack
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes,
            ),
        }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost:3000', /^https:\/\/gear\.stag\.bocotech\.fr/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

ReactDOM.createRoot(document.getElementById('root')).render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
);
