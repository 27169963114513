import { useState, useEffect } from 'react';
import { getSorters } from '../../../../../../API/SorterApi';
import { updateSorterWasherLink, getAllSorterWasherLinks } from '../../../../../../API/SorterWasherLinkApi';
import { useLocation, useNavigate } from 'react-router-dom';

export default function UpdateWasherSorterLink() {
    const location = useLocation();
    const navigate = useNavigate();
    const { sorter_id, washer_id } = location.state || {};
    const [sortersList, setSortersList] = useState([]);
    const [washersList, setWashersList] = useState([]);
    const [skuList, setSkuList] = useState([]);

    const [allLinksList, setAllLinksList] = useState([]);

    const [formData, setFormData] = useState({
        entity_id: sorter_id,
        washer_id: washer_id,
        selectedSkusToAdd: [],
        selectedSkusToDelete: [],
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        const _getAllSorters = async () => {
            const data = await getSorters();
            setSortersList(data);

            let sorters_list = document.getElementById('optionsSelect');
            if (sorter_id > 0) {
                sorters_list.value = sorter_id;
                sorters_list.dispatchEvent(new Event('change'));
                setFormData((formData) => ({ ...formData, entity_id: sorter_id }));

                sorters_list.disabled = true;
            }
        };

        _getAllSorters();
    }, [sorter_id]);

    useEffect(() => {
        const _getAllWashers = async () => {
            const { gearApi } = await import('../../../../../../gearApi.js');
            const data = await gearApi.washer.getWashers();
            setWashersList(data);

            let washer_list = document.getElementById('washerSelect');
            if (washer_id > 0) {
                washer_list.value = washer_id;
                washer_list.dispatchEvent(new Event('change'));
                setFormData((formData) => ({ ...formData, washer_id: washer_id }));

                washer_list.disabled = true;
            }
        };

        _getAllWashers();
    }, [washer_id]);

    useEffect(() => {
        const handleWasherChange = async () => {
            const { gearApi } = await import('../../../../../../gearApi.js');
            const data = await gearApi.washer.getSkusOfWasher({ washerId: formData.washer_id || washer_id });
            const allLinks = await getAllSorterWasherLinks();
            setAllLinksList(allLinks);

            const newData = [];

            for (let i = 0; i < data.length; i++) {
                let linkStatus = 'no';

                for (let j = 0; j < allLinks.length; j++) {
                    if (washer_id === allLinks[j].entity_washer_id && sorter_id === allLinks[j].entity_sorter_id && data[i].sku === allLinks[j].sku) {
                        linkStatus = 'yes';
                        break;
                    } else if (allLinks[j].entity_washer_id !== washer_id && sorter_id === allLinks[j].entity_sorter_id && data[i].sku === allLinks[j].sku) {
                        linkStatus = 'other';
                        break;
                    }
                }

                newData.push({ ...data[i], link: linkStatus });
            }

            setSkuList(newData);
        };

        handleWasherChange();
    }, [formData.washer_id, washer_id, sorter_id]);

    const handleCheckboxChange = (item, isChecked) => {
        setFormData((prevFormData) => {
            const { selectedSkusToAdd, selectedSkusToDelete } = prevFormData;
            const filteredToAdd = selectedSkusToAdd.filter((sku) => sku !== item.sku);
            const filteredToDelete = selectedSkusToDelete.filter((sku) => sku !== item.sku);

            for (let i = 0; i < allLinksList.length; i++) {
                if (
                    washer_id === allLinksList[i].entity_washer_id &&
                    sorter_id === allLinksList[i].entity_sorter_id &&
                    item.sku === allLinksList[i].sku &&
                    !isChecked
                ) {
                    filteredToDelete.push(item.sku);
                    if (item.link !== 'no') {
                        item.link = 'no';
                    }
                    break;
                } else if (isChecked && !filteredToAdd.includes(item.sku)) {
                    filteredToAdd.push(item.sku);
                }
            }

            return {
                ...prevFormData,
                selectedSkusToAdd: filteredToAdd,
                selectedSkusToDelete: filteredToDelete,
            };
        });
    };

    const handleSubmit = (e) => {
        console.log(formData);
        e.preventDefault();
        updateSorterWasherLink(formData)
            .then((data) => {
                console.log(data);
                alert('Le centre de lavage et les sku ont été modifiés avec succès');
                navigate(-1);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    return (
        <main>
            <section className="small-section">
                <h2>Modifier un centre de lavage associé</h2>
                <div className="form-container">
                    <section style={{ width: '100%' }}>
                        <div>
                            <label>Centre de tri:</label>
                            <select
                                name="entity_id"
                                id="optionsSelect"
                                onChange={handleChange}>
                                <option>Choisissez une option</option>
                                {sortersList.map((option, index) => (
                                    <option
                                        key={index}
                                        value={option.id}>
                                        {option.company_name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label>Centre de lavage:</label>
                            <select
                                name="washer_id"
                                id="washerSelect"
                                onChange={(e) => {
                                    handleChange(e);
                                }}>
                                <option>Choisissez une option</option>
                                {washersList.map((option, index) => (
                                    <option
                                        key={index}
                                        value={option.id}>
                                        {option.company_name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </section>
                    <section className="washer-sorter-container">
                        <div className="washer-sorter-content">
                            {skuList &&
                                skuList.map((item, index) => {
                                    return (
                                        <div
                                            className="checkbox-container"
                                            key={index}>
                                            <p>{item.sku}</p>
                                            <div className="toggle-checkbox">
                                                <input
                                                    type="checkbox"
                                                    id={`toggle-${item.id}`}
                                                    className="checkbox"
                                                    onChange={(e) => handleCheckboxChange(item, e.target.checked)}
                                                    disabled={item.link === 'other'}
                                                    title={item.link === 'other' ? 'SKU déjà attribué à un autre centre de lavage' : null}
                                                    checked={
                                                        (formData.selectedSkusToAdd.includes(item.sku) || item.link === 'yes') &&
                                                        !formData.selectedSkusToDelete.includes(item.sku)
                                                    }
                                                />
                                                <label
                                                    htmlFor={`toggle-${item.id}`}
                                                    className="label"></label>
                                            </div>
                                            <p style={{ fontSize: '12px' }}>{item.link === 'other' && 'SKU déjà attribué à un autre centre de lavage'}</p>
                                        </div>
                                    );
                                })}
                        </div>
                    </section>
                </div>
                <div className="button-confirm">
                    <button
                        className="button-actif"
                        onClick={handleSubmit}>
                        Modifier le centre de lavage et les SKU
                    </button>
                </div>
            </section>
        </main>
    );
}
