import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';

export class Jwt {
    constructor(jwtToken = Cookies.get('userToken')) {
        this.token = jwtToken;

        // By default we expect everything is wrong
        this.valid = false;
        this.expired = true;
        this.validSyntax = false;

        // At least create the keys so debugging and error handling is easier
        this.userId = null;
        this.entityId = null;
        this.entityType = null;
        this.uuid = null;
        this.createdAt = null;
        this.expiresAt = null;

        if (this.token) {
            let tokenData;
            try {
                tokenData = jwtDecode(this.token);
                this.validSyntax = true;
            } catch {
                console.error('Invalid token syntax');
                this.validSyntax = false;
            }
            if (this.validSyntax) {
                this.userId = tokenData.userId;
                this.entityId = tokenData.entityId;
                this.entityType = tokenData.entityType;
                this.uuid = tokenData.jti;

                // Dates in JWT are in seconds but Date() is ms
                this.createdAt = new Date(tokenData.iat * 1000);
                this.expiresAt = new Date(tokenData.exp * 1000);

                this.validateExpirationDate();
            }
        }
    }

    validateExpirationDate() {
        if (Date.now() < this.expiresAt) {
            this.expired = false;
            this.valid = true;
        } else {
            console.warn('Token expired');
            this.expired = true;
            this.valid = false;
        }
    }
}

export default new Jwt(Cookies.get('userToken'));
