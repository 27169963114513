import { useEffect, useState } from 'react';
import { getAllOrdersDetails } from '../../../../API/OrderApi';
import CircleChart from '../../../../Components/CircleChart';
import { getAllForecastDetails } from '../../../../API/AnnualForecastApi';
import { CaretLeft, CaretRight } from '@phosphor-icons/react';
import * as Constants from '../../../../Constants';
import { reduceByMonthAndKey, addMonthToTab } from '../../../../UtilsData';
import { endOfDay } from 'date-fns';
import MultiLineChart from '../../../../Components/MultiLineChart';
import { getAllForecastDetailsCheck } from '../../../../API/CheckApi/CheckAnnualForecastApi';
import { useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
export default function FsIndusNeedDashboard() {
    const navigate = useNavigate();
    const [selectAnnuelForecast, setSelectedAnnualForecast] = useState(false);
    const [totalOrders, setTotalOrders] = useState(0);
    const [dataByIndustrial, setDataByIndustrial] = useState([]);
    const [forecast, setForecast] = useState([]);
    const [needs, setNeeds] = useState([]);
    const [formattedGraph, setFormattedGraph] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const statOfyear = new Date(new Date().getFullYear(), 0, 1);
            const endOfyear = new Date(new Date().getFullYear(), 11, 31);
            const result = await getAllOrdersDetails();
            for (let i = 0; i < result.length; i++) {
                result[i].qty = result[i].qty * result[i].container.palet_nbcontainer;
            }

            const formattedData = reduceByMonthAndKey(result, 'dated', 'sku', 'qty', 'sku');

            const formattedChart = addMonthToTab(formattedData, statOfyear.toISOString(), endOfDay(endOfyear.toISOString()), 'sku', 'month', 'quantity');
            setFormattedGraph(formattedChart);
            const ordersGroupedByYearRaw = result.reduce((acc, item) => {
                const year = new Date(item.dated).getFullYear().toString();
                if (!acc[year]) {
                    acc[year] = [];
                }
                acc[year].push(item);
                return acc;
            }, {});
            const ordersGroupedByYear = Object.keys(ordersGroupedByYearRaw).map((year) => ({
                year,
                orders: ordersGroupedByYearRaw[year],
            }));

            for (let i = 0; i < ordersGroupedByYear.length; i++) {
                const quantitesCumulees = cumuleTest(ordersGroupedByYear[i].orders);
                ordersGroupedByYear[i].orders = quantitesCumulees;
            }

            setNeeds(ordersGroupedByYear);

            let somme = 0;

            for (let i = 0; i < result.length; i++) {
                somme += result[i].qty;
            }
            setTotalOrders(somme);

            const dataByIndustrialRaw = groupByIndustrial(result);

            const sommesParEntity = [];

            for (const key in dataByIndustrialRaw) {
                sommesParEntity.push({ name: key, qty: sommerQuantites(dataByIndustrialRaw[key]) });
            }
            setDataByIndustrial(sommesParEntity);

            const allForecastDetails = await getAllForecastDetails();
            const allForecastDetailsCheck = getAllForecastDetailsCheck(allForecastDetails);

            if (allForecastDetails.length && allForecastDetailsCheck !== true) {
                console.error(allForecastDetailsCheck);
                Sentry.captureException(allForecastDetailsCheck);
                navigate('/error');
                return;
            }
            const dataGroupedByYearRaw = allForecastDetails.reduce((acc, item) => {
                const year = new Date(item.annualForecast.startDate).getFullYear().toString();
                if (!acc[year]) {
                    acc[year] = [];
                }
                acc[year].push(item);
                return acc;
            }, {});
            const dataGroupedByYear = Object.keys(dataGroupedByYearRaw).map((year) => {
                const firstDayOfYear = new Date(year, 0, 1);
                const formattedYear = `${firstDayOfYear.getFullYear()}-${('0' + (firstDayOfYear.getMonth() + 1)).slice(-2)}-${('0' + firstDayOfYear.getDate()).slice(-2)}`;
                return {
                    year: formattedYear,
                    annualForecastDetails: dataGroupedByYearRaw[year],
                };
            });

            for (let i = 0; i < dataGroupedByYear.length; i++) {
                const quantitesCumulees = calculerQuantiteCumulee(dataGroupedByYear[i].annualForecastDetails);
                dataGroupedByYear[i].annualForecastDetails = quantitesCumulees;
            }

            setForecast(dataGroupedByYear);
        };
        fetchData();
    }, [navigate]);

    const cumuleTest = (data) => {
        const reducedData = data.reduce((accumulator, currentValue) => {
            const { sku, qty, dated } = currentValue;
            const date = new Date(dated);
            const year = date.getFullYear();
            const month = date.getMonth() + 1;

            const key = `${sku}_${year}-${month < 10 ? '0' : ''}${month}`;

            if (!accumulator[key]) {
                accumulator[key] = { qty: 0 };
            }
            accumulator[key].qty += qty;

            return accumulator;
        }, {});

        const resultFinal = Object.keys(reducedData).map((key) => {
            const [sku, date] = key.split('_');
            return { sku, date, qty: reducedData[key] };
        });
        return resultFinal;
    };

    const calculerQuantiteCumulee = (donnees) => {
        const quantitesCumuleesParSku = {};

        donnees.forEach((element) => {
            const { sku, ...mois } = element;
            const paletNbContainer = element.container && element.container.palet_nbcontainer;
            if (!quantitesCumuleesParSku[sku]) {
                quantitesCumuleesParSku[sku] = {};
                for (let i = 1; i <= 12; i++) {
                    quantitesCumuleesParSku[sku][`month${i}`] = 0;
                }
            }
            Object.keys(mois).forEach((moisKey) => {
                quantitesCumuleesParSku[sku][moisKey] += mois[moisKey];
            });
            if (paletNbContainer !== undefined) {
                quantitesCumuleesParSku[sku].palet_nbcontainer = paletNbContainer;
            }
        });
        const tableauResultat = Object.keys(quantitesCumuleesParSku).map((sku) => ({
            sku,
            ...quantitesCumuleesParSku[sku],
        }));

        return tableauResultat;
    };

    const groupByIndustrial = (tableau) => {
        return tableau.reduce((acc, obj) => {
            const name = obj.order.entity.name;

            if (!acc[name]) {
                acc[name] = [];
            }
            acc[name].push(obj);
            return acc;
        }, {});
    };

    function sommerQuantites(tableauObjets) {
        return tableauObjets.reduce((total, obj) => total + obj.qty * obj.container.palet_nbcontainer, 0);
    }

    const indusTotalGraphQuantities = dataByIndustrial.map((item) => item.qty);
    const indusTotalGraphLabels = dataByIndustrial.map((item) => item.name);

    const [currentIndex, setCurrentIndex] = useState(0);

    const getMonthsInYear = (startDate) => {
        const date = new Date(startDate);
        const year = date.getFullYear();

        const monthsInYear = [];
        let currentDate = new Date();
        currentDate.setFullYear(year);
        currentDate.setMonth(0);

        for (let i = 0; i < 12; i++) {
            const monthName = currentDate.toLocaleString('default', { month: 'long' });
            const currentYear = currentDate.getFullYear();
            monthsInYear.push({ month: monthName, year: currentYear });
            currentDate.setMonth(currentDate.getMonth() + 1);
        }

        return monthsInYear;
    };
    const handleNextIndex = () => {
        setCurrentIndex((prevIndex) => prevIndex + 1);
    };

    const handlePrevIndex = () => {
        setCurrentIndex((prevIndex) => prevIndex - 1);
    };

    const getSku = (tab) => {
        const skusTest = Array.from(new Set(tab.map((item) => item.sku)));
        return skusTest;
    };

    const indusneedsGraph = [...new Set(formattedGraph.map((fd) => fd.sku))];
    const indusneedsGraphQuantites = indusneedsGraph.map((sku) => {
        const data = formattedGraph.filter((fd) => fd.sku === sku).map((fd) => fd.quantity);
        return {
            label: sku,
            data,
        };
    });
    return (
        <main className="dashboard-industrial">
            <section
                className="long-graph-section"
                style={{ width: '100%' }}>
                <div className="long-graph-section-container">
                    <div className="graph-container">
                        <p style={{ fontSize: '15px', marginBottom: 0 }}>
                            Nombre total <br /> de besoins :
                        </p>
                        <span style={{ fontSize: '20px' }}>{totalOrders ? totalOrders : 0}</span>
                    </div>
                    <div className="graph-container">
                        <div style={{ height: '110%' }}>
                            {formattedGraph.length ? (
                                <MultiLineChart
                                    initialData={formattedGraph}
                                    datasets={indusneedsGraphQuantites}
                                />
                            ) : (
                                <div className="no-data-graph ">Aucune donnée.</div>
                            )}
                        </div>
                    </div>
                    <div className="graph-container">
                        {dataByIndustrial.length ? (
                            <CircleChart
                                title={indusTotalGraphLabels}
                                quantities={indusTotalGraphQuantities}
                                legend={'false'}
                            />
                        ) : (
                            <div className="no-data-graph ">Aucune donnée.</div>
                        )}
                    </div>
                </div>
            </section>
            <section className="consolidated-view">
                <div className="graph-needs">
                    <h2 style={{ flex: 1 }}>Vue consolidée</h2>
                    <div
                        className="filter-dashboard"
                        style={{ flex: 2 }}>
                        <div
                            className={selectAnnuelForecast ? 'selected' : ''}
                            onClick={() => {
                                setCurrentIndex(0);
                                setSelectedAnnualForecast(true);
                            }}>
                            Prévisions annuelles
                        </div>
                        <div
                            className={!selectAnnuelForecast ? 'selected' : ''}
                            onClick={() => {
                                setCurrentIndex(0);
                                setSelectedAnnualForecast(false);
                            }}>
                            Besoins mensuels
                        </div>
                    </div>
                    <div style={{ flex: 1 }}></div>
                </div>
                {selectAnnuelForecast ? (
                    <section
                        className="big-section"
                        style={{ margin: '0px' }}>
                        {forecast.length ? (
                            <div style={{ display: 'flex', gap: '1rem', width: '100%' }}>
                                <button
                                    style={{ opacity: currentIndex === 0 ? 0.5 : 1 }}
                                    disabled={currentIndex === 0 && true}
                                    className="index-button"
                                    onClick={handlePrevIndex}>
                                    <CaretLeft
                                        size={15}
                                        color={Constants.$blanc}
                                    />
                                </button>

                                <button
                                    style={{ opacity: currentIndex === forecast.length - 1 ? 0.5 : 1 }}
                                    disabled={currentIndex === forecast.length - 1 && true}
                                    className="index-button"
                                    onClick={handleNextIndex}>
                                    <CaretRight
                                        size={15}
                                        color={Constants.$blanc}
                                    />
                                </button>
                            </div>
                        ) : null}

                        {forecast.length ? (
                            <div className="tab-container">
                                <table style={{ width: '100%' }}>
                                    <thead style={{ backgroundColor: Constants.$vertTresClair }}>
                                        <tr>
                                            <th style={{ width: '16%', backgroundColor: Constants.$blanc }}></th>
                                            {forecast.length
                                                ? getMonthsInYear(forecast[currentIndex].year).map((item, index) => (
                                                      <th
                                                          style={{
                                                              color: Constants.$vertBocoloco,
                                                              fontWeight: 'bolder',
                                                              fontSize: '0.75rem',
                                                              width: '7%',
                                                              padding: '0.5rem',
                                                              borderTopLeftRadius: index === 0 && '0.62rem',
                                                              borderTopRightRadius: index === 11 && '0.62rem',
                                                              borderRight: index !== 11 ? 'solid 1px #CAC9CB' : '0px',
                                                          }}
                                                          key={index}>
                                                          {item.month} <br /> {item.year}
                                                      </th>
                                                  ))
                                                : null}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {forecast.length
                                            ? getSku(forecast[currentIndex].annualForecastDetails).map((sku, skuIndex) => (
                                                  <tr
                                                      key={skuIndex}
                                                      style={{
                                                          borderBottom:
                                                              skuIndex !== forecast[currentIndex].annualForecastDetails.length - 1
                                                                  ? 'solid 1px #CAC9CB'
                                                                  : '0px',
                                                      }}>
                                                      <td
                                                          style={{
                                                              borderRight: 'solid 1px',
                                                              borderRightColor: Constants.$vertTresClair,
                                                              color: Constants.$vertBocoloco,
                                                              fontWeight: 'bolder',
                                                              fontSize: '0.75rem',
                                                              padding: '0.5rem',
                                                              width: '16%',
                                                              backgroundColor: Constants.$vertTresClair,
                                                              borderTopLeftRadius: skuIndex === 0 ? '0.62rem' : '0px',
                                                              borderBottomLeftRadius:
                                                                  skuIndex === forecast[currentIndex].annualForecastDetails.length - 1 ? '0.62rem' : '0px',
                                                          }}>
                                                          {sku}
                                                      </td>
                                                      {getMonthsInYear(forecast[currentIndex].startDate).map((item, monthIndex) => {
                                                          const quantity =
                                                              forecast[currentIndex].annualForecastDetails.find((item) => item.sku === sku)[
                                                                  `month${monthIndex + 1}`
                                                              ] || 0;
                                                          const palet_nbcontainer = forecast[currentIndex].annualForecastDetails.find(
                                                              (item) => item.sku === sku,
                                                          )?.palet_nbcontainer;
                                                          const total = quantity * palet_nbcontainer;

                                                          return (
                                                              <td
                                                                  style={{
                                                                      color: Constants.$grisfonce,
                                                                      width: '7%',
                                                                      textAlign: 'center',
                                                                      padding: '0.5rem',
                                                                      fontSize: '0.75rem',
                                                                      borderLeft: 'solid 1px #CAC9CB',
                                                                  }}
                                                                  key={monthIndex}>
                                                                  {total}
                                                              </td>
                                                          );
                                                      })}
                                                  </tr>
                                              ))
                                            : null}
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            <div className="no-data-graph">Aucune donnée.</div>
                        )}
                    </section>
                ) : (
                    <section
                        className="big-section"
                        style={{ margin: '0px' }}>
                        {needs.length ? (
                            <div style={{ display: 'flex', gap: '1rem', width: '100%' }}>
                                <button
                                    style={{ opacity: currentIndex === 0 ? 0.5 : 1 }}
                                    disabled={currentIndex === 0 && true}
                                    className="index-button"
                                    onClick={handlePrevIndex}>
                                    <CaretLeft
                                        size={15}
                                        color={Constants.$blanc}
                                    />
                                </button>

                                <button
                                    style={{ opacity: currentIndex === needs.length - 1 ? 0.5 : 1 }}
                                    disabled={currentIndex === needs.length - 1 && true}
                                    className="index-button"
                                    onClick={handleNextIndex}>
                                    <CaretRight
                                        size={15}
                                        color={Constants.$blanc}
                                    />
                                </button>
                            </div>
                        ) : null}
                        {needs.length ? (
                            <div className="tab-container">
                                <table style={{ width: '100%' }}>
                                    <thead style={{ backgroundColor: Constants.$vertTresClair }}>
                                        <tr>
                                            <th style={{ width: '16%', backgroundColor: Constants.$blanc }}></th>
                                            {needs.length
                                                ? getMonthsInYear(needs[currentIndex].year).map((item, index) => (
                                                      <th
                                                          style={{
                                                              color: Constants.$vertBocoloco,
                                                              fontWeight: 'bolder',
                                                              fontSize: '0.75rem',
                                                              width: '7%',
                                                              padding: '0.5rem',
                                                              borderTopLeftRadius: index === 0 && '0.62rem',
                                                              borderTopRightRadius: index === 11 && '0.62rem',
                                                              borderRight: index !== 11 ? 'solid 1px' : '0px',
                                                              borderRightColor: index !== 11 && Constants.$grisclair,
                                                          }}
                                                          key={index}>
                                                          {item.month} <br /> {item.year}
                                                      </th>
                                                  ))
                                                : null}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {needs.length
                                            ? getSku(needs[currentIndex].orders).map((sku, skuIndex) => (
                                                  <tr
                                                      key={skuIndex}
                                                      style={{
                                                          borderBottom: skuIndex !== needs[currentIndex].orders.length - 2 ? 'solid 1px #CAC9CB' : '0px',
                                                      }}>
                                                      <td
                                                          style={{
                                                              borderRight: 'solid 1px',
                                                              borderRightColor: Constants.$vertTresClair,
                                                              color: Constants.$vertBocoloco,
                                                              fontWeight: 'bolder',
                                                              fontSize: '0.75rem',
                                                              padding: '0.5rem',
                                                              width: '16%',
                                                              backgroundColor: Constants.$vertTresClair,
                                                              borderTopLeftRadius: skuIndex === 0 ? '0.62rem' : '0px',
                                                              borderBottomLeftRadius: skuIndex === needs[currentIndex].orders.length - 1 ? '0.62rem' : '0px',
                                                          }}>
                                                          {sku}
                                                      </td>
                                                      {getMonthsInYear(needs[currentIndex].year).map((elem, monthIndex) => {
                                                          const order = needs[currentIndex].orders.find(
                                                              (item) => item.sku === sku && new Date(item.date).getMonth() + 1 === monthIndex + 1,
                                                          );
                                                          const quantity = order?.qty.qty || 0;

                                                          return (
                                                              <td
                                                                  style={{
                                                                      color: Constants.$grisfonce,
                                                                      width: '7%',
                                                                      textAlign: 'center',
                                                                      padding: '0.5rem',
                                                                      fontSize: '0.75rem',
                                                                      borderLeft: 'solid 1px',
                                                                      borderLeftColor: Constants.$grisclair,
                                                                  }}
                                                                  key={monthIndex}>
                                                                  <p>{quantity}</p>
                                                              </td>
                                                          );
                                                      })}
                                                  </tr>
                                              ))
                                            : null}
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            <div className="no-data-graph">Aucune donnée.</div>
                        )}
                    </section>
                )}
            </section>
        </main>
    );
}
