import React, { useState, useEffect, useRef } from 'react';
import { getOrderDetailsByEntityId, setOrderDetailsReceived } from '../../../../API/OrderApi';
import { getWasherDeclarationDetailsByOrderDetailIdCheck } from '../../../../API/CheckApi/CheckWasherDeclarationApi';
import { getSupplierOrderDetailsByOrderIdCheck } from '../../../../API/CheckApi/CheckSupplierOrderDetailsApi';
import { getOrderDetailsByEntityIdCheck } from '../../../../API/CheckApi/CheckOrderApi';
import { _defineOrderStatus } from '../../../../Utils';
import { getSupplierOrderDetailsByOrderId } from '../../../../API/SupplierOrderDetailsApi';
import { getWasherDeclarationDetailsByOrderDetailId } from '../../../../API/WasherDeclarationApi';
import { format } from 'date-fns';
import * as Constant from '../../../../Constants';
import { Jwt } from '../../../../Jwt';
import { CaretUp, CaretDown, SortDescending, SortAscending } from '@phosphor-icons/react';
import * as Sentry from '@sentry/react';
import { useNavigate } from 'react-router-dom';

export default function IndusOrderHistory() {
    const navigate = useNavigate();
    const userContext = new Jwt();
    const [orderList, setOrderList] = useState([]);
    const [showArchives, setShowArchives] = useState(false);
    const [archivesTab, setArchivesTab] = useState([]);
    const [modalData, setModalData] = useState([]);
    const [supplierOrder, setSupplierOrder] = useState([]);
    const [washerOrder, setWasherOrder] = useState([]);
    const [showDispo, setShowDispo] = useState(false);
    const [checkSupplier, setCheckSupplier] = useState(false);
    const [isAscending, setIsAscending] = useState(false);

    const dialogRef = useRef(null);

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const result = await getOrderDetailsByEntityId(userContext.entityId);
                const resultCheck = getOrderDetailsByEntityIdCheck(result);
                if (result.length && resultCheck !== true) {
                    console.error(resultCheck);
                    Sentry.captureException(resultCheck);
                    navigate('/error');
                    return;
                }

                const pendingOrder = result.filter((item) => item.status !== Constant.ORDERDETAILS_RECEIVED);
                const pendingOrderRaw = generalTabReduce(pendingOrder);

                const ordersTab = Object.entries(pendingOrderRaw);
                const sortedData = ordersTab.sort((a, b) => {
                    const dateA = new Date(a[1].dateCreation);
                    const dateB = new Date(b[1].dateCreation);
                    return dateB - dateA;
                });
                setOrderList(sortedData);

                setShowDispo({ [sortedData[0]]: true });

                const archiveTabData = result
                    .filter((item) => item.status === Constant.ORDERDETAILS_RECEIVED)
                    .sort((a, b) => new Date(b.time) - new Date(a.time));

                const archiveTabDataRaw = generalTabReduce(archiveTabData);

                setArchivesTab(archiveTabDataRaw);
            } catch (error) {
                console.error('Erreur lors de la récupération des suppliers', error);
            }
        };

        fetchOrders();
    }, [userContext.entityId, navigate]);

    const generalTabReduce = (data) => {
        const tab = data.reduce((acc, obj) => {
            const key = obj.order.id;
            if (!acc[key]) {
                acc[key] = { items: [], totalQty: 0, dateCreation: obj.order.createdAt };
            }
            acc[key].items.push(obj);
            acc[key].totalQty += obj.qty;
            return acc;
        }, {});
        return tab;
    };

    const handleModalOpen = async (item) => {
        const { gearApi } = await import('../../../../gearApi.js');
        const supplierData = await getSupplierOrderDetailsByOrderId(item.id);
        const supplierDataCheck = getSupplierOrderDetailsByOrderIdCheck(supplierData);
        if (supplierData.length && supplierDataCheck !== true) {
            console.error(supplierDataCheck);
            Sentry.captureException(supplierDataCheck);
            navigate('/error');
            return;
        }

        const washerData = await getWasherDeclarationDetailsByOrderDetailId(item.id);
        const washerDataCheck = getWasherDeclarationDetailsByOrderDetailIdCheck(washerData);
        if (washerData.length && washerDataCheck !== true) {
            console.error(washerDataCheck);
            Sentry.captureException(washerDataCheck);
            navigate('/error');
            return;
        }
        const resultSupplier = dataReduce(supplierData);
        const resultWasher = dataWasherReduce(washerData);

        for (let i = 0; i < resultWasher.length; i++) {
            const lastEvent = await gearApi.washer.getEventsOfWashers({ limitCount: 1, suiviId: resultWasher[i].suiviId });
            resultWasher[i].collecteData = lastEvent;
        }
        const resultWasherFiltered = resultWasher.filter((objet) => objet.collecteData[0].status !== Constant.EVENT_RECEIVED);

        setSupplierOrder(resultSupplier);
        setWasherOrder(resultWasherFiltered);
        setModalData(item);
        if (dialogRef.current) {
            dialogRef.current.showModal();
        }
    };

    const handleToggleDispo = (orderId) => {
        setShowDispo((prevState) => ({
            ...prevState,
            [orderId]: !prevState[orderId],
        }));
    };

    const dataReduce = (data) => {
        if (data.length) {
            const total = data.reduce(
                (acc, curr) => {
                    acc.qty += curr.qty;
                    return acc;
                },
                { company_name: data[0].company_name || '', sku: data[0].sku || '', qty: 0 },
            );
            return total;
        }
    };

    const dataWasherReduce = (data) => {
        if (data.length) {
            const total = data.reduce((acc, curr) => {
                if (!acc[curr.company_name]) {
                    acc[curr.company_name] = {
                        company_name: curr.company_name,
                        sku: curr.sku || '',
                        qty: 0,
                        suiviId: curr.suiviId,
                    };
                }
                acc[curr.company_name].qty += curr.qty;
                return acc;
            }, {});
            return Object.values(total);
        }
    };

    const handleModalClose = () => {
        setModalData();

        if (dialogRef.current) {
            dialogRef.current.close();
        }
    };

    const sendPickUpReceived = async (collecteData) => {
        try {
            const { gearApi } = await import('../../../../gearApi.js');
            await gearApi.washer
                .putStatusToSuiviofEventsForWashers({
                    status: Constant.EVENT_RECEIVED,
                    suiviId: collecteData.suiviId,
                    washerId: collecteData.washerId,
                    requestBody: {
                        industrialId: collecteData.industrialId,
                        operatorId: collecteData.operatorId,
                        time: new Date(),
                    },
                })
                .then(async function () {
                    if (washerOrder.length !== 1) {
                        alert('Enregistrement réussi');
                        handleModalClose();
                        window.location.reload();
                    } else {
                        // TODO: Synchronize so we undo the previous event change in case this fails
                        const pickUpData = await setOrderDetailsReceived(modalData);
                        if (pickUpData) {
                            alert('Enregistrement réussi');
                            handleModalClose();
                            window.location.reload();
                        } else {
                            alert('Une erreur est survenue lors de la MAJ de order detail');
                            return;
                        }
                    }
                })
                .catch(function (err) {
                    alert('Une erreur est survenue lors de la mise à jour de event');
                    Sentry.captureException(err);
                    return;
                });
        } catch (error) {
            console.error('Une erreur est survenue :', error);
        }
    };

    const sortData = (asc) => {
        const sortedData = orderList.sort((a, b) => {
            const dateA = new Date(a[1].dateCreation);
            const dateB = new Date(b[1].dateCreation);
            return asc ? dateA - dateB : dateB - dateA;
        });
        setOrderList(sortedData);
    };

    const toggleSortOrder = () => {
        setIsAscending(!isAscending);
        sortData(!isAscending);
    };

    return (
        <main>
            <section className="big-section">
                <div className="top top-history">
                    <h2>Suivi de mes commandes</h2>
                    <div className="sort">
                        <p
                            onClick={() => {
                                setShowArchives(false);
                            }}
                            className={!showArchives ? 'selected' : 'classic'}>
                            En cours
                        </p>
                        <p
                            onClick={() => {
                                setShowArchives(true);
                            }}
                            className={showArchives ? 'selected' : 'classic'}>
                            Archivées
                        </p>
                    </div>
                </div>
                {!showArchives ? (
                    <div className="tab-item">
                        <div
                            className="pre-filled-fields-1-several-item"
                            style={{ fontWeight: '600', width: '100%' }}>
                            <p style={{ flex: 1 }}>N° commande</p>
                            <p style={{ display: 'flex', justifyContent: 'space-between', flex: 1 }}>
                                <span>Réalisée le</span>
                                <span
                                    style={{ paddingRight: '0.93rem', cursor: 'pointer' }}
                                    onClick={toggleSortOrder}>
                                    {isAscending ? <SortAscending size={15} /> : <SortDescending size={15} />}
                                </span>
                            </p>
                            <p style={{ flex: 1, borderRight: 0 }}>Nombre total de palettes</p>
                            <p style={{ flex: 0.5 }}></p>
                        </div>
                        {orderList.length ? (
                            <div>
                                {orderList.map((order, index) => {
                                    return (
                                        <div key={order}>
                                            <div
                                                className="pre-filled-fields-1-several-item"
                                                style={{ fontWeight: '600', justifyContent: 'flex-start', marginBottom: '0.5rem', cursor: 'pointer' }}>
                                                <p style={{ flex: 1, border: '0px' }}>#{order[0]}</p>
                                                <p style={{ flex: 1, border: '0px' }}>{format(order[1].dateCreation, 'yyyy-MM-dd')}</p>
                                                <p style={{ flex: 1, border: '0px' }}>{order[1].totalQty}</p>

                                                <p style={{ flex: 0.5, border: '0px', textAlign: 'end' }}>
                                                    {showDispo[order] ? (
                                                        <CaretUp
                                                            onClick={() => handleToggleDispo(order)}
                                                            style={{ cursor: 'pointer', fontWeight: '800px', paddingRight: '0.93rem' }}
                                                            size={20}
                                                            weight="bold"
                                                        />
                                                    ) : (
                                                        <CaretDown
                                                            onClick={() => handleToggleDispo(order)}
                                                            style={{ cursor: 'pointer', paddingRight: '0.93rem' }}
                                                            size={20}
                                                            weight="bold"
                                                        />
                                                    )}
                                                </p>
                                            </div>
                                            {showDispo[order] && (
                                                <div>
                                                    {order[1].items.map((item) => (
                                                        <div
                                                            className="pre-filled-fields-2-several-item"
                                                            onClick={() => handleModalOpen(item)}
                                                            key={item.id}
                                                            style={{ width: '100%', marginBottom: '0.5rem', cursor: 'pointer' }}>
                                                            <p style={{ flex: 1 }}>SKU : {item.sku}</p>
                                                            <p style={{ flex: 1 }}>Livraison : {format(item.dated, 'yyyy-MM-dd')}</p>
                                                            <p style={{ flex: 1 }}>{item.qty} palette(s)</p>
                                                            <p
                                                                style={{
                                                                    height: '100%',
                                                                    backgroundColor: _defineOrderStatus(item.status).color,
                                                                    borderTopRightRadius: '0.6rem',
                                                                    borderBottomRightRadius: '0.6rem',
                                                                    color: Constant.$blanc,
                                                                    flex: 0.5,
                                                                }}>
                                                                {_defineOrderStatus(item.status).name}
                                                            </p>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        ) : (
                            <div className="no-data-graph">Aucune commande en cours.</div>
                        )}
                    </div>
                ) : (
                    <div className="tab-item">
                        {Object.keys(archivesTab).length ? (
                            <div>
                                {Object.keys(archivesTab).map((orderId) => {
                                    const group = archivesTab[orderId];
                                    return (
                                        <div key={orderId}>
                                            <div
                                                className="pre-filled-fields-1-several-item"
                                                style={{ fontWeight: '600', justifyContent: 'flex-start', marginBottom: '0.5rem' }}>
                                                <p style={{ width: '20rem', flex: 0.5, border: '0px' }}>Commande : #{orderId}</p>
                                                <p style={{ width: '30rem', flex: 0.5, border: '0px' }}>Réalisée le : {group.dateCreation}</p>
                                                <p style={{ width: '10%', flex: 0.5, border: '0px' }}>Total palette(s) : {group.totalQty}</p>
                                                <p style={{ width: '10%', flex: 2, border: '0px' }}></p>
                                                {showDispo[orderId] ? (
                                                    <CaretUp
                                                        onClick={() => handleToggleDispo(orderId)}
                                                        style={{ cursor: 'pointer', fontWeight: '800px', paddingRight: '0.93rem' }}
                                                        size={20}
                                                        weight="bold"
                                                    />
                                                ) : (
                                                    <CaretDown
                                                        onClick={() => handleToggleDispo(orderId)}
                                                        style={{ cursor: 'pointer', paddingRight: '0.93rem' }}
                                                        size={20}
                                                        weight="bold"
                                                    />
                                                )}
                                            </div>
                                            {showDispo[orderId] && (
                                                <div style={{ cursor: 'pointer' }}>
                                                    {group.items.map((item) => (
                                                        <div
                                                            className="pre-filled-fields-2-several-item"
                                                            key={item.id}
                                                            style={{ width: '100%', marginBottom: '0.5rem', cursor: 'pointer' }}>
                                                            <p>{item.sku}</p>
                                                            <p>{item.qty} palette(s)</p>
                                                            <p>Livraison : {format(item.dated, 'yyyy-MM-dd')}</p>
                                                            <p
                                                                style={{
                                                                    height: '100%',
                                                                    backgroundColor: _defineOrderStatus(item.status).color,
                                                                    borderTopRightRadius: '0.6rem',
                                                                    borderBottomRightRadius: '0.6rem',
                                                                    color: 'white',
                                                                }}>
                                                                {_defineOrderStatus(item.status).name}
                                                            </p>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        ) : (
                            <div
                                className="no-data-graph"
                                style={{ marginTop: '20px' }}>
                                Aucune commande archivée.
                            </div>
                        )}
                    </div>
                )}
            </section>
            <dialog
                ref={dialogRef}
                className="dialog-collector">
                <div className="title">
                    <p>Confirmer la réception</p>
                    <button
                        className="button-negatif"
                        onClick={handleModalClose}>
                        X
                    </button>
                </div>
                {modalData ? (
                    <div className="collector-infos">
                        <p>
                            Vous confirmez avoir reçu votre commande de <br /> SKU {modalData.sku} :
                        </p>
                        {supplierOrder && !checkSupplier ? (
                            <div style={{ paddingTop: '1rem', display: 'flex', fontWeight: '400', justifyContent: 'space-between', alignItems: 'center' }}>
                                <p>{supplierOrder && supplierOrder.company_name} </p>
                                <p>{supplierOrder && supplierOrder.qty} palette(s)</p>
                                <button
                                    style={{ width: '50px' }}
                                    onClick={() => {
                                        setCheckSupplier(true);
                                    }}
                                    className="button-actif">
                                    OK
                                </button>
                            </div>
                        ) : null}
                        {washerOrder.length
                            ? washerOrder.map((item, index) => {
                                  return (
                                      <div
                                          key={index}
                                          style={{
                                              paddingTop: '1rem',
                                              display: 'flex',
                                              fontWeight: '400',
                                              justifyContent: 'space-between',
                                              alignItems: 'center',
                                          }}>
                                          <p style={{ flex: 1 }}>{item.collecteData[0].status !== Constant.EVENT_RECEIVED ? item.company_name : null} </p>
                                          <p style={{ flex: 1 }}>{item.collecteData[0].status !== Constant.EVENT_RECEIVED ? `${item.qty} palette(s)` : null}</p>
                                          {item.collecteData[0].status !== Constant.EVENT_RECEIVED ? (
                                              <button
                                                  style={{ width: '50px' }}
                                                  onClick={() => {
                                                      sendPickUpReceived(item.collecteData[0]);
                                                  }}
                                                  className="button-actif">
                                                  OK
                                              </button>
                                          ) : null}
                                      </div>
                                  );
                              })
                            : null}
                    </div>
                ) : null}
            </dialog>
        </main>
    );
}
