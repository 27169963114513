import { useState, useEffect, useRef } from 'react';
import { getCollectorById } from '../../../../API/CollectorApi';
import { getReturnByCollectorIdCheck } from '../../../../API/CheckApi/CheckCollectorReturnApi';
import { getCollectorByIdcheck } from '../../../../API/CheckApi/CheckCollectorApi';
import { generateTokenRVM, getTokenRVM, deleteTokenRVM } from '../../../../API/TokenApi';
import { _defineStatus, _defineStatusBool, transformDateFull, _defineCollectorType } from '../../../../Utils';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { getReturnByCollectorId } from '../../../../API/CollectorReturnApi';
import { Pencil, Trash } from '@phosphor-icons/react';
import * as Constant from '../../../../Constants';
import { useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
export default function CollectorSheet() {
    const navigate = useNavigate();
    const [filterSelected, setFilterSelected] = useState('asc');
    const [collectorData, setCollectorData] = useState([]);
    const [collectorReturnData, setCollectorReturnData] = useState([]);
    const [collectorToken, setCollectorToken] = useState(null);
    const [collectorDBTokens, setCollectorDBTokens] = useState(null);
    const location = useLocation();
    const dialogRef = useRef(null);
    const { id } = location.state || {};

    useEffect(() => {
        const fetchDepotData = async () => {
            try {
                const collectorRawData = await getCollectorById(id);
                const collectorRawDataCheck = getCollectorByIdcheck(collectorRawData);
                if (collectorRawData.length && collectorRawDataCheck !== true) {
                    console.error(collectorRawDataCheck);
                    Sentry.captureException(collectorRawDataCheck);
                    navigate('/error');
                    return;
                }
                setCollectorData(collectorRawData[0]);

                const collectorTokens = await getTokenRVM(id);
                setCollectorDBTokens(collectorTokens);

                const storeCollectorReturnRawData = await getReturnByCollectorId(id);
                const storeCollectorReturnRawDataCheck = getReturnByCollectorIdCheck(storeCollectorReturnRawData);
                if (storeCollectorReturnRawData.length && storeCollectorReturnRawDataCheck !== true) {
                    console.error(storeCollectorReturnRawDataCheck);
                    Sentry.captureException(storeCollectorReturnRawDataCheck);
                    navigate('/error');
                    return;
                }
                setCollectorReturnData(storeCollectorReturnRawData);
            } catch (error) {
                console.error('Erreur lors de la récupération des infos du collecteur', error);
            }
        };
        fetchDepotData();
    }, [id, navigate]);

    const generateTokenOnClick = async () => {
        try {
            // Generating Token
            const collectorGenerateToken = await generateTokenRVM(id);
            setCollectorToken(collectorGenerateToken);

            const collectorToken = await getTokenRVM(id);
            setCollectorDBTokens(collectorToken);

            if (dialogRef.current) {
                dialogRef.current.showModal();
            }
        } catch (error) {
            console.error("Erreur lors de l'enregistrement des modifications", error);
        }
    };

    const deleteTokenOnClick = async (item) => {
        try {
            // Generating Token
            await deleteTokenRVM(id, item.uuid);
            const collectorToken = await getTokenRVM(id);
            setCollectorDBTokens(collectorToken);
            alert('Token supprimé');
        } catch (error) {
            console.error("Erreur lors de l'enregistrement des modifications", error);
        }
    };

    const handleModalClose = () => {
        setCollectorToken();
        if (dialogRef.current) {
            dialogRef.current.close();
        }
    };

    const sortDataAsc = (data, setData) => {
        const sortedData = [...data].sort((a, b) => a.returnedAt.localeCompare(b.returnedAt));
        setData(sortedData);
        setFilterSelected('asc');
    };

    const sortDataDesc = (data, setData) => {
        const sortedData = [...data].sort((a, b) => b.returnedAt.localeCompare(a.returnedAt));
        setData(sortedData);
        setFilterSelected('desc');
    };
    return (
        <main>
            {collectorData.id && (
                <div className="sheet">
                    <div className="sheet-top-infos">
                        <div className="title">
                            <Link
                                className="update-collector"
                                to={'/rvmowner/operation/collector/update'}
                                state={{ id: collectorData.id }}>
                                <div className="icon">
                                    <Pencil size={20} />
                                </div>
                            </Link>
                            <h2>
                                {collectorData.store_name} - {collectorData.id}
                            </h2>
                            <div
                                className="status"
                                style={{ backgroundColor: _defineStatus(collectorData.status).color }}>
                                {_defineStatus(collectorData.status).name}
                            </div>
                        </div>
                        <section className="item-info-container">
                            <div className="border">
                                <div>
                                    <p>
                                        Enseigne : <span>{collectorData.store_company_name}</span>
                                    </p>
                                    <p>
                                        Nom : <span>{collectorData.store_name}</span>
                                    </p>
                                    <p>
                                        Addresse : <span>{collectorData.store_address_1}</span>
                                    </p>
                                    <p>
                                        Addresse Comp. : <span>{collectorData.store_address_2}</span>
                                    </p>
                                    <p>
                                        Code postal : <span>{collectorData.store_zipcode}</span>
                                    </p>
                                    <p>
                                        Ville : <span>{collectorData.store_city}</span>
                                    </p>
                                </div>
                            </div>
                            <div className="border">
                                <div>
                                    <p>
                                        Fabricant : <span>{collectorData.supplier_name}</span>
                                    </p>
                                    <p>
                                        Modèle : <span>{collectorData.supplier_model}</span>
                                    </p>
                                    <p>
                                        Type : <span>{_defineCollectorType(collectorData.type)}</span>
                                    </p>
                                    <p>
                                        Device Id : <span>{collectorData.device_id}</span>
                                    </p>
                                    <p>
                                        Capacité : <span>X/{collectorData.capacity}</span>
                                    </p>
                                </div>
                            </div>
                            <div className="border">
                                <div>
                                    <p>
                                        Dernière Sync : <span>{transformDateFull(collectorData.datep)}</span>
                                    </p>
                                    <p>
                                        Remplissage : <span>{collectorData.filling_rate}</span>
                                    </p>
                                    <p>
                                        Statut syncronisation : <span>{_defineStatus(collectorData.status).name}</span>
                                    </p>
                                </div>
                            </div>
                        </section>
                    </div>
                    <section className="big-section">
                        <div className="top">
                            <h2 style={{ margin: '0' }}>Collecteur token :</h2>
                            <div className="top-button-sort">
                                <div className="filter"></div>
                                <button
                                    className="button-actif"
                                    onClick={generateTokenOnClick}>
                                    Générer un token
                                </button>
                            </div>
                        </div>

                        <div className="tab-item">
                            <div
                                className="pre-filled-fields-1-several-item"
                                style={{ fontWeight: '600' }}>
                                <p>date creation</p>
                                <p style={{ flex: 1.5 }}>UUID</p>
                                <p>Date expiration</p>
                                <p>Statut</p>
                                <p>Action</p>
                            </div>
                            {collectorDBTokens && collectorDBTokens.length ? (
                                collectorDBTokens.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className="pre-filled-fields-2-several-item">
                                            <p>{transformDateFull(item.createdAt)}</p>
                                            <p style={{ flex: 1.5 }}>{item.uuid}</p>
                                            <p>{transformDateFull(item.expiresAt)}</p>
                                            <p
                                                style={{
                                                    backgroundColor: _defineStatusBool(item.invalid).color,
                                                    color: Constant.$blanc,
                                                }}>
                                                {_defineStatusBool(item.invalid).name}
                                            </p>
                                            <p style={{ cursor: 'pointer' }}>
                                                <Trash
                                                    size={20}
                                                    className="update-collector"
                                                    onClick={() => deleteTokenOnClick(item)}
                                                />
                                            </p>
                                        </div>
                                    );
                                })
                            ) : (
                                <div className="no-data-graph ">Aucun token enregistré.</div>
                            )}
                        </div>
                    </section>
                    <section className="big-section">
                        <div className="top">
                            <h2 style={{ margin: '0' }}>Les Retours :</h2>
                            <div className="top-button-sort">
                                <div className="filter">
                                    <p className="sort">Trier par</p>
                                    <div>
                                        <p
                                            className={filterSelected === 'asc' ? 'selected' : 'classic'}
                                            onClick={() => {
                                                sortDataAsc(collectorReturnData, setCollectorReturnData);
                                            }}>
                                            A &gt; Z
                                        </p>
                                        <p
                                            className={filterSelected === 'desc' ? 'selected' : 'classic'}
                                            onClick={() => {
                                                sortDataDesc(collectorReturnData, setCollectorReturnData);
                                            }}>
                                            Z &gt; A
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="tab-item">
                            <div
                                className="pre-filled-fields-1-several-item"
                                style={{ fontWeight: '600' }}>
                                <p>Id</p>
                                <p>Date Retour</p>
                                <p>SKU</p>
                                <p>EAN</p>
                                <p>Qty</p>
                            </div>

                            {collectorReturnData.length ? (
                                collectorReturnData.map((item, index) => {
                                    return (
                                        <div
                                            to={'/servicesupplier/operation/collector/sheet'}
                                            key={index}
                                            className="pre-filled-fields-2-several-item">
                                            <p>{item.id}</p>
                                            <p>{transformDateFull(item.returnedAt)}</p>
                                            <p>{item.entityIndustrialProduct ? item.entityIndustrialProduct.container.sku : '-'}</p>
                                            <p>{item.ean}</p>
                                            <p>{item.qty}</p>
                                        </div>
                                    );
                                })
                            ) : (
                                <div className="no-data-graph ">Aucun retour dans ce collecteur.</div>
                            )}
                        </div>
                    </section>
                    <dialog
                        ref={dialogRef}
                        className="dialog-collector">
                        <div className="title">
                            <p>Information Token</p>
                            <button
                                className="button-negatif"
                                onClick={handleModalClose}>
                                X
                            </button>
                        </div>
                        {collectorToken ? (
                            <div style={{ border: 'solid 1px red', wordBreak: 'break-word', display: 'flex', flexDirection: 'column', gap: '0.93rem' }}>
                                <p style={{ fontWeight: '400' }}>
                                    Ce token ne vous sera devoile que une seule fois. Veuillez le copier et le conserver en lieu sur. <br /> En cas de probleme
                                    avec un token vous pourrez supprimer le précédent et en créer un nouveau :
                                </p>
                                <p style={{ fontWeight: '400', fontSize: 13 }}>{collectorToken.token}</p>
                            </div>
                        ) : null}
                    </dialog>
                </div>
            )}
        </main>
    );
}
