import { getGearAPIApiEndpoint } from './endpoints';
import { defaultHeaders } from './headers';
const GEAR_API_BASE_URL = getGearAPIApiEndpoint();

const BOCOLOCO_TOKENS_RVMS = '/tokens/rvms/';
const BOCOLOCO_TOKENS_RVMS_GENERATE = '/tokens/rvms/';
const BOCOLOCO_TOKENS_RVMS_INVALID = '/tokens/rvms/invalid/';

export function getTokenRVM(collectorId) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_TOKENS_RVMS + collectorId;
    const url = encodeURI(url_base);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => response.json())
        .catch((error) => {
            throw error;
        });
}

export function generateTokenRVM(collectorId) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_TOKENS_RVMS_GENERATE + collectorId;
    const url = encodeURI(url_base);
    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            throw error;
        });
}

export function deleteTokenRVM(collectorId, tokenUUID) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_TOKENS_RVMS_INVALID + collectorId + '/' + tokenUUID;
    const url = encodeURI(url_base);
    return fetch(url, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            throw error;
        });
}
