// SUPPLIER ORDER DETAILS

export const getSupplierOrderDetailsByOrderIdCheck = (data) => {
    const name = 'getSupplierOrderDetailsByOrderId : ';
    for (let i = 0; i < data.length; i++) {
        if (!data[i].id) {
            return name + 'No id found';
        } else if (!data[i].supplierProduct.id) {
            return name + 'No supplier Product id found';
        } else if (!data[i].supplierProduct.supplier.id) {
            return name + 'No supplier id found';
        } else {
            return true;
        }
    }
};
