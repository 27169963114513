import { useState, useEffect } from 'react';
import { getOperatorById } from '../../../../../API/OperatorApi';
import { _defineStatus } from '../../../../../Utils';
import { useLocation, Link } from 'react-router-dom';
import { Pencil } from '@phosphor-icons/react';

export default function OperatorSheet() {
    const [operatorData, setOperatorData] = useState([]);

    const location = useLocation();
    const { id } = location.state || {};

    useEffect(() => {
        const fetchOperatorData = async () => {
            try {
                const washerRowData = await getOperatorById(id);
                setOperatorData(washerRowData[0]);
            } catch (error) {
                console.error('Erreur lors de la récupération des infos du centre de lavage', error);
            }
        };
        fetchOperatorData();
    }, [id]);
    return (
        <main>
            {operatorData.id && (
                <div className="sheet">
                    <div className="sheet-top-infos">
                        <div className="title">
                            <Link
                                className="update-indus"
                                to={'/servicesupplier/operation/operator/update'}
                                state={{ id: operatorData.id }}>
                                <div className="icon">
                                    <Pencil size={20} />
                                </div>
                            </Link>

                            <h2>{operatorData.company_name}</h2>
                            <div
                                className="status"
                                style={{ backgroundColor: _defineStatus(operatorData.status).color }}>
                                {_defineStatus(operatorData.status).name}
                            </div>
                        </div>
                        <section className="item-info-container">
                            <div className="border">
                                <div>
                                    <p>
                                        Société : <span>{operatorData.company_name}</span>
                                    </p>
                                    <p>
                                        Site web : <span>{operatorData.website}</span>
                                    </p>
                                    <p>
                                        Siret : <span>{operatorData.siret}</span>
                                    </p>
                                    <p>
                                        Tva intracommunautaire : <span>{operatorData.tva_intra}</span>{' '}
                                    </p>
                                </div>
                            </div>
                            <div className="border">
                                <div>
                                    <p>
                                        Adresse 1 : <span>{operatorData.address_1}</span>
                                    </p>
                                    <p>
                                        Adresse 2 : <span>{operatorData.address_2}</span>
                                    </p>
                                    <p>
                                        Ville : <span>{operatorData.city}</span>
                                    </p>
                                    <p>
                                        Code postal : <span>{operatorData.zipcode}</span>
                                    </p>
                                </div>
                            </div>
                            <div className="border">
                                <div>
                                    <p>
                                        Nom du contact : <span>{operatorData.contact_name}</span>
                                    </p>
                                    <p>
                                        Email du contact : <span>{operatorData.contact_email}</span>
                                    </p>
                                    <p>
                                        Téléphone du contact : <span>{operatorData.contact_phone}</span>
                                    </p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            )}
        </main>
    );
}
