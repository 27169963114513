import { useState, useEffect } from 'react';
import { getOperatorById, updateOperator } from '../../../../../API/OperatorApi';
import { _defineStatus } from '../../../../../Utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { checkEntityFormErrors, checkIsInputEmpty } from '../../../../../Utils';
import * as Constant from '../../../../../Constants';
import AddressSearch from '../../../../../Components/AddressSearch';
export default function UpdateOperator() {
    const location = useLocation();
    const navigate = useNavigate();
    const { id } = location.state || {};
    const [operatorData, setOperatorData] = useState([]);
    const [errorMessage, setErrorMessage] = useState([]);
    const [errorEmpty, setErrorEmpty] = useState('');
    useEffect(() => {
        const fetchOperatorData = async () => {
            try {
                const operatorRowData = await getOperatorById(id);
                setOperatorData(operatorRowData[0]);
            } catch (error) {
                console.error("Erreur lors de la récupération des infos de l'operateur de collecte", error);
            }
        };
        fetchOperatorData();
    }, [id]);

    const [formErrors, setFormErrors] = useState({
        company_name: false,
        address_1: false,
        address_2: false,
        city: false,
        zipcode: false,
        siret: false,
        tva_intra: false,
        contact_name: false,
        contact_phone: false,
        contact_email: false,
        website: false,
    });

    const requiredFields = [
        'company_name',
        'address_1',
        'city',
        'zipcode',
        'siret',
        'tva_intra',
        'contact_name',
        'contact_phone',
        'contact_email',
    ];

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setOperatorData({
            ...operatorData,
            [name]: value,
        });
    };

    const handleSaveChanges = async (e) => {
        e.preventDefault();
        setErrorMessage([]);
        setErrorEmpty('');
        const updatedFormData = { ...operatorData, name: operatorData.company_name };

        const inputEmpty = checkIsInputEmpty(requiredFields, operatorData, setFormErrors, setErrorEmpty);
        if (!inputEmpty) {
            const checkFormat = checkEntityFormErrors(operatorData, formErrors, setFormErrors, setErrorMessage);
            if (checkFormat) {
                return;
            } else {
                const updateOperatorData = await updateOperator(id, updatedFormData);
                if (updateOperatorData) {
                    alert("L'operateur de collecte a été modifié avec succès");
                    navigate(-1);
                } else {
                    alert("une erreur est survenue lors de la modification de l'operateur de collecte");
                }
            }
        } else {
            console.log('Il reste des champs obligatoires vides');
        }
    };
    return (
        <main>
            <section className="big-section">
                <h2>Modifier un operateur de collecte</h2>

                {operatorData.id ? (
                    <div className="form-container">
                        <section>
                            <div>
                                <label>Nom de la société:</label>
                                <input
                                    className={formErrors.company_name ? 'error' : ''}
                                    type="text"
                                    name="company_name"
                                    value={operatorData.company_name || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <label>Siret:</label>
                                <input
                                    className={formErrors.siret ? 'error' : ''}
                                    type="text"
                                    name="siret"
                                    value={operatorData.siret || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <label>TVA intracommunautaire::</label>
                                <input
                                    className={formErrors.tva_intra ? 'error' : ''}
                                    type="text"
                                    name="tva_intra"
                                    value={operatorData.tva_intra || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </section>
                        <section>
                            <AddressSearch
                                formData={operatorData}
                                setFormData={setOperatorData}
                                formErrors={formErrors}
                                setFormErrors={setFormErrors}
                                handleChange={handleInputChange}
                            />
                        </section>
                        <section>
                            <div>
                                <label>Nom du contact:</label>
                                <input
                                    className={formErrors.contact_name ? 'error' : ''}
                                    type="text"
                                    name="contact_name"
                                    value={operatorData.contact_name || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <label>Téléphone du contact:</label>
                                <input
                                    className={formErrors.contact_phone ? 'error' : ''}
                                    type="text"
                                    name="contact_phone"
                                    value={operatorData.contact_phone || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <label>Email du contact:</label>
                                <input
                                    className={formErrors.contact_email ? 'error' : ''}
                                    type="email"
                                    name="contact_email"
                                    value={operatorData.contact_email || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <label>Site web:</label>
                                <input
                                    className={formErrors.website ? 'error' : ''}
                                    type="url"
                                    name="website"
                                    value={operatorData.website || ''}
                                    onChange={handleInputChange}
                                />
                            </div>

                            <div>
                                <label>Statut:</label>
                                <select
                                    style={{ backgroundColor: _defineStatus(operatorData.status).color, color: Constant.$blanc }}
                                    name="status"
                                    value={operatorData.status}
                                    onChange={handleInputChange}>
                                    <option value={0}>Actif</option>
                                    <option value={100}>Inactif</option>
                                </select>
                            </div>
                        </section>
                    </div>
                ) : null}
                <p className="error-form">{errorEmpty && errorEmpty}</p>
                {errorMessage.length
                    ? errorMessage.map((item, index) => {
                          return (
                              <div
                                  className="error-form"
                                  key={index}>
                                  {item}
                              </div>
                          );
                      })
                    : null}
                <div className="button-confirm">
                    <button
                        className="button-actif"
                        onClick={handleSaveChanges}>
                        Enregistrer les modifications
                    </button>
                </div>
            </section>
        </main>
    );
}
