import { useEffect, useState } from 'react';
import { addIndustrialProduct } from '../../../../API/IndustrialApi';
import { useLocation, useNavigate } from 'react-router-dom';
import { checkIndustrialProductFormErrors, checkIsInputEmpty, _defineProductCat, productCatTab } from '../../../../Utils';
import { getContainers } from '../../../../API/ContainerApi';

export default function AddIndustrialProduct() {
    const location = useLocation();
    const navigate = useNavigate();
    const { entity_id } = location.state || {};
    const [errorMessage, setErrorMessage] = useState([]);
    const [errorEmpty, setErrorEmpty] = useState('');
    const [containerList, setContainerList] = useState([]);

    const [formData, setFormData] = useState({
        entity_id: entity_id,
        name: '',
        ean: '',
        cat: '',
        sku: '',
        unitValue: null,
        unit: '',
    });

    const [formErrors, setFormErrors] = useState({
        name: false,
        ean: false,
        cat: false,
        sku: false,
        unitValue: null,
        unit: '',
    });

    const requiredFields = [
        'name',
        'ean',
        'cat',
        'sku',
        'unitValue',
        'unit',
    ];
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        const fetchContainers = async () => {
            const containerData = await getContainers();
            setContainerList(containerData);
        };
        fetchContainers();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage([]);
        setErrorEmpty('');

        const inputEmpty = checkIsInputEmpty(requiredFields, formData, setFormErrors, setErrorEmpty);
        if (!inputEmpty) {
            const checkFormat = checkIndustrialProductFormErrors(formData, formErrors, setFormErrors, setErrorMessage);
            if (checkFormat) {
                return;
            } else {
                const addProductData = await addIndustrialProduct(formData);
                if (addProductData) {
                    alert('Le produit a été a été créé avec succès');
                    navigate(-1);
                } else {
                    alert("une erreur est survenue lors de l'ajout du produit");
                }
            }
        } else {
            console.log('Il reste des champs obligatoires vides');
        }
    };
    return (
        <main>
            <section className="small-section">
                <h2>Ajouter un produit</h2>
                <div className="form-container">
                    <section style={{ width: '100%' }}>
                        <div>
                            <label>Nom du produit :</label>
                            <input
                                className={formErrors.name ? 'error' : ''}
                                type="text"
                                name="name"
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <label>EAN : </label>
                            <input
                                className={formErrors.ean ? 'error' : ''}
                                type="text"
                                name="ean"
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <label>SKU : </label>

                            <select
                                name="sku"
                                className={formErrors.sku ? 'error' : ''}
                                onChange={handleChange}>
                                <option>Choisissez une option</option>
                                {containerList.map((option, index) => (
                                    <option
                                        key={index}
                                        value={option.sku}>
                                        {option.sku}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label className="small-title">Catégorie :</label>
                            <select
                                className={formErrors.cat ? 'error' : ''}
                                name="cat"
                                onChange={handleChange}>
                                <option value={''}>--</option>
                                {productCatTab.map((cat, index) => (
                                    <option
                                        key={index}
                                        value={parseInt(cat)}>
                                        {_defineProductCat(cat)}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label className="small-title">Unité :</label>
                            <select
                                className={formErrors.unit ? 'error' : ''}
                                name="unit"
                                onChange={handleChange}>
                                <option>Choisissez une option</option>
                                <option value={1}>mL</option>
                                <option value={1}>g</option>
                            </select>
                        </div>
                        <div>
                            <label className="small-title">Valeur Unité :</label>
                            <input
                                className={formErrors.unitValue ? 'error' : ''}
                                type="number"
                                name="unitValue"
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </section>
                </div>
                <p className="error-form">{errorEmpty && errorEmpty}</p>
                {errorMessage.length
                    ? errorMessage.map((item, index) => {
                          return (
                              <div
                                  className="error-form"
                                  key={index}>
                                  {item}
                              </div>
                          );
                      })
                    : null}
                <div className="button-confirm">
                    <button
                        className="button-actif"
                        onClick={handleSubmit}>
                        Ajouter le produit
                    </button>
                </div>
            </section>
        </main>
    );
}
