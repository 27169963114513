export const AccessCdc = {
    cdc: {
        operation: {},
        data: {},
        financial: {
            gestion_financiere: {
                dashboard: 0,
                marques: 0,
                magasins: 0,
                collecteurs: 0,
            },
        },
    },
};
