import { useState, useEffect } from 'react';
import { getProductInfoById, updateIndustrialProduct } from '../../../../../../API/IndustrialApi';
import { getProductInfoByIdCheck } from '../../../../../../API/CheckApi/CheckIndustrialApi';
import { _defineStatus } from '../../../../../../Utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { getContainers } from '../../../../../../API/ContainerApi';
import * as Constant from '../../../../../../Constants';
import { checkIndustrialProductFormErrors, checkIsInputEmpty, productCatTab, _defineProductCat } from '../../../../../../Utils';
import * as Sentry from '@sentry/react';

export default function UpdateIndustrialProduct() {
    const location = useLocation();
    const navigate = useNavigate();
    const { id } = location.state || {};
    const [productData, setProductData] = useState([]);
    const [errorMessage, setErrorMessage] = useState([]);
    const [errorEmpty, setErrorEmpty] = useState('');
    const [containerList, setContainerList] = useState([]);

    useEffect(() => {
        const fetchProductData = async () => {
            try {
                const product = await getProductInfoById(id);
                const productCheck = getProductInfoByIdCheck(product);
                if (product.length && productCheck !== true) {
                    console.error(productCheck);
                    Sentry.captureException(productCheck);
                    navigate('/error');
                    return;
                }
                setProductData(product[0]);

                const containerData = await getContainers();
                setContainerList(containerData);
            } catch (error) {
                console.error("Erreur lors de la récupération de l'adresse", error);
            }
        };
        fetchProductData();
    }, [id, navigate]);

    const [formErrors, setFormErrors] = useState({
        name: false,
        ean: false,
        cat: false,
        sku: false,
        unitValue: null,
        unit: '',
    });

    const requiredFields = [
        'name',
        'ean',
        'sku',
        'unitValue',
        'unit',
    ];

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProductData({
            ...productData,
            [name]: value,
        });
    };

    const handleSaveChanges = async (e) => {
        e.preventDefault();
        setErrorMessage([]);
        setErrorEmpty('');

        const inputEmpty = checkIsInputEmpty(requiredFields, productData, setFormErrors, setErrorEmpty);
        if (!inputEmpty) {
            const checkFormat = checkIndustrialProductFormErrors(productData, formErrors, setFormErrors, setErrorMessage);
            if (checkFormat) {
                return;
            } else {
                const updateProductData = await updateIndustrialProduct(id, productData);
                if (updateProductData) {
                    alert('Le produit a été a été modifié avec succès');
                    navigate(-1);
                } else {
                    alert('une erreur est survenue lors de la modification du produit');
                }
            }
        } else {
            console.log('Il reste des champs obligatoires vides');
        }
    };
    return (
        <main>
            <section className="small-section">
                <h2>Modifier le produit d&apos;une marque</h2>
                {productData.id ? (
                    <div className="form-container">
                        <section style={{ width: '100%' }}>
                            <div>
                                <label>Nom de la marque :</label>
                                <select
                                    disabled={true}
                                    className={formErrors.entity_id ? 'error' : ''}
                                    name="entity_id"
                                    onChange={handleInputChange}>
                                    <option value={productData.entity_id}>{productData.company_name}</option>
                                </select>
                            </div>
                            <div>
                                <label>Nom du produit :</label>
                                <input
                                    className={formErrors.name ? 'error' : ''}
                                    type="text"
                                    name="name"
                                    value={productData.name || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <label>EAN :</label>
                                <input
                                    className={formErrors.ean ? 'error' : ''}
                                    type="text"
                                    name="ean"
                                    value={productData.ean || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <label>SKU :</label>
                                <select
                                    value={productData.sku}
                                    name="sku"
                                    className={formErrors.sku ? 'error' : ''}
                                    onChange={handleInputChange}>
                                    {containerList.map((option, index) => (
                                        <option
                                            key={index}
                                            value={option.sku}>
                                            {option.sku}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label className="small-title">Unité:</label>
                                <select
                                    className={formErrors.unit ? 'error' : ''}
                                    name="unit"
                                    value={productData.unit}
                                    onChange={handleInputChange}>
                                    <option value={''}>--</option>
                                    <option value={1}>mL</option>
                                    <option value={1}>g</option>
                                </select>
                            </div>
                            <div>
                                <label className="small-title">Valeur Unité:</label>
                                <input
                                    value={productData.unitValue}
                                    className={formErrors.unitValue ? 'error' : ''}
                                    type="number"
                                    name="unitValue"
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div>
                                <label className="small-title">Catégorie:</label>
                                <select
                                    value={productData.cat}
                                    className={formErrors.cat ? 'error' : ''}
                                    name="cat"
                                    onChange={handleInputChange}>
                                    <option value={''}>--</option>
                                    {productCatTab.map((cat, index) => (
                                        <option
                                            key={index}
                                            value={parseInt(cat)}>
                                            {_defineProductCat(cat)}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label>Statut :</label>
                                <select
                                    style={{ backgroundColor: _defineStatus(productData.status).color, color: Constant.$blanc }}
                                    name="status"
                                    value={productData.status}
                                    onChange={handleInputChange}>
                                    <option value={0}>Actif</option>
                                    <option value={100}>Inactif</option>
                                </select>
                            </div>
                        </section>
                    </div>
                ) : null}
                <p className="error-form">{errorEmpty && errorEmpty}</p>
                {errorMessage.length
                    ? errorMessage.map((item, index) => {
                          return (
                              <div
                                  className="error-form"
                                  key={index}>
                                  {item}
                              </div>
                          );
                      })
                    : null}
                <div className="button-confirm">
                    <button
                        className="button-actif"
                        onClick={handleSaveChanges}>
                        Enregistrer les modifications
                    </button>
                </div>
            </section>
        </main>
    );
}
