import { useState, useEffect } from 'react';
import { _defineStatus } from '../../../../../Utils';
import { Link, useLocation } from 'react-router-dom';
import { Pencil } from '@phosphor-icons/react';
import * as Constant from '../../../../../Constants';

export default function WasherSheet() {
    const [filterSelected, setFilterSelected] = useState('asc');

    const [washerData, setWasherData] = useState([]);
    const [washerSkuData, setWasherSkuData] = useState([]);
    const location = useLocation();
    const { id } = location.state || {};

    useEffect(() => {
        const fetchWasherData = async () => {
            try {
                const { gearApi } = await import('../../../../../gearApi.js');

                const washerRowData = await gearApi.washer.getWasherById({ washerId: id });
                setWasherData(washerRowData);

                const washerSku = await gearApi.washer.getSkusOfWasher({ washerId: id });
                setWasherSkuData(washerSku);
            } catch (error) {
                console.error('Erreur lors de la récupération des infos du centre de lavage', error);
            }
        };
        fetchWasherData();
    }, [id]);

    const sortDataAsc = (data, setData) => {
        const sortedData = [...data].sort((a, b) => a.sku.localeCompare(b.sku));
        setData(sortedData);
        setFilterSelected('asc');
    };

    const sortDataDesc = (data, setData) => {
        const sortedData = [...data].sort((a, b) => b.sku.localeCompare(a.sku));
        setData(sortedData);
        setFilterSelected('desc');
    };

    return (
        <main>
            {washerData.id && (
                <div className="sheet">
                    <div className="sheet-top-infos">
                        <div className="title">
                            <Link
                                className="update-indus"
                                to={'/servicesupplier/operation/washer/update'}
                                state={{ id: washerData.id }}>
                                <div className="icon">
                                    <Pencil size={20} />
                                </div>
                            </Link>

                            <h2>{washerData.company_name}</h2>
                            <div
                                className="status"
                                style={{ backgroundColor: _defineStatus(washerData.status).color }}>
                                {_defineStatus(washerData.status).name}
                            </div>
                        </div>
                        <section className="item-info-container">
                            <div className="border">
                                <div>
                                    <p>
                                        Société : <span>{washerData.company_name}</span>
                                    </p>
                                    <p>
                                        Site web : <span>{washerData.website}</span>
                                    </p>
                                    <p>
                                        Siret : <span>{washerData.siret}</span>
                                    </p>
                                    <p>
                                        Tva intracommunautaire : <span>{washerData.tva_intra}</span>{' '}
                                    </p>
                                </div>
                            </div>

                            <div className="border">
                                <div>
                                    <p>
                                        Adresse 1 : <span>{washerData.address_1}</span>
                                    </p>
                                    <p>
                                        Adresse 2 : <span>{washerData.address_2}</span>
                                    </p>
                                    <p>
                                        Ville : <span>{washerData.city}</span>
                                    </p>
                                    <p>
                                        Code postal : <span>{washerData.zipcode}</span>
                                    </p>
                                </div>
                            </div>
                            <div className="border">
                                <div>
                                    <p>
                                        Nom du contact : <span>{washerData.contact_name}</span>
                                    </p>
                                    <p>
                                        Email du contact : <span>{washerData.contact_email}</span>
                                    </p>
                                    <p>
                                        Téléphone du contact : <span>{washerData.contact_phone}</span>
                                    </p>
                                </div>
                            </div>
                        </section>
                    </div>

                    <section className="big-section">
                        <div className="top">
                            <h2 style={{ margin: '0' }}>Les SKU associés :</h2>
                            <div className="top-button-sort">
                                <div className="filter">
                                    <p className="sort">Trier par</p>
                                    <div>
                                        <p
                                            className={filterSelected === 'asc' ? 'selected' : 'classic'}
                                            onClick={() => {
                                                sortDataAsc(washerSkuData, setWasherSkuData);
                                            }}>
                                            A &gt; Z
                                        </p>
                                        <p
                                            className={filterSelected === 'desc' ? 'selected' : 'classic'}
                                            onClick={() => {
                                                sortDataDesc(washerSkuData, setWasherSkuData);
                                            }}>
                                            Z &gt; A
                                        </p>
                                    </div>
                                </div>
                                <Link
                                    to={'/servicesupplier/operation/washer/sku/add'}
                                    state={{ entity_id: washerData.id }}
                                    className="button-actif">
                                    ASSOCIER UN SKU
                                </Link>
                            </div>
                        </div>

                        <div className="tab-item">
                            <div
                                className="pre-filled-fields-1-several-item"
                                style={{ fontWeight: '600' }}>
                                <p>SKU</p>
                                <p>Statut</p>
                            </div>

                            {washerSkuData.length ? (
                                washerSkuData.map((item, index) => {
                                    return (
                                        <Link
                                            to={'/servicesupplier/operation/washer/sku/update'}
                                            state={{
                                                skuLink: item,
                                                washerName: washerData.company_name,
                                            }}
                                            key={index}
                                            className="pre-filled-fields-2-several-item">
                                            <p>{item.sku}</p>
                                            <p
                                                style={{
                                                    backgroundColor: _defineStatus(item.status).color,
                                                    color: Constant.$blanc,
                                                    borderTopRightRadius: '0.6rem',
                                                    borderBottomRightRadius: '0.6rem',
                                                }}>
                                                {_defineStatus(item.status).name}
                                            </p>{' '}
                                        </Link>
                                    );
                                })
                            ) : (
                                <div className="no-data-graph ">Aucun SKU associé.</div>
                            )}
                        </div>
                    </section>
                </div>
            )}
        </main>
    );
}
