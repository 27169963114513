import React, { useState, useRef } from 'react';
import { useEffect } from 'react';
import { salesCreate } from '../../../../API/SalesApi';
import parser from 'papaparse';
import { UploadSimple } from '@phosphor-icons/react';
import CSVDownloader from '../../../../Components/CSVDownloader';
import { format } from 'date-fns';
import { Jwt } from '../../../../Jwt';
import { getIndustrialProductById } from '../../../../API/IndustrialApi';
import { _defineStoreType, storeTypeTab } from '../../../../Utils';
import * as Constant from '../../../../Constants';

export default function CreateSales() {
    const userContext = new Jwt();
    const [productList, setProductList] = useState([]);
    const today = new Date();
    const todayFormatted = format(today, 'yyyy-MM-dd');
    const [importMsg, setImportMsg] = useState('');
    const [formOk, setFormOk] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);
    const [fileName, setFileName] = useState(null);
    const csvName = `declaration_market_${todayFormatted}`;
    const headers = ['ean', 'quantite', 'type'];
    const exemples = ['ex:5523467890987', 'ex:10000', 'Hyper=1 Super=2 Proxi=3'];
    const [declarationDetailsData, setDeclarationDetailsData] = useState([
        { indusProductId: '', productName: '', nb_contenants: 0, type: '' },
    ]);

    const dialogRef = useRef(null);

    useEffect(() => {
        setImportMsg('');

        const fetchData = async () => {
            const data = await getIndustrialProductById(userContext.entityId);
            if (data.length > 0) {
                setProductList(data);
            }
        };
        fetchData();
    }, [userContext.entityId]);

    const ajouterLigne = () => {
        setDeclarationDetailsData([...declarationDetailsData, { indusProductId: '', productName: '', nb_contenants: 0, type: '' }]);
    };

    const mettreAJourCommande = (index, field, value) => {
        const newData = [...declarationDetailsData];
        newData[index][field] = value;
        setDeclarationDetailsData(newData);
    };

    const mettreAJourCommande2 = async (index, value) => {
        const findName = productList.filter((item) => item.id === parseInt(value));
        const newData = [...declarationDetailsData];
        newData[index]['indusProductId'] = value;
        newData[index]['productName'] = findName && findName[0].name;

        setDeclarationDetailsData(newData);
    };

    const envoyerCommandeAuServeur = async () => {
        const response = await salesCreate(declarationDetailsData, userContext.entityId, userContext.userId);
        if (response) {
            alert('Déclaration enregistrée.');
            window.location.reload();
        } else {
            alert('Une erreur est survenue.');
        }
    };

    const supprimerLigne = (index) => {
        const newData = [...declarationDetailsData];
        newData.splice(index, 1);
        setDeclarationDetailsData(newData);
    };

    const handleModalOpen = () => {
        if (dialogRef.current) {
            setModalOpen(true);

            dialogRef.current.showModal();
        }
    };

    const handleModalClose = () => {
        setImportMsg('');

        setFileName(null);
        if (dialogRef.current) {
            dialogRef.current.close();
        }
    };

    const importHandler = (e) => {
        setImportMsg('Import in progress');
        e.preventDefault();
        var file = e.target.files[0];
        setFileName(file.name);

        parser.parse(e.target.files[0], {
            header: true,
            skipEmptyLines: true,
            complete: callBackHandleImportParse,
        });
    };

    const callBackHandleImportParse = (results) => {
        if (checkDataValidity(results)) {
            ConvertCsvToForm(results);
        }
    };

    const checkDataValidity = (results) => {
        var i = 0;
        if (checkHeaderValidity(results.meta.fields)) {
            for (i = 0; i < results.data.length; i++) {
                checkLineValidity(i, results.data[i]);
            }
        } else {
            console.log('HEADER - Unvalid');
        }

        setImportMsg('DATA Validity check finished !');
        return 1;
    };

    const checkLineValidity = (i, resultLine) => {
        var ret = 1;
        if (resultLine.ean.length > 32) {
            console.log('LINE' + i + ' - column EAN - unvalid');
            ret = 0;
        }

        if (resultLine.nb_contenants <= 0 || '') {
            console.log('LINE' + i + ' - column QUANTITE - unvalid');
            ret = 0;
        }

        if (resultLine.type === '') {
            console.log('LINE' + i + ' - column TYPE - unvalid');
            ret = 0;
        }

        return ret;
    };

    const checkHeaderValidity = (fields) => {
        var ret = 1;
        if (fields[0] !== 'ean') {
            console.log('HEADER - column 1 - unvalid');
            ret = 0;
        }

        if (fields[1] !== 'quantite') {
            console.log('HEADER - column 2 - unvalid');
            ret = 0;
        }

        if (fields[2] !== 'type') {
            console.log('HEADER - column 3 - unvalid');
            ret = 0;
        }

        return ret;
    };

    const ConvertCsvToForm = async (results) => {
        let newData = [];
        for (let i = 1; i < results.data.length; i++) {
            const findProduct = productList.filter((item) => item.ean === results.data[i].ean);

            if (!productList.some((option) => option.ean === results.data[i].ean)) {
                setImportMsg('Erreur: EAN ' + results.data[i].ean + " n'existe pas.");
                setFormOk(false);
                return;
            }

            newData.push({
                indusProductId: findProduct[0].id,
                productName: findProduct[0].name,
                nb_contenants: parseInt(results.data[i].quantite),
                type: parseInt(results.data[i].type),
            });
        }

        setDeclarationDetailsData(newData);
        setImportMsg('CSV converti avec succès !');
        setFormOk(true);
    };
    return (
        <main>
            <section className="big-section">
                <div className="top">
                    <h2>Déclaration mise sur le marché</h2>
                    <button
                        onClick={handleModalOpen}
                        className="button-actif">
                        IMPORTER UN FICHIER
                    </button>
                </div>

                <div className="declaration-container">
                    <div className="tab-item">
                        <div
                            className="pre-filled-fields-1-several-item"
                            style={{ fontWeight: '600' }}>
                            <p>EAN</p>
                            <p>Nom produit</p>
                            <p>Quantité contenants</p>
                            <p>Type de magasin</p>
                        </div>
                        {declarationDetailsData.map((ligne, index) => (
                            <div
                                key={index}
                                className="line-container">
                                {productList.length ? (
                                    <select onChange={(e) => mettreAJourCommande2(index, e.target.value)}>
                                        <option>Choisissez une option</option>
                                        {productList.map((option, index) => (
                                            <option
                                                key={index}
                                                value={option.id}
                                                selected={ligne.indusProductId === option.id ? 'selected' : ''}>
                                                {option.ean}
                                            </option>
                                        ))}
                                    </select>
                                ) : (
                                    <input
                                        style={{ width: '47%' }}
                                        type="text"
                                        disabled
                                        value={'Aucun produit enregistré.'}
                                    />
                                )}

                                <input
                                    disabled
                                    style={{ backgroundColor: Constant.$grisclair, color: Constant.$blanc, opacity: '0.9', fontWeight: 'bolder' }}
                                    type="text"
                                    value={ligne.productName}
                                />
                                <input
                                    type="number"
                                    value={ligne.nb_contenants}
                                    onChange={(e) => mettreAJourCommande(index, 'nb_contenants', e.target.value)}
                                />
                                <select
                                    name="type"
                                    onChange={(e) => mettreAJourCommande(index, 'type', e.target.value)}>
                                    <option value="">--</option>
                                    {storeTypeTab.map((type, index) => (
                                        <option
                                            key={index}
                                            selected={ligne.type === type ? 'selected' : ''}
                                            value={parseInt(type)}>
                                            {_defineStoreType(type)}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        ))}
                    </div>
                    <div className="add-ligne">
                        {declarationDetailsData.length > 1 && <button onClick={() => supprimerLigne(declarationDetailsData.length - 1)}>-</button>}
                        <button onClick={ajouterLigne}>+</button>
                    </div>
                    <div className="save">
                        {!modalOpen && importMsg && <p style={{ color: Constant.$rouge, fontWeight: 'bolder' }}>{importMsg}</p>}
                        <button
                            className="button-actif"
                            onClick={envoyerCommandeAuServeur}>
                            DÉCLARER
                        </button>
                    </div>
                </div>
            </section>
            <dialog
                ref={dialogRef}
                className="dialog-collector">
                <div className="title">
                    <p>Importer un fichier</p>
                    <button
                        className="button-negatif"
                        onClick={handleModalClose}>
                        X
                    </button>
                </div>
                <div className="modal-declaration">
                    {!fileName ? (
                        <>
                            <CSVDownloader
                                fileName={csvName}
                                headers={headers}
                                data={exemples}
                            />
                            <div className="custom-file-upload">
                                <input
                                    id="fileInput"
                                    type={'file'}
                                    accept={'.csv'}
                                    onChange={importHandler}
                                />
                                <label htmlFor="fileInput">
                                    <span>
                                        <UploadSimple size={32} />
                                    </span>
                                    Importer un fichier CSV
                                </label>
                            </div>
                        </>
                    ) : (
                        <div style={{ width: '100%' }}>
                            {importMsg && <p style={{ paddingBottom: '1rem' }}>{importMsg}</p>}
                            <p>Fichier : {fileName}</p>
                            <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '2rem', width: '100%' }}>
                                <button
                                    className="button-negatif"
                                    onClick={() => {
                                        setFileName(null);
                                    }}>
                                    RECOMMENCER
                                </button>
                                {formOk && (
                                    <button
                                        onClick={handleModalClose}
                                        className="button-actif">
                                        VALIDER
                                    </button>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </dialog>
        </main>
    );
}
