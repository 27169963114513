import { Routes, Route, Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import * as Constants from '../../Constants';

import CreateOrder from '../../containers/operation/industrial_interface/orders/CreateOrder';
import IndustrialHome from '../../containers/operation/industrial_interface/IndustrialHome';
import Documentation from '../../containers/Documentation';
import Support from '../../containers/account/Support';
import UpdatePassword from '../../containers/account/UpdatePassword';
import IndusOrderHistory from '../../containers/operation/industrial_interface/orders/IndustrialOrderHistory';
import AnnualForecast from '../../containers/operation/industrial_interface/orders/AnnualForecast';
import IndustrialProducts from '../../containers/operation/industrial_interface/products/IndustrialProducts';
import AddIndustrialProduct from '../../containers/operation/industrial_interface/products/AddIndustrialProduct';
import CreateSales from '../../containers/operation/industrial_interface/orders/CreateSales';
import PreHome from '../../containers/PreHome';
import ErrorPage from '../../Components/ErrorPage';

// DATA
import IndusDataHome from '../../containers/data/industrial_interface/IndusDataHome';

import IndusDataReuseDashboard from '../../containers/data/industrial_interface/reemploi/IndusDataReuseDashboard';
import IndusDataReuseCollecteurs from '../../containers/data/industrial_interface/reemploi/IndusDataReuseCollecteurs';
import IndusDataReuseContainer from '../../containers/data/industrial_interface/reemploi/IndusDataReuseContainer';
import IndusDataReuseProducts from '../../containers/data/industrial_interface/reemploi/IndusDataReuseProducts';
import IndusDataReuseReturns from '../../containers/data/industrial_interface/reemploi/IndusDataReuseReturns';
import IndusDataReuseSales from '../../containers/data/industrial_interface/reemploi/IndusDataReuseSales';
import IndusDataReuseSorter from '../../containers/data/industrial_interface/reemploi/IndusDataReuseSorter';
import IndusDataReuseWasher from '../../containers/data/industrial_interface/reemploi/IndusDataReuseWasher';

import IndusDataConsumerDashboard from '../../containers/data/industrial_interface/consumer/IndusDataConsumerDashboard';
import IndusDataConsumerCollecteurs from '../../containers/data/industrial_interface/consumer/IndusDataConsumerCollecteurs';
import IndusDataConsumerFrequence from '../../containers/data/industrial_interface/consumer/IndusDataConsumerFrequence';
import IndusDataConsumerProduct from '../../containers/data/industrial_interface/consumer/IndusDataConsumerProduct';
import IndusDataConsumerRegions from '../../containers/data/industrial_interface/consumer/IndusDataConsumerRegions';

// Profil
import ProfilPersonalInfo from '../../containers/account/ProfilPersonalInfo';
import ProfilCompanyInfo from '../../containers/account/ProfilCompanyInfo';
import ProfilAccess from '../../containers/account/ProfilAccess';
import ProfilRoleManagement from '../../containers/account/ProfilRoleManagement';
import CreateUser from '../../containers/account/CreateUser';
import UserSheet from '../../containers/account/UserSheet';
import Unknown from '../../containers/account/Unknown';

// FINANCE
import IndustrialReportings from '../../containers/finance/industrial_interface/IndustrialReportings';
import IndustrialReportingDetails from '../../containers/finance/industrial_interface/IndustrialReportingsDetail';

export default function IndustrialInterface() {
    const role = Cookies.get('role');
    const isAdmin = role === Constants.ENTITY_ROLE_ADMIN;
    return (
        <Routes>
            <Route
                path="/error"
                element={<ErrorPage />}
            />
            <Route
                path="/prehome"
                element={<PreHome />}
            />
            <Route
                path="/support"
                element={<Support />}
            />
            <Route
                path="/documentation"
                element={<Documentation />}
            />
            <Route
                path="/updatepassword"
                element={<UpdatePassword />}
            />
            <Route
                path="/industrial/operation/home"
                element={<IndustrialHome />}
            />

            <Route
                path="/industrial/operation/order/create"
                element={<CreateOrder />}
            />
            <Route
                path="/industrial/operation/order/history"
                element={<IndusOrderHistory />}
            />
            <Route
                path="/industrial/operation/forecast/create"
                element={<AnnualForecast />}
            />
            <Route
                path="/industrial/operation/sales/create"
                element={<CreateSales />}
            />
            <Route
                path="/industrial/operation/product"
                element={<IndustrialProducts />}
            />
            <Route
                path="/industrial/operation/product/add"
                element={<AddIndustrialProduct />}
            />
            <Route
                path="/industrial/data/home"
                element={<IndusDataHome />}
            />
            <Route
                path="/industrial/data/reuse/dashboard"
                element={<IndusDataReuseDashboard />}
            />
            <Route
                path="/industrial/data/reuse/collectors"
                element={<IndusDataReuseCollecteurs />}
            />
            <Route
                path="/industrial/data/reuse/container"
                element={<IndusDataReuseContainer />}
            />
            <Route
                path="/industrial/data/reuse/products"
                element={<IndusDataReuseProducts />}
            />
            <Route
                path="/industrial/data/reuse/returns"
                element={<IndusDataReuseReturns />}
            />
            <Route
                path="/industrial/data/reuse/sales"
                element={<IndusDataReuseSales />}
            />
            <Route
                path="/industrial/data/reuse/sorter"
                element={<IndusDataReuseSorter />}
            />
            <Route
                path="/industrial/data/reuse/washer"
                element={<IndusDataReuseWasher />}
            />
            <Route
                path="/industrial/data/consumer/dashboard"
                element={<IndusDataConsumerDashboard />}
            />
            <Route
                path="/industrial/data/consumer/product"
                element={<IndusDataConsumerProduct />}
            />
            <Route
                path="/industrial/data/consumer/regions"
                element={<IndusDataConsumerRegions />}
            />
            <Route
                path="/industrial/data/consumer/frequence"
                element={<IndusDataConsumerFrequence />}
            />
            <Route
                path="/industrial/data/consumer/collectors"
                element={<IndusDataConsumerCollecteurs />}
            />
            <Route
                path="/industrial/financial/reportings"
                element={<IndustrialReportings />}
            />
            <Route
                path="/industrial/financial/reportings/details"
                element={<IndustrialReportingDetails />}
            />

            <Route
                path="/industrial/myprofil/personal_info"
                element={<ProfilPersonalInfo />}
            />
            <Route
                path="/industrial/myprofil/company_info"
                element={<ProfilCompanyInfo />}
            />
            <Route
                path="/industrial/myprofil/access"
                element={<ProfilAccess />}
            />
            {isAdmin ? (
                <Route
                    path="/industrial/myprofil/rolemanagement"
                    element={<ProfilRoleManagement />}
                />
            ) : null}
            {isAdmin ? (
                <Route
                    path="/industrial/myprofil/user/add"
                    element={<CreateUser />}
                />
            ) : null}
            {isAdmin ? (
                <Route
                    path="/industrial/myprofil/user/sheet"
                    element={<UserSheet />}
                />
            ) : null}
            <Route
                path="/industrial/operation/unknown"
                element={<Unknown />}
            />
            <Route
                path="*"
                element={
                    <Navigate
                        replace
                        to="/industrial/operation/unknown"
                    />
                }
            />
        </Routes>
    );
}
